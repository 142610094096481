.row {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
}

/* Create four equal columns that sits next to each other */
.column {
  flex: 25%;
  max-width: 25%;
  padding: 0 4px;
}

.column img {
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
}

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 800px) {
  .column {
    flex: 50%;
    max-width: 50%;
  }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .column {
    flex: 100%;
    max-width: 100%;
  }
}

.video-body {
  background-color: #151845;
  padding: 0;
  margin: 0;
  position: sticky;
  height: 22rem;
  cursor: wait;
}
.video-container {
  /* height: 370px; */
  /* width: 370px; */
  /* border: 1px solid #fff; */
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
}
.video-moon {
  /* background-color: #39beff; */
  background-color: #d4d3bd85;
  height: 170px;
  width: 170px;
  border-radius: 50%;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
}
.video-crater {
  background-color: white;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  position: relative;
}
.video-crater:before {
  content: "";
  position: absolute;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  box-shadow: -5px 0 0 2px #00000054;
  top: 2px;
  left: 7px;
}
.video-crater1 {
  top: 27px;
  left: 90px;
  transform: scale(0.9);
}
.video-crater2 {
  bottom: 15px;
  left: 61px;
  transform: scale(0.6);
}
.video-crater3 {
  left: 15px;
  transform: scale(0.75);
}
.video-crater4 {
  left: 107px;
  top: 32px;
  transform: scale(1.18);
}
.video-crater5 {
  left: 33px;
  bottom: 4px;
  transform: scale(0.65);
}
.video-shadow {
  height: 190px;
  width: 190px;
  box-shadow: 21px 0 0 5px rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  position: relative;
  bottom: 157.5px;
  right: 46px;
}
.video-eye {
  background-color: #161616;
  height: 12px;
  width: 12px;
  position: relative;
  border-radius: 50%;
}
.video-eye-l {
  bottom: 255px;
  left: 59px;
}
.video-eye-r {
  bottom: 267px;
  left: 101px;
}
.video-mouth {
  height: 5px;
  width: 10px;
  border: 3px solid #161616;
  position: relative;
  bottom: 262px;
  left: 79px;
  border-top: none;
  border-radius: 0 0 10px 10px;
}
.video-blush {
  background-color: #1ca4f9;
  height: 7.5px;
  width: 7.5px;
  position: relative;
  border-radius: 50%;
}
.video-blush1 {
  bottom: 273px;
  left: 50px;
}
.video-blush2 {
  bottom: 281px;
  left: 115px;
}
.video-orbit {
  height: 280px;
  width: 280px;
  /* border: 1px solid #fff; */
  border-radius: 50%;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  animation: spin 10s infinite linear;
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.video-rocket {
  background-color: #fafcf7;
  height: 50px;
  width: 25px;
  border-radius: 50% 50% 0 0;
  position: relative;
  left: -11px;
  top: 115px;
}
.video-rocket:before {
  content: "";
  position: absolute;
  background-color: #a00030;
  height: 20px;
  width: 55px;
  border-radius: 50% 50% 0 0;
  z-index: -1;
  right: -15px;
  bottom: 0;
}
.video-rocket:after {
  content: "";
  position: absolute;
  background-color: #a00030;
  height: 4px;
  width: 15px;
  border-radius: 0 0 2px 2px;
  bottom: -4px;
  left: 4.3px;
}
.video-window {
  background-color: #151845;
  height: 10px;
  width: 10px;
  border: 2px solid #b8d2ec;
  border-radius: 50%;
  position: relative;
  top: 17px;
  left: 5px;
}
.video-color {
  color: white;
  padding: 7px;
  height: 3rem;
  gap: 0.5rem;
  position: relative;
  z-index: 1;
}

.line {
  display: inline-block;
  width: 4px;
  height: 15px;
  border-radius: 15px;
  background-color: coral;
}

.load-1 .line:nth-last-child(1) {
  animation: loadingA 1.5s 1.5s infinite;
  margin-right: 5px;
}
.load-1 .line:nth-last-child(2) {
  animation: loadingA 1.5s 0.6s infinite;
  margin-right: 5px;
}
.load-1 .line:nth-last-child(3) {
  animation: loadingA 1.5s 0.3s infinite;
  margin-right: 5px;
}
.load-1 .line:nth-last-child(4) {
  animation: loadingA 1.5s 0s infinite;
  margin-right: 5px;
}
@keyframes loadingA {
  0% {
    height: 15px;
  }
  50% {
    height: 35px;
  }
  100% {
    height: 15px;
  }
}
