/* ================ WELCOME SCREEN ======== */
.greyBg {
  background: #f2f2f2;
}

.welcomeCard {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 5px 5px 10px #0000000d;
  border-radius: 4px;
  padding: 20px 25px;
  margin: 0 60px;
}

#welcome {
  padding-top: 5%;
}

.welcomeCard img {
  width: 380px;
  height: auto;
}

.welcomeDetails p {
  color: #000000cc;
  font-size: 16px;
  margin-bottom: 20px;
}

.welcomeDetails {
  margin-top: 10%;
}

b.welcomeBlueText {
  color: #60c2e7;
}

button.letsGoBtn {
  background: linear-gradient(to right, #ef5a3e 5%, #a00030 100%);
  border-radius: 4px;
  border: none;
  color: #fff;
  width: 125px;
  padding: 6px;
  font-family: "nunitoBold", sans-serif;
  cursor: pointer;
}
button.letsGoBtn:focus {
  outline: none;
}

/* =========== PROFILE PAGE ========= */
p.highlitedTabText {
  color: #b5b5b5;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 5px;
}

.profileTab {
  text-align: left;
}

.profileNumber p {
  color: #b5b5b5;
  font-size: 24px;
  font-weight: bold;
}

.profileNumber {
  margin-right: 15px;
  align-items: center;
  justify-content: center;
  display: flex;
  min-width: 30px;
}

p.tabDetailedDesc {
  color: #b5b5b5cc;
  font-size: 13px;
}

.ProfileTabNav .nav-item {
  background: #fff;
  border: 1px solid #e4e4e5;
}

.ProfileTabNav .nav-item {
  position: relative;
  border-right: 1px solid #e4e4e5 !important;
  border: none;
}
.showTabArrow:after,
.showTabArrow:before {
  left: 100%;
  top: 70%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.showTabArrow:after {
  border-color: rgba(34, 34, 34, 0);
  border-left-color: #ffffff;
  border-width: 15px;
  margin-top: -30px;
  z-index: 1;
}
.showTabArrow:before {
  border-color: rgba(228, 228, 229, 0);
  border-left-color: #dee2e6;
  border-width: 16px;
  margin-top: -31px;
  z-index: 1;
}

.ProfilePage {
  padding-top: 25px;
  padding-bottom: 25px;
}

a#about-you {
  padding-left: 30px;
}

a#learning {
  padding-left: 30px;
}

nav.ProfileTabNav {
  padding-bottom: 35px;
}

nav.ProfileTabNav .active p.highlitedTabText {
  color: #60c2e7;
  opacity: 1;
}

nav.ProfileTabNav .active .profileNumber p {
  color: #212121;
}

nav.ProfileTabNav .active p.tabDetailedDesc {
  color: #000000cc;
}
nav.ProfileTabNav .active p.highlitedTabText {
  color: #60c2e7;
  opacity: 1;
}

nav.ProfileTabNav .active .profileNumber p {
  color: #212121;
}

nav.ProfileTabNav .active p.tabDetailedDesc {
  color: #000000cc;
}
.profileTabContent::placeholder {
  font-family: "Lato", sans-serif;
  font-size: 13px;
}
.profilePageTabContent {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 5px 5px 10px #0000000d;
  border-radius: 4px;
  padding: 25px;
}

.profileImg {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.profileDetailsForm label {
  display: block;
  font-family: "Lato", sans-serif;
  font-size: 13px;
  font-weight: bold;
  color: #333333de;
  margin-bottom: 5px;
}

.genderRadioButton label {
  display: initial;
}

.profileFormInput input {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dededf;
  border-radius: 4px;
  margin-bottom: 15px;
  width: 260px;
  padding: 6px;
  font-size: 13px;
}
.profileFormInput input {
  border: 0px solid #dededf !important;
}
.genderDetails {
  margin-bottom: 15px;
}

.choosePhoto {
  align-items: center;
}

.choosePhotoImg img {
  width: 115px;
  height: auto;
}

.choosePhotoBtn button {
  background: #dfdfdf 0% 0% no-repeat padding-box;
  border-radius: 4px;
  border: none;
  width: 125px;
  padding: 6px;
  letter-spacing: 0px;
  color: #333333;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-weight: bold;
}

.choosePhotoImg {
  margin-right: 15px;
}

.profileSubmitBtn button {
  background: #f97516;
  border-radius: 4px;
  border: none;
  /* width: 154px; */
  color: #fff;
  padding: 7.5px;
  font-size: 14px;
  font-weight: bold;
  font-family: "Lato", sans-serif;
  letter-spacing: 0;
}
button {
  cursor: pointer;
}
.profileTabContent button:focus {
  outline: none;
}
.formRadioInput [type="radio"]:checked,
.formRadioInput [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.formRadioInput [type="radio"]:checked + label,
.formRadioInput [type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  line-height: 23px;
  display: inline-block;
  color: #666;
}
.formRadioInput [type="radio"]:checked + label:before,
.formRadioInput [type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 3px;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}
.formRadioInput [type="radio"]:checked + label:after,
.formRadioInput [type="radio"]:not(:checked) + label:after {
  content: "";
  width: 8px;
  height: 8px;
  background: #0c0c0c;
  position: absolute;
  top: 8px;
  left: 5px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.formRadioInput [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.formRadioInput [type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.formRadioInput {
  margin-right: 10px;
}

/* ========= model ======== */
.uploadEmptyCard {
  border: 1px solid #707070;
  background: #e1e1e1;
  text-align: center;
}

.uploadEmptyCard img {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  border: 5px dashed #ffffff;
}

.uploadButton button {
  display: block;
  width: 175px;
  border: none;
  background: #dfdfdf 0% 0% no-repeat padding-box;
  border-radius: 4px;
  color: #333333;
  font-size: 14px;
  font-weight: bold;
  font-family: "Lato", sans-serif;
  padding: 7.5px;
  margin-bottom: 15px;
}

.uploadButton {
  margin-left: 35px;
}
.createPostModel button {
  margin-left: 0 !important;
}
h5#uploadModelLabel {
  font-size: 16px;
  font-weight: bold;
  color: #000000de;
}

div#uploadModel .modal-header {
  border-bottom: 1px solid #707070;
}

#uploadModel .modal-footer {
  border: navajowhite;
}

#uploadModel .btn-primary {
  background: linear-gradient(to right, #ef5a3e 5%, #a00030 100%);
  border: none;
  color: #ffffff;
  font-size: 16px;
  width: 120px;
  padding: 11.5px;
  font-weight: bold;
}

#uploadModel .btn-secondary {
  background: no-repeat;
  border: none;
  color: #333333;
  font-size: 14px;
  font-weight: bold;
}

.blurSection {
  filter: blur(8px);
}

/* ========== about section ======== */
.profileFormTextarea textarea {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dededf;
  border-radius: 4px;
  margin-bottom: 15px;
}

.aboutInputAddButton {
  position: relative;
}

.aboutInputAddButton i {
  position: absolute;
  left: 46%;
  top: 45%;
  color: #747576;
  cursor: pointer;
}

.individualBadge {
  display: flex;
  border: 1px solid #333333;
  border-radius: 100px;
  padding: 2px 10px;
  margin-right: 15px;
  align-items: center;
}

.individualBadge p {
  font-size: 13px;
  font-family: "Lato", sans-serif;
  margin-right: 10px;
}

.profileBadge {
  margin-bottom: 15px;
}

.individualBadge span {
  font-size: 14px;
  cursor: pointer;
}

.aboutSubmitBtn button {
  width: 120px !important;
}

/* ================ LEARNING SECTION ========= */
.learningSection .individualBadge p {
  margin: 0;
}
.learningSection .individualBadge {
  display: inline-block;
  margin-bottom: 5px;
}
p.dropTitle {
  font-size: 13px;
  color: #333333de;
  font-family: "Lato", sans-serif;
  font-weight: bold;
  text-align: center;
}

.dragDropCardContainer {
  margin-top: 25px;
  width: 220px;
}

.dragDropCard {
  width: 220px;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #b2b2b2;
  border-radius: 4px;
  background: #b2b2b205;
  margin: 5px 0;
}

.dragDropCard p.dragDropTxt {
  color: #000000;
  opacity: 0.25;
  font-size: 14px;
  font-weight: bold;
  width: 100px;
  text-align: center;
  font-family: "Lato", sans-serif;
}

.dragDropBottomIcons {
  text-align: center;
}

.dragDropBottomIcons img {
  width: 15%;
}
.learningBtn button {
  width: 106px;
  margin-top: 35px;
}

.dropBadgeArea {
  min-height: 150px;
}
.activeDragArea {
  display: inline-block;
  padding: 5px;
  text-align: center;
  overflow: hidden auto;
}

.profileNumber .fa {
  color: #21ba45;
  font-size: 30px;
}

.letsGoBtn img {
  width: 20px !important;
  margin-left: 5px;
}

/* ============= MY CLASSROOM ================= */
.classroomCard {
  box-shadow: 5px 5px 10px #0000000d;
  border-radius: 4px;
  background: #fff;
  padding: 10px;
  height: 570px;
  margin-bottom: 15px;
}

.classDetails h3 {
  font-size: 16px;
  margin-bottom: 2px;
}

.classDetails h2 {
  font-size: 18px;
}

.classDetails {
  position: relative;
  padding: 15px 10px 30px;
}

.classDetails i {
  position: absolute;
  left: 2%;
  top: 14%;
}
.classDetails h3,
.classDetails h2 {
  font-family: "nunitoRegular", sans-serif;
  font-weight: bold;
  margin-left: 8%;
  color: #000000de;
}

.classDetails p {
  letter-spacing: 0px;
  color: #545454;
  font-size: 12px;
}

.classImg img {
  width: 100px;
  height: 50px;
  object-fit: fill;
}

.classList h2 {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0px;
  font-family: "nunitoRegular", sans-serif;
}

.classList {
  margin-bottom: 30px;
  position: relative;
  width: 100%;
  cursor: pointer;
}

.classList p {
  font-family: "Lato", sans-serif;
  font-size: 12px;
}

.classList strong {
  font-size: 24px;
  color: #333333;
  font-family: "Bebas Neue", cursive;
  position: absolute;
  right: 2%;
  top: 21%;
}

.classListOpactiy {
  opacity: 0.5;
}

.activeClass {
  background: #e9e9e9;
  border-radius: 4px;
}

.classFeatureimg img {
  width: 100%;
  height: auto;
}

.classDetailed h1 {
  font-size: 18px;
  font-weight: bold;
  font-family: "nunitoRegular", sans-serif;
  margin-bottom: 0;
}

.classDetailed {
  padding: 25px;
}

.classDetailed p {
  font-size: 12px;
  color: #b3b3b3;
  font-weight: bold;
  font-family: "nunitoRegular", sans-serif;
  margin-bottom: 30px;
}

.takeQuizBtn {
  text-align: right;
  margin-top: 20px;
}

.takeQuizBtn button {
  background: #60c2e7 0% 0% no-repeat padding-box;
  border-radius: 4px;
  border: none;
  color: #fff;
  font-size: 14px;
  width: 130px;
  padding: 7.5px 0;
}

.classDetailed hr {
  margin: 20px 0;
  border: 2px solid #707070;
}

.flexReview {
  display: flex;
}

.flexReview p {
  margin-bottom: 0;
  margin-right: 12px;
}

.flexReview img {
  width: 20px;
  margin-right: 5px;
}

.shareClass p {
  margin-bottom: 0;
  margin-right: 25px;
  color: #333333;
  font-weight: bold;
  font-family: "Lato", sans-serif;
}

.shareClass {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.ClassRoomMainContainer {
  padding-bottom: 35px;
}
.flexClassList {
  display: flex;
  padding: 5px;
}

.classInfo {
  margin-left: 15px;
  margin-top: 5px;
}
#myclassRoom {
  padding-top: 3%;
}
/* ============= MY CLASSROOM DIALOGUE ============ */
.myClassroomModelContent {
  text-align: center;
  padding: 30px 0;
}

.myClassroomModelContent img {
  width: 60px;
}

.myClassroomModelContent p {
  color: #707070;
  font-size: 18px;
  font-family: "Lato", sans-serif;
  font-weight: bold;
  margin-bottom: 25px;
}

.myClassroomModelContent button {
  width: 170px;
  background: linear-gradient(to right, #ef5a3e 5%, #a00030 100%);
  border: none;
  padding: 10px 0;
}

/* =============== MY NETWORK SCREEN ================ */
.findFriendContainer {
  padding: 3% 0;
}

.searchFriend {
  margin-bottom: 25px;
}

.searchFriend h3 {
  color: #707070;
  font-size: 24px;
  font-weight: bold;
  font-family: "Lato", sans-serif;
  margin-bottom: 25px;
  text-align: center;
}

.searchFriendInput.input-group,
.findaMatchSearchInput.input-group {
  width: 250px;
  margin: auto;
  position: relative;
}
.searchFriend.findaMatchCard .searchFriendSelectBoxFlex {
  width: 85%;
}
.searchFriend .input-group input {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dededf;
  border-radius: 100px !important;
  font-size: 12px;
  padding: 7px 15px;
  height: auto !important;
}

.searchFriendInput i {
  position: absolute;
  right: 15px;
  top: 9px;
  color: #9a9a9a;
  font-size: 14px;
  cursor: pointer;
}

.searchFriendSelectBoxFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 75%;
  margin: auto;
  margin-bottom: 30px;
}

.searchFriendSelectBoxFlex label {
  width: 100%;
  color: #333333de;
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 5px;
  line-height: 1;
}

.searchFriendFormField {
  margin-right: 10px;
  width: 160px;
}

.searchFriendSelectBoxFlex select {
  border: 1px solid #dededf;
  border-radius: 4px !important;
  font-size: 12px;
}

.profilePhoto img {
  width: 100px;
  height: 100px;
  box-shadow: 0px 0px 5px #00000029;
  border: 3px solid #ffffff;
  border-radius: 50%;
  object-fit: cover;
}

.networkCard {
  text-align: center;
  border: 1px solid #dededf;
  border-radius: 4px;
  background: #fff;
  position: relative;
}

.coverPhoto {
  height: 100px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  border-radius: 4px 4px 0px 0px;
}

.profilePhoto {
  margin-top: -45px;
}

.networkDetails {
  padding: 20px 15px 0;
}

.networkDetails h3 {
  font-size: 18px;
  color: #333333;
  font-weight: bold;
  font-family: "Lato", sans-serif;
}

.networkDetails p {
  color: #b3b3b3;
  font-size: 12px;
  margin-bottom: 15px;
}

.connectBtn {
  padding: 20px 15px 15px;
}

.connectBtn button {
  border-radius: 4px;
  border: none;
  background: #60c2e7;
  color: #fff;
  font-size: 12px;
  padding: 5px 8px;
  font-weight: bold;
  font-family: "nunitoRegular", sans-serif;
}
.networkCardContainer .col-md-3 {
  margin-bottom: 30px;
}
.networkCardContainer {
  min-height: 500px;
}
.networkDetailsBtn button {
  display: block;
  margin: auto;
  font-size: 14px;
  border: none;
  padding: 7.5px 0;
  font-weight: bold;
  font-family: "Lato", sans-serif;
  cursor: pointer;
}

button.networkSkip {
  background: #dfdfdf;
  border-radius: 4px;
  color: #333333;
  width: 125px;
}

/* =============== FIND A MATCH SCREEN ============ */
.findaMatchCard {
  background: #fff 0 0 no-repeat padding-box;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 15px 10px;
  margin-bottom: 15px;
  min-height: 130px;
  /*min-height: 230px; */
}
span.filterTxt {
  margin: 0 !important;
}
.filterDownArrow {
  cursor: pointer;
}
.findaMatchSearchInput span i {
  margin-right: 5px;
}
.findaMatchSearchInput {
  width: 350px !important;
}

.findaMatchSearchInput i {
  position: inherit;
  top: 10px;
  right: 10px;
  color: #9a9a9a;
  font-size: 14px;
  cursor: pointer;
}

.findaMatchSearchInput span {
  font-size: 14px;
  color: #989898;
  text-decoration: underline;
  margin-top: 6px;
  margin-left: 10px;
}

.findaMatchSearchInput span i {
  font-size: 14px;
}

.findaMatchSearchInput input {
  width: 275px;
}

.findMatachHeading {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}
.navbar-brand {
  background: transparent;
  margin: 0 20px 0 0;
}
.findMatachHeading:before,
.findMatachHeading:after {
  background: #707070;
  height: 1px;
  content: "";
  flex: 1;
}

.findMatchNetworkCard {
  padding: 20px 0;
}

.findMatachHeading h3 {
  color: #333333de;
  font-size: 24px;
  font-weight: bold;
  font-family: "nunitoRegular", sans-serif;
  padding: 0 10px;
}
.yuukkeHeading h3 {
  color: #b3b3b3;
}
.yuukkeHeading:before,
.yuukkeHeading:after {
  background: #b3b3b3;
}
.detailedCards {
  width: 110%;
  margin: auto;
}

.detailedCards .col-md-3 {
  margin-bottom: 30px;
}

.findMatchCardBtn i {
  margin-right: 5px;
}
/* .findMatchCardBtn button {
      width: auto;
      padding: 5px 10px;
    } */
.findMatchCardBtn button {
  width: auto;
  padding: 5px 5px;
}
button.btnOutlined {
  background: none;
  color: #60c2e7;
  border: 1px solid #60c2e7;
  margin-left: 5px;
}

.findMatchNetworkCard .row {
  margin-bottom: 25px;
}

.detailedCards .networkDetails > p:nth-child(3) {
  margin-bottom: 0;
}
/*============= My YUUKKE =================*/

.MyYuukke {
  padding-top: 50px;
}
.smiley {
  text-align: center;
}
.smiley p {
  opacity: 0.6;
  font-weight: 700;
  font-size: 18px;
}
.img-wrapper img {
  max-width: 200px;
}
/* .card-wrapper {
  background: #fff;
  box-shadow: 5px 5px 10px #0000000d;
  border-radius: 4px;
  padding: 32px 40px;
  min-height: 273px;
  margin-bottom: 20px;
} */
.btn-peer {
  background: #60c2e7;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
}
.mypeercards .container {
  max-width: 1000px;
}
.btn-peer:hover {
  color: #fff;
  text-decoration: none;
}
.mypeercards {
  padding-top: 20px;
}
.img-wrapper a {
  position: absolute;
  bottom: 85px;
  left: 55px;
}

/*=============== Book Time ============*/
.book-time {
  padding: 50px 0px;
}
.book-time h4 {
  color: #707070;
  font-weight: bold;
  margin-bottom: 50px;
}
.bpc-img-wrapper {
  text-align: center;
}
.bpc-img-wrapper img {
  border-radius: 50%;
  box-shadow: 0px 0px 5px #00000029;
  border: 3px solid #ffffff;
}
.hash-tags {
  font-size: 13px;
}
.chat-btn {
  border: 1px solid #60c2e7;
  border-radius: 4px;
  padding: 10px 30px;
  color: #60c2e7;
  font-weight: bold;
  font-size: 14px;
  background: transparent;
}
.update-btn {
  background: #60c2e7 0% 0% no-repeat padding-box;
  border-radius: 4px;
  color: #fff;
  padding: 10px 30px;
  border: none;
  font-weight: bold;
}
.booking-profile-card {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d4d4d5;
  border-radius: 4px 4px 0px 0px;
  padding: 30px;
}
.right-col-wrap {
  padding: 30px;
}
.align-flex-end {
  align-items: flex-end;
}

/* ================ CALANDER =========== */
.appoinmentCalander {
  position: relative;
}
.appoinmentCalander .fc-header-toolbar {
  height: 0;
  margin: 0 !important;
}
.appoinmentCalander h2.fc-toolbar-title {
  display: none;
}
.appoinmentConfirmend {
  padding: 25px;
  text-align: center;
}

.appoinmentConfirmedContent img {
  width: 40px;
  margin-bottom: 10px;
}

p.appoinmentBoldTxt {
  font-size: 21px !important;
  font-weight: bold;
  color: #707070;
  margin-bottom: 25px !important;
}

.appoinmentConfirmedContent p {
  font-size: 18px;
  margin-bottom: 0;
}

.appoinmentBtnFlex i {
  margin-right: 5px;
}
a.fc-col-header-cell-cushion {
  font-size: 12px;
}

.fc-scroller {
  /* overflow: hidden !important; */
}

.fc-button-group .fc-next-button {
  right: -10%;
}

.fc-button-primary {
  /* background: none !important; */
  /* color: #333 !important; */
  border: none !important; /* width: 50px; */
}

.fc-button-primary .fc-icon {
  width: auto !important;
}

.fc-button-group .fc-prev-button {
  left: -10%;
}

.fc-button-group {
  position: inherit !important;
  z-index: 1;
}

.fc-view-harness {
  padding-bottom: 50% !important;
  z-index: 0;
  border-bottom: 1px solid #d4d4d5;
}

.fc .fc-timegrid-col.fc-day-today {
  background: none !important;
}

.fc-theme-standard td,
.fc-theme-standard th {
  border: 0px;
}

table.fc-scrollgrid.fc-scrollgrid-liquid {
  /* border: 0px; */
}

.fc-timeGridWeek-view thead .fc-scrollgrid-section td {
  border-top: 1px solid #d4d4d5 !important;
  border-bottom: 1px solid #d4d4d5 !important;
  padding: 5px 0;
}

.fc-button-group .fc-button {
  position: absolute !important;
  top: 2%;
  color: #5fc0e4 !important;
}
.avaliableText {
  color: #707070;
  text-align: center;
  font-size: 21px;
  font-family: "Lato", sans-serif;
}
.bookingCalanderContainer > .col-md-6 {
  padding-left: 15;
  padding-right: 0;
}
.row.bookingCalanderContainer {
  margin-top: 15px;
}
.seeMoreAvalibile {
  color: #5fc0e4;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  font-family: "Lato", sans-serif;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 0;
}
.hideOverflow {
  overflow: hidden auto !important;
}
.fc-button-group .fc-button:focus {
  box-shadow: none !important;
}
.fc .fc-scroller-harness-liquid {
  /* margin-top: 10px; */
}
.fc-timegrid-slots tr td:nth-child(odd) {
  /* display: none; */
}

.fc-scrollgrid-sync-inner {
  width: 50px;
  margin: auto;
}
.fc-event-main {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
}
.fc-v-event {
  border: none !important;
}
.available,
.unavailable {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2ecc71;
  padding: 0;
  border-radius: 3px;
  color: #fff;
}
.available {
  cursor: pointer;
}
.unavailable {
  background-color: #5fc0e4 !important;
  color: #333333 !important;
}
.fc-media-screen .fc-timegrid-event-harness {
  height: 26px;
}
/* ======== Book Time ============ */
#booktime {
  padding: 50px 0px;
}
#booktime h4 {
  color: #707070;
  font-weight: bold;
  margin-bottom: 50px;
}
.bpc-img-wrapper {
  text-align: center;
}
.bpc-img-wrapper img {
  border-radius: 50%;
  box-shadow: 0px 0px 5px #00000029;
  border: 3px solid #ffffff;
}
.hash-tags {
  font-size: 13px;
}
.chat-btn {
  border: 1px solid #60c2e7;
  border-radius: 4px;
  padding: 10px 30px;
  color: #60c2e7;
  font-weight: bold;
  font-size: 14px;
  background: transparent;
}
.update-btn {
  background: #60c2e7 0% 0% no-repeat padding-box;
  border-radius: 4px;
  color: #fff;
  padding: 10px 30px;
  border: none;
  font-weight: bold;
}
.booking-profile-card {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d4d4d5;
  border-radius: 4px 4px 0px 0px;
  padding: 20px;
}
.right-col-wrap {
  padding: 20px;
}
.align-flex-end {
  align-items: flex-end;
}
.profile-details-wrap p {
  margin: 0;
}
/* ======== Book Time ============ */
#sendRequestModal .modal-body {
  padding: 0;
}
.sr-title h3 {
  font-size: 18px;
  color: #333;
  text-transform: uppercase;
  border-bottom: 1px solid #707070;
  padding: 1rem;
  font-weight: bold;
}
.srModalContent {
  padding: 20px 40px 40px 40px;
}
.subjectDropdown {
  max-width: 180px;
}
.descriptionTextArea {
  max-width: 500px;
}
.datePicker {
  max-width: 170px;
  display: inline-block;
  margin-right: 20px;
}
.timePicker {
  max-width: 100px;
  display: inline-block;
}
.srCancel {
  background: #dfdfdf;
  color: #333;
  border: none;
  font-size: 14px;
  padding: 7.5px 20px;
  border-radius: 4px;
  font-weight: bold;
}
.srSend {
  background: #60c2e7;
  border: none;
  padding: 7.5px 20px;
  font-size: 14px;
  border-radius: 4px;
  font-weight: bold;
  color: #fff;
}
#sendRequestModal label {
  font-size: 13px;
  color: #333333de;
}

/* ============= COMMUNITY SCREEN =========== */
/* .communityCard {
      box-shadow: 5px 5px 6px #0000000d;
      border: 1px solid #dededfbf;
      border-radius: 4px;
      background: #fff;
      margin-bottom: 16px;
    } */
.communityCard {
  box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(222, 222, 223, 0.75);
  border-radius: 10px;
  background: #fff;
  margin-bottom: 16px;
  padding: 0px 20px 0px 10px;
}

.userImg img {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  box-shadow: 0px 0px 5px #00000029;
  border: 3px solid #ffffff;
}
.userImgPost img {
  float: left !important;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  box-shadow: 0px 0px 5px #00000029;
  border: 3px solid #ffffff;
}

.newPostUploadFileTypes {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding-right: 15px;
}

.newPostUpload {
  display: flex;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
}
.newPostUpload.newPostUploadFiles {
  margin-right: 0;
}
.newPostSearchField input {
  width: 100%;
  border-radius: 50px;
  border: 0;
  background: rgb(179, 179, 179, 0.25);
  font-size: 14px;
  padding: 16px 25px;
}

.newPostUpload p {
  margin-bottom: 0;
  font-size: 16px;
  margin-left: 5px;
  letter-spacing: 0.32px;
  color: #333333;
  font-weight: bold;
}

.newPostUpload svg {
  font-size: 24px;
  color: #333333;
}
/* .newPostSearchField {
      padding-left: 15px;
    } */
.padding-15 {
  padding: 15px;
}
.communityRootContianer {
  padding: 50px 15px;
}
.flex-center {
  align-items: center;
}
.newPostUploadVideo svg {
  font-size: 30px;
}
.flexSpaceBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.postCardHeaderInfo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.postInfo p {
  margin-bottom: 0;
}

.postUserName {
  color: #333333;
  font-weight: bold;
  font-size: 16px;
}

.postUploadTie {
  color: #b3b3b3;
  font-size: 14px;
}

.postInfo {
  margin-left: 16px;
}

.postCardHeader {
  padding: 15px 25px 20px;
}

.postDescription {
  padding: 0 25px;
}

.postDescription p {
  color: #000000;
  font-size: 16px;
  margin-bottom: 15px;
  letter-spacing: 0.32px;
}

/* .postCardBody img,
    .postCardBody video {
      width: 100%;
      height: 500px;
      object-fit: cover;
    } */

.likeComment p {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: bold;
}

.likeComment {
  display: flex;
}
.invitationCardBtn {
  text-align: center;
}
.likeComment div {
  display: flex;
  cursor: pointer;
}

.postLike {
  margin-right: 30px;
  cursor: pointer;
}

.likeComment svg {
  margin-right: 10px;
  font-size: 24px;
}

.likeComment p,
.likeComment svg {
  color: #b3b3b3;
}
@media (max-width: 1025px) {
  .likesInfo {
    display: none !important;
  }
}
@media (min-width: 1025px) {
  .mobile-likesInfo {
    display: none !important;
  }
}

.mobile-likesInfo {
  display: flex;
  align-items: flex-end;
  font-size: 10px;
}

.likesInfo {
  display: flex;
  align-items: flex-end;
}

.likesInfo p {
  margin-bottom: 0;
  color: #b3b3b3;
  font-size: 14px;
  margin-right: 10px;
}

.postCardFooter {
  padding: 25px;
  position: relative;
}

.likesInfo img {
  width: 63px;
  height: 25px;
  margin-right: 10px;
}

p.commentInfo {
  margin-right: 0;
  cursor: pointer;
}
a.commentInfo {
  margin-right: 0;
  cursor: pointer;
}
p.likesDot {
  position: relative;
  top: -3px;
}

.linkCard {
  display: flex;
  background-color: #fff;
}
.menuCard {
  display: flex !important;
  margin-left: 8px;
}
.menuItem {
  margin-top: 6px;
}
.linkCard img {
  width: 200px;
  height: 200px;
  border-radius: 4px 0px 0px 4px;
}
.linkCard {
  display: flex;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  margin: 15px;
}
p.postEventName {
  letter-spacing: 0px;
  color: #1e1e1e;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}
p.postEventTime {
  letter-spacing: 0px;
  color: #1e1e1e;
  text-transform: uppercase;
  font-size: 16px;
  margin-left: 5px;
  margin-bottom: 10px;
}
p.postEventTime svg {
  font-size: 18px;
  position: relative;
  top: -3px;
  left: -5px;
}
.postLike img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
.joinNowBtn button {
  background: #60c2e7;
  border-radius: 4px;
  border: none;
  width: 92px;
  height: 36px;
  display: grid;
  place-content: center;
  color: #ffffff;
}
/* .linkDetails {
      padding: 15px;
    } */
.postLinkDescription p {
  font-size: 14px;
  color: #b3b3b3;
  margin-bottom: 0;
  padding: 15px;
}

.eventOrginzerDetails {
  display: flex;
  color: #b3b3b3;
  align-items: center;
  font-size: 14px;
  padding: 0 15px 10px;
}

.eventOrginzerDetails p {
  margin-bottom: 0;
  margin-right: 15px;
}

.eventOrginzerDetails svg {
  font-size: 21px;
  position: relative;
  top: -2px;
  margin-right: 10px;
}

.linkInfo {
  border-bottom: 1px solid #e2e2e2;
  padding: 10px 15px 0;
}
.invitationCardTitle {
  border-bottom: 1px solid #dededfbf;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

/* .invitationCardWrapper {
      padding: 25px 15px 0;
    } */
.invitationCardWrapper {
  padding: 25px 15px !important;
}
.invitationCardTitle p {
  margin-bottom: 0;
  font-size: 16px;
  color: #333333;
  font-weight: bold;
}

.invitationUserInfo img {
  width: 75px;
  height: 75px;
}

.invitationUserInfoWrapper {
  display: flex;
}

.invitationUserInfo p {
  margin-bottom: 0;
  font-size: 12px;
  color: #b3b3b3;
}

p.invitationUserName {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}

.invitationUserInfo {
  margin-left: 10px;
}

.invitationConnect {
  display: flex;
  align-items: center;
}

.invitationConnect {
  font-size: 12px;
  font-weight: bold;
  color: #b3b3b3;
  padding: 0;
}

.invitationConnect p {
  margin-bottom: 0;
  padding-right: 10px;
}
.invitationCard {
  margin-bottom: 20px;
}
.invitationConnect i {
  margin-right: 5px;
}
.writeCommentWrapper {
  padding: 10px 25px;
  border-top: 1px solid #dededfbf;
}
.flexCenter {
  display: flex;
  align-items: center;
}
.writeComment .userImg img,
.allComments .userImg img {
  width: 40px;
  height: 40px;
}

.commentInput input {
  width: 100%;
  border-radius: 18px !important;
  border: 0;
  background: rgb(179, 179, 179, 0.25);
  font-size: 14px;
  padding: 5.5px 25px;
}

.commentInput {
  flex: 1;
  margin-left: 25px !important;
}
.commentDetails p {
  margin-bottom: 0;
  font-size: 14px;
}

.commentDetails {
  padding: 8px 15px;
  background: rgb(179, 179, 179, 0.25);
  border-radius: 18px;
}

.commentDetails strong {
  margin-right: 10px;
  color: #f97516;
}

.allComments .userImg {
  margin-right: 6px;
}

.allComments {
  margin-bottom: 15px;
}
/* .likeOptions {
      width: 290px;
      height: 50px;
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15), 0 4px 6px rgba(0, 0, 0, 0.2);
      border-radius: 300px;
      background: #fff;
      border: none;
      justify-content: space-evenly;
      align-items: center;
      padding: 0 15px;
      position: absolute;
      top: -25px;
      left: -15px;
    }
    
    .likeOption img {
      width: 36px;
      height: 36px;
    } */
.showResponseImg img {
  width: 100%;
}
.uploadTypeIcons {
  position: relative;
  overflow: hidden;
  display: inline-block;
  cursor: pointer;
}

.uploadTypeIcons input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}
.overAlluploadicons {
  position: center;
  cursor: pointer;
}
.uploadWrapper img,
.uploadWrapper video {
  width: 100%;
  height: 300px;
  object-fit: contain;
  object-position: top;
}
.uploadPostInput textarea {
  width: 100%;
  height: 80px;
  border: none;
}

.uploadBtnWrapper {
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 10px;
  margin-top: 20px;
}

.uploadTypeIcons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-basis: 20%;
}

.addtoPostTitle {
  flex: 1;
}

.addtoPostTitle p {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: bold;
}

.uploadTypeIcons svg {
  cursor: pointer;
}

.previewWrapper {
  max-width: 280px !important;
  overflow: hidden auto;
  padding: 10px;
  border: 1px solid lightblue;
  border-radius: 5px;
}

.removeLink {
  padding: 0px;
  font-size: 10px;
  color: blue;
  cursor: pointer;
  float: right;
}

.uploadModelTitle {
  font-size: 20px;
  font-weight: 600;
  margin: auto;
}
.uploadPostTitle {
  font-size: 20px;

  font-weight: 400;
  margin: auto;
  display: inline-flex;
}

/* ============ MY PROFILE SCREEN =========== */
.myProfileCoverPhoto img {
  height: 280px;
  width: 100%;
  object-fit: cover;
  border-radius: 4px 4px 0 0px;
}

.myProfilePhoto img {
  width: 150px;
  height: 150px;
}

.myProfileNameDetails h2,
.myKnowledgePoint h3 {
  font-size: 21px;
  font-family: "nunitoBold", sans-serif;
  color: #333333;
  margin-bottom: 0;
}

.myProfileNameDetails p,
.myProfileDescription p {
  font-size: 14px;
  margin-bottom: 0;
  color: #b3b3b3;
}

.myKnowledgePoint p {
  margin-bottom: 0;
  color: #333333;
  font-family: "nunitoRegular", sans-serif;
}

.myProfilePhoto {
  margin-left: 50px;
  position: relative;
  bottom: 90px;
}

.alignItemStart {
  align-items: flex-start;
}

.myProifleUserInfo {
  height: 100px;
  padding-top: 20px;
}

.myProfileNameDetails {
  padding: 0 15px;
}

.myProfileDescription {
  flex-basis: 29%;
  padding: 0 15px;
}

.myProfileEdit {
  padding: 0;
  margin-left: auto;
  margin-right: 15px;
  display: flex;
  align-items: center;
  height: 100%;
}

.myProfileEdit button {
  position: relative;
  top: -20px;
}

.myKnowledgePoint {
  padding: 0 15px;
}

.borderRightDivider {
  border-right: 1px solid #dbdbdc;
  height: 50%;
}
button i {
  margin-right: 5px;
}

.myIntrestWrapper {
  padding: 20px;
}

.titleMedium {
  font-size: 18px;
  font-family: "Lato", sans-serif;
  color: #333333;
  margin-bottom: 15px;
  font-weight: bold;
}

.myIntrestCards img {
  width: 100%;
  height: 120px;
  object-fit: cover;
  border-radius: 4px;
}

.myIntrestCard {
  margin-right: 15px;
  text-align: center;
  width: 100%;
}

.myIntrestCard p {
  margin-bottom: 0;
  font-size: 16px;
  color: #333333;
  font-family: "nunitoExtraBold", sans-serif;
  font-weight: 900;
  margin-top: 5px;
}

.addNewIntrest {
  height: 120px;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #707070;
  border-radius: 4px;
  width: 100%;
  margin-top: 0 !important;
}

.addNewMyIntrestCard {
  width: 100%;
  cursor: pointer;
}

.addNewIntrest i {
  font-size: 80px;
  color: #6f6f6f;
  opacity: 0.19;
}

.addNewMyIntrestCard p {
  font-size: 16px;
  margin-bottom: 0;
  margin-top: 5px;
  color: #333333;
}

.myCompetenciesWrapper {
  padding: 20px;
}

.myCompetencie p {
  margin-bottom: 0;
  font-size: 12px;
  font-weight: bold;
  font-family: "nunitoBold", sans-serif;
}

.myCompetencie {
  flex-basis: 33.33%;
}

.myCompetencie svg {
  font-size: 16px;
  margin-right: 5px;
}

.myCompetencie span {
  font-weight: 100;
}

.myReviewsWrapper {
  padding: 15px;
}

.reviewerProfileImg img {
  width: 50px;
  height: 50px;
}

.reviewerNameDetails h3 {
  font-size: 14px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 0;
}

.reviewerNameDetails p {
  margin-bottom: 0;
  font-size: 12px;
  color: #b3b3b3;
}

.alignItemCenter {
  align-items: center;
}

.reviewerNameDetails {
  margin-left: 15px;
}

.reviewerSubText p {
  margin-bottom: 0;
  font-size: 12px;
  color: #b3b3b3;
  margin-top: 15px;
}

.reviewCard {
  border-left: 4px solid #dbdbdc;
  padding-left: 10px;
}

.allBadges img {
  width: 65px;
  margin-right: 15px;
}

.badgeCard {
  padding: 20px;
}
.allBadges img:nth-child(4) {
  margin-right: 0;
}

.myTodoListCard {
  padding: 20px;
}
ul.todoLists {
  padding-left: 0;
  margin-bottom: 0;
}

.activeTodoLists {
  color: #545454;
  text-decoration: line-through;
}

.todoLists li {
  font-size: 14px;
  color: #000000cc;
  list-style: none;
  position: relative;
  padding: 0 0 0 30px;
  cursor: pointer;
  font-weight: bold;
}

.todoLists li:before {
  content: "";
  position: absolute;
  left: 0;
  top: 3px;
  width: 17px;
  height: 17px;
  border-radius: 4px;
  border: 1px solid #d4d4d5;
}

.activeTodoLists:after {
  position: absolute;
  top: 2px;
  left: 2px;
  content: "\f00c";
  font-family: "FontAwesome", sans-serif;
  font-size: 13px;
}
.activeTodoLists:before {
  border-color: #60c2e7 !important;
}
.myProfileWrapper {
  margin-top: 15px;
}
li {
  font-family: "nunitoRegular", sans-serif;
}

.newTodoForm p {
  width: 17px;
  height: 17px;
  border-radius: 4px;
  border: 1px solid #d4d4d5;
  margin-bottom: 0;
}

.newTodoForm input {
  border-bottom: 1px solid #b3b3b3cc !important;
  border: none;
  margin-left: 10px;
  padding-left: 5px;
  font-size: 14px;
  width: 50%;
}

.addNewTodo {
  border: 1px solid #60c2e7;
  border-radius: 4px;
  width: 17px;
  height: 17px;
  margin-left: 10px;
}

.addNewTodo i {
  font-size: 11px;
  color: #60c2e7;
  position: relative;
  top: 1px;
}

.flexJustifyCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.newTodoForm {
  align-items: flex-end;
}

.myRemiderCard {
  padding: 20px;
}

.reminderGroupImg {
  width: 35px;
  height: 35px;
}

.reminderInfo h4 {
  font-size: 14px;
  color: #000000cc;
  font-weight: bold;
  margin-bottom: 0;
}

.reminderInfo p {
  font-size: 10px;
  color: #b3b3b3;
  margin-bottom: 0;
}

.reminderInfo {
  margin-left: 15px;
}

.myReminder {
  margin-bottom: 10px;
}

.reminderGroupIcon {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 2px solid #60c2e7;
}

.reminderGroupIcon svg {
  font-size: 21px;
  color: #60c2e7;
}
/* #root .container {
      max-width: 1410px;
    } */
/* =========== STUDY GROUP ============ */
.studyGroupCard {
  margin: 0;
  margin-bottom: 15px !important;
}

.studyGroupCard img {
  width: 185px;
  height: 185px;
}

.studyGroupCard p.postEventName {
  font-size: 14px;
}

.studyGroupCard p.postEventTime {
  font-size: 14px;
}

.studyGroupCard .postLinkDescription p {
  font-size: 12px !important;
  padding: 10px;
}

.studyGroupCard .eventOrginzerDetails p {
  font-size: 10px;
}

.studyGroupCard .eventOrginzerDetails svg {
  font-size: 16px;
}

.studyGroupCard .eventOrginzerDetails p:nth-child(2) {
  margin-right: 0;
}

.studyGroupCard .linkInfo {
  padding: 10px 10px 0;
}

.studyGroupCard .eventOrginzerDetails {
  padding: 0 10px 10px;
}

.studyGroupCard p.postEventTime svg {
  font-size: 14px;
}
.studyGroupCard .joinNowBtn button {
  width: 85px;
  height: 30px;
  font-size: 14px;
}
.studyGroupCard .postLinkDescription {
  max-height: 92px;
  overflow: hidden;
}
.studyGroupSeeMoreBtn {
  padding: 0;
  text-align: center;
  margin: 30px 0;
}

.studyGroupSeeMoreBtn button {
  width: 105px;
  height: 36px;
}
.createStudyGroupBtn button {
  width: 238px;
  height: 48px;
  font-size: 16px;
  font-weight: 300;
}

.createStudyGroupBtn button i {
  font-size: 13px;
}

.createStudyGroupBtn {
  padding: 0;
  position: absolute;
  top: 4.5em;
  right: 0;
}

.studyTourWrapper .searchFriend {
  position: relative;
  padding-top: 30px;
  min-height: 200px;
}
.createStudyGroupModelTitle {
  letter-spacing: 0px;
  color: #333333;
  text-transform: uppercase;
  font-size: 18px !important;
  font-family: "Lato", sans-serif;
  padding-left: 15px !important;
}

.formBasicName {
  width: 291px;
}

.createStudyGroupModel .form-group label {
  font-size: 13px;
  color: #333333de;
  font-weight: bold;
  margin-bottom: 5px;
}

.createStudyGroupModel input,
.createStudyGroupModel textarea,
.createStudyGroupModel select {
  border: 1px solid #dededf;
  border-radius: 4px;
}

.formBasicSubject {
  width: 180px;
}

.createStudyGroupModel input,
.createStudyGroupModel select {
  height: 38px;
  font-size: 14px;
}

.formBasicDescription {
  width: 500px;
}

.createStudyGroupModel .form-group {
  margin-bottom: 15px;
}

.formBasicDate {
  width: 150px;
}

.formBasicHour {
  width: 70px;
  margin: 0 20px 15px;
}

.formBasicDuration {
  width: 86px;
}

.newStudyTourinviteParticipate .input-group-prepend .input-group-text {
  background: none;
  border-right: 0;
  color: #747576;
  padding-right: 0;
}
.newStudyTourinviteParticipate .input-group-append .input-group-text {
  background: none;
  border-left: 0;
  color: #747576;
}
.newStudyTourinviteParticipate input {
  border-left: 0;
  border-right: 0;
}

.newStudyTourinviteParticipate .individualBadge p {
  margin: 0 !important;
  margin-right: 10px !important;
}

.newStudyTourinviteParticipate .individualBadge {
  height: 30px;
  position: relative;
  top: -5px;
  left: 30px;
}

.newStudyTourinviteParticipate {
  align-items: center;
  height: 100%;
}

.formBasicCheckbox input {
  height: auto;
}

.formBasicMaxStudent select {
  width: 75px;
}

.createNewStudyTourModelfooter {
  border-top: 0 !important;
}

.createNewStudyTourModelfooter .btn-secondary {
  background: #dfdfdf 0% 0% no-repeat padding-box;
  width: 75px;
  margin-right: 65px;
}

.createNewStudyTourModelfooter .btn {
  border-radius: 4px;
  border: none;
  letter-spacing: 0px;
  color: #333333;
  font-weight: bold;
  font-size: 14px;
  height: 36px;
}

.createNewStudyTourModelfooter button.btn.btn-primary {
  color: #fff;
  background: #60c2e7;
}
.createStudyGroupModel {
  padding-left: 30px !important;
}
.formBasicinviteParticipate {
  margin-bottom: 0 !important;
}
.createStudyGroupModelWrapper .modal-header {
  border-bottom: 1px solid #707070;
}

/* ==================== FOR MOBILE ============= */
/* @media (min-width: 1300px) {
      #root .container {
        max-width: 1410px;
      }
    } */
@media (max-width: 768px) {
  .subjectDropdown {
    max-width: 100%;
  }
  .descriptionTextArea {
    max-width: 100%;
  }
  .descriptionTextArea {
    max-width: 100%;
  }
  .datePicker {
    max-width: 100%;
    display: block;
    margin-right: 0px;
  }
  .timePicker {
    max-width: 100%;
    display: block;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  #root .container {
    max-width: 992px;
  }
  /* COMMUNITY SCREEN */
  .newPostUpload p {
    font-size: 12px;
  }

  .newPostUpload svg {
    font-size: 21px;
  }

  .newPostUploadVideo svg {
    font-size: 27px;
  }

  p.invitationUserName {
    font-size: 11px;
  }

  .invitationUserInfo p {
    font-size: 9px;
  }

  .invitationUserInfoWrapper .userImg img {
    width: 50px;
    height: 50px;
  }

  .invitationConnect button {
    display: flex;
    align-items: center;
  }
  /* STUDY GROUP SCREEN */
  .studyGroupCard p.postEventName {
    font-size: 12px;
  }

  .studyGroupCard p.postEventTime {
    font-size: 11px;
  }

  .studyGroupCard .postLinkDescription p {
    font-size: 11px !important;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  /* .flexDefault {
        display: block;
      } */
  /* COMMUNITY SCREEN */
  .newPostUpload p {
    font-size: 12px;
  }

  .newPostUpload svg {
    font-size: 21px;
  }

  .newPostUploadVideo svg {
    font-size: 27px;
  }
  /* ======== PROFILE SCREEN ============ */
  .uploadTypeIcons {
    flex-basis: auto;
  }
  .myProfilePhoto {
    margin-left: 0;
    bottom: 0;
    margin-bottom: 15px;
  }

  /* .flexDefault {
        display: block;
      }
     */
  .myProifleUserInfo {
    text-align: center;
    position: relative;
    bottom: 90px;
    height: 330px;
  }

  .borderRightDivider {
    border: none;
    height: auto;
  }

  .myProfileEdit {
    display: block;
    height: auto;
  }

  .myProfileDescription {
    margin: 15px 0;
  }

  .myKnowledgePoint {
    margin: 15px 0;
  }

  .myProfileEdit button {
    position: inherit;
  }

  .myIntrestCards img {
    height: 350px;
  }

  .myIntrestCard p {
    margin: 15px 0;
  }

  .addNewIntrest {
    height: 350px;
  }

  .addNewMyIntrestCard p {
    text-align: center;
    margin-top: 15px;
  }

  .myCompetencie p {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .myCompetencie svg {
    font-size: 18px;
  }

  .reviewerInfo {
    display: flex;
  }

  .allBadges {
    display: flex;
  }

  .newTodoForm {
    display: flex;
  }

  .myReminder {
    display: flex;
  }
  .reviewCard {
    margin-bottom: 15px;
  }
  /* STUDY TOUR */
  .createStudyGroupBtn {
    position: relative;
    top: 0;
    text-align: center;
  }
}
@media (min-width: 0) and (max-width: 768px) {
  /* login page */
  h3.loginQuotes {
    font-size: 14px;
    margin-top: 15px;
  }

  .signupOptions {
    display: block !important;
    width: 100%;
  }

  .signupLogo {
    margin-bottom: 15px;
    margin-right: 0;
  }
  .signupTxt p {
    margin-bottom: 5px;
  }

  /* welcome screen */
  #header .userDetailsMenu {
    display: none !important;
  }

  .welcomeCard img {
    width: 100%;
  }

  .welcomeCard {
    margin: 0;
  }

  /* ======== PROFILE SCREEN ============ */
  .ProfileTabNav .nav-item {
    width: 100%;
    margin-bottom: 5px;
  }

  .showTabArrow:after,
  .showTabArrow:before {
    content: inherit;
  }

  a#about-you {
    padding-left: 15px;
  }

  a#learning {
    padding-left: 15px;
  }
  .learningSection .profileBadge {
    display: inline-block !important;
    margin-bottom: 0;
  }

  .profileImg img {
    width: 100%;
  }

  .profileImg {
    margin-bottom: 15px;
  }
  .profileFormTextarea textarea {
    width: 100%;
  }

  .aboutInputAddButton i {
    right: 5%;
    top: 44%;
    left: inherit;
  }

  input {
    width: 100% !important;
  }
  .dragDropCardContainer {
    width: auto;
  }

  .dragDropCard {
    width: auto;
  }
  .showMobileNav {
    display: flex !important;
  }
  /* ======= MY NETWORK  ========= */
  .searchFriendSelectBoxFlex {
    display: block;
    width: 100%;
  }
  .searchFriendInput.input-group,
  .findaMatchSearchInput.input-group {
    width: 100%;
  }
  .findaMatchSearchInput {
    width: 80% !important;
  }

  .findaMatchSearchInput i {
    top: 25px;
    left: -20px;
  }

  .filterDownArrow {
    position: absolute;
    right: -55px;
  }

  .filterDownArrow i {
    top: 0;
  }

  .findMatachHeading h3 {
    font-size: 16px;
  }
  .chat-btn,
  .profile-details-wrap {
    margin-top: 15px;
  }
  .BooktimeMainContainer .col-md-6 {
    padding: 0 15px !important;
  }
  .filterTxt {
    display: none;
  }
  .filterDownArrow i {
    top: 20px;
  }

  .searchFriend.findaMatchCard .searchFriendSelectBoxFlex {
    width: 100%;
  }
  .navbar-toggler-icon {
    margin: 0;
  }
  .classroomCard {
    height: auto;
  }

  .shareClass {
    justify-content: flex-start;
    margin-top: 10px;
  }
  /* study group */
  .linkCard img {
    width: 100%;
    border-radius: 4px 4px 0px 0px;
    height: 250px;
  }
  .linkCard {
    display: block;
  }

  .createStudyGroupBtn {
    position: inherit;
    top: 0;
    margin-bottom: 15px;
    text-align: center;
  }
  .findaMatchSearchInput span {
    right: -30px;
  }
  /* STUDY GROUP DIALOGUE */
  .createStudyGroupModel .form-group {
    width: 100%;
    margin-left: 0;
  }

  .formBasicCheckbox {
    margin-top: 15px;
  }
  .newStudyTourinviteParticipate input {
    width: 1% !important;
  }

  .newStudyTourinviteParticipate .individualBadge {
    display: inline-flex;
    position: inherit;
    margin-top: 5px;
  }

  .formBasicCheckbox input {
    width: auto !important;
  }

  .formBasicMaxStudent select {
    width: 100%;
  }

  .createNewStudyTourModelfooter {
    justify-content: flex-start;
  }

  .createNewStudyTourModelfooter .btn-secondary {
    margin-right: 10px;
  }

  .createNewStudyTourModelfooter .btn {
    font-size: 11px;
  }

  .createStudyGroupModel {
    padding-left: 15px !important;
  }
  /* Community screen */
  .newPostUpload p {
    font-size: 12px;
  }

  .newPostUpload svg {
    font-size: 21px;
  }

  .newPostUploadVideo svg {
    font-size: 27px;
  }

  .communityRootContianer {
    padding: 30px 0;
  }

  /* .newPostUploadFileTypes p {
        display: none;
      } */

  .newPostUpload {
    margin-right: 5px;
  }

  .newPostUploadFileTypes {
    justify-content: center;
  }

  .newPostSearchField input {
    padding: 5px 15px;
    font-size: 12px;
  }

  .postLike {
    margin-right: 12px;
  }

  .likeComment p {
    font-size: 12px;
  }

  .likeComment svg {
    font-size: 18px;
    margin-right: 5px;
  }

  .postLike img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    position: relative;
    top: -3px;
  }

  .postCardFooter {
    padding: 15px;
  }

  .postCardHeader {
    padding: 15px 15px 20px;
  }

  .writeCommentWrapper {
    padding: 10px 15px;
  }
  /* MY PROFILE SCREEN */
  .myProfilePhoto {
    bottom: 0;
    margin-bottom: 15px;
  }

  .uploadTypeIcons {
    flex-basis: auto;
  }
  .myProifleUserInfo {
    text-align: center;
    position: relative;
    bottom: 90px;
    height: 330px;
  }

  .borderRightDivider {
    border: none;
    height: auto;
  }

  .myProfileEdit {
    display: block;
    height: auto;
  }

  .myProfileDescription {
    margin: 15px 0;
  }

  .myKnowledgePoint {
    margin: 15px 0;
  }

  .myProfileEdit button {
    position: inherit;
  }

  .myIntrestCards img {
    height: 250px;
  }

  .myIntrestCard p {
    margin: 15px 0;
  }

  .addNewIntrest {
    height: 250px;
  }

  .addNewMyIntrestCard p {
    text-align: center;
    margin-top: 15px;
  }

  .myCompetencie p {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .myCompetencie svg {
    font-size: 18px;
  }

  .reviewerInfo {
    display: flex;
  }

  .allBadges {
    display: flex;
  }

  .newTodoForm {
    display: flex;
  }

  .myReminder {
    display: flex;
  }
  .reviewCard {
    margin-bottom: 15px;
  }
}

@media (min-width: 0) and (max-width: 378px) {
  /* COMMUNITY SCREEN */
  .likesInfo p {
    font-size: 11px;
  }

  p.postEventName {
    font-size: 14px;
  }

  p.postEventTime {
    font-size: 11px;
  }

  .eventOrginzerDetails p {
    font-size: 12px;
  }
}
/* .barBtn img {
        width: 10px;
        height: 10px;
      }
      
      .barBtn img {
        margin-right: 5px;
      }
      
      .barBtn i {
        font-size: 14px !important;
      }
      
      
      .barBtn p {
        font-size: 12px;
        margin-right: 10px;
      } */

.action-bar {
  margin-left: 10px;
}

.action-item {
  margin: 5px !important;
  text-align: center;
}

.action-item > span {
  margin: 0px !important;
  text-align: center;
}

.colorLikeImg {
  width: 26px !important;
  height: 26px !important;
  margin-top: -5px;
  margin-right: 10px;
}

.commentInput {
  flex: 1 1;
  margin-left: 6px;
  margin-left: 25px;
}

.commentList {
  padding: 10px 25px;
}

.suggestedHeader {
  margin: "8px";
}

.suggestedSubHeading {
  text-decoration-color: "#eeeeee";
  font-size: 12px;
  cursor: pointer;
}

.subHead {
  position: relative;
  float: right;
  cursor: pointer;
}

.suggestedFriendsbtns {
  display: inline-flex;
}
.addFriendBtn button {
  display: block;
  font-size: 10px;
  border: none;

  font-weight: bold;
  font-family: "Lato", sans-serif;
  cursor: pointer;
}
.removeFriendBtn button {
  display: block;
  margin: 8px;
  font-size: 10px;
  border: none;
  padding: 7.5px 0;
  font-weight: bold;
  font-family: "Lato", sans-serif;
  cursor: pointer;
}
button.frndrequest {
  color: #ffffff;
  background: #60c2e7;
  border-radius: 3px;
  width: 80px;
  padding: 8px;
}
p.frndremove {
  margin: 8px;
  color: #424242;

  border-radius: 3px;
  width: auto;
}
.socialProfileHeader {
  box-shadow: 5px 5px 6px #0000000d;
  border: 1px solid #dededfbf;
  border-radius: 4px;
  background: #fff;
  margin-bottom: 16px;
}
.profileTabs {
  margin-left: 10px;
  margin-right: 40px;
}
.profileTabsStart {
  margin-left: 100px;
  margin-right: 40px;
}
.profileTabsEnd {
  margin-left: 10px;
  margin-right: 0px;
}
.tabBottomLine {
  background: #60c2e7;
  height: 2px;
  margin-top: 8px;
  content: "";
  flex: 1;
  width: 70px;
  margin-left: 10px;
  margin-right: 40px;
}
.tabBottomLineOne {
  background: #60c2e7;
  height: 2px;
  margin-top: 8px;
  content: "";
  flex: 1;
  width: 50px;
  margin-left: 10px;
  margin-right: 40px;
}
.tabBottomLineTwo {
  background: #60c2e7;
  height: 2px;
  margin-top: 8px;
  content: "";
  flex: 1;
  width: 40px;
  margin-left: 10px;
  margin-right: 40px;
}
.tabBottomLineStart {
  background: #60c2e7;
  height: 2px;
  margin-top: 8px;
  content: "";
  flex: 1;
  width: 50px;
  margin-left: 100px;
  margin-right: 40px;
}
.tabBottomLineEnd {
  background: #60c2e7;
  height: 2px;
  margin-top: 8px;
  content: "";
  flex: 1;
  width: 90px;
  margin-left: 10px;
  margin-right: 0px;
}
.fake-input {
  position: relative;
}
.fake-input input {
  border: none;
  background-color: #fff;
  display: block;
  width: 100%;
  box-sizing: border-box;
}
.fake-input img {
  position: absolute;
  top: 2px;
  right: 5px;
  width: 30px;
  margin: 8px;
}

.topicsList {
  overflow-x: scroll !important;
  height: 100px;
}
