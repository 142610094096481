.meeting {
  /* padding-top: 90px; */
  margin-left: 3rem;
  display: flex;
  height: 90vh;
  background: white;
}

.meetingSideBar {
  flex-basis: 30%;
  overflow-y: scroll;
  border-right: 1px solid grey;
}
.meetingSideBar--Heading {
  display: flex;
  font-size: 18px;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid lightgray;
  padding-left: 1rem;
  padding-block: 2rem;
}

.meeting-link:link,
meeting-link:visited {
  color: white;
}
.meetingSidebarCard {
  /* height: 2rem; */
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  border-bottom: 1px solid lightgray;
  padding-block: 1rem;
  cursor: pointer;
}
.meetingSidebarCard h3 {
  font-size: 18px;
  width: 85%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.meetingSidebarCard p {
  font-size: 14px;
  color: gray;
  white-space: nowrap;
  width: 85%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.meetingMainBar {
  flex-basis: 70%;
  display: flex;
  flex-direction: column;
  /* background-color: gray; */
}

.meetingMainBar--heading {
  display: flex;
  /* justify-content: center;
    align-items: center; */
  height: 20%;
  /* background-color: lightgray; */
  /* border-bottom: 1px solid lightgray; */
  flex-direction: column;
  font-size: 18px;
  word-wrap: break-word;
}

.meetingMainBar--body {
  display: flex;
  padding-left: 1rem;
  /* justify-content: flex-start; */
  /* align-items: center; */
  flex-direction: column;
  height: 100%;
  /* align-items: center; */
  word-wrap: break-word;
}

.meetingMainBar-description {
  margin-top: 1rem;
  flex-basis: 50%;
}
.meeting-btn {
  background: white;
  color: black !important;
  font-weight: 700;
  border: 1px solid red;
}

.newanchor:link {
  color: white !important;
  text-decoration: none;
}

.newanchor:hover {
  color: white !important;
}

.newanchor {
  color: white !important;
}
