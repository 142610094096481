.market-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: -3rem;
  /* height: 100vh;
  padding-top: 3rem; */
  /* background: rgb(34, 193, 195); */
  /* background: linear-gradient(
      0deg,
      rgba(34, 193, 195, 1) 0%,
      rgba(45, 113, 253, 1) 100%
    ); */
}
/* Error component */
.error-container {
  text-align: center;
  font-size: 180px;
  font-family: "Catamaran", sans-serif;
  font-weight: 800;
  margin: 20px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.error-container > span {
  display: inline-block;
  line-height: 0.7;
  position: relative;
  color: #ffb485;
}
.error-container > span {
  display: inline-block;
  position: relative;
  vertical-align: middle;
}
.error-container > span:nth-of-type(1) {
  color: #d1f2a5;
  animation: colordancing 4s infinite;
}
.error-container > span:nth-of-type(3) {
  color: #f56991;
  animation: colordancing2 4s infinite;
}
.error-container > span:nth-of-type(2) {
  width: 120px;
  height: 120px;
  border-radius: 999px;
}
.error-container > span:nth-of-type(2):before,
.error-container > span:nth-of-type(2):after {
  border-radius: 0%;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: 999px;
  box-shadow: inset 30px 0 0 rgba(209, 242, 165, 0.4),
    inset 0 30px 0 rgba(239, 250, 180, 0.4),
    inset -30px 0 0 rgba(255, 196, 140, 0.4),
    inset 0 -30px 0 rgba(245, 105, 145, 0.4);
  animation: shadowsdancing 4s infinite;
}
.error-container > span:nth-of-type(2):before {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
}

.screen-reader-text {
  position: absolute;
  top: -9999em;
  left: -9999em;
}
@keyframes shadowsdancing {
  0% {
    box-shadow: inset 30px 0 0 rgba(209, 242, 165, 0.4),
      inset 0 30px 0 rgba(239, 250, 180, 0.4),
      inset -30px 0 0 rgba(255, 196, 140, 0.4),
      inset 0 -30px 0 rgba(245, 105, 145, 0.4);
  }
  25% {
    box-shadow: inset 30px 0 0 rgba(245, 105, 145, 0.4),
      inset 0 30px 0 rgba(209, 242, 165, 0.4),
      inset -30px 0 0 rgba(239, 250, 180, 0.4),
      inset 0 -30px 0 rgba(255, 196, 140, 0.4);
  }
  50% {
    box-shadow: inset 30px 0 0 rgba(255, 196, 140, 0.4),
      inset 0 30px 0 rgba(245, 105, 145, 0.4),
      inset -30px 0 0 rgba(209, 242, 165, 0.4),
      inset 0 -30px 0 rgba(239, 250, 180, 0.4);
  }
  75% {
    box-shadow: inset 30px 0 0 rgba(239, 250, 180, 0.4),
      inset 0 30px 0 rgba(255, 196, 140, 0.4),
      inset -30px 0 0 rgba(245, 105, 145, 0.4),
      inset 0 -30px 0 rgba(209, 242, 165, 0.4);
  }
  100% {
    box-shadow: inset 30px 0 0 rgba(209, 242, 165, 0.4),
      inset 0 30px 0 rgba(239, 250, 180, 0.4),
      inset -30px 0 0 rgba(255, 196, 140, 0.4),
      inset 0 -30px 0 rgba(245, 105, 145, 0.4);
  }
}
@keyframes colordancing {
  0% {
    color: #d1f2a5;
  }
  25% {
    color: #f56991;
  }
  50% {
    color: #ffc48c;
  }
  75% {
    color: #effab4;
  }
  100% {
    color: #d1f2a5;
  }
}
@keyframes colordancing2 {
  0% {
    color: #ffc48c;
  }
  25% {
    color: #effab4;
  }
  50% {
    color: #d1f2a5;
  }
  75% {
    color: #f56991;
  }
  100% {
    color: #ffc48c;
  }
}

/* demo stuff */
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.error-wrapper {
  background-color: #416475;
  margin-bottom: 50px;
  height: 100vh;
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  top: -22px;
  bottom: 0;
}

.error-wrapper h1 {
  text-align: center;
  margin: 30px 15px;
  color: white;
}
.zoom-area {
  max-width: 490px;
  margin: 30px auto 30px;
  font-size: 19px;
  text-align: center;
}
.link-container {
  text-align: center;
}
.error-wrapper a.more-link {
  text-transform: uppercase;
  font-size: 13px;
}

/* Error componet end */
.form-wrapper {
  background: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  width: 95%;
  border-radius: 1rem;
}
.form-wrapper > div:first-child {
  width: 100%;
  font-weight: bold;
  padding: 1rem;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  border-bottom: 1px solid brown;
}
.form-wrapper h4 {
  text-align: center;
  font-weight: bold;
  font-size: 22px;
  color: #a00030;
}
.form-wrapper p {
  font-size: 12px;
  font-weight: 500;
  color: gray;
}
.custom-form label {
  font-weight: bold;
  font-size: 17px;
}
.market-text-area {
  border: 1px solid gray;
  border-radius: 5px;
  width: 100%;
  padding: 1rem;
}
.bg-color {
  color: brown;
}
.kb-data-box {
  width: 100%;
  flex: 1;
}

.kb-modal-data-title {
  margin-bottom: 10px;
}
.kb-data-title h6 {
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 600;
}
.kb-file-upload {
  margin-bottom: 20px;
}
.file-upload-box {
  border: 1px dashed #b6bed1;
  background-color: #f0f2f7;
  border-radius: 4px;
  min-height: 150px;
  position: relative;
  overflow: hidden;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #8194aa;
  font-weight: 400;
  font-size: 15px;
}
.file-upload-box .file-upload-input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}
.file-link {
  color: #475f7b;
  text-decoration: underline;
  margin-left: 3px;
}
.file-upload-box .file-link:hover {
  text-decoration: none;
}
.file-atc-box {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.file-image {
  /* width: 130px;
    min-width: 130px;
    height: 85px;
    min-height: 85px; */
  width: 130px;
  height: 85px;
  background-size: cover;
  border-radius: 5px;
  margin-right: 15px;
  background-color: #eaecf1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  color: #475f7b;
  padding: 3px;
}
.file-image img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 4px;
}
.file-detail {
  flex: 1;
  width: calc(100% - 210px);
}
.file-detail h6 {
  word-break: break-all;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 8px;
}
.file-detail p {
  font-size: 12px;
  color: #8194aa;
  line-height: initial;
  font-weight: 400;
  margin-bottom: 8px;
}
.file-actions {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
}
.file-action-btn {
  font-size: 12px;
  color: #8194aa;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  text-decoration: underline;
  margin-right: 15px;
  cursor: pointer;
}
.file-action-btn:hover {
  color: #3d546f;
  text-decoration: underline;
}
.file-atc-box:last-child {
  margin-bottom: 0;
}
.f-image {
  height: 100%;
  width: 100%;
  display: block;
  object-fit: cover;
  border-radius: 9px;
  margin-bottom: 1rem;
}
.form-bg {
  background: linear-gradient(
    0deg,
    rgba(34, 193, 195, 1) 0%,
    rgba(45, 113, 253, 1) 100%
  );
}
.refer-phone-btn {
  background: white;
  color: black;
  border-radius: 5px;
  font-size: 13px;
  padding: 10px;
  font-weight: bold;
  cursor: pointer;
}
.text-input-v2 {
  width: 100%;
  border: 1px solid gainsboro;
  border-radius: 5px;
  height: 44px;
  margin-top: 3px;
  padding: 0 12px;
}
.service-otp > input {
  border: 1px solid black;
  color: black;
  text-align: center;
  height: 49px;
}
.service-landing-wrapper {
  background: #f9f9fc;
  min-height: 100vh;
}

.service-sec-1 {
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: center;
}
.service-sec-1-img {
  /* width: 36rem; */
  flex-shrink: 0;
  border-radius: 7px;
  overflow: hidden;
  border-radius: 9rem 2rem;
}
.service-sec-1 h2 {
  font-weight: bold;
  margin-bottom: 2rem;
  font-size: 2rem;
}
.service-btn {
  margin-top: 2rem;
  border-radius: 1rem !important;
  padding: 12px !important;
}
.service-wrap {
  width: 80%;
  margin: 0 auto;
}
.service-sec-2 {
  padding-top: 4rem;
  width: 50%;
  margin: auto;
  text-align: center;
  min-height: 100vh;
  place-content: center;
  display: grid;
  gap: 1rem;
}

.service-sec-2 h6 {
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: 3rem;
}
.service-types {
  list-style: none;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
}
.service-types svg {
  color: white;
  width: 6rem;
  height: auto;
  background: #a00030;
  border-radius: 50%;
  padding: 1rem;
}
.font-bold {
  font-weight: bold;
}
@media (max-width: 960px) {
  .service-sec-1 {
    flex-direction: row-reverse;
    flex-wrap: wrap;
    height: 100%;
  }
  .service-sec-1 > div:nth-child(2) {
    width: 100%;
  }
  .service-wrap {
    width: 95%;
    margin: 0 auto;
  }
  .service-sec-2 {
    height: 100%;
    width: 90%;
  }
}

.font-14 {
  font-size: 14px;
}

.service-card {
  min-height: 21rem;
  width: 18rem;
  background: white;
  text-align: center;
  position: relative;
  /* overflow: hidden; */
  border-radius: 5px;
  transition: all 0.5s ease-in;
  cursor: pointer;
}
.service-card > div {
  background: linear-gradient(90deg, #000940 0%, #a00030 100%);
  width: 100%;
  height: 3rem;
  position: absolute;
  text-align: center;
  bottom: 0;
  border-radius: 5rem 5rem 0rem 0rem;
  transition: all 0.5s ease-in;
}
.service-card:hover > div {
  height: 100%;
  width: 100%;
  border-radius: 0%;
}
.service-card:hover {
  color: white;
}
.service-info-wrap {
  display: flex;
  min-height: 100vh;
}
.service-info-wrap {
  display: flex;
  padding-top: 5rem;
  gap: 2rem;
}
.service-landing-wrapper h6 {
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 1rem;
  /* margin-top: 2rem; */
}
.service-info-wrap li {
  font-size: 16px;
  color: gray;
}
.service-card p {
  color: gray;
}
.service-card > section:nth-child(1) {
  z-index: 10;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid brown;
  overflow: hidden;
  border-radius: 5px;
}
.service-card > section:nth-child(1) ul {
  padding: 14px;
  text-align: start;
}

.service-card:hover p {
  color: white;
}
.gap-1 {
  gap: 1rem;
}
.service-wis {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding-top: 2rem; */
  gap: 2rem;
  flex-wrap: wrap;
  margin-bottom: 3rem;
}
.service-wis svg {
  /* background: linear-gradient(90deg, #000940 0%, #a00030 100%); */
  width: 4rem;
  height: auto;
  padding: 14px;
}
.service-wis div {
  border-radius: 50%;
  color: white;
}
.service-head-under {
  /* font-size: 1.5rem; */
  border-bottom: 2px solid brown;
  width: fit-content;
  padding: 12px 0px;
  margin: auto;
  font-weight: bold;
  color: #000940;
}
.service-tag {
  border-radius: 24px;
  padding: 7px 17px;
  /* font-weight: bold; */
  color: black;
  transition: all 0.5s ease-in-out;
  border: 1px solid brown;
  font-size: 12px;
  background: white;
}
.service-tag:hover {
  /* background: brown; */
}
.service-bg-gradient {
  /* background: linear-gradient(3deg, #000940 0%, #a00030 100%); */
  color: black !important;
}
.service-form {
  max-width: 33rem;
  text-align: start;
  padding: 2rem 2rem;
  /* border: 1px solid brown; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.text-crass {
  text-decoration: line-through;
  color: gray;
  margin-right: 5px;
  font-size: 18px;
}
.service-box {
  max-width: 21rem;
  border: 2px solid brown;
  border-radius: 35px;
  padding: 1.5rem;
  background: white;
  min-height: 19rem;
}
.service-box p {
  margin-top: 2rem !important;
  font-size: 15px;
  color: gray;
}
.service-box h5 {
  border: 1px solid gray;
  width: -moz-fit-content;
  width: fit-content;
  padding: 13px;
  border-radius: 5px;
  font-weight: bold;
  color: brown;
}
.service-card li {
  font-weight: bold;
  font-size: 14px;
}
.s-heading {
  font-size: 3rem;
  font-weight: 700;
  color: #a00030;
}
.service-img-grid {
  gap: 3rem;
  align-items: center;
  display: flex;
}
.service-img-grid-head h2 {
  font-size: 2rem;
  max-width: 30rem;
}
.slider-arrow {
  position: absolute;
  top: 50%;
  background: #00000063;
  width: 3rem;
  height: 3rem;
  padding: 5px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.slider-arrow svg {
  width: 25px;
  height: auto;
  margin-left: 6px;
}

.slider-arrow.a-left {
  left: -3rem;
}
.slider-arrow.a-right {
  right: -3rem;
}
.carouselv2-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  margin: auto;
  position: relative;
}

.carouselv2 {
  position: relative;
  overflow: hidden;
  /* width: 900px; */
  /* min-height: 34rem; */
  border-radius: 20px;
}

.carouselv2-inner {
  display: flex;
  transition: transform 0.5s ease-in-out;
  /* gap: 1rem; */
  /* overflow: hidden; */
}

.carouselv2-item {
  /* min-width: 100%; */
  /* min-width: 50%; */
  overflow: hidden;
  padding: 1rem;
  background: white;
  margin: 1rem;
  border-radius: 10px;
  height: 100%;
}
.s-min-50 {
  min-width: 50%;
  overflow: hidden;
  padding: 1rem;
}

.carouselv2-item img {
  width: 100%;
  height: 500px;
  object-fit: cover;
}

.carouselv2-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  color: white;
  font-size: 24px;
}

.prevv2 {
  left: -5rem;
}

.nextv2 {
  right: -5rem;
}

.carouselv2-indicators {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 5px;
}

.carouselv2-indicators span {
  width: 8px;
  height: 8px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  cursor: pointer;
  transition: width 0.3s ease-in-out;
}

.carouselv2-indicators .active {
  width: 16px;
  background: white;
}
.w-fit-content {
  width: fit-content;
}
.sub-dis-image {
  position: absolute;
  width: 7rem;
  height: 7rem;
  top: -3rem;
  left: -3rem;
  z-index: 1000;
  rotate: 345deg;
}
.service-form-heading {
  max-width: 37rem;
  text-align: start;
  font-size: 15px;
  color: black;
}

.service-button {
  font-size: 2rem;
  background: none;
  border: none;
  position: relative;
  /* to do, calc arrow-stripes so they repeat nicely on animation loop   */
  --padding-block: 1rem;
  --padding-inline: 2rem;
  --arrow: 5rem;
  --arrow-stripes: 0.8rem;

  padding: var(--padding-block) var(--padding-inline);
  padding-right: calc(var(--padding-inline) + var(--arrow));
  filter: drop-shadow(4px 4px 4px hsl(0 0% 0% / 0.5));
  color: white;
  border-radius: 999vmax 0 0 999vmax;
  cursor: pointer;
  transition: all 125ms;
}
.service-button:active {
  scale: 0.975;
}
.service-button:hover::after {
  animation-play-state: paused;
}
.service-button::after {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: inherit;
  z-index: -1;
  background-color: green;
  background-image: linear-gradient(transparent 50%, hsl(0 0% 0% / 0.5) 0);
  animation: mask-position 5s linear infinite;

  --conic-stops: black 0 90deg, #0000 0;
  --linear-stops: #0000 0px, black 1px var(--arrow-stripes),
    #0000 calc(var(--arrow-stripes) + 1px) calc(var(--arrow-stripes) * 2);
  --mask-image: conic-gradient(from 225deg at right, var(--conic-stops)),
    conic-gradient(from 225deg at right, var(--conic-stops)),
    repeating-linear-gradient(-135deg, var(--linear-stops)),
    repeating-linear-gradient(-45deg, var(--linear-stops));
  --mask-position: 0 0, 0 0, 0 0, 0 100%;
  --mask-position-to: 0, 0, -100% 0, -100% 100%;
  --mask-size: calc(100% - var(--arrow)) 100%, 100%, 200% 50%, 200% 50%;
  --mask-repeat: no-repeat, repeat, repeat-x, repeat-x;

  -webkit-mask-image: var(--mask-image);
  -webkit-mask-position: var(--mask-position);
  -webkit-mask-size: var(--mask-size);
  -webkit-mask-repeat: var(--mask-repeat);
  -webkit-mask-composite: source-over, source-out, source-over, source-over;

  mask-image: var(--mask-image);
  mask-position: var(--mask-position);
  mask-size: var(--mask-size);
  mask-repeat: var(--mask-repeat);
  mask-composite: add, subtract, add, add;
}
.carouselv3-card {
  background: white;
  border-radius: 14px;
  overflow: hidden;
}
.carouselv3-card > div:nth-child(1) {
  height: 19rem;
  width: 100%;
}
@media (max-width: 739px) {
  .service-padding {
    width: 85%;
    padding: 0;
    margin: 0 auto;
  }
}
.service-image {
  align-items: center;
  justify-content: center;
  display: flex;
}

/* Base styles for the button */

/* Base styles for the button */
.getStartedWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.getStartedButton {
  background-color: linear-gradient(90deg, #000940 0%, #a00030 100%);
  border: 1px solid #ffb485;
  color: #fff; /* White text */
  padding: 9px 15px;
  text-align: center;
  text-decoration: none;
  font-size: 1rem; /* Base font size */
  cursor: pointer;
  border-radius: 50px; /* Rounded shape on both sides */
  margin-top: 30px;
}

.fee {
  font-size: 1rem; /* Base font size */
  margin: 0 5px; /* Spacing between elements */
}

.fee strong {
  font-weight: bold;
}

.text-crass {
  font-size: 1.2rem; /* Slightly larger font size for $4999 */
  font-weight: bold;
}
