.home-container {
  /* position: relative; */
  /* height: 100vh; */
}
.home-container .user-profile {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.4rem;
  font-weight: bold;
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.home-container .chat-card {
  position: fixed;
  bottom: 0;
  right: 0rem;
  /* width: 30vw; */
  background-color: #fff;
  z-index: 5;
}
.home-container .card-header {
  padding: 1rem 0.6rem;
  display: flex;
  justify-content: space-between;
}
.home-container .card-header .img-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}
.home-container .card-header .img-container .avatar-img {
  border-radius: 50%;
  width: 2.4rem;
  height: 2.4rem;
}
.home-container .card-header .icons-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  /* background-color: green; */
}
.home-container .chatApp-icon {
  cursor: pointer;
}
.home-container .open {
  display: block;
}
.home-container .close {
  display: none;
}
.home-container .card-body {
  /* border-top: 1px solid #788fa5; */
  /* padding: 0.6rem 1rem; */
  /* height:40vh; */
}
.home-container .card-body .search-filter {
  /* padding: 1rem 0; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 3rem;
  padding: 0.6rem;
  background-color: #eef3f8;
  border-radius: 2px;
}
.home-container .card-body .search-filter div {
  display: flex;
  align-items: center;
  gap: 0.4rem;
}
.home-container .card-body .search-filter div input {
  background: none;
  border: none;
  outline: none;
  flex: 1;
}
.home-container .card-body .conversation-list {
  /* max-height: 70vh; */
  margin-top: 1rem;
  min-height: 50vh;
  max-height: 60vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
.home-container .card-body .conversation-list .conversation-list-item {
  padding: 10px 0rem;
  border-bottom: 1px solid #eef3f8;
  cursor: pointer;
  display: flex;
  align-items: center;
  /* gap: 0.6rem; */
  flex: 1;
}
.home-container
  .card-body
  .conversation-list
  .conversation-list-item
  .conversation-user-name-details {
  display: flex;
  flex-direction: column;
  /* gap: 0.8rem; */
  flex: 1;
  position: relative;
}
.home-container
  .card-body
  .conversation-list
  .conversation-list-item
  .conversation-user-name-container {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

/* .conversation-user-name {
  font-size: 16px !important;
} */

.home-container
  .card-body
  .conversation-list
  .conversation-list-item
  .conversation-user-name-container
  span {
  font-size: 10px;
  color: gray;
}
.home-container
  .card-body
  .conversation-list
  .conversation-list-item
  .conversation-user-avatar {
  /* width: 3.6rem;
  height: 3.6rem; */
  border-radius: 50%;
  object-fit: contain;
}
.home-container
  .card-body
  .conversation-list
  .conversation-list-item
  .conversation-last-message {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  flex: 1;
  gap: 1rem;
}

.conversation-last-message {
  color: "#898989" !important;
}
.home-container
  .card-body
  .conversation-list
  .conversation-list-item
  .conversation-last-message
  span:nth-child(2) {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 10rem;
  flex: 1;
}
.home-container
  .card-body
  .conversation-list
  .conversation-list-item
  .conversation-last-message
  span:last-child {
  /* margin-left: auto; */
}
.unseen-count {
  margin-left: auto;
  background: brown;
  color: #fff !important;
  border-radius: 50%;
  padding: 2px 3px;
  font-weight: 700;
  position: absolute;
  bottom: 0;
  right: 9px;
  text-align: center;
  min-width: 20px;
  z-index: 100;
}

.message-check {
  display: inline-block;
  transform: rotate(45deg);
  height: 18px;
  width: 9px;
}

.message-seen {
  border-bottom: 2px solid #a00030;
  border-right: 3px solid #a00030;
}
.message-deliver {
  border-bottom: 2px solid gray;
  border-right: 3px solid gray;
}
.message-status {
  position: absolute;
  right: 19px;
}

.notification-puls {
  animation: puls 2s ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes puls {
  40% {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.3, 1.3, 1.3);
  }

  55% {
    transform: scale3d(1, 1, 1);
  }

  60% {
    transform: scale3d(1.3, 1.3, 1.3);
  }

  65% {
    transform: scale3d(1, 1, 1);
  }
}

.rounded-notification {
  margin-bottom: 21px;
  background: brown;
  border-radius: 50%;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  min-width: 19px;
  height: auto;
  text-align: center;
  padding: 1px 4px;
}
