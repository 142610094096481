.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
  gap: 10px;
}

.image-item {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  align-items: center;
  background: black;
  border-radius: 5px;
}

.image-item img {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease-in-out;
}

.image-item.zoomed img {
  /* transform: scale(1.2); */
}

.single-image {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  border-radius: 5px;
  max-height: 40rem;
}

.single-image img {
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease-in-out;
  object-fit: cover;
}

.single-image.zoomed img {
  /* transform: scale(1.2); */
}
.count-item {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 33px;
  color: white;
  background: rgb(0 0 0 / 60%);
  font-weight: 600;
}
/* .react-fb-image-grid {
  margin: auto;
}

.react-fb-image-grid * {
  margin: 0;
  box-sizing: border-box;
  padding: 0;
}

 

.borders {
  border: none;
  border-radius: 0;
}

.background-react-fb-image-grid {
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}

.height-one {
  height: 100%;
  width: 100% !important;
}

.height-two {
  height: 65%;
  width: 100% !important;
}

.background-react-fb-image-grid-cover {
  background-color: #222;
  opacity: 0.8;
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
}

.cover-text {
  right: 0;
  left: 0;
  bottom: 0;
  color: white;
  font-size: 1.2em !important;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0%, -50%);
  -ms-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
  text-align: center;
}

.cover-text > p {
  margin: 0;
  position: absolute;
  font-size: 1.5em !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.slide {
  height: 0;
  bottom: 100%;
  transition: 0.4s ease;
  overflow: hidden;
}

.borders:hover .slide {
  bottom: 0;
  height: auto;
}

.borders:hover .animate-text {
  top: 62%;
} */
