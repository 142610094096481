/* .connectBtn {
  padding: 20px 0px 15px 0px;
} */
.connectBtn {
  padding: 20px 0px !important;
}
.profilePhoto img {
  width: 70px;
  height: 70px;
}

.profilePhoto {
  margin-top: -35px;
}

.networkDetails h3 {
  text-align: center;
  font: nozrmal normal bold 18px/22px, sans-serif;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
}

.bpc-img-wrapper img {
  width: 75px;
  height: 75px;
}

.chat-btn {
  border-radius: 3px;
  padding: 5px 35px;
  font-weight: normal;
  font-size: 11px;
}

.update-btn {
  border: 1px solid #a00030;
  border-radius: 3px;
  padding: 5px 15px;
  color: white;
  float: right;
  font-weight: normal;
  font-size: 11px;
  background: #a00030;
  margin-right: 20px;
}

.slotCard {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d4d4d5;
  border-radius: 4px 4px 0px 0px;
  padding: 20px 10px 10px 10px;
  padding-top: 30px;
}

.timeline .dx-scheduler-cell-sizes-horizontal {
  width: 20px !important;
  max-width: 20px !important;
  white-space: normal !important;
  word-wrap: break-word !important;
}
.timeline .dx-scheduler-header-panel-cell {
  width: 20px !important;
  max-width: 20px !important;
  white-space: normal !important;
  word-wrap: break-word !important;
}
.timeline .dx-scheduler-cell-sizes-vertical {
  max-height: 40px !important;
}
.timeline .dx-scheduler-header-panel {
  width: unset;
}

/* .avaliableTextTeaching {
    padding-bottom: 20px !important;
  } */
.updateMyCalenderText {
  color: #707070 !important;
  text-align: center !important;
  font-size: 21px !important;
  font-family: "Lato", sans-serif !important;
}
.booking-profile-teaching-card {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d4d4d5;
  border-radius: 4px 4px 0px 0px;
  padding: 30px;
}
.BooktimeMainContainerTeaching {
  padding: 0 15px !important;
}
span.MuiIconButton-label {
  margin: 0px;
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 600px !important;
    margin: 1.75rem auto;
  }
}
