h1 {
  color: gray;
}

.PropName {
  font-weight: bold;
  color: #6264a7;
}
.Logo {
  font-size: 45pt;
  color: #6264a7;
}
.Error {
  color: red;
}
