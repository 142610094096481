.friend-avatar-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (min-width: 768px) {
  .friend-avatar-xxl {
    width: 8rem !important;
    height: 8rem !important;
  }
}

@media (min-width: 0) and (max-width: 399px) {
  .friend-avatar-xxl {
    height: 5.125rem !important;
    width: 5.125rem !important;
  }
}

.friend-avatar {
  height: 3rem;
  width: 3rem;
  position: relative;
  display: inline-block;
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}

.friend-cover-img {
  /* background-image: url("../../images/cover_page.png"); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
}

.h-200px {
  height: 200px !important;
  object-fit: cover;
}
.rounded-top {
  border-top-left-radius: 0.4rem !important;
  border-top-right-radius: 0.4rem !important;
}

.ms-sm-4 {
  margin-left: 1.5rem !important;
}
@media (min-width: 576px) {
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
}

.list-inline {
  padding-left: 0 !important;
  list-style: none !important;
}

@media (min-width: 576px) {
  .text-sm-start {
    text-align: left !important;
  }
}

.friend-text-correction {
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5rem;
  color: #676a79;
  text-align: var(--bs-body-text-align);
  -webkit-text-size-adjust: 100%;
}

.nav-link {
  /* display: block !important; */
  display: flex;
  align-items: center;
  padding-top: 0.1rem !important;
  padding-bottom: 0.1rem !important;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
  color: #000000;
}

.greenLabelConnection {
  color: #21ba45 !important;
  /* border-radius:30px !important; */
  /* padding-top:  4px !important;
    padding-bottom: 4px !important; */
  margin-left: 0.5rem;
  font-size: 9px !important;
  background: #c4eece !important;
  padding: 6px 10px !important;
  float: right !important;
  border-radius: 4px !important;
}

.about-card {
  position: relative;
  display: flex;
  -webkit-box-orient: vertical;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.09);
  border-radius: 0.4rem;
}

.about-card-title {
  margin-bottom: 0.5rem;
  font-weight: 700;
  line-height: 1.25;
  color: #14191e;
  color: #212529;
  text-align: left;
  font-size: 1.171875rem;
}

.about-card-header:first-child {
  border-radius: calc(0.4rem - 1px) calc(0.4rem - 1px) 0 0;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.border-0 {
  border: 0 !important;
}
.about-card-header {
  padding: 1.25rem 1.25rem;
  padding-bottom: 1.25rem;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}

.about-body-p {
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #676a79;
}

.about-mb-2 {
  margin-bottom: 0.5rem !important;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #676a79;
}

.exp-card {
  position: relative;
  display: flex;
  -webkit-box-orient: vertical;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.09);
  border-radius: 0.4rem;
}

.exp-small {
  font-weight: 400;
  font-size: 0.875em;
  line-height: 1.5;
  color: #676a79;
  margin-top: 0;
  margin-bottom: 1rem !important;
}

.exp-card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.exp-card-title {
  font-weight: 700;
  line-height: 1.25;
  color: #14191e;
  color: #212529;
  text-align: left;
  font-size: 1.171875rem;
}

.exp-card-header {
  padding-top: 1.25rem !important;
  padding-bottom: 0rem !important;
  padding-left: 1.25rem !important;
  padding-right: 2rem !important;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}

.connection-side-row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  /* margin-top: 1rem;
    margin-right: 1rem;
    margin-left: 1rem; */
}

.main-card-footer {
  padding: 1.25rem 1.25rem;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  background-color: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.09);
}

.friendlist-w-100 {
  width: 100% !important;
  overflow: hidden;
}

.friendlist-avatar {
  height: 3rem;
  width: 3rem;
  position: relative;
  display: inline-block;
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}

.friendlist-me-3 {
  margin-right: 1rem !important;
}

@media (min-width: 768px) {
  .friendlist-mb-md-0 {
    margin-bottom: 0 !important;
  }
}

.friendlist-mb-3 {
  margin-bottom: 1rem !important;
}

/* .friendlist-mb-4 {
    margin-bottom: 1.5rem !important;
  } */
.friendlist-align-items-center {
  -webkit-box-align: center !important;
  align-items: center !important;
}

.friendlist-card-body {
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  padding-top: 1.25rem;
  padding-bottom: 0rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.nav.nav-bottom-line .nav-link.active {
  background: transparent;
  color: #a00030;
  border-bottom: 3px solid #a00030;
  display: block !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.nav-link-body {
  margin-bottom: 0.5rem !important;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #676a79 !important;
  text-align: center !important;
}

.nav-link-body:hover {
  margin-bottom: 0.5rem !important;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #676a79 !important;
  text-align: center !important;
}

.nav-link-body-active {
  margin-bottom: 0.5rem !important;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #0f6fec !important;
  text-align: center !important ;
}

.nav-link-body-active:hover {
  margin-bottom: 0.5rem !important;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #0f6fec !important;
  text-align: center !important ;
}

.friendlist-col-6 {
  -webkit-box-flex: 0;
  flex: 0 0 auto;
  width: 50%;
}

.friend-connection-pt-0 {
  padding-top: 0 !important;
}
.friend-connection-position-relative {
  position: relative !important;
}
.friend-connection-card-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem 1.25rem;
  padding-top: 1.25rem;
}

.fcc-pb-0 {
  padding-bottom: 0 !important;
}
.fcc-p-2 {
  padding: 0.5rem !important;
  padding-bottom: 0.5rem;
}
.fcc-card-body {
  -webkit-box-flex: 1;
  /* -ms-flex: 1 1 auto; */
  flex: 1 1 auto;
  /* padding: 1.25rem 1.25rem; */
}

.fcc-h-100 {
  height: 100% !important;
}

.ffc-shadow-none {
  box-shadow: none !important;
}
.fcc-card {
  position: relative;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.09);
  border-radius: 0.4rem;
  overflow: hidden;
}

.fcc-avatar-img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.fcc-avatar-xl {
  height: 5.125rem;
  width: 5.125rem;
}
.fcc-avatar {
  position: relative;
  display: inline-block;
}

.post-avatar-img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.post-avatar-story {
  position: relative;
  padding: 4px;
  /* z-index: 1; */
}
.post-avatar {
  height: 3rem;
  width: 3rem;
  display: inline-block;
  flex-shrink: 0 !important;
}
.post-me-2 {
  margin-right: 0.5rem !important;
}
.postCardImg-me-2 {
  margin-right: 0.5rem !important;
}
.post-avatar-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.post-nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.post-nav.post-nav-divider .post-nav-item {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.post-mb-0 {
  margin-bottom: 0 !important;
}

.post-h6 {
  font-size: 0.9375rem;
}

.post-small {
  font-weight: 400;
  font-size: 0.775em;
  color: #676a79 !important;
}

.post-card-body {
  flex: 1 1 auto;
  padding: 1.25rem 1.25rem;
}

textarea.post-form-control {
  min-height: calc(2.5em + 1rem + 2px);
}
.post-bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}
.post-pe-4 {
  padding-right: 1.5rem !important;
}

.post-w-100 {
  width: 100% !important;
}
.post-position-relative {
  position: relative !important;
}

.post-form-control {
  display: block;
  width: 101%;
  padding: 0.5rem 1rem;
  padding-right: 1rem;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #29292e;
  background-clip: padding-box;
  border: 1px solid #e1e4e6;
  appearance: none;
  border-radius: 0.4rem;
}

.description-h5 {
  font-weight: 700 !important;
  line-height: 1.25 !important;
  color: #14191e !important;
  font-size: 1.171875rem !important;
}

.post-nav.post-nav-divider .post-nav-item + .post-nav-item::before {
  content: " ";
  color: inherit;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  opacity: 0.8;
}

.post-card-img,
.post-card-img-bottom {
  border-bottom-right-radius: calc(0.4rem - 1px);
  border-bottom-left-radius: calc(0.4rem - 1px);
}
.post-card-img,
.post-card-img-top {
  border-top-left-radius: calc(0.4rem - 1px);
  border-top-right-radius: calc(0.4rem - 1px);
}
.post-card-img,
.post-card-img-top,
.post-card-img-bottom {
  width: 100%;
}

.comment-small {
  font-weight: 400;

  font-size: 0.875em;
}
.comment-nav-stack {
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  gap: 0.5rem;
  min-height: 2rem;
}
.comment-py-3 {
  /* padding-top: 1rem !important; */
  /* padding-bottom: 1rem !important; */
}
.comment-nav {
  flex-wrap: wrap;
  margin-bottom: 0;
  list-style: none;
}

.comment-nav-link.active {
  /* margin-bottom: 0.5rem !important; */
  font-size: 0.875em;
  font-weight: 400;
  line-height: 1.5;
  color: #a00030 !important;
}

.comment-nav-link {
  /* margin-bottom: 0.5rem !important; */
  font-size: 0.875em;
  font-weight: 400;
  line-height: 1.5;
  color: #676a79 !important;
}

.comment-nav-link:hover {
  /* margin-bottom: 0.5rem !important; */
  font-size: 0.875em;
  font-weight: 400;
  line-height: 1.5;
  color: #676a79 !important;
}
.comment-nav-link.active:hover {
  /* margin-bottom: 0.5rem !important; */
  font-size: 0.875em;
  font-weight: 400;
  line-height: 1.5;
  color: #a00030 !important;
}

.comment-name {
  color: #14191e;
  font-size: 0.9375rem;
  font-weight: 700;
  line-height: 1.25;
}

.load-more-comment {
  font-size: 0.8125rem !important;
  border-radius: 0.2rem !important;
  color: #676a79 !important;
  font-weight: 500 !important;
  text-align: center !important;
}

.connectionredLabel {
  color: #ba2121 !important;
  /* border-radius:30px !important; */
  /* padding-top:  4px !important;
          padding-bottom: 4px !important; */
  font-size: 12px !important;
  background: #eec4c4 !important;
  padding: 6px 10px !important;
  float: right !important;
  margin-left: 12px !important;
  border-radius: 4px !important;
  width: 100px !important;
}

.connectiongreenLabel {
  color: #21ba45 !important;
  font-size: 12px !important;
  background: #c4eece !important;
  padding: 6px 10px !important;
  margin-left: 12px !important;
  float: right !important;
  width: 100px !important;
  border-radius: 4px !important;
}
.m-l-0 {
  margin-left: 0 !important;
}
.connectiongrayLabel {
  color: #252525 !important;
  font-size: 12px !important;
  background: #eaebea !important;
  padding: 6px 10px !important;
  margin-left: 12px !important;
  float: right !important;
  width: 100px !important;
  border-radius: 4px !important;
}
.connectionblueLabel {
  color: #0f6fec !important;
  /* border-radius:30px !important; */
  /* padding-top:  4px !important;
          padding-bottom: 4px !important; */
  font-size: 12px !important;
  background: #cddbee !important;
  padding: 6px 10px !important;
  margin-left: 12px !important;
  float: right !important;
  width: 100px !important;
  border-radius: 4px !important;
}
.report-btn {
  /* color: #0f6fec !important; */
  /* border-radius:30px !important; */
  /* padding-top:  4px !important;
          padding-bottom: 4px !important; */
  font-size: 12px !important;
  background: #ece75f !important;
  padding: 6px 10px !important;
  /* margin-left: 12px !important; */
  float: right !important;
  width: 100px !important;
  border-radius: 4px !important;
}
.connectionListredLabel {
  font-size: 12px !important;
  background: #ba2121 !important;
  padding: 6px 10px !important;
  margin-left: 12px !important;
  border-radius: 4px !important;

  border: #ba2121 !important;
}

.connectionListgreenLabel {
  font-size: 12px !important;
  background: #21ba45 !important;
  padding: 6px 10px !important;
  margin-left: 12px !important;
  border-radius: 4px !important;

  border: #21ba45 !important;
}

.connectionListgrayLabel {
  font-size: 12px !important;
  background: #252525 !important;
  padding: 6px 10px !important;
  margin-left: 12px !important;
  border-radius: 4px !important;

  border: #252525 !important;
}

.connectionListblueLabel {
  font-size: 12px !important;
  background: #0f6fec !important;
  padding: 6px 10px !important;
  margin-left: 12px !important;
  border-radius: 4px !important;
  border: #0f6fec !important;
}

.profileInfoOverview {
  font-size: 0.9375rem !important;
  margin-top: 0 !important;
  margin-bottom: 0.5rem !important;
  font-weight: 700 !important;
  line-height: 1.25 !important;
  color: #14191e !important;
}
.nav-item-post-card:hover {
  background: #80808033;
}
/* .nav-item-post-card > a {
  transition: all 0.3s ease-in-out;
} */
.nav-item-post-card {
  cursor: pointer;
  border-radius: 5px;
  padding: 0.5rem;
}
