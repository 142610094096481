/* src/PremiumSubscriptionActivation.css */

.premium-subscription-activation {
    /* max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9; */
    text-align: center;
}

.premium-subscription-activation h2 {
    margin-bottom: 20px;
    color: #333;
}

.premium-subscription-activation p {
    margin-bottom: 20px;
}

.premium-subscription-activation ul {
    text-align: left;
    margin-bottom: 20px;
}

.subscription-plans {
    display: flex;
    justify-content: space-around;
    margin-bottom: 50px;
}

.plan-card {
    border: 1px solid #ccc;
    border-radius: 3rem 3rem 42px 3px;;
    
    width: 25%;
    cursor: pointer;
    transition: all 0.3s ease;
    color: white;
}

.plan-card:hover {
    border-color: #a00030
}

.plan-card.selected {
    border-color: #a00030;
    background-color: #e7f3ff;
}

.premium-subscription-activation form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.premium-subscription-activation button {
    padding: 10px 20px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.premium-subscription-activation button:hover {
    background-color: #218838;
}

.premium-subscription-activation p {
    color: black;
    margin-top: 20px;
}


.hight-badgeName-amount{
    background: linear-gradient(90deg, #000940 0%, #a00030 100%);
    border-radius: 60px;
}