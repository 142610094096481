.wrapper-forum {
  max-width: 100%;
  padding: 0 1.5em;
  background-color: #f8f9fa !important;
  border: 1px solid lightgray;
  border-radius: 0.5em;
  overflow: hidden;
}
.wrapper-forum:hover,
.wrapper-forum.focus {
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
}

.search {
  display: flex;
  height: 2.1em;
  align-items: center;
}
.search input {
  flex: 1;
  width: 100%;
  border: 0;
  background-color: #f8f9fa !important;
}
.search i {
  color: tomato;
}

:focus {
  outline: none;
}
.Dropdown-menu p {
  text-transform: capitalize;
  line-height: 1.7;
  cursor: pointer;
  text-align: center;
}
.Dropdown-menu p:hover {
  background: whitesmoke;
}

.Dropdown-menu {
  top: 100%;
  left: 18px;
  z-index: 1;
  /* min-width: 21rem; */
  padding: 8px 0;
  margin: 7px 0;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 2px 6px rgb(0 0 0 / 35%);
  list-style: none;
  /* text-align: left; */
  color: #111;
  position: absolute;
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: 7rem;
  min-width: 90%;
}

.result {
  display: flex;
  align-items: center;
}
.result b {
  margin-right: 0.5em;
}
.result span {
  margin: 0 0.5em;
  width: 1em;
  height: 1em;
  border-radius: 0.5em;
}

@keyframes enlarge {
  to {
    max-height: 10em;
  }
}
button[type="search"] {
  text-indent: -999px;
  overflow: hidden;
  width: 40px;
  padding: 0;
  margin: 0;
  border: 1px solid transparent;
  border-radius: inherit;
  background: transparent
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
    no-repeat center;
  cursor: pointer;
  opacity: 0.7;
}

button[type="search"]:hover {
  opacity: 1;
}
.discussionButton {
  color: #fff;
  background: linear-gradient(90deg, #000940 0%, #a00030 100%);
  font-weight: bold;
  /* padding-left: 20px;
  padding-right: 20px; */
  border-color: #a00030;
  font-size: 11px !important;
}
.cardButton-forum {
  border: 1px rgb(245, 245, 245);
  border-radius: 4px !important;
  font-size: 11px !important;
  background: #dcdcdc;
  color: #000;
  cursor: pointer;
}
.cardButton-forum-dropdownCardPost {
  background: #ffffff 0% 0% no-repeat padding-box;
  /* box-shadow: 5px 5px 25px #00000040; */
  border: 1px solid #dededebf;
  border-radius: 4px;
  min-width: 134px;
  height: auto;
  /* padding: 0px 3px 1px 13px; */
  position: absolute;
  /* right: 63%; */
  top: 2.6em;
  cursor: pointer;
  z-index: 10;
  float: left;
  padding: 5px;
}
.cardButton-forum-dropdownCardPost div {
  font-size: 14px;
  padding: 4px;
  text-align: center;
}
.cardButton-forum-dropdownCardPost:hover div {
  /* font-size: 14px;
    padding: 4px;
    text-align: center; */
  color: #000;
}
.refresh-forum {
  background: lightgray;
  padding: 3px 5px;
  border-radius: 3px;
  cursor: pointer;
}
.displayName-forum {
  font-size: 12px;
  color: gray;
  font-weight: bold;
}

.forum-card:hover,
.forum-card.focus {
  background: #eeeeee;
  cursor: pointer;
  border-radius: 10px !important;
  /* margin-bottom: 8px !important; */
}
.forum-card {
  margin-top: 1.1rem;
  padding: 7px 0px 5px 4px;
}
.forum-card-ans {
  margin-top: 1.1rem;
  padding: 11px 15px 5px 13px;
  margin-bottom: 2rem;
}
.forum-tags span {
  font-size: 0.79rem;
  margin-left: 8px;
  font-weight: initial;
}
.forum-tags:hover {
  color: gray;
}
.forum-menu li {
  cursor: pointer;
}
.segmentsList-forum.selected-forum {
  background: #eeeeee;
  color: black;
  /* border-radius: 10px !important; */
  /* margin-bottom: 8px !important; */
  text-align: center;
}
.forum-ans {
  word-break: break-all;
  color: gray;
  font-size: 11px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.badge-primary {
  color: #fff !important;
  background-color: #007bff;
}
.forum-tags-badges:hover,
a {
  color: white;
}
.forum-tags-badges {
  display: flex;
  overflow: hidden;
}
.forum-tags-badges:hover {
  display: flex;
  flex-direction: row-reverse;
  overflow: initial;
}
.badge-primary:hover,
a {
  color: #fff;
}
.tag-List {
  padding: 6px;
  color: gray;
  font-size: 11px;
  height: 33.1rem;
  overflow: scroll;
  overflow-x: hidden;
  /* text-align: center; */
}
.tag-List p {
  padding: 5px 5px;
}
.tag-List p:hover {
  background: #eeeeee;
  cursor: pointer;
  border-radius: 5px;
}
.tag-List-select {
  color: #007bff;
  padding: 5px !important;
  border-radius: 2px;
}
.tag-List-Unselect {
  background: white;
  padding: 5px !important;
}
.forum-module {
  background: white;
  border-radius: 6px;
  padding: 7px;
  /* height: 39.5rem; */
  overflow: hidden;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}
.forum-description-wrapper {
  /* background-color:rgb(85, 85, 85); */
  /* height: 8rem; */
  border-radius: 12px;
  overflow: hidden;
  background-color: rgb(85 85 85 / 7%);
}
.forum-description-question-wrapper {
  display: flex;
  margin-left: 2rem;
  /* justify-content: center; */
  /* text-transform: capitalize; */
  padding-top: 10px;
  padding-left: 10px;
  /* margin-right: 11px; */
  /* color: white; */
  font-size: 1.1rem;
  /* font-weight: 700; */
}
.desIcons {
  /* height: 34px !important;  */
  width: 16px !important;
  margin-left: 10px !important;
  color: #e83e8c;
  cursor: pointer;
}
.forum-ans-button p:hover {
  color: #a00030;
}
.forum-reply-status {
  color: #a00030 !important;
}
.answer-reply {
  display: flex;
  align-items: baseline;
  padding: 10px;
  /* margin-left: 28px; */
  font-size: 14px;
  color: gray;
}
.forum-ans-button p {
  float: right;
  font-size: 12px;
  color: gray;
  padding-left: 2rem;
  cursor: pointer;
}
.forum-ans-clear {
  float: right;
  font-size: 14px;
  color: gray;
  padding-left: 2rem;
  cursor: pointer;
}
.forum-ans-button span {
  font-size: 12px;
  color: gray;
  cursor: pointer;
  padding-right: 7px;
}

.forum-ans-button span:hover {
  color: #a00030 !important;
}

.forum_questionMedia {
  width: 193px !important;
  height: 145px !important;
  margin-top: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
}
.q-tag {
  display: flex;
  flex-direction: row;
  margin-left: 2rem;
  /* align-items: center; */
  /* justify-content: center; */
}
.forum-text-Editor {
  position: fixed;
  bottom: 50px;
  border: 2px solid #f7f7f7;
  background: rgba(255, 255, 255, 0.95);
  padding: 2px 13px 8px 13px;
  z-index: 1020;
}
.cardBox {
  max-width: 22rem;
  border-radius: 5px !important;
}
.cardBox:hover {
  box-shadow: 5px 10px 15px 1px rgba(0, 0, 0, 253) !important;
}
.bg-forum-dark {
  color: #fff !important;
  background: #444 !important;
  font-weight: bold !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.forum-big-dis {
  font-size: 12px;
  padding: 2px 9px 10px 9px;
  /* color: white; */
  text-indent: 34px;
  /* /* text-align: center; */
}
.badge-secondary {
  color: #fff !important;
  background-color: #6c757d;
  margin-left: 5px;
}
.forum-q-image {
  display: flex;
  justify-content: center;
  align-items: center;
}
.submit-btn {
  flex-grow: 1;
  border-color: gray;
  border-style: dashed;
  padding: 10px;
  border-radius: 13px;
}
.submit-btn:hover {
  border-color: #a00030;
}
.forum-wrapper-search {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 9px;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap-reverse;
}
.join-Button {
  color: #fff;
  background: linear-gradient(90deg, #000940 0%, #a00030 100%);
  font-weight: bold;
  border-color: #a00030;
  padding: 0.5rem;
  font-size: 11px !important;
  border-radius: 7px !important;
}
.private-forum-wrapper {
  max-width: 100%;
  padding: 0 1.5em;
  background-color: #f8f9fa !important;
  border: 1px solid lightgray;
  border-radius: 0.5em;
  overflow: hidden;
  margin-top: 4rem;
  margin-bottom: 6.7rem;
}
