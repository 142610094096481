.groupCard {
  position: relative;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.09);
  border-radius: 0.4rem;
}

.groupCard {
  word-wrap: break-word;
}

.groupCard-body {
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  padding: 1.25rem 1.25rem;
}

@media (min-width: 768px) {
  .groupCard-text-md-start {
    text-align: left !important;
  }
}

@media (min-width: 768px) {
  .groupCard-d-md-flex {
    /* display: -webkit-box !important;
  display: -ms-flexbox !important; */
    display: flex !important;
  }
}

.groupCard-align-items-start {
  -webkit-box-align: start !important;
  /* -ms-flex-align: start !important; */
  align-items: flex-start !important;
}

.groupCard-flex-wrap {
  /* -ms-flex-wrap: wrap !important; */
  flex-wrap: wrap !important;
}

.groupCard-mb-2 {
  margin-bottom: 0.5rem !important;
}

.groupCard-avatar-xl {
  height: 5.125rem;
  width: 5.125rem;
}

.groupCard-avatar {
  height: 3rem;
  /* width: 3rem; */
  position: relative;
  display: inline-block;
  /* -ms-flex-negative: 0 !important; */
  flex-shrink: 0 !important;
}

.groupCard-avatar-img {
  width: 100%;
  height: 100%;
  /* -o-object-fit: cover; */
  object-fit: cover;
}

.groupCard-border-0 {
  border: 0 !important;
}

@media (min-width: 768px) {
  .groupCard-ms-md-4 {
    margin-left: 1.5rem !important;
  }
}

.groupCard-mt-3 {
  margin-top: 1rem !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

h5,
.h5 {
  font-size: 1.171875rem;
}

small,
.small {
  font-weight: 400;
}

.groupCard-text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

small,
.small {
  font-size: 0.875em;
}

@media (min-width: 768px) {
  .groupCard-justify-content-md-start {
    -webkit-box-pack: start !important;
    /* -ms-flex-pack: start !important; */
    justify-content: flex-start !important;
  }
}

.groupCard-nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

ol,
ul,
dl {
  margin-top: 0;
}

@media (min-width: 768px) {
  .text-md-start {
    text-align: left !important;
  }
}

.text-center {
  /* text-align: center !important; */
}

.groupCard-nav.groupCard-nav-divider .groupCard-nav-item {
  display: flex;
  /* -webkit-box-align: center;
  align-items: center; */
}

@media (min-width: 992px) {
  .groupCard-ms-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .groupCard-justify-content-md-start {
    -webkit-box-pack: start !important;
    /* -ms-flex-pack: start !important; */
    justify-content: flex-start !important;
  }
}

.groupCard-mt-3 {
  margin-top: 1rem !important;
}

.groupCard-align-items-center {
  -webkit-box-align: center !important;
  /* -ms-flex-align: center !important; */
  align-items: center !important;
}

.groupCard-justify-content-center {
  /* -webkit-box-pack: center !important;
  -ms-flex-pack: center !important; */
  /* justify-content: center !important; */
}

.groupCard-d-flex {
  /* display: -webkit-box !important;
  display: -ms-flexbox !important; */
  display: flex !important;
}

.groupCard-btn-primary-soft {
  color: #0f6fec;
  background-color: rgba(15, 111, 236, 0.1);
}

.groupCard-btn {
  outline: 0;
}

.groupCard-me-2 {
  margin-right: 0.5rem !important;
}

.groupCard-btn-sm,
.groupCard-btn-group-sm > .groupCard-btn {
  padding: 0.4rem 0.8rem;
  font-size: 0.8125rem;
  border-radius: 0.2rem;
}

.groupCard-btn {
  display: inline-block;
  font-weight: 500;
  line-height: 1.5;
  /* color: #676a79; */
  text-align: center;
  /* vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none; */
  -ms-user-select: none;
  user-select: none;
  /* background-color: transparent; */
  border: 1px solid transparent;
  /* padding: 0.5rem 1rem;
  font-size: 0.9375rem;
  border-radius: 0.4rem;
  -webkit-transition: all 0.3s ease-in-out; */
  transition: all 0.3s ease-in-out;
}

.groupCard-icon-sm {
  height: 2rem;
  width: 2rem;
  font-size: 0.7em;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}

.groupCard-btn-dark-soft {
  color: #14191e;
  background-color: rgba(20, 25, 30, 0.1);
}

.groupCard-bi::before,
[class^="bi-"]::before,
[class*=" bi-"]::before {
  display: inline-block;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  vertical-align: -0.125em;
}

.groupCard-bi-three-dots::before {
  content: "\f5d4";
}

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative;
}

.dropdown-menu {
  -webkit-box-shadow: 0px 20px 30px rgba(83, 88, 93, 0.1),
    0px 0px 30px rgba(83, 88, 93, 0.2);
  box-shadow: 0px 20px 30px rgba(83, 88, 93, 0.1),
    0px 0px 30px rgba(83, 88, 93, 0.2);
}

.dropdown-menu-end {
  --bs-position: end;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 15rem;
  padding: 1rem 0;
  margin: 0;
  font-size: 0.9375rem;
  color: #676a79;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 0.4rem;
}

.groupCard-avatar-group {
  padding: 0;
  /* display: -webkit-box;
  display: -ms-flexbox; */
  display: flex;
  border: 2px solid #fff;
}

@media (min-width: 768px) {
  .groupCard-justify-content-md-start {
    -webkit-box-pack: start !important;
    /* -ms-flex-pack: start !important; */
    justify-content: flex-start !important;
  }
}

.groupCard-mb-0 {
  margin-bottom: 0 !important;
}

.groupCard-flex-wrap {
  /* -ms-flex-wrap: wrap !important; */
  flex-wrap: wrap !important;
}

.groupCard-list-unstyled {
  /* padding-left: 0; */
  list-style: none;
}

.groupCard-avatar-group > li:not(:last-child) {
  margin-right: -0.8rem;
}

.groupCard-avatar-group > li {
  position: relative;
}

.groupCard-avatar-xs {
  height: 2.1875rem !important;
  width: 2.1875rem !important;
}

.groupCard-avatar {
  height: 3rem;
  width: 3rem;
  position: relative;
  display: inline-block;
  flex-shrink: 0 !important;
}

.groupCard-avatar-group .groupCard-avatar-img {
  border: 2px solid #fff;
}

.groupCard-avatar-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.rounded-circle {
  border-radius: 50% !important;
}

.groupCardStatus {
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #676a79;
}

.groupCard-nav.groupCard-nav-divider
  .groupCard-nav-item
  + .groupCard-nav-item::before {
  content: "•";
  color: inherit;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  opacity: 0.8;
}

.groupCard-avatar-group > li {
  position: relative;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #676a79;
}

.groupCard-small {
  font-weight: 400 !important;
}

.groupCard-small {
  font-size: 0.875em !important;
}

.main-card-footer {
  padding: 1.25rem 1.25rem;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  background-color: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.09);
}

.groupCard-h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  line-height: 1.25;
  color: #14191e;
}

@media (min-width: 992px) {
  .groupd-desc-ms-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .groupd-desc-justify-content-md-start {
    justify-content: flex-start !important;
  }
}
.groupd-desc-mt-3 {
  margin-top: 1rem !important;
}
.groupd-desc-align-items-center {
  align-items: center !important;
}
.groupd-desc-justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}
.groupd-desc-d-flex {
  display: flex !important;
}

.groupd-desc-btn-primary-soft {
  color: #0f6fec;
  background-color: rgba(15, 111, 236, 0.1);
}
.groupd-desc-btn {
  outline: 0;
}
.groupd-desc-me-2 {
  margin-right: 0.5rem !important;
}
.groupd-desc-btn-sm,
.btn-group-sm > .btn {
  padding: 0.4rem 0.8rem;
  font-size: 0.8125rem;
  border-radius: 0.2rem;
}
.groupd-desc-btn {
  display: inline-block;
  font-weight: 500;
  line-height: 1.5;
  color: #676a79;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.5rem 1rem;
  font-size: 0.9375rem;
  border-radius: 0.4rem;
  transition: all 0.3s ease-in-out;
}

.skeleton-loader {
  margin: auto;
  display: block;
  position: relative;
  background: #fff;
  box-sizing: border-box;
  height: 10rem;
  overflow: hidden;
}
.skeleton-loader::after {
  content: "";
  width: 100%;
  height: 100%;
  top: 15px;
  left: 15px;
  position: absolute;
  background-image: linear-gradient(
      100deg,
      transparent,
      rgba(255, 255, 255, 0.5) 50%,
      transparent 80%
    ),
    linear-gradient(#ddd 56px, transparent 0),
    linear-gradient(#ddd 24px, transparent 0),
    linear-gradient(#ddd 18px, transparent 0),
    linear-gradient(#ddd 66px, transparent 0);
  background-repeat: no-repeat;
  background-size: 90px 130px, 55px 56px, 90% 30px, 90% 20px, 96% 5rem;
  background-position: 0% 0, 0 0, 70px 5px, 70px 38px, 0px 66px;
  box-sizing: border-box;
  animation: animloader 1s linear infinite;
}

@keyframes animloader {
  0% {
    background-position: 0% 0, 0 0, 70px 5px, 70px 38px, 0px 66px;
  }
  100% {
    background-position: 150% 0, 0 0, 70px 5px, 70px 38px, 0px 66px;
  }
}

.skeleton-loader-group {
  margin: auto;
  display: block;
  position: relative;
  background: #fff;
  box-sizing: border-box;
  height: 8rem;
  overflow: hidden;
}
.skeleton-loader-group::after {
  content: "";
  width: 100%;
  height: 100%;
  top: 15px;
  left: 15px;
  position: absolute;
  background-image: linear-gradient(
      100deg,
      transparent,
      rgba(255, 255, 255, 0.5) 50%,
      transparent 80%
    ),
    linear-gradient(#ddd 57px, transparent 0),
    linear-gradient(#ddd 15px, transparent 0),
    linear-gradient(#ddd 16px, transparent 0),
    linear-gradient(#ddd 37px, transparent 0);
  background-repeat: no-repeat;
  background-size: 90px 130px, 55px 56px, 90% 30px, 90% 20px, 96% 5rem;
  background-position: 0% 0, 0 0, 70px 5px, 70px 38px, 0px 66px;
  box-sizing: border-box;
  animation: animloader 1s linear infinite;
}
