* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  /* // height: 100vh; */
  background-color: #f3f2ef;
}

.App {
  /* position: relative; */
}

@media (min-width: 1025px) {
  .chat-card {
    width: 35vw;
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.08),
      0px 4px 4px rgba(0, 0, 0, 0.3);
  }
  .card-header {
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  }
  .card-body {
    /* border-top: 1px solid #788fa5; */
    padding: 0.6rem 1rem;
  }

  .chatItemBox-container {
    right: 36vw;
    min-height: 70vh;
    max-height: 75vh;
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.08),
      0px 4px 4px rgba(0, 0, 0, 0.3);
    width: 25rem;
  }
  .conversation-user-avatar {
    width: 3.6rem;
    height: 3.6rem;
  }
  .conversation-user-name {
    font-size: 16px !important;
  }
  .conversation-user-name-container span {
    font-size: 12px !important;
  }
  .conversation-list-item {
    gap: 0.6rem;
  }
}

@media (min-width: 739px) and (max-width: 1024px) {
  .chat-card {
    width: 40vw;
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.08),
      0px 4px 4px rgba(0, 0, 0, 0.3);
  }
  .card-header {
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  }
  .card-body {
    border-top: 1px solid #788fa5;
    padding: 0.6rem 0.2rem;
  }
  .chatItemBox-container {
    right: 41vw;
    min-height: 70vh;
    max-height: 75vh;
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.08),
      0px 4px 4px rgba(0, 0, 0, 0.3);
    width: 25rem;
  }

  .conversation-user-avatar {
    width: 2.6rem;
    height: 2.6rem;
  }

  .conversation-user-name {
    font-size: 14px !important;
  }

  .conversation-user-name-container span {
    font-size: 10px !important;
  }

  .conversation-last-message {
    font-size: 12px !important;
  }

  .search-filter {
    margin-inline: 0.2rem;
  }

  .conversation-list-item {
    gap: 0.4rem;
    margin-left: 0.2rem;
  }
}

@media (min-width: 0px) and (max-width: 738px) {
  .Desktop-App {
    display: none;
  }
  .Mobile-App {
    display: block;
  }
  .icons-container {
    display: none;
    visibility: hidden;
  }
  .chat-card {
    width: 100vw;
    top: 95px;
    box-shadow: none;
  }
  .card-header {
    background: white;
    box-shadow: none;
  }
  .card-body {
    border-top: none;
  }
  .conversation-user-avatar {
    height: 2.6rem;
    width: 2.6rem;
  }
  .conversation-list-item {
    gap: 0.6rem;
  }
  .conversation-user-name {
    font-size: 14px;
  }
  .chatItemBox-container {
    top: 95px;
    width: 100vw;
    height: 100%;
  }
  .chatItemBox--header {
  }
  .chatItemBox--body {
  }
  .chatItemBox--footer {
  }
}
