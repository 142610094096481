.chatItemBox-container {
  position: fixed;
  bottom: 0;
  /* right: 31vw; */
  /* background-color: white; */
  background-color: #fff;
 
  padding: 1rem 0.6rem 0 0.6rem;
  z-index: 999;
  border-radius: 5px;
  /* max-height: 60vh; */
  display: flex;
  flex-direction: column;

}
.chatItemBox-container .img {
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 50%;
}
.chatItemBox-container .chatItemBox--header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1rem;
  border-bottom: 1px solid lightgrey;
  max-height: 20%;
}
.chatItemBox-container .chatItemBox--header .chatItemBox-img-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.chatItemBox-container .chatItemBox--header .chatItemBox-icons-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
}
.chatItemBox-container .chatItemBox--header .chatItemBox-icon {
  cursor: pointer;
}
.chatItemBox-container .chatItemBox--body {
  padding: 0.4rem 0 1rem 0rem;
  max-height: 50%;
  overflow-y: scroll;
  flex-grow: 1;
  overflow-x: hidden;
  word-break: break-word;
}
.chatItemBox-container .chatItemBox--body .chatItemBox--body-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem 0;
  border-bottom: 1px solid gray;
}

.chatItemBox--body-header-name {
  font-size: medium !important;
}

.chatItemBox-container .chatItemBox--body .chatItemBox-message-list {
  max-height: 60%;
}

.chatItemBox-container .chatItemBox--body .chatItemBox-message-item {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
  padding: 1rem 0;
}

.chatItemBox-message-item-user{
    display: flex;
    flex: 1;
    flex-direction: column;
}

.chatItemBox-container
  .chatItemBox--body
  .chatItemBox-message-item
  .chatItemBox--body-sender-name {
  /* padding-bottom: 0.6rem; */
  font-size: medium !important;
  display: flex;
  justify-content: space-between;
  align-items:baseline;
}
.chatItemBox-container
  .chatItemBox--body
  .chatItemBox-message-item
  .chatItemBox--body-sender-message {
  word-wrap: break-word;
}
.chatItemBox-container
  .chatItemBox--body
  .chatItemBox-message-item
  .chatItemBox--body-sender-name
  span {
  margin-left: 2rem;
  font-size: 13px;
  color: gray;

}
.chatItemBox-container .chatItemBox--footer {
  border-top: 1px solid lightgray;
  max-height: 30%;
}
.chatItemBox-container .chatItemBox--footer .chatItemBox--footer-text-box {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  /* height: 3rem; */
  padding-top: 0.8rem;
  width: 100%;
}
.chatItemBox-container
  .chatItemBox--footer
  .chatItemBox--footer-text-box
  textarea {
  border: none;
  outline: none;
  flex-grow: 1;
  resize: none;
}
.chatItemBox-container .chatItemBox--footer .chatItemBox--footer-send-box {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid lightgray;
  padding: 1rem 0;
}
.chatItemBox-container
  .chatItemBox--footer
  .chatItemBox--footer-emoji-container {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
}
.chatItemBox-container .chatItemBox--footer .chatItemBox--footer-send-button {
  display: flex;
  justify-content: flex-start;
  gap: 2rem;
  align-items: center;
}
.chatItemBox-container
  .chatItemBox--footer
  .chatItemBox--footer-send-button
  button {
  padding: 0.4rem 0.8rem;
  border-radius: 10rem;
  background-color: blue;
  color: white;
  border: none;
  outline: none;
}
.chatItemBox-container
  .chatItemBox--footer
  .chatItemBox--footer-send-button
  button:disabled {
  background-color: lightgray;
  color: #5e5e5e;
  cursor: not-allowed;
}
.chatItemBox-container .emojiContainer {
  position: absolute;
  right: 0vw;
  bottom: 30%;
}
.chatItemBox-container .empty-conversation {
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-content: center;
  font-size: 24px;
  font-weight: bold;
}

.chatItemBox--body-sender-name {
  font-size: medium !important;
}
