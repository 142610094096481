.invalid-feedback {
  margin-top: -15px;
}

button.btnBlack {
  min-width: 106px;
  width: auto !important;
}
.letter {
  margin: 5px;

  text-align: center;
}
.likeopen {
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: center;
}
.likecount {
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: center;
}
.likeclose {
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: center;
}

.madeInLogo {
  height: 60px;
}
.langBox,
.madeBox {
  margin: 0px;
  font-size: 12px;
  padding-top: 20px;
}
.langDescTxt,
.madeInTxt {
  margin: 0px;
}
.langTxt {
  margin: 0px;
  margin: 0px 10px;
  cursor: pointer !important;
}

.langImg {
  width: 32px;
  height: 24px;
}

.loginInput {
  width: 260px !important;
}

.formInputss input {
  width: 260px !important;
}

@media only screen and (max-width: 768px) {
  .formInputss input {
    width: 260px !important;
  }
}

.formInputsDescription {
  width: 260px !important;
}

.logo img {
  /* height: 60px;
  width:200px;
  margin-right: 10px; */
}

/* Error User */
.error-user-body {
  /* background-color: #2f3242; */
  height: 100vh;
  overflow: hidden;
}
.error-user-body svg {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -250px;
  margin-left: -400px;
}
.message-box {
  height: 200px;
  width: 380px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -100px;
  margin-left: 50px;
  color: white;
  font-family: Roboto;
  font-weight: 300;
}
.message-box h1 {
  font-size: 60px;
  line-height: 46px;
  margin-bottom: 40px;
}
.buttons-con .action-link-wrap {
  margin-top: 40px;
}
.buttons-con .action-link-wrap a {
  background: #68c950;
  padding: 8px 25px;
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  transition: all 0.3s linear;
  cursor: pointer;
  text-decoration: none;
  margin-right: 10px;
}
.buttons-con .action-link-wrap a:hover {
  background: #5a5c6c;
  color: #fff;
}

#Polygon-1,
#Polygon-2,
#Polygon-3,
#Polygon-4,
#Polygon-4,
#Polygon-5 {
  animation: float 1s infinite ease-in-out alternate;
}
#Polygon-2 {
  animation-delay: 0.2s;
}
#Polygon-3 {
  animation-delay: 0.4s;
}
#Polygon-4 {
  animation-delay: 0.6s;
}
#Polygon-5 {
  animation-delay: 0.8s;
}

@keyframes float {
  100% {
    transform: translateY(20px);
  }
}
@media (max-width: 450px) {
  .error-user-body svg {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -250px;
    margin-left: -190px;
  }
  .message-box {
    top: 50%;
    left: 50%;
    margin-top: -100px;
    margin-left: -190px;
    text-align: center;
  }
}
.login-container {
  display: flex;
  align-items: center;
  height: 100vh;
  background: #a00030 0 0 no-repeat padding-box;
  background-image: linear-gradient(9deg, #000940 0%, #a00030 100%);
  justify-content: center;
  overflow: auto;
}
.login-option-wrap > img {
  width: 224px;
  height: auto;
}
.login-options-opt {
  color: white;
  list-style: none;
  display: flex;
  flex-direction: column;
  margin: 0;
  text-align: center;
  padding: 4rem 2rem;
  grid-gap: 2rem;
  gap: 2rem;
  background: rgba(74, 144, 226, 0.1);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(0px);
  -webkit-backdrop-filter: blur(0px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  margin-top: 1rem;
}
.login-options-btn {
  border: 1px solid white;
  padding: 0.5rem 1.5rem;
  border-radius: 25px 25px !important;
  display: flex;
  align-items: center;
  font-weight: 600 !important;
  transition: all 0.5s;
  text-transform: uppercase;
}
.login-options-img {
  height: 24px;
  width: 24px;
  margin-right: 14px;
}
.reg-btn {
  text-decoration: underline;
  color: wheat !important;
  display: block;
  cursor: pointer;
  width: fit-content;
  font-size: 13px;
}
.login-options-btn:hover {
  background: white;
  cursor: pointer;
  color: black;
}
.login-other-option {
  font-size: 14px;
}
.m1-option {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 0;
}
.m1-option-img {
  height: 24px;
  width: 24px;
  margin: 10px;
  cursor: pointer;
}
.arrow-icon {
  color: white;
  position: absolute;
  left: 20px;
  top: 20px;
  height: 28px;
  width: auto;
  cursor: pointer;
}

.login-option-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
}
.wrap-menu-1 {
  animation: moving-menu-1 0.5s ease-in-out;
}

.wrap-menu-2 {
  animation: moving-menu-2 0.5s ease-in-out;
}

@keyframes moving-menu-1 {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes moving-menu-2 {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
.input-text {
  position: relative;
}
.input-text-input {
  height: 45px;
  width: 100%;
  border-radius: 6px;
  border: 1px solid #e0e3e9;
  padding: 0px 10px;
  padding-right: 40px;
  font-size: 13px;
  background: transparent;
  caret-color: white;
  color: white;
}
.input-text svg {
  position: absolute;
  right: 10px;
  /* cursor: pointer; */
  top: 15px;
  color: white;
  height: 19px;
  width: auto;
}
.custom-select-login {
  background: transparent;
}
.login-details-wrapper {
  background: rgba(74, 144, 226, 0.1);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(0px);
  -webkit-backdrop-filter: blur(0px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.login-details-content {
  font-size: 14px;
  font-weight: 300;
}
.btn-hover:hover {
  background: black !important;
  color: white !important;
}
.otpInput {
  background: transparent;
  color: white;
  padding: 1rem;
  width: 3rem;
  border: 1px solid white;
  border-radius: 11px;
  font-weight: bold;
  font-size: 17px;
  text-align: center;
}
/* Loader */
.loader {
  animation: rotate 1s infinite;
  height: 50px;
  width: 50px;
}

.loader:before,
.loader:after {
  border-radius: 50%;
  content: "";
  display: block;
  height: 20px;
  width: 20px;
}
.loader:before {
  animation: ball1 1s infinite;
  background-color: #fff;
  box-shadow: 30px 0 0 #ff3d00;
  margin-bottom: 10px;
}
.loader:after {
  animation: ball2 1s infinite;
  background-color: #ff3d00;
  box-shadow: 30px 0 0 #fff;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
  }
}

@keyframes ball1 {
  0% {
    box-shadow: 30px 0 0 #ff3d00;
  }
  50% {
    box-shadow: 0 0 0 #ff3d00;
    margin-bottom: 0;
    transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 #ff3d00;
    margin-bottom: 10px;
  }
}

@keyframes ball2 {
  0% {
    box-shadow: 30px 0 0 #fff;
  }
  50% {
    box-shadow: 0 0 0 #fff;
    margin-top: -20px;
    transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 #fff;
    margin-top: 0;
  }
}
.blur-loader-bg {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(3px);
  z-index: 5;
}
.login-details-wrapper > label {
  font-weight: 500;
  color: white;
}
.email-verify {
  color: black;
  background: white;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 11px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.email-verify > img {
  width: 24rem;
  height: auto;
  object-fit: cover;
  margin-top: 3rem;
}
.react-tel-input .country-list {
  color: black;
  background: white;
  font-weight: 600;
}
.react-tel-input .form-control {
  height: 45px !important;
  width: 100% !important;
  border-radius: 6px !important;
  border: 1px solid #e0e3e9 !important;
  padding-right: 40px !important;
  font-size: 13px !important;
  background: transparent !important;
  caret-color: white !important;
  color: white !important;
}
.react-tel-input .flag-dropdown {
  background: transparent !important;
}
.resend-otp {
  font-weight: 600;
  margin-top: 1rem !important;
  text-decoration: underline;
  color: blue;
  width: fit-content;
  cursor: pointer;
}
.c-info {
  max-width: 32rem;
  font-size: 9px;
  margin-top: 0.5rem !important;
}
.email-warn {
  background: #ffff004a;
  padding: 1rem;
  border-radius: 5px;
  font-size: 12px;
}

.custom-phone-input .form-control {
  color: black !important; /* Set font color to black */
  caret-color: black !important;
}
.z-1000 {
  z-index: 1000;
}
.input-dark .form-control {
  color: black !important;
}
