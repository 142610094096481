@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@1,100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;1,100;1,300&display=swap);
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif !important;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
p,
span,
button,
input,
div,
li {
  font-family: "Poppins", sans-serif !important;
}

.screenInnerGapchat {
  /* padding: 122px 0px 113px 200px; */
  /* padding: 30px 0px 113px 207px; */
  background: #f5f5f5 0 0 no-repeat padding-box;
}
.container {
  text-align: left;
}
.toggle-switch {
  position: relative;
  width: 60px;
  height: 29px;
  display: inline-block;
  text-align: left;
  top: 16px;
  font-size: 10px;
  margin-left: 40px;
}
.rdw-image-modal-upload-option-label {
  word-break: break-all !important;
}
/* .max-width-100 */
.checkbox {
  display: none;
}
.label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid #bbb;
  border-radius: 20px;
  margin-top: 2px;
  width: 45px;
  height: 24px;
}
.inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}
.inner:before,
.inner:after {
  float: left;
  width: 50%;
  height: 36px;
  padding: 0;
  line-height: 36px;
  color: #fff;
  font-weight: bold;
  box-sizing: border-box;
}
.inner:before {
  content: "YES";

  padding-left: 10px;
  margin-top: -5px;
  background-color: #060;
  color: #fff;
}
.inner:after {
  content: "NO";
  padding-right: 10px;
  margin-top: -5px;
  background-color: #bbb;
  color: #fff;
  text-align: right;
}
.switch {
  display: block;
  width: 24px;
  margin: 5px;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 40px;
  border: 0 solid #bbb;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}
.checkbox:checked + .label .inner {
  margin-left: 0;
}
.checkbox:checked + .label .switch {
  right: 0px;
}

p {
  margin: 0px !important;
}

.flexDefault {
  display: flex;
}

.flexAlignCenter {
  align-items: center;
}

.flexJustifySpacebetween {
  justify-content: space-between;
}

body {
  background: white !important;
  margin: 0;
}
body.react-joyride-is-anchored {
  overflow: hidden;
}
img {
  max-width: 100%;
  height: auto;
}

a:focus,
div:focus,
button:focus,
input:focus,
fieldset:focus {
  outline: none;
  box-shadow: none;
}

a:hover,
a {
  color: #a00030 !important;
}

.uploadFileModelFooter button {
  width: 100%;
  font-weight: 600;
  background: #a00030 0 0 no-repeat padding-box;
  background-image: linear-gradient(90deg, #000940 0%, #a00030 100%);
  border: 1px solid #a00030;
}

/* button:hover {
  color: #065064 !important;
} */

.sendbtn {
  border-radius: 80px;
  border: none;
  border: 1px solid #a00030;
  background: #a00030 0 0 no-repeat padding-box;
  background-image: linear-gradient(90deg, #000940 0%, #a00030 100%);
  color: #fff;
  font-size: 12px;
  padding: 5px 8px;
  font-weight: bold;
  /* font-family: "nunitoRegular"; */
  font-family: "nunitoRegular", sans-serif;
  margin-left: 2%;
  width: 10%;
}

button.networkSeeMore {
  border-radius: 4px;
  width: 105px;
  background: linear-gradient(90deg, #000940 0%, #a00030 100%);
}

button,
.sendbtn {
  border: 1px solid #f97516;
  /* border-radius: 80px !important; */
  color: #fff;
  font-size: 14px !important;
  padding: 5px 15px;
  background: linear-gradient(90deg, #000940 0%, #a00030 100%);
  border-radius: 4px !important;
  /* background: #f97516 0 0 no-repeat padding-box;
  background-image: linear-gradient(to right, #f97516 5%, #f97516 100%); */
}

.cardWrapper {
  background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;
  border: 1px solid #dededf;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
}

.MuiBackdrop-root {
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
}

/* .fontFamilyIBMPlex {
  font-family: "IBM Plex Sans", sans-serif;
} */

.bold {
  font-weight: bold;
}

::-webkit-scrollbar {
  width: 10px;
  height: 20px;
}

::-webkit-scrollbar-track {
  border-radius: 15px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #dedede;
}

/* ============== TAG MENU =========== */

.tagSelectedMenuWrapper .MuiChip-root {
  border-radius: 4px;
  background: #f3f5f9;
  color: linear-gradient(90deg, #000940 0%, #a00030 100%);
  font-size: 11px;
  font-weight: normal;
  height: 26px;
}

.tagSelectedMenuWrapper .MuiChip-root svg {
  border: 0.5px solid #707070;
  border-radius: 100%;
  color: linear-gradient(90deg, #000940 0%, #a00030 100%);
  font-size: 20px;
}

.tagSelectedMenuWrapper .MuiChip-root .MuiChip-deleteIcon {
  color: #db2828;
  width: 20px;
  height: 20px;
}

.tagMenuFilter {
  min-width: 200px;
  border: 1px solid #c7c7c780;
  border-radius: 4px;
  padding: 5px;
}

.tagMenuFilter .tagSelectedMenus {
  max-width: 100%;
  overflow: auto hidden;
}

.tagMenuFilter .tagSelectedMenus::-webkit-scrollbar {
  display: none;
}

.TSMMenu {
  margin-right: 10px;
}

/* ====================== HEADER =============== */

header.headerWrapper {
  background: #fff;
  padding: 16px 0;
}

.logoDark img {
  height: 100px;
}

.headerIcon {
  position: relative;
  cursor: pointer;
}

.headerIcon svg {
  color: #333333;
  font-size: 26px;
  position: relative;
  top: 3px;
  /* left: -7px; */
}

.headerIcon img {
  font-size: 26px;
  position: relative;
  top: 9px;
  left: -17px;
}

/* .newMessageIconWrapper img {
  width: 22px;
  
} */

.headerIcon p {
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  background: linear-gradient(90deg, #000940 0%, #a00030 100%);
  text-align: center;
  position: absolute;
  min-width: 25px;
  min-height: 14px;
  bottom: 15px;
  left: 15px;
  padding: 2px;
  z-index: 0;
  border-radius: 50%;
}

/* .newMessageIconWrapper .headerIcon p {
  background: #6d064e;
} */

.headerUserProfile {
  /* margin-left: 50px; */
  margin-top: 7px;
  cursor: pointer;
}

.headerUserName {
  margin-left: 10px;
  margin-right: 5px;
  font-size: 18px;
  color: #333333;
}

.dropdonwCard {
  position: absolute;
  right: 0;
  top: 120px;
  box-shadow: 5px 5px 25px #00000040;
  border: 1px solid #dededebf;
  border-radius: 4px;
  background: #fff;
  z-index: 9999;
}

.dropdonwCard ul {
  padding-left: 0;
  list-style: none;
  width: 224px;
  height: 200px;
  margin: 0;
}

.dropdonwCard li a {
  color: #333333;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-size: 15px;
  /* font-weight: 600; */
  padding-left: 12px;
  padding-top: 5px;
}

.dropdonwCard li a div {
  width: 38px;
  height: 38px;
  /* background-color: #d8dadf; */
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 7px;
}

.navigationIcon {
  display: flex;
  align-items: center;
}

.dropdownIcon svg {
  font-size: 30px;
}

/* ====================== HEADER > NAVIGATION =========== */

.navMainWrapper {
  /* background: #fff;
  border-top: 1px solid #ddd; */
}

.navigationWrapper a.active {
  color: linear-gradient(90deg, #000940 0%, #a00030 100%);
  border-bottom: 3px solid;
}

.navigationWrapper a:hover {
  color: linear-gradient(90deg, #000940 0%, #a00030 100%);
}

.navigationWrapper a.active svg,
.navigationWrapper a:hover svg {
  fill: #a00030 !important;
}

.navigationWrapper {
  background: #fff;
  height: 75px;
  justify-content: flex-start;
  /*grid-gap: 90px !important;
  gap: 90px !important;*/
}

.navigationWrapper a {
  color: #263238;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 600;
  padding-left: 0;
}

.navigationWrapper a svg {
  margin-right: 6px;
}

/* =================== NOTIFICATION ================= */

.notificationWrapper {
  position: absolute;
  top: 5rem;
  right: -10rem;
  /* top: 67; */
  /* top: 74px; */
  z-index: 2000;
  width: 352px;
  max-height: 520px;
  /* left: -74px; */
  /* right: -164px; */
  background: #fff;
  box-shadow: 0px 1px 1px #00000029;
  border-radius: 4px;
}

.notificationCard > div.likedInfo {
  margin-left: 0;
}

.norfityReminderCardWrapper {
  border: 0.5px solid #33333380;
  border-top: 0;
  border-radius: 0 0 4px 4px;
}

.notifyHeader {
  background-color: #a00030;
  height: 56px;
  color: #fff;
  padding: 0 32px;
  border-radius: 4px 4px 0 0;
}

p.notifyTitle {
  font-size: 14px;
  font-weight: bold;
}

p.notifyCount {
  font-size: 14px;
}

.notifyReminder {
  background: #db28281a;
  height: 80px;
  padding: 0 32px;
  border-bottom: 1px solid #db2828ad;
}

.notifyReminderIcon svg {
  font-size: 32px;
  color: #306174;
}

.notifyReminderIcon {
  margin-right: 24px;
}

p.notifyReminderText {
  font-size: 14px;
  font-weight: 500;
  color: #242134;
  margin-bottom: 5px !important;
}

.notifyReminderGroup .MuiAvatarGroup-avatar {
  width: 32px;
  height: 32px;
  font-size: 11px;
}

.notificationCard {
  /* padding: 16px 32px; */
  padding: 10px 6px 10px 20px;
  border-bottom: 1px solid #3333331a;
  align-items: flex-start;
}

.notificationCardUserInfo {
  flex: 1 1;
}

p.NCUserName {
  font-size: 14px;
  color: #242134;
  font-weight: 600;
  margin-bottom: 3px !important;
  line-height: 15px;
}

p.NCType {
  font-size: 11px;
  color: #333333;
  font-weight: 500;
}

p.NCDescriptionBlue {
  color: #f97516;
  font-size: 11px;
  font-weight: 500;
}

.NCTime {
  font-size: 11px;
  color: #f97516;
  font-weight: 600;
  align-items: baseline !important;
  float: right !important;
}

.NCTimeGray {
  font-size: 11px;
  color: #9b9b9b;
  font-weight: 600;
  align-items: baseline !important;
  padding-top: 6px;
}

p.NCDescription {
  font-size: 12px;
  font-weight: 600;
  color: #333333;
}

.notificationCard > div:nth-child(1) {
  width: 48px;
  height: 48px;
  padding: 0;
  justify-content: center;
  margin-right: 16px;
}

.notificationWrapper:after,
.notificationWrapper:before {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.notificationWrapper:after {
  border-color: rgba(136, 183, 213, 0);
  border-bottom-color: #a00030;
  border-width: 20px;
  margin-left: -20px;
}

.notificationWrapper:before {
  border-color: rgba(194, 225, 245, 0);
  border-width: 38px;
  margin-left: -38px;
}

/* ======================= INNER TAB ============== */

.tabWrapper {
  background: white;
}

.MuiTab-textColorPrimary,
.OpenButton-textButton-14,
.NavigationButton-button-13,
.TodayButtonBase-button-11,
.MuiToggleButtonGroup-groupedHorizontal {
  background-image: none !important;
}

.tabs {
  background-image: linear-gradient(90deg, #000940 0%, #a00030 100%);
  margin-bottom: 10px;
}

.tabWrapper .MuiTabs-root {
  padding: 15px 50px 18px 20px !important;
  /* padding: 15px 50px 18px 0px; */
  min-height: 25px;
}

.tabWrapper .MuiTab-textColorPrimary {
  color: black !important;
  font-size: 16px;
}

.tabWrapper .MuiTab-textColorPrimary {
  min-width: 57px;
  min-height: 23px;
  padding: 0;
}

.tabWrapper .MuiTabs-indicator {
  bottom: 0;
  color: #db2828;
  /* background-color: #f97516; */
}

.tabWrapper .MuiTab-textColorPrimary {
  margin-right: 87px;
}

.MuiTab-wrapper {
  flex-direction: row !important;
  grid-gap: 5px;
  gap: 5px;
}

.MuiTab-wrapper svg {
  color: white !important;
}

/* ================ HOME > SEARCH TAB ============== */

.peopleMainWrapper {
  padding: 25px 0;
}

.boldLabel {
  font-size: 16px;
  font-weight: 600;
}

.searchHeading {
  margin-bottom: 7px;
}

/* .peopleCardWrapper img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  object-position: top center;
  border-radius: 4px 4px 0px 0px;
} */

.peopleCardInfor h2 {
  color: #333333;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0px;
}

.groupCardInfor h2 {
  color: #333333;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0px;
}
.groupCardInfor h2:hover {
  color: #a00030;
}
.groupCardInfor {
  text-align: center;
  margin-top: 15px;
  margin-bottom: 25px;
  height: 100px;
}

.peopleCardInfor {
  text-align: center;
  margin-top: 25px;
  margin-bottom: 60px;
  height: 70px;
}

.peopleCardInfor p {
  /* font-size: 12px;
  color: #b3b3b3;
  margin-bottom: 0; */
  font-size: 12px;
  color: #b3b3b3;
  margin-bottom: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 4px;
}

.groupCardInfor p {
  font-size: 12px;
  color: #b3b3b3;
  margin-bottom: 0;
}

.peopleCardAdd {
  flex-basis: 50%;
  /* background: linear-gradient(to right, #f97516 1%, #f97516 100%); */
  background: linear-gradient(90deg, #000940 0%, #a00030 100%);
  border-radius: 4px !important;
  border-radius: 0px 0px 0px 3px;
  color: #fff;
  border: 1px solid #f97516;
}

.peopleCardAddVG {
  flex-basis: 50%;
  background: #ffffff;
  border-radius: 0px 0px 0px 3px;
  color: #eeeeee;
  border: 1px solid #eeeeee;
  margin: 8px;
  text-align: center;
  padding: 12px;
}
.peopleCardRemove {
  flex-basis: 50%;
  border-top: 1px solid #dededf;
}

.peopleCardAddRemove {
  min-height: 42px;
  align-items: center;
  text-align: center;
  border-radius: 0px 0px 10px 10px;
}

.peopleCardAddRemove p {
  margin-bottom: 0;
}

.peopleCardAddReq p {
  margin-bottom: 0;
}

.peopleCardAddRemove div {
  font-size: 10px;
  font-weight: 600;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-transform: uppercase;
  border-radius: 0px 0px 10px 10px;
}

.peopleCardAddReq div {
  font-size: 10px;
  font-weight: 600;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  border-radius: 0px 0px 10px 10px;
}

.peopleCarouselWrapper .slick-slide > div {
  margin: 0 5px;
}

div.peopleCarouselWrapper
  > div
  > div.slick-list
  > div
  > div.slick-slide.slick-active.slick-current
  > div {
  margin-left: 0;
  width: 100px;
}

.slick-slide > div {
  margin: 0 0px;
  padding: 8px;
}

/* .slick-list {
  margin: 0 -10px;
} */

.peopleCarouselWrapper {
  padding: 0px 10px;
  /*padding: 0 50px 30px;*/
}

.PITitle {
  margin-bottom: 6px;
}

.peopleInvitationCardWrapper {
  background: #ffffff 0% 0% no-repeat padding-box;
  align-items: center;
  padding: 20px 15px;
  margin-bottom: 6px;
  box-shadow: rgb(17 17 26 / 10%) 0px 4px 16px, rgb(17 17 26 / 5%) 0px 8px 32px;
  border: 1px solid #f7f7f7;
  border-radius: 10px;
  cursor: pointer;
}
.peopleInvitationCardWrapper1 {
  background: #ffffff 0% 0% no-repeat padding-box;
  align-items: center;
  padding: 10px 15px 1px;
  margin-bottom: 6px;
  box-shadow: 0px 1px 3px #00000029;
  border: 1px solid #f7f7f7;
  border-radius: 2px;
}

.peopleInvitationCardWrapperPopup {
  background: #ffffff 0% 0% no-repeat padding-box;
  align-items: center;
  padding: 20px 15px;
  margin: 6px;
  box-shadow: 0px 1px 3px #00000029;
  border: 1px solid #f7f7f7;
  border-radius: 2px;
  text-align: left;
  height: 150px;
  /* padding: 22px; */
  overflow-y: scroll;
}

h2.PIuserName {
  font-size: 15px;
  font-weight: bold;
  /* color: #333333; */
  color: linear-gradient(to right, #ef5a3e 5%, #a00030 100%) !important ;
  margin-bottom: 3px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 120px;
}
p.PIFriend {
  font-size: 10px;
}

p.PImutualFriend {
  font-size: 13px;
  color: #b3b3b3;
  margin-bottom: 5px;
  line-height: 14px;
}

p.PIminutesAgo {
  font-size: 10px;
  color: #b3b3b3;
  margin-bottom: 0;
}

button.PIAcceptOutlined {
  border: 1px solid #dededf;
  background: #fff;
  color: #383838;
  margin-top: 10px;
}

.peopleInvitationUserInfo {
  flex: 1 1;
  margin: 0 10px;
}

.PICardAction button {
  /* display: block; */
  margin-right: 0;
  width: 105px;
  height: 33px;
  font-size: 12px !important;
  padding: 0 !important;
  display: flex;
  float: right !important;
  align-items: center;
  justify-content: center;
}

.PIWrapper {
  padding: 0 50px;
}

/* ============ SEARCH AUTOCOMPLETE  ==========*/

.SOptionImg img {
  width: 34px;
  height: 34px;
  border-radius: 100%;
}

.SOptionWrapper {
  align-items: center;
}

.SOptionLabel p {
  margin-bottom: 0;
  font-size: 14px;
  color: #253137;
  margin-left: 11px;
}

.SAWrapper {
  width: 100%;
  position: relative;
}

.searchWrapper {
  margin-bottom: 35px;
  padding: 0 50px;
}

label.searchLabel {
  font-size: 18px;
  color: #333333;
  margin-right: 10px;
}

.MuiOutlinedInput-notchedOutline {
  box-shadow: 1px 1px 1px #00000029;
  border: 0.5px solid #b5b5b5;
  border-radius: 4px;
}

.SOptionImg {
  margin-right: 10px;
  width: 34px;
  height: 34px;
}

.recentSearchToolbar p {
  margin-bottom: 0;
}

.recentSearchToolbar {
  background: #fff;
  padding: 17px 24px 11px;
  /* position: absolute; */
  width: 100%;
  z-index: 100;
  /* top: 40px;
  right: 0px;
  border-top: 0.5px solid #b5b5b5;
  border-radius: 4px 4px 0px 0px;
  border-right: 0.5px solid #b5b5b5;
  border-left: 0.5px solid #b5b5b5; */
}

.MuiAutocomplete-popper {
  /* top: 44px !important; */
  border-radius: 0px 0px 4px 4px;
  border-right: 0.5px solid #b5b5b5;
  border-left: 0.5px solid #b5b5b5;
  border-bottom: 0.5px solid #b5b5b5;
}

.MuiAutocomplete-paper {
  box-shadow: 0px 8px 16px #2f53971a !important;
  border-radius: 0 !important;
}

.RSClearAll p {
  color: #56accc;
  font-weight: normal;
}

/* .searchHeading {
  margin-bottom: 30px;
} */

.RSsearchSuffixWrapper {
  /* position: absolute;
  top: 290px; */
  z-index: 1301;
  background: #fff;
  width: 100%;
  padding: 5px 24px 20px;
  align-items: center;
  /* right: 0px;
  border-bottom: 0.5px solid #b5b5b5;
  border-radius: 0px 0px 4px 4px;
  border-right: 0.5px solid #b5b5b5;
  border-left: 0.5px solid #b5b5b5; */
}

.RSearchForText p {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: normal;
  color: #f97516;
}

.RSsearchSuffix {
  width: 34px;
  height: 34px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f97516;
  color: #fff;
  margin-right: 11px;
}

.RSsearchSuffix svg {
  width: 16px;
  height: 16px;
}

/* .MuiAutocomplete-noOptions {
  height: 210px;
  background: #fff;
}
.MuiAutocomplete-paper {
  height: 210px;
  background: #fff;
} */

.MuiAutocomplete-option {
  padding: 5px 16px !important;
}

.MuiAutocomplete-paper {
  margin: 0px !important;
}

/* ============== HOME > SEARCH CARD ============= */

.SCardWrapper {
  border-bottom: 1px solid #dededfbf;
  align-items: center;
  /* padding-bottom: 20px; */
  margin-bottom: 20px;
  padding: 0px 50px 20px;
}

.SCardWrapper .userImg img {
  width: 90px;
  height: 90px;
}

h2.SCuserName {
  margin-bottom: 2px;
  font-size: 18px;
  font-weight: bold;
}

p.SCmutualFriend {
  color: #b3b3b3;
  font-size: 13px;
  font-weight: normal;
  margin-bottom: 0;
}

.SCuserInfo {
  margin-left: 20px;
  flex: 1 1;
}

button.SCaddFriendFilled {
  font-size: 10px !important;
  text-transform: uppercase;
  padding: 0;
  width: 100px;
  height: 33px;
}

.SOptionImg img {
  width: 34px;
  height: 34px;
  border-radius: 100%;
}

.SOptionWrapper {
  align-items: center;
  margin-bottom: 10px;
}

.SOptionLabel p {
  margin-bottom: 0;
  font-size: 14px;
  color: #253137;
  margin-left: 11px;
}

button.SCRemoveFriendOutlined {
  color: #ff7272;
  font-size: 10px !important;
  font-weight: 600;
  background: #fff;
  border-color: #ff7272;
  width: 136px;
  height: 33px;
  padding: 0;
}

/* =================== HOME > PROFILE =========== */

.profieBannerWrapper {
  position: relative;
  height: 383px;
}

.profieBannerWrapper > img {
  position: absolute;
  width: 100%;
  height: 283px;
  object-fit: cover;
  /* z-index: 2; */
  top: 0;
}
.profileFlipWrapper {
  position: absolute;
  width: 100%;
  height: 283px;
  object-fit: contain;
  z-index: 2;
  top: 0;
}
.withoutProfileFlipWrapper {
  position: absolute;
  width: 100%;
  height: 283px;
  object-fit: cover;
  z-index: 2;
  top: 0;
}
.friendprofieBannerWrapper {
  position: relative;
  height: 283px;
}

.friendprofieBannerWrapper > img {
  position: absolute;
  width: 100%;
  height: 283px;
  object-fit: cover;
  /* z-index: 0; */
  border-radius: 10px 10px 0px 0px;
  top: 0;
}

.profilePictureEditProfile {
  /* z-index: 1; */
  position: relative;
  height: 100%;
  padding: 0 16px 0 80px;
  align-items: flex-end;
}

/* .profilePictureEditProfile > div:nth-child(1) {
  margin-bottom: 45px;
} */

.editProfileButton button {
  display: flex;
  align-items: center;
  font-size: 16px !important;
  font-weight: 600;
  /* background: #f97516; */
  border-radius: 9px !important;
  height: 53px;
  /* width: 192px; */
  /* padding: 0; */
  padding: 6px;
  justify-content: center;
  margin-bottom: 20px;
}

.editProfileButton button svg {
  margin-right: 10px;
}

.userDetailsWrapper {
  padding: 35px 56px 15px 40px;
}

.userInfoWrapper h2 {
  font-size: 28px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 10px;
  color: #242134;
}

p.userDesignation {
  font-size: 16px;
  color: #242134;
  margin-bottom: 5px;
}

p.useradditionalInfo {
  color: #707070;
  font-size: 14px;
  margin-bottom: 10px;
}

.pointsInfo {
  display: flex;
  width: 184px;
  height: 48px;
  align-items: center;
  border-radius: 4px 0px 0px 4px;
}

.pointsCount {
  width: 80px;
  height: 100%;
  background: #f97516;
  border: 1px solid #f97516;
  border-radius: 4px 0px 0px 4px;
  color: #fff;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pointsInfo div:nth-child(2) {
  background: #ffffff;
  border: 0.5px solid #707070;
  border-radius: 0px 4px 4px 0px;
  font-size: 12px;
  color: #f97516;
  height: 100%;
  display: flex;
  align-items: center;
  flex: 1 1;
}

.pointsInfo div:nth-child(2) p {
  position: relative;
  left: 15px;
  width: 70px;
}

.FriendsCardWrapper {
  box-shadow: 0px 1px 2px #00000029;
  border: 1px solid #f7f7f7;
  border-radius: 5px;
  background: #fff;
  padding: 16px 70px 16px 16px;
  align-items: center;
  margin-bottom: 25px;
}

.friendsinfo h2 {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  color: #333333;
}

.friendsinfo p {
  font-size: 13px;
  color: #b3b3b3;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.friendsinfo {
  margin-left: 18px;
  margin-top: 2px;
}

/* PROFILE 16 TO 20 */

.userInfoCardWrapper {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
  background: #fff;
  padding: 32px 45px;
  margin: 30px 25px;
}

p.UIUserName {
  font-size: 28px;
  font-weight: 600;
  color: #242134;
  line-height: 29px;
  margin-bottom: 5px !important;
}

p.UIDesignation {
  font-size: 17px;
  color: #242134;
  margin-bottom: 2px !important;
}

p.UILocation {
  font-size: 14px;
  color: #707070;
  margin-bottom: 20px !important;
}

.UILocation svg {
  font-size: 20px;
  margin-right: 8px;
}

.UiReputationCount {
  width: 80px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #999999;
  border-radius: 4px 0px 0px 4px;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
}

.UIReputationLabel {
  width: 70px;
  height: 48px;
  color: #f97516;
  font-size: 12px;
  display: flex;
  align-items: center;
  margin-left: 16px;
}

.UIDescription {
  font-size: 14px;
  color: #707070;
}

.UIDescription p {
  margin-bottom: 25px !important;
}

.UIDescription button {
  box-shadow: 0px 3px 6px #00000029;
  font-size: 13px !important;
}

.UIDescription button svg {
  font-size: 16px;
  margin-left: 3px;
}

/* ============================ COMMUNITY > TAB > ASK A QUESTIONS =============== */

.askQuestions {
  flex: 1 1;

  /* padding: 15px 0px 18px 50px; */
  padding: 15px 20px 18px 50px !important;
  min-height: 25px;
  text-align: right;
  color: #f97516;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.askQuestions a {
  color: #f97516;
  padding: 0;
}

.askQuestions p {
  display: inline-block;
  cursor: pointer;
}

.askQuestions_incalls {
  flex: 1 1;
  background: #dfe2e6;
  /* padding: 15px 0px 18px 50px; */
  padding: 15px 20px 18px 50px !important;
  min-height: 25px;
  text-align: right;
  color: #f97516;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.askQuestions_incalls a {
  color: #f97516;
  padding: 0;
}

.askQuestions_incalls p {
  display: inline-block;
  cursor: pointer;
}

.askQuestionWrapper {
  padding: 20px 56px 56px;
}

p.askAQuestionTitle {
  font-size: 32px;
  color: #263238;
  font-weight: 500;
  margin-bottom: 20px !important;
}

.askAQuestionCardWrapper {
  /* background: #f3f5f9; */
  background: #ffffff;
  box-shadow: 5px 5px 6px #0000000d;
  border: 1px solid #dededfbf;
  padding: 18px 35px 35px;
  border-radius: 10px;
}

label.formLabel {
  display: block;
  font-size: 16px;
  color: #263238;
  font-weight: 500;
  margin-bottom: 8px;
}

.askAQuestionInputGroup.inputLarge {
  margin-bottom: 25px;
  margin-top: 0;
}

.askAQuestionInputGroup input {
  border: 0.5px solid #b5b5b5;
  border-radius: 4px;
  width: 100%;
  color: #333333;
  padding: 0 22px;
  height: 40px;
}

.askAQuestionInputGroup.inputLarge input {
  height: 58px;
  font-size: 24px;
}

/* .askAQuestionInputGroup {
  margin: 24px 0;
} */

.askAQuestionBtn button {
  width: 100%;
  height: 40px;
}

p.bookACallLabel {
  margin-right: 15px !important;
}

.askAQuestionInputGroup .tagMenuFilter {
  background: #fff;
}

.rdw-link-modal-btn,
.rdw-embedded-modal-btn,
.rdw-image-modal-btn {
  background: linear-gradient(to right, #ef5a3e 5%, #a00030 100%) !important;
  border: 1px solid #f97516 !important;
  border-radius: 4px !important;
  color: #fff;
  font-size: 11px !important;
  padding: 5px 15px;
}

.rdw-link-modal-btn:disabled,
.rdw-embedded-modal-btn:disabled,
.rdw-image-modal-btn:disabled {
  background: #f97516;
  opacity: 0.5;
}

.rdw-link-modal-buttonsection .rdw-link-modal-btn:nth-child(2),
.rdw-embedded-modal-btn-section .rdw-embedded-modal-btn:nth-child(2),
.rdw-image-modal-btn-section .rdw-image-modal-btn:nth-child(2) {
  background: #fff !important;
  color: #f97516 !important;
}

.rdw-link-modal,
.rdw-embedded-modal,
.rdw-image-modal {
  height: max-content !important;
}

.rdw-link-modal-buttonsection,
.rdw-embedded-modal-btn-section,
.rdw-image-modal-btn-section {
  margin: 0 !important;
  justify-content: flex-start !important;
}

.rdw-link-modal-btn:nth-child(1),
.rdw-embedded-modal-btn:nth-child(1),
.rdw-image-modal-btn:nth-child(1) {
  margin-left: 0 !important;
}

/* =============== COMMUNITY > SUBJECTS ============ */

.subjectsWrapper {
  /* padding: 30px 30px 60px;
  background: white !important; */
}

.chatWrapper {
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  /* flex-wrap: nowrap;
  overflow-x: hidden;
  overflow-y: auto; */
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  height: 200px;
}

.chatWrapperMyEvent {
  /* flex-wrap: nowrap;
  overflow-x: hidden;
  overflow-y: auto; */
  height: 100px;
  margin-top: 8px;
  margin-left: 0px;
}

.chatWrapperChallenge {
  /* flex-wrap: nowrap;
  overflow-x: hidden;
  overflow-y: auto; */
  height: 100px;
  margin-top: 8px;
  margin-left: 20px;
}

p.titleLarge {
  font-size: 32px;
  color: #263238;
  font-weight: 500;
  margin-bottom: 10px !important;
}

.subjectInput .MuiOutlinedInput-input {
  padding: 10px 15px 10px 0px;
}

.subjectInput fieldset {
  border: 1px solid #c7c7c780;
  border-radius: 4px;
  box-shadow: none;
}

.toggleButtonWrapper {
  text-align: right;
}

.toggleButtonWrapper .Mui-selected {
  background: #a00030 !important;
}

.subjectInput .MuiInputAdornment-positionStart svg {
  color: #c9c9c9;
}

.creategroupButton {
  /* style={{ paddingLeft: "90%", paddingTop: "30" } */
  padding-left: 590px;
  padding-bottom: 50px;
}

.toggleButtonWrapper .Mui-selected {
  background: #a00030 0% 0% no-repeat padding-box;
  color: #fff !important;
}

.toggleButtonWrapper button {
  font-size: 15px !important;
  color: #a00030 !important;
  border-radius: 0 !important;
  padding: 10px 0px 10px 0px !important;
  text-transform: capitalize;
}

.subjectCardWrapper {
  border: 1px solid #ececec;
  border-radius: 4px;
  padding: 16px 24px 25px;
  cursor: pointer;
}

.upcomingCardWrapper {
  border: 1px solid #f7f7f7;
  background: #ffffff;
  border-radius: 4px;
  padding: 16px 16px 16px;
  cursor: pointer;
  margin-right: 20px;
  box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.05);
}

.insideupcomingCardWrapper {
  border: 1px solid #e4e4e4;
  padding-bottom: 40px;
}

.insideupcomingCardWrapperPS {
  border: 1px solid #e4e4e4;
}

p.engageLabel {
  font-size: 11px;
  font-weight: 300;
  color: #333333;
  margin-bottom: 10px !important;
}

.subjectTitle {
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

p.subjectName {
  font-size: 20px;
  font-weight: 600;
  color: #f97516;
}

.subjectTitleUE {
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.subjectTitleGroups {
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 10px;
}

p.subjectNameUE {
  font-size: 20px !important;
  font-weight: 600 !important;
  color: #000000 !important;
  padding: 12px !important;
}

.followCount {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.followCountBg {
  width: 24px;
  height: 24px;
  background: #999999;
  border-radius: 4px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

p.followFriends {
  font-size: 11px;
  font-weight: 500;
  color: #333333;
  line-height: 12px;
}

p.questionWeeks {
  font-size: 11px;
  color: #333333;
  font-weight: 500;
}

/* ================ COMMUNITY CARD ================ */

.communityCardWrapper {
  margin-bottom: 24px;
}

.upvotesCount {
  flex-basis: 72px;
  height: 54px;
  background: #21ba45;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 24px;
}

.upvotesQuestion {
  flex: 1 1;
}

p.upvotesCountLabel {
  font-size: 20px;
  line-height: 21px;
  margin-bottom: 2px !important;
  font-weight: 500;
}

p.upvotesLabel {
  font-size: 12px;
  line-height: 13px;
}

.upvotesQuestion p {
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
}

.upvotesTitle {
  margin-bottom: 14px;
  align-items: center;
  padding: 24px 24px 0;
}

.askedonTagWrapper > p:nth-child(1) {
  font-size: 14px;
  font-weight: 500;
  color: #999999;
}

.askedonTagWrapper > p:nth-child(1) > span {
  color: #242134;
  margin-left: 12px;
}

.askedonCard {
  background: #9999994d;
  border-radius: 4px;
  align-items: center;
  padding: 7px;
}

.askedImg img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
}

p.askedName {
  font-size: 14px;
  font-weight: 500;
  color: #242134;
}

p.askedCount {
  font-size: 14px;
  color: #24213480;
  font-weight: 500;
}

.askedInfo {
  margin-left: 8px;
}

.askedImg {
  width: 40px;
  height: 40px;
}

.askedonTagWrapper {
  width: 168px;
}

.askedTagInfoWrapper .askedonTagWrapper {
  margin-left: auto;
  margin-bottom: 24px;
}

.askedTagInfoWrapper {
  padding: 0 24px;
}

.likeCommentWrapper {
  border-top: 1px solid #f7f7f7;
  border-bottom: 1px solid #f7f7f7;
  padding: 11px 25px;
}

.borderLineUE {
  border-top: 1px solid #e4e4e4;
}
.askedTagLikeCommentWrapper {
  margin-bottom: 16px;
}

.likeCommentIconLabel p {
  font-size: 14px;
  font-weight: 500;
}

.likeCommentIconLabel svg {
  font-size: 24px;
  margin-right: 5px;
}

.likeCommentIconLabel {
  align-items: center;
  cursor: pointer;
}

.likedInfo {
  align-items: center;
  margin-left: 20px;
}

.likedIconGroups .MuiAvatar-root {
  width: 17px;
  height: 17px;
}

.likedIconGroups .MuiAvatar-root svg {
  font-size: 9px;
}

.likedUserNames {
  font-size: 13px;
  font-weight: 500;
}

.wallButtonWrapper button {
  width: 100%;
  height: 40px;
  font-size: 13px !important;
  text-transform: uppercase;
}

.wallButtonWrapper {
  padding: 0 24px 24px;
}

.communityCardQuestion {
  padding: 24px;
  align-items: flex-start;
  background-color: #f5f5f5 0 0 no-repeat padding-box !important;
}

p.AYCMinsAgo {
  font-size: 13px;
  color: #b3b3b3;
  margin-bottom: 3px;
}

h2.CCTitle {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}

.CCTitle span {
  color: #999999;
}

.communityCardUserImg {
  margin-right: 16px;
}

.likedIcon {
  width: 17px;
  height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}

.like {
  background: #21ba45 !important;
}

.favorite {
  background: #db2828 !important;
}

.likedIcon svg {
  font-size: 9px;
  color: #fff;
}

.CCnoUserFound {
  width: 64px;
  height: 64px;
}

.CCnoUserFound svg {
  font-size: 64px;
  color: #db2828;
}

p.communityCardDescription {
  font-size: 13px;
  color: #33333380;
  font-weight: 300;
  margin-top: 18px !important;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.badgeWrapper {
  display: inline-block;
  margin-right: 8px;
  background: #f3f5f9;
  border-radius: 4px;
  font-size: 14px;
  color: #242134;
  padding: 5px 10px;
}

.CCtitleGrey {
  color: #82b0c5;
}

.badgeMainWrapper {
  margin-top: 12px;
}

.wallMainWrapper {
  padding: 25px 0px;
}

p.loadmoreText {
  font-size: 12px;
  color: #333333;
  text-align: center;
  font-weight: 500;
  cursor: pointer;
}

.suggestedCard {
  padding: 20px 24px 17px;
  border-bottom: 0.5px solid #dededf;
}

p.suggCardTitle {
  font-size: 11px;
  font-weight: 300;
  margin-bottom: 10px !important;
}

.suggSubjectName p {
  font-size: 20px;
  color: #242134;
  font-weight: 600;
}

.suggSubjectName {
  margin-bottom: 10px;
}

.followIcon {
  background: #f97516;
  color: #fff;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.followIcon svg {
  font-size: 14px;
}

.suggFriendsCount {
  width: 24px;
  height: 24px;
  background: #999999;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
  margin-right: 5px;
}

p.followText {
  font-size: 11px;
  color: #333333;
  font-weight: 500;
}

.suggFriendsWrapper {
  align-items: center;
  margin-bottom: 24px;
}

p.SFQuestionInWeek {
  font-size: 11px;
  font-weight: 500;
  color: #333333;
}

.followIconButton {
  align-items: center;
  justify-content: center;
  padding: 13px 0 11px;
}

.followIconButton p {
  font-size: 14px;
  color: #242134;
  font-weight: 600;
}

.followIconButton svg {
  font-size: 16px;
  color: #000000;
  margin-right: 5px;
}

.suggestedCardWrapper {
  margin-bottom: 24px;
}

.eventUserName {
  font-size: 13px;
  color: #333333;
  font-weight: 500;
  margin-left: 12px;
}

.eventUserName span {
  color: #999999;
}

.sessionCardWrapper {
  background: #f97516;
  box-shadow: 0px 3px 20px #00000036;
  border-radius: 4px;
  padding: 16px 24px 25px;
  margin-bottom: 24px;
}

.todayText {
  color: rgba(255, 255, 255, 0.5);
  font-size: 13px;
  font-weight: bold;
}

.moreItemsIcon svg {
  color: #fff;
}

.sessionCardToolbar {
  margin-bottom: 17px;
}

p.sessionName {
  font-size: 21px;
  font-weight: bold;
  color: #ffffff;
}

p.sessionUserName {
  font-size: 15px;
  font-weight: 500;
  color: #fff;
}

.sessionCardUserInfo {
  margin-bottom: 20px;
}

.sessionCardEventDate,
.sessionCardEventTime {
  font-size: 15px;
  color: #fff;
}

.sessionCardEventDateIon svg,
.sessionCardEventTimeIon svg {
  font-size: 16px;
  color: #fff;
}

.sessionCardEventDateIon,
.sessionCardEventTimeIon {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.joinSessionBtn {
  margin-top: 34px;
}

.joinSessionBtn button {
  width: 100%;
  background: #ffffff;
  color: #f97516;
  font-size: 13px;
  font-weight: 600;
  height: 36px;
}

.suggestedCard {
  padding: 20px 24px 17px;
  border-bottom: 0.5px solid #dededf;
}

p.suggCardTitle {
  font-size: 11px;
  font-weight: 300;
  margin-bottom: 10px !important;
}

.suggSubjectName p {
  font-size: 20px;
  color: #242134;
  font-weight: 600;
}

.suggSubjectName {
  margin-bottom: 10px;
}

.followIcon {
  background: #f97516;
  color: #fff;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.followIcon svg {
  font-size: 14px;
}

.suggFriendsCount {
  width: 24px;
  height: 24px;
  background: #999999;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(255, 255, 255);
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
  margin-right: 5px;
}

p.followText {
  font-size: 11px;
  color: #333333;
  font-weight: 500;
}

.suggFriendsWrapper {
  align-items: center;
  margin-bottom: 24px;
}

p.SFQuestionInWeek {
  font-size: 11px;
  font-weight: 500;
  color: #333333;
}

.followIconButton {
  align-items: center;
  justify-content: center;
  padding: 13px 0 11px;
}

.followIconButton p {
  font-size: 14px;
  color: #242134;
  font-weight: 600;
}

.followIconButton svg {
  font-size: 16px;
  color: #000000;
  margin-right: 5px;
}

.suggestedCardWrapper {
  margin-bottom: 24px;
}

.bdayUpdates {
  padding: 16px 12px 0px;
  margin-bottom: 24px;
}

.eventUserName {
  font-size: 13px;
  color: #333333;
  font-weight: 500;
  margin-left: 12px;
}

.eventUserName span {
  color: #999999;
}

.sessionCardWrapper {
  background: #f97516;
  box-shadow: 0px 3px 20px #00000036;
  border-radius: 4px;
  padding: 16px 24px 25px;
  margin-bottom: 24px;
}

.todayText {
  color: rgba(255, 255, 255, 0.5);
  font-size: 13px;
  font-weight: bold;
}

.moreItemsIcon svg {
  color: #fff;
}

.sessionCardToolbar {
  margin-bottom: 17px;
}

p.sessionName {
  font-size: 21px;
  font-weight: bold;
  color: #ffffff;
}

p.sessionUserName {
  font-size: 15px;
  font-weight: 500;
  color: #fff;
}

.sessionCardUserInfo {
  margin-bottom: 20px;
}

.sessionCardEventDate,
.sessionCardEventTime {
  font-size: 15px;
  color: #fff;
}

.sessionCardEventDateIon svg,
.sessionCardEventTimeIon svg {
  font-size: 16px;
  color: #fff;
}

.sessionCardEventDateIon,
.sessionCardEventTimeIon {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.joinSessionBtn {
  margin-top: 34px;
}

.joinSessionBtn button {
  width: 100%;
  background: #ffffff;
  color: #f97516;
  font-size: 13px;
  font-weight: 600;
  height: 36px;
}

.becameFriendsCardWrapper {
  background: #ffffff;
  border: 1px solid #ececec;
  border-radius: 4px;
}

.becameFriendsHeading {
  border-bottom: 0.5px solid #dededf;
  text-align: center;
}

.becameFriendsHeading h3 {
  margin: 0;
  font-size: 18px;
  font-weight: 900;
  color: #333333;
  padding: 16px 0 18px;
}

.becameFriendsLists {
  padding: 16px 24px 0px;
}

.becameFriendList {
  margin-bottom: 16px;
}

.becameFriendUserName p {
  color: #999999;
  font-size: 15px;
  font-weight: bold;
}

.becameFriendUserName {
  margin-left: 16px;
}

.bookGroupCallBtn button {
  width: 100%;
  font-size: 13px !important;
  text-transform: uppercase;
  font-weight: 600;
  height: 36px;
}

.bookGroupCallBtn {
  padding: 0 24px 24px;
}

.likeOptions {
  display: flex;
  position: absolute;
  top: -35px;
  background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;
  border: 1px solid #dededf;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
  width: 70px;
  align-items: center;
  justify-content: space-evenly;
}

.likeOption svg {
  font-size: 20px;
  margin-top: 4px;
}

.likeOption {
  height: 30px;
  cursor: pointer;
}

.likeOption svg[title="Favorite"] {
  color: #db2828;
}

.likeOption svg[title="Like"] {
  color: #21ba45;
}

.likeOption svg[title="Favorite"]:hover {
  color: #db2828 !important;
}

.likeOption svg[title="Like"]:hover {
  color: #21ba45 !important;
}

.dashboardSidebar {
  border: 1px solid #ececec;
  border-radius: 4px;
  background: #fff;
}

.myQuestionsWrapper {
  margin-bottom: 32px;
}

.sidebarTitle h2 {
  font-size: 18px;
  color: #333333;
  font-weight: 900;
}

.sidebarTitle {
  padding: 16px;
  text-align: center;
  border-bottom: 1px solid #ececec;
}

.myQuestionWrapper {
  padding: 25px;
}

.myQuestion,
.RVQQuestion div {
  font-size: 16px;
  font-weight: 500;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.questionActions {
  margin-bottom: 10px;
}

.questionActions {
  font-size: 16px;
  font-weight: 500;
}

.questionActions div {
  margin-right: 15px;
}

.myQuesLikes {
  color: #21ba45;
}

.myQuesLikes svg {
  font-size: 25px;
}

.myQuesLikes .arrowBefore {
  width: 24px;
  height: 12px;
  border-left: 13px solid transparent;
  border-right: 13px solid transparent;
  border-bottom: 13px solid #21ba45;
  margin: 0;
  margin-top: 5px;
  margin-right: 3px;
}

.myQuesComments {
  color: #db2828;
}

.myQuesComments svg {
  margin-right: 3px;
}

.myQuesViews {
  color: #999999;
}

.myQuesViews svg {
  margin-right: 3px;
}

.myQuestionsPagination {
  padding: 10px 10px 10px 10px;
  margin-top: 0px !important;
  overflow-y: scroll;
}

.myQuestionsPagination nav.MuiPagination-root {
  margin-top: 0;
}

.RVQQuestion {
  padding: 25px;
}

.RVQQuestion div {
  -webkit-line-clamp: 2;
}

.paginationWrapper .MuiPagination-ul {
  justify-content: center;
}

/* ================ LOGIN SCREEN ============== */

/* ====================== LOGIN =============== */

/* .loginContainer {
  background-image: url("./images/cover_page.png");
  background-size: inherit;
  background-position: 65% 45%;
  background-repeat: no-repeat;
  height: 100vh;
  padding: 30px 0;
} */

.loginContainer {
  background-image: url(/static/media/cover_page.a2cfb071.png);
  /* background-size: contain; */
  background-position: right;
  background-repeat: no-repeat;
  height: 100vh;
  padding: 30px 0;
  background-size: 100%;
}

.rightlogin {
  position: absolute;
  right: 10%;
  top: 10%;
}

.loginQuotes {
  font-size: 18px;
  font-family: inherit;
}

.loginHeaderWrapper {
  margin: 5% 0;
}

.formInput label {
  display: block;
  color: #333333de;
  font-size: 13px;
  margin-bottom: 5px;
}

.formInput input {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dededf;
  border-radius: 4px;
  width: 260px;
  height: 10px;
  padding: 15px;
  font-size: 13px;
  margin-bottom: 20px;
}

.passwordInput input {
  margin-bottom: 5px;
}

.forgotPassword {
  width: 288px;
  text-align: right;
}

.forgotPassword a {
  text-decoration: underline;
  letter-spacing: 0px;
  color: #333333de;
  font-size: 13px;
}

.formButton {
  margin-bottom: 15%;
}

.formButton .btnBlack {
  background: #f97516 0% 0%;
  border-radius: 4px;
  width: 106px;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  padding: 6px 15px;
  background-image: linear-gradient(to right, #f97516 5%, #f97516 100%);
}

.signUpLogo {
  border: 2px solid #f97516;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 15px 7px 8px;
  margin-right: 10px;
  cursor: pointer;
  font-size: 12px;
}

.googleLogo {
  border: 2px solid #e84133;
  color: #e84133;
}

.iamLogo {
  border: 2px solid #5a5b5d;
  color: #5a5b5d;
}

.emailLogo {
  border: 2px solid #77b3d4;
  color: #77b3d4;
}

.signUpLogo p {
  margin-left: 5px;
}

.signupTxt p {
  font-size: 11px;
  margin-right: 10px;
  font-weight: bold;
}

.loginContainer .MuiContainer-root {
  max-width: 1140px;
}

/* ====================== COMMUNITY QUESTIONS ================== */

.questionVotes p {
  color: #263238;
  font-size: 24px;
  text-align: center;
}

.questionVotes small {
  color: #999999;
  font-size: 12px;
  display: block;
  text-align: center;
  margin-bottom: 10px;
}

.questionVotes svg {
  color: #21ba45;
  display: block;
  margin: 0 auto;
}

.questionVotes {
  margin: 0px 20px;
}
.questionCardWrapper {
  display: flex;
  /*align-items: center;*/
  align-items: flex-start;
  padding-bottom: 10px !important;
  /*padding-bottom: 40px;*/
  border-bottom: 1px solid rgba(179, 179, 179, 0.25);
  /*margin-bottom: 15px;*/
  /* cursor: pointer; */
}

.questionCardWrapper:hover .questionCardQuestion > p {
  color: #f97516;
}

.questionCardWrapper > div:last-child {
  /* margin-left: 35px; */
  width: 100%;
}

.questionCardQuestion p {
  color: #333333;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px !important;
}

.subjectTag {
  font-size: 11px;
  color: #242134;
  padding: 8px 14px;
  background: #f3f5f9;
  border-radius: 4px;
  display: inline-block;
  cursor: pointer;
}

.questionCardMessageWrapper {
  position: relative;
  color: #db2828;
  font-size: 14px;
}

.questionCardMessageWrapper svg {
  position: absolute;
  width: 20px;
  height: 20px;
}

.questionCardMessageWrapper span {
  position: absolute;
  top: 0;
  left: 27px;
}

.messageIconWrapper {
  display: inline-block;
  color: #db2828;
  position: relative;
}

.viewsIconWrapper {
  position: relative;
  display: inline-block;
  color: #999999;
}

.messageIconWrapper span,
.viewsIconWrapper span {
  font-size: 14px;
  position: relative;
  left: 28px;
  top: -2px;
}

.messageIconWrapper svg,
.viewsIconWrapper svg {
  width: 23px;
  height: 23px;
  position: absolute;
}

.questionCardDetailsWrapperLeft > div:first-child {
  margin-right: 50px;
}

/* .questionCardTagsWrapper .subjectTag {
  margin-right: 10px;
} */

.questionCardTagsWrapper .subjectTag {
  /* border-radius: 30px; */
  margin-right: 10px;
  /* border-style: outset; */
  border-color: gray;
  border-width: 1px;
  background-color: white;
}

.questionCardTagsWrapper {
  margin-top: 10px;
}

.questionCardDetailsWrapper {
  display: flex;
  justify-content: space-between;
}

.questionCardDetailsWrapperRight {
  margin: 0 20px;
}

.questionCardDetailsWrapperRight .askedName {
  font-size: 14px;
  margin: 0 !important;
}

.questionCardDetailsWrapperRight .askedCount {
  font-size: 14px;
  color: #24213480;
}

.questionCardDetailsWrapperRight .askedonTagWrapper p {
  margin: 0 !important;
  font-size: 13px;
}

.questionFilterInputWrapper label,
.CTInputWrapper label {
  display: block;
  font-size: 16px;
  color: #333333;
  margin-bottom: 3px;
}

.questionFilterInputWrapper {
  display: inline-block;
  margin-right: 16px;
  max-width: 200px;
}

.questionFilterInputWrapper input,
.CTInputWrapper input,
.CTInputWrapper select {
  max-width: 200px;
  border: 1px solid #c7c7c780;
  border-radius: 4px;
  padding: 5px 10px;
}

.questionsFilterWrapper {
  background: #f4f4f46f;
  padding: 20px 55px;
}

.questionWrapperTitle p {
  margin: 0 !important;
}

.questionWrapperTitle {
  /* padding: 20px 55px; */
  /* padding: 20px 0px; */
  padding: 0px 0px 20px 0px;
}

.paginationWrapper input {
  max-width: 50px;
  border: 1px solid #dededf;
  border-radius: 4px;
  padding: 5px 10px;
}

.paginationWrapper {
  display: flex;
  align-items: center;
  margin-top: 30px;
  color: #8d8d8d;
  justify-content: center;
}

.paginationWrapperRight {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.paginationWrapper nav {
  margin: 0;
}

.paginationWrapper ul li button {
  background: #e8ecef;
  border: none;
  color: #8d8d8d;
}

.paginationWrapper .MuiPaginationItem-ellipsis {
  color: #8d8d8d;
}

.paginationWrapper .MuiPaginationItem-page.Mui-selected {
  background: #f97516;
  color: #fff;
}

/* =================== ANSWER SCREEN ============ */

.answersWrapper {
  /* padding: 20px 56px; */
  padding: 0px 0px;
}

.ansBackQuestion {
  margin-bottom: 15px;
  color: #a00030 !important;
  cursor: pointer;
  align-items: center;
}

.backEventorGroup {
  margin-bottom: 8px;
  margin-top: 8px;
  color: #a00030;
  cursor: pointer;
  align-items: center;
}

.ansBackQuestion p {
  font-size: 16px;
  font-weight: 500;
  margin-left: 5px !important;
}

.ansBackQuestion svg {
  font-size: 20px;
}

p.ansCount {
  position: relative;
  font-size: 24px;
  color: #263238;
  font-weight: 500;
  text-align: center;
  padding: 10px 0;
  line-height: 25px;
}

.arrowBefore {
  width: 42px;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid #33333380;
  margin: auto;
  cursor: pointer;
}

.arrowAfter {
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #33333380;
  width: 42px;
  margin: auto;
  cursor: pointer;
}

.answerCountUserImgWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.answersWrapper .answeCountArrowGrid {
  max-width: 10.333333%;
  flex-basis: 10.333333%;
}

.answersWrapper .answeCardGrid {
  max-width: 89.666667%;
  padding-left: 0 !important;
  flex-basis: 89.666667%;
}

.ACUserNameBadge {
  border-radius: 12px;
  background-color: #c7c7c7;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  padding: 1px 7px 1px 10px;
  margin-right: 10px;
  min-height: 26px;
  max-width: max-content;
}

.ACUserName {
  align-items: center;
  margin-bottom: 15px;
}

p.ACAnsweredOn {
  font-size: 14px;
  font-weight: 600;
  color: #999;
}

.answerVideo video {
  border-radius: 8px;
}

.answerVideo {
  margin-top: 15px;
}

.ACAnsweredOn span {
  margin-left: 8px;
  color: #242134;
}

.questionAnswerCardWrapper {
  box-shadow: 5px 5px 6px #0000000d;
  border: 1px solid #dededfbf;
  border-radius: 4px;
  background: #fff;
  margin-bottom: 70px;
}

.QCHeader {
  padding: 23px 33px;
}

.QCQuestion {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px !important;
}

p.QCSeeMoreText {
  color: #999999;
  font-size: 14px;
  font-weight: 500;
  margin-right: 15px !important;
}

.QCTag {
  border-radius: 2px;
  background: #f97516;
  color: #fff;
  padding: 2px 5px;
  margin-right: 10px;
  font-size: 11px;
  font-weight: 500;
}

.QCPostedOn p {
  font-size: 14px;
  color: #999999;
  font-weight: 500;
}

.QCPostedOn span {
  color: #000;
  margin-left: 15px;
}

.QCQuestionInfoBanner {
  padding: 6px 33px;
  background: #f3f5f9;
  font-size: 14px;
  color: #999999;
  font-weight: 500;
}

.QCQuestionInfoBanner strong {
  font-weight: 500;
  color: #242134;
  margin-left: 8px;
}

.QCQuestionInfoBanner p {
  margin-right: 25px !important;
}

.QCQuestionDescribed {
  padding: 18px 33px 40px;
  font-size: 16px;
  color: #333333;
}

.QCQuestionDescribed p {
  font-size: 16px;
  font-weight: 300;
}

.QCFooter {
  padding: 0 33px 26px;
}

.shareFollowBtn button {
  font-size: 16px !important;
  font-weight: 600;
  text-transform: uppercase;
  margin-right: 15px;
  display: flex;
}

.shareFollowBtn button svg {
  font-size: 21px;
  margin-right: 2px;
}

p.ansCountInfo {
  font-size: 24px;
  color: #263238;
  margin-bottom: 14px !important;
}

.answerVerfiedIcon {
  fill: #f97516;
  margin-top: 30px;
}

.answerCardWrapper {
  border: 1px solid #dededf;
  border-radius: 4px;
  padding: 15px 19px 21px;
}

.answersGrid {
  margin-bottom: 15px !important;
}

.ACFooter {
  align-items: flex-end;
  margin-top: 25px;
}

.ACTitle {
  font-size: 16px;
  font-weight: bold;
}

p.ACAnswer {
  font-size: 16px;
  font-weight: 300;
  /* word-break: break-all; */
  word-break: normal !important;
}

.ACAskedTag > p:nth-child(1) {
  font-size: 16px;
  color: #999999;
  font-weight: 500;
}

.ACAskedTag > p:nth-child(1) span {
  color: #000;
  margin-left: 10px;
}

.ACAskedTag .askedonTagWrapper {
  margin-left: auto;
}

/* ============================ TECT EDITOR  =================== */

.DraftEditor-editorContainer {
  border: 1px solid #dededf !important;
  /* min-height: 205px; */
  background: #f2f2f233 !important;
  padding: 5px;
}

.public-DraftStyleDefault-block {
  margin: 0 !important;
}

.rdw-block-dropdown span {
  color: #000;
}

.rdw-fontsize-wrapper span {
  color: #000;
}

.rdw-fontfamily-wrapper span {
  color: #000;
}

.rdw-editor-toolbar {
  background: #99999917 !important;
  margin-bottom: 0 !important;
}

p.yourAnswer {
  font-size: 24px;
  color: #263238;
  font-weight: 500;
  margin: 33px 0 5px !important;
}

.yourAnswerBtn button {
  height: 44px;
  width: 100%;
  margin-top: 10px;
  font-size: 15px;
  font-weight: 600;
}

/* ============= CALL SCREEN ============ */

.callMainWrapper {
  background: #fff;
  padding: 46px 56px;
}

.upcomingCallCarouselWrapper p.boldLabel {
  font-size: 18px;
  color: #263238;
  text-transform: uppercase;
  padding-bottom: 32px;
}

.UCCWrapper {
  background: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  padding: 25px 16px;
  position: relative;
}

.UCCActive {
  background: #f97516;
  color: #fff;
  border: 1px solid #f97516;
}

.UCCCurrentCourse {
  background: #5dba73;
  color: #fff;
  border: 1px solid #5dba73;
}

.UCCResumeBtn {
  background: #fff;
  color: #5dba73;
}

.UCCHeader {
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 25px 0px 32px;
}

.UCCActive .UCCSessionDay {
  color: #edfffc;
}

.UCCSessionDay {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px !important;
}

.UCCSessionName {
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 10px;
}

.UCCUserName div {
  width: 18px;
  height: 18px;
  background: #f0f0f0;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999999;
  min-width: 18px;
  cursor: pointer;
  margin-left: 5px;
}

.UCCUserName svg {
  font-size: 10px;
}

.UCCUserName {
  display: flex;
  justify-content: flex-start;
  font-size: 16px;
  line-height: 17px;
  font-weight: 400;
  min-height: 56px;
  padding: 0 25px 22px 32px;
  width: 90%;
}

.UCCActive .UCCUserName div {
  background: #fff;
}

.UCCSchduleDay {
  font-size: 12px;
  padding-bottom: 10px;
}

.UCCSchduleDay svg {
  font-size: 16px;
  margin-right: 7px;
}

.UCCButtonWrapper button {
  width: 100%;
  height: 33px;
  text-transform: uppercase;
  font-size: 12px !important;
  font-weight: 600;
}

.UCCButtonWrapper {
  display: flex;
}

.UCCButtonWrapper button:nth-child(1) {
  margin-right: 16px;
}

.UCCStartBtn {
  background: #fff;
  color: #f97516;
}

.outlinedBtn {
  background: none;
  border: 1px solid #f97516;
  color: #f97516;
}

.UCCActive .outlinedBtn {
  color: #fff;
  border: 1px solid #fff;
}

.UCCUserImg .MuiAvatar-root {
  width: 42px;
  height: 42px;
  box-shadow: 0px 0px 5px #00000029;
  border: 3px solid #ffffff;
  background: #f97516;
  font-size: 14px;
  margin-left: -22px;
}

div.UCCUserImg > div > div:nth-child(1) {
  z-index: 0 !important;
}

div.UCCUserImg > div > div:nth-child(2) {
  z-index: 1 !important;
}

div.UCCUserImg > div > div:nth-child(3) {
  z-index: 2 !important;
}

.UCCScheduleTiming {
  padding: 0 25px 22px 32px;
}

.upcomingCallCarouselWrapper .slick-slide > div {
  margin: 0 5px;
}

.yourFriendsWrapper {
  background: #f3f5f9;
  margin-top: 39px;
}

.YFTitleWrapper.flexDefault.flexAlignCenter.flexJustifySpacebetween {
  border-bottom: 1px solid #dededf;
  padding: 20px 30px;
  margin-bottom: 30px;
}

p.YFTitle {
  font-size: 16px;
  font-weight: 600;
  color: #242134;
  line-height: 17px;
}

p.YFBookGroupCall {
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  color: #f97516;
  cursor: pointer;
}

.YFCardWrapper {
  padding: 35px 30px;
}

.YFSeeMoreBtnWrapper {
  padding: 0 30px 40px;
  text-align: center;
}

img.FACCoverImg {
  width: 100%;
  height: 90px;
  border-radius: 4px 4px 0px 0px;
}

.homePostPopup {
  border-radius: 10px 10px 0px 0px;
}

.FACHeader > div {
  margin: auto;
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
}

.FACWrapper {
  position: relative;
  border: 1px solid #ececec;
  border-radius: 4px;
  background: #fff;
}

.FACUserInfo {
  padding-top: 40px;
  text-align: center;
}

.FACUserName {
  font-size: 20px;
  color: #242134;
  font-weight: 600;
  padding-bottom: 3px;
  line-height: 21px;
}

.FACGender {
  font-size: 16px;
  font-weight: 600;
  color: #999999;
}

.FACBtnWrapper {
  padding: 20px 15px;
  display: flex;
}

.FACBtnWrapper button {
  width: 100%;
  padding: 0;
  display: flex;
  height: 33px;
  align-items: center;
  justify-content: center;
  font-size: 11px !important;
}

.FACBtnWrapper button svg {
  font-size: 20px;
  margin-right: 5px;
}

.FACChatBtn {
  margin-left: 10px;
}

.YFSeeMoreBtnWrapper button {
  width: 136px;
  height: 33px;
  font-size: 14px !important;
}

.yourFriendsWrapper .tabWrapper {
  background: white;
}

.yourFriendsWrapper .tabWrapper > div:nth-child(1) {
  border-bottom: 1px solid #dededf;
}

.bookACallTitleWrapper h2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.BACTitle {
  font-size: 20px;
  font-weight: 600;
  color: #000000;
}

.bookACallTitleWrapper button {
  background: #d9d9d9;
  padding: 0;
  width: 28px;
  height: 28px;
  border-radius: 100% !important;
}

.bookACallTitleWrapper button svg {
  font-size: 20px;
}

.bookACallTitleWrapper {
  padding: 20px 35px 35px !important;
}

.formGroup label {
  display: block;
  font-size: 16px;
  font-weight: 600;
  color: #999999;
  line-height: 17px;
  margin-bottom: 8px;
}

.BACContentWrapper {
  padding: 0 35px 16px !important;
}

.BACDuration {
  border: 1px solid #dededf;
  border-radius: 12px;
  width: 130px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
  color: black;
  cursor: pointer;
  margin-right: 10px;
  padding: 10px;
}

.BACDurationWrapper {
  padding: 32px 0 20px;
}

.BACDuration.BACActive {
  background: #a00030;
  color: #fff;
  border: 1px solid #a00030;
}

.BACNotes .MuiOutlinedInput-multiline {
  width: 100%;
  padding: 0;
}

.BACNotes textarea {
  width: 100%;
  padding: 15px;
}

.BACScheduleCallBtn button {
  width: 100%;
  height: 32px;
  font-size: 12px;
  text-transform: uppercase;
}

.BACScheduleCallBtn {
  padding: 16px 35px 32px !important;
}

.formGroup .toggleButtonWrapper {
  text-align: left;
}

.formGroup .toggleButtonWrapper button {
  padding: 0;
  height: 32px;
  width: 91px;
  font-size: 14px !important;
  font-weight: 300 !important;
}

.formGroup .MuiToggleButtonGroup-root {
  width: 100%;
}

.SCContentWrapper {
  text-align: center;
  padding: 0 30px 40px;
}

.SCContentWrapper div:nth-child(1) {
  margin: auto;
  margin-bottom: 40px;
}

p.SCTitle {
  font-size: 20px;
  color: #000;
  font-weight: 600;
  padding-bottom: 15px;
}

p.SCSUbTitle {
  font-size: 16px;
  color: #999999;
  font-weight: 600;
}

.SCCallBtn button {
  width: 100%;
  height: 32px;
  font-size: 12px !important;
  text-transform: uppercase;
}

.SCCallBtn {
  padding: 45px 30px 25px !important;
}

#scheduledCall {
  padding: 16px 14px !important;
}

.particiapntsTag {
  width: 100%;
  height: 88px;
  border: 1px solid #dededf;
  border-radius: 2px;
  margin-bottom: 32px;
  padding: 8px;
  overflow: hidden auto;
}

.BGCGroupName fieldset,
.BACDateTimeField fieldset,
.BACNotes fieldset,
.BGCTopicDiscussion fieldset {
  border: 1px solid #dededf;
  border-radius: 2px;
  box-shadow: none;
}

.BGCGroupName .MuiFormControl-root,
.BACDateTimeField .MuiFormControl-root,
.BGCTopicDiscussion .MuiInputBase-root {
  height: 31px;
}

.BGCGroupName .MuiInputBase-root,
.BACDateTimeField .MuiInputBase-root,
.BGCTopicDiscussion .MuiInputBase-root {
  height: 31px;
  font-size: 14px;
  color: #999999;
}

p.famousFiveLabel {
  font-size: 14px;
  font-weight: 500;
  color: #999999;
  padding: 7px 15px 5px;
}

.BGCTopicDiscussion {
  margin-bottom: 41px;
}

.SGCContentWrapper .MuiAvatar-root {
  width: 64px;
  height: 64px;
  margin-left: -25px;
  background: #f97516 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 5px #00000029;
  border: 3px solid #ffffff;
  margin-bottom: 40px;
  font-size: 24px;
  font-weight: 600;
}

.SGCContentWrapper {
  text-align: center;
}

.SGCContentWrapper .MuiAvatarGroup-root {
  margin: auto;
  width: max-content;
}

.SGCContentWrapper > div > div.MuiAvatar-root:nth-child(1) {
  z-index: 0 !important;
}

.SGCContentWrapper > div > div.MuiAvatar-root:nth-child(2) {
  z-index: 1 !important;
}

.SGCContentWrapper > div > div.MuiAvatar-root:nth-child(3) {
  z-index: 2 !important;
}

.SGCContentWrapper > div > div.MuiAvatar-root:nth-child(4) {
  z-index: 3 !important;
}

.SGCContentWrapper > div > div.MuiAvatar-root:nth-child(5) {
  z-index: 4 !important;
}

.BGCParticipants {
  margin-bottom: 32px;
}

/* Course Card */

.searchCourseCardWrapper__img img {
  width: 100%;
  height: 152px;
  object-fit: cover;
  border-radius: 4px 4px 0px 0px;
}

.searchCourseCardWrapper__content {
  padding: 15px;
  border: 0px solid #ddd;
  border-radius: 0px 0px 4px 4px;
}

.searchCourseCardWrapper__img .d-flex {
  position: relative;
  margin-top: -30px;
  justify-content: space-between;
  padding: 0px 15px;
  color: #fff;
}

.searchCourseCardWrapper {
  box-shadow: 0px 3px 6px #00000029;
  position: relative;
}

.searchCourseCardWrapper__content__title {
  color: #818181;
  font-size: 18px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden !important;
}

.searchCourseCardWrapper__content small {
  display: block;
  color: #a3a3a3;
  font-size: 13px;
}

.searchCourseCardWrapper__content__hash {
  margin-top: 10px;
  height: 120px;
  /* overflow: scroll; */
}

.searchCourseCardWrapper__content__hash span {
  color: #f97516;
  font-size: 13px;
  margin-right: 8px;
}

.searchCourseCardWrapper__title {
  font-size: 14px;
  font-weight: 600;
  /* white-space: nowrap; */
  text-overflow: ellipsis;
  overflow: hidden !important;
  white-space: break-spaces !important;
}

.searchCourseCardWrapper__duration {
  font-size: 12px;
  background: #fff;
  color: #999999;
  padding: 2px 6px;
  border-radius: 20px;
}

.searchCourseCarouselWrapper .slick-slide > div {
  margin: 0 5px;
}

.searchCourseCarouselWrapper {
  padding: 0px 50px 50px;
}

/* ================ UPCOMING CALLS NO RECORD CARD ======== */

.NUCCardWrapper {
  width: 328px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background: #f97516;
  border-radius: 4px;
  color: #fff;
  padding: 0 20px;
}

p.NUCTitle {
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 10px;
}

.NUCContent {
  font-size: 16px;
  font-weight: 500;
}

/* ==================== CLASSROOM SCREEN > COURSES ============= */

.courseTitle {
  font-size: 32px;
  font-weight: 600;
  color: #263238;
  margin-bottom: 0;
  line-height: 34px;
}

.alignLeft {
  text-align: left;
}

.alignCenter {
  text-align: center;
}

.coursesMainWrapper > h2 {
  /* padding: 22px 55px; */
  padding: 22px 0px;
}

.courseToolbarWrapper {
  background: #f4f4f46f;
  /* padding: 25px 55px; */
  /* padding: 25px 0px; */
  padding: 0px 0px 20px 0px;
}

.allCourses {
  padding: 30px 30px;
  background: white;
}

.tagsLabel {
  padding-top: 15px;
}

.CTInputWrapper input,
.CTInputWrapper select {
  width: 100%;
  max-width: 100%;
  font-size: 14px;
  min-height: 38px;
}
.CTInputwrapper label {
  padding-bottom: 10px;
  display: block;
  font-size: 16px;
  color: #333333;
  margin-bottom: 3px;
}

.CTSearchBtn {
  margin-top: 25px !important;
}

.QPSearchBtn {
  margin-top: 25px !important;
}

.allCourses .courseTitle {
  margin-bottom: 18px;
}

.peerProgramBtn button {
  width: 136px;
  height: 34px;
  font-size: 12px !important;
  text-transform: uppercase;
  font-weight: 600;
  margin-top: 32px;
}

.yuukkeProgramWrapper {
  margin-bottom: 40px;
}

.accreditedProgramsWrapper {
  margin-bottom: 40px;
}

.more_video {
  position: relative;
  display: inline-block;
  margin-left: 50px;
}
.courseCardPlayBt {
  /* width: 40px;
  height: 40px; */
  display: flex;
  /* align-items: center;
  justify-content: center; */
  background: gray;
  border-radius: 100%;
  position: absolute;
  /* left: 2px; */
  margin-bottom: 200px;
  /* top: 50px; */
  cursor: pointer;
}
.courseCardPlayBtn {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 100%;
  position: absolute;
  right: 17px;
  top: 50px;
  cursor: pointer;
}
.CompletecourseCardPlayBtn {
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 18%;
  position: absolute;
  left: 17px;
  top: 170px;
  color: #f97516;
}

.courseCardPlayBtn svg {
  color: #f97516;
  font-size: 40px;
}

.CTSearchBtn button {
  width: 96px;
  height: 32px;
  font-size: 15px !important;
  font-weight: 600;
  text-transform: uppercase;
}

.QPSearchBtn button {
  width: 96px;
  height: 32px;
  font-size: 13px !important;
  font-weight: 600;
  text-transform: uppercase;
}

/* ================== PROFILE ============= */

.disabledField .tagMenuFilter {
  pointer-events: none;
  /* background-color: #f2f2f2; */
}

.profileInfoWrapper {
  max-width: 662px;
  margin: auto;
  padding: 75px 0px 65px;
}

.profileTitle {
  font-size: 24px;
  font-weight: 500;
  color: #263238;
  padding-bottom: 10px;
}

.profileDescription {
  color: #999999;
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 55px;
}

.formFieldGroup label {
  display: block;
  font-size: 18px;
  font-weight: 500;
  color: #999999;
  margin-bottom: 5px;
  padding-right: 20px;
}

.formFieldGroup fieldset {
  box-shadow: 1px 1px 1px #00000029;
  border: 0.5px solid #b5b5b5;
  border-radius: 4px;
}

.formFieldGroup input,
.formFieldGroup select {
  padding: 5px 10px;
  font-size: 14px;
}

.MuiOutlinedInput-root {
  height: 40px;
}

.formFieldGroup button.MuiIconButton-root {
  width: 24px;
  height: 24px;
  background: #e8e8e8;
  border-radius: 100% !important;
  color: #242134;
}

.profileFormBtn {
  padding-top: 25px;
}

.profileFormBtn button {
  width: 100%;
  height: 45px;
  max-width: 294px;
  text-transform: uppercase;
  font-weight: 600;
}

.formFieldGroup.disabledField fieldset {
  /* background: #f2f2f2; */
}

.formFieldGroup .tagMenuFilter {
  box-shadow: 1px 1px 1px #00000029;
  border: 0.5px solid #b5b5b5;
  border-radius: 4px;
}

/* ============ UPDATES CARD ============ */

.updatesCardWrapper {
  max-width: 100%;
  padding: 20px;
  grid-gap: 15px;
  gap: 15px;
  align-items: center;
}

.updatesCardWrapper > div:nth-child(1) {
  width: 56px;
  border-radius: 100%;
  height: 56px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.updatesBg {
  width: 56px;
  height: 56px;
  position: absolute;
  top: 0;
  z-index: 0;
  border-radius: 100%;
  opacity: 0.16;
}

.updatesCardWrapper div:nth-child(1) svg {
  z-index: 1;
  font-size: 25px;
}

.updatesCardWrapper img {
  width: 25px;
  height: 25px;
}

.updatesCardWrapper p {
  flex: 1 1;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* number of lines to show */
  -webkit-box-orient: vertical;
  line-height: 18px;
}

.updatesCarouselCardWrapper {
  position: relative;
  margin-left: 12px;
}

.updatesCarouselCardWrapper .updatesCardClose {
  position: absolute;
  right: 5px;
  font-size: 16px;
  color: #999;
  top: 5px;
  cursor: pointer;
}

.slick-list {
  padding: 0 20% 0 0;
}

/* ================ SQUARE SCREEN ============== */

.updatesCarousel
  > div
  > div
  > div
  > div.slick-slide.slick-active.slick-current
  > div {
  margin-left: 0;
}

.newQuestionWrapper {
  box-shadow: 5px 5px 6px #0000000d;
  border: 1px solid #dededfbf;
  border-radius: 4px;
  background-color: #fff;
}

.postQuestionField img {
  min-width: 52px;
  height: 52px;
  box-shadow: 0px 0px 5px #00000029;
  border: 3px solid #ffffff;
  border-radius: 100%;
  margin-right: 30px;
}

.postQuestionField textarea {
  border: none;
  font-size: 14px;
  resize: none;
}

.postQuestionField textarea:-ms-input-placeholder {
  font-size: 17px;
  color: #999999;
  font-weight: bold;
  position: relative;
  top: 0px;
}

.postQuestionField textarea::placeholder {
  font-size: 17px;
  color: #999999;
  font-weight: bold;
  position: relative;
  top: 0px;
}

.postQuestionField {
  padding: 20px 20px 10px;
  border-bottom: 1px solid #dededfbf;
  align-items: center;
}

.postQuestionField textarea:focus {
  border-color: inherit;
  box-shadow: none;
}

/* .formPostMoreOption {
  align-items: center;
  justify-content: space-between;
  padding: 25px 20px;
  color: #d9d9d9;
} */

/* .formPostMoreOption .flexDefault:nth-child(1) svg {
  margin-right: 20px;
  cursor: pointer;
} */

/* .formPostMoreOption .flexDefault:nth-child(2)>svg {
  margin-right: 10px;
  cursor: pointer;
} */

.formPostMoreOption .flexDefault:nth-child(2) > div {
  width: 40px;
  height: 40px;
  /* background: #6d064e; */
  /* display: flex;
  align-items: center;
  justify-content: center; */
  color: #fff;
  border-radius: 100%;
  cursor: pointer;
}

/* .formPostMoreOption .flexDefault:nth-child(2) {
  align-items: center;
} */

.topQuestionsMainWrapper {
  margin-top: 40px;
}

.topQuestionsMainWrapper > .row {
  padding-bottom: 50px;
}

p.postUserName,
p.commentUserName {
  font-size: 17px;
  letter-spacing: 0px;
  color: #000000;
  font-weight: bold;
}

p.postUploadTime,
p.commentedTime {
  font-size: 13px;
  letter-spacing: 0px;
  color: #898989;
}

.postCardHeaderInfo {
  grid-gap: 15px;
  gap: 15px;
}

.postDescription {
  letter-spacing: 0px;
  color: #001737;
  font-size: 14px;
  padding: 0 30px 20px;
}

.postCardHeader {
  padding: 16px 30px 10px;
}

.postCardBody img {
  max-height: 465px;
  object-fit: cover;
  width: 100%;
}

.postCardFooter {
  padding: 16px 16px;
  font-size: 10px;
  letter-spacing: 0px;
  color: #001737;
  /*border-bottom: 1px solid #ddd;*/
}

.likeComment svg {
  font-size: 19px;
  margin-right: 10px;
  cursor: pointer;
}

p.commentInfo {
  padding-right: 25px;
}

.writeCommentWrapper {
  padding: 16px 42px;
}

.commentWrapper {
  padding-bottom: 20px;
}

.comment {
  grid-gap: 15px;
  gap: 15px;
}

p.comment {
  letter-spacing: 0px;
  color: #001737;
  font-size: 14px;
  padding-bottom: 6px;
}

.commentWrapper svg {
  font-size: 14px;
}

p.showMoreComments {
  letter-spacing: 0px;
  color: #f97516;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 30px;
  cursor: pointer;
}

.commentInput {
  flex: 1 1;
  margin: 0 16px;
  position: relative;
}

.commentInput input {
  width: 100%;
  background: #f4f2f2;
  border-radius: 28px;
  border: none;
  margin: 0;
  height: 56px;
  padding: 0 15px;
  font-size: 14px;
}

.commentActionIcons {
  position: absolute;
  top: 18px;
  right: 30px;
  color: #b2b2b2;
  grid-gap: 20px;
  gap: 20px;
}

.commentActionIcons svg {
  cursor: pointer;
  font-size: 18px;
}

.postCommentBtn button {
  background: #f97516 0% 0% no-repeat padding-box;
  width: 42px;
  height: 42px;
  border-radius: 100% !important;
  color: #fff;
}

.newFeedsWrapper {
  padding: 18px 0 30px;
}

.postCard {
  margin-bottom: 18px;
}

.updatesCarousel {
  margin-bottom: 48px;
}

.updatesCarousel .slick-track {
  margin-left: 0;
}

h3.listTitle {
  font-size: 20px;
  letter-spacing: 0px;
  color: #333333;
  font-weight: bold;
  padding: 15px 30px 20px;
  border-bottom: 1px solid #ddd;
}

.listContent {
  margin: 0px 22px 25px;
  overflow: hidden auto;
}

.friendOnline {
  letter-spacing: 0px;
  color: #001737;
  font-size: 14px;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  /* cursor: pointer; */
  padding: 13px 0px;
}

.screenTitle {
  font-size: 30px;
  letter-spacing: 0px;
  color: #3c3c3c;
  font-weight: 600;
  margin-bottom: 12px;
}

.screenTitleEvents {
  font-size: 20px;
  letter-spacing: 0px;
  color: #3c3c3c;
  font-weight: 600;
  margin-bottom: 12px;
}

.screenTitleevents {
  font-size: 20px;
  letter-spacing: 0px;
  color: #3c3c3c;
  font-weight: 600;
  margin-bottom: 12px;
  padding-bottom: 23px;
}
.announcementImg {
  margin-left: auto;
}

.announcementWrapperOuter .listContent {
  margin: 0;
  padding: 0px 22px 25px;
}

.announcementImg img {
  width: auto;
  height: 150px;
  object-fit: cover;
}

.announcementWrapper {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
  background-color: #fff;
  align-items: center;
  margin-bottom: 11px;
}

.announcementContent {
  max-width: 270px;
  padding-left: 16px;
}

.announcementWrapperOuter {
  position: relative;
}

.announcementWrapperOuter .announcementClose {
  position: absolute;
  top: 5px;
  right: 20px;
  font-size: 16px;
  color: #999;
  cursor: pointer;
}

.announcementContent h3 {
  font-size: 15px;
  letter-spacing: 0px;
  color: #f97516;
  text-transform: uppercase;
  font-weight: bold;
}

.announcementContent p {
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
  font-size: 16px;
  font-weight: 500;
}

.badgeCardWrapper {
  background: #ffffff;
  /* box-shadow: 0px 3px 6px #00000029; */
  position: relative;
  min-height: 250px;
  /* min-height: 268px; */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 12px;
}

.badgeMainWrapper .listContent {
  margin: 0;
  padding: 20px 22px 25px;
}

.earnedOuter {
  width: 60px;
  height: 60px;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  right: -20px;
  top: -30px;
}

.earnedInner {
  width: 38px;
  height: 38px;
  background-color: #3bb54a;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 50%;
}

.badgeImgWrapper {
  width: 122px;
  height: 122px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-bottom: 30px;
}

.badgeImgInner {
  width: 103px;
  height: 103px;
  border-radius: 50%;
  display: grid;
  place-content: center;
}

.badgeImgInner img {
  max-width: 70px;
}

.badgeCardTitle {
  font-size: 12px;
  font-weight: bold;
  padding-bottom: 7px;
  color: #f97516;
}

.earnedLabel {
  font-size: 12px;
  color: #3bb54a;
}

a.badgeCardQuestion {
  text-decoration: underline;
  color: #f97516;
}

/* 
.updatesCarousel>div>div>div>div.slick-slide.slick-active.slick-current>div {
  margin-left: 0;
}

.newQuestionWrapper {
  box-shadow: 5px 5px 6px #0000000d;
  border: 1px solid #dededfbf;
  border-radius: 4px;
  background-color: #fff;
}

.postQuestionField img {
  width: 52px;
  height: 52px;
  box-shadow: 0px 0px 5px #00000029;
  border: 3px solid #ffffff;
  border-radius: 100%;
  margin-right: 30px;
}

.postQuestionField textarea {
  border: none;
  font-size: 14px;
  resize: none;
}

.postQuestionField textarea::placeholder {
  font-size: 17px;
  color: #999999;
  font-weight: bold;
  position: relative;
  top: 0px;
}

.postQuestionField {
  padding: 20px 20px 10px;
  border-bottom: 1px solid #dededfbf;
  align-items: center;
}

.postQuestionField textarea:focus {
  border-color: inherit;
  box-shadow: none;
}

.formPostMoreOption {
  align-items: center;
  justify-content: space-between;
  padding: 25px 20px;
  color: #d9d9d9;
}

.formPostMoreOption .flexDefault:nth-child(1) svg {
  margin-right: 20px;
  cursor: pointer;
}

.formPostMoreOption .flexDefault:nth-child(2)>svg {
  margin-right: 10px;
  cursor: pointer;
}

.formPostMoreOption .flexDefault:nth-child(2)>div {
  width: 40px;
  height: 40px;
  background: #6d064e;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 100%;
  cursor: pointer;
}

.formPostMoreOption .flexDefault:nth-child(2) {
  align-items: center;
}

.topQuestionsMainWrapper {
  margin-top: 40px;
}

.topQuestionsMainWrapper >.row {
  padding-bottom: 50px;
}

p.postUserName, p.commentUserName {
  font-size: 17px;
  letter-spacing: 0px;
  color: #000000;
  font-weight: bold;
}

p.postUploadTime, p.commentedTime {
  font-size: 13px;
  letter-spacing: 0px;
  color: #898989;
}

.postCardHeaderInfo {
  gap: 15px;
}

.postDescription {
  letter-spacing: 0px;
  color: #001737;
  font-size: 14px;
  padding: 0 30px 20px;
}

.postCardHeader {
  padding: 16px 30px 10px;
}

.postCardBody img {
  max-height: 465px;
  object-fit: cover;
  width: 100%;
}

.postCardFooter {
  padding: 16px 42px;
  font-size: 14px;
  letter-spacing: 0px;
  color: #001737;
  border-bottom: 0px !important;
} */

/* 
.likeComment svg {
  font-size: 19px;
  margin-right: 10px;
  cursor: pointer;
}

p.commentInfo {
  padding-right: 25px;
}

.writeCommentWrapper {
  padding: 16px 42px;
}

.commentWrapper {
  padding-bottom: 20px;
}

.comment {
  gap: 15px;
}

p.comment {
  letter-spacing: 0px;
  color: #001737;
  font-size: 14px;
  padding-bottom: 6px;
}

.commentWrapper svg {
  font-size: 14px;
}

p.showMoreComments {
  letter-spacing: 0px;
  color: #6d064e;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 30px;
  cursor: pointer;
}

.commentInput {
  flex: 1;
  margin: 0 16px;
  position: relative;
}

.commentInput input {
  width: 100%;
  background: #f4f2f2;
  border-radius: 28px;
  border: none;
  margin: 0;
  height: 56px;
  padding: 0 15px;
  font-size: 14px;
}

.commentActionIcons {
  position: absolute;
  top: 18px;
  right: 30px;
  color: #b2b2b2;
  gap: 20px;
}

.commentActionIcons svg {
  cursor: pointer;
  font-size: 18px;
}

.postCommentBtn button {
  background: #6d064e 0% 0% no-repeat padding-box;
  width: 42px;
  height: 42px;
  border-radius: 100% !important;
  color: #fff;
}

.newFeedsWrapper {
  padding: 18px 0 30px;
}

.postCard {
  margin-bottom: 18px;
}


.updatesCarousel {
  margin-bottom: 48px;
}

.updatesCarousel .slick-track {
  margin-left: 0;
}

h3.listTitle {
  font-size: 20px;
  letter-spacing: 0px;
  color: #333333;
  font-weight: bold;
  padding: 15px 30px 20px;
  border-bottom: 1px solid #ddd;
}

.listContent {
  margin: 0px 22px 25px;
  overflow: auto;
}

.friendOnline {
  letter-spacing: 0px;
  color: #001737;
  font-size: 14px;
  align-items: center;
  gap: 20px;
  padding: 13px 0px;
}

.screenTitle {
  font-size: 30px;
  letter-spacing: 0px;
  color: #3c3c3c;
  font-weight: 600;
  margin-bottom: 12px;
}

.announcementImg {
  min-width: 84px;
  height: 77px;
}

.announcementImg img {
  width: 84px;
  height: 77px;
}

.announcementWrapper {
  gap: 15px;
  padding: 30px 0px 25px;
}

.announcementWrapperOuter {
  position: relative;
}

.announcementWrapperOuter .announcementClose {
  position: absolute;
  top: 5px;
  right: 20px;
  font-size: 16px;
  color: #999;
  cursor: pointer;
}

.announcementContent h3 {
  font-size: 14px;
  letter-spacing: 0px;
  color: #6d064e;
  text-transform: uppercase;
  font-weight: bold;
}

.announcementContent p {
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
  font-size: 13px;
  font-weight: 500;
} */

/* ================== CHAT SCREEN =========== */

.chatContainer {
  box-shadow: 0px 10px 99px #0000000d;
  border-radius: 11px;
  background: #fff;
  padding: 16px 25px 16px 30px;
}

.chatListsWrapper {
  max-height: 450px;
  overflow: auto;
  padding-right: 15px;
}

.chatMainContainer {
  padding-top: 28px;
}

.chatMyUserImg {
  box-shadow: 0px 0px 5px #00000029;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  position: relative;
}

.chatMyUserImg img {
  border-radius: 50%;
  width: 55px;
  height: 55px;
  border: 2px solid #ffffff;
}

.chatActiveStatus {
  width: 13px;
  height: 13px;
  border: 3px solid #ffffff;
  opacity: 1;
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  right: 2px;
}

.chatUserDetails {
  flex: 1 1;
  align-items: center;
  grid-gap: 16px;
  gap: 16px;
}

.chatUserName {
  font-size: 17px;
  color: #4b5155;
  font-weight: bold;
  margin-bottom: 1.5px;
  position: relative;
  top: 5px;
}

.MuiInput-underline:before {
  border-bottom: 0 !important;
}

.MuiInput-underline:hover:not(.Mui-disabled):before {
  border: none;
}

.chatUserNameStatus .MuiInputBase-root {
  font-size: 10px;
}

.chatUserNameStatus .MuiSelect-selectMenu {
  letter-spacing: 0px;
  color: #f97516;
  font-size: 9px;
  font-weight: bold;
  padding: 0;
}

.MuiSelect-nativeInput {
  padding: 0;
}

.settingIcon {
  color: #afbbc6;
  cursor: pointer;
}

.chatUserInfoWrapper {
  padding-right: 15px;
}

.activeChats {
  align-items: center;
  justify-content: space-between;
  padding: 26px 15px 18px 0;
}

.activeChatTitle {
  margin-bottom: 0;
  font-size: 16px;
  color: #4b5155;
  font-weight: bold;
}

.activeChatPlusBtn {
  background: rgba(26, 180, 222, 0.2);
  border-radius: 5px;
  color: #f97516;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.activeChatPlusBtn svg {
  font-size: 16px;
}

.searchField .MuiFormControl-root {
  width: 100%;
  padding-right: 15px;
}

.searchField {
  padding-bottom: 25px;
}

.chatListImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: relative;
}

.chatListImg img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.chatListDetails {
  flex: 1 1;
}

.chatListWrapper {
  grid-gap: 15px;
  gap: 15px;
  margin-bottom: 15px;
  cursor: pointer;
}

.chatListImg .chatActiveStatus {
  right: 0px;
}

.chatListName {
  font-size: 16px;
  color: #7b8793;
  font-weight: bold;
  margin-bottom: 4px;
}

.chatListTagLine {
  font-size: 12px;
  color: #afbbc6;
}

.chatDateWrapper {
  color: #7b8793;
  font-size: 12px;
  font-weight: bold;
}

.messageMainWrapper {
  border: 1px solid #dbe5ed;
  border-radius: 12px;
  background-color: #f9fafc;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.messageHeaderWrapper {
  border-bottom: 1px solid #dbe5ed;
  border-radius: 11px;
  min-height: 67px;
  padding: 0 25px;
  background-color: #f4f5f8;
}

.messageUserNameWrapoer {
  flex: 1 1;
}

.callIconWrapper {
  background-color: #f97516;
  border-radius: 20px;
  color: #fff;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.callIconWrapper svg {
  font-size: 16px;
}

.messagesBodyWrapper {
  flex: 1 1;
  /* min-height: 450px; */
  padding-top: 20px !important;
  overflow: auto;
  /* max-height: 450px; */
}

.messageActionWrapper input {
  flex: 1 1;
  height: 50px;
  font-size: 14px;
  border: none;
  border-radius: 30px;
  padding-left: 15px;
}

.messageActionWrapper {
  background: #ffffff;
  border: 1px solid #dbe5ed;
  border-radius: 30px;
  align-items: center;
  padding: 5px 5px 5px 0px;
  grid-gap: 15px;
  gap: 15px;
  margin: 15px 17px;
}

.messageActionWrapper {
  font-size: 16px;
  color: #afbbc6;
}

.sendIconBtn {
  width: 38px;
  height: 38px;
  background-color: #f97516;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
}

.sendIconBtn svg {
  font-size: 16px;
}

.justifyEnd {
  justify-content: flex-end;
}

.messagesBodyWrapper {
  padding: 0 17px;
}

.MRimgWrapper {
  box-shadow: 0px 0px 5px #00000029;
  border: 2px solid #ffffff;
  width: 43px;
  height: 43px;
  border-radius: 50%;
}

.MRimgWrapper img {
  border-radius: 50%;
}

.MessageReplyWrapper {
  align-items: center;
}

.colorWhite {
  color: #fff;
  cursor: pointer;
}

.reply {
  color: #7b8793;
}

.justifyEnd .message {
  background: #afbbc6;
}

.message,
.reply {
  padding: 15px;
  background: #fff;
  border-radius: 10px 10px 0px 10px;
  font-size: 14px;
  font-weight: 500;
}

.messageWrapper {
  grid-gap: 15px;
  gap: 15px;
  margin-bottom: 15px;
  align-items: center;
}

.justifyEnd .MRTime {
  text-align: right;
}

.MRTime {
  font-size: 11px;
  color: #afbbc6;
  font-weight: 500;
  padding-top: 5px;
}

.messageTyping {
  background: #dbe5ed;
  border-radius: 10px 10px 10px 0px;
  padding: 10px 15px;
  font-size: 11px;
  color: #7b8793;
  margin: 10px 17px 0;
  max-width: 20%;
  overflow: hidden;
}

.searchField fieldset {
  border: 1px solid #dbe5ed;
  border-radius: 8px;
  box-shadow: none;
}

.searchField .MuiInputBase-root {
  background: #f9fafc;
  border-radius: 8px !important;
}

.searchField svg {
  color: #afbbc6;
}

input#searchBar {
  letter-spacing: 0px;
  color: #afbbc6;
  font-size: 14px;
  font-weight: bold;
}

.activeChat .chatListDetails h3 {
  color: #4b5155;
}

.activeChat .chatListDetails p {
  color: #7b8793;
}

.textAvatar {
  font-size: 13px !important;
  color: #f97516 !important;
  font-weight: bold;
  background-color: rgba(26, 180, 222, 0.2) !important;
}

/*--- own ---*/

.slick-track {
  float: left !important;
}

.tabWrapper .MuiTab-textColorPrimary {
  margin-right: 45px !important;
}

.backlink {
  font-size: 12px;
  color: blue;
  cursor: pointer;
  text-decoration: underline;
}

.peopleCardWrapper {
  background: #fff 0 0 no-repeat padding-box;
  border: 1px solid #dededf;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  border-radius: 10px !important;
  margin-bottom: 20px;
  width: 290px;
}

.peopleCardWrapperGroup {
  background: #fff 0 0 no-repeat padding-box;
  border: 1px solid #dededf;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  border-radius: 10px !important;
  width: auto;
}

.no-data {
  background: #fff 0 0 no-repeat padding-box;
  /* border: 1px solid #dededf;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  border-radius: 4px; */
  width: 100%;
  padding: 20px 10px;
  margin: 7px 7px;
}

.headerZeroIcon {
  position: relative;
  cursor: pointer;
}

.headerZeroIcon svg {
  color: #808080;
  font-size: 26px;
  position: relative;
  top: 9px;
  left: -17px;
}

.headerZeroIcon p {
  font-size: 12px;
  font-weight: bold;
  color: rgb(99, 97, 97);
  background: #fff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  text-align: center;
  position: absolute;
  width: 23px;
  height: 15px;
  top: 0;
}

.settingsText {
  text-align: left !important;
  color: gray;
  padding-left: 60px;
}

.settingsRow {
  margin-bottom: 20px !important;
}

.settingsLanguage {
  max-width: 50% !important;
  margin-right: 10px;
}

.arrowUpvotes {
  width: 6px;
  height: 6px;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid rgb(24, 240, 24);
  margin: auto;
  cursor: pointer;
}

.paginationDashboardmyquest {
  padding: 0 24px 24px;
  margin: 8px !important;
}

.libraryBody img,
.libraryBody video,
.libraryBody audio,
.libraryBody a {
  max-width: 100% !important;
  height: 80px !important;
  object-fit: cover;
}

.profileDialog {
  max-height: 500px !important;
  overflow: scroll;
}

.divider {
  margin: 0 15px 0 15px;
  border-right: 1px;
}

.coursesCardWrapper img {
  width: 200%;
  height: 150px;
  object-fit: cover;
  object-position: top center;
  border-radius: 4px 4px 0px 0px;
}

.coursesCardWrapper {
  background: #fff 0 0 no-repeat padding-box;
  border: 1px solid #dededf;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  border-radius: 4px;
  margin-bottom: 20px !important;
}

.courseCardInfor h2 {
  color: #333333;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.courseCardInfor {
  text-align: left;
  margin-top: 25px;
  margin-bottom: 25px;
  margin-left: 25px;
}

.courseCardInfor p {
  font-size: 12px;
  color: #b3b3b3;
  margin-bottom: 0;
}

.coursesWrapper {
  padding: 30px 55px 0px;
}

.actionIcon {
  width: 18px !important;
  height: 18px !important;
  cursor: pointer;
}

.answerInfoBanner {
  padding: 5px 5px;
  margin: -0px -25px 0px -23px;
  background: #f3f5f9;
  font-size: 14px;
  color: #999999;
  font-weight: 500;
  width: 106%;
  display: inline-flex !important;
}

.NUCCSessionName {
  font-size: 18px !important;
  font-weight: 400;
  padding-bottom: 10px;
  padding-top: 40px;
}

.groupMemberName {
  margin: 8px;
  text-align: center !important;
}

.GCCUserName div {
  width: 18px;
  height: 18px;
  background: #f0f0f0;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999999;
  min-width: 18px;
  cursor: pointer;
  margin-left: 5px;
}

.GCCUserName svg {
  font-size: 10px;
}

/* .GCCUserName {
  display: flex;
  justify-content: flex-start;
  font-size: 16px;
  line-height: 17px;
  font-weight: 400;
  padding: 0 25px 22px 32px;
  width: 90%;
} */

.GCCUserName {
  display: flex;
  justify-content: center !important;
  font-size: 16px;
  line-height: 17px;
  font-weight: 400;
  align-items: center !important;
  padding: 0px;
  width: 90%;
}

.GCCUserName p {
  display: flex;
  text-align: center !important;
  padding: 0 20px 2px 40px;
  color: #00f;
  text-decoration: underline;
  font-size: 14px;
}

.GCCActive .GCCUserName div {
  background: #fff;
}

/* .GCCUserName p {
  display: flex;
  text-align: center !important;
  padding: 0 0px 2px 40px;
  color: blue;
  text-decoration: underline;
  font-size: 14px;
} */

.UGCCUserImg .MuiAvatar-root {
  width: 42px;
  height: 42px;
  box-shadow: 0px 0px 5px #00000029;
  border: 3px solid #ffffff;
  background: #f97516;
  font-size: 14px;
  margin-left: -22px;
  margin-top: 25px;
}

div.UGCCUserImg > div > div:nth-child(1) {
  z-index: 0 !important;
}

div.UGCCUserImg > div > div:nth-child(2) {
  z-index: 1 !important;
}

div.UGCCUserImg > div > div:nth-child(3) {
  z-index: 2 !important;
}

/** -- changed --**/

.UCCSessionName {
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden !important;
  width: 200px;
}

.FACUserName {
  font-size: 20px;
  color: #242134;
  font-weight: 600;
  /* padding-bottom: 3px; */
  line-height: 21px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden !important;
  padding: 10px 20px 3px;
}

.askedonCard {
  background: #9999994d;
  border-radius: 4px;
  /*align-items: center;*/
  align-items: flex-start;
  padding: 7px;
}

.norfityReminderCardWrapper {
  border: 0.5px solid #33333380;
  border-top: 0;
  border-radius: 0 0 4px 4px;
  max-height: 300px;
  overflow: scroll;
}

.answerVerfiedIconGray {
  fill: #999999;
  margin-top: 30px;
}

/**** MODIFIED CSS ***/

.askedImg img {
  max-width: 40px;
}

/* .NCTime {
  font-size: 11px;
  color: #6d064e;
  font-weight: 600;
  align-items: baseline !important;
  float: right !important;
} */

.notify-action-icon {
  float: right !important;
  width: 20px !important;
  height: 20px !important;
  color: red !important;
  align-items: baseline !important;
  cursor: pointer !important;
}

.groupquestionCardDetailsWrapperRight {
  max-height: 300px;
  overflow: scroll;
}

.groupimgHolder {
  padding-bottom: 5px;
}

.formPostMoreOption {
  /* padding: 20px 10px 20px 10px !important; */
  color: #000000 !important;
}

/* .previewWrapper {
  margin: 20px 10px 0px 10px !important;
} */

.questionMoreOptions {
  border: #ccc 1px solid;
  /* margin-bottom: 20px; */
  border-radius: 5px;
}

.likeCommentWrapper {
  padding: 0px !important;
}

.postCardFooter {
  padding: 15px 15px !important;
}

.topLine {
  margin-top: 40px;
  border-top: 1px solid lightgray;
  width: 100%;
}

.bottomLine {
  margin-bottom: 40px;
  border-bottom: 1px solid lightgray;
  width: 100%;
}

.noMoreArticles {
  margin: 12px !important;
}

.YFGroupMoreBtnWrapper {
  padding: 0 30px 40px;
  text-align: center !important;
}

.footerWrapper {
  padding: 0 30px 40px;
  text-align: center !important;
  cursor: pointer;
  margin: 20px !important;
}

.confirmBoxHeading {
  font-size: 50px;
}

.confirmBoxHeadingCL {
  font-size: 25px;
}

.confirmBoxIcon {
  font-size: 80px !important;
  color: rgb(3, 197, 3) !important;
}

.cscheckboxUi {
  text-align: left !important;
  align-items: left !important;
}

.csbookboxform {
  /*margin: 0 250px !important;*/
  margin: 0 0% !important;
}

.cscardUi {
  border-color: #01a4ef !important;
  /*margin: 0 90px !important;*/
  margin: 0 10% !important;
  background-color: #f5f5f5 !important;
}

.contactFilterWrapper {
  background: #ffffff;
  padding: 20px 55px;
  margin-left: 50px !important;
  margin-top: 20px !important;
  margin-right: 50px !important;
  margin-bottom: 60px !important;
}

.support {
  margin: 5px 15%;
}

.peopleCardInfor h2 {
  color: #333333;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden !important;
  padding: 10px 10px;
}
#firstText {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 180px;
  display: inline-block;
}
.groupCardInfor h2 {
  color: #333333;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden !important;
  padding: 10px 20px;
}

.dropdonwCard ul {
  padding-left: 0;
  list-style: none;
  width: 224px;
  /*height: 200px;*/
  height: auto;
  /* padding-bottom: 10px; */
  margin: 0;
}

.arrowBeforeGreen {
  width: 42px;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid green;
  margin: auto;
  cursor: pointer;
}

.arrowAfterRed {
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid red;
  width: 42px;
  margin: auto;
  cursor: pointer;
}

.answerCardWrappers:hover .questionCardAnswerBlur > p {
  color: #6d064e;
}

/* p.subjectName :hover{
  color: #081b22 ;
} */

.questionCardAnswerBlur p {
  color: #acabab !important;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px !important;
  max-width: 100% !important;
}

.questionCardTagsWrapper .subjectTagClassroom {
  margin-right: 10px;
}

.chatMedia {
  width: 100%;
}

.subjectTagClassroom {
  font-size: 11px;
  color: #f3f5f9;
  padding: 8px 12px;
  background: #f97516;
  border-radius: 4px;
  display: inline-block;
}

.libraryCardType {
  color: #acabab !important;
  font-size: 14px !important;
  font-weight: 600;
  width: 104px;
}

.questionNameDisplay {
  max-width: 100% !important;
}

.typeLibraryText {
  max-width: 100% !important;
}

.ansBackUpvotes {
  border-radius: 6px;
  margin-bottom: 15px;
  color: #21ba45;
  cursor: pointer;
  align-items: center;
  background-color: #c4eece;
  position: absolute;
  right: 0px;
  padding-left: 5px;
  padding-right: 5px;
}

.ansBackUpvotes p {
  font-size: 12px;
  font-weight: 500;
  margin-left: 5px !important;
  float: right !important;
}

.ansBackUpvotes svg {
  font-size: 20px;
}

.questionCardMedia img,
video,
audio {
  margin-bottom: 12px !important;
}

/* .CTMultiWrapper label {
width: 100%;
max-width: 100%;
font-size: 14px;
min-height: 38px;
} */

.CTMultiWrapper label {
  display: block;
  font-size: 16px;
  color: #333333;
  margin-bottom: 3px;
}

.MuiGrid-item {
  align-self: flex-start !important;
}

.loading-bullet {
  display: inline-block;
  opacity: 0;
  font-size: 2em;
  color: #a00030 !important;
}

.block-ui-message {
  color: #a00030 !important;
  background: none;
  z-index: 1011;
}

.mulListData {
  color: #db2828;
}

.bookmarkIcon {
  float: left;
  padding-top: 10px;
  cursor: pointer !important;
}

.bookmarkIconyellow {
  color: #f5ed04d1 !important;
  float: left;
  padding-top: 10px;
  cursor: pointer !important;
}

.bookmarkIconDash {
  float: right;
  margin: 20px !important;
  cursor: pointer !important;
}

.bookmarkIconyellowDash {
  color: #f5ed04d1 !important;
  float: right;
  margin: 20px !important;
  cursor: pointer !important;
}

.subMenu > .MuiTabs-root {
  padding: 15px 50px 18px 0px !important;
}

.MuiToggleButton-root {
  width: 120px;
  height: 40px;
}

.QCTags.flexDefault.flexAlignCenter {
  overflow: scroll;
}

/* .hide { 
  display: none; 
}  */

/* .rdw-option-wrapper { 
  padding: 10px 0px 10px 0px !important; 
  background: transparent !important; 
  color: black !important; 
} */

p.smallText {
  font-size: 75%;
  font-weight: 400;
}

.hide {
  display: none !important;
}

.rdw-editor-toolbar {
  border-top: 6px solid black;
  padding: 0px !important;
  background: #d6d6d6 !important;
  padding-top: 5px !important;
}

.rdw-editor-toolbar {
  padding: 0px !important;
  background: #d6d6d6 !important;
  padding-top: 5px !important;
  border: 1px solid #b5b5b5 !important;
  border-bottom: 0px !important;
  border-radius: 4px 4px 0px 0px !important;
}

.rdw-option-wrapper {
  padding: 10px 0px 10px 0px !important;
  background: transparent !important;
  color: black !important;
  border: 0px solid #f1f1f1 !important;
}

.DraftEditor-editorContainer {
  /* border: 1px solid #b5b5b5 !important; */
  border-top: 0px !important;
  border-bottom: 0px !important;
  border-radius: 0px 0px 0px 0px;
  overflow: scroll;
  /* height: 4rem !important; */
  overflow-x: hidden;
  height: 250px !important;
}

.boxText {
  font-size: 75%;
  font-weight: 400;
  border: 1px solid #b5b5b5 !important;
  border-top: 0px !important;
  background: #f1f1f1 !important;
  border-radius: 0px 0px 4px 4px;
  padding: 5px 0px 5px 10px;
}

.annoucmentBox {
  /* box-shadow: 0px 0px 6px #00000029; */
  opacity: 1;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  background: white;
  border-radius: 2px;
}

.annoucmentTitle {
  text-align: left;
  /* font: normal normal medium 15px/21px Roboto; */
  font: normal normal medium 15px/21px Roboto, sans-serif;
  letter-spacing: 0px;
  text-transform: uppercase;
  opacity: 1;
  color: white;
}

.annoucmentSubTitle {
  text-align: left;
  font: normal normal medium 15px/21px Roboto, sans-serif;
  padding: 15px 0px;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
}

.annoucmentContent {
  text-align: left;
  font: normal normal medium 15px/21px Roboto, sans-serif;
  /* line-height: 2; */
  letter-spacing: 0;
  color: white;
  opacity: 1;
  display: flex;
  flex-wrap: wrap;
}

.announcementClose {
  float: right;
}

.simpleMargin {
  margin: "50px" !important;
}

.CTSearchBtn {
  margin-top: 15px;
}

/* .QPSearchBtn {
  margin-top: 15px;
} */

.CTSearchBtn button {
  width: 100%;
  height: 37px;
}

.QPSearchBtn button {
  /* width: 100%; */
  height: 37px;
}

.ChipBox {
  overflow-x: auto;
  scrollbar-width: thin;
}

.askAQuestionInputGroup {
  margin: 0px;
}

.previewWrapper {
  margin: 10px 10px 10px 10px !important;
}

.overAlluploadicons {
  font-size: 14px;
}

.formPostMoreOption {
  padding: 10px 10px 10px 0px !important;
}

.previewWrapper {
  height: auto !important;
  max-width: 180px !important;
}

.questionMoreOptions {
  border: 0px !important;
}

.fileChip {
  margin-top: 10px !important;
}

/* .pageWrapper {
  padding: 30px 55px 60px;
  background: white !important;
} */

.profileWrapper {
  padding: 0px;
  background: white !important;
}

.pageHeading {
  padding: 0px 0px 30px 0px;
  font-size: 32px;
  font-weight: 600;
  color: #263238;
  margin-bottom: 0;
  line-height: 34px;
}

.formInputsDescriptions {
  width: 100%;
  border: 1px solid gray;
  border-radius: 3px;
}

.profilePictureEditProfile > div:nth-child(1) {
  margin-bottom: 0px;
}

.upVoteWallCard > .cardWrapper {
  background: #f5fcff !important;
  border: 1px solid #f7f7f7 !important;
}

.questionWallCard > .cardWrapper {
  background: #effbf2 !important;
  border: 1px solid #e0f8e6 !important;
}

.navigationWrapper .nav-link {
  margin-right: 90px !important;
}

.version {
  padding: 10px;
  color: gray;
  font-size: 6px;
}

.commentViewAll {
  /* float:"right" !important; */
  cursor: pointer !important;
  /* position: absolute !important;  */
}

.PIWrapperNew {
  padding: 20px 0 20px 0;
}

button.PIAcceptOutlinedNew {
  border: 1px solid #dededf;
  background: #fff;
  color: #383838;
  margin-top: 0px;
}

.player {
  height: auto !important;
  width: auto !important;
}

.videoArticleWrapper {
  background: #f4f4f46f;
  padding: 20px 55px;
}

.searchCourseCardWrapper__content__title {
  white-space: break-spaces !important;
}

.feedbackBox {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border: 20px solid #f97516;
  opacity: 1;
  padding: 15px;
  width: 50%;
}

.feedbackBoxTitle {
  font: normal normal medium 15px/21px Roboto, sans-serif;
  color: #f97516;
}

.feedbackBoxIcon {
  font-size: 20px;
  color: #f97516;
  padding: 10px 0px 20px 0px;
}

.feedbackBoxText {
  font-size: 10px;
}

.feedbackIcons {
  padding: 25px 0px 25px 0px;
}

.feedbackResult {
  padding: 0px 0px 25px 0px;
  font-weight: bold;
  color: darkgreen;
}

.react-thumbnail-generator {
  border-bottom: 1px solid #f2f2f2;
  height: 220px;
}

.searchCourseCardWrapper__content__title {
  height: 60px;
}

.snapshot-generator,
.videoRender {
  display: none !important;
}

.searchCourseCard_desc {
  height: 25px;
}

.react-thumbnail-generator {
  display: none;
  margin-left: auto;
  margin-right: auto;
  width: auto;
}

.thumbnail-view > img {
  height: auto !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: auto !important;
}

.CTInputWrapper select {
  font-size: 14px !important;
  min-height: 38px !important;
  border: 1px solid #c7c7c780 !important;
  border-radius: 4px !important;
  padding: 5px 10px !important;
}

.toggleButtonWrapper button {
  padding: 10px 10px !important;
}

.smart-option {
  font-size: 12px;
  margin: 3px 10px 0px 30px;
}

.smart-option input {
  font-size: 12px !important;
  height: 12px !important;
  float: left;
  width: 15px !important;
  margin: 3px 5px 0px 0px;
}

.page-item.active .page-link {
  color: #ffffff !important;
  /* background-color: #f97516 !important; */
  background: linear-gradient(90deg, #000940 0%, #a00030 100%);
  border-color: linear-gradient(to right, #ef5a3e 5%, #a00030 100%) !important;
}

.infoButton {
  padding: 0 !important;
}

/*.page-link {
  color: #6d064e !important;
  background-color: #fff;
  border: 1px solid #6d064e;
}
a {
  color: #6d064e !important;
} */

.surveyBox {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border: 20px solid #f97516;
  opacity: 1;
  padding: 5px;
}

.roleRadio {
  font: normal normal medium 15px/18px Poppins, sans-serif;
  letter-spacing: 0px;
  padding: 2px !important;
  margin-bottom: 2px !important;
  color: #6a6a6a;

  /* text-transform: capitalize; */
  /* border: #ebe4e4;
  border: 1px solid #ebe4e4; */
}

.roleRadio label {
  padding: 5px !important;
}

.roleRadio .form-check-input {
  width: 16px;
  height: 16px;
  margin-top: 0.4rem;
}

.roleRadio .form-check {
  margin-bottom: 15px;
  cursor: pointer;
}

.surveyTitle {
  text-align: left;
  font: normal normal medium 15px/21px Roboto, sans-serif;
  letter-spacing: 0px;
  color: #0c0c0c;
  /* text-transform: uppercase; */
  opacity: 1;
  margin-bottom: 20px !important;
}

.greenLabel {
  color: #21ba45 !important;
  /* border-radius:30px !important; */
  /* padding-top:  4px !important;
  padding-bottom: 4px !important; */
  font-size: 9px !important;
  background: #c4eece !important;
  padding: 6px 10px !important;
  float: right !important;
  border-radius: 15px !important;
}

.peopleCardChat {
  flex-basis: 50%;
  border-top: 1px solid #f2f2f2;
  background: #f2f2f2;
}

.FACBtnWrapper button:hover,
.wallButtonWrapper button:hover,
.CTSearchBtn button:hover,
.QPSearchBtn button:hover,
.profileFormBtn button:hover {
  background: linear-gradient(to right, #ef5a3e 5%, #a00030 100%) !important;
}

.subjectName {
  /* color: linear-gradient(to right, #ef5a3e 5%, #a00030 100%) !important; */
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden !important;
  font-size: 20px;
  font-weight: 600;
  color: #f97516;
}

/* .peopleCardAddRemove div:hover {
  background: #6d064e !important;
} */

div.peopleCardAdd:hover {
  background: linear-gradient(to right, #ef5a3e 5%, #a00030 100%) !important;
}

div.myQuestion:hover {
  color: linear-gradient(to right, #ef5a3e 5%, #a00030 100%) !important;
}

div.peopleCardChat:hover {
  border-top: 1px solid lightgray !important;
  background: lightgray !important;
}

div.subjectName:hover {
  color: linear-gradient(to right, #ef5a3e 5%, #a00030 100%) !important;
}

div.subjectCardWrapper:hover {
  background: #f5f4f4 !important;
}

p.bookACallLabel {
  margin-right: 0px !important;
}

div.RVQQuestion:hover {
  color: #667277 !important;
}

.questionCardWrapperBlue {
  display: flex;
  /*align-items: center;*/
  align-items: flex-start;
  padding-bottom: 10px !important;
  /*padding-bottom: 40px;*/
  border-bottom: 1px solid rgba(179, 179, 179, 0.25);
  /*margin-bottom: 15px;*/

  background: #f5fcff !important;
}

.questionCardWrapperBlue:hover .questionCardQuestion > p {
  color: #f97516;
  background: #f5fcff !important;
}

.questionCardWrapperBlue > div:last-child {
  margin-left: 35px;
  width: 100%;
  background: #f5fcff !important;
}

.questionCardWrapperGrey {
  display: flex;
  /*align-items: center;*/
  align-items: flex-start;
  padding-bottom: 10px !important;
  /*padding-bottom: 40px;*/
  border-bottom: 1px solid rgba(179, 179, 179, 0.25);
  /*margin-bottom: 15px;*/

  background: #ffffdd !important;
}
.questionCardWrapperGrey:hover .questionCardQuestion > p {
  color: #829397;
  background: #ffffdd !important;
}

.questionCardWrapperGrey > div:last-child {
  margin-left: 35px;
  width: 100%;
  background: #ffffdd !important;
}

.questionCardWrapperRed {
  display: flex;
  /*align-items: center;*/
  align-items: flex-start;
  padding-bottom: 10px !important;
  /*padding-bottom: 40px;*/
  border-bottom: 1px solid rgba(179, 179, 179, 0.25);
  /*margin-bottom: 15px;*/

  /* background: #ecebeb !important; */
}
.questionCardWrapperRed:hover .questionCardQuestion > p {
  color: #829397;
  /* background: #ecebeb !important; */
}

.questionCardWrapperRed > div:last-child {
  /* margin-left: 10px; */
  width: 100%;
  /* background: #ecebeb !important; */
}

.messageIconWrapper,
.viewsIconWrapper {
  min-width: 150px;
}

.qabox {
  padding: 2px 3px 3px 0px;
  margin-bottom: 10px;
}

.qaIconView {
  display: block;
}

.qaIcon {
  min-width: 150px !important;
  position: relative;
  padding-bottom: 10px;
}

p.notifyCount {
  margin-right: 13px !important;
}

.hlink {
  color: #f97516 !important;
}

.hlink:hover {
  color: #f97516 !important;
}

.player {
  /*width: auto !important;*/
  width: auto !important;
  height: 500px !important;
  max-height: 100% !important;
}

.bannerImg {
  height: 100px;
  width: 100%;
  object-fit: cover;
  object-position: top center;
  border-radius: 10px 10px 0px 0px;
}

.bannerImgPOpup {
  width: 100%;
  object-fit: cover;
  object-position: top center;
  border-radius: 10px 10px 0px 0px;
  height: 170px;
}

.bannerGroupImg {
  height: 130px;
  width: 100%;
  object-fit: cover;
  /* object-position: top center; */
  border-radius: 10px 10px 0px 0px;
}

.bannerEventImg {
  height: 200px;
  width: 100%;
  object-fit: cover;
  object-position: top center;
  border-radius: 0px 0px 0px 0px;
}

.bannerClassroomImg {
  height: 180px;
  width: 320px;
  /* object-fit: cover; */
  object-position: top center;
  border-radius: 0px 0px 0px 0px;
}

.userImg {
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
  object-fit: cover !important;
  object-position: top center !important;
  width: 100px !important;
  height: 100px !important;
  border-radius: 50px !important;
  box-shadow: rgb(0 0 0 / 16%) 0px 0px 5px !important;
  border: 3px solid rgb(255, 255, 255) !important;
}

.postuserImg {
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
  object-fit: cover !important;
  object-position: top center !important;
  width: 65px !important;
  height: 65px !important;
  border-radius: 50px !important;
  box-shadow: rgb(0 0 0 / 16%) 0px 0px 5px !important;
  border: 3px solid rgb(255, 255, 255) !important;
}

.chatImg {
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
  object-fit: cover !important;
  object-position: top center !important;
  width: 60px !important;
  height: 60px !important;
  border-radius: 30px !important;
  box-shadow: rgb(0 0 0 / 16%) 0px 0px 5px !important;
  border: 3px solid rgb(255, 255, 255) !important;
}

.w3-col.w3-container.w3-green {
  display: flex;
  align-items: center;
  margin: auto;
}

.w3-container {
  width: 100px;
  height: 100px;
}

.w3-row {
  border: 0px solid #aaa;
  width: 100%;
  background-color: #fff;
  margin-top: -50px;
}
.bookmarkIconCourse {
  float: right;
  padding-top: 10px;
  cursor: pointer !important;
}

.bookmarkIconyellowCourse {
  color: #f5ed04d1 !important;
  float: right;
  padding-top: 10px;
  cursor: pointer !important;
}

.listCardTitle {
  border-bottom: 1px solid #ddd;
}

.badgesCarouselWrapper .slick-slide > div {
  margin: 0 5px;
}

div.badgesCarouselWrapper
  > div
  > div.slick-list
  > div
  > div.slick-slide.slick-active.slick-current
  > div {
  margin-left: 0;
}
.badgesCarouselWrapper {
  padding: 0px 10px;
  margin: 50px !important;
  /*padding: 0 50px 30px;*/
}
.blueLabel {
  color: #ffffff !important;
  font-size: 12px !important;
  background: #f97516 !important;
  /* padding: 6px 10px !important; */
  float: right !important;
  border-radius: 25px !important;
}

.blueLabelLeft {
  color: #ffffff !important;
  font-size: 12px !important;
  background: #f97516 !important;
  padding: 6px 10px !important;
  margin-top: 8px !important;
  /* float: right !important; */
  border-radius: 25px !important;
}

.likesDashboard img {
  max-width: 100% !important;
  height: 80px !important;
  object-fit: cover;
}

.questionMedia {
  width: 320px !important;
  height: 220px !important;
}
.profilepageWrapper {
  padding: 30px 0px 0px !important;
  background: white !important;
}
.tabsProfile {
  background-image: linear-gradient(to right, #f97516 5%, #f97516 100%);
  /* padding-left: 60px; */
}

.badgeMainWrapper .listContentAnnouncement {
  margin: 0;
  padding: 20px 22px 25px;
}

.announcementWrapperOuter .listContentAnnouncement {
  margin: 0;
  padding: 0px 22px 25px;
}

.listContentAnnouncement {
  margin: 0px 22px 25px;
  /* overflow: hidden auto; */
}

.surveyBoxCard {
  padding: 5px !important;
}

.surveyButton {
  background: #f97516 !important ;
  margin-bottom: 30px !important;
  padding: 12px !important;
  cursor: "pointer" !important;
  width: 100% !important;
}

/* .surveyBoxCard-root:last-child {
  padding-bottom: 2px !important;
  
} */

.badgesCardColored {
  background: #f97516 !important;
  overflow-y: scroll !important;
  max-height: 800px !important;
  padding: 2px !important;
}

.MuiCardContent-root {
  padding: 10px !important;
}

.badgesCard {
  overflow-y: scroll !important;
  max-height: 800px !important;
}

.badgesTextWhite {
  color: #ffff !important;
  font-size: 13px !important;
  width: 200px !important;
}

.badgesTextYellowPoints {
  color: #fcee0e !important;
  font-size: 13px !important;
  width: 200px !important;
}

.badgesHomeWhiteLearn {
  color: #ffff !important;
  font-size: 15px !important;
  letter-spacing: 0px;
  font-weight: bold;
  float: right !important;
}

.badgesHomeWhite {
  color: #ffff !important;
  font-size: 15px !important;
  float: right !important;
  margin-top: 6px !important;
  margin-right: 8px !important;
  margin-left: 8px !important;
}

.badgesTextYellow {
  color: #fcee0e !important;
  font-size: 15px !important;
  font-weight: bold !important;
  text-align: center !important;
  margin-right: 8px !important;
}

.badgesImage {
  width: 39px !important;
  height: 35px !important;
}

.mybadgesImage {
  width: 35px !important;
  height: 35px !important;
}

.badgesTextWhiteName {
  color: #ffff !important;
  font-size: 18px !important;
  font-weight: bold !important;
  width: 200px !important;
}

.badgesTextWhiteCondition {
  color: #ffff !important;
  font-size: 13px !important;
}

.groupuserImg {
  margin-left: auto !important;
  margin-right: auto !important;
}

.badgeslistHeading {
  font-size: 20px;
  letter-spacing: 0px;
  color: #333333;
  font-weight: bold;
}

.badgeslistTitle {
  padding: 15px 30px 20px;
  border-bottom: 1px solid #ddd;
}

.badgeslistSubHeading {
  font-size: 15px;
  letter-spacing: 0px;
  font-weight: bold;
  float: right !important;
  color: #f97516 !important;
  cursor: pointer !important;
}

.badgeRowHeading {
  font-size: 13px !important;
  padding: 18px !important;
}

.badgeRowHeadingName {
  font-size: 13px !important;
  font-weight: bold !important;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 1px solid #edeff0 !important;
}

.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #edeff0 !important;
}

.bluetable thead th {
  vertical-align: bottom;
  border-bottom: 1px solid #f0efef29 !important;
}

.bluetable td,
.bluetable th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #f0efef29 !important;
}

.bluetable {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

.verticalLine {
  border: 1px solid #e9e9e9 !important;
  height: 100px !important;
  width: 0px !important;
}

.badgesBottomWrapper {
  max-height: 500px !important;
  background: #f97516 !important;
}

.badgesCardFooter {
  max-height: 600px !important;
  padding: 40px 40px;
  font-size: 14px;
  /* letter-spacing: 0px; */
  color: #001737;
  /*border-bottom: 1px solid #ddd;*/
}

.badgesflexSpaceBetween {
  display: inline-flex;
  /* align-items: center; */
}

.badgeslistContent {
  overflow: hidden auto;
}

.deleteGroup {
  text-decoration: underline !important;
  color: blue !important;
  cursor: pointer !important;
  text-align: center !important;
  margin-bottom: 8px !important;
}
.likesCountAvataricon {
  background: #ffffff 0 0 no-repeat padding-box !important;
  border: 1px solid #8e8f8f !important;
  border-radius: 30px !important;
  padding: 0px !important;
}

.announcementContent h6 {
  font-size: 14px;
  letter-spacing: 0px;
  color: #f97516;
  font-weight: bold;
  margin-top: 12px !important;
}

.headerIconCalls img {
  font-size: 26px;
  position: relative;
  left: -30px;
}
.headerIconCalls1 img {
  font-size: 26px;
  position: relative;
  left: -45px;
}
.widthincress {
  width: 630px !important;
}
.friendssearch {
  display: flex;
  flex-wrap: nowrap;
}
.moreIcon {
  display: inline-flex;
}
.navsincontact {
  display: flex;
  flex-wrap: nowrap;
  justify-content: left;
  flex-direction: column;
}
.showcount {
  display: none;
}
.likesInfo {
  display: block;
}
.mobile-likesInfo {
  display: block;
}
/* .slick-slide {
  width: 250px;
} */
.badgeCardInner1 {
  width: 250px;
  height: 300px;
  background: #f97516 !important;
}
.badgeCardTitle1 {
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 7px;
  color: #f4f8fa;
  margin-top: 20px !important;
  letter-spacing: 1px !important;
}

.badgeCardTitle2 {
  font-size: 11px;
  font-weight: normal;
  color: #f4f8fa !important;
  margin-bottom: 12px !important;
  letter-spacing: 1px !important;
  padding-bottom: 12px !important;
}

.questionViewLeftbar {
  display: none;
}
.questionEditDelete {
  display: none;
}
.answerviewimg {
  display: none;
}
.answerviewimginview {
  display: block;
}
.QuestionFilteronMobile {
  display: none;
}

.mycorses {
  padding-top: 50px !important;
}

.classroomBottomWrapper {
  color: #000000 !important;
  font-size: 20px;
  font-weight: bold !important;
  padding: 14px !important;
  margin-top: 100px;
  margin-bottom: 0px !important;
}

.mobileMenu {
  display: flex;
}

.mobileMenu .menuIconWrapper {
  width: 100%;
  display: flex;
}

.mobileMenu .navHome,
.mobileMenu .navCalls,
.mobileMenu .navClassroom,
.mobileMenu .navCommunity,
.mobileMenu .navBanner {
  display: flex;
  flex: 1 1;
  justify-content: center;
  /* margin-right: 0px; */
  margin: 0px;
}

.mobileMenu .navigationWrapper .nav-link {
  margin-right: 0px !important;
}

.mobileMenu .navigationWrapper a {
  padding-left: 0px;
}

.mobileMenu .nav-link {
  padding-right: 0px !important;
}

.contactpageWrapper {
  padding: 30px 0px 60px;
}

.removeButton {
  float: right !important;
}

.topbar-wrapper {
  /* padding: 0 24px; */
  /* padding-right: 24px; */
  /* background-color: #1f262e; */
  box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%);
  /* min-height: 99.69px; */
  position: fixed;
  /* left: 208px; */
  left: 0;
  top: 0;
  right: 0;
  z-index: 1000;
  background-color: white;
  min-height: 64.69px;
}
.topbar-inner {
  background: #f5f5f5;
  display: flex;
  /* min-height: 99px; */
  min-height: 70px;
  align-items: center;
  color: #fff;
  justify-content: space-between;
}

.screenInnerGapEUI {
  /* padding: 122px 0px 113px 200px; */
  /* padding: 30px 0px 113px 150px; */
  background: #f5f5f5 0 0 no-repeat padding-box;
}

.screenInnerGapAA {
  /* padding: 122px 0px 113px 200px; */
  /* padding: 110px 0px 213px 217px; */
  background: #f5f5f5 0 0 no-repeat padding-box;
}

.screenInnerGapMyGroup {
  /* padding: 122px 0px 113px 200px; */
  /* padding: 80px 0px 113px 0px; */
  background: #f5f5f5 0 0 no-repeat padding-box;
}

.logo {
  float: left;
  margin-left: -10px;
}

/* .leftside-menu {
  width: 208px;
  height: auto;
  z-index: 10;
  background: linear-gradient(to right, #9c1c28 5%, #6d064e 90%); 
  border-radius: 4px !important;
  color: #fff;
  bottom: 0;
  position: fixed;
  top: 0;
  padding-top: 115px;
} */

/* .leftside-menu::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}
.leftside-menu::-webkit-scrollbar {
  width: 1px;
  background-color: #f5f5f5;
}
.leftside-menu::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #1f262e;
} */

.leftside-menu {
  width: 240px;
  flex-wrap: nowrap;
  overflow-x: hidden;
  overflow-y: hidden;
  /* height: 100vh; */
  /* z-index: 10; */
  /* background: #f5f5f5 0 0 no-repeat padding-box; */
  background: block;
  border-radius: 4px !important;
  color: #fff;
  bottom: 0;
  position: fixed;
  top: 0;
  /* padding-top: 115px; */
}

/* .leftside-menu:hover {
  overflow-y: auto;
} */
.leftside-menu::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}
.leftside-menu::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}
.leftside-menu::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #1f262e;
}

.border-none {
  border: none;
}

.border-none .modal-content {
  border: none;
}

.customLoading .modal-dialog {
  position: relative;

  top: 35%;
}

.adminPanelWrapper {
  /* width: 300px; */
  height: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 0px #00000029;
  padding: 20px 10px 0px 10px;
}

.adminPanelWrapper {
  /* width: 370px; */
  height: 100%;
  overflow-y: scroll;
}
.myEventsIcon {
  width: 20px;
  height: 20px !important;
  margin-right: 10px !important;
}

.navHome {
  padding-top: 9px;
}

.navBanner {
  padding-top: 9px;
}

div.navHome:focus {
  background-color: #c0c0c0;
  border-radius: 4px !important;
  padding: 12px;
  height: 50px;
  width: 200px;
}

div.navBanner:focus {
  background-color: #c0c0c0;
  border-radius: 4px !important;
  padding: 12px;
  height: 120px;
  width: 200px;
}

.navHome {
  border-radius: 4px !important;
  padding: 12px;
  height: 50px;
  width: 200px;
  cursor: pointer;
}

.navBanner {
  border-radius: 4px !important;
  padding: 12px;
  height: 120px;
  width: 200px;
  cursor: pointer;
}

.navHome:hover {
  background-color: #eeeeee !important;
}

.navBanner:hover {
  background-color: #eeeeee !important;
}

.navHomeProfession {
  padding: 12px;
  height: 50px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid gray;
  width: auto;
  cursor: pointer;
}

.navHomeProfile {
  /* padding: 3px;
  height: 50px;
  margin-top: 5px;
  margin-bottom: 5px;*/
  border-bottom: 1px solid gray;
  width: auto;
  cursor: pointer;
  display: flex;
  height: 4rem;
  /* padding: 1rem 2px; */
}
.navHomeProfile p {
  font-size: 15px;
  display: flex;
  align-items: center;
}
.navHomeSkills {
  padding: 12px;
  height: 50px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid gray;
  width: auto;
  cursor: pointer;
}

.navHomeEducation {
  padding: 12px;
  height: 50px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid gray;
  width: auto;
  cursor: pointer;
}

.navMember {
  border-radius: 4px !important;
  padding: 12px;
  padding-top: 9px;
  height: 50px;
  width: 200px;
  cursor: pointer;
}

div.navMember:focus {
  background-color: #c0c0c0;
  border-radius: 4px !important;
  padding: 12px;
  height: 50px;
  width: 200px;
}

.navMember:hover {
  background-color: #eeeeee !important;
}

.navGroup {
  border-radius: 4px !important;
  padding: 12px;
  padding-top: 9px;
  height: 50px;
  width: 200px;
  cursor: pointer;
}

div.navGroup:focus {
  background-color: #c0c0c0;
  border-radius: 4px !important;
  padding: 12px;
  height: 50px;
  width: 200px;
}

.navGroup:hover {
  background-color: #eeeeee !important;
}

.navEvent {
  border-radius: 4px !important;
  padding: 12px;
  height: 50px;
  width: 200px;
  cursor: pointer;
  padding-top: 9px;
}

div.navEvent:focus {
  background-color: #c0c0c0;
  border-radius: 4px !important;
  padding: 12px;
  height: 50px;
  width: 200px;
}

.navEvent:hover {
  background-color: #eeeeee !important;
}

.navCEvent {
  border-radius: 4px !important;
  padding: 12px;
  height: 50px;
  width: 200px;
  cursor: pointer;
  padding-top: 9px;
}

div.navCEvent:focus {
  background-color: #c0c0c0;
  border-radius: 4px !important;
  padding: 12px;
  height: 50px;
  width: 200px;
}

.navCEvent:hover {
  background-color: #eeeeee !important;
}

.navCGroup {
  border-radius: 4px !important;
  padding: 12px;
  height: 50px;
  width: 200px;
  cursor: pointer;
  padding-top: 9px;
}

div.navCGroup:focus {
  background-color: #c0c0c0;
  border-radius: 4px !important;
  padding: 12px;
  height: 50px;
  width: 200px;
}

.navCGroup:hover {
  background-color: #eeeeee !important;
}

.pageWrapper {
  padding: 60px 255px 150px;
  background: white !important;
}

.pageWrapperClassroom {
  padding: 20px 20px 20px;
}

.pageWrapperHome {
  padding: 60px 235px 90px;
  padding-right: 30px;
}

.messageBoxWrapper {
  height: 400px;
}

.listContentMessage {
  margin: 0px 22px 25px;
  overflow: scroll;
  height: 400px;
}
.listContentMessage1 {
  margin: 0px 22px 25px;
  /* overflow: scroll; */
  height: 400px;
}
.material-icons {
  font-family: "Material Icons" !important;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

.grouporeventwrapper {
  padding: 120px 25px 90px 235px !important;
}

.backtoGroup {
  float: right !important;
}

.bgUED {
  background: #f5fcff !important;
  padding: 20px 20px 0px 20px !important;
}

.gbgUED {
  padding: 20px 20px 0px 20px !important;
}

.uploader {
  /* color: #f97516; */
  color: #a00030 !important;
}

.dateinevents {
  position: absolute;
  right: 0;
}

.boxcolor {
  /* background-color: #d5d9dd; */
  padding: 4px 4px 4px 4px;
  border-radius: 6px;
  font-size: 12px;
  display: inline-flex !important;
  /* padding-left: 5px; */
}

.boxcolorRed {
  background-image: linear-gradient(to right, #f97516 5%, #f97516 100%);
  padding: 8px 8px 8px 8px;
  border-radius: 0px;
  font-size: 15px;
  color: #ffffff;
}

.postUserName {
  color: brown;
  font-weight: bold;
  font-size: 16px;
}

.postUploadTime {
  padding: 2px;
  color: black;
  font-size: 13px;
  padding-left: 10px;
  border-radius: 2px;
}

.pageWrapperHomePage {
  background: #f5f5f5 0 0 no-repeat padding-box;
  padding: 60px 235px 90px;
  padding-right: 30px;
}

.membersList {
  font-weight: bold;
  color: #000000;
  font-size: 25px;
  padding-top: 2%;
}

.hostedWrapper {
  border-top-style: solid;
  border-top-color: #f97516;
  border-top-width: 10px;
  border-radius: 7px;
  padding: 20px;
  cursor: pointer;
  background: #ffffff;
}

.aboutGroupWrapper {
  border-radius: 10px;
  padding: 20px;
  background: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.aboutMyRequestWrapper {
  border-radius: 10px;
  padding: 20px;
  background: #fff;
  box-shadow: rgb(17 17 26 / 10%) 0px 4px 16px, rgb(17 17 26 / 5%) 0px 8px 32px;
}

.aboutGroupTitle {
  align-items: center;
  font-weight: bold;
  justify-content: space-between;
  margin-bottom: 10px;
}

/* .nav-link:focus{
  background-image: #ffffff;
 
} */

/* .nav-link:focus{
  background-color: #c0c0c0;
  border-radius: 4px !important;
  padding: 12px;
  height: 50px;
  width: 200px;
}


.nav-link{
  border-radius: 4px !important;
  padding: 12px;
  height: 50px;
  width: 200px;
  background-color: #000000 !important;
}

.nav-link:hover {
  background-color: #eeeeee !important;
} */

.JRWrapper {
  padding: 10px 12px 10px;
  background: white !important;
}

.MuiIconButton-root,
.MuiIconButton-colorInherit,
.button,
.sendbtn {
  border: 1px solid #ffffff;
  border-radius: 4px !important;
  color: #a00030;
  font-size: 14px !important;
  padding: 5px 15px;
  background: #ffffff 0 0 no-repeat padding-box;
  background-image: linear-gradient(to right, #ffffff 5%, #ffffff 100%);
}

@media (min-width: 600px) and (max-width: 1200px) {
  /* =========== TAB MAIN ========= */

  .screenInnerGap {
    /* padding: 30px 0px 113px 255px !important; */
    background: #f5f5f5 0 0 no-repeat padding-box;
  }

  .screenInnerGapMyGroup {
    /* padding: 122px 0px 113px 200px; */
    /* padding: 0px 0px 113px 2px; */
    background: #f5f5f5 0 0 no-repeat padding-box;
  }
}

@media (max-width: 400px) {
  /* =========== TAB MAIN ========= */

  .screenInnerGap {
    /* padding: 30px 0px 113px 250px; */
    background: #f5f5f5 0 0 no-repeat padding-box;
  }

  .screenInnerGapMyGroup {
    /* padding: 122px 0px 113px 200px; */
    /* padding: 0px 0px 113px 2px; */
    background: #f5f5f5 0 0 no-repeat padding-box;
  }
}

.videoThumbnailcontainer {
  position: relative;
  width: 100%;
  max-width: 400px;
}

.videoThumbnailcontainer img {
  width: 100%;
  height: auto;
}

.videoThumbnailcontainer .btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

h3.listHomeTitle {
  font-size: 22px;
  letter-spacing: 0px;
  color: #333333;
  font-weight: bold;
  padding: 10px 5px 5px 0px;
}

.GroupTSearchBtn button {
  width: 90px;
  height: 32px;
  font-size: 16px;
  color: #333333;
  padding: 10px 10px;
}

.greenLabelHome {
  color: #006400 !important;
  font-size: 12px !important;
  background: #c4eece !important;
  padding: 6px 10px !important;
  border-radius: 15px !important;
  font-weight: bold;
  width: 100px !important;
}

.greenTextHome {
  color: #21ba45 !important;
  font-size: 12px !important;
  padding: 6px 10px !important;
}

.peopleCardInforhome {
  text-align: center;
  margin-top: 25px;
  margin-bottom: 25px;
  height: auto;
  margin-left: 12px;
  margin-right: 12px;
}

.peopleCardInforhome p {
  font-size: 12px;
  color: #b3b3b3;
  margin-bottom: 0;
}
.peopleCardInforhome h2 {
  color: #333333;
  font-size: 18px;
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden !important;
  padding: 10px 20px;
}

.setupCard {
  background: #e8e8e8;
  border-radius: 10px;
  margin: 12px;
  height: 250px;
  width: 200px;
  padding: 12px;
  justify-content: center;
  text-align: center;
}

.setupMainCard {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}

.setupCardInfor h6 {
  color: #333333;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0px;
}

.setupCardInfor {
  text-align: center;
  margin-top: 15px;
  margin-bottom: 25px;
  height: 70px;
}
.setupCardInfor h2 {
  color: #333333;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden !important;
  padding: 10px 20px;
}

.setupCardInfor p {
  font-size: 14px;
  color: #b3b3b3;
}

.pendingButton {
  border: 1px solid #eeeeee;
  border-radius: 4px !important;
  color: darkgrey;
  font-size: 14px !important;
  padding: 5px 15px;
  background: #ffffff 0 0 no-repeat padding-box;
  background-image: linear-gradient(to right, #ffffff 5%, #ffffff 100%);
  margin: 12px;
}

.menuFocus {
  padding: 5px;
  height: 50px;
  width: 200px;
}

.menuFocus:hover {
  background-color: #eeeeee;
  height: 50px;
  width: 250px;
  border-radius: 4px !important;
}

.bannerImgHOme {
  height: 100px;
  width: 100%;
  object-fit: cover;
  object-position: top center;
  border-radius: 10px 10px 0px 0px;
}

.spaceFiletCard {
  box-shadow: 5px 5px 6px #0000000d;
  border: 1px solid #dededfbf;
  border-radius: 10px;
  background: #fff;
  margin-bottom: 16px;
  padding: 12px;
}

@media (max-width: 1200px) {
}

button.mobileTabBackground {
  padding: 15px 15px 15px 15px !important;
  justify-content: center;
  background: #ffffff;
  color: #f97516;
  width: 100% !important;
  font-size: 14px !important;
  border-color: #eeeeee;
  border-radius: 0px !important;
}

button.mobileTabBackground:hover {
  border-bottom: #f97516;
  border-bottom-style: solid;
  border-bottom-color: #f97516;
  border-bottom-width: 2px;
  border-radius: 0px !important;
}

@media (min-width: 992px) {
  .screenInnerGap {
    /* padding:30px 0px 0px 250px; */
    background: #f5f5f5 0 0 no-repeat padding-box;
  }

  .leftside-menu {
    width: 200px;
    flex-wrap: nowrap;
    overflow-x: hidden;
    /* height: 97vh; */
    overflow-y: hidden;
    /* z-index: 10; */
    /* background: #f5f5f5 0 0 no-repeat padding-box; */
    background: block;
    border-radius: 4px !important;
    color: #fff;
    bottom: 0;
    position: fixed;
    top: 0;
    /* padding-top: 15px; */
  }
  .leftside-menuStatic {
    width: 280px;
    flex-wrap: nowrap;
    overflow-x: hidden;
    overflow-y: hidden;
    /* z-index: 10;   */
    background: block;
    border-radius: 4px !important;
    color: #fff;
    position: fixed;
    bottom: 12.8rem;
  }
  /* .leftside-menu:hover {
    overflow-y: auto;
  } */
  .leftside-menu::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }
  .leftside-menu::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f5f5;
  }
  .leftside-menu::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #1f262e;
  }
}

@media (min-width: 600px) {
  .screenInnerGap {
    /* padding-top: 90px;
      padding-left: 260px; */
    /* padding:30px 0px 0px 260px; */
    background: #f5f5f5 0 0 no-repeat padding-box;
  }

  /* .leftside-menu {
    width: 200px;
    flex-wrap: nowrap;
    overflow-x: hidden;
    overflow-y: hidden;
    height: 100vh;
    z-index: 10;
    background: #f5f5f5 0 0 no-repeat padding-box;
    background: block;
    border-radius: 4px !important;
    color: #fff;
    bottom: 0;
    position: fixed;
    top: 0;
    padding-top: 115px;
  } */

  .leftside-menu {
    width: 280px;
    flex-wrap: nowrap;
    overflow-x: hidden;
    overflow-y: hidden;
    /* height: 100vh; */
    /* z-index: 10; */
    /* background: #f5f5f5 0 0 no-repeat padding-box; */
    background: block;
    border-radius: 4px !important;
    color: #fff;
    bottom: 0;
    position: static;
    top: 0;
    /* padding-top: 87px; */
  }

  /* .leftside-menu:hover {
    overflow-y: auto;
  } */

  .leftside-menu::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }
  .leftside-menu::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f5f5;
  }
  .leftside-menu::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #1f262e;
  }
}
.listCardWrapperLeaderHeader {
  margin-bottom: 20px;
  width: 290px;
  height: 46rem;
  position: fixed;
  overflow: hidden;
}
.listCardWrapperLeader {
  margin-bottom: 20px;
  width: 290px;
  height: 39rem;
  overflow-y: scroll;
  overflow-x: hidden;

  /* scrollbar-width: thin; */
}

@media (min-width: 1280px) {
  .MuiContainer-maxWidthLg {
    max-width: 2000px !important;
  }
}

@media (min-width: 600px) {
  .MuiContainer-maxWidthLg {
    max-width: 1300px !important;
  }
}

@media (min-width: 1280px) {
  .MuiContainer-maxWidthLg {
    max-width: 1500px !important;
    height: auto !important;
  }
}

@media (min-width: 600px) {
  /* .MuiContainer-root {
    padding-left: 0px !important;
    padding-right: 0px !important;
  } */
}
.MuiContainer-root {
  width: 100%;
  display: block;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
}

.max-width-100 {
  min-width: 100%;
}
.pd-10 {
  margin: 30px;
  position: relative;
  right: 16px;
  top: 12px;
}

.relative-top-38 {
  position: relative;
  top: 5px;
}

.input.has-error {
  border-color: tomato;
}

.error {
  margin: 0;
  font-size: 90%;
  color: tomato;
}

.tag-item {
  background-color: #d4d5d6;
  font-size: 14px;
  border-radius: 30px;
  height: 30px;
  padding: 0 4px 0 1rem;
  display: inline-flex;
  align-items: center;
  margin: 0 0.3rem 0.3rem 0;
}

.tag-item > .button {
  background-color: white;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  font: inherit;
  margin-left: 10px;
  font-weight: bold;
  padding: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px !important;
  outline: 0;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}
.w2-row {
  border: 0px solid #aaa;
  float: right;
  background-color: #fff;
  margin-top: -101px;
}

.profilePopupContainer {
  position: relative;
  text-align: center;
  color: white;
  width: 700px;
  margin-top: -100px;
}

.peopleCardInner {
  background: #fff 0 0 no-repeat padding-box;
  border: 1px solid #dededf;
  box-shadow: 35px 35px 35px 35px #00000029;
  border-radius: 4px !important;
  width: 550px;
  height: 450px;
}
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.circlediv {
  width: 100px;
  height: 100px;
  background: #eeeeee;
  border-radius: 50px;
  padding: 24px;
}

.first-time-setup {
  margin-top: -20px;
  margin-left: -20%;
}

.pageWrapperProfileSetup {
  padding: 60px 35px 30px;
  background: white !important;
}

.vl {
  border-left: 1px solid gray;
  height: auto !important;
}

.selectCard {
  background: #ffffff;
  border-radius: 10px;
  margin: 12px;
  height: 250px;
  width: auto;
  padding: 12px;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}
.selectdotCard {
  background: #fffafa;
  border-radius: 10px;
  border-style: dotted;
  margin: 12px;
  height: 250px;
  width: 200px;
  padding: 12px;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}

.selectCardArrow {
  border-radius: 10px;
  margin: 12px;
  height: 250px;
  width: auto;
  padding-top: 50%;
  padding-bottom: 50%;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}

.setupPhotoCardWrapperContainer {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30%;
  margin-bottom: 25%;
}

.profilepageWrapperProfileSetup {
  /* padding: 60px 35px 30px; */
  background: #f5f5f5 0 0 no-repeat padding-box;
}

.profileInvitationCardWrapper {
  /* background: #ffffff 0% 0% no-repeat padding-box; */
  align-items: center;
  padding: 20px 15px;
  margin-bottom: 6px;
  box-shadow: 0px 1px 3px #00000029;
  border: 1px solid #f7f7f7;
  border-radius: 2px;
}

.profileselectCard {
  background: #ffffff;
  margin-top: 10px;
  height: 800px;
  width: auto;
  align-items: center;
  padding: 20px 15px;
  margin-bottom: 6px;
  box-shadow: 0px 1px 3px #00000029;
  border: 1px solid #f7f7f7;
  border-radius: 3px;
  overflow-y: scroll;
}

.poweredby-div {
  position: relative;
}

.poweredby-div span {
  position: absolute;
  bottom: 0;
  right: 0;
}

.homeCardWrapper {
  background: #ffffff 0% 0% no-repeat padding-box;
  align-items: center;
  margin-bottom: 6px;
  box-shadow: 0px 1px 3px #00000029;
  padding-bottom: 10px;
  border-bottom: 0px solid gray;
  cursor: pointer;
}

.postcardUploadTime {
  font-size: 13px;
  letter-spacing: 0px;
  color: #898989 !important;
}

.newPostUploadUserImgCol {
  padding-right: 0 !important;
  padding-left: 0 !important;
  left: 11px;
}

.postscommunityCard {
  box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(222, 222, 223, 0.75);
  border-radius: 10px;
  background: #fff;
  margin-bottom: 16px;
}

.postInput {
  flex: 1 1;
  margin: 0 16px;
  position: relative;
}

.postInput input {
  width: 100%;
  background: #f4f2f2;
  border-radius: 16px;
  border: none;
  margin: 0;
  height: 56px;
  padding: 0 15px;
  font-size: 14px;
}

.valid-form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  resize: none !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.valid-form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}

.URLInvalid {
  border-color: #dc3545 !important;
  box-shadow: none !important;
  border-radius: 6px;
  padding: 8px;
  width: auto;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") !important;
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.URLvalid {
  border-color: #18e43a0d !important;
  box-shadow: none !important;
  border-radius: 6px;
  padding: 8px;
  width: auto;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.urlBox {
  box-shadow: none !important;
  border-radius: 6px;
  padding: 8px;
  width: auto;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.kycContainerInside {
  position: absolute;
  margin-top: 10%;
  margin-left: 25%;
  justify-content: center !important;
  align-items: center;
  background: #ffffff;
  box-shadow: 5px 5px 6px #0000000d;
  border: 1px solid #dededfbf;
  padding: 18px 35px 35px;
  border-radius: 10px;
}

.educationContainerEdit {
  background: #ffffff 0% 0% no-repeat padding-box;
  margin: 6px;
  box-shadow: 0px 1px 3px #00000029;
  border: 1px solid #f7f7f7;
  border-radius: 2px;
  height: 110px;
  padding: 22px;
  padding: 20px 15px;
}
.professionCard {
  background: #ffffff 0% 0% no-repeat padding-box;
  padding: 20px 15px;
  margin: 6px;
  box-shadow: 0px 1px 3px #00000029;
  border: 1px solid #f7f7f7;
  border-radius: 2px;
  height: 190px;
  /* padding: 22px; */
}

.invitationuserImg {
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
  object-fit: cover !important;
  object-position: top center !important;
  width: 48px !important;
  height: 48px !important;
  border-radius: 50px !important;
  box-shadow: rgb(0 0 0 / 16%) 0px 0px 5px !important;
  border: 3px solid rgb(255, 255, 255) !important;
}

.videopostcontainer {
  position: relative;
  width: auto;
  max-width: auto;
  /* margin-bottom: 12px; */
  /* display: inline-flex !important; */
  cursor: pointer;
  /* margin-right: 8px; */
}
.videoOverAllCard {
  background: #000000 !important;
}

.image {
  display: block;
  width: 100%;
  height: auto;
}

/* .videopostcontainer:hover .overlay {
  opacity: 1;
} */

/* .overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .3s ease;
  background-color: transparent;
} */

.videopostcontainer .overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50px;
  right: 0;
  height: 100%;
  width: 100%;
  transition: 0.3s ease;
  background-color: transparent;
  opacity: 1;
}

.icon {
  color: white;
  font-size: 100px;
  position: absolute;
  top: 40%;
  left: 40%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.fa-user:hover {
  color: #eee;
}

.videoCardPlayBtn {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 100%;
  position: absolute;
  /* right: 17px;
  top: 50px; */
  cursor: pointer;
}

.profileselectCardExpMobile {
  background: #ffffff;
  margin-top: 10px;
  height: 800px;
  width: auto;
  align-items: center;
  padding: 20px 15px;
  margin-bottom: 6px;
  box-shadow: 0px 1px 3px #00000029;
  border: 1px solid #f7f7f7;
  border-radius: 3px;
  overflow-y: scroll;
}
.businessProfile {
  background: #ffffff;
  margin-top: 10px;
  height: auto;
  width: auto;
  align-items: center;
  padding: 31px 38px;
  margin-bottom: 6px;
  box-shadow: 0px 1px 3px #00000029;
  border: 1px solid #f7f7f7;
  border-radius: 3px;
}
.profileselectCardEducation {
  background: #ffffff;
  margin-top: 10px;
  height: 800px;
  width: auto;
  align-items: center;
  padding: 20px 15px;
  margin-bottom: 6px;
  box-shadow: 0px 1px 3px #00000029;
  border: 1px solid #f7f7f7;
  border-radius: 3px;
  overflow-y: scroll;
}
.profileselectCardMobile {
  background: #ffffff;
  margin-top: 10px;
  height: 800px;
  width: auto;
  align-items: center;
  padding: 20px 15px;
  margin-bottom: 6px;
  box-shadow: 0px 1px 3px #00000029;
  border: 1px solid #f7f7f7;
  border-radius: 3px;
  overflow-y: scroll;
}
.valueselectCard {
  background: #ffffff;

  margin-top: 10px;
  height: 800px;
  width: auto;
  align-items: center;
  padding: 20px 15px;
  margin-bottom: 6px;
  box-shadow: 0px 1px 3px #00000029;
  border: 1px solid #f7f7f7;
  border-radius: 3px;
}
.profileselectCardValue {
  background: #ffffff;
  border-radius: 10px;
  margin-top: 10px;
  height: auto;
  width: auto;
  align-items: center;
  padding: 20px 15px;
  margin-bottom: 6px;
  box-shadow: 0px 1px 3px #00000029;
  border: 1px solid #f7f7f7;
}
#video-img-wrapper {
  display: flex;
  flex-wrap: wrap;
}

#video-img-wrapper > div {
  flex: 0 1 33%;
}

.profileselectCardFirstTime {
  background: #ffffff;
  margin-top: 10px;
  height: 380px;
  width: auto;
  align-items: center;
  padding: 20px 15px;
  margin-bottom: 6px;
  box-shadow: 0px 1px 3px #00000029;
  border: 1px solid #f7f7f7;
  border-radius: 3px;
}

.PrivateTabIndicator-colorPrimary-6 {
  background-color: #f97516 !important;
}

/* .MuiTab-textColorPrimary.Mui-selected {
  color: #ffffff !important;
} */

.PrivateTabIndicator-colorPrimary-2 {
  background-color: #ffffff !important;
}
.PrivateTabIndicator-colorSecondary-7 {
  /* background-color: #f97516 !important; */
  background-color: #a00030 !important;
}

.groupheaderIcon {
  font-size: 10px;
  font-weight: bold;
  color: #fff;
  background: linear-gradient(90deg, #000940 0%, #a00030 100%);
  border-radius: 10px;
  text-align: center;
  position: absolute;
  width: 23px;
  height: 15px;
  top: 0;
  left: 95%;
  right: 0;
}

.menuBarHeaderIcon {
  font-size: 10px;
  font-weight: bold;
  color: #fff;
  background: linear-gradient(90deg, #000940 0%, #a00030 100%);
  border-radius: 5px;
  text-align: center;
  position: absolute;
  width: 26px;
  height: 15px;
  top: 5%;
  left: 85%;
  right: 0;
}

.postcardGeneral {
  float: left;
  background: #f4f2f2;
  border-radius: 22px;
  border: none;
  margin: 0;
  /* height: 24px;
  width: 75px; */
  /* padding: 4px 10px 1px 16px; */
  padding: 4px 10px;
  font-size: 10px;
}
.postcardPost {
  float: left;
  background: #f4f2f2;
  border-radius: 22px;
  border: none;
  margin: 0;
  height: 25px;
  width: 58px;
  padding: 4px 10px 1px 16px;
  font-size: 10px;
}

.MuiTab-textColorPrimary.Mui-selected {
  color: black !important;
}
.peopleCardWrapperGroups {
  background: #fff 0 0 no-repeat padding-box;
  border: 1px solid #dededf;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  margin-bottom: 20px;
  width: 100%;
  height: 60px;
  border-radius: 10px !important;
}

.menuCardBanner {
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  border-radius: 10px !important;
  margin-bottom: 20px;
  width: 100%;
  height: 60px;
}

.homePostInputWrapper select {
  width: 150px;
  border: none;
  border-radius: 6px;
  padding: 5px 5px;
  font-size: 12px;
  color: #0c0c0c;
}

.homepostTitleWrapper {
  padding: 20px 20px 20px !important;
}

.homepostTitleWrapper h2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.homepostTitleWrapper button {
  background: #d9d9d9;
  padding: 0;
  width: 28px;
  height: 28px;
  border-radius: 100% !important;
}

.homepostTitleWrapper button svg {
  font-size: 20px;
}

.homePostMediaButtons {
  border: 1px solid #d6d6d6;
  height: 40px;
  margin-right: 5px;
  width: 50px;
  padding: 8px;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
}
.navMenuTextoverFlowOrange {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 120px;
  color: #ffffff !important;
  background: linear-gradient(to right, #ef5a3e 5%, #a00030 100%) !important;
  border: linear-gradient(to right, #ef5a3e 5%, #a00030 100%) !important;
}
.navMenuTextoverFlow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 150px;
}

.navMenuTextoverFlowGray {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 120px;
  color: #000000 !important;
  background: #e0e0e0 !important;
  border: #e0e0e0 !important;
}

.homepostgroupcommunityCard {
  box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(222, 222, 223, 0.75);
  border-radius: 2px;
  background: #fff;
  margin-bottom: 16px;
  position: absolute;
  width: 310px;
  margin-top: 6px;
  height: 100px;
  overflow-y: scroll;
}
.homepostgroupcommunityCard p:hover {
  color: red;
  cursor: pointer;
}
.homeMyRequestCard {
  margin-bottom: 16px;
  margin-top: 6px;
  height: 200px;
  overflow: scroll;
  box-sizing: border-box;
}

.cardButton {
  border: 1px rgb(245, 245, 245);
  border-radius: 4px !important;
  font-size: 14px !important;
  padding: 4px 0px 0px 0px;
  background: rgb(245, 245, 245) none repeat scroll 0% 0%;
  color: #000 !important;
  float: right;
  cursor: pointer;
}

.chooseCardList {
  font-size: 14px;
  padding: 3px;
}

.postCardCloud {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  /* margin-top: 3px; */
  /* border-radius: 10px !important;
  margin-bottom: 20px;
  width: auto;
  height: auto;
  padding: 7px;
  border-radius: 10px !important;
  padding: 5px;
  */
}

.dropdownCardPost {
  background: #ffffff 0% 0% no-repeat padding-box;
  /* box-shadow: 5px 5px 25px #00000040; */
  border: 1px solid #dededebf;
  border-radius: 4px;
  width: 170px;
  height: auto;
  padding: 15px 14px 16px 17px;
  position: absolute;
  right: 1%;
  /* top: 9.6em; */
  cursor: pointer;
  z-index: 10;
  float: right;
}

.dropdownfirstTimeCardPost {
  background: #ffffff 0% 0% no-repeat padding-box;
  /* box-shadow: 5px 5px 25px #00000040; */
  border: 1px solid #dededebf;
  border-radius: 4px;
  /* width: 170px; */
  height: auto;
  padding: 0.7rem;
  position: absolute;
  right: 1%;
  cursor: pointer;
  z-index: 10;
  float: right;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.dropdownfirstTimeCardPost p:hover {
  color: #a00030;
}
.postcardGroup {
  background: #f4f2f2;
  border-radius: 22px;
  border: none;
  margin: 0;
  height: 24px;
  width: auto;
  padding: 4px 10px 1px 16px;
  font-size: 10px;
  float: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* width: 150px; */
}

.postcardGroupOverFlow {
  background: #f4f2f2;
  border-radius: 22px;
  border: none;
  margin: 0;
  height: 24px;
  padding: 4px 10px 1px 16px;
  font-size: 10px;
  float: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 250px;
}
.privacyCardWrapper {
  background: #ffffff 0% 0% no-repeat padding-box;
  align-items: center;
  padding: 20px;
  margin-bottom: 6px;
  box-shadow: 0px 1px 3px #00000029;
}
.privacyCardInnerWrapper {
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid #eeeeee;
}

.profilePictureFriend {
  z-index: 1;
  position: relative;
  height: 100%;
  padding: 50px 16px 0 80px;
  align-items: flex-end;
}

.profilePictureFriend > div:nth-child(1) {
  margin-top: 100px;
  margin-bottom: 0px;
}

.friendTabCardWrapper {
  background: #fff 0 0 no-repeat padding-box;
  /* border: 1px solid #dededf; */
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  border-radius: 10px !important;
  width: 100%;
  height: 60px;
}

.connectionfriendTabCardWrapper {
  background: #eeeeee 0 0 no-repeat padding-box;
  /* border: 1px solid #dededf; */
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  border-radius: 10px !important;
  width: 100%;
  padding: 8px !important;
  padding-top: 20px !important;
}

.aboutFriendTitle {
  float: left;
  font-weight: bold;
  justify-content: space-between;
  margin-bottom: 10px;
}

.aboutFriendWrapper {
  border-radius: 10px;
  padding: 20px;
  cursor: pointer;
  background: #ffffff;
}

.friendInvitationCardWrapper {
  padding: 10px 1px;
  margin-bottom: 6px;
  border-radius: 1px;
  border-bottom: gray;
}

.redLabel {
  color: #ba2121 !important;
  /* border-radius:30px !important; */
  /* padding-top:  4px !important;
  padding-bottom: 4px !important; */
  font-size: 9px !important;
  background: #eec4c4 !important;
  padding: 6px 10px !important;
  float: right !important;
  margin-left: 12px !important;
  border-radius: 4px !important;
}

.friendCardInfor h2 {
  color: #333333;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden !important;
  padding: 10px 20px;
}
p.subhead {
  font-size: 13px;
  width: 100%;
  /* max-width: 45em; */
  /* margin: 0 auto; */
}

.subhead:first-letter {
  color: black;
  float: left;
  font-size: 6em;
  position: absolute;
  /* margin: 8px 2px 2px 0px; */

  /* margin: 0 .1em .1em .1em; */
  /* margin-top: 5px; */
}
.reqestDescription {
  /* background: #f3f5f9; */
  background: #f5f5f5;
  /* box-shadow: 5px 5px 6px #0000000d; */
  border: 1px solid #dededfbf;
  padding: 18px 35px 35px;
  border-radius: 3px;
}
h3.requestHomeTitle {
  font-size: 22px;
  letter-spacing: 0px;
  color: #333333;
  font-weight: bold;
  padding: 8px 1px 0px 0px;
}
.requestHomeSubTitle {
  font-size: 17px;
  letter-spacing: 0px;
  color: #333333;
  font-weight: bold;
  padding: 22px 0px 5px;
}
.myRequestCardWrapper {
  background: #ffffff;
  border: 1px solid #dededfbf;
  padding: 18px 35px 35px;
  border-radius: 10px;
  box-shadow: rgb(17 17 26 / 10%) 0px 4px 16px, rgb(17 17 26 / 5%) 0px 8px 32px;
}

.myRequestCardWrapperUser {
  background: #ffffff;
  box-shadow: 5px 5px 6px #0000000d;
  border: 1px solid #dededfbf;
  border-radius: 10px;
  padding-bottom: 8px;
}

.myRequestCardDetails {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 150px;
}
.myrequestTextfield {
  height: 41px;
  width: 100%;
  background-color: white;
  border: 1px solid #999999;
  border-radius: 5px;
}

.myRequestTagCard {
  margin-bottom: 16px;
  margin-top: 6px;
  /* height: 200px; */
}
.friendsConnectiontextOverflow {
  width: 127px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.otherRequestPage {
  margin-top: 70px !important;
  margin-bottom: 100px !important;
}
.segmentsList-forum:hover {
  cursor: pointer;
  background: gray;
  border-radius: 0 !important;
  margin-bottom: 0.4rem;
}
.segmentsList:hover {
  color: blue;
  border-radius: 10px !important;
  margin-bottom: 8px !important;
  cursor: pointer;
}
.segmentsList.selected {
  /* background: #eeeeee; */
  color: #a00030;
}
.dropdownshareCard {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 8px 7px #00000040;
  border: 1px solid #dededebf;
  border-radius: 4px;
  min-width: 258px;
  /* height: 17rem; */
  padding: 8px 15px 18px 17px;
  position: absolute;
  /* right: -76%; */
  /* cursor: pointer; */
  z-index: 1000;
  /* margin-top: 0; */
  /* width: 100%; */
  left: 0;
  bottom: 40px;
  right: 0;
}
.dropdownshareList {
  font-size: 16px;
  padding: 1px;
  cursor: pointer;
}
.homepostgroupCard {
  box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(222, 222, 223, 0.75);
  border-radius: 10px;
  background: #fff;
  margin-bottom: 16px;
  position: absolute;
  width: 200px;
  margin-top: 6px;
  height: 140px;
  overflow-y: scroll;
  z-index: 1;
}
.wallLoadingPage:block-ui-overlay {
  width: 0% !important;
  height: 81%;
  opacity: 0.5;
}

.block-ui-overlay {
  width: 0% !important;
  height: 100%;
  opacity: 0.5;
  filter: alpha(opacity=50);
}

.dropdownCardGroup {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dededebf;
  border-radius: 4px;
  width: 123px;
  height: auto;
  padding: 18px 14px 16px 17px;
  position: absolute;
  right: 2%;
  cursor: pointer;
  z-index: 10;
  float: left;
}

.thumbnail-view-challenge-card > img {
  height: auto !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: auto !important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.postGroupCardChallenge {
  padding: 12px;
  display: inline-flex;
}

.challengeCardDescription {
  line-height: 18px !important;
  /* max-height: 34px;  */
  overflow: hidden !important;
  /* text-overflow:ellipsis !important;
  white-space:nowrap !important;  */
}

.challengeCardUserName {
  line-height: 18px !important;
  max-height: 18px !important; /* line-height * 3 */
  overflow: hidden !important;
  font-weight: bold !important;
}

.challengeCardOthers {
  line-height: 18px !important;
  max-height: 18px !important; /* line-height * 3 */
  overflow: hidden !important;
}

.scrollTrendingGroups {
  box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(222, 222, 223, 0.75);
  border-radius: 10px;
  background: #fff;
  margin-bottom: 16px;
  position: absolute;
  width: auto;
  margin-top: 6px;
  height: 300px;
  overflow-y: scroll;
}

.challengeParticipantImg {
  height: 200px !important;
  width: 100% !important;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.challengeCardDesp {
  color: #333333;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden !important;
}

p.Grouppage {
  font-size: 16px;
  font-weight: 300;
  word-break: normal !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis !important;
  width: 150px;
}
.MuiFormLabel-root.Mui-focused {
  color: red !important;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #a00030 !important;
}

.MuiMenuItem-root {
  color: #db2828 !important;
  min-height: auto;
}

.postCardImage {
  /* height: 35rem; */
  cursor: pointer;
  margin: 2px;
}

.postCardImageSingle {
  /* height: 25rem; */
  height: auto;
  cursor: pointer;
}

.mentorCardWrapper {
  background: #fff 0 0 no-repeat padding-box;
  border: 1px solid #dededf;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  border-radius: 10px !important;
  width: auto;
}

.mentorCardInfor h2 {
  color: #333333;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0px;
}

.mentorCardInfor {
  text-align: center;
  margin-top: 15px;
  margin-bottom: 25px;
  height: 100px;
}
.mentorCardInfor p {
  font-size: 12px;
  color: #b3b3b3;
  margin-bottom: 0;
}
.mentorCardInfor h2:hover {
  color: #a00030;
}
.mentorCardInfor p:hover {
  color: #a00030;
}
.mentorupdateCardWrapper {
  background: #ffffff;
  box-shadow: 5px 5px 6px #0000000d;
  border: 1px solid #dededfbf;
  padding: 18px 35px 35px;
  border-radius: 10px;
  /* overflow-y: scroll; */
  /* height: 33rem; */
}
.noMoreCardWrapper {
  background: #ffffff;
  box-shadow: 5px 5px 6px #0000000d;
  border: 1px solid #dededfbf;
  padding: 8px 25px 16px;
  width: 13rem;
  margin-top: 3rem;
}
.navMenuInnerGap {
  margin-left: 40px;
}
.navMenuInnerGapNotHome {
  margin-left: 40px;
  padding: 5px;
}
.containerSlide {
  /* padding: 37px; */
  padding: 0px 34px 25px 34px;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;
  border: 1px solid #dededf;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
}

@media (min-width: 992px) {
  /* .screenInnerGapConnection{
    padding: 1px 10px 0px 3px;
   }
  .screenInnerGapGroup{
    padding:6px 0px 0px 0px; 
    background: #f5f5f5 0 0 no-repeat padding-box; 
    margin-left: 20px;
  } */
  .screenInnerGap {
    padding: 6px 0px 0px 7px;
    background: #f5f5f5 0 0 no-repeat padding-box;
  }
  /* .containerSlide {
    
    padding: 4px 34px 6px 34px;
    background: white;
    height: 30rem;
    overflow: scroll;
    overflow-x: hidden;
    
  } */
  /* .screenInnerGapFriendsCard { 
    padding:6px 0px 0px 0px; 
    background: #f5f5f5 0 0 no-repeat padding-box; 
  } */
}

@media (min-width: 992px) and (max-width: 1400px) {
  /* =========== SMALL SCREEN ========== */
  /* .screenInnerGapConnection{
      padding: 6px 10px 0px 26px !important;
      margin-left: 20px;
     }
     .screenInnerGapFriendsCard { 
      padding:7px 0px 0px 25px; 
      background: #f5f5f5 0 0 no-repeat padding-box; 
    } */
  /* .listCardWrapperLeaderHeader {
      margin-bottom: 20px;
      width: 194px;
      height: 46rem;
      position: fixed;
  } */
  .navHomeProfile p {
    font-size: 13px;
  }
  .screenInnerGap {
    padding: 6px 0px 0px 25px;
    background: #f5f5f5 0 0 no-repeat padding-box;
  }
  /* .screenInnerGapGroup{
        padding:6px 0px 0px 25px !important;
        background: #f5f5f5 0 0 no-repeat padding-box; 
        margin-left: 20px;
      } */
}
.rw-widget-input {
  color: #db2828 !important;
  background-color: #fff;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  background-clip: padding-box;
}

.form-control:focus {
  /* color: #db2828 !important; */
  background-color: #fff;
  border-color: #db2828 !important;
  outline: 0;
  box-shadow: #db2828 !important;
}

.resultsCardChallenge {
  /* background: #f3f5f9; */
  background: linear-gradient(
    to top,
    #ff7b6e 0,
    #ff7b6e 13%,
    #ff9b6b 43%,
    #ffa86b 66%
  );

  /* background: linear-gradient(to top, #A00030 10%, #EF5A3E 100%); */
  box-shadow: 5px 5px 6px #0000000d;
  border: 1px solid #dededfbf;
  padding: 18px 35px 35px;
  border-radius: 10px;
}

.resultData {
  width: 100%;
  background: #eeeeee;
  border-radius: 28px;
  border: none;
  margin: 0;
  height: 56px;
  padding: 0 0px;
  font-size: 14px;
  display: inline-flex;
  margin-top: 18px;
}

.resultHeaderIcon {
  font-size: 10px;
  font-weight: bold;
  color: #fff;

  border-radius: 5px;
  text-align: center;
  position: absolute;
  width: 26px;
  height: 15px;
  top: 5%;
  left: 94%;
  right: 0;
}

.confirm_selection {
  animation: glow 0.5s infinite alternate;
}

@keyframes glow {
  to {
    text-shadow: 0 0 10px red;
  }
}

.confirm_selection {
  font-size: 36px;
  font-weight: bold;
  color: gold !important;
}

.badgesTextPink {
  color: #a00030 !important;
  font-size: 14px !important;
  font-weight: bold !important;
  text-align: center !important;
  margin-left: 9px;
  margin-right: 22px;
}

.badgesTextNormal {
  padding-top: 20px !important;
}

.rightModal {
  min-width: 240px;
  z-index: 10;
  background: block;
  border-radius: 4px !important;
  color: #fff;
  bottom: 0;
  position: absolute;
  top: 0;
  padding-top: 45px;
  overflow-y: scroll;
}
.naviGationPadding {
  padding: 30px;
  position: "relative";
  background: "#f5f5f5";
}

.kycContainerInsideLoading {
  position: absolute;
  margin-top: 15%;
  margin-left: 25%;
  justify-content: center !important;
  align-items: center;
  background: #ffffff;
  box-shadow: 5px 5px 6px #0000000d;
  border: 1px solid #dededfbf;
  padding: 44px 35px 35px;
  border-radius: 10px;
  width: 50%;
}

#sub {
  vertical-align: sub !important;
  font-size: 12px;
}

.groupParticipantsImage {
  font-size: 14px;
  font-weight: bold;
  color: #a00030 !important;
  text-align: center;
  height: 10px;
  top: 15px;
  bottom: 10px;
}
.meetingModal {
  padding: 15px 15px 15px 15px !important;
  justify-content: center;
  background: #ffffff;
  width: 100% !important;
  font-size: 14px !important;
  border-color: #eeeeee;
  border-radius: 0px !important;
  height: 600px;
  align-items: center;
  margin-bottom: 6px;
  box-shadow: 0px 1px 3px #00000029;
  border: 12px solid #a00030;
  overflow-y: scroll;
}

.meetingListCard {
  background: #ffffff 0% 0% no-repeat padding-box;
  align-items: center;
  margin-bottom: 6px;
  box-shadow: 2px 2px 3px #00000029;
  padding: 12px;
  border-bottom: 0px solid gray;
  cursor: pointer;
  border: 1px solid #eeeeee;
}

.meetingSelectCard {
  background: #eeeeee;
  margin-top: 10px;
  height: 500px;
  width: auto;
  align-items: center;
  padding: 20px 15px;
  margin-bottom: 6px;
  box-shadow: 0px 1px 3px #00000029;
  border: 1px solid #f7f7f7;
  border-radius: 3px;
}

.meetingSelectRightCard {
  background: #fff;
  margin-top: 10px;
  height: 500px;
  width: auto;
  align-items: center;
  padding: 20px 15px;
  margin-bottom: 6px;
  box-shadow: 0px 1px 3px #fff;
  border: 1px solid #f7f7f7;
  border-radius: 3px;
}
/* @media only screen and (min-width: 790px) and (max-width: 810px)  { 
  .navMenuInnerGap{
    margin-left: 0px;
    margin-right: 0rem;
      
  }
} */
@media only screen and (min-width: 810px) and (max-width: 900px) {
  .navMenuInnerGap {
    margin-left: 1rem;
    margin-right: 0rem;
    padding: 0px 10px;
  }
}
@media only screen and (min-width: 900px) and (max-width: 980px) {
  .navMenuInnerGapNotHome {
    margin-left: 3.1rem;
    padding: 5px;
  }
  .navMenuInnerGap {
    /* margin-left: 1rem; */
    /* margin-right: 8rem; */
    padding: 0px 10px;
  }
  .screenInnerGap {
    margin-left: 30px;
    background: #f5f5f5 0 0 no-repeat padding-box;
  }
}
@media (min-width: 980px) and (max-width: 1100px) {
  .navMenuInnerGap {
    margin-left: 1px;
    /* margin-right: 128px; */
    padding: 0px 10px;
  }
  .listCardWrapperLeaderHeader {
    margin-bottom: 20px;
    width: 12rem;
    height: 46rem;
    position: fixed;
  }
  .homeCardWrapper {
    background: #ffffff 0% 0% no-repeat padding-box;
    align-items: center;
    margin-bottom: 6px;
    box-shadow: 0px 1px 3px #00000029;
    padding-bottom: 10px;
    border-bottom: 0px solid gray;
    cursor: pointer;
    width: 9rem;
  }
  .screenInnerGap {
    margin-left: 30px;
    background: #f5f5f5 0 0 no-repeat padding-box;
  }
}
@media (min-width: 1100px) and (max-width: 1160px) {
  .workspace-button {
    position: absolute;
    right: 12px !important;
    top: 74px;
  }
  .navMenuInnerGap {
    margin-left: 0px;
    /* margin-right: 112px; */
    padding: 0px 10px;
  }
  .screenInnerGap {
    padding: 6px 0px 0px 0px;
    background: #f5f5f5 0 0 no-repeat padding-box;
  }
  .listCardWrapperLeaderHeader {
    margin-bottom: 20px;
    width: 207px;
    height: 46rem;
    position: fixed;
  }
  .homeCardWrapper {
    background: #ffffff 0% 0% no-repeat padding-box;
    align-items: center;
    margin-bottom: 6px;
    box-shadow: 0px 1px 3px #00000029;
    padding-bottom: 10px;
    border-bottom: 0px solid gray;
    cursor: pointer;
    width: 9rem;
  }
}
@media (min-width: 1160px) and (max-width: 1200px) {
  .workspace-button {
    right: 11px !important;
  }
  .navMenuInnerGap {
    margin-left: 0px;
    /* margin-right: 112px; */
    padding: 0px 1px;
  }
  .navHomeProfile p {
    font-size: 11px;
  }
  .screenInnerGap {
    padding: 6px 0px 0px 0px;
    background: #f5f5f5 0 0 no-repeat padding-box;
  }
  .listCardWrapperLeaderHeader {
    margin-bottom: 20px;
    width: 215px;
    height: 46rem;
    position: fixed;
  }
  .homeCardWrapper {
    background: #ffffff 0% 0% no-repeat padding-box;
    align-items: center;
    margin-bottom: 6px;
    box-shadow: 0px 1px 3px #00000029;
    padding-bottom: 9px;
    border-bottom: 0px solid gray;
    cursor: pointer;
    width: 12rem;
  }
}
@media (min-width: 1200px) and (max-width: 1260px) {
  .navMenuInnerGapNotHome {
    margin-left: 0px;
    padding: 5px;
  }
  .navHomeProfile p {
    font-size: 11px;
  }
  .navMenuInnerGap {
    margin-left: 0px;
    margin-right: 72px;
    padding: 0px 0px;
  }
  .screenInnerGap {
    padding: 5px 6px 2px 0px;
    background: #f5f5f5 0 0 no-repeat padding-box;
  }

  .listCardWrapperLeaderHeader {
    margin-bottom: 20px;
    width: 280px;
    height: 46rem;
    position: fixed;
  }
}

@media (min-width: 1260px) and (max-width: 1280px) {
  .navMenuInnerGapNotHome {
    margin-left: 0px;
    padding: 5px;
  }
  .navHomeProfile p {
    font-size: 11px;
  }
  .navMenuInnerGap {
    margin-left: 0px;
    margin-right: 75px;
    padding: 0px 0px;
  }
  .screenInnerGap {
    padding: 6px 0px 0px 0px;
    background: #f5f5f5 0 0 no-repeat padding-box;
  }
}
@media only screen and (min-width: 1280px) and (max-width: 1400px) {
  .navMenuInnerGap {
    margin-left: 30px;
    margin-right: 43px;
    padding: 0px 10px;
  }
  .navMenuInnerGapNotHome {
    margin-left: 5rem;
    /* margin-right: 43px; */
    padding: 5px;
  }
  .workspace-button {
    position: absolute;
    right: 11px !important;
    top: 74px;
  }

  .screenInnerGapConnection {
    padding: 1px 10px 0px 3px;
  }
  .listCardWrapperLeaderHeader {
    margin-bottom: 20px;
    width: 279px;
    height: 46rem;
    position: fixed;
  }
  .screenInnerGap {
    padding: 6px 0px 0px 35px;
    background: #f5f5f5 0 0 no-repeat padding-box;
  }
}

@media (min-width: 980px) and (max-width: 1100px) {
  .navHomeProfile p {
    font-size: 11px;
  }
  .mentorCard {
    background: #fff;
    width: 18em !important;
    border-radius: 0.6em;
    margin: 0.2em;
    overflow: hidden;
    cursor: pointer;
    box-shadow: 0 13px 27px -5px hsl(240deg 30% 28% / 25%),
      0 8px 16px -8px hsl(0deg 0% 0% / 30%),
      0 -6px 16px -6px hsl(0deg 0% 0% / 3%);
    transition: all ease 200ms;
  }
}

.friendsCardAreaofinterest p {
  color: #333333;
  font-size: 12px !important;
  font-weight: bold;
  margin-bottom: 0px;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}

.newQuestionCardVoteBc {
  box-shadow: 2px 2px 2px #0000000f;
  border-radius: 10px;
  color: #ffffff !important;
  font-size: 14px;
  /* top: 437px; */
  /* left: 65px; */
  /* width: 58px; */
  /* height: 55px; */
  background: #edf6fc;
  padding: 2px;
  opacity: 1;

  /* box-shadow: 2px 2px 2px #0000000F;
  border-radius: 10px;
  color: #ffffff !important;
  font-size: 18px;
  top: 437px;
  left: 65px;
  width: 58px;
  height: 55px;
  padding: 10px !important;
  background: #edf6fc;
  opacity: 1; */
}

.newQuestionCardVoteBcMobile {
  color: #1ab4de !important;
  font-size: 10px;
  margin-top: 25px;
  display: flex !important;
  font-weight: bold;
  margin-left: 6px;
}
.newaskedImg img {
  width: 25px;
  height: 25px;
  border-radius: 100%;
}
p.newaskedName {
  font-size: 8px;
  font-weight: 300;
  display: flex !important;
}
.askedInfo {
  margin-left: 8px;
}
.newquestionVotes p {
  color: #838e98;
  text-align: center;
  font-size: 16px;
}

.newquestionVotes small {
  color: #838e98;
  font-size: 10px;
  display: block;
  text-align: center;
  margin-bottom: 10px;
}

.newquestionVotes svg {
  color: #21ba45;
  display: block;
  margin: 0 auto;
}

.newquestionVotes {
  /* margin: 10px 10px; */
}
.prefferenceCard {
  background: #ffffff;
  /* margin-top: 10px; */
  width: auto;
  align-items: center;
  padding: 20px 15px;
  margin-bottom: 6px;
  box-shadow: 0px 1px 3px #00000029;
  border: 1px solid #f7f7f7;
  border-radius: 3px;
  overflow-y: scroll;
}

.dropdownshareCard p:hover {
  color: #a00030;
}
.reportMenuColor {
  color: black !important;
}

.slick-slide img {
  margin: auto;
}
.slick-next:before {
  font-family: "slick", sans-serif;
  font-size: 27px !important;
  line-height: 1;
  opacity: 0.75;
  color: #a00030 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick", sans-serif;
  font-size: 27px !important;
  line-height: 1;
  opacity: 0.75;
  color: white !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.post-replay-control {
  display: block;
  width: 100%;
  padding: 0.5rem 1rem;
  padding-right: 1rem;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.8;
  color: #29292e;
  background-clip: padding-box;
  border: 1px solid #e1e4e6;
  appearance: none;
  border-radius: 0.6rem;
  background: #fbfbfb;
}
.replayButton {
  border-radius: 17px !important;
  font-size: 11px !important;
  padding: 5px 10px 6px 10px;
  float: right;
  background: gray;
  /* border: 1px rgb(245, 245, 245); */
  border: 1px solid gray;
  color: white;
}
.replayButtonPost {
  border-radius: 17px !important;
  font-size: 11px !important;
  padding: 5px 10px 6px 10px;
  float: right;
  /* border: 1px rgb(245, 245, 245); */
  border: 1px solid gray;
  color: white;
}
.list-replay {
  padding-left: 59px;
  list-style: none;
}
/* .nav-item-post-card a:hover {
  color: #a00030 !important;
} */
.replayComment {
  color: #a00030;
  text-decoration: underline;
  cursor: pointer;
  font-size: 12px;
}
.p-reply {
  padding: 5px 3rem 20px 15px !important;
}
.container-box {
  position: relative;
}
.container-box textarea {
  display: block;
  width: 100% !important;
  resize: vertical;
  background: #fbfbfb;
  border: 1px solid #e1e4e6;
  border-radius: 9px;
  padding: 9px 13px 0px 13px;
}

.container-box .reply-btn {
  padding: 5px 11px;
  font-size: 20px;
  position: absolute;
  right: 30px;
  bottom: 17px;
  border-radius: 30px !important;
}
.containerSlide p {
  font-size: 14px;
  color: gray;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 0.2rem !important;
}

.languageIcon {
  display: flex;
  cursor: pointer;
  padding-top: 5px;
}
.languageIcon p {
  padding: 2px;
  font-size: 19px;
  font-weight: 500;
  color: lightslategrey;
}
.languageIcon p:hover {
  color: #a00030;
}
.languageInputWrapper select {
  border-color: gainsboro;
  /* border-radius: 5px; */
  background: white;
  font-size: 15px;
  border: none;
  cursor: pointer;
}
.service-small option {
  font-size: 14px;
  padding: 5px;
  /* background: #5c5c5c; */
}
.service-small option:hover {
  background: #5c5c5c !important;
}
.w3-list-row {
  border: 0px solid #aaa;
  width: 100%;
  background-color: #fff;
  margin-top: 28px;
}
.bannerImg-playList {
  height: 10rem;
  width: 100%;
  object-fit: cover;
  object-position: top center;
  border-radius: 10px 10px 0px 0px;
}
.peopleCardInfor-play {
  text-align: center;
  margin-bottom: 23px;
}
.peopleCardInfor-play h2 {
  color: #333333;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden !important;
  padding: 10px 10px;
  text-transform: capitalize;
}
.toggle-switch-event {
  position: relative;
  width: 60px;
  height: 29px;
  display: inline-block;
  text-align: left;
  top: 16px;
  font-size: 10px;
}
.tabWrapperPoints {
  background: white;
  border-radius: 5px;
}
.userDetailsWrapper-friend {
  padding: 35px 0px 15px 0px;
}
#cb1 {
  accent-color: #a00030;
}
.forumCardWrapper {
  background: #ffffff 0% 0% no-repeat padding-box;
  align-items: center;
  padding: 11px 12px;
  margin-bottom: 6px;
  box-shadow: 0px 1px 3px #00000029;
  border: 1px solid #f7f7f7;
  border-radius: 2px;
}
.forum-applyButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1rem;
}
.memberCount {
  font-size: 11px;
  color: #a00030;
  padding-top: 3px;
  padding-left: 10px;
}
.workspace {
  justify-content: center;
  display: flex;
  cursor: pointer;
  align-items: center;
}
.dropdown-work-space {
  background: #ffffff 0% 0% no-repeat padding-box;
  /* box-shadow: 5px 5px 25px #00000040; */
  border: 1px solid #dededebf;
  border-radius: 4px;
  padding: 15px 14px 16px 17px;
  position: absolute;
  cursor: pointer;
  z-index: 10;
  top: 4em;
}
.dropdown-work-space p:hover {
  color: gray;
}
.chooseCardListSelected {
  font-size: 14px;
  padding: 3px;
  color: #a00030;
}
.page-item.active .page-link {
  z-index: 0 !important;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.userList {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 1rem;
  gap: 1rem;
  /* display: flex;
  gap: 1rem;
  margin-bottom: 10px;
  flex-wrap: wrap;
  justify-content: space-between; */
}
.userListCard {
  background: white;
  padding: 15px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
}
.userList-search {
  max-width: 100%;
  padding: 0 1.5em;
  background-color: #f8f9fa !important;
  border: 1px solid lightgray;
  border-radius: 0.5em;
  overflow: hidden;
  max-height: 2.3rem;
}
.userTable div:hover {
  color: red;
}
.selectedMenu {
  color: red !important;
}
.unSelectedMenu {
  color: #000 !important;
}

.text-area-workSpace {
  width: 100%;
  min-height: 40px !important;
  border-radius: 3px;
  border-color: lightgray;
  overflow: hidden;
}
.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
  background-color: rgba(0, 0, 0, 0.08);
  color: blue !important;
}

.adb-card-wrap {
  flex: 5 1 303px;
  margin-top: 5px;
}
.adb-card {
  display: flex;
  flex-wrap: wrap;
}
.un-active-menu {
  flex: 1 1;
  border-radius: 0px !important;
  background: white;
  color: black;
  border-color: gray;
}
.active-menu {
  flex: 1 1;
  border-radius: 0px !important;
  background: #d3d3d378;
  color: #a00030;
  border-color: gray;
}
.img-grid-chat {
  width: auto;
  min-height: 300px;
  background-size: 100% 100%;
  box-shadow: 0px -5px 18px 0px rgb(0 0 0 / 24%);
}
.popover {
  /* z-index: 999 !important; */
}
/* .img-grid-chat:hover{
  opacity: 1;
  transition: .9s;
} */
.user-post-description-textarea {
  padding: 10px 1px 0px 9px;
  border-color: darkgray;
  border-radius: 5px;
  height: 57px;
  flex: 1 1;
}
.user-post-des {
  font-size: 12px;
  color: gray;
  text-indent: 40px;
}
.home-post-card-wrap1 {
  flex: 2 1 303px;
  margin-top: 5px;
}
.home-post-card-wrap2 {
  flex: 1 1 303px;
  margin-top: 5px;
}
.home-post-comment-wrap {
  border: 1px solid lavender;
  background: #f5f5f5;
  border-radius: 7px;
  padding: 10px;
}
.sendMsgBtnHome {
  background: lightgray !important;
  border: 1px solid gray !important;
  color: black !important;
  margin-left: 5px;
}
.adb-card p {
  font-size: 13px;
  color: gray;
}
.home-post-overflow {
  margin-top: 2rem;
  height: 46rem;
  overflow: scroll;
}
.MuiPaper-elevation8 {
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
    0px 8px 10px 1px rgb(0 0 0 / 0%), 0px 3px 14px 2px rgb(0 0 0 / 0%) !important;
}
.img-black-theme {
  align-items: center;
  display: flex;
  justify-content: center;
  background: black;
}
.post-date-small {
  font-weight: 400;
  font-size: 0.7rem;
  color: #676a79 !important;
}
.post-badge-secondary {
  color: #676a79;
  background-color: #6c757d29;
  font-size: 10px !important;
  font-weight: 420 !important;
  text-transform: capitalize;
  padding: 5px !important;
}
.home-img-icon {
  width: 39px;
  height: auto;
  cursor: pointer;
}
.home-img-icon-like {
  width: 34px;
  height: 42px;
  cursor: pointer;
  object-fit: cover;
}
.home-img-icon-like:hover {
  opacity: 0.6;
}
.comment-overflow {
  /* max-height: 35rem;
  overflow-y: scroll;
  overflow-x: hidden; */
}
.like-count {
  margin-bottom: 0.5rem !important;
  font-size: 0.65em;
  font-weight: 400;
  line-height: 1.5;
  color: #000000bd;
  margin-top: 5px !important;
  display: flex;
  align-content: center;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
}
.home-like-img-icon {
  width: 39px;
  height: 51px;
  margin-right: 6px;
}

.bg-color {
  color: #a00030 !important;
}
.bck-color {
  background: #a00030 !important;
}
.workspace-card {
  display: flex;
  flex-wrap: wrap;
  padding: 25px;
  background: white;
  border-radius: 10px;
  box-shadow: rgb(50 50 93 / 25%) 0px 13px 27px -5px,
    rgb(0 0 0 / 30%) 0px 8px 16px -8px;
}
.workspace-card-wrap1 {
  flex: 3 1 303px;
  margin-top: 5px;
}
.workspace-card-wrap2 {
  flex: 1 2 303px;
  margin-top: 5px;
}
.workspace-card p {
  display: block;
  font-size: 18px;
  font-weight: 500;
  color: #999999;
  margin-bottom: 7px !important;
  padding-right: 20px;
}
.workspace-btn {
  background: #0000ff73;
  border-color: #60c2e7;
  color: black;
  float: right;
  margin-right: 2rem;
}

.header-logo-inner {
  display: block;
  margin: 0 auto;
  height: 50px;
}
.headerDynamicLogo {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 72px;
  width: 251px;
  /* height: 74px;
  width: auto; */
  padding: 5px;
}
.headerDynamicLogo > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.peopleInvitationCardWrapper :is(p, h6):hover {
  color: #a00030;
}
.hr-tag {
  border-top: 2px solid #a00030;
  margin-top: 10px;
  margin-bottom: 1px;
}
input[type="checkbox"] {
  accent-color: #a00030;
}
.MuiAvatar-colorDefault {
  color: #fafafa;
  background-color: #a00030 !important;
}
.MuiChip-root .MuiChip-avatar {
  /* color: #616161; */
  width: 24px;
  height: 24px;
  font-size: 0.75rem;
  margin-left: 5px;
  margin-right: -6px;
  color: white !important;
}
.group-chip {
  overflow: hidden;
  font-size: 12px;
  text-overflow: ellipsis;
  text-transform: capitalize;
  max-width: 138px;
}
.PrivateTabIndicator-colorSecondary-3 {
  background-color: #a00030 !important;
}
.card-header-profile {
  display: flex;
  padding: 12px 2px 43px 4px;
  justify-content: center;
  cursor: pointer;
}
.card-body-profile {
  flex: 3 1;
  background: white;
  border-top-left-radius: 17px;
  border-top-right-radius: 17px;
  cursor: pointer;
}
.card-profile {
  display: flex;
  flex-direction: column;
  /* background: linear-gradient(90deg, #000940 0%, #a00030 100%); */
  border-radius: 21px;
  background: #e4ccd4;
}
.avatar-profile {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 11px;
  margin-top: -37px;
}
.avatar-profile img {
  max-width: 100%;
  object-fit: cover;
}
.dis-color {
  color: gray;
  font-size: 0.9375rem;
}
.social-icons {
  color: rgb(160, 0, 48);
  width: 21px;
  height: 33px;
  cursor: pointer;
  margin-right: 16px;
}
.option-profile {
  border: 1px solid gray;
  padding: 4px 11px;
  border-radius: 7px;
  margin-left: 3rem;
}
.small-font {
  font-size: 12px;
  color: gray;
}
.bg.grad {
  background: linear-gradient(90deg, #000940 0%, #a00030 100%);
}
.social-icons:hover {
  color: blue;
}
.profileCopy-card p {
  cursor: pointer;
  font-size: 12px;
  text-decoration: underline;
  color: blue;
}
.profileCopy-card p:hover {
  color: rgb(160, 0, 48);
}
.unAccess-page {
  margin-top: 90px;
  margin-left: -24%;
  display: flex;
  justify-content: center;
}
.access-page {
  margin-top: 90px;
  margin-left: 5rem;
}
.copy-icon {
  margin-bottom: 1px;
  width: 18px;
  height: 14px;
  cursor: pointer;
}
.arrowIcon-home {
  width: 18px !important;
  height: auto !important;
  transition: all 0.4s ease;
}
.arrow-rotate {
  transform: rotateZ(90deg);
}
.add-slow {
  transition: all 0.4s ease;
}
.home-item-card {
  display: flex;
  flex-wrap: wrap;
  margin-top: -9px;
  grid-gap: 6px;
  grid-gap: 6px;
  gap: 6px;
}
.home-item-card p {
  font-size: 0.8rem;
  font-weight: 400;
  color: #333333;
  padding-left: 4.5rem;
  cursor: pointer;
  flex-basis: 100%;
}
.home-item-card p:hover {
  color: blue;
}
.animi-icon {
  width: 30px;
  height: auto;
  cursor: pointer;
}
.option-profile1 {
  border: 1px solid gray;
  padding: 7px 9px;
  border-radius: 7px;
  margin-left: 3rem;
  cursor: pointer;
}
.my-element {
  cursor: pointer;
}
.card-body-profile p:hover {
  color: rgb(160, 0, 48);
}
.postuserImg-fourm {
  display: block !important;
  /* margin-left: auto !important; */
  /* margin-right: auto !important; */
  object-fit: cover !important;
  object-position: top center !important;
  width: 50px !important;
  height: 50px !important;
  border-radius: 50px !important;
  box-shadow: rgb(0 0 0 / 16%) 0px 0px 5px !important;
  border: 3px solid rgb(255, 255, 255) !important;
}
.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 9rem;
}
.postuserImg-vcard {
  margin-top: -1.9rem;
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
  object-fit: cover !important;
  object-position: top center !important;
  width: 75px !important;
  height: 75px !important;
  border-radius: 50px !important;
  box-shadow: rgb(0 0 0 / 16%) 0px 0px 5px !important;
  border: 3px solid rgb(255, 255, 255) !important;
  margin-bottom: 0.5rem;
}
.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
  background-color: #a00030 !important;
  color: white !important;
}
.select.decorated option:hover {
  background-color: #a00030 !important;
}
.qr-active {
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  /* transform: rotateY(0deg);
  transition: transform 0.1s ease-in-out 0s;
  cursor: pointer; */
}
.qr-unactive {
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  /* transform: rotateY(0deg);
  transition: transform 1.3s ease-in-out 0s;
  cursor: pointer; */
  width: 300px;
}
.select {
  padding: 10px;
}
.qr-present {
  width: auto;
  display: block;
  margin: 0 auto;
}
.qr-card {
  padding: 2.1rem;
  display: flex;
  flex: 3 1;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
}
.lang-card {
  border-radius: 4px;
  background: white;
  position: absolute;
  top: 5.2rem;
  box-shadow: rgb(0 0 0 / 31%) 0px 2px 5px;
  /* overflow: scroll;
  overflow-x: hidden;
  height: 16rem; */
}
.lang-card-overflow > div:hover {
  background-color: rgb(160, 0, 48);
  cursor: pointer;
  border-radius: 4px;
  color: white !important;
}

.lang-card p {
  padding: 0.7rem;
}
.lang-card::before {
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 2px solid transparent;
  border-bottom: 9px solid #fff;
  content: "";
  top: -9px;
  position: absolute;
  right: 72px;
  z-index: 2;
}
.selected-lang {
  display: flex;
  align-items: center;
  justify-content: end;
  grid-gap: 0.7rem;
  gap: 0.7rem;
}
.selected-lang-option > p {
  padding-right: 15px;
  /* padding-top: 8px; */
  font-weight: 600;
  cursor: pointer;
}
.selected-lang-option > p:hover {
  color: #a00030;
}
/* .full-bg-color {
  background-color: #a00030 !important;
  color: white;
  border-radius: 4px;
} */
.up-arrow {
  position: fixed;
  bottom: 90px;
  right: 1rem;
  padding: 3px;
  opacity: 1;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #a00030ba;
  z-index: 10;
  border-radius: 50%;
  cursor: pointer;
}
.up-arrow:hover {
  background-color: gray;
}
.lang-card-overflow {
  /* height: 12rem; */
  overflow: scroll;
  overflow-x: hidden;
}

.lang-card-overflow::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}
.lang-card-overflow::-webkit-scrollbar {
  width: 2px;
  background-color: #f5f5f5;
}
.lang-card-overflow::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #a00030;
}
.home-img-icon-select {
  width: 34px;
  height: 39px;
  cursor: pointer;
  object-fit: cover;
}
.filter-btn {
  color: black !important;
  background: rgb(245, 245, 245);
}
.home-img-icon-friend {
  width: 42px;
  height: 53px;
  cursor: pointer;
}
.calender-edit {
  width: 23px;
  height: 19px;
  color: gray;
  margin-bottom: 2px;
  margin-left: 5px;
  cursor: pointer;
}
.stepper p {
  font-size: 13px;
  color: gray;
  max-width: 25rem;
}
.stepper {
  margin-top: 1.1rem;
}
.stepper-box {
  /* margin-top: 13px; */
  /* background: white; */
  padding: 2px;
  border-radius: 12px;
  /* box-shadow: 5px 5px 6px #0000000d; */
}
.stepper-box > div:nth-child(2) {
  color: #a00030;
  cursor: pointer;
}
.stepper-left {
  background: white;
  /* padding: 1rem; */
  border-radius: 12px;
  /* box-shadow: 5px 5px 6px #0000000d; */
}
.stepper-left > div:nth-child(1) {
  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
  grid-gap: 2rem;
  gap: 2rem;
  padding: 23px 0px 64px 28px;
}
.radio-box input[type="radio"] {
  cursor: pointer;
  width: 26px;
  height: 18px;
}
.file-uploader {
  align-items: center;
  justify-content: center;
  border-style: dotted;
  display: flex;
  text-align: center;
  border-radius: 12px;
  cursor: pointer;
  flex-flow: column;
}
.details-card {
  margin-top: 1rem;
  margin-bottom: 1rem;
  /* width: 19rem; */
  background: white;
  padding: 14px;
  border-radius: 12px;
  box-shadow: 5px 5px 6px #0000000d;
}
.market-checkbox {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.wizard-icon {
  width: 24px;
  height: 24px;
  color: gray;
}
.cancel-btn {
  font-size: 12px !important;
  padding: 2px 6px 3px 6px !important;
}
.my-button {
  position: fixed;
  right: 0;
  top: 16rem;
  font-size: 15px !important;
  font-weight: bold;
  transform: translate(36%, 50%) rotate(270deg);
  border: 1px solid gray;
  transition: transform 0.2s ease-in-out;
}
.my-button:hover {
  opacity: 0.5;
}
.peopleInvitationCardWrapper1 {
  background: #ffffff 0% 0% no-repeat padding-box;
  align-items: center;
  padding: 13px 10px;
  margin-bottom: 6px;
  box-shadow: rgb(17 17 26 / 10%) 0px 4px 16px, rgb(17 17 26 / 5%) 0px 8px 32px;
  border: 1px solid #f7f7f7;
  border-radius: 10px;
  cursor: pointer;
}
.gird-myreq {
  display: grid;
  /* grid-template-columns: repeat(auto-fit, minmax(17rem, 1fr)); */
  align-items: center;
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.8rem;
}
.gird-myreq > p {
  color: gray;
}
.gird-myreq li {
  font-size: 13px;
}
.gird-myreq div {
  font-size: 13px;
}
.askAQuestionCardWrapperNew {
  background: #ffffff;
  box-shadow: rgb(17 17 26 / 10%) 0px 4px 16px, rgb(17 17 26 / 5%) 0px 8px 32px;
  border: 1px solid #dededfbf;
  padding: 18px 35px 35px;
  border-radius: 7px;
}
.homeMyRequestCard::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
.homeMyRequestCard::-webkit-scrollbar-thumb {
  height: 120px;
}
.community-tag-wrapper::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
.community-tag-wrapper::-webkit-scrollbar-thumb {
  height: 120px;
}
.community-tag-wrapper {
  height: 11rem;
  overflow: scroll;
  text-transform: capitalize;
}
.otherRequestPage-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.otherRequestPage-wrapper:hover {
  color: blue;
}
.setupMainCard::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
.announcement-image {
  width: 40%;
  aspect-ratio: 3/2;
  object-fit: cover;
  height: 50%;
  mix-blend-mode: color-burn;
  margin-top: 5px;
}
.need-help-Modal {
  width: 100vh;
  z-index: 10;
  background: block;
  border-radius: 4px !important;
  color: #0000007a;
  bottom: 0;
  /* position: absolute; */
  top: 0;
  /* padding-top: 45px; */
  overflow-y: scroll;
  position: static;
}

.ask-badge {
  position: fixed;
  top: 14.5rem;
  right: 0;
  z-index: 1;
}
.need-help-Modal-close {
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-left: 12.6rem;
}
.need-help-wrapper {
  padding: 12px;
  color: black;
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
}
.need-help-card {
  position: relative;
  background: #2b544c1f;
  padding: 9px;
  max-width: 19.3rem;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.ask-help-imgCard {
  display: block !important;
  object-fit: cover !important;
  object-position: top center !important;
  width: 65px !important;
  height: 65px !important;
  border-radius: 50px !important;
  box-shadow: rgb(0 0 0 / 16%) 0px 0px 5px !important;
  border: 3px solid rgb(255, 255, 255) !important;
}
.need-help-card p {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 6rem;
  white-space: nowrap;
  color: gray;
  font-size: 10px;
}
.need-help-card a {
  color: gray !important;
}
.need-help-card :hover {
  cursor: pointer;
}
.arrow-request {
  position: absolute;
  top: 0;
  right: 0;
  width: 37px;
  height: 31px;
  background: #a0000042;
  border-radius: 0px 8px 0px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.arrow-request-svg {
  width: 20px;
  height: 18px;
  color: #0000009c;
  margin-bottom: 4px;
}
.need-help-card:hover {
  transition: all 0.2s ease-out;
  box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
  top: -4px;
  border: 1px solid #cccccc;
  background-color: #a0000042;
}
.req-un-active-menu {
  width: 10.3rem;
  background: #80808036;
  color: black;
  border-color: white;
  border-radius: 15px !important;
}
.req-un-active-menu:hover {
  background: #808080ab;
}
.req-active-menu {
  width: 10.3rem;
  color: white;
  background: black;
  border-color: white;
  border-radius: 15px !important;
}
.annoucmentBox img {
  width: 75vw;
  height: auto;
}

.annoucmentBox h3 {
  font-size: 31px;
  line-height: 42px;
  letter-spacing: -1.4px;
  font-weight: normal;
  color: #22222278;
  margin-top: 1rem;
  margin-left: 1rem;
}
.image-container {
  position: relative;
}
.announcement-overlay {
  background-color: rgb(0 0 0 / 53%);
  color: white;
  padding: 10px;
  width: 100%;
}

.svg-icons {
  width: 24px;
  height: 24px;
  cursor: pointer;
  color: black;
}
.slick-next:before {
  content: "→";
  margin-left: -3rem !important;
  color: black !important;
}
.home-announcement > p {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.home-announcement-wrapper {
  position: absolute;
  bottom: 13px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px;
}
.home-announcement-wrapper > h3 {
  margin: 0;
}
.waring-box {
  display: flex;
  flex: 1 1;
  justify-content: end;
}
.waring-box > p {
  margin-right: 1rem !important;
  font-size: 12px;
  color: gray;
  margin-top: 4px !important;
}
.search-container {
  position: relative;
  /* display: inline-block; */
  margin-right: 10px;
  width: 100%;
  display: flex;
}

.search-container input[type="text"] {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  width: 100%;
}

.search-container ul {
  position: absolute;
  top: 57px;
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0;
  margin: 0;
  z-index: 1;
}

.search-container li {
  list-style: none;
  padding: 10px;
  cursor: pointer;
}

.search-container li:hover {
  background-color: #f2f2f2;
}
.rdw-suggestion-dropdown {
  position: absolute;
  display: flex;
  flex-direction: column;
  border: 2px solid rgba(0, 0, 0, 0.15) !important;
  min-width: 100px;
  max-height: 150px;
  overflow: auto;
  background: white !important;
  cursor: pointer !important;
  z-index: 100;
}
.rdw-suggestion-dropdown > span:hover {
  background: #a00030 !important;
  color: white;
}
.textarea-comment {
  border: 1px solid #ccc;
  border-radius: 7px;
  padding: 5px;
  background: white;
}
.editor-wrapper {
  overflow: hidden;
}
.form-control-comment {
  overflow-y: hidden !important;
  resize: none;
  /* max-height: 5rem !important; */
  /* font-size: 16px;
  line-height: 1.5;
  height: 200px; */
}
/* .rdw-suggestion-wrapper {
  position: fixed;
  top: 100px; 
  left: 100px;  
  z-index: 1; 
} */
.editor-textFiled {
  background-color: #000;
}
.DraftEditor-editorContainer::-webkit-scrollbar {
  width: 4px;
  height: 20px;
}
.box-shadow {
  border: 1px solid rgb(0 0 0 / 10%) !important;
  border-radius: 5px !important;
}
.skeleton {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  padding: 1rem;
  background-color: #f0f0f0;
}

.skeleton-img {
  width: 100px;
  height: 100px;
  background-color: #ccc;
}

.skeleton-text {
  flex: 1 1;
  height: 1.5rem;
  background-color: #ccc;
}
.underline-text {
  cursor: pointer;
  font-size: 12px;
  text-decoration: underline !important;
  color: blue !important;
}
.csv-button {
  border: 3px solid gray;
  position: relative;
  display: inline-block;
  overflow: hidden;
  cursor: pointer;
  text-align: center;
  border-style: dashed;
  width: 94%;
  border-radius: 13px;
}

.csv-button:before {
  content: "Drag and drop a file here or click to select a file";
  position: absolute;
  top: 21px;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  text-align: center;
  line-height: 1;
  pointer-events: none;
  z-index: 1000;
  background: white;
}
.user-count {
  position: absolute;
  left: 28px;
  bottom: 22px;
}

.csv-button input[type="file"] {
  padding: 27px 35px;
  cursor: pointer;
  width: 100%;
}
.csv-button:hover {
  cursor: pointer;
  border: 3px solid #db2828;
  border-style: dashed;
}
.react-share__ShareButton {
  background: white !important;
}
.workspace-button {
  position: absolute;
  right: 27px;
  top: 74px;
}
.add-btn {
  font-size: 12px !important;
  background: #0000ff29 !important;
  padding: 6px 10px !important;
  margin-left: 12px !important;
  float: right !important;
  border-radius: 4px !important;
}
.add-btn-green {
  background: #c4eece !important;
}
.hashtag-style {
  grid-gap: 0.6rem;
  gap: 0.6rem;
  font-size: 1rem;
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  margin-bottom: 1rem;
  align-items: center;
}
.hashtag-style-group {
  grid-gap: 0.6rem;
  gap: 0.6rem;
  font-size: 1rem;
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.2rem;
  margin-left: 0.8rem;
  align-items: center;
}
.directory-card {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
}
.directory-card-wrapper {
  /* width: 24rem; */
  height: 14rem;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  color: #f0f0f0;
  box-shadow: 0 10px 30px 5px rgba(0, 0, 0, 0.2);
}
.directory-card-wrapper img {
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.9;
  transition: opacity 0.2s ease-out;
  filter: brightness(0.5);
}
.directory-card-wrapper h2 {
  position: absolute;
  inset: auto auto 30px 30px;
  margin: 0;
  transition: inset 0.3s 0.3s ease-out;
  font-family: "Roboto Condensed", sans-serif;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: bold;
}
.directory-card-wrapper p {
  position: absolute;
  opacity: 0;
  max-width: 80%;
  transition: opacity 0.3s ease-out;
  font-size: 12px;
  inset: auto auto 80px 30px;
}

.directory-card-wrapper:hover h2 {
  inset: auto auto 115px 30px;
  transition: inset 0.3s ease-out;
}
.directory-card-wrapper:hover p {
  opacity: 1;
  transition: opacity 0.5s 0.1s ease-in;
}
.directory-card-wrapper:hover img {
  transition: opacity 0.3s ease-in;
  opacity: 1;
}

.material-symbols-outlined {
  vertical-align: middle;
}
.directory-card-wrapper:hover hr {
  background: white;
  position: absolute;
  width: 100%;
  bottom: 93px;
  left: 32px;
  transition: 0.5s 0.1s ease-in;
}
.directory-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  align-items: center;
  justify-content: center;
  text-align: center;
  grid-gap: 3rem;
  gap: 3rem;
}

.image-gallery {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 2rem;
  gap: 2rem;
}

.selected-image {
  width: 100%;
  max-height: 400px;
  object-fit: cover;
  border-radius: 5px;
  cursor: pointer;
}

.image-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  gap: 10px;
}

.image-item-dir {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
  border-radius: 8px;
  opacity: 0.5;
}

.image-item-dir.active {
  border: 2px solid #a00030;
  opacity: 1;
}
.image-item-dir:hover {
  opacity: 1;
}
.skeleton {
  width: 100%;
  height: 100%;
  background-color: #f3f3f3;
  background-image: linear-gradient(
    90deg,
    #f3f3f3 25%,
    #e6e6e6 37%,
    #f3f3f3 63%
  );
  background-size: 200% 100%;
  animation: skeleton-loading 1.5s ease-in-out infinite;
}

@keyframes skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: -200% 50%;
  }
}
.selected-image-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: opacity 0.3s;
}

.arrow:hover {
  opacity: 0.7;
}

.left-arrow {
  left: 10px;
}

.right-arrow {
  right: 10px;
}
.svg-icons-dir {
  width: 24px;
  height: auto;
  cursor: pointer;
}
.dir-card {
  border-radius: 5px;
  padding: 20px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.dir-content::before {
  content: " . ";
  color: #a00030;
  margin-left: 0.3rem;
}
/* .dir-card > h6 {
  font-size: 18px;
  font-weight: bold;
} */
.dir-card p {
  font-size: 14px;
  color: darkgray;
}
.full-image-container {
  width: 100%;
  height: 300px;
  position: relative;
}
.full-image-container > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: gray;
  filter: brightness(0.8);
  cursor: pointer;
  border-radius: 5px;
}
.full-image-container > div {
  position: absolute;
  bottom: 46px;
  left: 36px;
  color: white;
}
.dir-card-icons {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 1rem;
  gap: 1rem;
  word-break: break-word;
  align-items: center;
}
.dir-card > span {
  background: #0080003d;
  padding: 1px 8px;
  font-size: 9px;
  border-radius: 2px;
}
.div-card-headr-icons {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.div-card-headr-icons > svg {
  width: 19px;
  height: 19px;
  margin-right: 5px;
}
.hash-bg-color {
  color: #a00030 !important;
}
.dir-similar {
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  border: 1px solid #80808059;
  border-radius: 9px;
  padding: 5px;
  margin-bottom: 1rem;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.dir-similar > img {
  width: 100%;
  height: 10rem;
  object-fit: cover;
  border-radius: 6px;
  opacity: 1;
}
.dir-sub-card {
  border-radius: 5px;
  padding: 10px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  max-height: 71rem;
  overflow: scroll;
  overflow-x: hidden;
  margin-bottom: 3rem;
}
.dir-sub-card::-webkit-scrollbar {
  width: 6px;
}
.dir-similar:hover {
  background: #dcdcdc3b;
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  grid-gap: 2rem;
  gap: 2rem;
}
.grid-wrap-dir {
  grid-column: span 2;
}
@media (max-width: 1200px) {
  .grid-wrap-dir {
    grid-column: auto;
  }
}
.rmdp-container {
  width: 100%;
}
.announcement-edit {
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
  margin-right: 1rem;
  margin-bottom: 1rem;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  color: #a00030;
  font-weight: 700;
  cursor: pointer;
}
.listHomeTitle > p {
  font-size: 14px;
  color: gray;
  margin-top: 0.2rem !important;
}
.listHomeTitle > p::before {
  content: " - ";
  margin-left: 5px;
}
.search-icon {
  background: white;
  right: 6px;
}
.resource-img-icon {
  object-fit: cover;
  width: 64px;
  height: 64px;
}

.cardButton-com {
  border: 1px solid #8080805e !important;
  border-radius: 4px !important;
  font-size: 17px !important;
  background: white !important;
}
.kyc-background {
  background: #1e8fd5;
  padding: 8px;
  border-radius: 4px;
  color: white;
  max-width: 33rem;
  font-size: 14px;
}
.kyc-background > p::before {
  content: "Disclaimer : ";
  font-weight: 700;
}
.sub-font {
  font-size: 12px;
  color: gray;
}
.message-icon {
  width: 20px;
  height: 20px;
  margin-right: 6px;
}
.adb-card-new {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 10px;
  grid-gap: 2em;
  gap: 2em;
}
.adb-card-new > div {
  width: 100%;
}
.share-popup {
  border: 1px solid gray;
  border-radius: 4px;
  width: 100%;
  min-height: 20px;
  padding: 5px;
}
.article-wrap {
  margin-top: 4rem;
  margin-left: -16%;
  overflow-wrap: break-word;
}
.selfAssessment-form {
  background: #f8f9fa;
  padding: 2rem;
  border-radius: 5px;
}
.friendsCardWrapper-new {
  box-shadow: 0px 1px 2px #00000029;
  border: 1px solid #f7f7f7;
  border-radius: 5px;
  background: #fff;
  align-items: center;
  justify-content: center;
  padding: 11px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background: #dcdcdc1f;
}
.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-top: 0px;
  /* min-height: 8rem; */
}
.editor-container {
  position: relative;
}

.mention-dropdown {
  position: absolute;
  /* bottom: -100%;   */
  left: 0;
  width: 100%;
  padding: 8px;
  background-color: #fff;
  border: 1px solid #ccc;
  list-style-type: none;
  margin: 0;
  z-index: 1;
  /* top: 0; */
}

.mention-dropdown li {
  cursor: pointer;
}

.mention-dropdown li:hover {
  background-color: #f2f2f2;
}
.resource-file-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(8rem, 1fr));
  grid-gap: 0.3rem;
  grid-gap: 0.3rem;
  gap: 0.3rem;
  margin-top: 1rem;
}
.resource-content {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
  padding: 8px;
  border: 1px solid #a00030;
  border-radius: 9px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  height: 8rem;
  overflow: hidden;
}
.resource-content:hover {
  background-color: #dcdcdc91;
}

.resource-content > img {
  border-radius: 5px;
  object-fit: cover;
  margin-top: 1rem;
  height: 100%;
  margin-bottom: 1rem;
  width: auto;
}
.video-play-icon {
  background: white;
  border-radius: 50%;
  color: #a00030;
  padding: 3px;
}
.svg-icon-doc {
  width: 28px;
  height: 44px;
  cursor: pointer;
}
.mentor-time-slot {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(6rem, 1fr));
  justify-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}
.mentor-time-slot > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border: 1px solid #a00030;
  border-radius: 5px;
  padding: 7px;
  cursor: pointer;
  font-weight: 500;
  color: #a00030;
}

.mentor-time-slot > div:hover {
  border: none !important;
  background-color: #fcf7f8;
  color: black !important;
}
.mentor-selected {
  background-color: blue;
  color: white !important;
  border: none !important;
}
.mentor-slot {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(11rem, 1fr));
  padding: 22px;
  border: 1px solid #a00030;
  border-radius: 5px;
  grid-gap: 2rem;
  gap: 2rem;
  font-weight: 700;
  /* color: #a00030; */
}
.mentor-slot > span {
  padding: 5px;
  text-align: center;
  border-radius: 5px;
  border: 1px solid #a00030;
  cursor: pointer;
}
.mentor-slot-select {
  /* background-color: #f3e0e5; */
  color: black !important;
  border: none !important;
}
.mentor-slot-chip {
  padding: 10px;
  border: 1px solid #a00030;
  border-radius: 5px;
  grid-gap: 2rem;
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  font-weight: 700;
  flex-wrap: wrap;
}
.time-slots {
  border: 1px solid #a00030;
  padding: 10px;
  border-radius: 5px;
  background: #fcf7f8;
  /* cursor: pointer; */
}

.right-model {
  overflow: scroll;
  height: 90vh;
}
.time-slot-model {
  border: 1px solid #a00030;
  padding: 5px;
  border-radius: 5px;
  background: #fcf7f8;
  font-size: 8px;
  width: 100%;
  cursor: pointer;
  text-align: center;
  font-weight: 700;
}
.time-slot-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(7rem, 1fr));
  justify-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}
.time-slot-model:hover {
  background-color: white;
}
.mentor-select-model {
  color: white;
  border: none !important;
  background: blue !important;
}
.slide-in {
  transition: transform 0.5s ease !important;
  transform: translateX(100%) !important;
}

.slide-in.show {
  transform: translateX(0%) !important;
}
.mentor-selected-slot {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(20rem, 100%), 1fr));
  padding: 22px;
  grid-gap: 2rem;
  grid-gap: 2rem;
  gap: 2rem;
}
.slot-box {
  padding: 5px;
  border: 1px solid #a00030;
  border-radius: 5px;
  text-align: center;
}
.connectionredLabel-slot {
  color: #ba2121 !important;
  font-size: 12px !important;
  background: #eec4c4 !important;
  padding: 6px 10px !important;
  float: right !important;
  border-radius: 4px !important;
  width: 100px !important;
}

/* .top-dialog-paper {
  bottom: 10vh;
  margin-top: 0 !important;
} */
.pop-up-images {
  list-style: none;
  align-items: center;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(5rem, 1fr));
  text-align: center;
  padding: 0;
  margin-bottom: -15px;
  margin-top: 0.5rem;
  grid-gap: 1rem;
  gap: 1rem;
}
.popup-active {
  /* opacity: 0.5; */
}
.popup-active:after {
  content: "";
  display: block;
  height: 3px;
  width: 100%;
  background: #a00030;
  position: absolute;
  top: 65px;
}
.pop-up-images > li {
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.resource-like {
  flex-wrap: wrap;
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 6px;
  padding: 0;
  grid-gap: 1rem;
  gap: 1rem;
}
.resource-item {
  cursor: pointer;
  width: -moz-fit-content;
  width: fit-content;
  color: gray;
  font-size: 12px;
}
.resource-overflow {
  max-height: 44rem;
  overflow: scroll;
  overflow-x: hidden;
}
.participant-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(17rem, 1fr));
  grid-gap: 1rem;
  gap: 1rem;
}
.remove-user-button {
  background: #a00030;
  border-radius: 50%;
  font-size: 36px;
  padding: 5px;
  position: absolute;
  top: -15px;
  right: -9px;
  color: white;
  cursor: pointer;
}
.remove-user-button:hover {
  background: red;
}
.learning-filter {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(14rem, 1fr));
  grid-gap: 2rem;
  gap: 2rem;
}
.home-post-button {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-left: 1.5rem;
  grid-gap: 1rem;
  gap: 1rem;
}

.poll-btn {
  background: rgb(221, 76, 94) !important;
  border: none !important;
}
.survey-btn {
  background: rgb(253, 132, 144) !important;
  border: none !important;
}
.article-btn {
  background: rgb(253, 104, 118) !important;
  border: none !important;
}
.group-help-card {
  position: relative;
  background: #2b544c1f;
  padding: 9px;
  /* max-width: 19.3rem; */
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  flex-wrap: wrap;
}
.bg-light-new {
  background-color: #e9ecef !important;
}
.bell-svg {
  position: absolute;
  left: 2rem;
  bottom: 1rem;
}
.profile-img {
  cursor: pointer;
  margin-right: 21px;
  width: auto;
  height: 24px;
  margin-left: 0.3rem;
}
.valuematch-img {
  cursor: pointer;
  margin-right: 7px;
  width: auto;
  height: 24px;
}
.education-img {
  cursor: pointer;
  margin-right: 8px;
  width: auto;
  height: 19px;
}
.skill-img {
  cursor: pointer;
  margin-right: 13px;
  width: auto;
  height: 32px;
}
.profession-img {
  cursor: pointer;
  margin-right: 14px;
  width: 29px;
  height: 29px;
}
.group-count {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}
.formInputsDescriptions-new {
  width: 91%;
  border: 1px solid #808080a3;
  border-radius: 6px;
  padding: 5px;
}
.time-slot-wrap {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(11rem, 1fr));
}
.calender-btn {
  background: blue !important;
  border: none !important;
}
.event-box input[type="checkbox"] {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.text-outer-box {
  border: 1px solid #ddd;
  border-radius: 3px;
}
.time-wrapper {
  background: #8080803b;
  padding: 2rem;
  margin-bottom: 1rem !important;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  grid-gap: 1rem;
  gap: 1rem;
}
.seller-container {
  position: relative;
}
.seller-container img {
  height: 500px;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
  filter: brightness(0.7);
}
.seller-container > h4 {
  bottom: 4rem;
  position: absolute;
  left: 2rem;
  font-weight: bold;
  color: white;
}

.indicator {
  position: relative;
  width: 25px;
  height: 25px;
  transform: rotate(45deg);
}

.indicator span {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: none;
  border-bottom: 3px solid #fff;
  border-right: 3px solid #fff;
  animation: animate 1s linear infinite;
}

.indicator span:nth-child(1) {
  top: -30px;
  left: -30px;
  animation-delay: 0s;
}

.indicator span:nth-child(2) {
  top: -15px;
  left: -15px;
  animation-delay: 0.2s;
}

.indicator span:nth-child(3) {
  top: 0;
  left: 0;
  animation-delay: 0.4s;
}

.indicator span:nth-child(4) {
  top: 15px;
  left: 15px;
  animation-delay: 0.6s;
}

@keyframes animate {
  0% {
    border-color: blue;
    transform: translate(0, 0);
  }
  20% {
    border-color: blue;
    transform: translate(15px, 15px);
  }
  20.1%,
  100% {
    border-color: blue;
  }
}
.down-arrow {
  transform: rotate(270deg);
  -webkit-transform: rotate(270deg);
}
.notification-card {
  background: aliceblue;
  border: 1px solid #80808082;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}
::selection {
  background: #a00030;
  color: white;
}
.community-description {
  display: flex;
  background: ghostwhite;
  align-items: center;
  justify-content: center;
}
.group-card {
  width: 21rem;
  max-height: 25rem;
  margin-top: 1rem;
  background: white;
  padding: 1rem 1rem;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 1rem;
  border-radius: 15px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.group-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.group-card p {
  font-size: 12px;
}
.bg-warning-post {
  background-color: #ffc10799 !important;
}
.popover-body {
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.mentor-home-card {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(17rem, 1fr));
  text-transform: capitalize;
}
.mentor-home-card > div {
  display: flex;
  height: 9rem;
  grid-gap: 1rem;
  gap: 1rem;
  overflow: hidden;
  background: #fff;
  box-shadow: rgb(17 17 26 / 10%) 0px 4px 16px, rgb(17 17 26 / 5%) 0px 8px 32px;
  border-radius: 5px;
  cursor: pointer;
}
.mentor-home-card > div:hover {
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
}
.mentor-home-card img {
  height: auto;
  width: 124px;
  object-fit: cover;
}
.mentor-home-card h4 {
  font-weight: bold;
  font-size: 18px;
  color: gray;
}
.mentor-home-card p {
  font-size: 12px;
}
.drawer-body {
  background: #8080801c;
  padding: 1rem;
  border-radius: 12px;
  margin-top: 2rem !important;
  margin-bottom: 1rem !important;
}
.drawer-bar {
  border: 1px solid gray;
  padding: 1rem;
  border-radius: 5px;
  overflow: hidden;
  height: 4rem;
}
.drawer-bar-active {
  min-height: 4rem;
  border: 0.5px solid #b5b5b5;
  padding: 1rem;
  border-radius: 5px;
  overflow: hidden;
}

.mentor-menu {
  position: absolute;
  top: 21px;
  right: 12px;
  height: 24px;
  width: auto;
  transform: translate(0%, 0%) rotate(0deg);
  cursor: pointer;
}
.mentor-menu.active {
  transform: translate(0%, 0%) rotate(90deg);
}
.youtube-container {
  grid-gap: 1rem;
  gap: 1rem;
  padding: 1.3rem;
  border-radius: 5px;
  /* background: black; */
  border: 1px solid gray;
}
.connection-card {
  background: gainsboro;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  padding: 1rem;
  border-radius: 5px;
}
.admin-span {
  background: gold;
  border-radius: 15px;
  font-size: 12px;
  padding: 2px 8px;
  position: absolute;
  right: 5px;
  top: 107px;
  color: blue;
}

.admin-span > svg {
  width: 23px;
  height: auto;
  margin-top: 0px;
  color: darkviolet;
}

.group-filter {
  grid-gap: 1rem;
  gap: 1rem;
  background: gainsboro;
  padding: 1rem;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.community-img {
  border: 1px solid gray;
  border-radius: 5px;
  padding: 13px;
  margin-bottom: 27px;
}

.referral-code {
  cursor: pointer;
  background: #dcdcdc8f;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 2px;
  font-size: 14px;
  padding: 1px 9px;
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}
.referral-code p {
  font-size: 12px;
  color: brown;
}

.social-icons-share {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
}
.social-icons-share ul {
  padding: 0;
  flex-direction: row;
  grid-gap: 2rem;
  gap: 2rem;
  margin-top: 2rem;
}

.social-icons-share li {
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  color: gray;
  grid-gap: 1rem;
  gap: 1rem;
}
.copy-button {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: -moz-fit-content;
  width: fit-content;
  grid-gap: 7px;
  gap: 7px;
}

.copy-button li {
  padding: 4px;
}
.copy-button li:nth-child(1) {
  background: aliceblue;
  padding: 13px;
  border: 1px solid gainsboro;
  cursor: pointer;
}
.copy-button li:nth-child(2) {
  background: antiquewhite;
  padding: 13px;
  cursor: pointer;
}
.waviy {
  position: relative;
}
.waviy span {
  position: relative;
  display: inline-block;
  font-size: 14px;
  color: #a00030;
  animation: flip 2s infinite;
  animation-delay: calc(0.2s * var(--i));
  font-weight: 700;
}
@keyframes flip {
  0%,
  80% {
    transform: rotateY(360deg);
    color: black;
  }
}

.moving-arrow {
  animation: arrow 1s ease-in infinite alternate;
  margin-left: 1rem;
}
@keyframes arrow {
  0% {
    margin-right: 1rem;
  }
  25% {
    margin-right: 1.5rem;
  }
  50% {
    margin-right: 2rem;
  }
  75% {
    margin-right: 1.5rem;
  }
  100% {
    margin-right: 1rem;
  }
}

.market-place-animation > span {
  margin: 0 15px;
  font-size: 12px;
  font-weight: 200;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.45);
  animation: span 4s ease-in infinite alternate;
}

.design-letter {
  display: inline-flex;
  height: 30px;
  width: 27px;
  border: 2.35px solid black;
  border-radius: 14px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.75), inset 0 0 2px rgba(0, 0, 0, 0.45);
  animation: letter 3s ease-in-out infinite alternate;
}

@keyframes span {
  0%,
  30% {
    margin: 0 1px;
  }
  70%,
  100% {
    margin: 0 3px;
    font-weight: 500;
  }
}
@keyframes letter {
  0%,
  30% {
    width: 27px;
  }
  70%,
  100% {
    width: 30vw;
  }
}
.green-btn {
  background: forestgreen;
  padding: 12px;
  border-radius: 22px;
  color: white;
  cursor: pointer;
  font-size: 13px;
}
.directory-wrap {
  background: gainsboro;
  margin-top: 1rem;
  grid-gap: 1rem;
  gap: 1rem;
  padding: 1rem;
  border-radius: 5px;
}

.profile-form {
  /* background: aliceblue; */
  margin: 1rem;
  border-radius: 14px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
/* .form-label {
  color: #999999;
  font-size: 18px;
} */
.card-dName {
  color: #333333;
  font-size: 18px;
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden !important;
  padding: 1px 1px 0px 10px;
  margin: 0;
}
.w-content {
  width: max-content;
}
.selected-img {
  height: 50px;
  width: auto;
}

#popover-basic {
  margin-left: 0px;
  animation: popover 0.5s ease;
}
@keyframes popover {
  0% {
    margin-left: 25vw;
  }

  100% {
    margin-left: 0vw;
  }
}
.nav-new-menus {
  color: black;
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  font-size: 0.9375rem;
  grid-gap: 4px;
  gap: 4px;
}
.nav-new-menus > li {
  margin-left: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 1rem;
  padding: 0.5rem;
}
.nav-active {
  border: 0.01rem solid #a00030;
  border-radius: 5px;
  color: brown;
  /* border: 1px solid #a00030;
  border-radius: 5px;
  background: #a000300d;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px; */
  /* color: black; */
}
.nav-new-menus svg {
  width: 24px;
  height: auto;
}
.nav-new-menus li > span:nth-child(1) {
  margin-right: 0.5rem;
}
.sub-nav-menu {
  margin-left: 2rem !important;
  font-size: 12px;
  color: black;
}
.text-filed-border {
  background: white;
  border-radius: 5px;
}
.connection-grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(7rem, 1fr));
  grid-gap: 1rem;
  gap: 1rem;
}
/* market-card  */

.market-card-container {
  width: 100%;
  background-color: white;
  border-radius: 15px;
  margin-bottom: 1rem;
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

/* Details */
.hero-image-container {
  height: 330px;
  display: flex;
  width: auto;
  border-radius: 14px;
  cursor: pointer;
  background: black;
}
.hero-image-container img {
  width: 100%;
  height: 100%;
  object-fit: fill;
  border-radius: 1rem;
  transition: all 0.5s ease-in-out;
}
/* .hero-image-container::after {
  content: "";
  background-image: url("https://i.postimg.cc/9MtT4GZY/view.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 5rem;
  background-color: hsla(178, 100%, 50%, 0.3);
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  z-index: 2;
  opacity: 0;
  transition: opacity 0.3s ease-out;
} */

.hero-image-container > img:hover {
  border-radius: 45%;
}

.small-image {
  width: 1.2em;
  margin-right: 0.5em;
}

.small-avatar {
  width: 2em;
  border-radius: 200px;
  outline: 2px solid white;
  margin-right: 1.4em;
}
.pay-button {
  background: #00800075;
  color: black;
  border: none;
  padding: 5px 18px;
  font-weight: 600;
  transition: all 0.5s ease-in-out;
}

.market-grid {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
  grid-gap: 1rem;
  grid-gap: 1rem;
  gap: 1rem;
}
.friend-about-card {
  color: gray;
  width: auto;
  height: 19px;
  margin-right: 5px;
}

/* Nav Menu  */
.nav-menu-new {
  position: relative;
  display: flex;
  height: 100vh;
  padding: 0rem 4rem;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.nav-menu-new > main {
  flex: 1 1;
  overflow-x: clip;
  width: 100%;
}
@-moz-document url-prefix() {
  .announcement-res-wrap {
    width: 50vw;
  }
}
.nav-menu-new > *:nth-child(1),
.nav-menu-new > *:nth-child(2) {
  margin-top: 7rem;
}
.new-mobileMenu {
  display: none;
}

.home-sub {
  color: gray;
  font-size: 14px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-weight: 600;
}
.t-community-logo {
  width: 33px;
  height: auto;
  margin-right: 7px;
  color: brown;
  margin-bottom: 5px;
}
.cardWrapper > span {
  padding: 0.8rem;
  display: flex;
  align-items: center;
  text-align: center;
}
.trending-card:hover {
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.header-menu-new {
  display: flex;
  align-items: center;
  padding-left: 4rem;
  padding-right: 4rem;
}
.like-alert {
  position: absolute;
  left: 38%;
  transform: translate(-50%, -50%);
  padding: 20px;
  z-index: 10000;
  top: 93%;
}
.menu-filter-list {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 1rem;
}
.menu-filter-list > li {
  display: flex;
  list-style: none;
  padding: 1rem;
  grid-gap: 1rem;
  gap: 1rem;
}
.menu-filter-list svg {
  height: 24px;
  color: #a00030;
  width: auto;
}
.menu-filter-list h4 {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
}
.menu-filter-list > li:hover {
  cursor: pointer;
  background: gainsboro;
  border-radius: 5px;
}
.menu-filter-list p {
  font-size: 13px;
  margin-top: 5px !important;
}
.profile-card-v1 {
  background: white;
  margin-top: 10px;
  box-shadow: 0px 1px 3px #00000029;
  border: 1px solid #f7f7f7;
  border-radius: 3px;
  list-style: none;
  padding: 0;
  height: 800px;
}
.profile-card-v1 li {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  grid-gap: 1rem;
  gap: 1rem;
  margin-left: 1rem;
  border-bottom: 1px solid gray;
  padding-bottom: 1rem;
  padding-top: 1rem;
  cursor: pointer;
  color: gray;
}
.profile-card-v1 li:first-child {
  margin-top: 1rem;
}
.profile-card-v1 svg {
  width: 24px;
  height: auto;
}
.profile-card-v1 h4 {
  margin: 0;
  font-size: 15px;
}
.pay-svg,
.pay-button svg {
  width: 18px;
  height: auto;
  margin-right: 0.5rem;
  margin-bottom: 3px;
}
.m-button {
  background: blue;
  color: white;
  border-radius: 5px;
  padding: 8px;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
}
/* market place animation */
.product-container {
  display: flex;
  justify-content: center;
  padding: 1rem;
  background: #a9a9a942;
  grid-gap: 1rem;
  grid-gap: 1rem;
  gap: 1rem;
  flex-wrap: wrap;
  border-radius: 5px;
}

.product-container h6 {
  font-size: 13px;
  font-weight: bold;
}
.product-container > div:nth-child(1) {
  max-width: 17rem;
  height: 17rem;
}
.product-container > div:nth-child(2) {
  display: flex;
  flex: 1 1;
  flex-direction: column;
}
/* .product-container span {
  color: white;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
   font-weight: bold;
  font-size: 14px;
  width: 100%;
  cursor: pointer;
  margin-top: auto;
  border-radius: 5px;
  animation: product 1s infinite;
}
@keyframes product {
  from {
    background: linear-gradient(
      12deg,
      rgba(34, 193, 195, 1) 0%,
      rgb(236 45 253) 100%
    );
  }
  to {
    background: linear-gradient(
      317deg,
      rgba(34, 193, 195, 1) 0%,
      #c435dca8 100%
    );
  }
} */
.product-container img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
  /* cursor: pointer; */
}
.sub-product-container h4 {
  font-size: 17px;
  font-weight: bold;
}
.sub-product-container > p:nth-child(2) {
  text-transform: capitalize;
  word-break: break-all;
  color: gray;
  font-size: 11px;
  display: -webkit-box;
  -webkit-line-clamp: 15;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.sub-product-container > p:nth-child(4) {
  font-size: 13px;
  font-weight: 400;
  margin-top: 8px !important;
}
.r-model {
  text-align: center;
  line-height: 2rem;
  justify-content: center;
  flex-wrap: wrap;
  margin: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.r-model p {
  font-weight: bold;
  margin-left: 8px !important;
  margin-right: 8px !important;
}
.form-label {
  display: block !important;
  font-size: 15px !important;
  font-weight: bold !important;
  color: black !important;
  padding-right: 20px !important;
}
.m-stepper {
  list-style: none;
  display: flex;
  align-items: center;
  margin-top: 2rem;
}

.m-stepper > ul {
  padding: 0;
  margin: 0;
  display: flex;
}

.m-stepper div {
  height: 1rem;
  width: 4rem;
  z-index: 100;
  color: red;
  border-top: 3px solid brown;
  margin-top: 1rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.m-stepper span {
  padding: 0.5rem 0.9rem;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid gray;
}
.m-stepper span:hover {
  background: blue;
  color: white;
}
.m-stepper p {
  color: gray;
  font-size: 15px;
  margin-top: 1rem !important;
  margin-bottom: 0 !important;
}
/* .m-container {
  list-style: none;
  margin: 0;
  background: #8080801f;
  border-radius: 5px;
  padding: 1rem !important;
}
.m-container p {
  font-size: 16px;
  margin-bottom: 0.5rem !important;
  margin-top:0.5rem !important;
} */
.img-chip {
  display: flex;
  border: 1px solid gainsboro;
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px;
  border-radius: 5px;
  color: gray;
  height: -moz-fit-content;
  height: fit-content;
  flex-direction: column;
}
.post-container-btn {
  padding: 5px 12px !important;
  font-size: 12px;
  background: #e9ebeff7 !important;
  display: flex !important;
  align-items: center !important;
  grid-gap: 4px !important;
  grid-gap: 4px !important;
  gap: 4px !important;
  text-overflow: ellipsis;
  word-break: break-all;
  overflow: hidden;
  border-radius: 5px;
  color: gray !important;
}
.button-svg {
  width: 20px;
  height: 20px;
}
.post-container-btns {
  margin-left: 19px;
  list-style: none;
  padding: 0;
  /* display: grid;
  grid-template-columns: repeat(auto-fit, minmax(6rem, 1fr)); */
  grid-gap: 1rem;
  gap: 1rem;
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
}
.post-container-btns a:hover {
  color: blue !important;
}
.post-container-btns p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* .post-container-btns > :last-child {
  grid-column: span 2;
} */

@media (max-width: 1400px) {
  .post-container-btns {
    align-items: center;
    justify-content: center;
  }
}

.pay-button:hover {
  padding: 9px 25px;
}

.lineUp {
  animation: 2s anim-lineUp ease-out infinite;
  font-weight: 600;
}
@keyframes anim-lineUp {
  0% {
    opacity: 0;
    transform: translateY(80%);
  }
  20% {
    opacity: 0;
    color: red;
  }
  50% {
    opacity: 1;
    transform: translateY(0%);
    color: rebeccapurple;
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
    color: brown;
  }
}
.directory-card-new {
  background: #dededf;
  display: flex;
  flex-direction: column;
  align-items: start;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.directory-card-new > section:nth-child(1) {
  width: 100%;
  height: 284px;
  overflow: hidden;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.directory-card-new img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  transition: all 0.5s;
}
.directory-card-new > section:nth-child(2) div {
  width: 100%;
  background: #dededf;
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
  margin-top: -2rem;
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.directory-card-new button {
  width: 100%;
  border-top-left-radius: 2rem !important;
  border-top-right-radius: 2rem !important;
  margin-top: 1rem;
  padding: 0.7rem;
  border: none;
  /* background: black; */
  font-weight: bold;
}
.directory-card-new img:hover {
  transform: scale(1.4) rotate(12deg);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.directory-mark {
  position: absolute;
  width: 40px;
  height: auto;
  right: -1px;
  color: #80808099;
  cursor: pointer;
  top: 4px;
  /* filter: drop-shadow(2px 4px 6px black); */
}
.directory-mark:hover {
  filter: opacity(0.7);
}
.text-area {
  border: 1px solid gray;
  border-radius: 5px;
  width: 100%;
  padding: 1rem;
}

.cardWrapper-2 {
  background: white;
  padding: 2rem;
  border-radius: 10px;
  box-sizing: border-box;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.elpsis-line {
  color: gray;
  font-size: 12px;
  text-transform: capitalize;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  word-break: break-all;
  height: 3rem;
}
.b-17 {
  border-radius: 17px !important;
}
.bd-container {
  width: 13rem;
  border-radius: 5px;
  border: 1px solid gainsboro;
  overflow: hidden;
  height: 13rem;
}
.bd-container > img {
  height: 100%;
  border-radius: 5px;
  object-fit: contain;
  width: 100%;
  background: #80808012;
}
.ring:hover {
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
}

.single-line {
  font-size: 16px;
  font-weight: bold;
  text-transform: capitalize;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  word-break: break-all;
}
.p-container {
  list-style: none;
  padding: 0;
  align-items: center;
}
.p-container > li:nth-child(1),
.p-container > li:nth-child(2) {
  min-width: 11rem;
}
.p-sub {
  color: gray;
  font-size: 13px;
  font-weight: 500;
  border-bottom: 1px solid gainsboro;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.event-ref-page {
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: white;
}
.event-ref-page > header {
  width: auto;
  padding: 1rem;
}
.event-ref-page img {
  height: 4rem;
  width: auto;
}
.event-ref-page section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background: url(https://corporate.yuukke.com/bcav2/auth/image/4723eb0e-4948-4096-911d-464d3e75778d)
      center / cover no-repeat fixed,
    #28254c5e;
  color: black;
  text-align: center;
  width: -webkit-fill-available;
  /* min-height: 23rem; */
  width: 100%;
}
.meeting-box {
  border-top: 3px solid brown;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 1rem;
  grid-gap: 1rem;
  gap: 1rem;
  margin: 2rem 1rem;
}
.meeting-box-btn {
  border: 1px solid brown;
  border-radius: 5px;
  padding: 0.4rem;
  font-size: 10px;
  font-weight: bold;
  display: block;
  color: brown;
  cursor: pointer;
}
.btn-svg {
  color: brown;
  margin-left: 10px;
  width: 15px;
  height: auto;
}
.meeting-copy {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  border: 1px solid gray;
  border-radius: 5px;
  padding: 0.4rem;
  color: blue;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
}
.meeting-copy > p:nth-child(1) {
  overflow: hidden;
  text-wrap: nowrap;
  max-width: 18rem;
  text-overflow: ellipsis;
  margin-left: 12px !important;
}
.f-5 {
  font-weight: 500 !important;
}
/* .meeting-btn {
  margin-right: 15px;
  position: absolute;
  right: 0;
  top: 38%;
  background: white;
  color: black !important;
  font-weight: 700;
  border: 1px solid red;
} */
.btn2 {
  padding: 8px 18px;
  border: 1px solid #a00030;
  border-radius: 19px;
  background: #a0000014;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  width: -moz-fit-content;
  width: fit-content;
}
.btn2:hover {
  outline: 2px solid brown;
}
.community-v2-card {
  height: 6.5rem;
  border: 1px solid gainsboro;
  border-radius: 5px;
  overflow: hidden;
  line-height: 21px;
}
.text-overflow-v2 {
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.community-v2-card {
  height: 6.5rem;
  border: 1px solid gainsboro;
  border-radius: 5px;
  overflow: hidden;
  line-height: 21px;
}
.text-overflow-v2 {
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.load-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  font-weight: 700;
  text-transform: capitalize;
  font-size: 14px !important;
}

.activity-badge-container {
  margin-top: 5px;
}

.activity-badge-parent {
  display: flex;
  background-color: aliceblue;
  border-radius: 15px;
  width: 11rem;
  padding: 5px;
}

.label-v2 {
  font-size: 15px !important;
  color: black !important;
  font-weight: 500 !important;
  margin-bottom: 5px !important;
}
.heading-h4-v2 {
  font-size: 1.5rem;
  font-weight: 700;
}
.l-select > div{
margin-top:0 !important;
}
.mw-15{
  min-width:15rem;
}
.w-fit{
  width: -moz-fit-content;
  width: fit-content;
}
 
/* ============= 992px to 1200px ============= */

@media (min-width: 992px) and (max-width: 1200px) {
  .recentSearchToolbar {
    width: 96.5%;
  }
}

/* =============  992 to 1240 ============= */

@media (min-width: 992px) and (max-width: 1240px) {
  /* =========== CALL SCREEN ========== */
  .UCCHeader {
    padding: 0 15px;
  }
  .UCCScheduleTiming {
    padding: 0 15px;
  }
  p.UCCUserName {
    padding: 0 15px 15px;
    font-size: 12px;
    min-height: 40px;
  }
  .FACBtnWrapper button {
    font-size: 10px !important;
  }
  p.UCCSessionName {
    font-size: 16px;
  }
}

/* ============= 0 to 992 ============= */

@media (min-width: 0px) and (max-width: 992px) {
  /* =========== TAB MAIN ========= */
  .meeting-box {
    flex-wrap: wrap;
  }
  .meeting-copy {
    flex-wrap: wrap;
    justify-content: center;
  }
  .mentorCard {
    background: #fff;
    width: 18em !important;
    border-radius: 0.6em;
    margin: 2.2em;
    overflow: hidden;
    cursor: pointer;
    box-shadow: 0 13px 27px -5px hsl(240deg 30% 28% / 25%),
      0 8px 16px -8px hsl(0deg 0% 0% / 30%),
      0 -6px 16px -6px hsl(0deg 0% 0% / 3%);
    transition: all ease 200ms;
  }

  .postCardImage {
    /* height: 18rem; */
  }

  .postCardImageSingle {
    height: auto;
  }

  .upcomingCardWrapper {
    border: 1px solid #f7f7f7;
    background: #ffffff;
    border-radius: 4px;
    padding: 16px 16px 16px;
    cursor: pointer;
    width: auto;
    margin-right: 20px;
    box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.05);
  }
  .listCardWrapperLeader {
    margin-bottom: 20px;
    width: 290px;
    height: 28rem;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .listCardWrapperLeaderHeader {
    margin-bottom: 20px;
    width: 290px;
    height: 35rem;
  }
  .tabWrapper .MuiTab-textColorPrimary {
    margin-right: 30px;
  }
  .askQuestions {
    padding: 15px 25px 18px 0px !important;
  }
  .tabWrapper .MuiTabs-root {
    padding: 15px 0px 18px 25px;
  }
  /* ============ ANSWER SCREEN ========== */
  .QCQuestionInfoBanner {
    /* display: block; */
    padding: 7px 18px;
  }
  .QCTagsPostedOn {
    display: block;
  }
  .QCQuestion {
    font-size: 18px;
  }
  .answerCountUserImgWrapper {
    display: block;
  }
  .answeredUserImg div:nth-child(1) {
    margin: auto;
  }
  .ansCountWrapper {
    margin-bottom: 10px;
  }
  .answersWrapper .answeCountArrowGrid {
    text-align: center !important;
  }
  /* =========== CALL SCREEN ========== */
  .UCCHeader {
    padding: 0 15px;
  }
  .UCCScheduleTiming {
    padding: 0 15px;
  }
  p.UCCUserName {
    padding: 0 15px 15px;
  }
  .FACBtnWrapper button {
    font-size: 10px !important;
  }
}

@media (min-width: 600px) and (max-width: 800px) {
  .navMenuInnerGap {
    margin-left: 60px !important;
  }
}

/* ============= 0 to 768 ============= */

@media (min-width: 0) and (max-width: 768px) {
  /* ========= DEFAULT ======== */
  .carouselv2-container {
    width: 100%;
  }
  .prevv2 {
    left: 0rem !important;
  }
  .nextv2 {
    right: 0rem !important;
  }
  .s-min-50 {
    min-width: 100% !important;
  }
  .cpt-0 {
    padding-top: 0 !important;
  }
  .flex-col-reverse {
    flex-direction: column-reverse;
  }
  .enquiry-wrap {
    flex-wrap: wrap;
  }
  .service-img-grid {
    grid-gap: 0 !important;
    gap: 0 !important;
  }
  .service-img-grid {
    flex-wrap: wrap;
  }
  .s-heading {
    font-size: 2rem !important;
  }
  .service-otp > input {
    border: 1px solid black;
    color: black;
    width: 2rem !important;
    padding: 0;
    height: 2rem;
  }
  .service-wis {
    flex-direction: column;
    padding: 0;
  }
  .service-info-wrap {
    flex-wrap: wrap;
  }
  .booking-grid {
    grid-template-columns: repeat(auto-fill, minmax(22rem, 1fr)) !important;
  }
  .cal-grid {
    grid-column: 1fr;
  }
  .calendar-image {
    margin-left: 0rem !important;
  }
  .cal-res-1 {
    padding-left: 0rem;
  }
  .login-option-wrap {
    padding: 11px;
  }
  .login-options-opt {
    width: 100%;
    padding: 39px 12px !important;
    margin-top: 1rem !important;
  }

  .login-options-btn {
    padding: 8px 14px !important;
    align-items: center !important;
    text-align: center;
    justify-content: center;
    font-size: 13px;
  }

  .headerDynamicLogo {
    height: 57px;
    width: 198px;
  }
  .nav-menu-new > *:nth-child(2) {
    margin-top: 0rem;
    padding: 1rem;
  }
  .nav-menu-new {
    flex-direction: column;
  }
  .selfAssessment-form {
    background: #f8f9fa;
    padding: 5px;
    border-radius: 5px;
  }
  .kyc-background {
    width: auto;
  }
  .article-wrap {
    margin-top: 4rem;
    margin-left: 0;
    overflow-wrap: break-word;
  }
  .grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 1rem;
    gap: 1rem;
  }

  .workspace-button {
    position: absolute;
    right: 27px;
    top: 115px;
    z-index: 100;
  }
  .dropdownshareCard {
    left: inherit;
  }
  .req-un-active-menu {
    width: 7.3rem;
    border-radius: 0px !important;
    background: white;
    color: black;
    border-color: gray;
  }
  .req-active-menu {
    width: 7.3rem;
    border-radius: 0px !important;
    background: #d3d3d378;
    color: #a00030;
    border-color: gray;
  }

  .videopostcontainer .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0px;
    right: 0;
    height: 100%;
    width: 100%;
    /* opacity: 0; */
    transition: 0.3s ease;
    background-color: transparent;
    opacity: 1;
  }
  .headerDynamicLogo-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: 50px;
    width: auto;
    padding: 14px;
  }
  .unAccess-page {
    margin-top: 90px;
    margin-left: 0;
  }
  .access-page {
    margin-top: 90px;
    margin-left: 0;
  }
  .option-profile {
    border: 0.1rem solid gray;
    padding: 8px;
    border-radius: 9px;
    margin-left: 1rem;
  }
  .option-profile1 {
    border: 1px solid gray;
    padding: 7px 7px;
    border-radius: 7px;
    margin-left: 1rem;
  }
  .workspace-card {
    margin-right: 0rem;
    margin-top: 6rem;
    display: flex;
    flex-wrap: wrap;
    padding: 25px;
    background: white;
    border-radius: 10px;
    box-shadow: rgb(50 50 93 / 25%) 0px 13px 27px -5px,
      rgb(0 0 0 / 30%) 0px 8px 16px -8px;
  }
  .adb-card {
    display: block;
    flex-wrap: wrap;
  }
  .img-grid-chat {
    width: 100%;
    height: 400px;
    background-size: 100% 100%;
    opacity: 0.9;
    box-shadow: 0px -5px 18px 0px rgb(0 0 0 / 38%);
    margin-bottom: 10px;
  }
  .main__chatcontent {
    flex-grow: 1;
    padding: 10px;
    max-width: 100%;
    border: 1px solid #ebe7fb;
    border-width: 1px;
    border-radius: 19px;
  }
  .containerSlide {
    /* padding: 37px; */
    padding: 4px 34px 6px 34px;
    height: 19rem;
    overflow: scroll;
    overflow-x: hidden;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;
    border: 1px solid #dededf;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 4px;
  }
  .tag-List {
    padding: 6px;
    color: gray;
    font-size: 14px !important;
    height: 6.7rem !important;
    overflow: scroll;
    overflow-x: hidden;
    text-align: center;
  }
  .forum-tags-badges {
    display: contents !important;
    overflow: hidden !important;
  }
  .forum-tags-badges:hover {
    display: flex;
    flex-direction: unset !important;
    overflow: visible;
    overflow: initial;
  }
  p.yourAnswerClear {
    font-size: 18px;
    color: blue;
    font-weight: 500;
    margin: 37px 0 5px !important;
    cursor: pointer;
  }
  .QCFooter {
    padding: 5px 0px 0px 12px !important;
  }
  .QCQuestionInfoBanner p {
    margin-right: 7px !important;
  }
  .mentorCard {
    background: #fff;
    width: 20em !important;
    border-radius: 0.6em;
    margin: 2.2em;
    overflow: hidden;
    cursor: pointer;
    box-shadow: 0 13px 27px -5px hsl(240deg 30% 28% / 25%),
      0 8px 16px -8px hsl(0deg 0% 0% / 30%),
      0 -6px 16px -6px hsl(0deg 0% 0% / 3%);
    transition: all ease 200ms;
  }
  h3.listHomeTitle {
    font-size: 21px;
    letter-spacing: 0px;
    color: #333333;
    font-weight: bold;
    padding: 10px 5px 5px 0px;
  }
  .postCardImg-me-2 {
    margin-right: 0.5rem !important;
    /* margin-bottom: 3rem !important; */
    /* margin-bottom: 1rem; */
  }
  .groupCard-nav {
    display: inline-flex !important;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
  .groupCard {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.09);
    border-radius: 0.4rem;
    padding: 2px;
  }
  .groupPost-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.09);
    border-radius: 0.4rem;
    /* width: 21rem  !important; */
    padding: 2px;
  }
  .JRWrapper {
    padding: 10px 12px 10px;
    /* width: 21rem  !important; */
    padding: 2px;
    background: white !important;
  }
  .about-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.09);
    border-radius: 0.4rem;
    /* width: 21rem; */
  }
  .kycContainerInside {
    position: absolute;
    margin-top: 52%;
    margin-left: 14%;
    justify-content: center !important;
    align-items: center;
    background: #ffffff;
    box-shadow: 5px 5px 6px #0000000d;
    border: 1px solid #dededfbf;
    padding: 44px 35px 35px;
    border-radius: 10px;
  }
  .kycContainerInsideLoading {
    position: absolute;
    margin-top: 52%;
    margin-left: 35%;
    justify-content: center !important;
    align-items: center;
    background: #ffffff;
    box-shadow: 5px 5px 6px #0000000d;
    border: 1px solid #dededfbf;
    padding: 44px 35px 35px;
    border-radius: 10px;
    width: 50%;
  }
  .naviGationPadding {
    padding: 15px 0px 7px 3px;
    position: "relative";
    background: "#f5f5f5";
  }
  .navMenuInnerGap {
    margin-left: 1px;
  }
  .navMenuInnerGapNotHome {
    margin-left: 1px;
  }
  .notificationWrapper {
    position: absolute;
    top: 95px !important;
    z-index: 2000 !important;
    width: 292px !important;
    max-height: 520px;
    right: 12px !important;
    background: #fff;
    box-shadow: 0px 1px 1px #00000029;
    border-radius: 4px;
  }
  .profileselectCard {
    background: #ffffff;
    border-radius: 10px;
    margin-top: 10px;
    height: auto;
    width: auto;
    align-items: center;
    padding: 20px 15px;
    margin-bottom: 6px;
    box-shadow: 0px 1px 3px #00000029;
    border: 1px solid #f7f7f7;
    /* border-radius: 3px; */
  }
  .BACDuration {
    border: 1px solid #dededf;
    border-radius: 12px;
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 9px;
    font-weight: 500;
    color: black;
    cursor: pointer;
    margin-right: 10px;
    padding: 6px;
  }
  .profileselectCardMobile {
    background: #ffffff;
    /* border-radius: 10px; */
    margin-top: 10px;
    height: 600px;
    width: auto;
    align-items: center;
    padding: 20px 15px;
    margin-bottom: 6px;
    box-shadow: 0px 1px 3px #00000029;
    border: 1px solid #f7f7f7;
    border-radius: 3px;
    overflow-y: scroll;
  }
  .profileselectCardEducation {
    background: #ffffff;
    /* border-radius: 10px; */
    margin-top: 10px;
    height: 600px;
    width: auto;
    align-items: center;
    padding: 20px 15px;
    margin-bottom: 6px;
    box-shadow: 0px 1px 3px #00000029;
    border: 1px solid #f7f7f7;
    border-radius: 3px;
  }
  .profileselectCardExpMobile {
    background: #ffffff;
    /* border-radius: 10px; */
    margin-top: 10px;
    height: 700px;
    width: auto;
    align-items: center;
    padding: 20px 15px;
    margin-bottom: 6px;
    box-shadow: 0px 1px 3px #00000029;
    border: 1px solid #f7f7f7;
    border-radius: 3px;
    overflow-y: scroll;
  }
  .valueselectCard {
    background: #ffffff;
    /* border-radius: 10px; */
    margin-top: 10px;
    height: 800px;
    width: auto;
    align-items: center;
    padding: 20px 15px;
    margin-bottom: 6px;
    box-shadow: 0px 1px 3px #00000029;
    border: 1px solid #f7f7f7;
    border-radius: 3px;
  }
  .profileselectCardFirstTime {
    background: #ffffff;
    /* border-radius: 10px; */
    margin-top: 10px;
    height: auto;
    width: auto;
    align-items: center;
    padding: 20px 15px;
    margin-bottom: 6px;
    box-shadow: 0px 1px 3px #00000029;
    border: 1px solid #f7f7f7;
    border-radius: 3px;
  }

  .professionCard {
    background: #ffffff 0% 0% no-repeat padding-box;
    padding: 20px 15px;
    margin: 6px;
    box-shadow: 0px 1px 3px #00000029;
    border: 1px solid #f7f7f7;
    border-radius: 2px;
    height: auto;
    /* padding: 22px; */
  }
  .commentViewAll {
    /* float:"right" !important; */
    cursor: pointer !important;
    font-size: 12px;
    margin-right: -12px !important;
  }
  .commentInput input {
    width: 142% !important;
    margin: 0;
    margin-left: -18px !important;
    background: #f4f2f2;
    border-radius: 4px !important;
    border: none;
    height: 56px;
    padding: 0px 0px 1px 7px !important;
    font-size: 12px !important;
  }

  .educationContainerEdit {
    background: #ffffff 0% 0% no-repeat padding-box;
    /* padding: 22px; */

    padding: 20px 15px;
    margin: 6px;
    box-shadow: 0px 1px 3px #00000029;
    border: 1px solid #f7f7f7;
    border-radius: 2px;
    height: auto;
  }
  .player {
    /*width: auto !important;*/
    width: auto !important;
    height: 250px !important;
    max-height: 100% !important;
  }
  .dBlockMblTab {
    display: block;
  }
  .navigationWrapper {
    padding: 0;
  }
  .MuiDialog-root {
    width: 100% !important;
  }
  /* ================ HOME > PROFILE  ============== */
  .profilePictureEditProfile {
    padding: 0 25px;
    display: block;
  }
  .profilePictureFriend {
    padding: 0 25px;
    display: block;
  }
  .profieBannerWrapper {
    height: 380px;
  }
  .profieBannerWrapper > img {
    height: 380px;
  }
  .friendprofieBannerWrapper {
    height: 380px;
  }
  .friendprofieBannerWrapper > img {
    height: 380px;
  }
  .profilePictureEditProfile > div:nth-child(1) {
    margin: auto;
    top: 15px;
    position: relative;
  }
  .profilePictureFriend > div:nth-child(1) {
    margin: auto;
    top: 15px;
    position: relative;
  }
  .editProfileButton button {
    margin: auto;
    margin-top: 40px;
  }
  .userDetailsWrapper {
    padding: 15px 25px;
  }
  /* ================= ANSWER SCREEN =========== */
  .answersWrapper {
    padding: 20px 25px;
  }
  .QCFooter {
    display: block;
  }
  .shareFollowBtn {
    margin-bottom: 15px;
  }
  .ACFooter {
    display: block;
  }
  /* ============ COMMUNITY > ASK QUESTIONS ===========*/
  .askQuestionWrapper {
    /*padding: 25px;*/
    padding: 5px;
  }
  .askQuestions {
    font-size: 12px;
  }
  /* ============= PROFILE =========== */
  .profileInfoWrapper {
    padding: 10px;
  }
  .settingsCheckBox {
    margin-top: 5px !important;
    width: 25px !important;
  }
  .settingsText {
    padding-left: 5px;
  }
  .pageWrapper {
    padding: 20px 15px;
  }

  .pageWrapperProfileSetup {
    padding: 20px 15px;
    background: white !important;
  }
  .contactpageWrapper {
    padding: 20px 15px;
  }

  /* ============= CHAT ============= */
  .messageHeaderBackBtn {
    margin-right: 10px;
    cursor: pointer;
  }
  .messageHeaderBackBtn svg {
    font-size: 21px;
  }
  .messageTyping {
    max-width: 50%;
  }
  .videoArticleWrapper {
    padding: 0px;
  }
  .feedbackBox {
    width: 100%;
  }
}

/* ============ 0 to 595 ============ */

@media (min-width: 0) and (max-width: 595px) {
  .headerUserName {
    display: none;
    margin-left: 5px;
    margin-right: 5px;
    font-size: 12px;
  }
  /* ========= DEFAULT ======== */
  .dBlockMbl {
    display: block;
  }
  /* =========== HEADER =========== */
  .headerUserProfile {
    margin-left: 0px;
  }
  .notifictionMainIconWrapper {
    margin-left: 5px !important;
  }
  .newMessageIconWrapper {
    /* display: block; */
    width: 32px;
  }
  .newMessageIconWrapper .headerIcon p {
    background: #1ab4de;
  }
  .logoDark img {
    width: 100px;
    height: 50px;
    object-fit: contain;
  }
  /* ================ NAVGATION ============= */
  .peopleMainWrapper {
    padding: 25px;
  }
  .navigationWrapper {
    display: contents;
  }
  .navigationWrapper a {
    margin-bottom: 10px;
  }
  .navCalls a {
    float: inherit !important;
    /* margin-right: 170px !important; */
  }
  /*  ============= SEARCH AUTOCOMPLELTE ============= */
  .searchWrapper {
    padding: 0;
  }
  /* =================== HOME > AFTER SERACH =========== */
  .SCardWrapper div:nth-child(1) {
    margin: auto;
  }
  .SCardWrapper {
    text-align: center;
    padding: 0 0 20px;
  }
  p.SCmutualFriend {
    margin-bottom: 15px;
  }
  .SAWrapper {
    margin-top: 10px;
  }
  /* =================== HOME > SEARCH =========== */
  .peopleCarouselWrapper {
    padding: 0;
  }
  .PIWrapper {
    padding: 0;
  }
  /* ============== HOME > WALL ============== */
  h2.CCTitle {
    display: block;
    font-size: 14px;
  }
  h2.CCTitle .likedIcon {
    display: inline-flex;
  }
  .upvotesTitle {
    display: block;
    margin-right: 0;
  }
  .upvotesCount {
    margin-right: 0;
    width: 72px;
    margin-bottom: 15px;
  }
  .likedInfo {
    margin-top: 5px;
    margin-left: 0;
  }
  .askedTagInfoWrapper .askedonTagWrapper {
    margin-right: auto;
    margin-bottom: 24px;
    margin-left: 0;
  }
  .communityCardQuestion {
    display: block;
    background-color: #f5f5f5 0 0 no-repeat padding-box !important;
  }
  .communityCardUserImg {
    margin-right: 0;
  }
  .communityCardUserImg div:nth-child(1) {
    margin-bottom: 15px;
  }
  .upvotesQuestion p {
    font-size: 14px;
  }
  .wallMainWrapper {
    padding: 0px;
  }
  .likeCommentWrapper {
    padding: 16px 15px;
    display: block;
  }
  /* ============== TAB ========= */
  .askQuestionMain {
    display: flex;
  }
  .subMenu {
    display: block;
  }
  .askQuestions {
    padding: 15px 25px 15px 10px;
    text-align: left;
  }
  .tabWrapper .MuiTab-textColorPrimary {
    margin-right: 15px;
  }
  .tabWrapper .MuiTabs-root {
    padding: 15px 0px 18px 10px !important;
  }
  /* ============== SUBJECTS ========== */
  .toggleButtonWrapper {
    text-align: left;
  }
  .subjectsWrapper {
    /* padding: 30px 20px 60px; */
  }
  /*  In question view card -- by madhan start*/
  .questionCardWrapper {
    display: flex;
    padding-bottom: 10px !important;
    margin-right: 2px;
    border-bottom: 1px solid rgba(179, 179, 179, 0.25);
    justify-content: center;
    align-content: stretch;
    flex-direction: column;
    margin-left: 15px;
  }
  .questionCardWrapper > div:last-child {
    margin-left: 0px;
    width: 100%;
  }
  .questionCardWrapperBlue {
    display: flex;
    padding-bottom: 10px !important;
    margin-right: 2px;
    background: #f5fcff !important;
    justify-content: center;
    align-content: stretch;
    flex-direction: column;
    margin-left: 15px;
  }
  .questionCardWrapperBlue > div:last-child {
    margin-left: 0px;
    width: 100%;
    background: #f5fcff !important;
  }
  .questionCardWrapperGrey {
    display: flex;
    padding-bottom: 10px !important;
    margin-right: 2px;
    justify-content: center;
    align-content: stretch;
    flex-direction: column;
    margin-left: 15px;
    background: #ffffdd !important;
  }
  .questionCardWrapperGrey:hover .questionCardQuestion > p {
    color: #829397;
    background: #ffffdd !important;
  }

  .questionCardWrapperGrey > div:last-child {
    margin-left: 0px;
    width: 100%;
    background: #ffffdd !important;
  }

  .questionCardWrapperRed {
    display: flex;
    padding-bottom: 10px !important;
    margin-right: 2px;
    justify-content: center;
    align-content: stretch;
    flex-direction: column;
    margin-left: 15px;
    /* background: #ECEBEB  !important; */
  }
  .questionCardWrapperRed:hover .questionCardQuestion > p {
    color: #829397;
    /* background: #ECEBEB  !important; */
  }

  .questionCardWrapperRed > div:last-child {
    margin-left: 0px;
    width: 100%;
    /* background: #ECEBEB  !important; */
  }
  .postcardGeneral {
    float: left;
    background: #f4f2f2;
    border-radius: 22px;
    border: none;
    margin: 0;
    height: 24px;
    width: 75px;
    padding: 4px 10px 1px 16px;
    font-size: 10px;
  }
  .dropdownfirstTimeCardPost {
    background: #ffffff 0% 0% no-repeat padding-box;
    /* box-shadow: 5px 5px 25px #00000040; */
    border: 1px solid #dededebf;
    border-radius: 4px;
    width: 170px;
    height: auto;
    padding: 15px 14px 16px 17px;
    position: absolute;
    right: 1%;
    /* top: 37.1em; */
    cursor: pointer;
    z-index: 10;
    float: right;
  }
  .newQuestionCardVoteBcMobile {
    color: #1ab4de !important;
    font-size: 8px;
    margin-top: 25px;
    display: flex !important;
    font-weight: bold;
    margin-left: 6px;
  }
  .postcardGroup {
    background: #f4f2f2;
    border-radius: 22px;
    border: none;
    margin: 0;
    height: 25px;
    width: 68px;
    padding: 4px 9px 1px 9px;
    font-size: 10px;
    float: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    /* margin-top: -58px; */
  }
  .postcardGroupOverFlow {
    background: #f4f2f2;
    border-radius: 22px;
    border: none;
    margin: 0;
    height: 24px;
    padding: 4px 10px 1px 16px;
    font-size: 10px;
    float: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 68px;
  }
  .postcardPost {
    float: left;
    background: #f4f2f2;
    border-radius: 22px;
    border: none;
    margin: 0;
    height: 25px;
    width: 50px;
    padding: 4px 10px 1px 16px;
    font-size: 10px;
    /* margin-top: -58px; */
  }
  .friendsConnectiontextOverflow {
    width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .myRequestCardDetails {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 110px;
  }
  .questionCardDetailsWrapperRight {
    margin: 0 0px;
  }
  .qabox {
    padding: 0px;
    margin-bottom: 20px;
  }
  .friendsCardView {
    padding: 8px;
    box-sizing: content-box;
    width: 100%;
  }
  .callGrpImage {
    height: 60px;
    width: 60px;
  }
  /* .navigationWrapper .nav-link {
  margin-right: 17px !important;
    display: inline-block;
    vertical-align: middle;
    font-size: 15px;
} */
  .navigationWrapper .nav-link {
    margin-right: 20px !important;
    display: inline-block;
    vertical-align: middle;
    font-size: 15px !important;
  }
  .navigationIcon {
    display: block;
    text-align: center;
  }
  .badgesCardFooter {
    max-height: 600px !important;
    padding: 40px 20px;
    font-size: 14px;
    /* letter-spacing: 0px; */
    color: #001737;
    /* border-bottom: 1px solid #ddd; */
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
  }
  .widthincress {
    width: 310px !important;
  }
  .updatesCarousel {
    margin-bottom: 0px;
  }
  /* .screenTitle {
   display: none;
  } */
  .badgeslistTitle {
    padding: 15px 30px 20px;
    border-bottom: 1px solid #ddd;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .friendssearch {
    display: flex;
    flex-wrap: nowrap;
  }
  .moreIcon {
    display: none;
  }
  .LikeAllicons {
    display: none !important;
  }
  .titleLarge {
    display: none !important;
  }
  .courseTitle {
    display: none !important;
  }
  .askQuestions_incalls p {
    display: flex;
    cursor: pointer;
    flex-direction: column;
    margin-top: -10px !important;
  }
  .arrowBeforeGreen {
    /* width:0px;
  border-left: 16px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid green;   */
    cursor: pointer;
  }
  .arrowAfterRed {
    /* width:0px;
  border-left: 16px solid transparent;
  border-right: 15px solid transparent;
  border-top: 15px solid red;
  cursor: pointer; */
  }
  .navClassroom {
    float: inherit !important;
    margin-right: 10px !important;
  }
  .navCommunity {
    float: inherit !important;
    margin-right: 10px !important;
  }
  .navHome {
    float: inherit !important;
    margin-right: 17px;
    margin-left: 17px !important;
  }
  .likesMainWrapper {
    margin-bottom: 16px;
  }
  .showbottom {
    display: none;
  }
  .messageIconWrapper {
    min-width: 107px;
  }
  .viewsIconWrapper {
    min-width: 84px;
  }
  .votesview {
    display: flex;
    align-items: flex-end;
    margin-left: -18px;
  }
  .questionVotes {
    margin: 0px 20px;
    display: flex;
  }
  .askedonTagWrapper {
    width: auto;
  }
  .showcount {
    display: block;
  }
  .likesInfo {
    display: none;
  }
  .mobile-likesInfo {
    display: none !important;
  }
  .likesCount {
    display: none;
  }
  .tabs {
    background-color: #dfe2e6;
    margin-bottom: 18px;
  }

  .HidequestionViewLeftbar {
    display: none !important;
  }
  .QCQuestionInfoBanner p {
    margin-right: 7px !important;
  }
  .questionEditDelete {
    display: flex;
  }
  .QCHeader {
    padding: 8px 17px;
  }
  .QCFooter {
    padding: 0 11px 3px;
  }
  .QCQuestionDescribed {
    padding: 1px 15px 23px;
    font-size: 16px;
    color: #333333;
  }
  .answerCardWrapper {
    box-shadow: 5px 5px 6px #0000000d;
    border: 1px solid #dededfbf;
    border-radius: 4px;
    background: #fff;
    /* margin-bottom: 70px; */
  }
  .answerviewimg {
    display: block;
  }
  .answerviewimginview {
    display: none;
  }
  .QuestionFilteronMobile {
    display: block;
  }
  /* .subjectsWrapper {
  padding: 30px 4px 60px;
} */
  /*  In question view card -- by madhan end*/

  /* ============== NOTIFICATION ============== */
  /* .notificationWrapper {
    right: 55px;
  } */
  /* ================ ANSWER SCREEN ========== */
  .QCSeeMoreQuestionTag {
    display: block;
    margin-bottom: 10px;
  }
  p.QCSeeMoreText {
    margin-bottom: 5px !important;
  }
  .ACUserName {
    display: block;
  }
  .answersWrapper .QCAnswerQountGrid {
    max-width: 10.333333%;
    flex-basis: 10.333333%;
  }
  .answersWrapper .AnswerQuestionCardGrid {
    /* max-width: 89.666667%;
    flex-basis: 89.666667%; */
  }
  .arrowAfter {
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 15px solid #33333380;
    width: 25px;
  }
  .arrowBefore {
    width: 25px;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid #33333380;
  }
  .answersWrapper .answeCardGrid {
    padding-left: 8px !important;
  }
  .answeredUserImg div:nth-child(1) {
    width: 30px !important;
    height: 30px !important;
    position: relative;
  }
  .answersWrapper {
    padding: 20px 10px;
  }
  .answerVideo video {
    width: 100%;
  }
  /* ============== COMMUNITY > ASK QUESTION ==========*/
  .askAQuestionCardWrapper {
    padding: 18px 15px 35px;
    background: #ffffff;
  }
  .askQuestions {
    justify-content: flex-end;
  }
  /* =============== CALL SCREEN ================ */
  .callMainWrapper {
    padding: 25px;
  }
  .YFTitleWrapper,
  .YFCardWrapper {
    padding: 15px;
  }
  p.YFTitle {
    font-size: 12px;
  }
  p.YFBookGroupCall {
    font-size: 12px;
  }
  /* SEARCH COURSE CARD */
  .searchCourseCarouselWrapper {
    padding: 0 0px 50px;
  }
  .peopleCarouselWrapper {
    margin-bottom: 50px;
  }
  .upcomingCallCarouselWrapper {
    padding: 0px !important;
  }
  .SCRemoveFriendOutlined,
  .SCaddFriendFilled {
    margin-top: 15px;
  }
  .SCuserInfo {
    margin-top: 10px;
  }
  .SCmutualFriend {
    padding-top: 5px;
  }
  /* ============= CLASSROOM SCREEN > COURSES ============ */
  .courseToolbarWrapper {
    /*padding: 25px;*/
    padding: 0px;
    padding-bottom: 25px;
  }
  .coursesMainWrapper > h2 {
    padding: 25px;
  }
  .allCourses {
    padding: 25px;
  }
  .courseTitle {
    font-size: 21px;
  }
  /* =============== SQUARE SCREEN ================= */
  .postCardHeader {
    padding: 16px 15px 10px;
  }
  .postDescription {
    padding: 0 15px 20px;
  }
  .postCardFooter {
    padding: 16px 20px;
  }
  .writeCommentWrapper {
    padding: 16px 20px;
  }
}

/* =========== 0 to 399 ============ */

@media (min-width: 0) and (max-width: 399px) {
  .screenInnerGap {
    /* padding: 0px 0px 113px 2px !important; */
    background: #f5f5f5 0 0 no-repeat padding-box;
  }

  .screenInnerGapMyGroup {
    /* padding: 122px 0px 113px 200px; */
    /* padding: 0px 0px 113px 2px; */
    background: #f5f5f5 0 0 no-repeat padding-box;
  }

  /* ============= HOME > SEARCH ========== */
  .logoDark img {
    width: 100px;
    height: 50px;
    object-fit: contain;
  }
  .peopleInvitationCardWrapper {
    display: block;
    text-align: center;
  }
  .peopleInvitationCardWrapper > div:nth-child(1) {
    margin: auto;
  }
  .PICardAction button {
    margin: auto;
  }
  button.PIAcceptFilled {
    margin-bottom: 5px;
  }
  .PICardAction {
    margin-top: 15px;
  }
  .peopleCarouselWrapper {
    padding: 0 0 30px;
  }
  .PIWrapper {
    padding: 0;
  }
  /* ============ DROPDOWN ========= */
  .dropdonwCard {
    top: 85px;
  }
  /* ============ NOTIFICATION =========== */
  /* .notificationWrapper {
    right: 0;
    width: 100%;
    left: 0;
    top: 88px;
  } */
  .notifictionMainIconWrapper {
    position: inherit !important;
  }
  /* .notificationWrapper:after {
    margin-left: -50px;
  } */
  /* ============= ANSWER SCREEN =========== */
  .shareFollowBtn {
    display: block;
  }
  .shareFollowBtn > button:nth-child(1) {
    margin-bottom: 5px;
  }
  .answerVerfiedIcon {
    position: relative;
    left: -5px;
  }
  .answeredUserImg div:nth-child(1) {
    left: -6px;
  }
  .ansCountWrapper {
    position: relative;
    left: -5px;
  }
  p.ansCount {
    position: relative;
    left: 8px;
  }
  /* ============ CALL SCREEN ======== */
  .BACDuration {
    margin-bottom: 10px;
    font-size: 10px;
    margin-right: 2px;
  }
  /* =========== SQUARE > HOME =========== */
  .writeComment {
    display: block;
  }
  .commentInput {
    margin: 10px 0;
  }
}

/* ====================== LOGIN =============== */

@media (max-width: 768px) {
  .signUpLogo {
    width: 100%;
    margin-top: 20px;
  }
  .loginQuotes {
    font-size: 12px;
  }
  .questionCardDetailsWrapper {
    display: block;
  }
  .questionCardTagsWrapper .subjectTag {
    margin-bottom: 10px;
  }
  .questionFilterInputWrapper {
    margin-bottom: 10px;
  }
  .paginationWrapperRight {
    justify-content: end;
  }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
  /* For portrait layouts only */
  .signUpLogo {
    width: auto;
    margin: 0 10px 0 0;
  }
  /* For portrait layouts only */
  .questionFilterInputWrapper input {
    max-width: 100%;
  }
  .toggleButtonWrapper {
    text-align: left;
  }
}

.mobileMenu {
  display: none;
}

@media (max-width: 738px) {
  .card-body {
    padding: 0 !important;
  }
  /* For Mobile Menu */
  .desktopMenu {
    display: none;
  }
  /* .askQuestions {
    display: none !important;
  } */
  .askQuestions_incalls {
    display: block !important;
    height: 57px;
  }
  .menuIconWrapper {
    padding: 4px 0px 4px 0px;
    /* text-align: right; */
    display: inline-flex;
    /* flex-direction: row; */
    /* margin-left: 8px; */
  }
  .mobileMenu {
    display: block;
  }

  .searchCourseCardWrapper__content__hash {
    margin-top: 10px;
    height: auto;
    /* overflow: scroll; */
  }

  .searchCourseCardWrapper__content__hash span {
    color: #1ab4de;
    font-size: 13px;
    margin-right: 8px;
  }

  .questionViewLeftbar {
    display: block;
    left: -5 !important;
    padding-top: 20px !important;
    font-size: 10px;
  }
}
/* New mobile wrapper */
@media (min-width: 0px) and (max-width: 992px) {
  .new-desktop-wrapper {
    display: none;
  }
  .new-mobileMenu {
    display: flex;
  }
  .nav-menu-new {
    position: relative;
    display: flex;
    height: 100vh;
    padding: 0rem 0.1rem;
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }
  .header-menu-new {
    display: flex;
    align-items: center;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media (min-width: 768px) and (max-width: 1240px) {
  .service-sec-1 > div:nth-child(2) {
    width: 24rem !important;
  }
  .service-sec-1 h2 {
    font-size: 2rem !important;
  }
}

.modal-header {
  border: none;
}
.modal-title {
  font-size: 10px;
}
.inputRounded .MuiOutlinedInput-root {
  border-radius: 20px;
}

.postion-relative {
  position: relative;
}

.modal.left .modal-dialog,
.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 330px;
  height: 100%;
  transform: translate3d(0%, 0, 0);
}
@media (max-width: 768px) {
  .modal.left .modal-dialog,
  .modal.right .modal-dialog {
    position: fixed;
    margin: auto;
    width: 230px;
    height: 100%;
    transform: translate3d(0%, 0, 0);
  }
  .modal-content {
    border-radius: 12px !important;
    border: #eeeeee;
  }
}
.modal.left .modal-content,
.modal.right .modal-content {
  height: 100%;
  /* overflow-y: auto; */
}

.modal.left .modal-body,
.modal.right .modal-body {
  padding: 15px 15px 80px;
}

/*Left*/
.modal.left.fade .modal-dialog {
  left: -320px;
  transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.left.fade.in .modal-dialog {
  left: 0;
}

/*Right*/
.modal.right.fade .modal-dialog {
  right: 0px;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.in .modal-dialog {
  right: 0;
}

/* ----- MODAL STYLE ----- */
.modal-content {
  /* border-radius: 0 !important; */
  /* border: none; */
}

.modal-header {
  border-bottom-color: #eeeeee;
  background-color: #fafafa;
}

/* ================ WELCOME SCREEN ======== */
.greyBg {
  background: #f2f2f2;
}

.welcomeCard {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 5px 5px 10px #0000000d;
  border-radius: 4px;
  padding: 20px 25px;
  margin: 0 60px;
}

#welcome {
  padding-top: 5%;
}

.welcomeCard img {
  width: 380px;
  height: auto;
}

.welcomeDetails p {
  color: #000000cc;
  font-size: 16px;
  margin-bottom: 20px;
}

.welcomeDetails {
  margin-top: 10%;
}

b.welcomeBlueText {
  color: #60c2e7;
}

button.letsGoBtn {
  background: linear-gradient(to right, #ef5a3e 5%, #a00030 100%);
  border-radius: 4px;
  border: none;
  color: #fff;
  width: 125px;
  padding: 6px;
  font-family: "nunitoBold", sans-serif;
  cursor: pointer;
}
button.letsGoBtn:focus {
  outline: none;
}

/* =========== PROFILE PAGE ========= */
p.highlitedTabText {
  color: #b5b5b5;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 5px;
}

.profileTab {
  text-align: left;
}

.profileNumber p {
  color: #b5b5b5;
  font-size: 24px;
  font-weight: bold;
}

.profileNumber {
  margin-right: 15px;
  align-items: center;
  justify-content: center;
  display: flex;
  min-width: 30px;
}

p.tabDetailedDesc {
  color: #b5b5b5cc;
  font-size: 13px;
}

.ProfileTabNav .nav-item {
  background: #fff;
  border: 1px solid #e4e4e5;
}

.ProfileTabNav .nav-item {
  position: relative;
  border-right: 1px solid #e4e4e5 !important;
  border: none;
}
.showTabArrow:after,
.showTabArrow:before {
  left: 100%;
  top: 70%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.showTabArrow:after {
  border-color: rgba(34, 34, 34, 0);
  border-left-color: #ffffff;
  border-width: 15px;
  margin-top: -30px;
  z-index: 1;
}
.showTabArrow:before {
  border-color: rgba(228, 228, 229, 0);
  border-left-color: #dee2e6;
  border-width: 16px;
  margin-top: -31px;
  z-index: 1;
}

.ProfilePage {
  padding-top: 25px;
  padding-bottom: 25px;
}

a#about-you {
  padding-left: 30px;
}

a#learning {
  padding-left: 30px;
}

nav.ProfileTabNav {
  padding-bottom: 35px;
}

nav.ProfileTabNav .active p.highlitedTabText {
  color: #60c2e7;
  opacity: 1;
}

nav.ProfileTabNav .active .profileNumber p {
  color: #212121;
}

nav.ProfileTabNav .active p.tabDetailedDesc {
  color: #000000cc;
}
nav.ProfileTabNav .active p.highlitedTabText {
  color: #60c2e7;
  opacity: 1;
}

nav.ProfileTabNav .active .profileNumber p {
  color: #212121;
}

nav.ProfileTabNav .active p.tabDetailedDesc {
  color: #000000cc;
}
.profileTabContent:-ms-input-placeholder {
  font-family: "Lato", sans-serif;
  font-size: 13px;
}
.profileTabContent::placeholder {
  font-family: "Lato", sans-serif;
  font-size: 13px;
}
.profilePageTabContent {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 5px 5px 10px #0000000d;
  border-radius: 4px;
  padding: 25px;
}

.profileImg {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.profileDetailsForm label {
  display: block;
  font-family: "Lato", sans-serif;
  font-size: 13px;
  font-weight: bold;
  color: #333333de;
  margin-bottom: 5px;
}

.genderRadioButton label {
  display: inline;
  display: initial;
}

.profileFormInput input {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dededf;
  border-radius: 4px;
  margin-bottom: 15px;
  width: 260px;
  padding: 6px;
  font-size: 13px;
}
.profileFormInput input {
  border: 0px solid #dededf !important;
}
.genderDetails {
  margin-bottom: 15px;
}

.choosePhoto {
  align-items: center;
}

.choosePhotoImg img {
  width: 115px;
  height: auto;
}

.choosePhotoBtn button {
  background: #dfdfdf 0% 0% no-repeat padding-box;
  border-radius: 4px;
  border: none;
  width: 125px;
  padding: 6px;
  letter-spacing: 0px;
  color: #333333;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-weight: bold;
}

.choosePhotoImg {
  margin-right: 15px;
}

.profileSubmitBtn button {
  background: #f97516;
  border-radius: 4px;
  border: none;
  /* width: 154px; */
  color: #fff;
  padding: 7.5px;
  font-size: 14px;
  font-weight: bold;
  font-family: "Lato", sans-serif;
  letter-spacing: 0;
}
button {
  cursor: pointer;
}
.profileTabContent button:focus {
  outline: none;
}
.formRadioInput [type="radio"]:checked,
.formRadioInput [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.formRadioInput [type="radio"]:checked + label,
.formRadioInput [type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  line-height: 23px;
  display: inline-block;
  color: #666;
}
.formRadioInput [type="radio"]:checked + label:before,
.formRadioInput [type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 3px;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}
.formRadioInput [type="radio"]:checked + label:after,
.formRadioInput [type="radio"]:not(:checked) + label:after {
  content: "";
  width: 8px;
  height: 8px;
  background: #0c0c0c;
  position: absolute;
  top: 8px;
  left: 5px;
  border-radius: 100%;
  transition: all 0.2s ease;
}
.formRadioInput [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
.formRadioInput [type="radio"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}

.formRadioInput {
  margin-right: 10px;
}

/* ========= model ======== */
.uploadEmptyCard {
  border: 1px solid #707070;
  background: #e1e1e1;
  text-align: center;
}

.uploadEmptyCard img {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  border: 5px dashed #ffffff;
}

.uploadButton button {
  display: block;
  width: 175px;
  border: none;
  background: #dfdfdf 0% 0% no-repeat padding-box;
  border-radius: 4px;
  color: #333333;
  font-size: 14px;
  font-weight: bold;
  font-family: "Lato", sans-serif;
  padding: 7.5px;
  margin-bottom: 15px;
}

.uploadButton {
  margin-left: 35px;
}
.createPostModel button {
  margin-left: 0 !important;
}
h5#uploadModelLabel {
  font-size: 16px;
  font-weight: bold;
  color: #000000de;
}

div#uploadModel .modal-header {
  border-bottom: 1px solid #707070;
}

#uploadModel .modal-footer {
  border: navajowhite;
}

#uploadModel .btn-primary {
  background: linear-gradient(to right, #ef5a3e 5%, #a00030 100%);
  border: none;
  color: #ffffff;
  font-size: 16px;
  width: 120px;
  padding: 11.5px;
  font-weight: bold;
}

#uploadModel .btn-secondary {
  background: no-repeat;
  border: none;
  color: #333333;
  font-size: 14px;
  font-weight: bold;
}

.blurSection {
  filter: blur(8px);
}

/* ========== about section ======== */
.profileFormTextarea textarea {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dededf;
  border-radius: 4px;
  margin-bottom: 15px;
}

.aboutInputAddButton {
  position: relative;
}

.aboutInputAddButton i {
  position: absolute;
  left: 46%;
  top: 45%;
  color: #747576;
  cursor: pointer;
}

.individualBadge {
  display: flex;
  border: 1px solid #333333;
  border-radius: 100px;
  padding: 2px 10px;
  margin-right: 15px;
  align-items: center;
}

.individualBadge p {
  font-size: 13px;
  font-family: "Lato", sans-serif;
  margin-right: 10px;
}

.profileBadge {
  margin-bottom: 15px;
}

.individualBadge span {
  font-size: 14px;
  cursor: pointer;
}

.aboutSubmitBtn button {
  width: 120px !important;
}

/* ================ LEARNING SECTION ========= */
.learningSection .individualBadge p {
  margin: 0;
}
.learningSection .individualBadge {
  display: inline-block;
  margin-bottom: 5px;
}
p.dropTitle {
  font-size: 13px;
  color: #333333de;
  font-family: "Lato", sans-serif;
  font-weight: bold;
  text-align: center;
}

.dragDropCardContainer {
  margin-top: 25px;
  width: 220px;
}

.dragDropCard {
  width: 220px;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #b2b2b2;
  border-radius: 4px;
  background: #b2b2b205;
  margin: 5px 0;
}

.dragDropCard p.dragDropTxt {
  color: #000000;
  opacity: 0.25;
  font-size: 14px;
  font-weight: bold;
  width: 100px;
  text-align: center;
  font-family: "Lato", sans-serif;
}

.dragDropBottomIcons {
  text-align: center;
}

.dragDropBottomIcons img {
  width: 15%;
}
.learningBtn button {
  width: 106px;
  margin-top: 35px;
}

.dropBadgeArea {
  min-height: 150px;
}
.activeDragArea {
  display: inline-block;
  padding: 5px;
  text-align: center;
  overflow: hidden auto;
}

.profileNumber .fa {
  color: #21ba45;
  font-size: 30px;
}

.letsGoBtn img {
  width: 20px !important;
  margin-left: 5px;
}

/* ============= MY CLASSROOM ================= */
.classroomCard {
  box-shadow: 5px 5px 10px #0000000d;
  border-radius: 4px;
  background: #fff;
  padding: 10px;
  height: 570px;
  margin-bottom: 15px;
}

.classDetails h3 {
  font-size: 16px;
  margin-bottom: 2px;
}

.classDetails h2 {
  font-size: 18px;
}

.classDetails {
  position: relative;
  padding: 15px 10px 30px;
}

.classDetails i {
  position: absolute;
  left: 2%;
  top: 14%;
}
.classDetails h3,
.classDetails h2 {
  font-family: "nunitoRegular", sans-serif;
  font-weight: bold;
  margin-left: 8%;
  color: #000000de;
}

.classDetails p {
  letter-spacing: 0px;
  color: #545454;
  font-size: 12px;
}

.classImg img {
  width: 100px;
  height: 50px;
  object-fit: fill;
}

.classList h2 {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0px;
  font-family: "nunitoRegular", sans-serif;
}

.classList {
  margin-bottom: 30px;
  position: relative;
  width: 100%;
  cursor: pointer;
}

.classList p {
  font-family: "Lato", sans-serif;
  font-size: 12px;
}

.classList strong {
  font-size: 24px;
  color: #333333;
  font-family: "Bebas Neue", cursive;
  position: absolute;
  right: 2%;
  top: 21%;
}

.classListOpactiy {
  opacity: 0.5;
}

.activeClass {
  background: #e9e9e9;
  border-radius: 4px;
}

.classFeatureimg img {
  width: 100%;
  height: auto;
}

.classDetailed h1 {
  font-size: 18px;
  font-weight: bold;
  font-family: "nunitoRegular", sans-serif;
  margin-bottom: 0;
}

.classDetailed {
  padding: 25px;
}

.classDetailed p {
  font-size: 12px;
  color: #b3b3b3;
  font-weight: bold;
  font-family: "nunitoRegular", sans-serif;
  margin-bottom: 30px;
}

.takeQuizBtn {
  text-align: right;
  margin-top: 20px;
}

.takeQuizBtn button {
  background: #60c2e7 0% 0% no-repeat padding-box;
  border-radius: 4px;
  border: none;
  color: #fff;
  font-size: 14px;
  width: 130px;
  padding: 7.5px 0;
}

.classDetailed hr {
  margin: 20px 0;
  border: 2px solid #707070;
}

.flexReview {
  display: flex;
}

.flexReview p {
  margin-bottom: 0;
  margin-right: 12px;
}

.flexReview img {
  width: 20px;
  margin-right: 5px;
}

.shareClass p {
  margin-bottom: 0;
  margin-right: 25px;
  color: #333333;
  font-weight: bold;
  font-family: "Lato", sans-serif;
}

.shareClass {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.ClassRoomMainContainer {
  padding-bottom: 35px;
}
.flexClassList {
  display: flex;
  padding: 5px;
}

.classInfo {
  margin-left: 15px;
  margin-top: 5px;
}
#myclassRoom {
  padding-top: 3%;
}
/* ============= MY CLASSROOM DIALOGUE ============ */
.myClassroomModelContent {
  text-align: center;
  padding: 30px 0;
}

.myClassroomModelContent img {
  width: 60px;
}

.myClassroomModelContent p {
  color: #707070;
  font-size: 18px;
  font-family: "Lato", sans-serif;
  font-weight: bold;
  margin-bottom: 25px;
}

.myClassroomModelContent button {
  width: 170px;
  background: linear-gradient(to right, #ef5a3e 5%, #a00030 100%);
  border: none;
  padding: 10px 0;
}

/* =============== MY NETWORK SCREEN ================ */
.findFriendContainer {
  padding: 3% 0;
}

.searchFriend {
  margin-bottom: 25px;
}

.searchFriend h3 {
  color: #707070;
  font-size: 24px;
  font-weight: bold;
  font-family: "Lato", sans-serif;
  margin-bottom: 25px;
  text-align: center;
}

.searchFriendInput.input-group,
.findaMatchSearchInput.input-group {
  width: 250px;
  margin: auto;
  position: relative;
}
.searchFriend.findaMatchCard .searchFriendSelectBoxFlex {
  width: 85%;
}
.searchFriend .input-group input {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dededf;
  border-radius: 100px !important;
  font-size: 12px;
  padding: 7px 15px;
  height: auto !important;
}

.searchFriendInput i {
  position: absolute;
  right: 15px;
  top: 9px;
  color: #9a9a9a;
  font-size: 14px;
  cursor: pointer;
}

.searchFriendSelectBoxFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 75%;
  margin: auto;
  margin-bottom: 30px;
}

.searchFriendSelectBoxFlex label {
  width: 100%;
  color: #333333de;
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 5px;
  line-height: 1;
}

.searchFriendFormField {
  margin-right: 10px;
  width: 160px;
}

.searchFriendSelectBoxFlex select {
  border: 1px solid #dededf;
  border-radius: 4px !important;
  font-size: 12px;
}

.profilePhoto img {
  width: 100px;
  height: 100px;
  box-shadow: 0px 0px 5px #00000029;
  border: 3px solid #ffffff;
  border-radius: 50%;
  object-fit: cover;
}

.networkCard {
  text-align: center;
  border: 1px solid #dededf;
  border-radius: 4px;
  background: #fff;
  position: relative;
}

.coverPhoto {
  height: 100px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  border-radius: 4px 4px 0px 0px;
}

.profilePhoto {
  margin-top: -45px;
}

.networkDetails {
  padding: 20px 15px 0;
}

.networkDetails h3 {
  font-size: 18px;
  color: #333333;
  font-weight: bold;
  font-family: "Lato", sans-serif;
}

.networkDetails p {
  color: #b3b3b3;
  font-size: 12px;
  margin-bottom: 15px;
}

.connectBtn {
  padding: 20px 15px 15px;
}

.connectBtn button {
  border-radius: 4px;
  border: none;
  background: #60c2e7;
  color: #fff;
  font-size: 12px;
  padding: 5px 8px;
  font-weight: bold;
  font-family: "nunitoRegular", sans-serif;
}
.networkCardContainer .col-md-3 {
  margin-bottom: 30px;
}
.networkCardContainer {
  min-height: 500px;
}
.networkDetailsBtn button {
  display: block;
  margin: auto;
  font-size: 14px;
  border: none;
  padding: 7.5px 0;
  font-weight: bold;
  font-family: "Lato", sans-serif;
  cursor: pointer;
}

button.networkSkip {
  background: #dfdfdf;
  border-radius: 4px;
  color: #333333;
  width: 125px;
}

/* =============== FIND A MATCH SCREEN ============ */
.findaMatchCard {
  background: #fff 0 0 no-repeat padding-box;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 15px 10px;
  margin-bottom: 15px;
  min-height: 130px;
  /*min-height: 230px; */
}
span.filterTxt {
  margin: 0 !important;
}
.filterDownArrow {
  cursor: pointer;
}
.findaMatchSearchInput span i {
  margin-right: 5px;
}
.findaMatchSearchInput {
  width: 350px !important;
}

.findaMatchSearchInput i {
  position: inherit;
  top: 10px;
  right: 10px;
  color: #9a9a9a;
  font-size: 14px;
  cursor: pointer;
}

.findaMatchSearchInput span {
  font-size: 14px;
  color: #989898;
  text-decoration: underline;
  margin-top: 6px;
  margin-left: 10px;
}

.findaMatchSearchInput span i {
  font-size: 14px;
}

.findaMatchSearchInput input {
  width: 275px;
}

.findMatachHeading {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}
.navbar-brand {
  background: transparent;
  margin: 0 20px 0 0;
}
.findMatachHeading:before,
.findMatachHeading:after {
  background: #707070;
  height: 1px;
  content: "";
  flex: 1 1;
}

.findMatchNetworkCard {
  padding: 20px 0;
}

.findMatachHeading h3 {
  color: #333333de;
  font-size: 24px;
  font-weight: bold;
  font-family: "nunitoRegular", sans-serif;
  padding: 0 10px;
}
.yuukkeHeading h3 {
  color: #b3b3b3;
}
.yuukkeHeading:before,
.yuukkeHeading:after {
  background: #b3b3b3;
}
.detailedCards {
  width: 110%;
  margin: auto;
}

.detailedCards .col-md-3 {
  margin-bottom: 30px;
}

.findMatchCardBtn i {
  margin-right: 5px;
}
/* .findMatchCardBtn button {
      width: auto;
      padding: 5px 10px;
    } */
.findMatchCardBtn button {
  width: auto;
  padding: 5px 5px;
}
button.btnOutlined {
  background: none;
  color: #60c2e7;
  border: 1px solid #60c2e7;
  margin-left: 5px;
}

.findMatchNetworkCard .row {
  margin-bottom: 25px;
}

.detailedCards .networkDetails > p:nth-child(3) {
  margin-bottom: 0;
}
/*============= My YUUKKE =================*/

.MyYuukke {
  padding-top: 50px;
}
.smiley {
  text-align: center;
}
.smiley p {
  opacity: 0.6;
  font-weight: 700;
  font-size: 18px;
}
.img-wrapper img {
  max-width: 200px;
}
/* .card-wrapper {
  background: #fff;
  box-shadow: 5px 5px 10px #0000000d;
  border-radius: 4px;
  padding: 32px 40px;
  min-height: 273px;
  margin-bottom: 20px;
} */
.btn-peer {
  background: #60c2e7;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
}
.mypeercards .container {
  max-width: 1000px;
}
.btn-peer:hover {
  color: #fff;
  text-decoration: none;
}
.mypeercards {
  padding-top: 20px;
}
.img-wrapper a {
  position: absolute;
  bottom: 85px;
  left: 55px;
}

/*=============== Book Time ============*/
.book-time {
  padding: 50px 0px;
}
.book-time h4 {
  color: #707070;
  font-weight: bold;
  margin-bottom: 50px;
}
.bpc-img-wrapper {
  text-align: center;
}
.bpc-img-wrapper img {
  border-radius: 50%;
  box-shadow: 0px 0px 5px #00000029;
  border: 3px solid #ffffff;
}
.hash-tags {
  font-size: 13px;
}
.chat-btn {
  border: 1px solid #60c2e7;
  border-radius: 4px;
  padding: 10px 30px;
  color: #60c2e7;
  font-weight: bold;
  font-size: 14px;
  background: transparent;
}
.update-btn {
  background: #60c2e7 0% 0% no-repeat padding-box;
  border-radius: 4px;
  color: #fff;
  padding: 10px 30px;
  border: none;
  font-weight: bold;
}
.booking-profile-card {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d4d4d5;
  border-radius: 4px 4px 0px 0px;
  padding: 30px;
}
.right-col-wrap {
  padding: 30px;
}
.align-flex-end {
  align-items: flex-end;
}

/* ================ CALANDER =========== */
.appoinmentCalander {
  position: relative;
}
.appoinmentCalander .fc-header-toolbar {
  height: 0;
  margin: 0 !important;
}
.appoinmentCalander h2.fc-toolbar-title {
  display: none;
}
.appoinmentConfirmend {
  padding: 25px;
  text-align: center;
}

.appoinmentConfirmedContent img {
  width: 40px;
  margin-bottom: 10px;
}

p.appoinmentBoldTxt {
  font-size: 21px !important;
  font-weight: bold;
  color: #707070;
  margin-bottom: 25px !important;
}

.appoinmentConfirmedContent p {
  font-size: 18px;
  margin-bottom: 0;
}

.appoinmentBtnFlex i {
  margin-right: 5px;
}
a.fc-col-header-cell-cushion {
  font-size: 12px;
}

.fc-scroller {
  /* overflow: hidden !important; */
}

.fc-button-group .fc-next-button {
  right: -10%;
}

.fc-button-primary {
  /* background: none !important; */
  /* color: #333 !important; */
  border: none !important; /* width: 50px; */
}

.fc-button-primary .fc-icon {
  width: auto !important;
}

.fc-button-group .fc-prev-button {
  left: -10%;
}

.fc-button-group {
  position: inherit !important;
  z-index: 1;
}

.fc-view-harness {
  padding-bottom: 50% !important;
  z-index: 0;
  border-bottom: 1px solid #d4d4d5;
}

.fc .fc-timegrid-col.fc-day-today {
  background: none !important;
}

.fc-theme-standard td,
.fc-theme-standard th {
  border: 0px;
}

table.fc-scrollgrid.fc-scrollgrid-liquid {
  /* border: 0px; */
}

.fc-timeGridWeek-view thead .fc-scrollgrid-section td {
  border-top: 1px solid #d4d4d5 !important;
  border-bottom: 1px solid #d4d4d5 !important;
  padding: 5px 0;
}

.fc-button-group .fc-button {
  position: absolute !important;
  top: 2%;
  color: #5fc0e4 !important;
}
.avaliableText {
  color: #707070;
  text-align: center;
  font-size: 21px;
  font-family: "Lato", sans-serif;
}
.bookingCalanderContainer > .col-md-6 {
  padding-left: 15;
  padding-right: 0;
}
.row.bookingCalanderContainer {
  margin-top: 15px;
}
.seeMoreAvalibile {
  color: #5fc0e4;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  font-family: "Lato", sans-serif;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 0;
}
.hideOverflow {
  overflow: hidden auto !important;
}
.fc-button-group .fc-button:focus {
  box-shadow: none !important;
}
.fc .fc-scroller-harness-liquid {
  /* margin-top: 10px; */
}
.fc-timegrid-slots tr td:nth-child(odd) {
  /* display: none; */
}

.fc-scrollgrid-sync-inner {
  width: 50px;
  margin: auto;
}
.fc-event-main {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
}
.fc-v-event {
  border: none !important;
}
.available,
.unavailable {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2ecc71;
  padding: 0;
  border-radius: 3px;
  color: #fff;
}
.available {
  cursor: pointer;
}
.unavailable {
  background-color: #5fc0e4 !important;
  color: #333333 !important;
}
.fc-media-screen .fc-timegrid-event-harness {
  height: 26px;
}
/* ======== Book Time ============ */
#booktime {
  padding: 50px 0px;
}
#booktime h4 {
  color: #707070;
  font-weight: bold;
  margin-bottom: 50px;
}
.bpc-img-wrapper {
  text-align: center;
}
.bpc-img-wrapper img {
  border-radius: 50%;
  box-shadow: 0px 0px 5px #00000029;
  border: 3px solid #ffffff;
}
.hash-tags {
  font-size: 13px;
}
.chat-btn {
  border: 1px solid #60c2e7;
  border-radius: 4px;
  padding: 10px 30px;
  color: #60c2e7;
  font-weight: bold;
  font-size: 14px;
  background: transparent;
}
.update-btn {
  background: #60c2e7 0% 0% no-repeat padding-box;
  border-radius: 4px;
  color: #fff;
  padding: 10px 30px;
  border: none;
  font-weight: bold;
}
.booking-profile-card {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d4d4d5;
  border-radius: 4px 4px 0px 0px;
  padding: 20px;
}
.right-col-wrap {
  padding: 20px;
}
.align-flex-end {
  align-items: flex-end;
}
.profile-details-wrap p {
  margin: 0;
}
/* ======== Book Time ============ */
#sendRequestModal .modal-body {
  padding: 0;
}
.sr-title h3 {
  font-size: 18px;
  color: #333;
  text-transform: uppercase;
  border-bottom: 1px solid #707070;
  padding: 1rem;
  font-weight: bold;
}
.srModalContent {
  padding: 20px 40px 40px 40px;
}
.subjectDropdown {
  max-width: 180px;
}
.descriptionTextArea {
  max-width: 500px;
}
.datePicker {
  max-width: 170px;
  display: inline-block;
  margin-right: 20px;
}
.timePicker {
  max-width: 100px;
  display: inline-block;
}
.srCancel {
  background: #dfdfdf;
  color: #333;
  border: none;
  font-size: 14px;
  padding: 7.5px 20px;
  border-radius: 4px;
  font-weight: bold;
}
.srSend {
  background: #60c2e7;
  border: none;
  padding: 7.5px 20px;
  font-size: 14px;
  border-radius: 4px;
  font-weight: bold;
  color: #fff;
}
#sendRequestModal label {
  font-size: 13px;
  color: #333333de;
}

/* ============= COMMUNITY SCREEN =========== */
/* .communityCard {
      box-shadow: 5px 5px 6px #0000000d;
      border: 1px solid #dededfbf;
      border-radius: 4px;
      background: #fff;
      margin-bottom: 16px;
    } */
.communityCard {
  box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(222, 222, 223, 0.75);
  border-radius: 10px;
  background: #fff;
  margin-bottom: 16px;
  padding: 0px 20px 0px 10px;
}

.userImg img {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  box-shadow: 0px 0px 5px #00000029;
  border: 3px solid #ffffff;
}
.userImgPost img {
  float: left !important;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  box-shadow: 0px 0px 5px #00000029;
  border: 3px solid #ffffff;
}

.newPostUploadFileTypes {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding-right: 15px;
}

.newPostUpload {
  display: flex;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
}
.newPostUpload.newPostUploadFiles {
  margin-right: 0;
}
.newPostSearchField input {
  width: 100%;
  border-radius: 50px;
  border: 0;
  background: rgb(179, 179, 179, 0.25);
  font-size: 14px;
  padding: 16px 25px;
}

.newPostUpload p {
  margin-bottom: 0;
  font-size: 16px;
  margin-left: 5px;
  letter-spacing: 0.32px;
  color: #333333;
  font-weight: bold;
}

.newPostUpload svg {
  font-size: 24px;
  color: #333333;
}
/* .newPostSearchField {
      padding-left: 15px;
    } */
.padding-15 {
  padding: 15px;
}
.communityRootContianer {
  padding: 50px 15px;
}
.flex-center {
  align-items: center;
}
.newPostUploadVideo svg {
  font-size: 30px;
}
.flexSpaceBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.postCardHeaderInfo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.postInfo p {
  margin-bottom: 0;
}

.postUserName {
  color: #333333;
  font-weight: bold;
  font-size: 16px;
}

.postUploadTie {
  color: #b3b3b3;
  font-size: 14px;
}

.postInfo {
  margin-left: 16px;
}

.postCardHeader {
  padding: 15px 25px 20px;
}

.postDescription {
  padding: 0 25px;
}

.postDescription p {
  color: #000000;
  font-size: 16px;
  margin-bottom: 15px;
  letter-spacing: 0.32px;
}

/* .postCardBody img,
    .postCardBody video {
      width: 100%;
      height: 500px;
      object-fit: cover;
    } */

.likeComment p {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: bold;
}

.likeComment {
  display: flex;
}
.invitationCardBtn {
  text-align: center;
}
.likeComment div {
  display: flex;
  cursor: pointer;
}

.postLike {
  margin-right: 30px;
  cursor: pointer;
}

.likeComment svg {
  margin-right: 10px;
  font-size: 24px;
}

.likeComment p,
.likeComment svg {
  color: #b3b3b3;
}
@media (max-width: 1025px) {
  .likesInfo {
    display: none !important;
  }
}
@media (min-width: 1025px) {
  .mobile-likesInfo {
    display: none !important;
  }
}

.mobile-likesInfo {
  display: flex;
  align-items: flex-end;
  font-size: 10px;
}

.likesInfo {
  display: flex;
  align-items: flex-end;
}

.likesInfo p {
  margin-bottom: 0;
  color: #b3b3b3;
  font-size: 14px;
  margin-right: 10px;
}

.postCardFooter {
  padding: 25px;
  position: relative;
}

.likesInfo img {
  width: 63px;
  height: 25px;
  margin-right: 10px;
}

p.commentInfo {
  margin-right: 0;
  cursor: pointer;
}
a.commentInfo {
  margin-right: 0;
  cursor: pointer;
}
p.likesDot {
  position: relative;
  top: -3px;
}

.linkCard {
  display: flex;
  background-color: #fff;
}
.menuCard {
  display: flex !important;
  margin-left: 8px;
}
.menuItem {
  margin-top: 6px;
}
.linkCard img {
  width: 200px;
  height: 200px;
  border-radius: 4px 0px 0px 4px;
}
.linkCard {
  display: flex;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  margin: 15px;
}
p.postEventName {
  letter-spacing: 0px;
  color: #1e1e1e;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}
p.postEventTime {
  letter-spacing: 0px;
  color: #1e1e1e;
  text-transform: uppercase;
  font-size: 16px;
  margin-left: 5px;
  margin-bottom: 10px;
}
p.postEventTime svg {
  font-size: 18px;
  position: relative;
  top: -3px;
  left: -5px;
}
.postLike img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
.joinNowBtn button {
  background: #60c2e7;
  border-radius: 4px;
  border: none;
  width: 92px;
  height: 36px;
  display: grid;
  place-content: center;
  color: #ffffff;
}
/* .linkDetails {
      padding: 15px;
    } */
.postLinkDescription p {
  font-size: 14px;
  color: #b3b3b3;
  margin-bottom: 0;
  padding: 15px;
}

.eventOrginzerDetails {
  display: flex;
  color: #b3b3b3;
  align-items: center;
  font-size: 14px;
  padding: 0 15px 10px;
}

.eventOrginzerDetails p {
  margin-bottom: 0;
  margin-right: 15px;
}

.eventOrginzerDetails svg {
  font-size: 21px;
  position: relative;
  top: -2px;
  margin-right: 10px;
}

.linkInfo {
  border-bottom: 1px solid #e2e2e2;
  padding: 10px 15px 0;
}
.invitationCardTitle {
  border-bottom: 1px solid #dededfbf;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

/* .invitationCardWrapper {
      padding: 25px 15px 0;
    } */
.invitationCardWrapper {
  padding: 25px 15px !important;
}
.invitationCardTitle p {
  margin-bottom: 0;
  font-size: 16px;
  color: #333333;
  font-weight: bold;
}

.invitationUserInfo img {
  width: 75px;
  height: 75px;
}

.invitationUserInfoWrapper {
  display: flex;
}

.invitationUserInfo p {
  margin-bottom: 0;
  font-size: 12px;
  color: #b3b3b3;
}

p.invitationUserName {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}

.invitationUserInfo {
  margin-left: 10px;
}

.invitationConnect {
  display: flex;
  align-items: center;
}

.invitationConnect {
  font-size: 12px;
  font-weight: bold;
  color: #b3b3b3;
  padding: 0;
}

.invitationConnect p {
  margin-bottom: 0;
  padding-right: 10px;
}
.invitationCard {
  margin-bottom: 20px;
}
.invitationConnect i {
  margin-right: 5px;
}
.writeCommentWrapper {
  padding: 10px 25px;
  border-top: 1px solid #dededfbf;
}
.flexCenter {
  display: flex;
  align-items: center;
}
.writeComment .userImg img,
.allComments .userImg img {
  width: 40px;
  height: 40px;
}

.commentInput input {
  width: 100%;
  border-radius: 18px !important;
  border: 0;
  background: rgb(179, 179, 179, 0.25);
  font-size: 14px;
  padding: 5.5px 25px;
}

.commentInput {
  flex: 1 1;
  margin-left: 25px !important;
}
.commentDetails p {
  margin-bottom: 0;
  font-size: 14px;
}

.commentDetails {
  padding: 8px 15px;
  background: rgb(179, 179, 179, 0.25);
  border-radius: 18px;
}

.commentDetails strong {
  margin-right: 10px;
  color: #f97516;
}

.allComments .userImg {
  margin-right: 6px;
}

.allComments {
  margin-bottom: 15px;
}
/* .likeOptions {
      width: 290px;
      height: 50px;
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15), 0 4px 6px rgba(0, 0, 0, 0.2);
      border-radius: 300px;
      background: #fff;
      border: none;
      justify-content: space-evenly;
      align-items: center;
      padding: 0 15px;
      position: absolute;
      top: -25px;
      left: -15px;
    }
    
    .likeOption img {
      width: 36px;
      height: 36px;
    } */
.showResponseImg img {
  width: 100%;
}
.uploadTypeIcons {
  position: relative;
  overflow: hidden;
  display: inline-block;
  cursor: pointer;
}

.uploadTypeIcons input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}
.overAlluploadicons {
  position: center;
  cursor: pointer;
}
.uploadWrapper img,
.uploadWrapper video {
  width: 100%;
  height: 300px;
  object-fit: contain;
  object-position: top;
}
.uploadPostInput textarea {
  width: 100%;
  height: 80px;
  border: none;
}

.uploadBtnWrapper {
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 10px;
  margin-top: 20px;
}

.uploadTypeIcons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-basis: 20%;
}

.addtoPostTitle {
  flex: 1 1;
}

.addtoPostTitle p {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: bold;
}

.uploadTypeIcons svg {
  cursor: pointer;
}

.previewWrapper {
  max-width: 280px !important;
  overflow: hidden auto;
  padding: 10px;
  border: 1px solid lightblue;
  border-radius: 5px;
}

.removeLink {
  padding: 0px;
  font-size: 10px;
  color: blue;
  cursor: pointer;
  float: right;
}

.uploadModelTitle {
  font-size: 20px;
  font-weight: 600;
  margin: auto;
}
.uploadPostTitle {
  font-size: 20px;

  font-weight: 400;
  margin: auto;
  display: inline-flex;
}

/* ============ MY PROFILE SCREEN =========== */
.myProfileCoverPhoto img {
  height: 280px;
  width: 100%;
  object-fit: cover;
  border-radius: 4px 4px 0 0px;
}

.myProfilePhoto img {
  width: 150px;
  height: 150px;
}

.myProfileNameDetails h2,
.myKnowledgePoint h3 {
  font-size: 21px;
  font-family: "nunitoBold", sans-serif;
  color: #333333;
  margin-bottom: 0;
}

.myProfileNameDetails p,
.myProfileDescription p {
  font-size: 14px;
  margin-bottom: 0;
  color: #b3b3b3;
}

.myKnowledgePoint p {
  margin-bottom: 0;
  color: #333333;
  font-family: "nunitoRegular", sans-serif;
}

.myProfilePhoto {
  margin-left: 50px;
  position: relative;
  bottom: 90px;
}

.alignItemStart {
  align-items: flex-start;
}

.myProifleUserInfo {
  height: 100px;
  padding-top: 20px;
}

.myProfileNameDetails {
  padding: 0 15px;
}

.myProfileDescription {
  flex-basis: 29%;
  padding: 0 15px;
}

.myProfileEdit {
  padding: 0;
  margin-left: auto;
  margin-right: 15px;
  display: flex;
  align-items: center;
  height: 100%;
}

.myProfileEdit button {
  position: relative;
  top: -20px;
}

.myKnowledgePoint {
  padding: 0 15px;
}

.borderRightDivider {
  border-right: 1px solid #dbdbdc;
  height: 50%;
}
button i {
  margin-right: 5px;
}

.myIntrestWrapper {
  padding: 20px;
}

.titleMedium {
  font-size: 18px;
  font-family: "Lato", sans-serif;
  color: #333333;
  margin-bottom: 15px;
  font-weight: bold;
}

.myIntrestCards img {
  width: 100%;
  height: 120px;
  object-fit: cover;
  border-radius: 4px;
}

.myIntrestCard {
  margin-right: 15px;
  text-align: center;
  width: 100%;
}

.myIntrestCard p {
  margin-bottom: 0;
  font-size: 16px;
  color: #333333;
  font-family: "nunitoExtraBold", sans-serif;
  font-weight: 900;
  margin-top: 5px;
}

.addNewIntrest {
  height: 120px;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #707070;
  border-radius: 4px;
  width: 100%;
  margin-top: 0 !important;
}

.addNewMyIntrestCard {
  width: 100%;
  cursor: pointer;
}

.addNewIntrest i {
  font-size: 80px;
  color: #6f6f6f;
  opacity: 0.19;
}

.addNewMyIntrestCard p {
  font-size: 16px;
  margin-bottom: 0;
  margin-top: 5px;
  color: #333333;
}

.myCompetenciesWrapper {
  padding: 20px;
}

.myCompetencie p {
  margin-bottom: 0;
  font-size: 12px;
  font-weight: bold;
  font-family: "nunitoBold", sans-serif;
}

.myCompetencie {
  flex-basis: 33.33%;
}

.myCompetencie svg {
  font-size: 16px;
  margin-right: 5px;
}

.myCompetencie span {
  font-weight: 100;
}

.myReviewsWrapper {
  padding: 15px;
}

.reviewerProfileImg img {
  width: 50px;
  height: 50px;
}

.reviewerNameDetails h3 {
  font-size: 14px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 0;
}

.reviewerNameDetails p {
  margin-bottom: 0;
  font-size: 12px;
  color: #b3b3b3;
}

.alignItemCenter {
  align-items: center;
}

.reviewerNameDetails {
  margin-left: 15px;
}

.reviewerSubText p {
  margin-bottom: 0;
  font-size: 12px;
  color: #b3b3b3;
  margin-top: 15px;
}

.reviewCard {
  border-left: 4px solid #dbdbdc;
  padding-left: 10px;
}

.allBadges img {
  width: 65px;
  margin-right: 15px;
}

.badgeCard {
  padding: 20px;
}
.allBadges img:nth-child(4) {
  margin-right: 0;
}

.myTodoListCard {
  padding: 20px;
}
ul.todoLists {
  padding-left: 0;
  margin-bottom: 0;
}

.activeTodoLists {
  color: #545454;
  text-decoration: line-through;
}

.todoLists li {
  font-size: 14px;
  color: #000000cc;
  list-style: none;
  position: relative;
  padding: 0 0 0 30px;
  cursor: pointer;
  font-weight: bold;
}

.todoLists li:before {
  content: "";
  position: absolute;
  left: 0;
  top: 3px;
  width: 17px;
  height: 17px;
  border-radius: 4px;
  border: 1px solid #d4d4d5;
}

.activeTodoLists:after {
  position: absolute;
  top: 2px;
  left: 2px;
  content: "\f00c";
  font-family: "FontAwesome", sans-serif;
  font-size: 13px;
}
.activeTodoLists:before {
  border-color: #60c2e7 !important;
}
.myProfileWrapper {
  margin-top: 15px;
}
li {
  font-family: "nunitoRegular", sans-serif;
}

.newTodoForm p {
  width: 17px;
  height: 17px;
  border-radius: 4px;
  border: 1px solid #d4d4d5;
  margin-bottom: 0;
}

.newTodoForm input {
  border-bottom: 1px solid #b3b3b3cc !important;
  border: none;
  margin-left: 10px;
  padding-left: 5px;
  font-size: 14px;
  width: 50%;
}

.addNewTodo {
  border: 1px solid #60c2e7;
  border-radius: 4px;
  width: 17px;
  height: 17px;
  margin-left: 10px;
}

.addNewTodo i {
  font-size: 11px;
  color: #60c2e7;
  position: relative;
  top: 1px;
}

.flexJustifyCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.newTodoForm {
  align-items: flex-end;
}

.myRemiderCard {
  padding: 20px;
}

.reminderGroupImg {
  width: 35px;
  height: 35px;
}

.reminderInfo h4 {
  font-size: 14px;
  color: #000000cc;
  font-weight: bold;
  margin-bottom: 0;
}

.reminderInfo p {
  font-size: 10px;
  color: #b3b3b3;
  margin-bottom: 0;
}

.reminderInfo {
  margin-left: 15px;
}

.myReminder {
  margin-bottom: 10px;
}

.reminderGroupIcon {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 2px solid #60c2e7;
}

.reminderGroupIcon svg {
  font-size: 21px;
  color: #60c2e7;
}
/* #root .container {
      max-width: 1410px;
    } */
/* =========== STUDY GROUP ============ */
.studyGroupCard {
  margin: 0;
  margin-bottom: 15px !important;
}

.studyGroupCard img {
  width: 185px;
  height: 185px;
}

.studyGroupCard p.postEventName {
  font-size: 14px;
}

.studyGroupCard p.postEventTime {
  font-size: 14px;
}

.studyGroupCard .postLinkDescription p {
  font-size: 12px !important;
  padding: 10px;
}

.studyGroupCard .eventOrginzerDetails p {
  font-size: 10px;
}

.studyGroupCard .eventOrginzerDetails svg {
  font-size: 16px;
}

.studyGroupCard .eventOrginzerDetails p:nth-child(2) {
  margin-right: 0;
}

.studyGroupCard .linkInfo {
  padding: 10px 10px 0;
}

.studyGroupCard .eventOrginzerDetails {
  padding: 0 10px 10px;
}

.studyGroupCard p.postEventTime svg {
  font-size: 14px;
}
.studyGroupCard .joinNowBtn button {
  width: 85px;
  height: 30px;
  font-size: 14px;
}
.studyGroupCard .postLinkDescription {
  max-height: 92px;
  overflow: hidden;
}
.studyGroupSeeMoreBtn {
  padding: 0;
  text-align: center;
  margin: 30px 0;
}

.studyGroupSeeMoreBtn button {
  width: 105px;
  height: 36px;
}
.createStudyGroupBtn button {
  width: 238px;
  height: 48px;
  font-size: 16px;
  font-weight: 300;
}

.createStudyGroupBtn button i {
  font-size: 13px;
}

.createStudyGroupBtn {
  padding: 0;
  position: absolute;
  top: 4.5em;
  right: 0;
}

.studyTourWrapper .searchFriend {
  position: relative;
  padding-top: 30px;
  min-height: 200px;
}
.createStudyGroupModelTitle {
  letter-spacing: 0px;
  color: #333333;
  text-transform: uppercase;
  font-size: 18px !important;
  font-family: "Lato", sans-serif;
  padding-left: 15px !important;
}

.formBasicName {
  width: 291px;
}

.createStudyGroupModel .form-group label {
  font-size: 13px;
  color: #333333de;
  font-weight: bold;
  margin-bottom: 5px;
}

.createStudyGroupModel input,
.createStudyGroupModel textarea,
.createStudyGroupModel select {
  border: 1px solid #dededf;
  border-radius: 4px;
}

.formBasicSubject {
  width: 180px;
}

.createStudyGroupModel input,
.createStudyGroupModel select {
  height: 38px;
  font-size: 14px;
}

.formBasicDescription {
  width: 500px;
}

.createStudyGroupModel .form-group {
  margin-bottom: 15px;
}

.formBasicDate {
  width: 150px;
}

.formBasicHour {
  width: 70px;
  margin: 0 20px 15px;
}

.formBasicDuration {
  width: 86px;
}

.newStudyTourinviteParticipate .input-group-prepend .input-group-text {
  background: none;
  border-right: 0;
  color: #747576;
  padding-right: 0;
}
.newStudyTourinviteParticipate .input-group-append .input-group-text {
  background: none;
  border-left: 0;
  color: #747576;
}
.newStudyTourinviteParticipate input {
  border-left: 0;
  border-right: 0;
}

.newStudyTourinviteParticipate .individualBadge p {
  margin: 0 !important;
  margin-right: 10px !important;
}

.newStudyTourinviteParticipate .individualBadge {
  height: 30px;
  position: relative;
  top: -5px;
  left: 30px;
}

.newStudyTourinviteParticipate {
  align-items: center;
  height: 100%;
}

.formBasicCheckbox input {
  height: auto;
}

.formBasicMaxStudent select {
  width: 75px;
}

.createNewStudyTourModelfooter {
  border-top: 0 !important;
}

.createNewStudyTourModelfooter .btn-secondary {
  background: #dfdfdf 0% 0% no-repeat padding-box;
  width: 75px;
  margin-right: 65px;
}

.createNewStudyTourModelfooter .btn {
  border-radius: 4px;
  border: none;
  letter-spacing: 0px;
  color: #333333;
  font-weight: bold;
  font-size: 14px;
  height: 36px;
}

.createNewStudyTourModelfooter button.btn.btn-primary {
  color: #fff;
  background: #60c2e7;
}
.createStudyGroupModel {
  padding-left: 30px !important;
}
.formBasicinviteParticipate {
  margin-bottom: 0 !important;
}
.createStudyGroupModelWrapper .modal-header {
  border-bottom: 1px solid #707070;
}

/* ==================== FOR MOBILE ============= */
/* @media (min-width: 1300px) {
      #root .container {
        max-width: 1410px;
      }
    } */
@media (max-width: 768px) {
  .subjectDropdown {
    max-width: 100%;
  }
  .descriptionTextArea {
    max-width: 100%;
  }
  .descriptionTextArea {
    max-width: 100%;
  }
  .datePicker {
    max-width: 100%;
    display: block;
    margin-right: 0px;
  }
  .timePicker {
    max-width: 100%;
    display: block;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  #root .container {
    max-width: 992px;
  }
  /* COMMUNITY SCREEN */
  .newPostUpload p {
    font-size: 12px;
  }

  .newPostUpload svg {
    font-size: 21px;
  }

  .newPostUploadVideo svg {
    font-size: 27px;
  }

  p.invitationUserName {
    font-size: 11px;
  }

  .invitationUserInfo p {
    font-size: 9px;
  }

  .invitationUserInfoWrapper .userImg img {
    width: 50px;
    height: 50px;
  }

  .invitationConnect button {
    display: flex;
    align-items: center;
  }
  /* STUDY GROUP SCREEN */
  .studyGroupCard p.postEventName {
    font-size: 12px;
  }

  .studyGroupCard p.postEventTime {
    font-size: 11px;
  }

  .studyGroupCard .postLinkDescription p {
    font-size: 11px !important;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  /* .flexDefault {
        display: block;
      } */
  /* COMMUNITY SCREEN */
  .newPostUpload p {
    font-size: 12px;
  }

  .newPostUpload svg {
    font-size: 21px;
  }

  .newPostUploadVideo svg {
    font-size: 27px;
  }
  /* ======== PROFILE SCREEN ============ */
  .uploadTypeIcons {
    flex-basis: auto;
  }
  .myProfilePhoto {
    margin-left: 0;
    bottom: 0;
    margin-bottom: 15px;
  }

  /* .flexDefault {
        display: block;
      }
     */
  .myProifleUserInfo {
    text-align: center;
    position: relative;
    bottom: 90px;
    height: 330px;
  }

  .borderRightDivider {
    border: none;
    height: auto;
  }

  .myProfileEdit {
    display: block;
    height: auto;
  }

  .myProfileDescription {
    margin: 15px 0;
  }

  .myKnowledgePoint {
    margin: 15px 0;
  }

  .myProfileEdit button {
    position: inherit;
  }

  .myIntrestCards img {
    height: 350px;
  }

  .myIntrestCard p {
    margin: 15px 0;
  }

  .addNewIntrest {
    height: 350px;
  }

  .addNewMyIntrestCard p {
    text-align: center;
    margin-top: 15px;
  }

  .myCompetencie p {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .myCompetencie svg {
    font-size: 18px;
  }

  .reviewerInfo {
    display: flex;
  }

  .allBadges {
    display: flex;
  }

  .newTodoForm {
    display: flex;
  }

  .myReminder {
    display: flex;
  }
  .reviewCard {
    margin-bottom: 15px;
  }
  /* STUDY TOUR */
  .createStudyGroupBtn {
    position: relative;
    top: 0;
    text-align: center;
  }
}
@media (min-width: 0) and (max-width: 768px) {
  /* login page */
  h3.loginQuotes {
    font-size: 14px;
    margin-top: 15px;
  }

  .signupOptions {
    display: block !important;
    width: 100%;
  }

  .signupLogo {
    margin-bottom: 15px;
    margin-right: 0;
  }
  .signupTxt p {
    margin-bottom: 5px;
  }

  /* welcome screen */
  #header .userDetailsMenu {
    display: none !important;
  }

  .welcomeCard img {
    width: 100%;
  }

  .welcomeCard {
    margin: 0;
  }

  /* ======== PROFILE SCREEN ============ */
  .ProfileTabNav .nav-item {
    width: 100%;
    margin-bottom: 5px;
  }

  .showTabArrow:after,
  .showTabArrow:before {
    content: inherit;
  }

  a#about-you {
    padding-left: 15px;
  }

  a#learning {
    padding-left: 15px;
  }
  .learningSection .profileBadge {
    display: inline-block !important;
    margin-bottom: 0;
  }

  .profileImg img {
    width: 100%;
  }

  .profileImg {
    margin-bottom: 15px;
  }
  .profileFormTextarea textarea {
    width: 100%;
  }

  .aboutInputAddButton i {
    right: 5%;
    top: 44%;
    left: inherit;
  }

  input {
    width: 100% !important;
  }
  .dragDropCardContainer {
    width: auto;
  }

  .dragDropCard {
    width: auto;
  }
  .showMobileNav {
    display: flex !important;
  }
  /* ======= MY NETWORK  ========= */
  .searchFriendSelectBoxFlex {
    display: block;
    width: 100%;
  }
  .searchFriendInput.input-group,
  .findaMatchSearchInput.input-group {
    width: 100%;
  }
  .findaMatchSearchInput {
    width: 80% !important;
  }

  .findaMatchSearchInput i {
    top: 25px;
    left: -20px;
  }

  .filterDownArrow {
    position: absolute;
    right: -55px;
  }

  .filterDownArrow i {
    top: 0;
  }

  .findMatachHeading h3 {
    font-size: 16px;
  }
  .chat-btn,
  .profile-details-wrap {
    margin-top: 15px;
  }
  .BooktimeMainContainer .col-md-6 {
    padding: 0 15px !important;
  }
  .filterTxt {
    display: none;
  }
  .filterDownArrow i {
    top: 20px;
  }

  .searchFriend.findaMatchCard .searchFriendSelectBoxFlex {
    width: 100%;
  }
  .navbar-toggler-icon {
    margin: 0;
  }
  .classroomCard {
    height: auto;
  }

  .shareClass {
    justify-content: flex-start;
    margin-top: 10px;
  }
  /* study group */
  .linkCard img {
    width: 100%;
    border-radius: 4px 4px 0px 0px;
    height: 250px;
  }
  .linkCard {
    display: block;
  }

  .createStudyGroupBtn {
    position: inherit;
    top: 0;
    margin-bottom: 15px;
    text-align: center;
  }
  .findaMatchSearchInput span {
    right: -30px;
  }
  /* STUDY GROUP DIALOGUE */
  .createStudyGroupModel .form-group {
    width: 100%;
    margin-left: 0;
  }

  .formBasicCheckbox {
    margin-top: 15px;
  }
  .newStudyTourinviteParticipate input {
    width: 1% !important;
  }

  .newStudyTourinviteParticipate .individualBadge {
    display: inline-flex;
    position: inherit;
    margin-top: 5px;
  }

  .formBasicCheckbox input {
    width: auto !important;
  }

  .formBasicMaxStudent select {
    width: 100%;
  }

  .createNewStudyTourModelfooter {
    justify-content: flex-start;
  }

  .createNewStudyTourModelfooter .btn-secondary {
    margin-right: 10px;
  }

  .createNewStudyTourModelfooter .btn {
    font-size: 11px;
  }

  .createStudyGroupModel {
    padding-left: 15px !important;
  }
  /* Community screen */
  .newPostUpload p {
    font-size: 12px;
  }

  .newPostUpload svg {
    font-size: 21px;
  }

  .newPostUploadVideo svg {
    font-size: 27px;
  }

  .communityRootContianer {
    padding: 30px 0;
  }

  /* .newPostUploadFileTypes p {
        display: none;
      } */

  .newPostUpload {
    margin-right: 5px;
  }

  .newPostUploadFileTypes {
    justify-content: center;
  }

  .newPostSearchField input {
    padding: 5px 15px;
    font-size: 12px;
  }

  .postLike {
    margin-right: 12px;
  }

  .likeComment p {
    font-size: 12px;
  }

  .likeComment svg {
    font-size: 18px;
    margin-right: 5px;
  }

  .postLike img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    position: relative;
    top: -3px;
  }

  .postCardFooter {
    padding: 15px;
  }

  .postCardHeader {
    padding: 15px 15px 20px;
  }

  .writeCommentWrapper {
    padding: 10px 15px;
  }
  /* MY PROFILE SCREEN */
  .myProfilePhoto {
    bottom: 0;
    margin-bottom: 15px;
  }

  .uploadTypeIcons {
    flex-basis: auto;
  }
  .myProifleUserInfo {
    text-align: center;
    position: relative;
    bottom: 90px;
    height: 330px;
  }

  .borderRightDivider {
    border: none;
    height: auto;
  }

  .myProfileEdit {
    display: block;
    height: auto;
  }

  .myProfileDescription {
    margin: 15px 0;
  }

  .myKnowledgePoint {
    margin: 15px 0;
  }

  .myProfileEdit button {
    position: inherit;
  }

  .myIntrestCards img {
    height: 250px;
  }

  .myIntrestCard p {
    margin: 15px 0;
  }

  .addNewIntrest {
    height: 250px;
  }

  .addNewMyIntrestCard p {
    text-align: center;
    margin-top: 15px;
  }

  .myCompetencie p {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .myCompetencie svg {
    font-size: 18px;
  }

  .reviewerInfo {
    display: flex;
  }

  .allBadges {
    display: flex;
  }

  .newTodoForm {
    display: flex;
  }

  .myReminder {
    display: flex;
  }
  .reviewCard {
    margin-bottom: 15px;
  }
}

@media (min-width: 0) and (max-width: 378px) {
  /* COMMUNITY SCREEN */
  .likesInfo p {
    font-size: 11px;
  }

  p.postEventName {
    font-size: 14px;
  }

  p.postEventTime {
    font-size: 11px;
  }

  .eventOrginzerDetails p {
    font-size: 12px;
  }
}
/* .barBtn img {
        width: 10px;
        height: 10px;
      }
      
      .barBtn img {
        margin-right: 5px;
      }
      
      .barBtn i {
        font-size: 14px !important;
      }
      
      
      .barBtn p {
        font-size: 12px;
        margin-right: 10px;
      } */

.action-bar {
  margin-left: 10px;
}

.action-item {
  margin: 5px !important;
  text-align: center;
}

.action-item > span {
  margin: 0px !important;
  text-align: center;
}

.colorLikeImg {
  width: 26px !important;
  height: 26px !important;
  margin-top: -5px;
  margin-right: 10px;
}

.commentInput {
  flex: 1 1;
  margin-left: 6px;
  margin-left: 25px;
}

.commentList {
  padding: 10px 25px;
}

.suggestedHeader {
  margin: "8px";
}

.suggestedSubHeading {
  -webkit-text-decoration-color: "#eeeeee";
          text-decoration-color: "#eeeeee";
  font-size: 12px;
  cursor: pointer;
}

.subHead {
  position: relative;
  float: right;
  cursor: pointer;
}

.suggestedFriendsbtns {
  display: inline-flex;
}
.addFriendBtn button {
  display: block;
  font-size: 10px;
  border: none;

  font-weight: bold;
  font-family: "Lato", sans-serif;
  cursor: pointer;
}
.removeFriendBtn button {
  display: block;
  margin: 8px;
  font-size: 10px;
  border: none;
  padding: 7.5px 0;
  font-weight: bold;
  font-family: "Lato", sans-serif;
  cursor: pointer;
}
button.frndrequest {
  color: #ffffff;
  background: #60c2e7;
  border-radius: 3px;
  width: 80px;
  padding: 8px;
}
p.frndremove {
  margin: 8px;
  color: #424242;

  border-radius: 3px;
  width: auto;
}
.socialProfileHeader {
  box-shadow: 5px 5px 6px #0000000d;
  border: 1px solid #dededfbf;
  border-radius: 4px;
  background: #fff;
  margin-bottom: 16px;
}
.profileTabs {
  margin-left: 10px;
  margin-right: 40px;
}
.profileTabsStart {
  margin-left: 100px;
  margin-right: 40px;
}
.profileTabsEnd {
  margin-left: 10px;
  margin-right: 0px;
}
.tabBottomLine {
  background: #60c2e7;
  height: 2px;
  margin-top: 8px;
  content: "";
  flex: 1 1;
  width: 70px;
  margin-left: 10px;
  margin-right: 40px;
}
.tabBottomLineOne {
  background: #60c2e7;
  height: 2px;
  margin-top: 8px;
  content: "";
  flex: 1 1;
  width: 50px;
  margin-left: 10px;
  margin-right: 40px;
}
.tabBottomLineTwo {
  background: #60c2e7;
  height: 2px;
  margin-top: 8px;
  content: "";
  flex: 1 1;
  width: 40px;
  margin-left: 10px;
  margin-right: 40px;
}
.tabBottomLineStart {
  background: #60c2e7;
  height: 2px;
  margin-top: 8px;
  content: "";
  flex: 1 1;
  width: 50px;
  margin-left: 100px;
  margin-right: 40px;
}
.tabBottomLineEnd {
  background: #60c2e7;
  height: 2px;
  margin-top: 8px;
  content: "";
  flex: 1 1;
  width: 90px;
  margin-left: 10px;
  margin-right: 0px;
}
.fake-input {
  position: relative;
}
.fake-input input {
  border: none;
  background-color: #fff;
  display: block;
  width: 100%;
  box-sizing: border-box;
}
.fake-input img {
  position: absolute;
  top: 2px;
  right: 5px;
  width: 30px;
  margin: 8px;
}

.topicsList {
  overflow-x: scroll !important;
  height: 100px;
}

/* ================ WELCOME SCREEN ======== */
.greyBg {
  background: #f2f2f2;
}

.welcomeCard {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 5px 5px 10px #0000000d;
  border-radius: 4px;
  padding: 20px 25px;
  margin: 0 60px;
}

#welcome {
  padding-top: 5%;
}

.welcomeCard img {
  width: 380px;
  height: auto;
}

.welcomeDetails p {
  color: #000000cc;
  font-size: 16px;
  margin-bottom: 20px;
}

.welcomeDetails {
  margin-top: 10%;
}

b.welcomeBlueText {
  color: #60c2e7;
}

button.letsGoBtn {
  background: #60c2e7 0% 0% no-repeat padding-box;
  border-radius: 4px;
  border: none;
  color: #fff;
  width: 125px;
  padding: 6px;
  font-family: "nunitoBold", sans-serif;
  cursor: pointer;
}
button.letsGoBtn:focus {
  outline: none;
}

/* =========== PROFILE PAGE ========= */
p.highlitedTabText {
  color: #b5b5b5;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 5px;
}

.profileTab {
  text-align: left;
}

.profileNumber p {
  color: #b5b5b5;
  font-size: 24px;
  font-weight: bold;
}

.profileNumber {
  margin-right: 15px;
  align-items: center;
  justify-content: center;
  display: flex;
  min-width: 30px;
}

p.tabDetailedDesc {
  color: #b5b5b5cc;
  font-size: 13px;
}

.ProfileTabNav .nav-item {
  background: #fff;
  border: 1px solid #e4e4e5;
}

.ProfileTabNav .nav-item {
  position: relative;
  border-right: 1px solid #e4e4e5 !important;
  border: none;
}
.showTabArrow:after,
.showTabArrow:before {
  left: 100%;
  top: 70%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.showTabArrow:after {
  border-color: rgba(34, 34, 34, 0);
  border-left-color: #ffffff;
  border-width: 15px;
  margin-top: -30px;
  z-index: 1;
}
.showTabArrow:before {
  border-color: rgba(228, 228, 229, 0);
  border-left-color: #dee2e6;
  border-width: 16px;
  margin-top: -31px;
  z-index: 1;
}

.ProfilePage {
  padding-top: 25px;
  padding-bottom: 25px;
}

a#about-you {
  padding-left: 30px;
}

a#learning {
  padding-left: 30px;
}

nav.ProfileTabNav {
  padding-bottom: 35px;
}

nav.ProfileTabNav .active p.highlitedTabText {
  color: #60c2e7;
  opacity: 1;
}

nav.ProfileTabNav .active .profileNumber p {
  color: #212121;
}

nav.ProfileTabNav .active p.tabDetailedDesc {
  color: #000000cc;
}
nav.ProfileTabNav .active p.highlitedTabText {
  color: #60c2e7;
  opacity: 1;
}

nav.ProfileTabNav .active .profileNumber p {
  color: #212121;
}

nav.ProfileTabNav .active p.tabDetailedDesc {
  color: #000000cc;
}
.profileTabContent:-ms-input-placeholder {
  font-family: "Lato", sans-serif;
  font-size: 13px;
}
.profileTabContent::placeholder {
  font-family: "Lato", sans-serif;
  font-size: 13px;
}
.profilePageTabContent {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 5px 5px 10px #0000000d;
  border-radius: 4px;
  padding: 25px;
}

.profileImg {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.profileDetailsForm label {
  display: block;
  font-family: "Lato", sans-serif;
  font-size: 13px;
  font-weight: bold;
  color: #333333de;
  margin-bottom: 5px;
}

.genderRadioButton label {
  display: inline;
  display: initial;
}

.profileFormInput input {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dededf;
  border-radius: 4px;
  margin-bottom: 15px;
  width: 260px;
  padding: 6px;
  font-size: 13px;
}

.genderDetails {
  margin-bottom: 15px;
}

.choosePhoto {
  align-items: center;
}

.choosePhotoImg img {
  width: 115px;
  height: auto;
}

.choosePhotoBtn button {
  background: #dfdfdf 0% 0% no-repeat padding-box;
  border-radius: 4px;
  border: none;
  width: 125px;
  padding: 6px;
  letter-spacing: 0px;
  color: #333333;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-weight: bold;
}

.choosePhotoImg {
  margin-right: 15px;
}

.profileSubmitBtn button {
  background: #f97516;
  border-radius: 4px;
  border: none;
  /* width: 154px; */
  color: #fff;
  padding: 7.5px;
  font-size: 14px;
  font-weight: bold;
  font-family: "Lato", sans-serif;
  letter-spacing: 0;
}
button {
  cursor: pointer;
}
.profileTabContent button:focus {
  outline: none;
}
.formRadioInput [type="radio"]:checked,
.formRadioInput [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.formRadioInput [type="radio"]:checked + label,
.formRadioInput [type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  line-height: 23px;
  display: inline-block;
  color: #666;
}
.formRadioInput [type="radio"]:checked + label:before,
.formRadioInput [type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 3px;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}
.formRadioInput [type="radio"]:checked + label:after,
.formRadioInput [type="radio"]:not(:checked) + label:after {
  content: "";
  width: 8px;
  height: 8px;
  background: #0c0c0c;
  position: absolute;
  top: 8px;
  left: 5px;
  border-radius: 100%;
  transition: all 0.2s ease;
}
.formRadioInput [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
.formRadioInput [type="radio"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}

.formRadioInput {
  margin-right: 10px;
}

/* ========= model ======== */
.uploadEmptyCard {
  border: 1px solid #707070;
  background: #e1e1e1;
  text-align: center;
}

.uploadEmptyCard img {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  border: 5px dashed #ffffff;
}

.uploadButton button {
  display: block;
  width: 175px;
  border: none;
  background: #dfdfdf 0% 0% no-repeat padding-box;
  border-radius: 4px;
  color: #333333;
  font-size: 14px;
  font-weight: bold;
  font-family: "Lato", sans-serif;
  padding: 7.5px;
  margin-bottom: 15px;
}

.uploadButton {
  margin-left: 35px;
}

h5#uploadModelLabel {
  font-size: 16px;
  font-weight: bold;
  color: #000000de;
}

div#uploadModel .modal-header {
  border-bottom: 1px solid #707070;
}

#uploadModel .modal-footer {
  border: navajowhite;
}

#uploadModel .btn-primary {
  background: #60c2e7 0% 0% no-repeat padding-box;
  border-radius: 4px;
  border: none;
  color: #ffffff;
  font-size: 16px;
  width: 120px;
  padding: 11.5px;
  font-weight: bold;
}

#uploadModel .btn-secondary {
  background: no-repeat;
  border: none;
  color: #333333;
  font-size: 14px;
  font-weight: bold;
}

.blurSection {
  filter: blur(8px);
}

/* ========== about section ======== */
.profileFormTextarea textarea {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dededf;
  border-radius: 4px;
  margin-bottom: 15px;
}

.aboutInputAddButton {
  position: relative;
}

.aboutInputAddButton i {
  position: absolute;
  left: 46%;
  top: 45%;
  color: #747576;
  cursor: pointer;
}

.individualBadge {
  display: flex;
  border: 1px solid #333333;
  border-radius: 100px;
  padding: 2px 10px;
  margin-right: 15px;
  align-items: center;
}

.individualBadge p {
  font-size: 13px;
  font-family: "Lato", sans-serif;
  margin-right: 10px;
}

.profileBadge {
  margin-bottom: 15px;
}

.individualBadge span {
  font-size: 14px;
  cursor: pointer;
}

.aboutSubmitBtn button {
  width: 120px !important;
}

/* ================ LEARNING SECTION ========= */
.learningSection .individualBadge p {
  margin: 0;
}
.learningSection .individualBadge {
  display: inline-block;
  margin-bottom: 5px;
}
p.dropTitle {
  font-size: 13px;
  color: #333333de;
  font-family: "Lato", sans-serif;
  font-weight: bold;
  text-align: center;
}

.dragDropCardContainer {
  margin-top: 25px;
  width: 220px;
}

.dragDropCard {
  width: 220px;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #b2b2b2;
  border-radius: 4px;
  background: #b2b2b205;
  margin: 5px 0;
}

.dragDropCard p.dragDropTxt {
  color: #000000;
  opacity: 0.25;
  font-size: 14px;
  font-weight: bold;
  width: 100px;
  text-align: center;
  font-family: "Lato", sans-serif;
}

.dragDropBottomIcons {
  text-align: center;
}

.dragDropBottomIcons img {
  width: 15%;
}
.learningBtn button {
  width: 106px;
  margin-top: 35px;
}

.dropBadgeArea {
  min-height: 150px;
}
.activeDragArea {
  display: inline-block;
  padding: 5px;
  text-align: center;
  overflow: hidden auto;
}

.profileNumber .fa {
  color: #21ba45;
  font-size: 30px;
}

.letsGoBtn img {
  width: 20px !important;
  margin-left: 5px;
}

/* ============= MY CLASSROOM ================= */
.classroomCard {
  box-shadow: 5px 5px 10px #0000000d;
  border-radius: 4px;
  background: #fff;
  padding: 10px;
  height: 570px;
  margin-bottom: 15px;
}

.classDetails h3 {
  font-size: 16px;
  margin-bottom: 2px;
}

.classDetails h2 {
  font-size: 18px;
}

.classDetails {
  position: relative;
  padding: 15px 10px 30px;
}

.classDetails i {
  position: absolute;
  left: 2%;
  top: 14%;
}
.classDetails h3,
.classDetails h2 {
  font-family: "nunitoRegular", sans-serif;
  font-weight: bold;
  margin-left: 8%;
  color: #000000de;
}

.classDetails p {
  letter-spacing: 0px;
  color: #545454;
  font-size: 12px;
}

.classImg img {
  width: 100px;
  height: 50px;
  object-fit: fill;
}

.classList h2 {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0px;
  font-family: "nunitoRegular", sans-serif;
}

.classList {
  margin-bottom: 30px;
  position: relative;
  width: 100%;
  cursor: pointer;
}

.classList p {
  font-family: "Lato", sans-serif;
  font-size: 12px;
}

.classList strong {
  font-size: 24px;
  color: #333333;
  font-family: "Bebas Neue", cursive;
  position: absolute;
  right: 2%;
  top: 21%;
}

.classListOpactiy {
  opacity: 0.5;
}

.activeClass {
  background: #e9e9e9;
  border-radius: 4px;
}

.classFeatureimg img {
  width: 100%;
  height: auto;
}

.classDetailed h1 {
  font-size: 18px;
  font-weight: bold;
  font-family: "nunitoRegular", sans-serif;
  margin-bottom: 0;
}

.classDetailed {
  padding: 25px;
}

.classDetailed p {
  font-size: 12px;
  color: #b3b3b3;
  font-weight: bold;
  font-family: "nunitoRegular", sans-serif;
  margin-bottom: 30px;
}

.takeQuizBtn {
  text-align: right;
  margin-top: 20px;
}

.takeQuizBtn button {
  background: #60c2e7 0% 0% no-repeat padding-box;
  border-radius: 4px;
  border: none;
  color: #fff;
  font-size: 14px;
  width: 130px;
  padding: 7.5px 0;
}

.classDetailed hr {
  margin: 20px 0;
  border: 2px solid #707070;
}

.flexReview {
  display: flex;
}

.flexReview p {
  margin-bottom: 0;
  margin-right: 12px;
}

.flexReview img {
  width: 20px;
  margin-right: 5px;
}

.shareClass p {
  margin-bottom: 0;
  margin-right: 25px;
  color: #333333;
  font-weight: bold;
  font-family: "Lato", sans-serif;
}

.shareClass {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.ClassRoomMainContainer {
  padding-bottom: 35px;
}
.flexClassList {
  display: flex;
  padding: 5px;
}

.classInfo {
  margin-left: 15px;
  margin-top: 5px;
}
#myclassRoom {
  padding-top: 3%;
}
/* ============= MY CLASSROOM DIALOGUE ============ */
.myClassroomModelContent {
  text-align: center;
  padding: 30px 0;
}

.myClassroomModelContent img {
  width: 60px;
}

.myClassroomModelContent p {
  color: #707070;
  font-size: 18px;
  font-family: "Lato", sans-serif;
  font-weight: bold;
  margin-bottom: 25px;
}

.myClassroomModelContent button {
  width: 170px;
  background: #60c2e7 !important;
  border: none;
  padding: 10px 0;
}

/* =============== MY NETWORK SCREEN ================ */
.findFriendContainer {
  padding: 3% 0;
}

.searchFriend {
  margin-bottom: 25px;
}

.searchFriend h3 {
  color: #707070;
  font-size: 24px;
  font-weight: bold;
  font-family: "Lato", sans-serif;
  margin-bottom: 25px;
  text-align: center;
}

.searchFriendInput.input-group,
.findaMatchSearchInput.input-group {
  width: 250px;
  margin: auto;
  position: relative;
}
.searchFriend.findaMatchCard .searchFriendSelectBoxFlex {
  width: 85%;
}
.searchFriend .input-group input {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dededf;
  border-radius: 100px !important;
  font-size: 12px;
  padding: 7px 15px;
  height: auto !important;
}

.searchFriendInput i {
  position: absolute;
  right: 15px;
  top: 9px;
  color: #9a9a9a;
  font-size: 14px;
  cursor: pointer;
}

.searchFriendSelectBoxFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 75%;
  margin: auto;
  margin-bottom: 30px;
}

.searchFriendSelectBoxFlex label {
  width: 100%;
  color: #333333de;
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 5px;
  line-height: 1;
}

.searchFriendFormField {
  margin-right: 10px;
  width: 160px;
}

.searchFriendSelectBoxFlex select {
  border: 1px solid #dededf;
  border-radius: 4px !important;
  font-size: 12px;
}

.profilePhoto img {
  width: 100px;
  height: 100px;
  box-shadow: 0px 0px 5px #00000029;
  border: 3px solid #ffffff;
  border-radius: 50%;
  object-fit: cover;
}

.networkCard {
  text-align: center;
  border: 1px solid #dededf;
  border-radius: 4px;
  background: #fff;
  position: relative;
}

.coverPhoto {
  height: 100px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  border-radius: 4px 4px 0px 0px;
}

.profilePhoto {
  margin-top: -45px;
}

.networkDetails {
  padding: 20px 15px 0;
}

.networkDetails h3 {
  font-size: 18px;
  color: #333333;
  font-weight: bold;
  font-family: "Lato", sans-serif;
}

.networkDetails p {
  color: #b3b3b3;
  font-size: 12px;
  margin-bottom: 15px;
}

.connectBtn {
  padding: 20px 15px 15px;
}

.connectBtn button {
  border-radius: 4px;
  border: none;
  background: #60c2e7;
  color: #fff;
  font-size: 12px;
  padding: 5px 8px;
  font-weight: bold;
  font-family: "nunitoRegular", sans-serif;
}
.networkCardContainer .col-md-3 {
  margin-bottom: 30px;
}
.networkCardContainer {
  min-height: 500px;
}
.networkDetailsBtn button {
  display: block;
  margin: auto;
  font-size: 14px;
  border: none;
  padding: 7.5px 0;
  font-weight: bold;
  font-family: "Lato", sans-serif;
  cursor: pointer;
}

button.networkSkip {
  background: #dfdfdf;
  border-radius: 4px;
  color: #333333;
  width: 125px;
}

/* =============== FIND A MATCH SCREEN ============ */
.findaMatchCard {
  background: #fff 0 0 no-repeat padding-box;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 15px 10px;
  margin-bottom: 15px;
  min-height: 130px;
  /*min-height: 230px; */
}
span.filterTxt {
  margin: 0 !important;
}
.filterDownArrow {
  cursor: pointer;
}
.findaMatchSearchInput span i {
  margin-right: 5px;
}
.findaMatchSearchInput {
  width: 350px !important;
}

.findaMatchSearchInput i {
  position: inherit;
  top: 10px;
  right: 10px;
  color: #9a9a9a;
  font-size: 14px;
  cursor: pointer;
}

.findaMatchSearchInput span {
  font-size: 14px;
  color: #989898;
  text-decoration: underline;
  margin-top: 6px;
  margin-left: 10px;
}

.findaMatchSearchInput span i {
  font-size: 14px;
}

.findaMatchSearchInput input {
  width: 275px;
}

.findMatachHeading {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}
.navbar-brand {
  background: transparent;
  margin: 0 20px 0 0;
}
.findMatachHeading:before,
.findMatachHeading:after {
  background: #707070;
  height: 1px;
  content: "";
  flex: 1 1;
}

.findMatchNetworkCard {
  padding: 20px 0;
}

.findMatachHeading h3 {
  color: #333333de;
  font-size: 24px;
  font-weight: bold;
  font-family: "nunitoRegular", sans-serif;
  padding: 0 10px;
}
.yuukkeHeading h3 {
  color: #b3b3b3;
}
.yuukkeHeading:before,
.yuukkeHeading:after {
  background: #b3b3b3;
}
.detailedCard {
  width: 90%;
  margin: auto;
}

.detailedCard .col-md-3 {
  margin-bottom: 30px;
}

.findMatchCardBtn i {
  margin-right: 5px;
}
/* .findMatchCardBtn button {
    width: auto;
    padding: 5px 10px;
  } */
.findMatchCardBtn button {
  width: auto;
  padding: 5px 5px;
}
button.btnOutlined {
  background: none;
  color: #60c2e7;
  border: 1px solid #60c2e7;
  margin-left: 5px;
}

.findMatchNetworkCard .row {
  margin-bottom: 25px;
}

.detailedCard .networkDetails > p:nth-child(3) {
  margin-bottom: 0;
}
/*============= My Yuukke==============*/

.MyYuukke {
  padding-top: 50px;
}
.smiley {
  text-align: center;
}
.smiley p {
  opacity: 0.6;
  font-weight: 700;
  font-size: 18px;
}
.img-wrapper img {
  max-width: 200px;
}
/* .card-wrapper {
  background: #fff;
  box-shadow: 5px 5px 10px #0000000d;
  border-radius: 4px;
  padding: 32px 40px;
  min-height: 273px;
  margin-bottom: 20px;
} */
.btn-peer {
  background: #60c2e7;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
}
.mypeercards .container {
  max-width: 1000px;
}
.btn-peer:hover {
  color: #fff;
  text-decoration: none;
}
.mypeercards {
  padding-top: 20px;
}
.img-wrapper a {
  position: absolute;
  bottom: 85px;
  left: 55px;
}

/*=============== Book Time ============*/
.book-time {
  padding: 50px 0px;
}
.book-time h4 {
  color: #707070;
  font-weight: bold;
  margin-bottom: 50px;
}
.bpc-img-wrapper {
  text-align: center;
}
.bpc-img-wrapper img {
  border-radius: 50%;
  box-shadow: 0px 0px 5px #00000029;
  border: 3px solid #ffffff;
}
.hash-tags {
  font-size: 13px;
}
.chat-btn {
  border: 1px solid #60c2e7;
  border-radius: 4px;
  padding: 10px 30px;
  color: #60c2e7;
  font-weight: bold;
  font-size: 14px;
  background: transparent;
}
.update-btn {
  background: #60c2e7 0% 0% no-repeat padding-box;
  border-radius: 4px;
  color: #fff;
  padding: 10px 30px;
  border: none;
  font-weight: bold;
}
.booking-profile-card {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d4d4d5;
  border-radius: 4px 4px 0px 0px;
  padding: 30px;
}
.right-col-wrap {
  padding: 30px;
}
.align-flex-end {
  align-items: flex-end;
}

/* ================ CALANDER =========== */
.appoinmentCalander {
  position: relative;
}
.appoinmentCalander .fc-header-toolbar {
  height: 0;
  margin: 0 !important;
}
.appoinmentCalander h2.fc-toolbar-title {
  display: none;
}
.appoinmentConfirmend {
  padding: 25px;
  text-align: center;
}

.appoinmentConfirmedContent img {
  width: 40px;
  margin-bottom: 10px;
}

p.appoinmentBoldTxt {
  font-size: 21px !important;
  font-weight: bold;
  color: #707070;
  margin-bottom: 25px !important;
}

.appoinmentConfirmedContent p {
  font-size: 18px;
  margin-bottom: 0;
}

.appoinmentBtnFlex i {
  margin-right: 5px;
}
a.fc-col-header-cell-cushion {
  font-size: 12px;
}

.fc-scroller {
  /* overflow: hidden !important; */
}

.fc-button-group .fc-next-button {
  right: -10%;
}

.fc-button-primary {
  /* background: none !important; */
  /* color: #333 !important; */
  border: none !important; /* width: 50px; */
}

.fc-button-primary .fc-icon {
  width: auto !important;
}

.fc-button-group .fc-prev-button {
  left: -10%;
}

.fc-button-group {
  position: inherit !important;
  z-index: 1;
}

.fc-view-harness {
  padding-bottom: 50% !important;
  z-index: 0;
  border-bottom: 1px solid #d4d4d5;
}

.fc .fc-timegrid-col.fc-day-today {
  background: none !important;
}

.fc-theme-standard td,
.fc-theme-standard th {
  border: 0px;
}

table.fc-scrollgrid.fc-scrollgrid-liquid {
  /* border: 0px; */
}

.fc-timeGridWeek-view thead .fc-scrollgrid-section td {
  border-top: 1px solid #d4d4d5 !important;
  border-bottom: 1px solid #d4d4d5 !important;
  padding: 5px 0;
}

.fc-button-group .fc-button {
  position: absolute !important;
  top: 2%;
  color: #5fc0e4 !important;
}
.avaliableText {
  color: #707070;
  text-align: center;
  font-size: 21px;
  font-family: "Lato", sans-serif;
}
.bookingCalanderContainer > .col-md-6 {
  padding-left: 15;
  padding-right: 0;
}
.row.bookingCalanderContainer {
  margin-top: 15px;
}
.seeMoreAvalibile {
  color: #5fc0e4;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  font-family: "Lato", sans-serif;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 0;
}
.hideOverflow {
  overflow: hidden auto !important;
}
.fc-button-group .fc-button:focus {
  box-shadow: none !important;
}
.fc .fc-scroller-harness-liquid {
  /* margin-top: 10px; */
}
.fc-timegrid-slots tr td:nth-child(odd) {
  /* display: none; */
}

.fc-scrollgrid-sync-inner {
  width: 50px;
  margin: auto;
}
.fc-event-main {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
}
.fc-v-event {
  border: none !important;
}
.available,
.unavailable {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2ecc71;
  padding: 0;
  border-radius: 3px;
  color: #fff;
}
.available {
  cursor: pointer;
}
.unavailable {
  background-color: #5fc0e4 !important;
  color: #333333 !important;
}
.fc-media-screen .fc-timegrid-event-harness {
  height: 26px;
}
/* ======== Book Time ============ */
#booktime {
  padding: 50px 0px;
}
#booktime h4 {
  color: #707070;
  font-weight: bold;
  margin-bottom: 50px;
}
.bpc-img-wrapper {
  text-align: center;
}
.bpc-img-wrapper img {
  border-radius: 50%;
  box-shadow: 0px 0px 5px #00000029;
  border: 3px solid #ffffff;
}
.hash-tags {
  font-size: 13px;
}
.chat-btn {
  border: 1px solid #60c2e7;
  border-radius: 4px;
  padding: 10px 30px;
  color: #60c2e7;
  font-weight: bold;
  font-size: 14px;
  background: transparent;
}
.update-btn {
  background: #60c2e7 0% 0% no-repeat padding-box;
  border-radius: 4px;
  color: #fff;
  padding: 10px 30px;
  border: none;
  font-weight: bold;
}
.booking-profile-card {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d4d4d5;
  border-radius: 4px 4px 0px 0px;
  padding: 20px;
}
.right-col-wrap {
  padding: 20px;
}
.align-flex-end {
  align-items: flex-end;
}
.profile-details-wrap p {
  margin: 0;
}
/* ======== Book Time ============ */
#sendRequestModal .modal-body {
  padding: 0;
}
.sr-title h3 {
  font-size: 18px;
  color: #333;
  text-transform: uppercase;
  border-bottom: 1px solid #707070;
  padding: 1rem;
  font-weight: bold;
}
.srModalContent {
  padding: 20px 40px 40px 40px;
}
.subjectDropdown {
  max-width: 180px;
}
.descriptionTextArea {
  max-width: 500px;
}
.datePicker {
  max-width: 170px;
  display: inline-block;
  margin-right: 20px;
}
.timePicker {
  max-width: 100px;
  display: inline-block;
}
.srCancel {
  background: #dfdfdf;
  color: #333;
  border: none;
  font-size: 14px;
  padding: 7.5px 20px;
  border-radius: 4px;
  font-weight: bold;
}
.srSend {
  background: #60c2e7;
  border: none;
  padding: 7.5px 20px;
  font-size: 14px;
  border-radius: 4px;
  font-weight: bold;
  color: #fff;
}
#sendRequestModal label {
  font-size: 13px;
  color: #333333de;
}

/* ============= COMMUNITY SCREEN =========== */
.communityCard {
  box-shadow: 5px 5px 6px #0000000d;
  border: 1px solid #dededfbf;
  border-radius: 10px;
  background: #fff;
  margin-bottom: 16px;
}

/* .userImg img {
    width: 65px;
    height: 65px;
    border-radius: 50%;
    box-shadow: 0px 0px 5px #00000029;
    border: 3px solid #ffffff;
  } */

.newPostUploadFileTypes {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding-right: 15px;
}

.newPostUpload {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.newPostUpload.newPostUploadFiles {
  margin-right: 0;
}
.newPostSearchField input {
  width: 100%;
  border-radius: 50px;
  border: 0;
  background: rgb(179, 179, 179, 0.25);
  font-size: 14px;
  padding: 16px 25px;
}

.newPostUpload p {
  margin-bottom: 0;
  font-size: 16px;
  margin-left: 5px;
  letter-spacing: 0.32px;
  color: #333333;
  font-weight: bold;
}

.newPostUpload svg {
  font-size: 24px;
  color: #333333;
}
/* .newPostSearchField {
    padding-left: 15px;
  } */
.padding-15 {
  padding: 15px;
}
.communityRootContianer {
  padding: 50px 15px;
}
.flex-center {
  align-items: center;
}
.newPostUploadVideo svg {
  font-size: 30px;
}
.flexSpaceBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.postCardHeaderInfo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.postInfo p {
  margin-bottom: 0;
}

.postUserName {
  color: #333333;
  font-weight: bold;
  font-size: 16px;
}

.postUploadTie {
  color: #b3b3b3;
  font-size: 14px;
}

.postInfo {
  margin-left: 16px;
}

.postCardHeader {
  padding: 15px 25px 20px;
}

.postDescription {
  padding: 0 25px;
}

.postDescription p {
  color: #000000;
  font-size: 16px;
  margin-bottom: 15px;
  letter-spacing: 0.32px;
}

.likeComment p {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: bold;
}

.likeComment {
  display: flex;
}
.invitationCardBtn {
  text-align: center;
}
.likeComment div {
  display: flex;
  cursor: pointer;
}

.postLike {
  margin-right: 30px;
  cursor: pointer;
}

.likeComment svg {
  margin-right: 10px;
  font-size: 24px;
}

.likeComment p,
.likeComment svg {
  color: #b3b3b3;
}

.likesInfo {
  display: flex;
  align-items: flex-end;
}

.likesInfo p {
  margin-bottom: 0;
  color: #b3b3b3;
  font-size: 14px;
  margin-right: 10px;
}

.postCardFooter {
  padding: 25px;
  position: relative;
}

.likesInfo img {
  width: 63px;
  height: 25px;
  margin-right: 10px;
}

p.commentInfo {
  margin-right: 0;
}

p.likesDot {
  position: relative;
  top: -3px;
}

.linkCard {
  display: flex;
  background-color: #fff;
}

.linkCard img {
  width: 200px;
  height: 200px;
  border-radius: 4px 0px 0px 4px;
}
.linkCard {
  display: flex;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  margin: 15px;
}
p.postEventName {
  letter-spacing: 0px;
  color: #1e1e1e;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}
p.postEventTime {
  letter-spacing: 0px;
  color: #1e1e1e;
  text-transform: uppercase;
  font-size: 16px;
  margin-left: 5px;
  margin-bottom: 10px;
}
p.postEventTime svg {
  font-size: 18px;
  position: relative;
  top: -3px;
  left: -5px;
}
.postLike img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
.joinNowBtn button {
  background: #60c2e7;
  border-radius: 4px;
  border: none;
  width: 92px;
  height: 36px;
  display: grid;
  place-content: center;
  color: #ffffff;
}
/* .linkDetails {
    padding: 15px;
  } */
.postLinkDescription p {
  font-size: 14px;
  color: #b3b3b3;
  margin-bottom: 0;
  padding: 15px;
}

.eventOrginzerDetails {
  display: flex;
  color: #b3b3b3;
  align-items: center;
  font-size: 14px;
  padding: 0 15px 10px;
}

.eventOrginzerDetails p {
  margin-bottom: 0;
  margin-right: 15px;
}

.eventOrginzerDetails svg {
  font-size: 21px;
  position: relative;
  top: -2px;
  margin-right: 10px;
}

.linkInfo {
  border-bottom: 1px solid #e2e2e2;
  padding: 10px 15px 0;
}
.invitationCardTitle {
  border-bottom: 1px solid #dededfbf;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.invitationCardWrapper {
  padding: 25px 15px 0;
}

.invitationCardTitle p {
  margin-bottom: 0;
  font-size: 16px;
  color: #333333;
  font-weight: bold;
}

.invitationUserInfo img {
  width: 75px;
  height: 75px;
}

.invitationUserInfoWrapper {
  display: flex;
}

.invitationUserInfo p {
  margin-bottom: 0;
  font-size: 12px;
  color: #b3b3b3;
}

p.invitationUserName {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}

.invitationUserInfo {
  margin-left: 10px;
}

.invitationConnect {
  display: flex;
  align-items: center;
}

.invitationConnect {
  font-size: 12px;
  font-weight: bold;
  color: #b3b3b3;
  padding: 0;
}

.invitationConnect p {
  margin-bottom: 0;
  padding-right: 10px;
}
.invitationCard {
  margin-bottom: 20px;
}
.invitationConnect i {
  margin-right: 5px;
}
.writeCommentWrapper {
  padding: 10px 25px;
  border-top: 1px solid #dededfbf;
}
.flexCenter {
  display: flex;
  align-items: center;
}
.writeComment .userImg img,
.allComments .userImg img {
  width: 40px;
  height: 40px;
}

.commentInput input {
  width: 100%;
  border-radius: 50px;
  border: 0;
  background: rgb(179, 179, 179, 0.25);
  font-size: 14px;
  padding: 5.5px 25px;
}
.commentInput {
  flex: 1 1;
  margin-left: 6px;
}
.commentDetails p {
  margin-bottom: 0;
  font-size: 14px;
}

.commentDetails {
  padding: 8px 15px;
  background: rgb(179, 179, 179, 0.25);
  border-radius: 18px;
}

.commentDetails strong {
  margin-right: 10px;
  color: #f97516;
}

.allComments .userImg {
  margin-right: 6px;
}

.allComments {
  margin-bottom: 15px;
}
/* .likeOptions {
    width: 290px;
    height: 50px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15), 0 4px 6px rgba(0, 0, 0, 0.2);
    border-radius: 300px;
    background: #fff;
    border: none;
    justify-content: space-evenly;
    align-items: center;
    padding: 0 15px;
    position: absolute;
    top: -25px;
    left: -15px;
  }
  
  .likeOption img {
    width: 36px;
    height: 36px;
  } */
.showResponseImg img {
  width: 100%;
}
.uploadTypeIcons {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.uploadTypeIcons input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
.uploadWrapper img,
.uploadWrapper video {
  width: 100%;
  height: 300px;
  object-fit: contain;
  object-position: top;
}
.uploadPostInput textarea {
  width: 100%;
  height: 80px;
  border: none;
}

.uploadBtnWrapper {
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 10px;
  margin-top: 20px;
}

.uploadTypeIcons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-basis: 20%;
}

.addtoPostTitle {
  flex: 1 1;
}

.addtoPostTitle p {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: bold;
}

.uploadTypeIcons svg {
  cursor: pointer;
}

.previewWrapper {
  max-width: 180px !important;
  max-height: 180px;
  overflow: hidden auto;
}

.uploadModelTitle {
  font-size: 20px;
  font-weight: 600;
  margin: auto;
}

/* ============ MY PROFILE SCREEN =========== */
.myProfileCoverPhoto img {
  height: 280px;
  width: 100%;
  object-fit: cover;
  border-radius: 4px 4px 0 0px;
}

.mySocialProfilePhoto img {
  width: 150px !important;
  height: 150px !important;
}

.myProfileNameDetails h2,
.myKnowledgePoint h3 {
  font-size: 21px;
  font-family: "nunitoBold", sans-serif;
  color: #333333;
  margin-bottom: 0;
}

.myProfileNameDetails p,
.myProfileDescription p {
  font-size: 14px;
  margin-bottom: 0;
  color: #b3b3b3;
}

.myKnowledgePoint p {
  margin-bottom: 0;
  color: #333333;
  font-family: "nunitoRegular", sans-serif;
}

/* .myProfilePhoto {
    padding-left: 0px;
    position: relative;
    bottom: 90px;
  } */

.alignItemStart {
  align-items: flex-start;
}

.myProifleUserInfo {
  height: 100px;
  padding-top: 20px;
}

.myProfileNameDetails {
  padding: 0 15px;
}

.myProfileDescription {
  flex-basis: 29%;
  padding: 0 15px;
}

.myProfileEdit {
  padding: 0;
  margin-left: auto;
  margin-right: 15px;
  display: flex;
  align-items: center;
  height: 100%;
}

.myProfileEdit button {
  position: relative;
  top: -20px;
}

.myKnowledgePoint {
  padding: 0 15px;
}

.borderRightDivider {
  border-right: 1px solid #dbdbdc;
  height: 50%;
}
button i {
  margin-right: 5px;
}

.myIntrestWrapper {
  padding: 20px;
}

.titleMedium {
  font-size: 18px;
  font-family: "Lato", sans-serif;
  color: #333333;
  margin-bottom: 15px;
  font-weight: bold;
}

.myIntrestCards img {
  width: 100%;
  height: 120px;
  object-fit: cover;
  border-radius: 4px;
}

.myIntrestCard {
  margin-right: 15px;
  text-align: center;
  width: 100%;
}

.myIntrestCard p {
  margin-bottom: 0;
  font-size: 16px;
  color: #333333;
  font-family: nunitoExtraBold;
  font-weight: 900;
  margin-top: 5px;
}

.addNewIntrest {
  height: 120px;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #707070;
  border-radius: 4px;
  width: 100%;
  margin-top: 0 !important;
}

.addNewMyIntrestCard {
  width: 100%;
  cursor: pointer;
}

.addNewIntrest i {
  font-size: 80px;
  color: #6f6f6f;
  opacity: 0.19;
}

.addNewMyIntrestCard p {
  font-size: 16px;
  margin-bottom: 0;
  margin-top: 5px;
  color: #333333;
}
.myCompetenciesHeader {
  display: inline-flex;
}
.myCompetenciesWrapper {
  padding: 20px;
}

.myCompetencie p {
  margin-bottom: 0;
  font-size: 12px;
  font-weight: bold;
  font-family: "nunitoBold", sans-serif;
}

.myCompetencie {
  flex-basis: 33.33%;
  display: inline-flex;
}

.myCompetencie svg {
  font-size: 16px !important;
  margin-right: 5px !important;
  width: 20px !important;
}

.myCompetencie span {
  font-weight: 100;
}
.addCom {
  padding: 0;
  margin-left: auto;
  margin-right: 25px;
  display: flex;
  align-items: center;
  margin-top: -30px;
  height: 100%;
}
.myReviewsWrapper {
  padding: 15px;
}

.reviewerProfileImg img {
  width: 50px;
  height: 50px;
}

.reviewerNameDetails h3 {
  font-size: 14px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 0;
}

.reviewerNameDetails p {
  margin-bottom: 0;
  font-size: 12px;
  color: #b3b3b3;
}

.alignItemCenter {
  align-items: center;
}

.reviewerNameDetails {
  margin-left: 15px;
}

.reviewerSubText p {
  margin-bottom: 0;
  font-size: 12px;
  color: #b3b3b3;
  margin-top: 15px;
}

.reviewCard {
  border-left: 4px solid #dbdbdc;
  padding-left: 10px;
}

.allBadges img {
  width: 65px;
  margin-right: 15px;
}

.badgeCard {
  padding: 20px;
}
.allBadges img:nth-child(4) {
  margin-right: 0;
}

.myTodoListCard {
  padding: 20px;
}
ul.todoLists {
  padding-left: 0;
  margin-bottom: 0;
}

.activeTodoLists {
  color: #545454;
  text-decoration: line-through;
}

.todoLists li {
  font-size: 14px;
  color: #000000cc;
  list-style: none;
  position: relative;
  padding: 0 0 0 30px;
  cursor: pointer;
  font-weight: bold;
}

.todoLists li:before {
  content: "";
  position: absolute;
  left: 0;
  top: 3px;
  width: 17px;
  height: 17px;
  border-radius: 4px;
  border: 1px solid #d4d4d5;
}

.activeTodoLists:after {
  position: absolute;
  top: 2px;
  left: 2px;
  content: "\f00c";
  font-family: "FontAwesome", sans-serif;
  font-size: 13px;
}
.activeTodoLists:before {
  border-color: #60c2e7 !important;
}
.myProfileWrapper {
  margin-top: 15px;
}
li {
  font-family: "nunitoRegular", sans-serif;
}

.newTodoForm p {
  width: 17px;
  height: 17px;
  border-radius: 4px;
  border: 1px solid #d4d4d5;
  margin-bottom: 0;
}

.newTodoForm input {
  border-bottom: 1px solid #b3b3b3cc !important;
  border: none;
  margin-left: 10px;
  padding-left: 5px;
  font-size: 14px;
  width: 50%;
}

.addNewTodo {
  border: 1px solid #60c2e7;
  border-radius: 4px;
  width: 17px;
  height: 17px;
  margin-left: 10px;
}

.addNewTodo i {
  font-size: 11px;
  color: #60c2e7;
  position: relative;
  top: 1px;
}

.flexJustifyCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.updateNewTodo {
  width: 17px;
  height: 17px;
  margin-left: 10px;
}

.updateNewTodo i {
  font-size: 18px;
  color: #60c2e7;
  position: relative;
  top: 1px;
}
.newTodoForm {
  align-items: flex-end;
}

.myRemiderCard {
  padding: 20px;
}

.reminderGroupImg {
  width: 35px;
  height: 35px;
}

.reminderInfo h4 {
  font-size: 14px;
  color: #000000cc;
  font-weight: bold;
  margin-bottom: 0;
}

.reminderInfo p {
  font-size: 10px;
  color: #b3b3b3;
  margin-bottom: 0;
}

.reminderInfo {
  margin-left: 15px;
}

.myReminder {
  margin-bottom: 10px;
}

.reminderGroupIcon {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 2px solid #60c2e7;
}

.reminderGroupIcon svg {
  font-size: 21px;
  color: #60c2e7;
}
/* #root .container {
    max-width: 1410px;
  } */
/* =========== STUDY GROUP ============ */
.studyGroupCard {
  margin: 0;
  margin-bottom: 15px !important;
}

.studyGroupCard img {
  width: 185px;
  height: 185px;
}

.studyGroupCard p.postEventName {
  font-size: 14px;
}

.studyGroupCard p.postEventTime {
  font-size: 14px;
}

.studyGroupCard .postLinkDescription p {
  font-size: 12px !important;
  padding: 10px;
}

.studyGroupCard .eventOrginzerDetails p {
  font-size: 10px;
}

.studyGroupCard .eventOrginzerDetails svg {
  font-size: 16px;
}

.studyGroupCard .eventOrginzerDetails p:nth-child(2) {
  margin-right: 0;
}

.studyGroupCard .linkInfo {
  padding: 10px 10px 0;
}

.studyGroupCard .eventOrginzerDetails {
  padding: 0 10px 10px;
}

.studyGroupCard p.postEventTime svg {
  font-size: 14px;
}
.studyGroupCard .joinNowBtn button {
  width: 85px;
  height: 30px;
  font-size: 14px;
}
.studyGroupCard .postLinkDescription {
  max-height: 92px;
  overflow: hidden;
}
.studyGroupSeeMoreBtn {
  padding: 0;
  text-align: center;
  margin: 30px 0;
}

.studyGroupSeeMoreBtn button {
  width: 105px;
  height: 36px;
}
.createStudyGroupBtn button {
  width: 238px;
  height: 48px;
  font-size: 16px;
  font-weight: 300;
}

.createStudyGroupBtn button i {
  font-size: 13px;
}

.createStudyGroupBtn {
  padding: 0;
  position: absolute;
  top: 4.5em;
  right: 0;
}

.studyTourWrapper .searchFriend {
  position: relative;
  padding-top: 30px;
  min-height: 200px;
}
.createStudyGroupModelTitle {
  letter-spacing: 0px;
  color: #333333;
  text-transform: uppercase;
  font-size: 18px !important;
  font-family: "Lato", sans-serif;
  padding-left: 15px !important;
}

.formBasicName {
  width: 291px;
}

.createStudyGroupModel .form-group label {
  font-size: 13px;
  color: #333333de;
  font-weight: bold;
  margin-bottom: 5px;
}

.createStudyGroupModel input,
.createStudyGroupModel textarea,
.createStudyGroupModel select {
  border: 1px solid #dededf;
  border-radius: 4px;
}

.formBasicSubject {
  width: 180px;
}

.createStudyGroupModel input,
.createStudyGroupModel select {
  height: 38px;
  font-size: 14px;
}

.formBasicDescription {
  width: 500px;
}

.createStudyGroupModel .form-group {
  margin-bottom: 15px;
}

.formBasicDate {
  width: 150px;
}

.formBasicHour {
  width: 70px;
  margin: 0 20px 15px;
}

.formBasicDuration {
  width: 86px;
}

.newStudyTourinviteParticipate .input-group-prepend .input-group-text {
  background: none;
  border-right: 0;
  color: #747576;
  padding-right: 0;
}
.newStudyTourinviteParticipate .input-group-append .input-group-text {
  background: none;
  border-left: 0;
  color: #747576;
}
.newStudyTourinviteParticipate input {
  border-left: 0;
  border-right: 0;
}

.newStudyTourinviteParticipate .individualBadge p {
  margin: 0 !important;
  margin-right: 10px !important;
}

.newStudyTourinviteParticipate .individualBadge {
  height: 30px;
  position: relative;
  top: -5px;
  left: 30px;
}

.newStudyTourinviteParticipate {
  align-items: center;
  height: 100%;
}

.formBasicCheckbox input {
  height: auto;
}

.formBasicMaxStudent select {
  width: 75px;
}

.createNewStudyTourModelfooter {
  border-top: 0 !important;
}

.createNewStudyTourModelfooter .btn-secondary {
  background: #dfdfdf 0% 0% no-repeat padding-box;
  width: 75px;
  margin-right: 65px;
}

.createNewStudyTourModelfooter .btn {
  border-radius: 4px;
  border: none;
  letter-spacing: 0px;
  color: #333333;
  font-weight: bold;
  font-size: 14px;
  height: 36px;
}

.createNewStudyTourModelfooter button.btn.btn-primary {
  color: #fff;
  background: #60c2e7;
}
.createStudyGroupModel {
  padding-left: 30px !important;
}
.formBasicinviteParticipate {
  margin-bottom: 0 !important;
}
.createStudyGroupModelWrapper .modal-header {
  border-bottom: 1px solid #707070;
}

/* ==================== FOR MOBILE ============= */
/* @media (min-width: 1300px) {
    #root .container {
      max-width: 1410px;
    }
  } */
@media (max-width: 768px) {
  .subjectDropdown {
    max-width: 100%;
  }
  .descriptionTextArea {
    max-width: 100%;
  }
  .descriptionTextArea {
    max-width: 100%;
  }
  .datePicker {
    max-width: 100%;
    display: block;
    margin-right: 0px;
  }
  .timePicker {
    max-width: 100%;
    display: block;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  #root .container {
    max-width: 992px;
  }
  /* COMMUNITY SCREEN */
  .newPostUpload p {
    font-size: 12px;
  }

  .newPostUpload svg {
    font-size: 21px;
  }

  .newPostUploadVideo svg {
    font-size: 27px;
  }

  p.invitationUserName {
    font-size: 11px;
  }

  .invitationUserInfo p {
    font-size: 9px;
  }

  .invitationUserInfoWrapper .userImg img {
    width: 50px;
    height: 50px;
  }

  .invitationConnect button {
    display: flex;
    align-items: center;
  }
  /* STUDY GROUP SCREEN */
  .studyGroupCard p.postEventName {
    font-size: 12px;
  }

  .studyGroupCard p.postEventTime {
    font-size: 11px;
  }

  .studyGroupCard .postLinkDescription p {
    font-size: 11px !important;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  /* .flexDefault {
      display: block;
    } */
  /* COMMUNITY SCREEN */
  .newPostUpload p {
    font-size: 12px;
  }

  .newPostUpload svg {
    font-size: 21px;
  }

  .newPostUploadVideo svg {
    font-size: 27px;
  }
  /* ======== PROFILE SCREEN ============ */
  .uploadTypeIcons {
    flex-basis: auto;
  }
  /* .myProfilePhoto {
      padding-left: 0;
      bottom: 0;
      margin-bottom: 15px;
    } */

  /* .flexDefault {
      display: block;
    } */

  /* .myProifleUserInfo {
      text-align: center;
      position: relative;
      bottom: 90px;
      height: 330px;
    } */

  .borderRightDivider {
    border: none;
    height: auto;
  }

  .myProfileEdit {
    display: block;
    height: auto;
  }

  .myProfileDescription {
    margin: 15px 0;
  }

  .myKnowledgePoint {
    margin: 15px 0;
  }

  .myProfileEdit button {
    position: inherit;
  }

  .myIntrestCards img {
    height: 350px;
  }

  .myIntrestCard p {
    margin: 15px 0;
  }

  .addNewIntrest {
    height: 350px;
  }

  .addNewMyIntrestCard p {
    text-align: center;
    margin-top: 15px;
  }
  .competenciesOverall {
    display: inline-flex;
  }
  .myCompetencie p {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .myCompetencie svg {
    font-size: 18px;
  }

  .reviewerInfo {
    display: flex;
  }

  .allBadges {
    display: flex;
  }

  .newTodoForm {
    display: flex;
  }

  .myReminder {
    display: flex;
  }
  .reviewCard {
    margin-bottom: 15px;
  }
  /* STUDY TOUR */
  .createStudyGroupBtn {
    position: relative;
    top: 0;
    text-align: center;
  }
}
@media (min-width: 0) and (max-width: 768px) {
  /* login page */
  h3.loginQuotes {
    font-size: 14px;
    margin-top: 15px;
  }

  .signupOptions {
    display: block !important;
    width: 100%;
  }

  .signupLogo {
    margin-bottom: 15px;
    margin-right: 0;
  }
  .signupTxt p {
    margin-bottom: 5px;
  }

  /* welcome screen */
  #header .userDetailsMenu {
    display: none !important;
  }

  .welcomeCard img {
    width: 100%;
  }

  .welcomeCard {
    margin: 0;
  }
  button.todoUpdateButton {
    color: #ffffff;
    background: #60c2e7;
    border-radius: 4px;
    width: 105px;
    margin: 12px;
    margin-bottom: 15px;
  }
  /* ======== PROFILE SCREEN ============ */
  .ProfileTabNav .nav-item {
    width: 100%;
    margin-bottom: 5px;
  }

  .showTabArrow:after,
  .showTabArrow:before {
    content: inherit;
  }

  a#about-you {
    padding-left: 15px;
  }

  a#learning {
    padding-left: 15px;
  }
  .learningSection .profileBadge {
    display: inline-block !important;
    margin-bottom: 0;
  }

  .profileImg img {
    width: 100%;
  }

  .profileImg {
    margin-bottom: 15px;
  }
  .profileFormTextarea textarea {
    width: 100%;
  }

  .aboutInputAddButton i {
    right: 5%;
    top: 44%;
    left: inherit;
  }

  input {
    width: 100% !important;
  }
  .dragDropCardContainer {
    width: auto;
  }

  .dragDropCard {
    width: auto;
  }
  .showMobileNav {
    display: flex !important;
  }
  /* ======= MY NETWORK  ========= */
  .searchFriendSelectBoxFlex {
    display: block;
    width: 100%;
  }
  .searchFriendInput.input-group,
  .findaMatchSearchInput.input-group {
    width: 100%;
  }
  .findaMatchSearchInput {
    width: 80% !important;
  }

  .findaMatchSearchInput i {
    top: 25px;
    left: -20px;
  }

  .filterDownArrow {
    position: absolute;
    right: -55px;
  }

  .filterDownArrow i {
    top: 0;
  }

  .findMatachHeading h3 {
    font-size: 16px;
  }
  .chat-btn,
  .profile-details-wrap {
    margin-top: 15px;
  }
  .BooktimeMainContainer .col-md-6 {
    padding: 0 15px !important;
  }
  .filterTxt {
    display: none;
  }
  .filterDownArrow i {
    top: 20px;
  }

  .searchFriend.findaMatchCard .searchFriendSelectBoxFlex {
    width: 100%;
  }
  .navbar-toggler-icon {
    margin: 0;
  }
  .classroomCard {
    height: auto;
  }

  .shareClass {
    justify-content: flex-start;
    margin-top: 10px;
  }
  /* study group */
  .linkCard img {
    width: 100%;
    border-radius: 4px 4px 0px 0px;
    height: 250px;
  }
  .linkCard {
    display: block;
  }

  .createStudyGroupBtn {
    position: inherit;
    top: 0;
    margin-bottom: 15px;
    text-align: center;
  }
  .findaMatchSearchInput span {
    right: -30px;
  }
  /* STUDY GROUP DIALOGUE */
  .createStudyGroupModel .form-group {
    width: 100%;
    margin-left: 0;
  }

  .formBasicCheckbox {
    margin-top: 15px;
  }
  .newStudyTourinviteParticipate input {
    width: 1% !important;
  }

  .newStudyTourinviteParticipate .individualBadge {
    display: inline-flex;
    position: inherit;
    margin-top: 5px;
  }

  .formBasicCheckbox input {
    width: auto !important;
  }

  .formBasicMaxStudent select {
    width: 100%;
  }

  .createNewStudyTourModelfooter {
    justify-content: flex-start;
  }

  .createNewStudyTourModelfooter .btn-secondary {
    margin-right: 10px;
  }

  .createNewStudyTourModelfooter .btn {
    font-size: 11px;
  }

  .createStudyGroupModel {
    padding-left: 15px !important;
  }
  /* Community screen */
  .newPostUpload p {
    font-size: 12px;
  }

  .newPostUpload svg {
    font-size: 21px;
  }

  .newPostUploadVideo svg {
    font-size: 27px;
  }

  .communityRootContianer {
    padding: 30px 0;
  }

  .newPostUploadFileTypes p {
    display: none;
  }

  .newPostUpload {
    margin-right: 5px;
  }

  .newPostUploadFileTypes {
    justify-content: center;
  }

  .newPostSearchField input {
    padding: 5px 15px;
    font-size: 12px;
  }

  .postLike {
    margin-right: 12px;
  }

  .likeComment p {
    font-size: 12px;
  }

  .likeComment svg {
    font-size: 18px;
    margin-right: 5px;
  }

  .postLike img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    position: relative;
    top: -3px;
  }

  .postCardFooter {
    padding: 15px;
  }

  .postCardHeader {
    padding: 15px 15px 20px;
  }

  .writeCommentWrapper {
    padding: 10px 15px;
  }
  /* MY PROFILE SCREEN */
  /* .myProfilePhoto {
      padding-left: 0;
      bottom: 0;
      margin-bottom: 15px;
    } */

  .uploadTypeIcons {
    flex-basis: auto;
  }
  /* .myProifleUserInfo {
      text-align: center;
      position: relative;
      bottom: 90px;
      height: 330px;
    } */
  .myProifleUserInfo {
    text-align: center;
    position: relative;
    bottom: 90px;
    /*  height: 330px; */
    height: auto !important;
  }
  .borderRightDivider {
    border: none;
    height: auto;
  }

  .myProfileEdit {
    display: block;
    height: auto;
  }

  .myProfileDescription {
    margin: 15px 0;
  }

  .myKnowledgePoint {
    margin: 15px 0;
  }

  .myProfileEdit button {
    position: inherit;
  }

  .myIntrestCards img {
    height: 250px;
  }

  .myIntrestCard p {
    margin: 15px 0;
  }

  .addNewMyIntrestCard p {
    text-align: center;
    margin-top: 15px;
  }

  .myCompetencie p {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .myCompetencie svg {
    font-size: 18px;
  }

  .reviewerInfo {
    display: flex;
  }

  .allBadges {
    display: flex;
  }

  .newTodoForm {
    display: flex;
  }

  .myReminder {
    display: flex;
  }
  .reviewCard {
    margin-bottom: 15px;
  }
}
.todoeditUpdate {
  display: inline-flex;
  position: absolute;
  right: 0;
  margin-top: -20px;
  /*margin-top: -40px;*/
}
.competenciesListBody {
  overflow-x: scroll !important;
  height: 100px;
}
.mySocialProfilePhoto {
  margin-left: 50px;
  position: relative;
  bottom: 90px;
}

button:focus,
:focus,
div:focus {
  outline: none !important;
}
/* img {
  max-width: 100%;
  height: auto;
} */

/* ========= model ======== */
.uploadEmptyCard {
  border: 1px solid #707070;
  background: #e1e1e1;
  text-align: center;
}

.uploadEmptyCard img {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  border: 5px dashed #ffffff;
}

.uploadButton button {
  display: block;
  width: 175px;
  border: none;
  background: #dfdfdf 0% 0% no-repeat padding-box;
  border-radius: 4px;
  color: #333333;
  font-size: 14px;
  font-weight: bold;
  font-family: "Lato", sans-serif;
  padding: 7.5px;
  margin-bottom: 15px;
}
g .uploadButton {
  margin-left: 35px;
}
.createPostModel button {
  margin-left: 0 !important;
}
h5#uploadModelLabel {
  font-size: 16px;
  font-weight: bold;
  color: #000000de;
}

div#uploadModel .modal-header {
  border-bottom: 1px solid #707070;
}

#uploadModel .modal-footer {
  border: navajowhite;
}

#uploadModel .btn-primary {
  background: #60c2e7 0% 0% no-repeat padding-box;
  border-radius: 4px;
  border: none;
  color: #ffffff;
  font-size: 16px;
  width: 120px;
  padding: 11.5px;
  font-weight: bold;
}

#uploadModel .btn-secondary {
  background: no-repeat;
  border: none;
  color: #333333;
  font-size: 14px;
  font-weight: bold;
}

.blurSection {
  filter: blur(8px);
}

/* ========== about section ======== */
.profileFormTextarea textarea {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dededf;
  border-radius: 4px;
  margin-bottom: 15px;
}

.aboutInputAddButton {
  position: relative;
}

.aboutInputAddButton i {
  position: absolute;
  left: 46%;
  top: 45%;
  color: #747576;
  cursor: pointer;
}

.individualBadge {
  display: flex;
  border: 1px solid #333333;
  border-radius: 100px;
  padding: 2px 10px;
  margin-right: 15px;
  align-items: center;
}

.individualBadge p {
  font-size: 13px;
  font-family: "Lato", sans-serif;
  margin-right: 10px;
}

.profileBadge {
  margin-bottom: 15px;
}

.individualBadge span {
  font-size: 14px;
  cursor: pointer;
}

.aboutSubmitBtn button {
  width: 120px !important;
}

/* ================ LEARNING SECTION ========= */
.learningSection .individualBadge p {
  margin: 0;
}
.learningSection .individualBadge {
  display: inline-block;
  margin-bottom: 5px;
}
p.dropTitle {
  font-size: 13px;
  color: #333333de;
  font-family: "Lato", sans-serif;
  font-weight: bold;
  text-align: center;
}

.dragDropCardContainer {
  margin-top: 25px;
  width: 220px;
}

.dragDropCard {
  width: 220px;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #b2b2b2;
  border-radius: 4px;
  background: #b2b2b205;
  margin: 5px 0;
}

.dragDropCard p.dragDropTxt {
  color: #000000;
  opacity: 0.25;
  font-size: 14px;
  font-weight: bold;
  width: 100px;
  text-align: center;
  font-family: "Lato", sans-serif;
}

.dragDropBottomIcons {
  text-align: center;
}

.dragDropBottomIcons img {
  width: 15%;
}
.learningBtn button {
  width: 106px;
  margin-top: 35px;
}

.dropBadgeArea {
  min-height: 150px;
}
.activeDragArea {
  display: inline-block;
  padding: 5px;
  text-align: center;
  overflow: hidden auto;
}

.profileNumber .fa {
  color: #21ba45;
  font-size: 30px;
}

.letsGoBtn img {
  width: 20px !important;
  margin-left: 5px;
}

/* ============= MY CLASSROOM ================= */
.classroomCard {
  box-shadow: 5px 5px 10px #0000000d;
  border-radius: 4px;
  background: #fff;
  padding: 10px;
  height: 570px;
  margin-bottom: 15px;
}

.classDetails h3 {
  font-size: 16px;
  margin-bottom: 2px;
}

.classDetails h2 {
  font-size: 18px;
}

.classDetails {
  position: relative;
  padding: 15px 10px 30px;
}

.classDetails i {
  position: absolute;
  left: 2%;
  top: 14%;
}
.classDetails h3,
.classDetails h2 {
  /* font-family: "nunitoRegular"; */
  font-family: "nunitoRegular", sans-serif;
  font-weight: bold;
  margin-left: 8%;
  color: #000000de;
}

.classDetails p {
  letter-spacing: 0px;
  color: #545454;
  font-size: 12px;
}

.classImg img {
  width: 100px;
  height: 50px;
  object-fit: fill;
}

.classList h2 {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0px;
  font-family: "nunitoRegular", sans-serif;
}

.classList {
  margin-bottom: 30px;
  position: relative;
  width: 100%;
  cursor: pointer;
}

.classList p {
  font-family: "Lato", sans-serif;
  font-size: 12px;
}

.classList strong {
  font-size: 24px;
  color: #333333;
  font-family: "Bebas Neue", cursive;
  position: absolute;
  right: 2%;
  top: 21%;
}

.classListOpactiy {
  opacity: 0.5;
}

.activeClass {
  background: #e9e9e9;
  border-radius: 4px;
}

.classFeatureimg img {
  width: 100%;
  height: auto;
}

.classDetailed h1 {
  font-size: 18px;
  font-weight: bold;
  font-family: "nunitoRegular", sans-serif;
  margin-bottom: 0;
}

.classDetailed {
  padding: 25px;
}

.classDetailed p {
  font-size: 12px;
  color: #b3b3b3;
  font-weight: bold;
  font-family: "nunitoRegular", sans-serif;
  margin-bottom: 30px;
}

.takeQuizBtn {
  text-align: right;
  margin-top: 20px;
}

.takeQuizBtn button {
  background: #60c2e7 0% 0% no-repeat padding-box;
  border-radius: 4px;
  border: none;
  color: #fff;
  font-size: 14px;
  width: 130px;
  padding: 7.5px 0;
}

.classDetailed hr {
  margin: 20px 0;
  border: 2px solid #707070;
}

.flexReview {
  display: flex;
}

.flexReview p {
  margin-bottom: 0;
  margin-right: 12px;
}

.flexReview img {
  width: 20px;
  margin-right: 5px;
}

.shareClass p {
  margin-bottom: 0;
  margin-right: 25px;
  color: #333333;
  font-weight: bold;
  font-family: "Lato", sans-serif;
}

.shareClass {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.ClassRoomMainContainer {
  padding-bottom: 35px;
}
.flexClassList {
  display: flex;
  padding: 5px;
}

.classInfo {
  margin-left: 15px;
  margin-top: 5px;
}
#myclassRoom {
  padding-top: 3%;
}
/* ============= MY CLASSROOM DIALOGUE ============ */
.myClassroomModelContent {
  text-align: center;
  padding: 30px 0;
}

.myClassroomModelContent img {
  width: 60px;
}

.myClassroomModelContent p {
  color: #707070;
  font-size: 18px;
  font-family: "Lato", sans-serif;
  font-weight: bold;
  margin-bottom: 25px;
}

.myClassroomModelContent button {
  width: 170px;
  background: #60c2e7 !important;
  border: none;
  padding: 10px 0;
}

/* =============== MY NETWORK SCREEN ================ */
.findFriendContainer {
  padding: 3% 0;
}

.searchFriend {
  margin-bottom: 25px;
}

.searchFriend h3 {
  color: #707070;
  font-size: 24px;
  font-weight: bold;
  font-family: "Lato", sans-serif;
  margin-bottom: 25px;
  text-align: center;
}

.searchFriendInput.input-group,
.findaMatchSearchInput.input-group {
  width: 250px;
  margin: auto;
  position: relative;
}
.searchFriend.findaMatchCard .searchFriendSelectBoxFlex {
  width: 85%;
}
.searchFriend .input-group input {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dededf;
  border-radius: 100px !important;
  font-size: 12px;
  padding: 7px 15px;
  height: auto !important;
}

.searchFriendInput i {
  position: absolute;
  right: 15px;
  top: 9px;
  color: #9a9a9a;
  font-size: 14px;
  cursor: pointer;
}

.searchFriendSelectBoxFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 75%;
  margin: auto;
  margin-bottom: 30px;
}

.searchFriendSelectBoxFlex label {
  width: 100%;
  color: #333333de;
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 5px;
  line-height: 1;
}

.searchFriendFormField {
  margin-right: 10px;
  width: 160px;
}

.searchFriendSelectBoxFlex select {
  border: 1px solid #dededf;
  border-radius: 4px !important;
  font-size: 12px;
}

.profilePhoto img {
  width: 100px;
  height: 100px;
  box-shadow: 0px 0px 5px #00000029;
  border: 3px solid #ffffff;
  border-radius: 50%;
  object-fit: cover;
}

.networkCard {
  text-align: center;
  border: 1px solid #dededf;
  border-radius: 4px;
  background: #fff;
  position: relative;
}

.coverPhoto {
  height: 100px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  border-radius: 4px 4px 0px 0px;
}

.profilePhoto {
  margin-top: -45px;
}

.networkDetails {
  padding: 20px 15px 0;
}

.networkDetails h3 {
  font-size: 18px;
  color: #333333;
  font-weight: bold;
  font-family: "Lato", sans-serif;
}

.networkDetails p {
  color: #b3b3b3;
  font-size: 12px;
  margin-bottom: 15px;
}

.connectBtn {
  padding: 20px 5px 15px;
}

.connectBtn button {
  border-radius: 4px;
  border: none;
  background: #60c2e7;
  color: #fff;
  font-size: 12px;
  padding: 5px 8px;
  font-weight: bold;
  font-family: "nunitoRegular", sans-serif;
  border: 1px solid #60c2e7;
}
.networkCardContainer .col-md-3 {
  margin-bottom: 30px;
}
.networkCardContainer {
  min-height: 500px;
}
.networkDetailsBtn button {
  display: block;
  margin: auto;
  font-size: 14px;
  border: none;
  padding: 7.5px 0;
  font-weight: bold;
  font-family: "Lato", sans-serif;
  cursor: pointer;
}

button.networkSkip {
  background: #dfdfdf;
  border-radius: 4px;
  color: #333333;
  width: 125px;
}

/* =============== FIND A MATCH SCREEN ============ */
.findaMatchCard {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 5px 5px 10px #0000000d;
  border-radius: 4px;
  padding: 15px 10px;
  margin-bottom: 15px;
  min-height: 230px;
}
span.filterTxt {
  margin: 0 !important;
}
.filterDownArrow {
  cursor: pointer;
}
.findaMatchSearchInput span i {
  margin-right: 5px;
}
.findaMatchSearchInput {
  width: 350px !important;
}

.findaMatchSearchInput i {
  position: inherit;
  top: 10px;
  right: 10px;
  color: #9a9a9a;
  font-size: 14px;
  cursor: pointer;
}

.findaMatchSearchInput span {
  font-size: 14px;
  color: #989898;
  text-decoration: underline;
  margin-top: 6px;
  margin-left: 10px;
}

.findaMatchSearchInput span i {
  font-size: 14px;
}

.findaMatchSearchInput input {
  width: 275px;
}

.findMatachHeading {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.findMatachHeading:before,
.findMatachHeading:after {
  background: #707070;
  height: 1px;
  content: "";
  flex: 1 1;
}

.findMatchNetworkCard {
  padding: 20px 0;
}

.findMatachHeading h3 {
  color: #333333de;
  font-size: 24px;
  font-weight: bold;
  font-family: "nunitoRegular", sans-serif;
  padding: 0 10px;
}
.yuukkeHeading h3 {
  color: #b3b3b3;
}
.yuukkeHeading:before,
.yuukkeHeading:after {
  background: #b3b3b3;
}
.detailedCard {
  width: 90%;
  margin: auto;
}

.detailedCard .col-md-3 {
  margin-bottom: 30px;
}

.findMatchCardBtn i {
  margin-right: 5px;
}
.findMatchCardBtn button {
  width: auto;
  padding: 5px 10px;
}

button.btnOutlined {
  background: none;
  color: #60c2e7;
  border: 1px solid #60c2e7;
  margin-left: 5px;
}

.findMatchNetworkCard .row {
  margin-bottom: 25px;
}

.detailedCard .networkDetails > p:nth-child(3) {
  margin-bottom: 0;
}

/*=============== Book Time ============*/
.book-time {
  padding: 50px 0px;
}
.book-time h4 {
  color: #707070;
  font-weight: bold;
  margin-bottom: 50px;
}
.bpc-img-wrapper {
  text-align: center;
}
.bpc-img-wrapper img {
  border-radius: 50%;
  box-shadow: 0px 0px 5px #00000029;
  border: 3px solid #ffffff;
}
.hash-tags {
  font-size: 13px;
}
.chat-btn {
  border: 1px solid #60c2e7;
  border-radius: 4px;
  padding: 10px 30px;
  color: #60c2e7;
  font-weight: bold;
  font-size: 14px;
  background: transparent;
}
.update-btn {
  background: #60c2e7 0% 0% no-repeat padding-box;
  border-radius: 4px;
  color: #fff;
  padding: 10px 30px;
  border: none;
  font-weight: bold;
}
.booking-profile-card {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d4d4d5;
  border-radius: 4px 4px 0px 0px;
  padding: 30px;
}
.right-col-wrap {
  padding: 30px;
}
.align-flex-end {
  align-items: flex-end;
}

/* ================ CALANDER =========== */
.appoinmentCalander {
  position: relative;
}
.appoinmentCalander .fc-header-toolbar {
  height: 0;
  margin: 0 !important;
}
.appoinmentCalander h2.fc-toolbar-title {
  display: none;
}
.appoinmentConfirmend {
  padding: 25px;
  text-align: center;
}

.appoinmentConfirmedContent img {
  width: 40px;
  margin-bottom: 10px;
}

p.appoinmentBoldTxt {
  font-size: 21px !important;
  font-weight: bold;
  color: #707070;
  margin-bottom: 25px !important;
}

.appoinmentConfirmedContent p {
  font-size: 18px;
  margin-bottom: 0;
}

.appoinmentBtnFlex i {
  margin-right: 5px;
}
a.fc-col-header-cell-cushion {
  font-size: 12px;
}

.fc-scroller {
  /* overflow: hidden !important; */
}

.fc-button-group .fc-next-button {
  right: -10%;
}

.fc-button-primary {
  /* background: none !important; */
  /* color: #333 !important; */
  border: none !important; /* width: 50px; */
}

.fc-button-primary .fc-icon {
  width: auto !important;
}

.fc-button-group .fc-prev-button {
  left: -10%;
}

.fc-button-group {
  position: inherit !important;
  z-index: 1;
}

.fc-view-harness {
  padding-bottom: 50% !important;
  z-index: 0;
  border-bottom: 1px solid #d4d4d5;
}

.fc .fc-timegrid-col.fc-day-today {
  background: none !important;
}

.fc-theme-standard td,
.fc-theme-standard th {
  border: 0px;
}

table.fc-scrollgrid.fc-scrollgrid-liquid {
  /* border: 0px; */
}

.fc-timeGridWeek-view thead .fc-scrollgrid-section td {
  border-top: 1px solid #d4d4d5 !important;
  border-bottom: 1px solid #d4d4d5 !important;
  padding: 5px 0;
}

.fc-button-group .fc-button {
  position: absolute !important;
  top: 2%;
  color: #5fc0e4 !important;
}
.avaliableText {
  color: #707070;
  text-align: center;
  font-size: 21px;
  font-family: "Lato", sans-serif;
}
.bookingCalanderContainer > .col-md-6 {
  padding-left: 15;
  padding-right: 0;
}
.row.bookingCalanderContainer {
  margin-top: 15px;
}
.seeMoreAvalibile {
  color: #5fc0e4;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  font-family: "Lato", sans-serif;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 0;
}
.hideOverflow {
  overflow: hidden auto !important;
}
.fc-button-group .fc-button:focus {
  box-shadow: none !important;
}
.fc .fc-scroller-harness-liquid {
  /* margin-top: 10px; */
}
.fc-timegrid-slots tr td:nth-child(odd) {
  /* display: none; */
}

.fc-scrollgrid-sync-inner {
  width: 50px;
  margin: auto;
}
.fc-event-main {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
}
.fc-v-event {
  border: none !important;
}
.available,
.unavailable {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2ecc71;
  padding: 0;
  border-radius: 3px;
  color: #fff;
}
.available {
  cursor: pointer;
}
.unavailable {
  background-color: #5fc0e4 !important;
  color: #333333 !important;
}
.fc-media-screen .fc-timegrid-event-harness {
  height: 26px;
}
/* ======== Book Time ============ */
#booktime {
  padding: 50px 0px;
}
#booktime h4 {
  color: #707070;
  font-weight: bold;
  margin-bottom: 50px;
}
.bpc-img-wrapper {
  text-align: center;
}
.bpc-img-wrapper img {
  border-radius: 50%;
  box-shadow: 0px 0px 5px #00000029;
  border: 3px solid #ffffff;
}
.hash-tags {
  font-size: 13px;
}
.chat-btn {
  border: 1px solid #60c2e7;
  border-radius: 4px;
  padding: 10px 30px;
  color: #60c2e7;
  font-weight: bold;
  font-size: 14px;
  background: transparent;
}
.update-btn {
  background: #60c2e7 0% 0% no-repeat padding-box;
  border-radius: 4px;
  color: #fff;
  padding: 10px 30px;
  border: none;
  font-weight: bold;
}
.booking-profile-card {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d4d4d5;
  border-radius: 4px 4px 0px 0px;
  padding: 20px;
}
.right-col-wrap {
  padding: 20px;
}
.align-flex-end {
  align-items: flex-end;
}
.profile-details-wrap p {
  margin: 0;
}
/* ======== Book Time ============ */
#sendRequestModal .modal-body {
  padding: 0;
}
.sr-title h3 {
  font-size: 18px;
  color: #333;
  text-transform: uppercase;
  border-bottom: 1px solid #707070;
  padding: 1rem;
  font-weight: bold;
}
.srModalContent {
  padding: 20px 40px 40px 40px;
}
.subjectDropdown {
  max-width: 180px;
}
.descriptionTextArea {
  max-width: 500px;
}
.datePicker {
  max-width: 170px;
  display: inline-block;
  margin-right: 20px;
}
.timePicker {
  max-width: 100px;
  display: inline-block;
}
.srCancel {
  background: #dfdfdf;
  color: #333;
  border: none;
  font-size: 14px;
  padding: 7.5px 20px;
  border-radius: 4px;
  font-weight: bold;
}
.srSend {
  background: #60c2e7;
  border: none;
  padding: 7.5px 20px;
  font-size: 14px;
  border-radius: 4px;
  font-weight: bold;
  color: #fff;
}
#sendRequestModal label {
  font-size: 13px;
  color: #333333de;
}

/* =========== STUDY GROUP ============ */
.studyGroupCard {
  margin: 0;
  margin-bottom: 15px !important;
}

.studyGroupCard img {
  width: 185px;
  height: 185px;
}

.studyGroupCard p.postEventName {
  font-size: 14px;
}

.studyGroupCard p.postEventTime {
  font-size: 14px;
}

.studyGroupCard .postLinkDescription p {
  font-size: 12px !important;
  padding: 10px;
}

.studyGroupCard .eventOrginzerDetails p {
  font-size: 10px;
}

.studyGroupCard .eventOrginzerDetails svg {
  font-size: 16px;
}

.studyGroupCard .eventOrginzerDetails p:nth-child(2) {
  margin-right: 0;
}

.studyGroupCard .linkInfo {
  padding: 10px 10px 0;
}

.studyGroupCard .eventOrginzerDetails {
  padding: 0 10px 10px;
}

.studyGroupCard p.postEventTime svg {
  font-size: 14px;
}
.studyGroupCard .joinNowBtn button {
  width: 85px;
  height: 30px;
  font-size: 14px;
}
.studyGroupCard .postLinkDescription {
  max-height: 92px;
  overflow: hidden;
}
.studyGroupSeeMoreBtn {
  padding: 0;
  text-align: center;
  margin: 30px 0;
}

.studyGroupSeeMoreBtn button {
  width: 105px;
  height: 36px;
}
.createStudyGroupBtn button {
  width: 238px;
  height: 48px;
  font-size: 16px;
  font-weight: 300;
}

.createStudyGroupBtn button i {
  font-size: 13px;
}

.createStudyGroupBtn {
  padding: 0;
  position: absolute;
  top: 4.5em;
  right: 0;
}

.studyTourWrapper .searchFriend {
  position: relative;
  padding-top: 30px;
  min-height: 200px;
}
.createStudyGroupModelTitle {
  letter-spacing: 0px;
  color: #333333;
  text-transform: uppercase;
  font-size: 18px !important;
  font-family: "Lato", sans-serif;
  padding-left: 15px !important;
}

.formBasicName {
  width: 291px;
}

.createStudyGroupModel .form-group label {
  font-size: 13px;
  color: #333333de;
  font-weight: bold;
  margin-bottom: 5px;
}

.createStudyGroupModel input,
.createStudyGroupModel textarea,
.createStudyGroupModel select {
  border: 1px solid #dededf;
  border-radius: 4px;
}

.formBasicSubject {
  width: 180px;
}

.createStudyGroupModel input,
.createStudyGroupModel select {
  height: 38px;
  font-size: 14px;
}

.formBasicDescription {
  width: 500px;
}

.createStudyGroupModel .form-group {
  margin-bottom: 15px;
}

.formBasicDate {
  width: 150px;
}

.formBasicHour {
  width: 70px;
  margin: 0 20px 15px;
}

.formBasicDuration {
  width: 86px;
}

.newStudyTourinviteParticipate .input-group-prepend .input-group-text {
  background: none;
  border-right: 0;
  color: #747576;
  padding-right: 0;
}
.newStudyTourinviteParticipate .input-group-append .input-group-text {
  background: none;
  border-left: 0;
  color: #747576;
}
.newStudyTourinviteParticipate input {
  border-left: 0;
  border-right: 0;
}

.newStudyTourinviteParticipate .individualBadge p {
  margin: 0 !important;
  margin-right: 10px !important;
}

.newStudyTourinviteParticipate .individualBadge {
  height: 30px;
  position: relative;
  top: -5px;
  left: 30px;
}

.newStudyTourinviteParticipate {
  align-items: center;
  height: 100%;
}

.formBasicCheckbox input {
  height: auto;
}

.formBasicMaxStudent select {
  width: 75px;
}

.createNewStudyTourModelfooter {
  border-top: 0 !important;
}

.createNewStudyTourModelfooter .btn-secondary {
  background: #dfdfdf 0% 0% no-repeat padding-box;
  width: 75px;
  margin-right: 65px;
}

.createNewStudyTourModelfooter .btn {
  border-radius: 4px;
  border: none;
  letter-spacing: 0px;
  color: #333333;
  font-weight: bold;
  font-size: 14px;
  height: 36px;
}

.createNewStudyTourModelfooter button.btn.btn-primary {
  color: #fff;
  background: #60c2e7;
}
.createStudyGroupModel {
  padding-left: 30px !important;
}
.formBasicinviteParticipate {
  margin-bottom: 0 !important;
}
.createStudyGroupModelWrapper .modal-header {
  border-bottom: 1px solid #707070;
}

/* ============== SELF REVOVERY SCREEN ========= */
.selfDiscoveryBannerContainer {
  height: 230px;
  margin-top: 20px;
  position: relative;
  border-radius: 4px;
  background-image: url(https://thewallpaper.co//wp-content/uploads/2016/10/amazing-forest-road-photos-desktop-images-background-photos-download-hd-windows-wallpaper-samsung-iphone-mac-2560x1600.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  margin-bottom: 30px;
}

.blackOverlay {
  background: #000;
  width: 100%;
  position: absolute;
  height: 100%;
  opacity: 0.7;
}

.selfDiscoveryBannerInfo {
  z-index: 1;
}

.selfDiscoveryBannerInfo h1 {
  font-family: "nunitoRegular", sans-serif;
  font-weight: 900;
  text-transform: uppercase;
  font-size: 38px;
  color: #fff;
}

.selfDiscoveryBannerTab {
  justify-content: space-around;
}

.selfDiscoveryBannerTab p {
  margin-bottom: 0;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  font-family: "nunitoRegular", sans-serif;
  padding: 0 5px;
}

.selfDiscoveryBannerContainer .blackOverlay {
  border-radius: 4px;
}

.selfDiscoveryPersonal.selfDiscoveryActiveTab {
  border-bottom: 6px solid #2ecc71;
}

.selfDiscoveryAcademic.selfDiscoveryActiveTab {
  border-bottom: 6px solid #9b59b6;
}

.selfDiscoveryCareer.selfDiscoveryActiveTab {
  border-bottom: 6px solid #3498db;
}
.selfDiscoveryCard {
  width: 90%;
  max-height: 250px;
  padding: 25px;
  margin: -10px auto;
}
.selfDiscoveryQuestionTypeSelect {
  margin-right: 15px;
}
h5.selfDiscoveryQuestion {
  color: #333333;
  font-size: 13px;
  font-weight: bold;
  font-family: "nunitoRegular", sans-serif;
  margin-bottom: 30px;
}

.selfDiscoveryQuestionAnsInput input {
  border: 1px solid #dededf;
  border-radius: 4px;
  border-right: 0;
  font-size: 14px;
}

.selfDiscoveryContent {
  display: flex;
}

.selfDiscoveryContinueBtn {
  flex-basis: 20%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.selfDiscoveryContent > div:nth-child(1) {
  flex: 1 1;
  max-height: 150px;
  overflow: hidden auto;
}

.selfDiscoveryQuestionAnsInput .input-group-text {
  background: transparent;
  border-left: 0;
}

.selfDiscoveryQuestionAnsInput .input-group {
  margin-bottom: 15px !important;
  width: 250px;
}

.selfDiscoveryQuestionAnsInput .input-group:last-child {
  margin-bottom: 0 !important;
}

.selfDiscoveryContinueBtn button {
  width: 85px;
  height: 36px;
  padding: 0 !important;
  font-weight: bold;
  font-family: "Lato", sans-serif;
}

.selfDiscoveryQuestionTypeInput .input-group {
  width: 250px;
  margin-bottom: 0 !important;
}

.selfDiscoveryQuestionTypeInput input {
  border: 1px solid #dededf;
  border-radius: 4px;
}

.selfDiscoveryBadge p {
  margin-bottom: 0;
  color: #fff;
  font-weight: bold;
  font-family: "Lato", sans-serif;
  font-size: 14px;
}

.selfDiscoveryBadge {
  padding: 9px 15px;
  display: inline-block;
  background: #b3b3b3 0% 0% no-repeat padding-box;
  border-radius: 4px;
  margin-bottom: 15px;
  margin-right: 15px;
  cursor: pointer;
}
.roadImg .borderJoin {
  border-right: 6px solid;
  margin: 0;
  position: absolute;
  z-index: -1;
  height: 100%;
  top: 20%;
  left: 10.5%;
}
.roadImg img {
  width: 100%;
}
.roadImg .borderJoin.personal {
  border-color: #2ecc71;
}
.roadImg .borderJoin.academic {
  border-color: #9b59b6;
}
.roadImg .borderJoin.career {
  border-color: #3498db;
}
.roadImg.personal:after {
  background: #2ecc71;
}
.roadImg.academic:after {
  background: #9b59b6;
}
.roadImg.career:after {
  background: #3498db;
}
.roadImg {
  position: relative;
  padding-bottom: 50px;
}

.roadImg:after {
  content: "";
  width: 20px;
  height: 20px;
  background: #000;
  position: absolute;
  left: 9.2%;
  bottom: 0;
  border-radius: 50%;
}

/* ================ CALL SCREEN =============== */
.upcomingCallWrapper h2:nth-child(1) {
  font-weight: bold;
  font-size: 21.0939px;
  line-height: 26px;
  margin-top: 40px;
  margin-bottom: 50px;
  color: #5a5a5a;
}

.upcomingCallCardWrapper {
  background: #ffffff;
  box-shadow: 0px 0px 9.04026px rgba(0, 0, 0, 0.25);
  border-radius: 3.01342px;
  padding: 25px 20px;
  margin-bottom: 10px;
  position: relative;
}

.upcomingCallCardHeader {
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.upcomingCallType p {
  font-weight: bold;
  font-size: 24px;
  line-height: 26px;
  color: #5a5a5a;
  margin-bottom: 0;
}

.upcomingCallMoreIcon svg {
  font-size: 30px;
  color: #5a5a5a;
}

.upcomingCallCardDateInfo svg {
  font-size: 16px;
  color: #b3b3b3;
}

.upcomingCallDate p {
  font-weight: bold;
}

.upcomingCallCardDateInfo p {
  margin-bottom: 0;
  font-size: 12px;
  line-height: 29px;
  color: #5a5a5a;
  margin-left: 5px;
}

.upcomingCallCardDateInfo div {
  align-items: center;
}

.upcomingCallDate {
  margin-right: 15px;
}
.upcomingFullwidthBtn button {
  width: 100% !important;
}
.callUserInfo img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #3dc2ec;
}

.callUserInfo p {
  font-size: 10px;
  line-height: 26px;
  text-align: center;
  margin-bottom: 0;
}

.callUserInfo {
  width: 40px;
}

.groupCallUserInfoWrapper {
  display: flex;
  align-items: flex-start;
}

.groupCallUserInfoWrapper .callUserInfo {
  margin-right: 10px;
}

.viewMoreUsers {
  width: 40px;
  height: 40px;
  background: #3dc2ec;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}

.viewMoreUsers svg {
  color: #fff;
  font-size: 18px !important;
  line-height: 29px;
}

.upcomingCallCardFooter {
  padding: 0;
  justify-content: space-between;
  align-items: center;
}

.upcomingCallCardBody {
  padding: 25px 0 30px;
}
.upcomingCallCardFooter button {
  width: 72px;
  height: 27px;
  font-weight: bold;
  font-size: 16px !important;
  line-height: 8px;
  background: rgba(96, 194, 231, 0.5);
  border: 1px solid rgba(96, 194, 231, 0.5) !important;
}
.yourFriedsMainWrapper {
  background: #ffffff;
  box-shadow: 0px 3.01342px 6.02684px rgba(0, 0, 0, 0.1);
  border-radius: 3.01342px;
  margin-top: 60px;
  margin-bottom: 60px;
  padding-bottom: 35px;
}

.yourFriendsSimpleToolbar {
  border-bottom: 0.753355px solid #c4c4c4;
  align-items: center;
  justify-content: space-between;
  padding: 25px 50px;
  margin-bottom: 50px;
}

.yourFriendsSimpleToolbar h2 {
  font-weight: bold;
  font-size: 13.5604px;
  line-height: 26px;
  text-transform: uppercase;
  color: #a3a3a3;
  margin-bottom: 0;
}

.yourFriendsSimpleToolbar button {
  width: 120px;
  font-weight: 500;
  font-size: 10.547px !important;
  line-height: 24px;
  height: 27px;
  display: grid;
  place-content: center;
}

.yourFriendsSimpleToolbar .connectBtn {
  padding: 0;
}

.yourFriendSeemoreBtn {
  padding: 0;
  text-align: center;
}

.yourFriendSeemoreBtn button {
  width: 80px;
  height: 27px;
  font-weight: 500;
  font-size: 10.547px !important;
  line-height: 24px;
  display: grid;
  place-content: center;
  margin: auto;
}
.yourFriendInfoWraper {
  padding: 0 50px 30px;
}

.bookpeerSessionCardWrapper {
  box-shadow: 0px 0px 9.04026px rgba(0, 0, 0, 0.25);
  border-radius: 3.01342px;
}

.bookpeerSessionCardWrapper .profilePhoto img {
  box-shadow: 0px 3.01342px 3.01342px rgba(0, 0, 0, 0.25);
}

.bookpeerSessionCardWrapper .networkDetails h3 {
  font-weight: bold;
  font-size: 15.0671px;
  line-height: 21px;
  color: #000000;
}

.bookpeerSessionCardWrapper .connectBtn.findMatchCardBtn button:nth-child(1) {
  margin-right: 5px;
}

.bookpeerSessionCardWrapper .connectBtn.findMatchCardBtn button {
  font-size: 12px !important;
  font-weight: normal;
}
/* .callCardWrapper {
  height: 305px;
}
.callCardHeader {
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  border-bottom: 1px solid #dededf;
}

.callCardWrapper p {
  margin-bottom: 0;
}

.upcomingCallDate p {
  font-size: 18px;
  font-weight: bold;
}

.upcomingCallCardUserInfo strong {
  margin-right: 5px;
}

.upcomingCallTime p {
  font-size: 18px;
  text-transform: uppercase;
}

.upcomingCallCardBody {
  padding: 10px 15px;
}

.upcomingCallCardType {
  align-items: center;
  justify-content: space-between;
}

.upcomingCallType {
  font-size: 26px;
  font-weight: 600;
  flex: 1;
}

.upcomingCallEditBtn a.btn-peer {
  padding: 5px 15px;
  font-size: 18px;
}

.upcomingCallCardTypeIcon svg {
  font-size: 45px;
  margin-left: 10px;
}

.upcomingCallCardUserInfo h2 {font-size: 16px;font-weight: 500;}

.upcomingCallCardUserInfo {
  height: 120px;
  padding-top: 15px;
}

.upcomingCallCardFooter button {width: 100%;padding: 7px 10px;}

.upcomingCallCardFooter div {
  flex-basis: 49%;
}

.upcomingCallWrapper > h2:nth-child(1) {
  font-size: 26px;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 15px;
}

.upcomingCallWrapper {
  padding-top: 50px;
} */
.bookpeerSessionCardWrapper {
  margin-bottom: 15px;
}

.callTabMainWrapper {
  padding-top: 50px;
}

.callTabMainWrapper .tab-content {
  margin-top: 25px;
}

.callTabMainWrapper nav a {
  font-size: 24px;
  color: #000;
  font-weight: bold;
}
.groupSessionCard {
  padding: 20px;
}

.groupSessionCard .scheduleBtn {
  padding-bottom: 0;
}

.groupSessionCard h2 {
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 20px;
}

.groupSessionCard p {
  font-size: 16px;
  height: 120px;
  margin-bottom: 0;
}

.groupSessionCard .scheduleBtn button {
  width: 100%;
  font-size: 18px !important;
  text-transform: uppercase;
}
.bookGroupSessionTitleWrappper h2 {
  font-size: 28px;
  font-weight: bold;
}

.bookGroupSessionWrapper > .row:nth-child(1) {
  align-items: center;
  margin-bottom: 30px;
}

.bookGroupSessionBtnWrapper {
  text-align: right;
  padding: 0;
}

.bookGroupSessionBtnWrapper button {
  font-size: 18px !important;
  text-transform: uppercase;
  padding: 10px 15px;
}
.bookaCallMainWrapper {
}

.bookaCallMainWrapper .modal-title {
  margin: auto;
}
.bookaCallMainWrapper .modal-header .close {
  margin: 0;
  padding: 0;
  font-size: 26px !important;
}

.bookaCallMainWrapper .modal-header .close:nth-child(1) {
  font-size: 7px;
}

.bookaCallMainWrapper .modal-header {
  border-bottom: 0;
}

.bookaCallMainWrapper label.form-label {
  font-weight: bold;
  font-size: 21.0939px;
  line-height: 26px;
  color: #5a5a5a;
  margin-bottom: 5px;
}

.bookaCallMainWrapper label[for="meetingDuration"] {
  display: block;
}

.formButtonGroup button {
  border-radius: 0 !important;
  color: #a3a3a3;
  font-weight: bold;
  font-size: 21.0939px !important;
  line-height: 26px;
  background: #f3f3f3;
  border: 0;
  border-bottom: 2px solid #3dc2ec;
  margin-right: 2px;
  width: 38px;
  height: 47px;
  padding: 0;
}

.bookaCallMainWrapper textarea {
  resize: none;
  height: 150px !important;
}
.bookaCallMainWrapper .form-group {
  margin-bottom: 0;
}
.requestMeetingBtn button {
  width: 100%;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 18px !important;
  line-height: 22px;
  text-align: center;
  height: 40px;
  margin-bottom: 0 !important;
}
.bookaCallMainWrapper .modal-dialog {
  max-width: 670px;
}

.bookaCallMainWrapper .modal-body {
  padding: 25px 45px;
}

.bookaCallMainWrapper [class*="col-"] {
  margin-bottom: 50px;
}
.bookaCallTitle h2 {
  font-weight: bold;
  font-size: 21.0939px;
  line-height: 26px;
  color: #5a5a5a;
  text-align: center;
}

.bookaCallTitle h2 span {
  color: #3dc2ec;
  margin-left: 5px;
}

.bookaCallTitle {
  padding-top: 70px;
  padding-bottom: 25px;
}

.bookaCallFormWrapper {
  background: #ffffff;
  box-shadow: 0px 3.01342px 6.02684px rgba(0, 0, 0, 0.1);
  border-radius: 3.01342px;
  padding: 40px 50px;
  max-width: 750px;
  margin: auto;
}
.bookaCallMainWrapper textarea,
.bookaCallMainWrapper input,
.bookaCallMainWrapper select {
  background: #f3f3f3;
  border: 0;
  border-bottom: 2px solid #3dc2ec;
}
.activeBtnSelect {
  background-color: #3dc2ec !important;
  color: #fff !important;
}
.bookaCallFormRadioBtn {
  display: flex;
}

.bookaCallFormRadioBtn .form-check {
  margin-right: 15px;
  padding-left: 0;
}

.bookaCallFormRadioBtn .form-check label {
  font-weight: bold;
  font-size: 21.0939px;
  line-height: 26px;
  color: #a3a3a3;
}
.bookaCallFormRadioBtn .form-check [type="radio"]:checked,
.bookaCallFormRadioBtn .form-check [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.bookaCallFormRadioBtn .form-check [type="radio"]:checked + label,
.bookaCallFormRadioBtn .form-check [type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
}
.bookaCallFormRadioBtn .form-check [type="radio"]:checked + label:before,
.bookaCallFormRadioBtn .form-check [type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 19px;
  height: 19px;
  border: 2px solid #000000;
  border-radius: 50%;
  background: #fff;
}
.bookaCallFormRadioBtn .form-check [type="radio"]:checked + label:after,
.bookaCallFormRadioBtn .form-check [type="radio"]:not(:checked) + label:after {
  content: "";
  width: 7px;
  height: 7px;
  background: #000;
  position: absolute;
  top: 6px;
  left: 6px;
  border-radius: 100%;
  transition: all 0.2s ease;
}
.bookaCallFormRadioBtn .form-check [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
.bookaCallFormRadioBtn .form-check [type="radio"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}
.bookaCallMainWrapper input {
  height: 47px;
}
.requestMeetingBtn {
  padding: 0 !important;
}
.bookaCallMainWrapper {
  padding-bottom: 50px;
}
.formButtonGroup button:hover {
  background: #3dc2ec;
  border-bottom: 2px solid #3dc2ec;
}
.requesSuccessWrapper h2 {
  font-weight: bold;
  font-size: 21.0939px;
  line-height: 26px;
  color: #00d26d;
  text-align: center;
  margin-bottom: 20px;
}

.requestSuccessMycallBtn {
  text-align: center;
  margin-top: 30px;
}

.requesSuccessWrapper {
  padding-top: 80px;
}

.requestSuccessMycallBtn button {
  width: 120px;
}
.yourFriendInfoWraper .nav {
  margin-bottom: 30px;
}
.yourFriendInfoWraper .nav a {
  color: #495057;
}
.yourFriendInfoWraper .nav a.active {
  color: #60c2e7;
}
.bookaCallFormWrapper .form-check input {
  height: auto;
}
.optionsToggle ul {
  list-style: none;
  background: #fff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  padding: 10px;
  margin-bottom: 0;
}
.optionsToggle {
  position: absolute;
  top: 60px;
  right: 30px;
  width: 100px;
  z-index: 10;
}
.optionsToggle ul li {
  color: #5a5a5a;
}
.optionsToggle:before {
  /* width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 0px solid transparent;
  border-bottom: 10px solid red;
  position: absolute;
  content: "";
  top: 52px;
  right: 48px; */
}
.options-edit svg {
  color: #60c2e7;
  font-size: 16px;
  margin-right: 10px;
}
.optionsToggle ul li {
  color: #5a5a5a;
}
.options-cancel svg {
  color: #ff0000;
  font-size: 16px;
  margin-right: 10px;
}
.upcomingCallMoreIcon svg,
.upcomingCallMoreIcon li {
  cursor: pointer;
}
.upcomingCallMoreIcon {
  position: relative;
}
.upcomingCallMoreIcon svg,
.upcomingCallMoreIcon li {
  cursor: pointer;
}
/* .requestMeetingBtn {
  padding: 0 !important;
}
.requestSuccess {
  width: 75%;
  margin: auto;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 30px;
}

.requestSuccess svg {
  font-size: 75px;
  color: green;
}

h3.requestSuccessTitle {
  font-size: 24px;
  font-weight: bold;
  color: green;
  margin: 25px 0;
}

p.requestGroupInfo {
  font-size: 16px !important;
  margin-bottom: 40px;
}

p.requestSuccessDate, .requestSuccessTime {
  font-size: 26px;
  font-weight: bold;
}

p.requestSuccessDate {
  margin-bottom: 10px;
}

.requestSuccessEditBtn {
  padding: 0;
  text-align: right;
}

.requestSuccessMycallBtn button {
  width: 60%;
  font-size: 18px !important;
}

.requestSuccessMycallBtn {
  text-align: center;
  padding: 30px 0!important;
} */
/* .swiper-container {
  width: 100%;
} */

/* ==================== MY SOCIAL SCREEN ============ */
.mySocialTabWrapper {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  padding: 25px 25px 50px;
  margin-top: 30px;
}

.mySocialTabWrapper nav a {
  color: #33333380;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
}

.mySocialTabWrapper nav .nav-link.active {
  color: #333;
  border-bottom-width: 3px;
  border-color: #fff #fff #64c2e5;
}

.mySocialTabWrapper nav .nav-link:hover,
.mySocialTabWrapper nav .nav-link:focus {
  border-color: #fff #fff #64c2e5;
  border-bottom-width: 3px;
}

.mySocialTabWrapper nav.nav-tabs {
  border-bottom: 1px solid #dededfbf;
  margin-bottom: 20px;
}

.sidebarMenu .nav-item a {
  font-size: 16px;
  color: #8d8d8d;
  min-height: 47px;
  display: flex;
  align-items: center;
}

.sidebarMenu .nav-link.active {
  background: #f2f2f2 !important;
  color: #000 !important;
  font-weight: bold;
  border-bottom: 0;
  border-left: 3px solid #60c2e7;
  border-radius: 0;
}

.sidebarWrapper select {
  border: 1px solid #dededf;
  border-radius: 4px;
}

.boldLabel {
  font-size: 16px;
  font-weight: 600;
}

.noinvitationLabel {
  font-size: 14px;

  margin-left: 12px;
}

.sidebarWrapper .nav-pills {
  margin-bottom: 30px;
}

.popularTopicWrapper .slick-slide > div {
  margin: 0 5px;
}
.socialHeading {
  font-size: 20px;
  font-weight: bold;
  color: #333333;
  text-transform: capitalize;
  margin-bottom: 15px;
}
.newQuestionWrapper {
  box-shadow: 5px 5px 6px #0000000d;
  border: 1px solid #dededfbf;
  border-radius: 4px;
  margin-top: 21px;
}

.postQuestionField img {
  width: 52px;
  height: 52px;
  box-shadow: 0px 0px 5px #00000029;
  border: 3px solid #ffffff;
  border-radius: 100%;
  margin-right: 30px;
}

.postQuestionField textarea {
  border: none;
  font-size: 14px;
  resize: none;
}
.postQuestionField textarea:-ms-input-placeholder {
  font-size: 17px;
  color: #999999;
  font-weight: bold;
  position: relative;
  top: 5px;
}
.postQuestionField textarea::placeholder {
  font-size: 17px;
  color: #999999;
  font-weight: bold;
  position: relative;
  top: 5px;
}
.postQuestionField {
  padding: 20px 20px 10px;
  border-bottom: 1px solid #dededfbf;
  align-items: center;
}

.postQuestionField textarea:focus {
  border-color: inherit;
  box-shadow: none;
}

/* .formPostMoreOption {
  align-items: center;
  justify-content: space-between;
  padding: 25px 20px;
  color: #d9d9d9;
}

.formPostMoreOption .flexDefault:nth-child(1) svg {
  margin-right: 20px;
  cursor: pointer;
}

.formPostMoreOption .flexDefault:nth-child(2) > svg {
  margin-right: 10px;
  cursor: pointer;
}

.formPostMoreOption .flexDefault:nth-child(2) > div {
  width: 40px;
  height: 40px;
  background: #60c2e7;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 100%;
  cursor: pointer;
}

.formPostMoreOption .flexDefault:nth-child(2) {
  align-items: center;
} */
.topQuestionsMainWrapper {
  margin-top: 40px;
}

.topQuestionsMainWrapper > .row {
  padding-bottom: 50px;
}

#test > div.row {
  margin-bottom: 50px;
}

.filterTabMenu {
  border: 1px solid #dededf;
  border-radius: 0px 3px 4px 0px;
  width: max-content;
  margin-left: auto;
}

.filterTabMenu .nav-item {
  border-right: 1px solid #dededf;
}

.filterTabMenu .nav-link.active {
  background: #60c2e7 !important;
  border-radius: 0;
}
.filterTabMenu .nav-link {
  color: #60c2e7;
}
.postedQuestionUserInfo img {
  box-shadow: 0px 0px 5px #00000029;
  border: 3px solid #ffffff;
  border-radius: 100%;
  width: 90px;
  height: 90px;
}

.postedQuestion {
  flex: 1 1;
}

.postedQuestionUserInfo {
  margin-right: 21px;
  display: flex;
  align-items: center;
}

.postedQuestion > div > p {
  color: #333333;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  word-break: break-all;
}

.answersLikeViews p {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0;
  margin-right: 30px;
}

.answersLikeViews svg {
  font-size: 24px;
  margin-right: 8px;
}

.answersLikeViews {
  margin-bottom: 15px;
}

p.answersWrapper {
  color: #db2828;
}

p.votesWrapper {
  color: #21ba45;
}

p.viewsWrapper {
  color: #60c2e7;
}

.questiontag {
  border-radius: 16px;
  padding: 7px 15px;
  background: #f3f5f9;
  margin-right: 10px;
  font-size: 11px;
  color: #242134;
  max-width: max-content;
}

.questiontag p {
  margin-bottom: 0;
}

.postedQuestionAskedTime {
  color: #d9d9d9;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: flex-end;
}

.individualQuestion {
  padding-bottom: 20px;
  border-bottom: 1px solid #dededfbf;
  margin-bottom: 25px;
}
.topQuestionsMainWrapper h3.socialHeading {
  margin-bottom: 0;
  margin-top: 10px;
}
.individualQuestion:last-child {
  border-bottom: 0;
}

.filterTabMenu .nav-item:last-child {
  border-right: 0;
}
/* -============== TAGS TAB IN MY SOCIAL SCREEN =============== */
.tagsBannerWrapper p {
  color: #8d8d8d;
  font-size: 15px;
  font-weight: 300;
  margin-bottom: 0;
}

.tagsBannerWrapper {
  margin-bottom: 40px;
}

.tagsBannerWrapper h3.socialHeading {
  margin-bottom: 5px;
}

.filterRow .input-group-prepend span {
  border: none;
  border-right: 0px;
  padding: 0.375rem 10px;
  border-radius: 20px;
  background: #f2f2f2;
}

.filterRow input.form-control {
  border-left: 0;
  border-radius: 20px;
  background: #f2f2f2;
  border: none;
}

.filterRow .input-group {
  width: 254px;
}
.filterRow {
  margin-bottom: 50px;
}

.tagCardWrapper {
  border: 1px solid #ececec;
  border-radius: 8px;
  padding: 10px 15px;
  margin-bottom: 15px;
}

.subjectTagsDecription {
  font-size: 12px;
  color: #242134;
  margin-top: 15px;
  margin-bottom: 30px;
}

.QuestionsAskedCount {
  color: #dbdbdb;
  font-weight: bold;
  font-size: 14px;
  align-items: center;
  justify-content: space-between;
}

/* =============== MY SOCIAL SCREEN USERS TAB ============== */
.forumUserImg img {
  box-shadow: 0px 0px 5px #00000029;
  border: 3px solid #ffffff;
  width: 58px;
  height: 58px;
  border-radius: 100%;
}

.userTag {
  border: 1px solid #f7f7f7;
  border-radius: 4px;
  padding: 15px 10px;
  align-items: center;
  margin-bottom: 15px;
}

.forumUserImg {
  flex-basis: 65px;
  margin-right: 15px;
}

p.forumUserName {
  color: #333333;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0;
}

p.forumUserLocation {
  color: #b3b3b3;
  font-size: 15px;
  margin-bottom: 0;
}

p.forumUserQuestionsCount {
  color: #b3b3b3;
  font-size: 15px;
  margin-bottom: 0;
}

p.forumUserQuestionsTags {
  color: #60c2e7;
  font-size: 15px;
  margin-bottom: 0;
}
.yuukkePagination {
  margin-bottom: 0;
  float: right;
  margin-top: 50px;
}

.yuukkePagination a.page-link {
  border-radius: 6px;
  background: #f8f9fa;
  padding: 0;
  width: 33px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #353344;
  font-size: 14px;
  border: none;
  margin-right: 5px;
}

.yuukkePagination li.active .page-link {
  background: #64c2e5;
  border: none;
  width: 33px;
  height: 33px;
  border-radius: 6px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.yuukkePagination a.page-link:focus {
  box-shadow: none;
}

/* ============ MY QUESTIONS IN MY SOCIAL SCREEN ========= */
.questionAnswerWrapper .question {
  color: #333333;
  font-size: 18px;
  font-weight: bold;
  word-break: break-all;
}
.questionAnswerWrapper ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.questionAnswerWrapper ul li {
  display: inline-block;
  margin-right: 10px;
}
.questionAnswerWrapper {
  margin-bottom: 30px;
}
.questionUserDetailsWrapper .chatImageWrapper img {
  max-width: 60px;
}
.questionUserDetailsWrapper .chatImageWrapper {
  margin: 0 15px 0 0;
}
.questionUserDetailsWrapper {
  margin-top: 10px;
}
.replyCounts {
  color: #5b5b5b;
  font-size: 14px;
  border-bottom: 1px solid #ececec;
  padding: 20px 0px;
  margin-bottom: 20px;
}
.ansObjWrapper .chatImageWrapper img {
  max-width: 60px;
}
.ansObjWrapper .chatImageWrapper {
  margin: 0 15px 0 0;
}
.ansObjWrapperOuter {
  background: #f5f5f5;
  margin-left: 20px;
  padding: 20px 20px 0px;
}
.ansObjWrapperOuter small {
  color: #b3b3b3 !important;
}
.ansObjWrapper {
  padding-bottom: 20px;
}
.answerUser {
  color: #333333;
  margin: 20px 0px;
  word-break: break-all;
}
.lfvWrapper {
  color: #5b5b5b;
  font-size: 16px;
}
.lfvWrapper svg {
  font-size: 16px;
  margin-right: 5px;
}
.lfvLike .active {
  color: #fd2928;
}
.lfvWrapper > div {
  margin-right: 10px;
  cursor: pointer;
}
.questionUserDetails small {
  color: #b3b3b3 !important;
}

/* ============ STICKY CHAT IN MAIN SCREEN ========= */
.stickyChatContainer {
  position: fixed;
  right: 0;
  color: #333;
  z-index: 1029;
  /* height: calc(100vh - 96px); */
  height: 100vh;
  background: #fff;
  width: 60px;
  top: 0px;
  box-shadow: 0px 0 10px 0 rgba(0, 0, 0, 0.1);
}
.chatImageWrapperouter {
  position: relative;
  top: 96px;
}
.chatImageWrapper {
  border-radius: 50% !important;
  position: relative !important;
  padding: 3px !important;
  background: #fff !important;
  box-shadow: 0px 0 3px 0 rgba(0, 0, 0, 0.1) !important;
  margin: 20px 5px 0 !important;
  cursor: pointer !important;
}
.chatImageWrapper .online:before {
  position: absolute;
  content: "";
  background: #6ad148;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  top: 0;
  right: 5px;
}
.chatImageWrapper .offline:before {
  position: absolute;
  content: "";
  background: #c63928;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  top: 0;
  right: 5px;
}
.chatImageWrapper .away:before {
  position: absolute;
  content: "";
  background: #efc742;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  top: 0;
  right: 5px;
}
.chatImageWrapper img {
  border-radius: 50%;
  overflow: hidden;
}
/* ==================== FOR MOBILE ============= */
/* @media (min-width: 1300px) {
  #root .container {
    max-width: 1410px;
  }
} */
/* @media screen and (min-width: 640px) {
  .swiper-container {
    width: 640px;
  }
}

@media screen and (min-width: 768px) {
  .swiper-container {
    width: 768px;
  }
}
@media screen and (min-width: 992px) {
  .swiper-container {
    width: 992px;
  }
} */
@media (min-width: 1440px) {
  /* ============= SELFDISCOVERY SCREEN =============== */
  .roadImg:after {
    left: 9.5%;
  }
}
@media (max-width: 768px) {
  .subjectDropdown {
    max-width: 100%;
  }
  .descriptionTextArea {
    max-width: 100%;
  }
  .descriptionTextArea {
    max-width: 100%;
  }
  .datePicker {
    max-width: 100%;
    display: block;
    margin-right: 0px;
  }
  .timePicker {
    max-width: 100%;
    display: block;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  #root .container {
    max-width: 992px;
  }
  /* COMMUNITY SCREEN */
  .newPostUpload p {
    font-size: 12px;
  }

  .newPostUpload svg {
    font-size: 21px;
  }

  .newPostUploadVideo svg {
    font-size: 27px;
  }

  p.invitationUserName {
    font-size: 11px;
  }

  .invitationUserInfo p {
    font-size: 9px;
  }

  .invitationUserInfoWrapper .userImg img {
    width: 50px;
    height: 50px;
  }

  .invitationConnect button {
    display: flex;
    align-items: center;
  }
  /* STUDY GROUP SCREEN */
  .studyGroupCard p.postEventName {
    font-size: 12px;
  }

  .studyGroupCard p.postEventTime {
    font-size: 11px;
  }

  .studyGroupCard .postLinkDescription p {
    font-size: 11px !important;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  /* .flexDefault {
    display: block;
  } */
  /* COMMUNITY SCREEN */
  .newPostUpload p {
    font-size: 12px;
  }

  .newPostUpload svg {
    font-size: 21px;
  }

  .newPostUploadVideo svg {
    font-size: 27px;
  }

  /* ============ CALL SCREEN ========= */
  .callTabMainWrapper nav a {
    width: 100%;
  }
  .bookGroupSessionBtnWrapper button {
    font-size: 14px !important;
  }
  .requestSuccess {
    width: 100%;
  }
  .requestSuccessEditBtn {
    text-align: left;
  }
  /* ==================== MY SOCIAL SCREEN =========== */
  .filterTabMenu {
    margin-left: 0;
    margin-top: 15px;
  }

  .postedQuestion .questiontag {
    margin-bottom: 5px;
  }
  .sidebarWrapper {
    margin-top: 30px;
  }
  .borderLeftCol {
    border-left: none;
  }
  .yuukkePagination {
    float: left;
  }
  .filterRow .input-group {
    flex-wrap: nowrap;
  }
  /* ============= CHAT ============ */
  .stickyChatContainer {
    display: none;
  }
}

@media (min-width: 0) and (max-width: 378px) {
  /* COMMUNITY SCREEN */
  .likesInfo p {
    font-size: 11px;
  }

  p.postEventName {
    font-size: 14px;
  }

  p.postEventTime {
    font-size: 11px;
  }

  .eventOrginzerDetails p {
    font-size: 12px;
  }
  .yuukkePagination {
    float: left;
  }
  /* =============== MY SOCIAL SCREEN ============== */
  .mySocialTabWrapper nav .nav-link {
    display: block;
    width: 100%;
  }
}

/* ============= YUUKKE TUTORING =============== */
.peerTutoringRootContainer {
  padding: 50px 0px;
}

.peerTutoringRootContainer .slick-slide > div {
  margin: 0 5px;
}
.peerTutoringSliderWrapper
  > div
  > div
  > div.slick-list
  > div
  > div.slick-slide.slick-active.slick-current
  > div {
  margin-left: 0;
}
.peerTutoringRootContainer h2 {
  text-transform: uppercase;
  color: #333;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 30px;
}
.peerTutoringSliderWrapper {
  margin-bottom: 50px;
}
/* ============= UPCOMING CALLS CARD =============== */
.upcomingCalls {
  background: #fff;
  color: #333;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  margin: 10px;
}
.isUpcomingCallActive {
  background: #60c2e7;
  color: #fff;
}
.upcominingCalls_imgWrapper .chatImageWrapper {
  margin: 0;
  max-width: 60px;
  width: 52px;
  height: 52px;
}
.isUpcomingActiveText {
  color: #fff !important;
}
.upcomingCalls__nameWrapper {
  margin-right: 15px;
}
.upcomingCalls__nameWrapper p {
  min-height: 48px;
}
.upcomingCalls__nameWrapper h3 {
  font-size: 20px;
  font-weight: bold;
}
.upcomingCalls__nameWrapper p {
  margin: 0;
}
.upcomingCalls small {
  opacity: 0.6;
}
.upcomingCalls__Duration ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.upcomingCalls__Duration ul li {
  display: inline-block;
}
.upcomingCalls__Duration ul {
  margin: 10px 0px;
  padding: 0;
  list-style: none;
}
.upcomingCalls__Duration ul li {
  display: inline-block;
}
.upcomingCalls__Duration ul li svg {
  margin-right: 10px;
  width: 12px;
  height: 12px;
}
.upcomingCalls__Cta button {
  width: 48%;
  text-transform: uppercase;
  font-size: 12px !important;
  font-weight: bold;
  margin-top: 20px;
}
.upcomingCalls__Start {
  background: #fff;
  color: #60c2e7;
  text-transform: uppercase;
  font-size: 12px !important;
  font-weight: bold;
}
.upcomingCalls__Cta {
  display: flex;
  justify-content: space-between;
}
.upcomingCalls__Cancel {
  box-shadow: 0 0 0 1px #fff;
}
.peerTutoringTabsWrapper {
  background: #fff;
  padding: 20px;
}
.upcomingCalls__nameWrapper svg {
  width: 12px;
  height: 12px;
  cursor: pointer;
}
/* .MuiAvatarGroup-avatar {
  box-shadow: 0px 0px 5px #00000029;
  border: 2px solid #ffffff;
  width: 34px;
  height: 34px;
  margin-left: -15px !important;
}
.MuiAvatarGroup-root div:nth-child(1) {
  z-index: 4 !important;
}

.MuiAvatarGroup-root div:nth-child(2) {
  z-index: 5 !important;
}

.MuiAvatarGroup-root div:nth-child(3) {
  z-index: 6 !important;
  font-size: 13px;
  font-weight: bold;
  background: #60c2e7;
} */
/* FRIENDS CARD */
.friendsCardWrapper {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 30px;
}
.friendsUserDetails {
  padding: 20px 15px;
}
.profileImgWrapper img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px solid #fff;
  object-fit: cover;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}
.coverImgWrapper {
  position: relative;
}
.profileImgWrapper {
  position: absolute;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
}
.friendsUserDetails p {
  margin: 0;
  padding-top: 50px;
  font-size: 20px;
  font-weight: bold;
}
.friendsUserDetails p {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden !important;
}
.friendsUserDetails small {
  opacity: 0.5;
}
.friendsUserDetails button {
  font-size: 11px !important;
  font-weight: bold;
  margin-top: 10px;
  display: flex;
  align-items: center;
  height: 26px;
  width: auto;
  justify-content: center;
}
button.fcwCallBooked {
  padding: 0 5px;
  margin-right: 10px;
}
.friendsUserDetails button svg {
  margin-right: 5px;
  width: 15px;
  height: 15px;
}
.friendsUserDetails .fcwChat {
  background: transparent;
  color: #60c2e7;
}
.friendsTabWrapper {
  padding: 20px 0 0;
}
.peerTutoringTabsWrapper nav a {
  border: none !important;
}
.peerTutoringTabsWrapper nav a {
  text-transform: uppercase;
  color: #333;
  opacity: 0.5;
  font-weight: bold;
}
.peerTutoringTabsWrapper nav .active {
  border-bottom: 2px solid #60c2e7 !important;
  opacity: 1;
}
.ftwGroupCallCta {
  margin-bottom: 20px;
  text-align: right;
  text-transform: uppercase;
  color: #60c2e7;
  font-weight: bold;
  cursor: pointer;
}
.ftwGroupCallCta svg {
  margin-right: 10px;
}
.friendsListName {
  display: inline-flex !important;
}

.myCallWrapper {
  padding: 25px;
}

.mySearchCallWrapper {
  padding: 35px;
}

.navigationsMenu-mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
}
.navigationsMenu-navbar {
  /* position: relative;  
    display: flex; */
  align-items: center;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}

.navigationsMenu-nav {
  /* display: block; */
}

@media (min-width: 992px) {
  .navbar-expand-lg .navigationsMenu-offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
}

.navigationsMenu-offcanvas-start {
  top: 0;
  left: 0;
  width: 300px;
}
.navigationsMenu-offcanvas {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  /* background-color: #fff; */
  background-clip: padding-box;
  outline: 0;
}

@media (min-width: 992px) {
  .navbar-expand-lg .navigationsMenu-offcanvas-header {
    display: none;
  }
}

.navigationsMenu-offcanvas-header {
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.5rem;
}

@media (min-width: 992px) {
  .navbar-expand-lg .navigationsMenu-offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
}

/* .navigationsMenu-offcanvas {
    position: fixed;
    bottom: 0;
    z-index: 1045;

    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    max-width: 100%;
    visibility: hidden;
    background-color: #fff;
    background-clip: padding-box;
    outline: 0;
} */
.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}
.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.navigationsMenu-offcanvas-header .btn-close {
  padding: 0.5rem 0.75rem;
  margin-top: -0.5rem;
  margin-right: -0.75rem;
  margin-bottom: -0.5rem;
}
button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}
.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}
.ms-auto {
  margin-left: auto !important;
}
.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  border: 0;
  border-radius: 0.4rem;
  opacity: 0.5;
}
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button,
select {
  text-transform: none;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

@media (min-width: 992px) {
  .navbar-expand-lg .navigationsMenu-offcanvas-body {
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navigationsMenu-px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}
.navigationsMenu-px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}
.navigationsMenu-d-block {
  display: block !important;
}

div {
  display: block;
}
@media (min-width: 992px) {
  .navbar-expand-lg .navigationsMenu-offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
}
/* .navigationsMenu-offcanvas {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    max-width: 100%;
    background-color: #fff;
    background-clip: padding-box;
    outline: 0;
  } */

.navigationsMenu-card.navigationsMenu-overflow-hidden {
  will-change: transform;
}
.overflow-hidden {
  overflow: hidden !important;
}
.navigationsMenu-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.09);
  border-radius: 0.4rem;
}

.h-50px {
  height: 100px !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.navigationsMenu-card-body {
  flex: 1 1 auto;
  /* padding: 1.25rem 1.25rem; */
}

.text-center {
  text-align: center !important;
}

.avatar-lg {
  height: 4rem;
  width: 4rem;
}
.avatar {
  position: relative;
  display: inline-block;
  flex-shrink: 0 !important;
}
.mt-n5 {
  margin-top: -3rem !important;
}
.mb-3 {
  margin-bottom: 1rem !important;
}

a {
  transition: all 0.3s ease-in-out;
}

a {
  color: #0f6fec;
  text-decoration: none;
}

.avatar-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.rounded {
  border-radius: 0.4rem !important;
}
.border-3 {
  border-width: 3px !important;
}
.border-white {
  border-color: #fff !important;
}
.border {
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
}
img {
  max-width: 100%;
}
img,
svg {
  vertical-align: middle;
}

.mb-0 {
  margin-bottom: 0 !important;
}

h5,
.h5 {
  font-size: 1.171875rem;
}
.navigationsMenu-h6 {
  margin-top: 0;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  line-height: 1.25;
  color: #14191e;
}

small,
.small {
  font-weight: 400;
}

small,
.small {
  font-size: 0.875em;
}

.mt-3 {
  margin-top: 1rem !important;
}

.gap-xl-3 {
  grid-gap: 1rem !important;
  gap: 1rem !important;
}
.navigationsMenu-justify-content-center {
  justify-content: center !important;
}
.gap-2 {
  /* gap: 0.5rem !important; */
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.navigationsMenu-h6,
.h6 {
  font-size: 0.9375rem;
}
.navigationsMenu-h6 {
  margin-top: 0;
  font-weight: 700;
  line-height: 1.25;
  color: #14191e;
}

.navigationsMenu-h6 {
  display: block;
  font-size: 0.67em;
  margin-block-start: 2.33em;
  margin-block-end: 2.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentColor;
  opacity: 0.25;
}

.fw-bold {
  font-weight: 700 !important;
}
.gap-2 {
  grid-gap: 0.5rem !important;
  gap: 0.5rem !important;
}
.flex-column {
  flex-direction: column !important;
}
.navigationsMenu-nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
ol,
ul,
dl {
  margin-top: 0;
}
ol,
ul {
  padding-left: 2rem;
}

ul {
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

.nav.flex-column:not(.nav-pills) .nav-link {
  padding-left: 0;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}
.nav-link-secondary .nav-item .nav-link {
  color: #14191e;
}
.nav-link {
  /* display: block; */
  display: flex;
  padding: 0.25rem 0.75rem;
  color: #676a79;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.navigationsMenu-card-footer:last-child {
  border-radius: 0 0 calc(0.4rem - 1px) calc(0.4rem - 1px);
}
.text-center {
  text-align: center !important;
}
.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.navigationsMenu-card-footer {
  padding: 1.25rem 1.25rem;
  background-color: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.09);
}

.btn {
  outline: 0;
}
.btn-link {
  padding: 0;
}
.btn-sm,
.btn-group-sm > .btn {
  padding: 0.4rem 0.8rem;
  font-size: 0.8125rem;
  border-radius: 0.2rem;
}
.btn-link {
  font-weight: 500;
  color: #0f6fec;
  text-decoration: none;
}

.lh-1 {
  line-height: 1 !important;
}
.mt-4 {
  margin-top: 1.5rem !important;
}
.navigationsMenu-justify-content-center {
  justify-content: center !important;
}
.navigationsMenu-nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  /* display: block !important; */
  display: flex;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
  color: #000000;
}

.nav-link-body-sideMenu {
  margin-bottom: 0.5rem !important;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #676a79 !important;
}

.nav-link-body-sideMenu:hover {
  /* margin-bottom: 0.5rem !important; */
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #676a79 !important;
}

.nav-link-body-sideMenu-active {
  margin-bottom: 0.5rem !important;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #a00030 !important;
}

.nav-link-body-sideMenu-active:hover {
  /* margin-bottom: 0.5rem !important; */
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #a00030 !important;
}

.refer-wrapper {
  display: flex;
  justify-content: center;
}

.cta {
  display: flex;
  padding: 6px 9px;
  background: thistle;
  transition: 1s;
  box-shadow: 6px 6px 0 black;
  transform: skewX(-15deg);
  color: black !important;
  cursor: pointer;
  font-size: 11px;
  font-weight: 600;
}

.cta:focus {
  outline: none;
}

.cta:hover {
  transition: 0.5s;
  box-shadow: 8px 8px 0 #fbc638;
  color: black !important;
}

.cta span:nth-child(2) {
  transition: 0.5s;
  margin-right: 0px;
}

.cta:hover span:nth-child(2) {
  transition: 0.5s;
  margin-right: 15px;
}

.cta span {
  transform: skewX(15deg);
}

.cta > span:nth-child(2) {
  width: 20px;
  margin-left: 11px;
  position: relative;
  /* top: 12%; */
}

/**************SVG****************/

path.one {
  transition: 0.4s;
  transform: translateX(-60%);
}

path.two {
  transition: 0.5s;
  transform: translateX(-30%);
}

.cta:hover path.three {
  animation: color_anim 1s infinite 0.2s;
}

.cta:hover path.one {
  transform: translateX(0%);
  animation: color_anim 1s infinite 0.6s;
}

.cta:hover path.two {
  transform: translateX(0%);
  animation: color_anim 1s infinite 0.4s;
}

/* SVG animations */

@keyframes color_anim {
  0% {
    fill: white;
  }
  50% {
    fill: #000;
  }
  100% {
    fill: white;
  }
}

.ask-help-wrap {
  display: flex;
  background: white;
  border-radius: 5px;
  flex-wrap: wrap;
  margin-top: 1rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.ask-help-wrap img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.ask-help-wrap > section:nth-child(1) {
  height: 27rem;
  width: 26rem;
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.ask-help-wrap > section:nth-child(2) {
  flex: 1 1;
  background: #a52a2a63;
  border-bottom-left-radius: 29%;
  border-top-right-radius: 5px;
  position: relative;
  border-bottom-right-radius: 5px;
  padding: 2rem;
}
.ask-help-wrap h5 {
  font-weight: bold;
  margin-top: 2rem;
  text-transform: capitalize;
  font-size: 22px;
  margin-bottom: 0;
}
.ask-help-d1 {
  background: black;
  width: 1.5rem;
  height: 13rem;
  position: absolute;
  top: 0;
  right: 0;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 2.5rem;
}
.ask-help-d2 {
  background: black;
  width: 1.5rem;
  height: 13rem;
  position: absolute;
  top: 30%;
  left: 0;
  border-top-right-radius: 3rem;
  border-bottom-right-radius: 2.5rem;
}
.ask-help-wrap span {
  color: blue;
  font-size: 12px;
  padding-bottom: 1rem;
  display: block;
  margin-top: 6px;
}
.nav-new-menus > li:hover {
  background: #a52a2a0f;
  border-radius: 5px;
}
.nav-new-menus > li span {
  font-weight: 600;
}
.calendar-op-menu {
  list-style: none;
  background: gainsboro;
  width: -moz-fit-content;
  width: fit-content;
  padding: 1rem;
  margin-left: 1rem;
  border-radius: 6px;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  grid-gap: 1rem;
  gap: 1rem;
  height: -moz-fit-content;
  height: fit-content;
}
.date-picker {
  border-radius: 5px;
  width: 6.5rem;
  padding: 12px;
  text-align: center;
  border: 1px solid gray;
}

.g-2 {
  grid-gap: 2rem;
  gap: 2rem;
}
.g-1 {
  grid-gap: 1rem;
  gap: 1rem;
}
.g-3 {
  grid-gap: 3rem;
  gap: 3rem;
}
.calendar-multi {
  z-index: 0 !important;
}
.l-none {
  list-style: none;
  padding: 0;
}

.slot-card {
  font-size: 13px;
  border: 1px solid gainsboro;
  padding: 1rem;
  border-radius: 5px;
  flex: 1 1;
  overflow: hidden;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
    rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
}
.share-btn {
  flex-basis: 100%;
  background: linear-gradient(90deg, #000940 0%, #a00030 100%);
  color: white;
  overflow: hidden;
  padding: 12px;
  font-weight: bold;
  cursor: pointer;
}
.f-12 {
  font-size: 12px;
}
.f-14 {
  font-size: 14px;
}
.card-shadow {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.t-box {
  border: 1px solid gainsboro;
  border-radius: 6px;
  padding: 5px;
  font-size: 12px;
  overflow: hidden;
  height: -moz-fit-content;
  height: fit-content;
  text-align: center;
}
.calender-share {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  border-radius: 6px;
  background: #778899f7;
  width: 100%;
  transition: all 0.5s ease;
  animation: share-eventwrap 0.5s;
}
@keyframes share-eventwrap {
  from {
    transform: scale(0.5);
  }
  to {
    transform: scale(1);
  }
}
.c-pointer {
  cursor: pointer;
}
/* App.css */
.cal-grid {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(17rem, 1fr));
  place-items: center;
}
.s-box {
  border: 1px solid gray;
  padding: 7px;
  border-radius: 9px;
  display: flex;
  height: 2rem;
  place-content: center;
}
.event-hide-wrap {
  background: lightslategray;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  transition: all 0.5s ease;
  animation: eventwrap 0.5s;
}

@keyframes eventwrap {
  from {
    transform: scale(2);
  }
  to {
    transform: scale(1);
  }
}
.user-cal-btn {
  background: #0000ff6e !important;
  border: none !important;
  color: black;
}
.box-dis {
  display: flex;
  width: 7rem;
  text-align: center;
  background: gainsboro;
  flex-shrink: 0;
  height: 3rem;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.calendar-multi {
  --rmdp-font-size: 1.2em; /* Increase the font size */
  --rmdp-width: auto; /* Adjust width as needed */
}

.calendar-multi .rmdp-wrapper {
  width: var(--rmdp-width);
  height: auto;
}

.calendar-multi .rmdp-container {
  font-size: var(--rmdp-font-size);
  padding: 10px; /* Adjust padding if needed */
}

.calendar-multi .rmdp-calendar {
  max-width: var(--rmdp-width);
}

.calendar-multi .rmdp-day-picker {
  padding: 10px; /* Adjust padding if needed */
  display: flex;
}

.calendar-multi .rmdp-day {
  width: calc(100% / 7); /* Ensure each day cell adjusts to the new width */
}

.calendar-multi .rmdp-day span {
  font-size: var(--rmdp-font-size);
  font-weight: 600;
  margin: -3px -1px;
}

.calendar-multi .rmdp-week {
  grid-gap: 2rem;
  gap: 2rem;
}

.calendar-multi .rmdp-week-day {
  font-weight: bold;
  margin-left: 8px;
}
.calendar-multi .rmdp-week {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.calendar-multi .rmdp-header-values {
  font-weight: bold;
  font-size: 1.5em;
}
@media (min-width: 0px) and (max-width: 760px) {
  .calendar-multi .rmdp-day-picker {
    padding: 0;
  }
  .calendar-multi .rmdp-week {
    grid-gap: 0;
    gap: 0;
  }
}
.d-slot-index {
  z-index: 0 !important;
}

h1 {
  color: gray;
}

.PropName {
  font-weight: bold;
  color: #6264a7;
}
.Logo {
  font-size: 45pt;
  color: #6264a7;
}
.Error {
  color: red;
}

/* .connectBtn {
  padding: 20px 0px 15px 0px;
} */
.connectBtn {
  padding: 20px 0px !important;
}
.profilePhoto img {
  width: 70px;
  height: 70px;
}

.profilePhoto {
  margin-top: -35px;
}

.networkDetails h3 {
  text-align: center;
  font: nozrmal normal bold 18px/22px, sans-serif;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
}

.bpc-img-wrapper img {
  width: 75px;
  height: 75px;
}

.chat-btn {
  border-radius: 3px;
  padding: 5px 35px;
  font-weight: normal;
  font-size: 11px;
}

.update-btn {
  border: 1px solid #a00030;
  border-radius: 3px;
  padding: 5px 15px;
  color: white;
  float: right;
  font-weight: normal;
  font-size: 11px;
  background: #a00030;
  margin-right: 20px;
}

.slotCard {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d4d4d5;
  border-radius: 4px 4px 0px 0px;
  padding: 20px 10px 10px 10px;
  padding-top: 30px;
}

.timeline .dx-scheduler-cell-sizes-horizontal {
  width: 20px !important;
  max-width: 20px !important;
  white-space: normal !important;
  word-wrap: break-word !important;
}
.timeline .dx-scheduler-header-panel-cell {
  width: 20px !important;
  max-width: 20px !important;
  white-space: normal !important;
  word-wrap: break-word !important;
}
.timeline .dx-scheduler-cell-sizes-vertical {
  max-height: 40px !important;
}
.timeline .dx-scheduler-header-panel {
  width: unset;
}

/* .avaliableTextTeaching {
    padding-bottom: 20px !important;
  } */
.updateMyCalenderText {
  color: #707070 !important;
  text-align: center !important;
  font-size: 21px !important;
  font-family: "Lato", sans-serif !important;
}
.booking-profile-teaching-card {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d4d4d5;
  border-radius: 4px 4px 0px 0px;
  padding: 30px;
}
.BooktimeMainContainerTeaching {
  padding: 0 15px !important;
}
span.MuiIconButton-label {
  margin: 0px;
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 600px !important;
    margin: 1.75rem auto;
  }
}

.wrapper {
  background-color: white;
  width: 400px;
  padding: 2rem;
  box-shadow: 0 1.5rem 1rem -1rem rgba(0, 0, 0, 0.1);
  border-radius: 0.3rem;
}

.emailInput {
  width: 100%;
  height: 45px;
  padding: 0 1rem;
  margin-top: 1rem;
  box-sizing: border-box;
  font: inherit;
  border-radius: 0.2rem;
  border: 2px solid #d4d5d6;
  color: #565656;
  -webkit-appearance: none;
}

.emailInput:focus {
  border-color: cornflowerblue;
  outline: none;
}

.emailInput.has-errorEmail {
  border-color: tomato;
}

.error {
  margin: 0;
  font-size: 90%;
  color: tomato;
}

.tag-itemEmail {
  background-color: #d4d5d6;
  font-size: 14px;
  border-radius: 30px;
  height: 30px;
  padding: 0 4px 0 1rem;
  display: inline-flex;
  align-items: center;
  margin: 0 0.3rem 0.3rem 0;
}

.tag-itemEmail > .emailButton {
  background: rgb(192, 189, 189);
  width: 22px;
  height: 22px;
  border-radius: 30px;
  border: none;
  cursor: pointer;
  font: inherit;
  margin-left: 10px;
  font-weight: bold;
  padding: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

span.closeBtn {
  right: 0 !important;
  position: absolute !important;
  margin-right: 30px !important;
}

.userImgPost img {
  float: left !important;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  box-shadow: 0px 0px 5px #00000029;
  border: 3px solid #ffffff;
}

/* ================ Main menu ======== */
.inputEnter {
  white-space: pre-wrap;
}
.userDetailsMenu img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
}
.userDetailsMenu {
  cursor: pointer;
}
.userDetailsMenu.d-flex {
  justify-content: center;
  align-items: center;
}

.userDetailsMenu p {
  color: #fff;
  font-size: 14px;
  margin: 0;
}

.userDetailsMenu.d-flex i {
  color: #fff;
  margin-left: 11px;
  font-size: 14px;
}
#header .navbar-brand img {
  width: 150px;
  height: 60px;
  object-fit: contain;
  object-position: left;
  padding: 0;
}

header#header nav {
  background: transparent linear-gradient(328deg, #141414 0%, #2a2a2a 100%) 0%
    0% no-repeat;
}

#header .nav-link {
  font-size: 14px;
  text-transform: uppercase;
}

/* #header a.active {
    color: #fff;
  } */

#header .nav-link i {
  margin-right: 5px;
}
#header {
  position: relative;
}

/* .dropdonwCard {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 5px 5px 25px #00000040;
  border: 1px solid #dededebf;
  border-radius: 4px;
  width: 225px;
  height: auto;
  padding: 15px 20px;
  position: absolute;
  right: 8%;
  top: 6.5em;
  cursor: pointer;
  z-index: 10;
} */

.dropdonwCard {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 5px 5px 25px #00000040;
  border: 1px solid #dededebf;
  border-radius: 4px;
  width: 225px;
  height: auto;
  padding: 15px 10px;
  position: absolute;
  right: 2%;
  top: 6.2em;
  /* cursor: pointer; */
  z-index: 10;
}

.dropdonwCard ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  gap: 5px;
}

.dropdonwCard a {
  color: #333333;
  font-size: 14px;
  font-family: "nunitoBold", sans-serif;
}
.dropdonwCard p {
  color: black;
  font-weight: 600;
}
.dropdonwCard p:hover {
  color: #a00030;
}
.dropdonwCard i {
  background: #d8dadf 0% 0% no-repeat padding-box;
  width: 38px;
  height: 38px;
  text-align: center;
  border-radius: 50%;
  padding-top: 5px;
  font-size: 24px;
  margin-right: 5px;
}

.dropdonwCard li {
  /* margin-bottom: 10px; */
}

/* ================ WELCOME SCREEN ======== */
.greyBg {
  background: #f2f2f2;
}

.welcomeCard {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 5px 5px 10px #0000000d;
  border-radius: 4px;
  padding: 20px 25px;
  margin: 0 60px;
}

#welcome {
  padding-top: 5%;
}

.welcomeCard img {
  width: 380px;
  height: auto;
}

.welcomeDetails p {
  color: #000000cc;
  font-size: 16px;
  margin-bottom: 20px;
}

.welcomeDetails {
  margin-top: 10%;
}

b.welcomeBlueText {
  color: #60c2e7;
}

button.letsGoBtn {
  background: #60c2e7 0% 0% no-repeat padding-box;
  border-radius: 4px;
  border: none;
  color: #fff;
  width: 125px;
  padding: 6px;
  font-family: "nunitoBold", sans-serif;
  cursor: pointer;
}
button.letsGoBtn:focus {
  outline: none;
}

/* =========== PROFILE PAGE ========= */
p.highlitedTabText {
  color: #b5b5b5;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 5px;
}

.profileTab {
  text-align: left;
}

.profileNumber p {
  color: #b5b5b5;
  font-size: 24px;
  font-weight: bold;
}

.profileNumber {
  margin-right: 15px;
  align-items: center;
  justify-content: center;
  display: flex;
  min-width: 30px;
}

p.tabDetailedDesc {
  color: #b5b5b5cc;
  font-size: 13px;
}

.ProfileTabNav .nav-item {
  background: #fff;
  border: 1px solid #e4e4e5;
}

.ProfileTabNav .nav-item {
  position: relative;
  border-right: 1px solid #e4e4e5 !important;
  border: none;
}
.showTabArrow:after,
.showTabArrow:before {
  left: 100%;
  top: 70%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.showTabArrow:after {
  border-color: rgba(34, 34, 34, 0);
  border-left-color: #ffffff;
  border-width: 15px;
  margin-top: -30px;
  z-index: 1;
}
.showTabArrow:before {
  border-color: rgba(228, 228, 229, 0);
  border-left-color: #dee2e6;
  border-width: 16px;
  margin-top: -31px;
  z-index: 1;
}

.ProfilePage {
  padding-top: 25px;
  padding-bottom: 25px;
}

a#about-you {
  padding-left: 30px;
}

a#learning {
  padding-left: 30px;
}

nav.ProfileTabNav {
  padding-bottom: 35px;
}

nav.ProfileTabNav .active p.highlitedTabText {
  color: #60c2e7;
  opacity: 1;
}

nav.ProfileTabNav .active .profileNumber p {
  color: #212121;
}

nav.ProfileTabNav .active p.tabDetailedDesc {
  color: #000000cc;
}
nav.ProfileTabNav .active p.highlitedTabText {
  color: #60c2e7;
  opacity: 1;
}

nav.ProfileTabNav .active .profileNumber p {
  color: #212121;
}

nav.ProfileTabNav .active p.tabDetailedDesc {
  color: #000000cc;
}
.profileTabContent:-ms-input-placeholder {
  font-family: "Lato", sans-serif;
  font-size: 13px;
}
.profileTabContent::placeholder {
  font-family: "Lato", sans-serif;
  font-size: 13px;
}
.profilePageTabContent {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 5px 5px 10px #0000000d;
  border-radius: 4px;
  padding: 25px;
}

.profileImg {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.profileDetailsForm label {
  display: block;
  font-family: "Lato", sans-serif;
  font-size: 13px;
  font-weight: bold;
  color: #333333de;
  margin-bottom: 5px;
}

.genderRadioButton label {
  display: inline;
  display: initial;
}

.profileFormInput input {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dededf;
  border-radius: 4px;
  margin-bottom: 15px;
  width: 260px;
  padding: 6px;
  font-size: 13px;
}

.genderDetails {
  margin-bottom: 15px;
}

.choosePhoto {
  align-items: center;
}

.choosePhotoImg img {
  width: 115px;
  height: auto;
}

.choosePhotoBtn button {
  background: #dfdfdf 0% 0% no-repeat padding-box;
  border-radius: 4px;
  border: none;
  width: 125px;
  padding: 6px;
  letter-spacing: 0px;
  color: #333333;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-weight: bold;
}

.choosePhotoImg {
  margin-right: 15px;
}

.profileSubmitBtn button {
  background: #60c2e7 0% 0% no-repeat padding-box;
  border-radius: 4px;
  border: none;
  width: 154px;
  color: #fff;
  padding: 7.5px;
  font-size: 14px;
  font-weight: bold;
  font-family: "Lato", sans-serif;
  letter-spacing: 0;
}
button {
  cursor: pointer;
}
.profileTabContent button:focus {
  outline: none;
}
.formRadioInput [type="radio"]:checked,
.formRadioInput [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.formRadioInput [type="radio"]:checked + label,
.formRadioInput [type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  line-height: 23px;
  display: inline-block;
  color: #666;
}
.formRadioInput [type="radio"]:checked + label:before,
.formRadioInput [type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 3px;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}
.formRadioInput [type="radio"]:checked + label:after,
.formRadioInput [type="radio"]:not(:checked) + label:after {
  content: "";
  width: 8px;
  height: 8px;
  background: #0c0c0c;
  position: absolute;
  top: 8px;
  left: 5px;
  border-radius: 100%;
  transition: all 0.2s ease;
}
.formRadioInput [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
.formRadioInput [type="radio"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}

.formRadioInput {
  margin-right: 10px;
}

/* ========= model ======== */
.uploadEmptyCard {
  border: 1px solid #707070;
  background: #e1e1e1;
  text-align: center;
}

.uploadEmptyCard img {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  border: 5px dashed #ffffff;
}

.uploadButton button {
  display: block;
  width: 175px;
  border: none;
  background: #dfdfdf 0% 0% no-repeat padding-box;
  border-radius: 4px;
  color: #333333;
  font-size: 14px;
  font-weight: bold;
  font-family: "Lato", sans-serif;
  padding: 7.5px;
  margin-bottom: 15px;
}

.uploadButton {
  margin-left: 35px;
}
.createPostModel button {
  margin-left: 0 !important;
}
h5#uploadModelLabel {
  font-size: 16px;
  font-weight: bold;
  color: #000000de;
}

div#uploadModel .modal-header {
  border-bottom: 1px solid #707070;
}

#uploadModel .modal-footer {
  border: navajowhite;
}

#uploadModel .btn-primary {
  background: #60c2e7 0% 0% no-repeat padding-box;
  border-radius: 4px;
  border: none;
  color: #ffffff;
  font-size: 16px;
  width: 120px;
  padding: 11.5px;
  font-weight: bold;
}

#uploadModel .btn-secondary {
  background: no-repeat;
  border: none;
  color: #333333;
  font-size: 14px;
  font-weight: bold;
}

.blurSection {
  filter: blur(8px);
}

/* ========== about section ======== */
.profileFormTextarea textarea {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dededf;
  border-radius: 4px;
  margin-bottom: 15px;
}

.aboutInputAddButton {
  position: relative;
}

.aboutInputAddButton i {
  position: absolute;
  left: 46%;
  top: 45%;
  color: #747576;
  cursor: pointer;
}

.individualBadge {
  display: flex;
  border: 1px solid #333333;
  border-radius: 100px;
  padding: 2px 10px;
  margin-right: 15px;
  align-items: center;
}

.individualBadge p {
  font-size: 13px;
  font-family: "Lato", sans-serif;
  margin-right: 10px;
}

.profileBadge {
  margin-bottom: 15px;
}

.individualBadge span {
  font-size: 14px;
  cursor: pointer;
}

.aboutSubmitBtn button {
  width: 120px !important;
}

/* ================ LEARNING SECTION ========= */
.learningSection .individualBadge p {
  margin: 0;
}
.learningSection .individualBadge {
  display: inline-block;
  margin-bottom: 5px;
}
p.dropTitle {
  font-size: 13px;
  color: #333333de;
  font-family: "Lato", sans-serif;
  font-weight: bold;
  text-align: center;
}

.dragDropCardContainer {
  margin-top: 25px;
  width: 220px;
}

.dragDropCard {
  width: 220px;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #b2b2b2;
  border-radius: 4px;
  background: #b2b2b205;
  margin: 5px 0;
}

.dragDropCard p.dragDropTxt {
  color: #000000;
  opacity: 0.25;
  font-size: 14px;
  font-weight: bold;
  width: 100px;
  text-align: center;
  font-family: "Lato", sans-serif;
}

.dragDropBottomIcons {
  text-align: center;
}

.dragDropBottomIcons img {
  width: 15%;
}
.learningBtn button {
  width: 106px;
  margin-top: 35px;
}

.dropBadgeArea {
  min-height: 150px;
}
.activeDragArea {
  display: inline-block;
  padding: 5px;
  text-align: center;
  overflow: hidden auto;
}

.profileNumber .fa {
  color: #21ba45;
  font-size: 30px;
}

.letsGoBtn img {
  width: 20px !important;
  margin-left: 5px;
}

/* ============= MY CLASSROOM ================= */
.classroomCard {
  box-shadow: 5px 5px 10px #0000000d;
  border-radius: 4px;
  background: #fff;
  padding: 10px;
  height: 570px;
  margin-bottom: 15px;
}

.classDetails h3 {
  font-size: 16px;
  margin-bottom: 2px;
}

.classDetails h2 {
  font-size: 18px;
}

.classDetails {
  position: relative;
  padding: 15px 10px 30px;
}

.classDetails i {
  position: absolute;
  left: 2%;
  top: 14%;
}
.classDetails h3,
.classDetails h2 {
  font-family: "nunitoRegular", sans-serif;
  font-weight: bold;
  margin-left: 8%;
  color: #000000de;
}

.classDetails p {
  letter-spacing: 0px;
  color: #545454;
  font-size: 12px;
}

.classImg img {
  width: 100px;
  height: 50px;
  object-fit: fill;
}

.classList h2 {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0px;
  font-family: "nunitoRegular", sans-serif;
}

.classList {
  margin-bottom: 30px;
  position: relative;
  width: 100%;
  cursor: pointer;
}

.classList p {
  font-family: "Lato", sans-serif;
  font-size: 12px;
}

.classList strong {
  font-size: 24px;
  color: #333333;
  font-family: "Bebas Neue", cursive;
  position: absolute;
  right: 2%;
  top: 21%;
}

.classListOpactiy {
  opacity: 0.5;
}

.activeClass {
  background: #e9e9e9;
  border-radius: 4px;
}

.classFeatureimg img {
  width: 100%;
  height: auto;
}

.classDetailed h1 {
  font-size: 18px;
  font-weight: bold;
  font-family: "nunitoRegular", sans-serif;
  margin-bottom: 0;
}

.classDetailed {
  padding: 25px;
}

.classDetailed p {
  font-size: 12px;
  color: #b3b3b3;
  font-weight: bold;
  font-family: "nunitoRegular", sans-serif;
  margin-bottom: 30px;
}

.takeQuizBtn {
  text-align: right;
  margin-top: 20px;
}

.takeQuizBtn button {
  background: #60c2e7 0% 0% no-repeat padding-box;
  border-radius: 4px;
  border: none;
  color: #fff;
  font-size: 14px;
  width: 130px;
  padding: 7.5px 0;
}

.classDetailed hr {
  margin: 20px 0;
  border: 2px solid #707070;
}

.flexReview {
  display: flex;
}

.flexReview p {
  margin-bottom: 0;
  margin-right: 12px;
}

.flexReview img {
  width: 20px;
  margin-right: 5px;
}

.shareClass p {
  margin-bottom: 0;
  margin-right: 25px;
  color: #333333;
  font-weight: bold;
  font-family: "Lato", sans-serif;
}

.shareClass {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.ClassRoomMainContainer {
  padding-bottom: 35px;
}
.flexClassList {
  display: flex;
  padding: 5px;
}

.classInfo {
  margin-left: 15px;
  margin-top: 5px;
}
#myclassRoom {
  padding-top: 3%;
}
/* ============= MY CLASSROOM DIALOGUE ============ */
.myClassroomModelContent {
  text-align: center;
  padding: 30px 0;
}

.myClassroomModelContent img {
  width: 60px;
}

.myClassroomModelContent p {
  color: #707070;
  font-size: 18px;
  font-family: "Lato", sans-serif;
  font-weight: bold;
  margin-bottom: 25px;
}

.myClassroomModelContent button {
  width: 170px;
  background: #60c2e7 !important;
  border: none;
  padding: 10px 0;
}

/* =============== MY NETWORK SCREEN ================ */
.findFriendContainer {
  padding: 3% 0;
}

.searchFriend {
  margin-bottom: 25px;
}

.searchFriend h3 {
  color: #707070;
  font-size: 24px;
  font-weight: bold;
  font-family: "Lato", sans-serif;
  margin-bottom: 25px;
  text-align: center;
}

.searchFriendInput.input-group,
.findaMatchSearchInput.input-group {
  width: 250px;
  margin: auto;
  position: relative;
}
.searchFriend.findaMatchCard .searchFriendSelectBoxFlex {
  width: 85%;
}
.searchFriend .input-group input {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dededf;
  border-radius: 100px !important;
  font-size: 12px;
  padding: 7px 15px;
  height: auto !important;
}

.searchFriendInput i {
  position: absolute;
  right: 15px;
  top: 9px;
  color: #9a9a9a;
  font-size: 14px;
  cursor: pointer;
}

.searchFriendSelectBoxFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 75%;
  margin: auto;
  margin-bottom: 30px;
}

.searchFriendSelectBoxFlex label {
  width: 100%;
  color: #333333de;
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 5px;
  line-height: 1;
}

.searchFriendFormField {
  margin-right: 10px;
  width: 160px;
}

.searchFriendSelectBoxFlex select {
  border: 1px solid #dededf;
  border-radius: 4px !important;
  font-size: 12px;
}

.profilePhoto img {
  width: 100px;
  height: 100px;
  box-shadow: 0px 0px 5px #00000029;
  border: 3px solid #ffffff;
  border-radius: 50%;
  object-fit: cover;
}

.networkCard {
  text-align: center;
  border: 1px solid #dededf;
  border-radius: 4px;
  background: #fff;
  position: relative;
}

.coverPhoto {
  height: 100px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  border-radius: 4px 4px 0px 0px;
}

.profilePhoto {
  margin-top: -45px;
}

.networkDetails {
  padding: 20px 15px 0;
}

.networkDetails h3 {
  font-size: 18px;
  color: #333333;
  font-weight: bold;
  font-family: "Lato", sans-serif;
}

.networkDetails p {
  color: #b3b3b3;
  font-size: 12px;
  margin-bottom: 15px;
}

.connectBtn {
  padding: 20px 15px 15px;
}

.connectBtn button {
  border-radius: 4px;
  border: none;
  background: #60c2e7;
  color: #fff;
  font-size: 12px;
  padding: 5px 8px;
  font-weight: bold;
  font-family: "nunitoRegular", sans-serif;
}
.networkCardContainer .col-md-3 {
  margin-bottom: 30px;
}
.networkCardContainer {
  min-height: 500px;
}
.networkDetailsBtn button {
  display: block;
  margin: auto;
  font-size: 14px;
  border: none;
  padding: 7.5px 0;
  font-weight: bold;
  font-family: "Lato", sans-serif;
  cursor: pointer;
}

button.networkSkip {
  background: #dfdfdf;
  border-radius: 4px;
  color: #333333;
  width: 125px;
}

/* =============== FIND A MATCH SCREEN ============ */
.findaMatchCard {
  background: #fff 0 0 no-repeat padding-box;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 15px 10px;
  margin-bottom: 15px;
  min-height: 130px;
  /*min-height: 230px; */
}
span.filterTxt {
  margin: 0 !important;
}
.filterDownArrow {
  cursor: pointer;
}
.findaMatchSearchInput span i {
  margin-right: 5px;
}
.findaMatchSearchInput {
  width: 350px !important;
}

.findaMatchSearchInput i {
  position: inherit;
  top: 10px;
  right: 10px;
  font-size: 18px;
  color: #9a9a9a;
  font-size: 14px;
  cursor: pointer;
}

.findaMatchSearchInput span {
  font-size: 14px;
  color: #989898;
  text-decoration: underline;
  margin-top: 6px;
  margin-left: 10px;
}

.findaMatchSearchInput span i {
  font-size: 14px;
}

.findaMatchSearchInput input {
  width: 275px;
}

.findMatachHeading {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}
.navbar-brand {
  background: transparent;
  margin: 0 20px 0 0;
}
.findMatachHeading:before,
.findMatachHeading:after {
  background: #707070;
  height: 1px;
  content: "";
  flex: 1 1;
}

.findMatchNetworkCard {
  padding: 20px 0;
}

.findMatachHeading h3 {
  color: #333333de;
  font-size: 24px;
  font-weight: bold;
  font-family: "nunitoRegular", sans-serif;
  padding: 0 10px;
}
.yuukkeHeading h3 {
  color: #b3b3b3;
}
.yuukkeHeading:before,
.yuukkeHeading:after {
  background: #b3b3b3;
}
.detailedCard {
  width: 90%;
  margin: auto;
}

.detailedCard .col-md-3 {
  margin-bottom: 30px;
}

.findMatchCardBtn i {
  margin-right: 5px;
}
/* .findMatchCardBtn button {
    width: auto;
    padding: 5px 10px;
  } */
.findMatchCardBtn button {
  width: auto;
  padding: 5px 5px;
}
button.btnOutlined {
  background: none;
  color: #60c2e7;
  border: 1px solid #60c2e7;
  margin-left: 5px;
}

.findMatchNetworkCard .row {
  margin-bottom: 25px;
}

.detailedCard .networkDetails > p:nth-child(3) {
  margin-bottom: 0;
}
/*============= My Yuukke =================*/

.MyYuukke {
  padding-top: 50px;
}
.smiley {
  text-align: center;
}
.smiley p {
  opacity: 0.6;
  font-weight: 700;
  font-size: 18px;
}
.img-wrapper img {
  max-width: 200px;
}
/* .card-wrapper {
  background: #fff;
  box-shadow: 5px 5px 10px #0000000d;
  border-radius: 4px;
  padding: 32px 40px;
  min-height: 273px;
  margin-bottom: 20px;
} */
.btn-peer {
  background: #60c2e7;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
}
.mypeercards .container {
  max-width: 1000px;
}
.btn-peer:hover {
  color: #fff;
  text-decoration: none;
}
.mypeercards {
  padding-top: 20px;
}
.img-wrapper a {
  position: absolute;
  bottom: 85px;
  left: 55px;
}

/*=============== Book Time ============*/
.book-time {
  padding: 50px 0px;
}
.book-time h4 {
  color: #707070;
  font-weight: bold;
  margin-bottom: 50px;
}
.bpc-img-wrapper {
  text-align: center;
}
.bpc-img-wrapper img {
  border-radius: 50%;
  box-shadow: 0px 0px 5px #00000029;
  border: 3px solid #ffffff;
}
.hash-tags {
  font-size: 13px;
}
.chat-btn {
  border: 1px solid #60c2e7;
  border-radius: 4px;
  padding: 10px 30px;
  color: #60c2e7;
  font-weight: bold;
  font-size: 14px;
  background: transparent;
}
.update-btn {
  background: #60c2e7 0% 0% no-repeat padding-box;
  border-radius: 4px;
  color: #fff;
  padding: 10px 30px;
  border: none;
  font-weight: bold;
}
.booking-profile-card {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d4d4d5;
  border-radius: 4px 4px 0px 0px;
  padding: 30px;
}
.right-col-wrap {
  padding: 30px;
}
.align-flex-end {
  align-items: flex-end;
}

/* ================ CALANDER =========== */

.appoinmentCalander {
  position: relative;
}
.appoinmentCalander .fc-header-toolbar {
  height: 0;
  margin: 0 !important;
}
.appoinmentCalander h2.fc-toolbar-title {
  display: none;
}
.appoinmentConfirmend {
  padding: 25px;
  text-align: center;
}

.appoinmentConfirmedContent img {
  width: 40px;
  margin-bottom: 10px;
}

p.appoinmentBoldTxt {
  font-size: 21px !important;
  font-weight: bold;
  color: #707070;
  margin-bottom: 25px !important;
}

.appoinmentConfirmedContent p {
  font-size: 18px;
  margin-bottom: 0;
}

.appoinmentBtnFlex i {
  margin-right: 5px;
}
a.fc-col-header-cell-cushion {
  font-size: 12px;
}

.fc-scroller {
  /* overflow: hidden !important; */
}

.fc-button-group .fc-next-button {
  right: -10%;
}

.fc-button-primary {
  /* background: none !important; */
  /* color: #333 !important; */
  border: none !important; /* width: 50px; */
}

.fc-button-primary .fc-icon {
  width: auto !important;
}

.fc-button-group .fc-prev-button {
  left: -10%;
}

.fc-button-group {
  position: inherit !important;
  z-index: 1;
}

.fc-view-harness {
  padding-bottom: 50% !important;
  z-index: 0;
  border-bottom: 1px solid #d4d4d5;
}

.fc .fc-timegrid-col.fc-day-today {
  background: none !important;
}

.fc-theme-standard td,
.fc-theme-standard th {
  border: 0px;
}

table.fc-scrollgrid.fc-scrollgrid-liquid {
  /* border: 0px; */
}

.fc-timeGridWeek-view thead .fc-scrollgrid-section td {
  border-top: 1px solid #d4d4d5 !important;
  border-bottom: 1px solid #d4d4d5 !important;
  padding: 5px 0;
}

.fc-button-group .fc-button {
  position: absolute !important;
  top: 2%;
  color: #5fc0e4 !important;
}
.avaliableText {
  color: #707070;
  text-align: center;
  font-size: 21px;
  font-family: "Lato", sans-serif;
}
.bookingCalanderContainer > .col-md-6 {
  padding-left: 15;
  padding-right: 0;
}
.row.bookingCalanderContainer {
  margin-top: 15px;
}
.seeMoreAvalibile {
  color: #5fc0e4;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  font-family: "Lato", sans-serif;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 0;
}
.hideOverflow {
  overflow: hidden auto !important;
}
.fc-button-group .fc-button:focus {
  box-shadow: none !important;
}
.fc .fc-scroller-harness-liquid {
  /* margin-top: 10px; */
}
.fc-timegrid-slots tr td:nth-child(odd) {
  /* display: none; */
}

.fc-scrollgrid-sync-inner {
  width: 50px;
  margin: auto;
}
.fc-event-main {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
}
.fc-v-event {
  border: none !important;
}
.available,
.unavailable {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2ecc71;
  padding: 0;
  border-radius: 3px;
  color: #fff;
}
.available {
  cursor: pointer;
}
.unavailable {
  background-color: #5fc0e4 !important;
  color: #333333 !important;
}
.fc-media-screen .fc-timegrid-event-harness {
  height: 26px;
}
/* ======== Book Time ============ */
#booktime {
  padding: 50px 0px;
}
#booktime h4 {
  color: #707070;
  font-weight: bold;
  margin-bottom: 50px;
}
.bpc-img-wrapper {
  text-align: center;
}
.bpc-img-wrapper img {
  border-radius: 50%;
  box-shadow: 0px 0px 5px #00000029;
  border: 3px solid #ffffff;
}
.hash-tags {
  font-size: 13px;
}
.chat-btn {
  border: 1px solid #60c2e7;
  border-radius: 4px;
  padding: 10px 30px;
  color: #60c2e7;
  font-weight: bold;
  font-size: 14px;
  background: transparent;
}
.update-btn {
  background: #60c2e7 0% 0% no-repeat padding-box;
  border-radius: 4px;
  color: #fff;
  padding: 10px 30px;
  border: none;
  font-weight: bold;
}
.booking-profile-card {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d4d4d5;
  border-radius: 4px 4px 0px 0px;
  padding: 20px;
}
.right-col-wrap {
  padding: 20px;
}
.align-flex-end {
  align-items: flex-end;
}
.profile-details-wrap p {
  margin: 0;
}
/* ======== Book Time ============ */
#sendRequestModal .modal-body {
  padding: 0;
}
.sr-title h3 {
  font-size: 18px;
  color: #333;
  text-transform: uppercase;
  border-bottom: 1px solid #707070;
  padding: 1rem;
  font-weight: bold;
}
.srModalContent {
  padding: 20px 40px 40px 40px;
}
.subjectDropdown {
  max-width: 180px;
}
.descriptionTextArea {
  max-width: 500px;
}
.datePicker {
  max-width: 170px;
  display: inline-block;
  margin-right: 20px;
}
.timePicker {
  max-width: 100px;
  display: inline-block;
}
.srCancel {
  background: #dfdfdf;
  color: #333;
  border: none;
  font-size: 14px;
  padding: 7.5px 20px;
  border-radius: 4px;
  font-weight: bold;
}
.srSend {
  background: #60c2e7;
  border: none;
  padding: 7.5px 20px;
  font-size: 14px;
  border-radius: 4px;
  font-weight: bold;
  color: #fff;
}
#sendRequestModal label {
  font-size: 13px;
  color: #333333de;
}

/* ============= COMMUNITY SCREEN =========== */
/* .communityCard {
    box-shadow: 5px 5px 6px #0000000d;
    border: 1px solid #dededfbf;
    border-radius: 4px;
    background: #fff;
    margin-bottom: 16px;
  } */
.communityCard {
  box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(222, 222, 223, 0.75);
  border-radius: 10px;
  background: #fff;
  margin-bottom: 16px;
  padding: 0px 20px 0px 10px;
}

.userImg img {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  box-shadow: 0px 0px 5px #00000029;
  border: 3px solid #ffffff;
}
.userImgPost img {
  float: left !important;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  box-shadow: 0px 0px 5px #00000029;
  border: 3px solid #ffffff;
}

.newPostUploadFileTypes {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding-right: 15px;
}

.newPostUpload {
  display: flex;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
}
.newPostUpload.newPostUploadFiles {
  margin-right: 0;
}
.newPostSearchField input {
  width: 100%;
  border-radius: 50px;
  border: 0;
  background: rgb(179, 179, 179, 0.25);
  font-size: 14px;
  padding: 16px 25px;
}

.newPostUpload p {
  margin-bottom: 0;
  font-size: 16px;
  margin-left: 5px;
  letter-spacing: 0.32px;
  color: #333333;
  font-weight: bold;
}

.newPostUpload svg {
  font-size: 24px;
  color: #333333;
}
/* .newPostSearchField {
    padding-left: 15px;
  } */
.padding-15 {
  padding: 15px;
}
.communityRootContianer {
  padding: 50px 15px;
}
.flex-center {
  align-items: center;
}
.newPostUploadVideo svg {
  font-size: 30px;
}
.flexSpaceBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.postCardHeaderInfo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.postInfo p {
  margin-bottom: 0;
}

.postUserName {
  color: #333333;
  font-weight: bold;
  font-size: 16px;
}

.postUploadTie {
  color: #b3b3b3;
  font-size: 14px;
}

.postInfo {
  margin-left: 16px;
}

.postCardHeader {
  padding: 15px 25px 20px;
}

.postDescription {
  padding: 0 25px;
}

.postDescription p {
  color: #000000;
  font-size: 16px;
  margin-bottom: 15px;
  letter-spacing: 0.32px;
}

.postCardBody img,
.postCardBody video {
  width: 100%;
  height: 500px;
  object-fit: cover;
}

.likeComment p {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: bold;
}

.likeComment {
  display: flex;
}
.invitationCardBtn {
  text-align: center;
}
.likeComment div {
  display: flex;
  cursor: pointer;
}

.postLike {
  margin-right: 30px;
  cursor: pointer;
}

.likeComment svg {
  margin-right: 10px;
  font-size: 24px;
}

.likeComment p,
.likeComment svg {
  color: #b3b3b3;
}
@media (max-width: 1025px) {
  .likesInfo {
    display: none !important;
  }
}
@media (min-width: 1025px) {
  .mobile-likesInfo {
    display: none !important;
  }
}

.mobile-likesInfo {
  display: flex;
  align-items: flex-end;
  font-size: 10px;
  color: gray !important;
}

.likesInfo {
  display: flex;
  align-items: flex-end;
}

.likesInfo p {
  margin-bottom: 0;
  color: #b3b3b3;
  font-size: 14px;
  margin-right: 10px;
}

.postCardFooter {
  padding: 25px;
  position: relative;
}

.likesInfo img {
  width: 63px;
  height: 25px;
  margin-right: 10px;
}

p.commentInfo {
  margin-right: 0;
  cursor: pointer;
}
a.commentInfo {
  margin-right: 0;
  cursor: pointer;
}
p.likesDot {
  position: relative;
  top: -3px;
}

.linkCard {
  display: flex;
  background-color: #fff;
}
.menuCard {
  display: flex !important;
  margin-left: 8px;
}
.menuItem {
  margin-top: 6px;
}
.linkCard img {
  width: 200px;
  height: 200px;
  border-radius: 4px 0px 0px 4px;
}
.linkCard {
  display: flex;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  margin: 15px;
}
p.postEventName {
  letter-spacing: 0px;
  color: #1e1e1e;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}
p.postEventTime {
  letter-spacing: 0px;
  color: #1e1e1e;
  text-transform: uppercase;
  font-size: 16px;
  margin-left: 5px;
  margin-bottom: 10px;
}
p.postEventTime svg {
  font-size: 18px;
  position: relative;
  top: -3px;
  left: -5px;
}
.postLike img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
.joinNowBtn button {
  background: #60c2e7;
  border-radius: 4px;
  border: none;
  width: 92px;
  height: 36px;
  display: grid;
  place-content: center;
  color: #ffffff;
}
/* .linkDetails {
    padding: 15px;
  } */
.postLinkDescription p {
  font-size: 14px;
  color: #b3b3b3;
  margin-bottom: 0;
  padding: 15px;
}

.eventOrginzerDetails {
  display: flex;
  color: #b3b3b3;
  align-items: center;
  font-size: 14px;
  padding: 0 15px 10px;
}

.eventOrginzerDetails p {
  margin-bottom: 0;
  margin-right: 15px;
}

.eventOrginzerDetails svg {
  font-size: 21px;
  position: relative;
  top: -2px;
  margin-right: 10px;
}

.linkInfo {
  border-bottom: 1px solid #e2e2e2;
  padding: 10px 15px 0;
}
.invitationCardTitle {
  border-bottom: 1px solid #dededfbf;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

/* .invitationCardWrapper {
    padding: 25px 15px 0;
  } */
.invitationCardWrapper {
  padding: 25px 15px !important;
}
.invitationCardTitle p {
  margin-bottom: 0;
  font-size: 16px;
  color: #333333;
  font-weight: bold;
}

.invitationUserInfo img {
  width: 75px;
  height: 75px;
}

.invitationUserInfoWrapper {
  display: flex;
}

.invitationUserInfo p {
  margin-bottom: 0;
  font-size: 12px;
  color: #b3b3b3;
}

p.invitationUserName {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}

.invitationUserInfo {
  margin-left: 10px;
}

.invitationConnect {
  display: flex;
  align-items: center;
}

.invitationConnect {
  font-size: 12px;
  font-weight: bold;
  color: #b3b3b3;
  padding: 0;
}

.invitationConnect p {
  margin-bottom: 0;
  padding-right: 10px;
}
.invitationCard {
  margin-bottom: 20px;
}
.invitationConnect i {
  margin-right: 5px;
}
.writeCommentWrapper {
  padding: 10px 25px;
  border-top: 1px solid #dededfbf;
}
.flexCenter {
  display: flex;
  align-items: center;
}
.writeComment .userImg img,
.allComments .userImg img {
  width: 40px;
  height: 40px;
}

.commentInput input {
  width: 100%;
  border-radius: 18px !important;
  border: 0;
  background: rgb(179, 179, 179, 0.25);
  font-size: 14px;
  padding: 5.5px 25px;
}

.commentInput {
  flex: 1 1;
  margin-left: 25px !important;
}
.commentDetails p {
  margin-bottom: 0;
  font-size: 14px;
}

.commentDetails {
  padding: 8px 15px;
  background: rgb(179, 179, 179, 0.25);
  border-radius: 18px;
}

.commentDetails strong {
  margin-right: 10px;
  color: #f97516;
}

.allComments .userImg {
  margin-right: 6px;
}

.allComments {
  margin-bottom: 15px;
}
.likeOptions {
  width: 290px;
  height: 50px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15), 0 4px 6px rgba(0, 0, 0, 0.2);
  border-radius: 300px;
  background: #fff;
  border: none;
  justify-content: space-evenly;
  align-items: center;
  padding: 0 15px;
  position: absolute;
  top: -25px;
  left: -15px;
}

.likeOption img {
  width: 36px;
  height: 36px;
}
.showResponseImg img {
  width: 100%;
}
.uploadTypeIcons {
  position: relative;
  overflow: hidden;
  display: inline-block;
  cursor: pointer;
}

.uploadTypeIcons input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}
.overAlluploadicons {
  position: center;
  cursor: pointer;
}
.uploadWrapper img,
.uploadWrapper video {
  width: 100%;
  height: 300px;
  object-fit: contain;
  object-position: top;
}
.uploadPostInput textarea {
  width: 100%;
  height: 80px;
  border: none;
}

.uploadBtnWrapper {
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 10px;
  margin-top: 20px;
}

.uploadTypeIcons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-basis: 20%;
}

.addtoPostTitle {
  flex: 1 1;
}

.addtoPostTitle p {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: bold;
}

.uploadTypeIcons svg {
  cursor: pointer;
}

.previewWrapper {
  height: 180px;
  overflow: hidden auto;
}

.uploadModelTitle {
  font-size: 20px;
  font-weight: 600;
  margin: auto;
}
.uploadPostTitle {
  font-size: 20px;

  font-weight: 400;
  margin: auto;
  display: inline-flex;
}

/* ============ MY PROFILE SCREEN =========== */
.myProfileCoverPhoto img {
  height: 280px;
  width: 100%;
  object-fit: cover;
  border-radius: 4px 4px 0 0px;
}

/* .flexDefault {
    display: flex;
  }
   */
.myProfilePhoto img {
  width: 150px;
  height: 150px;
}

.myProfileNameDetails h2,
.myKnowledgePoint h3 {
  font-size: 21px;
  font-family: "nunitoBold", sans-serif;
  color: #333333;
  margin-bottom: 0;
}

.myProfileNameDetails p,
.myProfileDescription p {
  font-size: 14px;
  margin-bottom: 0;
  color: #b3b3b3;
}

.myKnowledgePoint p {
  margin-bottom: 0;
  color: #333333;
  font-family: nunitoRegular, sans-serif;
}

.myProfilePhoto {
  margin-left: 100px;
  position: relative;
  bottom: 90px;
}

.alignItemStart {
  align-items: flex-start;
}

.myProifleUserInfo {
  height: 100px;
  padding-top: 20px;
}

.myProfileNameDetails {
  padding: 0 15px;
}

.myProfileDescription {
  flex-basis: 29%;
  padding: 0 15px;
}

.myProfileEdit {
  padding: 0;
  margin-left: auto;
  margin-right: 15px;
  display: flex;
  align-items: center;
  height: 100%;
}

.myProfileEdit button {
  position: relative;
  top: -20px;
}

.myKnowledgePoint {
  padding: 0 15px;
}

.borderRightDivider {
  border-right: 1px solid #dbdbdc;
  height: 50%;
}
button i {
  margin-right: 5px;
}

.myIntrestWrapper {
  padding: 20px;
}

.titleMedium {
  font-size: 18px;
  font-family: "Lato", sans-serif;
  color: #333333;
  margin-bottom: 15px;
  font-weight: bold;
}

.myIntrestCards img {
  width: 100%;
  height: 120px;
  object-fit: cover;
  border-radius: 4px;
}

.myIntrestCard {
  margin-right: 15px;
  text-align: center;
  width: 100%;
}

.myIntrestCard p {
  margin-bottom: 0;
  font-size: 16px;
  color: #333333;
  font-family: nunitoExtraBold;
  font-weight: 900;
  margin-top: 5px;
}

.addNewIntrest {
  height: 120px;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #707070;
  border-radius: 4px;
  width: 100%;
  margin-top: 0 !important;
}

.addNewMyIntrestCard {
  width: 100%;
  cursor: pointer;
}

.addNewIntrest i {
  font-size: 80px;
  color: #6f6f6f;
  opacity: 0.19;
}

.addNewMyIntrestCard p {
  font-size: 16px;
  margin-bottom: 0;
  margin-top: 5px;
  color: #333333;
}

.myCompetenciesWrapper {
  padding: 20px;
}

.myCompetencie p {
  margin-bottom: 0;
  font-size: 12px;
  font-weight: bold;
  font-family: "nunitoBold", sans-serif;
}

.myCompetencie {
  flex-basis: 33.33%;
}

.myCompetencie svg {
  font-size: 16px;
  margin-right: 5px;
}

.myCompetencie span {
  font-weight: 100;
}

.myReviewsWrapper {
  padding: 15px;
}

.reviewerProfileImg img {
  width: 50px;
  height: 50px;
}

.reviewerNameDetails h3 {
  font-size: 14px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 0;
}

.reviewerNameDetails p {
  margin-bottom: 0;
  font-size: 12px;
  color: #b3b3b3;
}

.alignItemCenter {
  align-items: center;
}

.reviewerNameDetails {
  margin-left: 15px;
}

.reviewerSubText p {
  margin-bottom: 0;
  font-size: 12px;
  color: #b3b3b3;
  margin-top: 15px;
}

.reviewCard {
  border-left: 4px solid #dbdbdc;
  padding-left: 10px;
}

.allBadges img {
  width: 65px;
  margin-right: 15px;
}

.badgeCard {
  padding: 20px;
}
.allBadges img:nth-child(4) {
  margin-right: 0;
}

.myTodoListCard {
  padding: 20px;
}
ul.todoLists {
  padding-left: 0;
  margin-bottom: 0;
}

.activeTodoLists {
  color: #545454;
  text-decoration: line-through;
}

.todoLists li {
  font-size: 14px;
  color: #000000cc;
  list-style: none;
  position: relative;
  padding: 0 0 0 30px;
  cursor: pointer;
  font-weight: bold;
}

.todoLists li:before {
  content: "";
  position: absolute;
  left: 0;
  top: 3px;
  width: 17px;
  height: 17px;
  border-radius: 4px;
  border: 1px solid #d4d4d5;
}

.activeTodoLists:after {
  position: absolute;
  top: 2px;
  left: 2px;
  content: "\f00c";
  font-family: FontAwesome;
  font-size: 13px;
}
.activeTodoLists:before {
  border-color: #60c2e7 !important;
}
.myProfileWrapper {
  margin-top: 15px;
}
li {
  font-family: nunitoRegular, sans-serif;
}

.newTodoForm p {
  width: 17px;
  height: 17px;
  border-radius: 4px;
  border: 1px solid #d4d4d5;
  margin-bottom: 0;
}

.newTodoForm input {
  border-bottom: 1px solid #b3b3b3cc !important;
  border: none;
  margin-left: 10px;
  padding-left: 5px;
  font-size: 14px;
  width: 50%;
}

.addNewTodo {
  border: 1px solid #60c2e7;
  border-radius: 4px;
  width: 17px;
  height: 17px;
  margin-left: 10px;
}

.addNewTodo i {
  font-size: 11px;
  color: #60c2e7;
  position: relative;
  top: 1px;
}

.flexJustifyCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.newTodoForm {
  align-items: flex-end;
}

.myRemiderCard {
  padding: 20px;
}

.reminderGroupImg {
  width: 35px;
  height: 35px;
}

.reminderInfo h4 {
  font-size: 14px;
  color: #000000cc;
  font-weight: bold;
  margin-bottom: 0;
}

.reminderInfo p {
  font-size: 10px;
  color: #b3b3b3;
  margin-bottom: 0;
}

.reminderInfo {
  margin-left: 15px;
}

.myReminder {
  margin-bottom: 10px;
}

.reminderGroupIcon {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 2px solid #60c2e7;
}

.reminderGroupIcon svg {
  font-size: 21px;
  color: #60c2e7;
}
/* #root .container {
    max-width: 1410px;
  } */
/* =========== STUDY GROUP ============ */
.studyGroupCard {
  margin: 0;
  margin-bottom: 15px !important;
}

.studyGroupCard img {
  width: 185px;
  height: 185px;
}

.studyGroupCard p.postEventName {
  font-size: 14px;
}

.studyGroupCard p.postEventTime {
  font-size: 14px;
}

.studyGroupCard .postLinkDescription p {
  font-size: 12px !important;
  padding: 10px;
}

.studyGroupCard .eventOrginzerDetails p {
  font-size: 10px;
}

.studyGroupCard .eventOrginzerDetails svg {
  font-size: 16px;
}

.studyGroupCard .eventOrginzerDetails p:nth-child(2) {
  margin-right: 0;
}

.studyGroupCard .linkInfo {
  padding: 10px 10px 0;
}

.studyGroupCard .eventOrginzerDetails {
  padding: 0 10px 10px;
}

.studyGroupCard p.postEventTime svg {
  font-size: 14px;
}
.studyGroupCard .joinNowBtn button {
  width: 85px;
  height: 30px;
  font-size: 14px;
}
.studyGroupCard .postLinkDescription {
  max-height: 92px;
  overflow: hidden;
}
.studyGroupSeeMoreBtn {
  padding: 0;
  text-align: center;
  margin: 30px 0;
}

.studyGroupSeeMoreBtn button {
  width: 105px;
  height: 36px;
}
.createStudyGroupBtn button {
  width: 238px;
  height: 48px;
  font-size: 16px;
  font-weight: 300;
}

.createStudyGroupBtn button i {
  font-size: 13px;
}

.createStudyGroupBtn {
  padding: 0;
  position: absolute;
  top: 4.5em;
  right: 0;
}

.studyTourWrapper .searchFriend {
  position: relative;
  padding-top: 30px;
  min-height: 200px;
}
.createStudyGroupModelTitle {
  letter-spacing: 0px;
  color: #333333;
  text-transform: uppercase;
  font-size: 18px !important;
  font-family: "Lato", sans-serif;
  padding-left: 15px !important;
}

.formBasicName {
  width: 291px;
}

.createStudyGroupModel .form-group label {
  font-size: 13px;
  color: #333333de;
  font-weight: bold;
  margin-bottom: 5px;
}

.createStudyGroupModel input,
.createStudyGroupModel textarea,
.createStudyGroupModel select {
  border: 1px solid #dededf;
  border-radius: 4px;
}

.formBasicSubject {
  width: 180px;
}

.createStudyGroupModel input,
.createStudyGroupModel select {
  height: 38px;
  font-size: 14px;
}

.formBasicDescription {
  width: 500px;
}

.createStudyGroupModel .form-group {
  margin-bottom: 15px;
}

.formBasicDate {
  width: 150px;
}

.formBasicHour {
  width: 70px;
  margin: 0 20px 15px;
}

.formBasicDuration {
  width: 86px;
}

.newStudyTourinviteParticipate .input-group-prepend .input-group-text {
  background: none;
  border-right: 0;
  color: #747576;
  padding-right: 0;
}
.newStudyTourinviteParticipate .input-group-append .input-group-text {
  background: none;
  border-left: 0;
  color: #747576;
}
.newStudyTourinviteParticipate input {
  border-left: 0;
  border-right: 0;
}

.newStudyTourinviteParticipate .individualBadge p {
  margin: 0 !important;
  margin-right: 10px !important;
}

.newStudyTourinviteParticipate .individualBadge {
  height: 30px;
  position: relative;
  top: -5px;
  left: 30px;
}

.newStudyTourinviteParticipate {
  align-items: center;
  height: 100%;
}

.formBasicCheckbox input {
  height: auto;
}

.formBasicMaxStudent select {
  width: 75px;
}

.createNewStudyTourModelfooter {
  border-top: 0 !important;
}

.createNewStudyTourModelfooter .btn-secondary {
  background: #dfdfdf 0% 0% no-repeat padding-box;
  width: 75px;
  margin-right: 65px;
}

.createNewStudyTourModelfooter .btn {
  border-radius: 4px;
  border: none;
  letter-spacing: 0px;
  color: #333333;
  font-weight: bold;
  font-size: 14px;
  height: 36px;
}

.createNewStudyTourModelfooter button.btn.btn-primary {
  color: #fff;
  background: #60c2e7;
}
.createStudyGroupModel {
  padding-left: 30px !important;
}
.formBasicinviteParticipate {
  margin-bottom: 0 !important;
}
.createStudyGroupModelWrapper .modal-header {
  border-bottom: 1px solid #707070;
}

/* ==================== FOR MOBILE ============= */
/* @media (min-width: 1300px) {
    #root .container {
      max-width: 1410px;
    }
  } */
@media (max-width: 768px) {
  .subjectDropdown {
    max-width: 100%;
  }
  .descriptionTextArea {
    max-width: 100%;
  }
  .descriptionTextArea {
    max-width: 100%;
  }
  .datePicker {
    max-width: 100%;
    display: block;
    margin-right: 0px;
  }
  .timePicker {
    max-width: 100%;
    display: block;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  #root .container {
    max-width: 992px;
  }
  /* COMMUNITY SCREEN */
  .newPostUpload p {
    font-size: 12px;
  }

  .newPostUpload svg {
    font-size: 21px;
  }

  .newPostUploadVideo svg {
    font-size: 27px;
  }

  p.invitationUserName {
    font-size: 11px;
  }

  .invitationUserInfo p {
    font-size: 9px;
  }

  .invitationUserInfoWrapper .userImg img {
    width: 50px;
    height: 50px;
  }

  .invitationConnect button {
    display: flex;
    align-items: center;
  }
  /* STUDY GROUP SCREEN */
  .studyGroupCard p.postEventName {
    font-size: 12px;
  }

  .studyGroupCard p.postEventTime {
    font-size: 11px;
  }

  .studyGroupCard .postLinkDescription p {
    font-size: 11px !important;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  /* .flexDefault {
      display: block;
    } */
  /* COMMUNITY SCREEN */
  .newPostUpload p {
    font-size: 12px;
  }

  .newPostUpload svg {
    font-size: 21px;
  }

  .newPostUploadVideo svg {
    font-size: 27px;
  }
  /* ======== PROFILE SCREEN ============ */
  .uploadTypeIcons {
    flex-basis: auto;
  }
  .myProfilePhoto {
    margin-left: 0;
    bottom: 0;
    margin-bottom: 15px;
  }

  /* .flexDefault {
      display: block;
    } */

  .myProifleUserInfo {
    text-align: center;
    position: relative;
    bottom: 90px;
    height: 330px;
  }

  .borderRightDivider {
    border: none;
    height: auto;
  }

  .myProfileEdit {
    display: block;
    height: auto;
  }

  .myProfileDescription {
    margin: 15px 0;
  }

  .myKnowledgePoint {
    margin: 15px 0;
  }

  .myProfileEdit button {
    position: inherit;
  }

  .myIntrestCards img {
    height: 350px;
  }

  .myIntrestCard p {
    margin: 15px 0;
  }

  .addNewIntrest {
    height: 350px;
  }

  .addNewMyIntrestCard p {
    text-align: center;
    margin-top: 15px;
  }

  .myCompetencie p {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .myCompetencie svg {
    font-size: 18px;
  }

  .reviewerInfo {
    display: flex;
  }

  .allBadges {
    display: flex;
  }

  .newTodoForm {
    display: flex;
  }

  .myReminder {
    display: flex;
  }
  .reviewCard {
    margin-bottom: 15px;
  }
  /* STUDY TOUR */
  .createStudyGroupBtn {
    position: relative;
    top: 0;
    text-align: center;
  }
}
@media (min-width: 0) and (max-width: 768px) {
  /* login page */
  h3.loginQuotes {
    font-size: 14px;
    margin-top: 15px;
  }

  .signupOptions {
    display: block !important;
    width: 100%;
  }

  .signupLogo {
    margin-bottom: 15px;
    margin-right: 0;
  }
  .signupTxt p {
    margin-bottom: 5px;
  }

  /* welcome screen */
  #header .userDetailsMenu {
    display: none !important;
  }

  .welcomeCard img {
    width: 100%;
  }

  .welcomeCard {
    margin: 0;
  }

  /* ======== PROFILE SCREEN ============ */
  .ProfileTabNav .nav-item {
    width: 100%;
    margin-bottom: 5px;
  }

  .showTabArrow:after,
  .showTabArrow:before {
    content: inherit;
  }

  a#about-you {
    padding-left: 15px;
  }

  a#learning {
    padding-left: 15px;
  }
  .learningSection .profileBadge {
    display: inline-block !important;
    margin-bottom: 0;
  }

  .profileImg img {
    width: 100%;
  }

  .profileImg {
    margin-bottom: 15px;
  }
  .profileFormTextarea textarea {
    width: 100%;
  }

  .aboutInputAddButton i {
    right: 5%;
    top: 44%;
    left: inherit;
  }

  input {
    width: 100% !important;
  }
  .dragDropCardContainer {
    width: auto;
  }

  .dragDropCard {
    width: auto;
  }
  .showMobileNav {
    display: flex !important;
  }
  /* ======= MY NETWORK  ========= */
  .searchFriendSelectBoxFlex {
    display: block;
    width: 100%;
  }
  .searchFriendInput.input-group,
  .findaMatchSearchInput.input-group {
    width: 100%;
  }
  .findaMatchSearchInput {
    width: 80% !important;
  }

  .findaMatchSearchInput i {
    top: 25px;
    left: -20px;
  }

  .filterDownArrow {
    position: absolute;
    right: -55px;
  }

  .filterDownArrow i {
    top: 0;
  }

  .findMatachHeading h3 {
    font-size: 16px;
  }
  .chat-btn,
  .profile-details-wrap {
    margin-top: 15px;
  }
  .BooktimeMainContainer .col-md-6 {
    padding: 0 15px !important;
  }
  .filterTxt {
    display: none;
  }
  .filterDownArrow i {
    top: 20px;
  }

  .searchFriend.findaMatchCard .searchFriendSelectBoxFlex {
    width: 100%;
  }
  .navbar-toggler-icon {
    margin: 0;
  }
  .classroomCard {
    height: auto;
  }

  .shareClass {
    justify-content: flex-start;
    margin-top: 10px;
  }
  /* study group */
  .linkCard img {
    width: 100%;
    border-radius: 4px 4px 0px 0px;
    height: 250px;
  }
  .linkCard {
    display: block;
  }

  .createStudyGroupBtn {
    position: inherit;
    top: 0;
    margin-bottom: 15px;
    text-align: center;
  }
  .findaMatchSearchInput span {
    right: -30px;
  }
  /* STUDY GROUP DIALOGUE */
  .createStudyGroupModel .form-group {
    width: 100%;
    margin-left: 0;
  }

  .formBasicCheckbox {
    margin-top: 15px;
  }
  .newStudyTourinviteParticipate input {
    width: 1% !important;
  }

  .newStudyTourinviteParticipate .individualBadge {
    display: inline-flex;
    position: inherit;
    margin-top: 5px;
  }

  .formBasicCheckbox input {
    width: auto !important;
  }

  .formBasicMaxStudent select {
    width: 100%;
  }

  .createNewStudyTourModelfooter {
    justify-content: flex-start;
  }

  .createNewStudyTourModelfooter .btn-secondary {
    margin-right: 10px;
  }

  .createNewStudyTourModelfooter .btn {
    font-size: 11px;
  }

  .createStudyGroupModel {
    padding-left: 15px !important;
  }
  /* Community screen */
  .newPostUpload p {
    font-size: 12px;
  }

  .newPostUpload svg {
    font-size: 21px;
  }

  .newPostUploadVideo svg {
    font-size: 27px;
  }

  .communityRootContianer {
    padding: 30px 0;
  }

  /* .newPostUploadFileTypes p {
      display: none;
    } */

  .newPostUpload {
    margin-right: 5px;
  }

  .newPostUploadFileTypes {
    justify-content: center;
  }

  .newPostSearchField input {
    padding: 5px 15px;
    font-size: 12px;
  }

  .postLike {
    margin-right: 12px;
  }

  .likeComment p {
    font-size: 12px;
  }

  .likeComment svg {
    font-size: 18px;
    margin-right: 5px;
  }

  .postLike img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    position: relative;
    top: -3px;
  }

  .postCardFooter {
    padding: 15px;
  }

  .postCardHeader {
    padding: 15px 15px 20px;
  }

  .writeCommentWrapper {
    padding: 10px 15px;
  }
  /* MY PROFILE SCREEN */
  .myProfilePhoto {
    margin-left: 0;
    bottom: 0;
    margin-bottom: 15px;
  }

  /* .flexDefault {
      display: block;
    } */
  .uploadTypeIcons {
    flex-basis: auto;
  }
  .myProifleUserInfo {
    text-align: center;
    position: relative;
    bottom: 90px;
    height: 330px;
  }

  .borderRightDivider {
    border: none;
    height: auto;
  }

  .myProfileEdit {
    display: block;
    height: auto;
  }

  .myProfileDescription {
    margin: 15px 0;
  }

  .myKnowledgePoint {
    margin: 15px 0;
  }

  .myProfileEdit button {
    position: inherit;
  }

  .myIntrestCards img {
    height: 250px;
  }

  .myIntrestCard p {
    margin: 15px 0;
  }

  .addNewIntrest {
    height: 250px;
  }

  .addNewMyIntrestCard p {
    text-align: center;
    margin-top: 15px;
  }

  .myCompetencie p {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .myCompetencie svg {
    font-size: 18px;
  }

  .reviewerInfo {
    display: flex;
  }

  .allBadges {
    display: flex;
  }

  .newTodoForm {
    display: flex;
  }

  .myReminder {
    display: flex;
  }
  .reviewCard {
    margin-bottom: 15px;
  }
}

@media (min-width: 0) and (max-width: 378px) {
  /* COMMUNITY SCREEN */
  .likesInfo p {
    font-size: 11px;
  }

  p.postEventName {
    font-size: 14px;
  }

  p.postEventTime {
    font-size: 11px;
  }

  .eventOrginzerDetails p {
    font-size: 12px;
  }
}
/* .barBtn img {
      width: 10px;
      height: 10px;
    }
    
    .barBtn img {
      margin-right: 5px;
    }
    
    .barBtn i {
      font-size: 14px !important;
    }
    
    
    .barBtn p {
      font-size: 12px;
      margin-right: 10px;
    } */

.action-bar {
  margin-left: 10px;
}

.action-item {
  margin: 5px !important;
  text-align: center;
}

.action-item > span {
  margin: 0px !important;
  text-align: center;
}

.colorLikeImg {
  width: 28px !important;
  height: 28px !important;
  margin-top: -5px;
  margin-right: 10px;
}

.commentInput {
  flex: 1 1;
  margin-left: 6px;
  margin-left: 25px;
}

.commentList {
  padding: 10px 25px;
}

.postEditor .DraftEditor-editorContainer {
  border: 1px solid #ffffff !important;
  border-top: 0px !important;
  border-bottom: 0px !important;
  border-radius: 0px 0px 0px 0px;
  overflow: hidden;
  height: 195px !important;
  overflow-y: scroll;
  overflow-x: hidden;
}
.postEditor .rdw-editor-toolbar {
  border-top: 6px solid black;
  padding: 0px !important;
  background: #d6d6d6 !important;
  padding-top: 5px !important;
}

.postEditor .rdw-editor-toolbar {
  padding: 0px !important;
  background: #ffffff !important;
  padding-top: 5px !important;
  border: 1px solid #ffffff !important;
  border-bottom: 0px !important;
  border-radius: 4px 4px 0px 0px !important;
}

.postEditor .rdw-option-wrapper {
  padding: 10px 0px 10px 0px !important;
  background: transparent !important;
  color: black !important;
  border: 0px solid #f1f1f1 !important;
}

.homePostInputWrapper select {
  width: 150px;
  border: none;
  border-radius: 6px;
  padding: 5px 5px;
  font-size: 12px;
  color: #0c0c0c;
}

.homePostInputWrapper .MuiOutlinedInput-input {
  padding: 5px;
  padding-left: 6px;
}

.dot-slider {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 1rem;
  gap: 1rem;
}
.dot-slider > li {
  height: 10px;
  width: 10px;
  background: brown;
  border-radius: 50%;
  cursor: pointer;
}

.image-video-loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;
}
.image-video-loader::before,
.image-video-loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid #fff;
  animation: prixClipFix 2s linear infinite;
}
.image-video-loader::after {
  inset: 8px;
  transform: rotate3d(90, 90, 0, 180deg);
  border-color: #ff3d00;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }
  75%,
  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }
}
.video-overlay {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  background: white;
  border-radius: 50%;
  padding: 7px;
}

.v2-container {
  height: 23rem;
  overflow: hidden;
  background: black;
  display: flex;
  align-items: center;
  justify-content: center;
}
.border-dot {
  border: 1px solid blue;
  background: white !important;
}
.polling-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: none;
  color: black;
  border: 1px solid black !important;
  /* box-shadow: #000000e8 5px 5px; */
  border-radius: 8px !important;
  overflow: hidden;
}
.polling-box .select {
  box-shadow: none;
  background: #a00030;
  color: white;
}

.background-part {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #a52a2a26;
  border-radius: 7px 12px 15px 7px;
  color: white;
  overflow: hidden;
}
.polling-box p {
  font-size: 14px;
  font-weight: 500;
  color: black;
  z-index: 1;
}
.not-hover:hover {
  background: white !important;
}

.row {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
}

/* Create four equal columns that sits next to each other */
.column {
  flex: 25% 1;
  max-width: 25%;
  padding: 0 4px;
}

.column img {
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
}

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 800px) {
  .column {
    flex: 50% 1;
    max-width: 50%;
  }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .column {
    flex: 100% 1;
    max-width: 100%;
  }
}

.video-body {
  background-color: #151845;
  padding: 0;
  margin: 0;
  position: sticky;
  height: 22rem;
  cursor: wait;
}
.video-container {
  /* height: 370px; */
  /* width: 370px; */
  /* border: 1px solid #fff; */
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
}
.video-moon {
  /* background-color: #39beff; */
  background-color: #d4d3bd85;
  height: 170px;
  width: 170px;
  border-radius: 50%;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
}
.video-crater {
  background-color: white;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  position: relative;
}
.video-crater:before {
  content: "";
  position: absolute;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  box-shadow: -5px 0 0 2px #00000054;
  top: 2px;
  left: 7px;
}
.video-crater1 {
  top: 27px;
  left: 90px;
  transform: scale(0.9);
}
.video-crater2 {
  bottom: 15px;
  left: 61px;
  transform: scale(0.6);
}
.video-crater3 {
  left: 15px;
  transform: scale(0.75);
}
.video-crater4 {
  left: 107px;
  top: 32px;
  transform: scale(1.18);
}
.video-crater5 {
  left: 33px;
  bottom: 4px;
  transform: scale(0.65);
}
.video-shadow {
  height: 190px;
  width: 190px;
  box-shadow: 21px 0 0 5px rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  position: relative;
  bottom: 157.5px;
  right: 46px;
}
.video-eye {
  background-color: #161616;
  height: 12px;
  width: 12px;
  position: relative;
  border-radius: 50%;
}
.video-eye-l {
  bottom: 255px;
  left: 59px;
}
.video-eye-r {
  bottom: 267px;
  left: 101px;
}
.video-mouth {
  height: 5px;
  width: 10px;
  border: 3px solid #161616;
  position: relative;
  bottom: 262px;
  left: 79px;
  border-top: none;
  border-radius: 0 0 10px 10px;
}
.video-blush {
  background-color: #1ca4f9;
  height: 7.5px;
  width: 7.5px;
  position: relative;
  border-radius: 50%;
}
.video-blush1 {
  bottom: 273px;
  left: 50px;
}
.video-blush2 {
  bottom: 281px;
  left: 115px;
}
.video-orbit {
  height: 280px;
  width: 280px;
  /* border: 1px solid #fff; */
  border-radius: 50%;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  animation: spin 10s infinite linear;
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.video-rocket {
  background-color: #fafcf7;
  height: 50px;
  width: 25px;
  border-radius: 50% 50% 0 0;
  position: relative;
  left: -11px;
  top: 115px;
}
.video-rocket:before {
  content: "";
  position: absolute;
  background-color: #a00030;
  height: 20px;
  width: 55px;
  border-radius: 50% 50% 0 0;
  z-index: -1;
  right: -15px;
  bottom: 0;
}
.video-rocket:after {
  content: "";
  position: absolute;
  background-color: #a00030;
  height: 4px;
  width: 15px;
  border-radius: 0 0 2px 2px;
  bottom: -4px;
  left: 4.3px;
}
.video-window {
  background-color: #151845;
  height: 10px;
  width: 10px;
  border: 2px solid #b8d2ec;
  border-radius: 50%;
  position: relative;
  top: 17px;
  left: 5px;
}
.video-color {
  color: white;
  padding: 7px;
  height: 3rem;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  position: relative;
  z-index: 1;
}

.line {
  display: inline-block;
  width: 4px;
  height: 15px;
  border-radius: 15px;
  background-color: coral;
}

.load-1 .line:nth-last-child(1) {
  animation: loadingA 1.5s 1.5s infinite;
  margin-right: 5px;
}
.load-1 .line:nth-last-child(2) {
  animation: loadingA 1.5s 0.6s infinite;
  margin-right: 5px;
}
.load-1 .line:nth-last-child(3) {
  animation: loadingA 1.5s 0.3s infinite;
  margin-right: 5px;
}
.load-1 .line:nth-last-child(4) {
  animation: loadingA 1.5s 0s infinite;
  margin-right: 5px;
}
@keyframes loadingA {
  0% {
    height: 15px;
  }
  50% {
    height: 35px;
  }
  100% {
    height: 15px;
  }
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
  grid-gap: 10px;
  gap: 10px;
}

.image-item {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  align-items: center;
  background: black;
  border-radius: 5px;
}

.image-item img {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease-in-out;
}

.image-item.zoomed img {
  /* transform: scale(1.2); */
}

.single-image {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  border-radius: 5px;
  max-height: 40rem;
}

.single-image img {
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease-in-out;
  object-fit: cover;
}

.single-image.zoomed img {
  /* transform: scale(1.2); */
}
.count-item {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 33px;
  color: white;
  background: rgb(0 0 0 / 60%);
  font-weight: 600;
}
/* .react-fb-image-grid {
  margin: auto;
}

.react-fb-image-grid * {
  margin: 0;
  box-sizing: border-box;
  padding: 0;
}

 

.borders {
  border: none;
  border-radius: 0;
}

.background-react-fb-image-grid {
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}

.height-one {
  height: 100%;
  width: 100% !important;
}

.height-two {
  height: 65%;
  width: 100% !important;
}

.background-react-fb-image-grid-cover {
  background-color: #222;
  opacity: 0.8;
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
}

.cover-text {
  right: 0;
  left: 0;
  bottom: 0;
  color: white;
  font-size: 1.2em !important;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0%, -50%);
  -ms-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
  text-align: center;
}

.cover-text > p {
  margin: 0;
  position: absolute;
  font-size: 1.5em !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.slide {
  height: 0;
  bottom: 100%;
  transition: 0.4s ease;
  overflow: hidden;
}

.borders:hover .slide {
  bottom: 0;
  height: auto;
}

.borders:hover .animate-text {
  top: 62%;
} */

.conf0 {
  fill: #fc6394;
}
.conf1 {
  fill: #ef3c8a;
}
.conf2 {
  fill: #5adaea;
}
.conf3 {
  fill: #974cbe;
}
.conf4 {
  fill: #3cbecd;
}
.conf5 {
  fill: #813bbe;
}
.conf6 {
  fill: #f9b732;
}
.conf7 {
  display: none;
  fill: none;
  stroke: #000000;
  stroke-miterlimit: 10;
}
.conf8 {
  fill: none;
  stroke: #f9b732;
  stroke-width: 9;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}

.confetti-cone {
  transform-origin: 200px 50px;
  animation: confetti-cone1 1.2s ease infinite;
}
@keyframes confetti-cone1 {
  0% {
    transform: translate(40px, 95px) rotate(45deg) scale(1, 1);
  }
  15% {
    transform: translate(10px, 145px) rotate(45deg) scale(1.1, 0.85);
  }
  100% {
    transform: translate(40px, 105px) rotate(45deg) scale(1, 1);
  }
}

#yellow-strip {
  fill: none;
  stroke: #f9b732;
  stroke-width: 9;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  animation: confdash 1.2s ease infinite;
}

@keyframes confdash {
  0% {
    stroke-dasharray: 1000;
    stroke-dashoffset: 500;
    transform: translate(-30px, 30px);
    opacity: 0;
  }
  2% {
    stroke-dasharray: 1000;
    stroke-dashoffset: 500;
    transform: translate(-30px, 30px);
    opacity: 0;
  }
  35% {
    stroke-dasharray: 1000;
    stroke-dashoffset: 900;
    transform: translate(-2px, 0px);
    opacity: 1;
  }

  85% {
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    transform: translate(1px, -5px);
    opacity: 1;
  }
  90% {
    stroke-dashoffset: 1000;
    stroke-dashoffset: 1000;
    transform: translate(2px, -8px);
    opacity: 0;
  }
  100% {
    stroke-dashoffset: 1000;
    stroke-dashoffset: 500;
    transform: translate(2px, -8px);
    opacity: 0;
  }
}

#a2 {
  transform-origin: 310.9px 241px;
  animation: confa 1.2s ease-out infinite;
}

#a1 {
  transform-origin: 276px 246px;
  animation: confa 1.2s ease-out infinite;
}

@keyframes confa {
  0% {
    opacity: 0;
    transform: translate(-30px, 20px) rotate(0);
  }
  15% {
    opacity: 1;
    transform: translate(25px, -10px) rotate(60deg);
  }
  80% {
    opacity: 1;
    transform: translate(33px, -18px) rotate(180deg);
  }
  100% {
    opacity: 0;
    transform: translate(37px, -23px) scale(0.5) rotate(230deg);
  }
}

#b1 {
  transform-origin: 195.2px 232.6px;
  animation: confb 1.2s ease-out infinite;
}

#b2 {
  transform-origin: 230.8px 219.8px;
  animation: confb 1.2s ease-out infinite;
}
#b3 {
  transform-origin: 222.8px 190.6px;
  animation: confb 1.2s ease-out infinite;
}

#b4 {
  transform-origin: 262px 188.5px;
  animation: confb 1.2s ease-out infinite;
}

#b5 {
  transform-origin: 282.3px 170.6px;
  animation: confb 1.2s ease-out infinite;
}

@keyframes confb {
  0% {
    opacity: 0;
    transform: translate(-30px, 20px) rotate(0);
  }
  12% {
    opacity: 1;
    transform: translate(25px, -10px) rotate(60deg);
  }
  76% {
    opacity: 1;
    transform: translate(33px, -18px) rotate(180deg);
  }
  100% {
    opacity: 0;
    transform: translate(37px, -23px) scale(0.5) rotate(240deg);
  }
}

#c1 {
  transform-origin: 174.8px 183.4px;
  animation: confc 1.2s ease-out infinite;
}
#c2 {
  transform-origin: 178.9px 156.2px;
  animation: confc 1.2s ease-out infinite;
}

#c3 {
  transform-origin: 206.7px 140px;
  animation: confc 1.2s ease-out infinite;
}

#c4 {
  transform-origin: 213.5px 120.2px;
  animation: confc 1.2s ease-out infinite;
}

@keyframes confc {
  0% {
    opacity: 0.7;
    transform: translate(-30px, 20px) rotate(0);
  }
  18% {
    opacity: 1;
    transform: translate(5px, -10px) rotate(60deg);
  }
  76% {
    opacity: 1;
    transform: translate(13px, -18px) rotate(180deg);
  }
  100% {
    opacity: 0;
    transform: translate(17px, -23px) scale(0.5) rotate(230deg);
  }
}

#d1 {
  transform-origin: 127px 176px;
  animation: confd 1.2s ease-out infinite;
}
#d2 {
  transform-origin: 133px 118px;
  animation: confd 1.2s ease-out infinite;
}
#d3 {
  transform-origin: 152px 100px;
  animation: confd 1.2s ease-out infinite;
}

@keyframes confd {
  0% {
    opacity: 0.7;
    transform: translate(-20px, 20px) rotate(0);
  }
  18% {
    opacity: 1;
    transform: translate(-5px, -10px) rotate(60deg);
  }
  76% {
    opacity: 1;
    transform: translate(-8px, -18px) rotate(180deg);
  }
  100% {
    opacity: 0;
    transform: translate(-10px, -23px) scale(0.5) rotate(230deg);
  }
}
.point-svg-text {
  animation: 2s anim-popoutin ease infinite;
  position: absolute;
  width: max-content;
  font-weight: bold;
  font-size: 14px;
  left: 48%;
  top: 25%;
  /* background: #808080; */
  /* position: absolute;
  top: 18%;
  width: max-content;
  left: 38%;
  font-weight: bold;
  color: red;
  font-size: 27px;
  animation: point-text 3s infinite;
  z-index: -1; */
}
@keyframes anim-popoutin {
  0% {
    color: black;
    transform: scale(0);
    opacity: 0;
    text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }
  25% {
    color: red;
    transform: scale(2);
    opacity: 1;
    text-shadow: 3px 10px 5px rgba(0, 0, 0, 0.5);
  }
  50% {
    color: black;
    transform: scale(1);
    opacity: 1;
    text-shadow: 1px 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    /* animate nothing to add pause at the end of animation */
    transform: scale(1);
    opacity: 1;
    text-shadow: 1px 0 0 rgba(0, 0, 0, 0);
    color: black;
  }
}
/* @keyframes point-text {
  0% {
    top: 3%;
    left: -3%;
    transform: rotate(313deg);
    color: #fc6394;
  }
  50% {
    top: -12%;
    left: 12%;
    transform: rotate(313deg);
    color: green;
  }
  75% {
    top: 13%;
    left: 24%;
    transform: rotate(0deg);
    color: blue;
  }
  100% {
    top: 26%;
    left: 40%;
    transform: rotate(0deg);
    color: brown;
  }
} */

/* .trending-users-wrap {
  height: 100%;
  width: 100%;
  background: linear-gradient(90deg, #000940a1 0%, #a0009c 100%);
  position: absolute;
  top: 0;
}

.trending-users-wrap div {
  height: 60px;
  width: 60px;
   border: 1px solid lightpink;
  border-radius: 50px;
  position: absolute;
  top: 10%;
  left: 10%;
  animation: 4s linear infinite;
}
div .dot {
  height: 10px;
  width: 10px;
  border-radius: 50px;
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 20%;
  right: 20%;
}
.trending-users-wrap div:nth-child(1) {
  top: 20%;
  left: 20%;
  animation: animate 8s linear infinite;
}
.trending-users-wrap div:nth-child(2) {
  top: 60%;
  left: 80%;
  animation: animate 10s linear infinite;
}
.trending-users-wrap div:nth-child(3) {
  top: 40%;
  left: 40%;
  animation: animate 3s linear infinite;
}
.trending-users-wrap div:nth-child(4) {
  top: 66%;
  left: 30%;
  animation: animate 7s linear infinite;
}
.trending-users-wrap div:nth-child(5) {
  top: 90%;
  left: 10%;
  animation: animate 9s linear infinite;
}
.trending-users-wrap div:nth-child(6) {
  top: 30%;
  left: 60%;
  animation: animate 5s linear infinite;
}
.trending-users-wrap div:nth-child(7) {
  top: 70%;
  left: 20%;
  animation: animate 8s linear infinite;
}
.trending-users-wrap div:nth-child(8) {
  top: 75%;
  left: 60%;
  animation: animate 10s linear infinite;
}
.trending-users-wrap div:nth-child(9) {
  top: 50%;
  left: 50%;
  animation: animate 6s linear infinite;
}
.trending-users-wrap div:nth-child(10) {
  top: 45%;
  left: 20%;
  animation: animate 10s linear infinite;
}
.trending-users-wrap div:nth-child(11) {
  top: 10%;
  left: 90%;
  animation: animate 9s linear infinite;
}
.trending-users-wrap div:nth-child(12) {
  top: 20%;
  left: 70%;
  animation: animate 7s linear infinite;
}
.trending-users-wrap div:nth-child(13) {
  top: 20%;
  left: 20%;
  animation: animate 8s linear infinite;
}
.trending-users-wrap div:nth-child(14) {
  top: 60%;
  left: 5%;
  animation: animate 6s linear infinite;
}
.trending-users-wrap div:nth-child(15) {
  top: 90%;
  left: 80%;
  animation: animate 9s linear infinite;
}
@keyframes animate {
  0% {
    transform: scale(0) translateY(0) rotate(70deg);
  }
  100% {
    transform: scale(1.3) translateY(-100px) rotate(360deg);
  }
}
.trending-user {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 9px;
  overflow: hidden;
  z-index: 12;
}
.trending-user > img {
  width: 109px;
  height: 109px;
  position: relative;
  border-radius: 50%;
  z-index: 1;
  object-fit: cover;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.trending-title {
  border: 1px solid;
  padding: 0.5rem;
  border-radius: 5px;
  margin-bottom: 1rem !important;
  margin-top: 2rem !important;
  font-weight: bold;
} */

.groupCard {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.09);
  border-radius: 0.4rem;
}

.groupCard {
  word-wrap: break-word;
}

.groupCard-body {
  flex: 1 1 auto;
  padding: 1.25rem 1.25rem;
}

@media (min-width: 768px) {
  .groupCard-text-md-start {
    text-align: left !important;
  }
}

@media (min-width: 768px) {
  .groupCard-d-md-flex {
    /* display: -webkit-box !important;
  display: -ms-flexbox !important; */
    display: flex !important;
  }
}

.groupCard-align-items-start {
  /* -ms-flex-align: start !important; */
  align-items: flex-start !important;
}

.groupCard-flex-wrap {
  /* -ms-flex-wrap: wrap !important; */
  flex-wrap: wrap !important;
}

.groupCard-mb-2 {
  margin-bottom: 0.5rem !important;
}

.groupCard-avatar-xl {
  height: 5.125rem;
  width: 5.125rem;
}

.groupCard-avatar {
  height: 3rem;
  /* width: 3rem; */
  position: relative;
  display: inline-block;
  /* -ms-flex-negative: 0 !important; */
  flex-shrink: 0 !important;
}

.groupCard-avatar-img {
  width: 100%;
  height: 100%;
  /* -o-object-fit: cover; */
  object-fit: cover;
}

.groupCard-border-0 {
  border: 0 !important;
}

@media (min-width: 768px) {
  .groupCard-ms-md-4 {
    margin-left: 1.5rem !important;
  }
}

.groupCard-mt-3 {
  margin-top: 1rem !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

h5,
.h5 {
  font-size: 1.171875rem;
}

small,
.small {
  font-weight: 400;
}

.groupCard-text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

small,
.small {
  font-size: 0.875em;
}

@media (min-width: 768px) {
  .groupCard-justify-content-md-start {
    /* -ms-flex-pack: start !important; */
    justify-content: flex-start !important;
  }
}

.groupCard-nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

ol,
ul,
dl {
  margin-top: 0;
}

@media (min-width: 768px) {
  .text-md-start {
    text-align: left !important;
  }
}

.text-center {
  /* text-align: center !important; */
}

.groupCard-nav.groupCard-nav-divider .groupCard-nav-item {
  display: flex;
  /* -webkit-box-align: center;
  align-items: center; */
}

@media (min-width: 992px) {
  .groupCard-ms-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .groupCard-justify-content-md-start {
    /* -ms-flex-pack: start !important; */
    justify-content: flex-start !important;
  }
}

.groupCard-mt-3 {
  margin-top: 1rem !important;
}

.groupCard-align-items-center {
  /* -ms-flex-align: center !important; */
  align-items: center !important;
}

.groupCard-justify-content-center {
  /* -webkit-box-pack: center !important;
  -ms-flex-pack: center !important; */
  /* justify-content: center !important; */
}

.groupCard-d-flex {
  /* display: -webkit-box !important;
  display: -ms-flexbox !important; */
  display: flex !important;
}

.groupCard-btn-primary-soft {
  color: #0f6fec;
  background-color: rgba(15, 111, 236, 0.1);
}

.groupCard-btn {
  outline: 0;
}

.groupCard-me-2 {
  margin-right: 0.5rem !important;
}

.groupCard-btn-sm,
.groupCard-btn-group-sm > .groupCard-btn {
  padding: 0.4rem 0.8rem;
  font-size: 0.8125rem;
  border-radius: 0.2rem;
}

.groupCard-btn {
  display: inline-block;
  font-weight: 500;
  line-height: 1.5;
  /* color: #676a79; */
  text-align: center;
  /* vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none; */
  -ms-user-select: none;
  -webkit-user-select: none;
          user-select: none;
  /* background-color: transparent; */
  border: 1px solid transparent;
  /* padding: 0.5rem 1rem;
  font-size: 0.9375rem;
  border-radius: 0.4rem;
  -webkit-transition: all 0.3s ease-in-out; */
  transition: all 0.3s ease-in-out;
}

.groupCard-icon-sm {
  height: 2rem;
  width: 2rem;
  font-size: 0.7em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.groupCard-btn-dark-soft {
  color: #14191e;
  background-color: rgba(20, 25, 30, 0.1);
}

.groupCard-bi::before,
[class^="bi-"]::before,
[class*=" bi-"]::before {
  display: inline-block;
  font-style: normal;
  font-weight: normal !important;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: -0.125em;
}

.groupCard-bi-three-dots::before {
  content: "\f5d4";
}

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative;
}

.dropdown-menu {
  box-shadow: 0px 20px 30px rgba(83, 88, 93, 0.1),
    0px 0px 30px rgba(83, 88, 93, 0.2);
}

.dropdown-menu-end {
  --bs-position: end;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 15rem;
  padding: 1rem 0;
  margin: 0;
  font-size: 0.9375rem;
  color: #676a79;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 0.4rem;
}

.groupCard-avatar-group {
  padding: 0;
  /* display: -webkit-box;
  display: -ms-flexbox; */
  display: flex;
  border: 2px solid #fff;
}

@media (min-width: 768px) {
  .groupCard-justify-content-md-start {
    /* -ms-flex-pack: start !important; */
    justify-content: flex-start !important;
  }
}

.groupCard-mb-0 {
  margin-bottom: 0 !important;
}

.groupCard-flex-wrap {
  /* -ms-flex-wrap: wrap !important; */
  flex-wrap: wrap !important;
}

.groupCard-list-unstyled {
  /* padding-left: 0; */
  list-style: none;
}

.groupCard-avatar-group > li:not(:last-child) {
  margin-right: -0.8rem;
}

.groupCard-avatar-group > li {
  position: relative;
}

.groupCard-avatar-xs {
  height: 2.1875rem !important;
  width: 2.1875rem !important;
}

.groupCard-avatar {
  height: 3rem;
  width: 3rem;
  position: relative;
  display: inline-block;
  flex-shrink: 0 !important;
}

.groupCard-avatar-group .groupCard-avatar-img {
  border: 2px solid #fff;
}

.groupCard-avatar-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.rounded-circle {
  border-radius: 50% !important;
}

.groupCardStatus {
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #676a79;
}

.groupCard-nav.groupCard-nav-divider
  .groupCard-nav-item
  + .groupCard-nav-item::before {
  content: "•";
  color: inherit;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  opacity: 0.8;
}

.groupCard-avatar-group > li {
  position: relative;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #676a79;
}

.groupCard-small {
  font-weight: 400 !important;
}

.groupCard-small {
  font-size: 0.875em !important;
}

.main-card-footer {
  padding: 1.25rem 1.25rem;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  background-color: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.09);
}

.groupCard-h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  line-height: 1.25;
  color: #14191e;
}

@media (min-width: 992px) {
  .groupd-desc-ms-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .groupd-desc-justify-content-md-start {
    justify-content: flex-start !important;
  }
}
.groupd-desc-mt-3 {
  margin-top: 1rem !important;
}
.groupd-desc-align-items-center {
  align-items: center !important;
}
.groupd-desc-justify-content-center {
  justify-content: center !important;
}
.groupd-desc-d-flex {
  display: flex !important;
}

.groupd-desc-btn-primary-soft {
  color: #0f6fec;
  background-color: rgba(15, 111, 236, 0.1);
}
.groupd-desc-btn {
  outline: 0;
}
.groupd-desc-me-2 {
  margin-right: 0.5rem !important;
}
.groupd-desc-btn-sm,
.btn-group-sm > .btn {
  padding: 0.4rem 0.8rem;
  font-size: 0.8125rem;
  border-radius: 0.2rem;
}
.groupd-desc-btn {
  display: inline-block;
  font-weight: 500;
  line-height: 1.5;
  color: #676a79;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.5rem 1rem;
  font-size: 0.9375rem;
  border-radius: 0.4rem;
  transition: all 0.3s ease-in-out;
}

.skeleton-loader {
  margin: auto;
  display: block;
  position: relative;
  background: #fff;
  box-sizing: border-box;
  height: 10rem;
  overflow: hidden;
}
.skeleton-loader::after {
  content: "";
  width: 100%;
  height: 100%;
  top: 15px;
  left: 15px;
  position: absolute;
  background-image: linear-gradient(
      100deg,
      transparent,
      rgba(255, 255, 255, 0.5) 50%,
      transparent 80%
    ),
    linear-gradient(#ddd 56px, transparent 0),
    linear-gradient(#ddd 24px, transparent 0),
    linear-gradient(#ddd 18px, transparent 0),
    linear-gradient(#ddd 66px, transparent 0);
  background-repeat: no-repeat;
  background-size: 90px 130px, 55px 56px, 90% 30px, 90% 20px, 96% 5rem;
  background-position: 0% 0, 0 0, 70px 5px, 70px 38px, 0px 66px;
  box-sizing: border-box;
  animation: animloader 1s linear infinite;
}

@keyframes animloader {
  0% {
    background-position: 0% 0, 0 0, 70px 5px, 70px 38px, 0px 66px;
  }
  100% {
    background-position: 150% 0, 0 0, 70px 5px, 70px 38px, 0px 66px;
  }
}

.skeleton-loader-group {
  margin: auto;
  display: block;
  position: relative;
  background: #fff;
  box-sizing: border-box;
  height: 8rem;
  overflow: hidden;
}
.skeleton-loader-group::after {
  content: "";
  width: 100%;
  height: 100%;
  top: 15px;
  left: 15px;
  position: absolute;
  background-image: linear-gradient(
      100deg,
      transparent,
      rgba(255, 255, 255, 0.5) 50%,
      transparent 80%
    ),
    linear-gradient(#ddd 57px, transparent 0),
    linear-gradient(#ddd 15px, transparent 0),
    linear-gradient(#ddd 16px, transparent 0),
    linear-gradient(#ddd 37px, transparent 0);
  background-repeat: no-repeat;
  background-size: 90px 130px, 55px 56px, 90% 30px, 90% 20px, 96% 5rem;
  background-position: 0% 0, 0 0, 70px 5px, 70px 38px, 0px 66px;
  box-sizing: border-box;
  animation: animloader 1s linear infinite;
}

.groupPost-card-body {
    flex: 1 1 auto;
    padding: 1.25rem 1.25rem;
}
.groupPost-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.09);
    border-radius: 0.4rem;
}

.groupPost-mb-3 {
    margin-bottom: 1rem !important;
}
.groupPost-d-flex {
   
    display: flex !important;
}

.groupPost-avatar-xs {
    height: 2.1875rem;
    width: 2.1875rem;
}
.groupPost-avatar {
    position: relative;
    display: inline-block;
    flex-shrink: 0 !important;
}
.groupPost-me-2 {
    margin-right: 0.5rem !important;
}


.groupPost-avatar-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.groupPost-rounded-circle {
    border-radius: 50% !important;
}
.groupPost-img {
    max-width: 100%;
}

.groupPost-w-100 {
    width: 100% !important;
}

.groupPost-pe-4 {
    padding-right: 1.5rem !important;
}

.groupPost-border-0 {
    border: 0 !important;
}
.groupPost-form-control {
    display: block;
    width: 100%;
    padding: 0.5rem 1rem;
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 1.5;
    color: #29292e;
    background-color: #fff;
    background-clip: padding-box;
   
    appearance: none;
    border-radius: 0.4rem;
  }

  .groupPost-nav-stack {

    display: flex !important;
    flex-direction: row;
    align-items: center;
    align-self: stretch;
    grid-gap: 0.7rem;
    gap: 0.7rem;
}

.groupPost-fw-normal {
    font-weight: 500 !important;
}
.groupPost-nav {

    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}
small, .groupPost-small {
    font-size: 0.875em;
}

.groupPost-nav-stack .groupPost-nav-link {
    padding-left: 0;
    padding-right: 0;
}
.groupPost-nav-pills .groupPost-nav-link {
    cursor: pointer;
    margin-bottom: 15px;
    padding: 0.5rem 1rem;
}
.groupPost-nav-pills .groupPost-nav-link {
    background: none;
    border: 0;
    border-radius: 0.4rem;
}
.groupPost-bg-light {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}
.groupPost-py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
}
.groupPost-px-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
}



.groupPost-mb-0 {
    margin-bottom: 0 !important;
}
.groupPost-nav-link {
    display: block;
    padding: 0.25rem 0.75rem;
    color: #676a79 !important;
}

.connectionSumbitLabel {
    color: #ffffff !important;
    font-size: 12px !important;
    background: linear-gradient(to right, #EF5A3E 5%, #A00030 100%);
    padding: 6px 10px !important;
    margin-left: 12px !important;
    float: right !important;
    width: 100% !important;
    border-radius: 4px !important;
  }
  

  .groupPost-nav-link:hover{
    color: #007bff !important;
}
/* ================ WELCOME SCREEN ======== */
.greyBg {
  background: #f2f2f2;
}

.linkedin img {
  border-radius: 40%;
  width: 30px;
  height: 30px;
  margin-top: 150%;
  /* padding-top: 50%; */
}

.welcomeCard {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 5px 5px 10px #0000000d;
  border-radius: 4px;
  padding: 20px 25px;
  margin: 0 60px;
}

#welcome {
  padding-top: 5%;
}

.welcomeCard img {
  width: 380px;
  height: auto;
}

.welcomeDetails p {
  color: #000000cc;
  font-size: 16px;
  margin-bottom: 20px;
}

.welcomeDetails {
  margin-top: 10%;
}

b.welcomeBlueText {
  color: #60c2e7;
}

button.letsGoBtn {
  background: linear-gradient(to right, #ef5a3e 5%, #a00030 100%);
  border-radius: 4px;
  border: none;
  color: #fff;
  width: 125px;
  padding: 6px;
  font-family: "nunitoBold", sans-serif;
  cursor: pointer;
}
button.letsGoBtn:focus {
  outline: none;
}

/* =========== PROFILE PAGE ========= */
p.highlitedTabText {
  color: #b5b5b5;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 5px;
}

.profileTab {
  text-align: left;
}

.profileNumber p {
  color: #b5b5b5;
  font-size: 24px;
  font-weight: bold;
}

.profileNumber {
  margin-right: 15px;
  align-items: center;
  justify-content: center;
  display: flex;
  min-width: 30px;
}

p.tabDetailedDesc {
  color: #b5b5b5cc;
  font-size: 13px;
}

.ProfileTabNav .nav-item {
  background: #fff;
  border: 1px solid #e4e4e5;
}

.ProfileTabNav .nav-item {
  position: relative;
  border-right: 1px solid #e4e4e5 !important;
  border: none;
}
.showTabArrow:after,
.showTabArrow:before {
  left: 100%;
  top: 70%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.showTabArrow:after {
  border-color: rgba(34, 34, 34, 0);
  border-left-color: #ffffff;
  border-width: 15px;
  margin-top: -30px;
  z-index: 1;
}
.showTabArrow:before {
  border-color: rgba(228, 228, 229, 0);
  border-left-color: #dee2e6;
  border-width: 16px;
  margin-top: -31px;
  z-index: 1;
}

.ProfilePage {
  padding-top: 25px;
  padding-bottom: 25px;
}

a#about-you {
  padding-left: 30px;
}

a#learning {
  padding-left: 30px;
}

nav.ProfileTabNav {
  padding-bottom: 35px;
}

nav.ProfileTabNav .active p.highlitedTabText {
  color: #60c2e7;
  opacity: 1;
}

nav.ProfileTabNav .active .profileNumber p {
  color: #212121;
}

nav.ProfileTabNav .active p.tabDetailedDesc {
  color: #000000cc;
}
nav.ProfileTabNav .active p.highlitedTabText {
  color: #60c2e7;
  opacity: 1;
}

nav.ProfileTabNav .active .profileNumber p {
  color: #212121;
}

nav.ProfileTabNav .active p.tabDetailedDesc {
  color: #000000cc;
}
.profileTabContent:-ms-input-placeholder {
  font-family: "Lato", sans-serif;
  font-size: 13px;
}
.profileTabContent::placeholder {
  font-family: "Lato", sans-serif;
  font-size: 13px;
}
.profilePageTabContent {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 5px 5px 10px #0000000d;
  border-radius: 4px;
  padding: 25px;
}

.profileImg {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.profileDetailsForm label {
  display: block;
  font-family: "Lato", sans-serif;
  font-size: 13px;
  font-weight: bold;
  color: #333333de;
  margin-bottom: 5px;
}

.genderRadioButton label {
  display: inline;
  display: initial;
}

.profileFormInput input {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dededf;
  border-radius: 4px;
  margin-bottom: 15px;
  width: 260px;
  padding: 6px;
  font-size: 13px;
}

.genderDetails {
  margin-bottom: 15px;
}

.choosePhoto {
  align-items: center;
}

.choosePhotoImg img {
  width: 115px;
  height: auto;
}

.choosePhotoBtn button {
  background: #dfdfdf 0% 0% no-repeat padding-box;
  border-radius: 4px;
  border: none;
  width: 125px;
  padding: 6px;
  letter-spacing: 0px;
  color: #333333;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-weight: bold;
}

.choosePhotoImg {
  margin-right: 15px;
}

.profileSubmitBtn button {
  background: #ff1744;
  border-radius: 4px;
  border: none;
  /* width: 154px; */
  color: #fff;
  padding: 7.5px;
  font-size: 14px;
  font-weight: bold;
  font-family: "Lato", sans-serif;
  letter-spacing: 0;
}
button {
  cursor: pointer;
}
.profileTabContent button:focus {
  outline: none;
}
.formRadioInput [type="radio"]:checked,
.formRadioInput [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.formRadioInput [type="radio"]:checked + label,
.formRadioInput [type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  line-height: 23px;
  display: inline-block;
  color: #666;
}
.formRadioInput [type="radio"]:checked + label:before,
.formRadioInput [type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 3px;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}
.formRadioInput [type="radio"]:checked + label:after,
.formRadioInput [type="radio"]:not(:checked) + label:after {
  content: "";
  width: 8px;
  height: 8px;
  background: #0c0c0c;
  position: absolute;
  top: 8px;
  left: 5px;
  border-radius: 100%;
  transition: all 0.2s ease;
}
.formRadioInput [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
.formRadioInput [type="radio"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}

.formRadioInput {
  margin-right: 10px;
}

/* ========= model ======== */
.uploadEmptyCard {
  border: 1px solid #707070;
  background: #e1e1e1;
  text-align: center;
}

.uploadEmptyCard img {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  border: 5px dashed #ffffff;
}

.uploadButton button {
  display: block;
  width: 175px;
  border: none;
  background: #dfdfdf 0% 0% no-repeat padding-box;
  border-radius: 4px;
  color: #333333;
  font-size: 14px;
  font-weight: bold;
  font-family: "Lato", sans-serif;
  padding: 7.5px;
  margin-bottom: 15px;
}

.uploadButton {
  margin-left: 35px;
}
.createPostModel button {
  margin-left: 0 !important;
}
h5#uploadModelLabel {
  font-size: 16px;
  font-weight: bold;
  color: #000000de;
}

div#uploadModel .modal-header {
  border-bottom: 1px solid #707070;
}

#uploadModel .modal-footer {
  border: navajowhite;
}

#uploadModel .btn-primary {
  background: linear-gradient(to right, #ef5a3e 5%, #a00030 100%);
  border-radius: 4px;
  border: none;
  color: #ffffff;
  font-size: 16px;
  width: 120px;
  padding: 11.5px;
  font-weight: bold;
}

#uploadModel .btn-secondary {
  background: no-repeat;
  border: none;
  color: #333333;
  font-size: 14px;
  font-weight: bold;
}

.blurSection {
  filter: blur(8px);
}

/* ========== about section ======== */
.profileFormTextarea textarea {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dededf;
  border-radius: 4px;
  margin-bottom: 15px;
}

.aboutInputAddButton {
  position: relative;
}

.aboutInputAddButton i {
  position: absolute;
  left: 46%;
  top: 45%;
  color: #747576;
  cursor: pointer;
}

.individualBadge {
  display: flex;
  border: 1px solid #333333;
  border-radius: 100px;
  padding: 2px 10px;
  margin-right: 15px;
  align-items: center;
}

.individualBadge p {
  font-size: 13px;
  font-family: "Lato", sans-serif;
  margin-right: 10px;
}

.profileBadge {
  margin-bottom: 15px;
}

.individualBadge span {
  font-size: 14px;
  cursor: pointer;
}

.aboutSubmitBtn button {
  width: 120px !important;
}

/* ================ LEARNING SECTION ========= */
.learningSection .individualBadge p {
  margin: 0;
}
.learningSection .individualBadge {
  display: inline-block;
  margin-bottom: 5px;
}
p.dropTitle {
  font-size: 13px;
  color: #333333de;
  font-family: "Lato", sans-serif;
  font-weight: bold;
  text-align: center;
}

.dragDropCardContainer {
  margin-top: 25px;
  width: 220px;
}

.dragDropCard {
  width: 220px;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #b2b2b2;
  border-radius: 4px;
  background: #b2b2b205;
  margin: 5px 0;
}

.dragDropCard p.dragDropTxt {
  color: #000000;
  opacity: 0.25;
  font-size: 14px;
  font-weight: bold;
  width: 100px;
  text-align: center;
  font-family: "Lato", sans-serif;
}

.dragDropBottomIcons {
  text-align: center;
}

.dragDropBottomIcons img {
  width: 15%;
}
.learningBtn button {
  width: 106px;
  margin-top: 35px;
}

.dropBadgeArea {
  min-height: 150px;
}
.activeDragArea {
  display: inline-block;
  padding: 5px;
  text-align: center;
  overflow: hidden auto;
}

.profileNumber .fa {
  color: #21ba45;
  font-size: 30px;
}

.letsGoBtn img {
  width: 20px !important;
  margin-left: 5px;
}

/* ============= MY CLASSROOM ================= */
.classroomCard {
  box-shadow: 5px 5px 10px #0000000d;
  border-radius: 4px;
  background: #fff;
  padding: 10px;
  height: 570px;
  margin-bottom: 15px;
}

.classDetails h3 {
  font-size: 16px;
  margin-bottom: 2px;
}

.classDetails h2 {
  font-size: 18px;
}

.classDetails {
  position: relative;
  padding: 15px 10px 30px;
}

.classDetails i {
  position: absolute;
  left: 2%;
  top: 14%;
}
.classDetails h3,
.classDetails h2 {
  font-family: "nunitoRegular", sans-serif;
  font-weight: bold;
  margin-left: 8%;
  color: #000000de;
}

.classDetails p {
  letter-spacing: 0px;
  color: #545454;
  font-size: 12px;
}

.classImg img {
  width: 100px;
  height: 50px;
  object-fit: fill;
}

.classList h2 {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0px;
  font-family: "nunitoRegular", sans-serif;
}

.classList {
  margin-bottom: 30px;
  position: relative;
  width: 100%;
  cursor: pointer;
}

.classList p {
  font-family: "Lato", sans-serif;
  font-size: 12px;
}

.classList strong {
  font-size: 24px;
  color: #333333;
  font-family: "Bebas Neue", cursive;
  position: absolute;
  right: 2%;
  top: 21%;
}

.classListOpactiy {
  opacity: 0.5;
}

.activeClass {
  background: #e9e9e9;
  border-radius: 4px;
}

.classFeatureimg img {
  width: 100%;
  height: auto;
}

.classDetailed h1 {
  font-size: 18px;
  font-weight: bold;
  font-family: "nunitoRegular", sans-serif;
  margin-bottom: 0;
}

.classDetailed {
  padding: 25px;
}

.classDetailed p {
  font-size: 12px;
  color: #b3b3b3;
  font-weight: bold;
  font-family: "nunitoRegular", sans-serif;
  margin-bottom: 30px;
}

.takeQuizBtn {
  text-align: right;
  margin-top: 20px;
}

.takeQuizBtn button {
  background: linear-gradient(to right, #ef5a3e 5%, #a00030 100%);
  border-radius: 4px;
  border: none;
  color: #fff;
  font-size: 14px;
  width: 130px;
  padding: 7.5px 0;
}

.classDetailed hr {
  margin: 20px 0;
  border: 2px solid #707070;
}

.flexReview {
  display: flex;
}

.flexReview p {
  margin-bottom: 0;
  margin-right: 12px;
}

.flexReview img {
  width: 20px;
  margin-right: 5px;
}

.shareClass p {
  margin-bottom: 0;
  margin-right: 25px;
  color: #333333;
  font-weight: bold;
  font-family: "Lato", sans-serif;
}

.shareClass {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.ClassRoomMainContainer {
  padding-bottom: 35px;
}
.flexClassList {
  display: flex;
  padding: 5px;
}

.classInfo {
  margin-left: 15px;
  margin-top: 5px;
}
#myclassRoom {
  padding-top: 3%;
}
/* ============= MY CLASSROOM DIALOGUE ============ */
.myClassroomModelContent {
  text-align: center;
  padding: 30px 0;
}

.myClassroomModelContent img {
  width: 60px;
}

.myClassroomModelContent p {
  color: #707070;
  font-size: 18px;
  font-family: "Lato", sans-serif;
  font-weight: bold;
  margin-bottom: 25px;
}

.myClassroomModelContent button {
  width: 170px;
  background: linear-gradient(to right, #ef5a3e 5%, #a00030 100%);
  border: none;
  padding: 10px 0;
}

/* =============== MY NETWORK SCREEN ================ */
.findFriendContainer {
  padding: 3% 0;
}

.searchFriend {
  margin-bottom: 25px;
}

.searchFriend h3 {
  color: #707070;
  font-size: 24px;
  font-weight: bold;
  font-family: "Lato", sans-serif;
  margin-bottom: 25px;
  text-align: center;
}

.searchFriendInput.input-group,
.findaMatchSearchInput.input-group {
  width: 250px;
  margin: auto;
  position: relative;
}
.searchFriend.findaMatchCard .searchFriendSelectBoxFlex {
  width: 85%;
}
.searchFriend .input-group input {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dededf;
  border-radius: 100px !important;
  font-size: 12px;
  padding: 7px 15px;
  height: auto !important;
}

.searchFriendInput i {
  position: absolute;
  right: 15px;
  top: 9px;
  color: #9a9a9a;
  font-size: 14px;
  cursor: pointer;
}

.searchFriendSelectBoxFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 75%;
  margin: auto;
  margin-bottom: 30px;
}

.searchFriendSelectBoxFlex label {
  width: 100%;
  color: #333333de;
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 5px;
  line-height: 1;
}

.searchFriendFormField {
  margin-right: 10px;
  width: 160px;
}

.searchFriendSelectBoxFlex select {
  border: 1px solid #dededf;
  border-radius: 4px !important;
  font-size: 12px;
}

.profilePhoto img {
  width: 100px;
  height: 100px;
  box-shadow: 0px 0px 5px #00000029;
  border: 3px solid #ffffff;
  border-radius: 50%;
  object-fit: cover;
}

.networkCard {
  text-align: center;
  border: 1px solid #dededf;
  border-radius: 4px;
  background: #fff;
  position: relative;
}

.coverPhoto {
  height: 100px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  border-radius: 4px 4px 0px 0px;
}

.profilePhoto {
  margin-top: -45px;
}

.networkDetails {
  padding: 20px 15px 0;
}

.networkDetails h3 {
  font-size: 18px;
  color: #333333;
  font-weight: bold;
  font-family: "Lato", sans-serif;
}

.networkDetails p {
  color: #b3b3b3;
  font-size: 12px;
  margin-bottom: 15px;
}

.connectBtn {
  padding: 20px 15px 15px;
}

.connectBtn button {
  border-radius: 4px;
  border: none;
  background: linear-gradient(to right, #ef5a3e 5%, #a00030 100%);
  color: #fff;
  font-size: 12px;
  padding: 5px 8px;
  font-weight: bold;
  font-family: "nunitoRegular", sans-serif;
}
.networkCardContainer .col-md-3 {
  margin-bottom: 30px;
}
.networkCardContainer {
  min-height: 500px;
}
.networkDetailsBtn button {
  display: block;
  margin: auto;
  font-size: 14px;
  border: none;
  padding: 7.5px 0;
  font-weight: bold;
  font-family: "Lato", sans-serif;
  cursor: pointer;
  margin-top: 10px !important;
  margin-bottom: 2rem;
}

button.networkSkip {
  background: #dfdfdf;
  border-radius: 4px;
  color: #333333;
  width: 125px;
}

/* =============== FIND A MATCH SCREEN ============ */
.findaMatchCard {
  background: #fff 0 0 no-repeat padding-box;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 15px 10px;
  margin-bottom: 15px;
  min-height: 130px;
  /*min-height: 230px; */
}
span.filterTxt {
  margin: 0 !important;
}
.filterDownArrow {
  cursor: pointer;
}
.findaMatchSearchInput span i {
  margin-right: 5px;
}
.findaMatchSearchInput {
  width: 350px !important;
}

.findaMatchSearchInput i {
  position: inherit;
  top: 10px;
  right: 10px;
  color: #9a9a9a;
  font-size: 14px;
  cursor: pointer;
}

.findaMatchSearchInput span {
  font-size: 14px;
  color: #989898;
  text-decoration: underline;
  margin-top: 6px;
  margin-left: 10px;
}

.findaMatchSearchInput span i {
  font-size: 14px;
}

.findaMatchSearchInput input {
  width: 275px;
}

.findMatachHeading {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}
.navbar-brand {
  background: transparent;
  margin: 0 20px 0 0;
}
.findMatachHeading:before,
.findMatachHeading:after {
  background: #707070;
  height: 1px;
  content: "";
  flex: 1 1;
}

.findMatchNetworkCard {
  padding: 20px 0;
}

.findMatachHeading h3 {
  color: #333333de;
  font-size: 24px;
  font-weight: bold;
  font-family: "nunitoRegular,sans-serif;";
  padding: 0 10px;
}
.yuukkeHeading h3 {
  color: #b3b3b3;
}
.yuukkeHeading:before,
.yuukkeHeading:after {
  background: #b3b3b3;
}
.detailedCard {
  width: 90%;
  margin: auto;
}

.detailedCard .col-md-3 {
  margin-bottom: 30px;
}

.findMatchCardBtn i {
  margin-right: 5px;
}
/* .findMatchCardBtn button {
      width: auto;
      padding: 5px 10px;
    } */
.findMatchCardBtn button {
  width: auto;
  padding: 5px 5px;
}
button.btnOutlined {
  background: none;
  color: #60c2e7;
  border: 1px solid #60c2e7;
  margin-left: 5px;
}

.findMatchNetworkCard .row {
  margin-bottom: 25px;
}

.detailedCard .networkDetails > p:nth-child(3) {
  margin-bottom: 0;
}
/*============= My YUUKKE =================*/

.MyYuukke {
  padding-top: 50px;
}
.smiley {
  text-align: center;
}
.smiley p {
  opacity: 0.6;
  font-weight: 700;
  font-size: 18px;
}
.img-wrapper img {
  max-width: 300px;
  height: 200px;
}
.card-wrapper {
  background: #fff;
  box-shadow: 5px 5px 10px #0000000d;
  border-radius: 4px;
  padding: 12px 20px;
  height: 280px !important;
  margin-bottom: 28px !important;
}

.card-main-wrapper {
  background: #fff 0 0 no-repeat padding-box;
  border: 1px solid #dededf;
  box-shadow: 6px 3px 6px rgb(0 0 0 / 16%);
  border-radius: 10px !important;
  width: auto;
}

.bannerClassroomMainImg {
  height: 180px;
  width: 100%;
  /* object-fit: cover; */
  object-position: top center;
  border-radius: 10px !important;
}

.btn-peer {
  background: linear-gradient(to right, #ef5a3e 5%, #a00030 100%);
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
}
.mypeercards .container {
  max-width: 1000px;
}
.btn-peer:hover {
  color: #fff;
  text-decoration: none;
}
.mypeercards {
  padding-top: 20px;
}
.img-wrapper a {
  position: absolute;
  bottom: 85px;
  left: 55px;
}

/*=============== Book Time ============*/
.book-time {
  padding: 50px 0px;
}
.book-time h4 {
  color: #707070;
  font-weight: bold;
  margin-bottom: 50px;
}
.bpc-img-wrapper {
  text-align: center;
}
.bpc-img-wrapper img {
  border-radius: 50%;
  box-shadow: 0px 0px 5px #00000029;
  border: 3px solid #ffffff;
}
.hash-tags {
  font-size: 13px;
}
.chat-btn {
  border: 1px solid #60c2e7;
  border-radius: 4px;
  padding: 10px 30px;
  color: #60c2e7;
  font-weight: bold;
  font-size: 14px;
  background: transparent;
}
.update-btn {
  background: linear-gradient(to right, #ef5a3e 5%, #a00030 100%);
  border-radius: 4px;
  color: #fff;
  padding: 10px 30px;
  border: none;
  font-weight: bold;
}
.booking-profile-card {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d4d4d5;
  border-radius: 4px 4px 0px 0px;
  padding: 30px;
}
.right-col-wrap {
  padding: 30px;
}
.align-flex-end {
  align-items: flex-end;
}

/* ================ CALANDER =========== */

/* ======== Book Time ============ */
#booktime {
  padding: 50px 0px;
}
#booktime h4 {
  color: #707070;
  font-weight: bold;
  margin-bottom: 50px;
}
.bpc-img-wrapper {
  text-align: center;
}
.bpc-img-wrapper img {
  border-radius: 50%;
  box-shadow: 0px 0px 5px #00000029;
  border: 3px solid #ffffff;
}
.hash-tags {
  font-size: 13px;
}
.chat-btn {
  border: 1px solid #60c2e7;
  border-radius: 4px;
  padding: 10px 30px;
  color: #60c2e7;
  font-weight: bold;
  font-size: 14px;
  background: transparent;
}
.update-btn {
  background: linear-gradient(to right, #ef5a3e 5%, #a00030 100%);
  border-radius: 4px;
  color: #fff;
  padding: 10px 30px;
  border: none;
  font-weight: bold;
}
.booking-profile-card {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d4d4d5;
  border-radius: 4px 4px 0px 0px;
  padding: 20px;
}
.right-col-wrap {
  padding: 20px;
}
.align-flex-end {
  align-items: flex-end;
}
.profile-details-wrap p {
  margin: 0;
}
/* ======== Book Time ============ */
#sendRequestModal .modal-body {
  padding: 0;
}
.sr-title h3 {
  font-size: 18px;
  color: #333;
  text-transform: uppercase;
  border-bottom: 1px solid #707070;
  padding: 1rem;
  font-weight: bold;
}
.srModalContent {
  padding: 20px 40px 40px 40px;
}
.subjectDropdown {
  max-width: 180px;
}
.descriptionTextArea {
  max-width: 500px;
}
.datePicker {
  max-width: 170px;
  display: inline-block;
  margin-right: 20px;
}
.timePicker {
  max-width: 100px;
  display: inline-block;
}
.srCancel {
  background: #dfdfdf;
  color: #333;
  border: none;
  font-size: 14px;
  padding: 7.5px 20px;
  border-radius: 4px;
  font-weight: bold;
}
.srSend {
  background: linear-gradient(to right, #ef5a3e 5%, #a00030 100%);
  border: none;
  padding: 7.5px 20px;
  font-size: 14px;
  border-radius: 4px;
  font-weight: bold;
  color: #fff;
}
#sendRequestModal label {
  font-size: 13px;
  color: #333333de;
}

/* ============= COMMUNITY SCREEN =========== */
/* .communityCard {
      box-shadow: 5px 5px 6px #0000000d;
      border: 1px solid #dededfbf;
      border-radius: 4px;
      background: #fff;
      margin-bottom: 16px;
    } */
.communityCard {
  box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(222, 222, 223, 0.75);
  border-radius: 7px;
  background: #fff;
  margin-bottom: 16px;
  padding: 15px 20px 1px 20px;
}

.userImg img {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  box-shadow: 0px 0px 5px #00000029;
  border: 3px solid #ffffff;
}
.userImgPost img {
  float: left !important;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  box-shadow: 0px 0px 5px #00000029;
  border: 3px solid #ffffff;
}

.newPostUploadFileTypes {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding-right: 15px;
}

.newPostUpload {
  display: flex;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
}
.newPostUpload.newPostUploadFiles {
  margin-right: 0;
}
.newPostSearchField input {
  width: 100%;
  border-radius: 50px;
  border: 0;
  background: rgb(179, 179, 179, 0.25);
  font-size: 14px;
  padding: 16px 25px;
}

.newPostUpload p {
  margin-bottom: 0;
  font-size: 16px;
  margin-left: 5px;
  letter-spacing: 0.32px;
  color: #333333;
  font-weight: bold;
}

.newPostUpload svg {
  font-size: 24px;
  color: #333333;
}
/* .newPostSearchField {
      padding-left: 15px;
    } */
.padding-15 {
  padding: 15px;
}
.communityRootContianer {
  padding: 50px 15px;
}
.flex-center {
  align-items: center;
}
.newPostUploadVideo svg {
  font-size: 30px;
}
.flexSpaceBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
}

.postCardHeaderInfo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.postInfo p {
  margin-bottom: 0;
}

.postUserName {
  color: #333333;
  font-weight: bold;
  font-size: 16px;
}

.postUploadTie {
  color: #b3b3b3;
  font-size: 14px;
}

.postInfo {
  margin-left: 16px;
}

.postCardHeader {
  padding: 15px 25px 20px;
}

.postDescription {
  padding: 0 25px;
}

.postDescription p {
  color: #000000;
  font-size: 16px;
  margin-bottom: 15px;
  letter-spacing: 0.32px;
}

.postCardBody img,
.postCardBody video {
  width: 100%;
  height: 500px;
  object-fit: cover;
}

.likeComment p {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: bold;
}

.likeComment {
  display: flex;
}
.invitationCardBtn {
  text-align: center;
}
.likeComment div {
  display: flex;
  cursor: pointer;
}

.postLike {
  margin-right: 30px;
  cursor: pointer;
}

.likeComment svg {
  margin-right: 10px;
  font-size: 24px;
}

.likeComment p,
.likeComment svg {
  color: #b3b3b3;
}
@media (max-width: 1025px) {
  .likesInfo {
    display: none !important;
  }
}
@media (min-width: 1025px) {
  .mobile-likesInfo {
    display: none !important;
  }
}

.mobile-likesInfo {
  display: flex;
  align-items: flex-end;
  font-size: 10px;
}

.likesInfo {
  display: flex;
  /* align-items: flex-end; */
}

.likesInfo p {
  margin-bottom: 0;
  color: #b3b3b3;
  font-size: 14px;
  /* margin-right: 10px; */
}

.postCardFooter {
  padding: 15px !important;
  position: relative;
}

.likesInfo img {
  width: 25px !important;
  height: 25px !important;
  margin-right: 0px;
}

p.commentInfo {
  margin-right: 0;
  cursor: pointer;
}
a.commentInfo {
  margin-right: 0;
  cursor: pointer;
}
p.likesDot {
  position: relative;
  top: -3px;
}

.linkCard {
  display: flex;
  background-color: #fff;
}
.menuCard {
  display: flex !important;
  margin-left: 8px;
}
.menuItem {
  margin-top: 6px;
}
.linkCard img {
  width: 200px;
  height: 200px;
  border-radius: 4px 0px 0px 4px;
}
.linkCard {
  display: flex;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  margin: 15px;
}
p.postEventName {
  letter-spacing: 0px;
  color: #1e1e1e;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}
p.postEventTime {
  letter-spacing: 0px;
  color: #1e1e1e;
  text-transform: uppercase;
  font-size: 16px;
  margin-left: 5px;
  margin-bottom: 10px;
}
p.postEventTime svg {
  font-size: 18px;
  position: relative;
  top: -3px;
  left: -5px;
}
.postLike img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
.joinNowBtn button {
  background: linear-gradient(to right, #ef5a3e 5%, #a00030 100%);
  border-radius: 4px;
  border: none;
  width: 92px;
  height: 36px;
  display: grid;
  place-content: center;
  color: #ffffff;
}
/* .linkDetails {
      padding: 15px;
    } */
.postLinkDescription p {
  font-size: 14px;
  color: #b3b3b3;
  margin-bottom: 0;
  padding: 15px;
}

.eventOrginzerDetails {
  display: flex;
  color: #b3b3b3;
  align-items: center;
  font-size: 14px;
  padding: 0 15px 10px;
}

.eventOrginzerDetails p {
  margin-bottom: 0;
  margin-right: 15px;
}

.eventOrginzerDetails svg {
  font-size: 21px;
  position: relative;
  top: -2px;
  margin-right: 10px;
}

.linkInfo {
  border-bottom: 1px solid #e2e2e2;
  padding: 10px 15px 0;
}
.invitationCardTitle {
  border-bottom: 1px solid #dededfbf;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

/* .invitationCardWrapper {
      padding: 25px 15px 0;
    } */
.invitationCardWrapper {
  padding: 25px 15px !important;
}
.invitationCardTitle p {
  margin-bottom: 0;
  font-size: 16px;
  color: #333333;
  font-weight: bold;
}

.invitationUserInfo img {
  width: 75px;
  height: 75px;
}

.invitationUserInfoWrapper {
  display: flex;
}

.invitationUserInfo p {
  margin-bottom: 0;
  font-size: 12px;
  color: #b3b3b3;
}

p.invitationUserName {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}

.invitationUserInfo {
  margin-left: 10px;
}

.invitationConnect {
  display: flex;
  align-items: center;
}

.invitationConnect {
  font-size: 12px;
  font-weight: bold;
  color: #b3b3b3;
  padding: 0;
}

.invitationConnect p {
  margin-bottom: 0;
  padding-right: 10px;
}
.invitationCard {
  margin-bottom: 20px;
}
.invitationConnect i {
  margin-right: 5px;
}
.writeCommentWrapper {
  padding: 10px 25px;
  border-top: 1px solid #dededfbf;
}
.flexCenter {
  display: flex;
  align-items: center;
}
.writeComment .userImg img,
.allComments .userImg img {
  width: 40px;
  height: 40px;
}

.commentInput input {
  width: 100%;
  border-radius: 18px !important;
  border: 0;
  background: rgb(179, 179, 179, 0.25);
  font-size: 14px;
  padding: 5.5px 25px;
}

.commentInput {
  flex: 1 1;
  margin-left: 25px !important;
}
.commentDetails p {
  margin-bottom: 0;
  font-size: 14px;
}

.commentDetails {
  padding: 8px 15px;
  background: rgb(179, 179, 179, 0.25);
  border-radius: 18px;
}

.commentDetails strong {
  margin-right: 10px;
  color: #a00030;
}

.allComments .userImg {
  margin-right: 6px;
}

.allComments {
  margin-bottom: 15px;
}
.likeOptions {
  width: 290px;
  height: 50px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15), 0 4px 6px rgba(0, 0, 0, 0.2);
  border-radius: 300px;
  background: #fff;
  border: none;
  justify-content: space-evenly;
  align-items: center;
  padding: 0 15px;
  position: absolute;
  top: -25px;
  left: -15px;
}

.likeOption img {
  width: 36px;
  height: 36px;
}
.showResponseImg img {
  width: 100%;
}
.uploadTypeIcons {
  position: relative;
  overflow: hidden;
  display: inline-block;
  cursor: pointer;
}

.uploadTypeIcons input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}
.overAlluploadicons {
  position: center;
  cursor: pointer;
}
.uploadWrapper img,
.uploadWrapper video {
  width: 100%;
  height: 300px;
  object-fit: contain;
  object-position: top;
}
.uploadPostInput textarea {
  width: 100%;
  height: 80px;
  border: none;
}

.uploadBtnWrapper {
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 10px;
  margin-top: 20px;
}

.uploadTypeIcons {
  /* display: flex; */
  float: right !important;
  justify-content: space-between;
  align-items: center;
  flex-basis: 20%;
}

.addtoPostTitle {
  flex: 1 1;
}

.addtoPostTitle p {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: bold;
}

.uploadTypeIcons svg {
  cursor: pointer;
}

.previewWrapper {
  height: 180px;
  overflow: hidden auto;
}

.uploadModelTitle {
  font-size: 20px;
  font-weight: 600;
  margin: auto;
}
.uploadPostTitle {
  font-size: 20px;

  font-weight: 400;
  margin: auto;
  display: inline-flex;
}

/* #root .container {
      max-width: 1410px;
    } */
/* =========== STUDY GROUP ============ */
.studyGroupCard {
  margin: 0;
  margin-bottom: 15px !important;
}

.studyGroupCard img {
  width: 185px;
  height: 185px;
}

.studyGroupCard p.postEventName {
  font-size: 14px;
}

.studyGroupCard p.postEventTime {
  font-size: 14px;
}

.studyGroupCard .postLinkDescription p {
  font-size: 12px !important;
  padding: 10px;
}

.studyGroupCard .eventOrginzerDetails p {
  font-size: 10px;
}

.studyGroupCard .eventOrginzerDetails svg {
  font-size: 16px;
}

.studyGroupCard .eventOrginzerDetails p:nth-child(2) {
  margin-right: 0;
}

.studyGroupCard .linkInfo {
  padding: 10px 10px 0;
}

.studyGroupCard .eventOrginzerDetails {
  padding: 0 10px 10px;
}

.studyGroupCard p.postEventTime svg {
  font-size: 14px;
}
.studyGroupCard .joinNowBtn button {
  width: 85px;
  height: 30px;
  font-size: 14px;
}
.studyGroupCard .postLinkDescription {
  max-height: 92px;
  overflow: hidden;
}
.studyGroupSeeMoreBtn {
  padding: 0;
  text-align: center;
  margin: 30px 0;
}

.studyGroupSeeMoreBtn button {
  width: 105px;
  height: 36px;
}
.createStudyGroupBtn button {
  width: 238px;
  height: 48px;
  font-size: 16px;
  font-weight: 300;
}

.createStudyGroupBtn button i {
  font-size: 13px;
}

.createStudyGroupBtn {
  padding: 0;
  position: absolute;
  top: 4.5em;
  right: 0;
}

.studyTourWrapper .searchFriend {
  position: relative;
  padding-top: 30px;
  min-height: 200px;
}
.createStudyGroupModelTitle {
  letter-spacing: 0px;
  color: #333333;
  text-transform: uppercase;
  font-size: 18px !important;
  font-family: "Lato", sans-serif;
  padding-left: 15px !important;
}

.formBasicName {
  width: 291px;
}

.createStudyGroupModel .form-group label {
  font-size: 13px;
  color: #333333de;
  font-weight: bold;
  margin-bottom: 5px;
}

.createStudyGroupModel input,
.createStudyGroupModel textarea,
.createStudyGroupModel select {
  border: 1px solid #dededf;
  border-radius: 4px;
}

.formBasicSubject {
  width: 180px;
}

.createStudyGroupModel input,
.createStudyGroupModel select {
  height: 38px;
  font-size: 14px;
}

.formBasicDescription {
  width: 500px;
}

.createStudyGroupModel .form-group {
  margin-bottom: 15px;
}

.formBasicDate {
  width: 150px;
}

.formBasicHour {
  width: 70px;
  margin: 0 20px 15px;
}

.formBasicDuration {
  width: 86px;
}

.newStudyTourinviteParticipate .input-group-prepend .input-group-text {
  background: none;
  border-right: 0;
  color: #747576;
  padding-right: 0;
}
.newStudyTourinviteParticipate .input-group-append .input-group-text {
  background: none;
  border-left: 0;
  color: #747576;
}
.newStudyTourinviteParticipate input {
  border-left: 0;
  border-right: 0;
}

.newStudyTourinviteParticipate .individualBadge p {
  margin: 0 !important;
  margin-right: 10px !important;
}

.newStudyTourinviteParticipate .individualBadge {
  height: 30px;
  position: relative;
  top: -5px;
  left: 30px;
}

.newStudyTourinviteParticipate {
  align-items: center;
  height: 100%;
}

.formBasicCheckbox input {
  height: auto;
}

.formBasicMaxStudent select {
  width: 75px;
}

.createNewStudyTourModelfooter {
  border-top: 0 !important;
}

.createNewStudyTourModelfooter .btn-secondary {
  background: #dfdfdf 0% 0% no-repeat padding-box;
  width: 75px;
  margin-right: 65px;
}

.createNewStudyTourModelfooter .btn {
  border-radius: 4px;
  border: none;
  letter-spacing: 0px;
  color: #333333;
  font-weight: bold;
  font-size: 14px;
  height: 36px;
}

.createNewStudyTourModelfooter button.btn.btn-primary {
  color: #fff;
  background: linear-gradient(to right, #ef5a3e 5%, #a00030 100%);
}
.createStudyGroupModel {
  padding-left: 30px !important;
}
.formBasicinviteParticipate {
  margin-bottom: 0 !important;
}
.createStudyGroupModelWrapper .modal-header {
  border-bottom: 1px solid #707070;
}

/* ==================== FOR MOBILE ============= */
/* @media (min-width: 1300px) {
      #root .container {
        max-width: 1410px;
      }
    } */
@media (max-width: 768px) {
  .subjectDropdown {
    max-width: 100%;
  }
  .descriptionTextArea {
    max-width: 100%;
  }
  .descriptionTextArea {
    max-width: 100%;
  }
  .datePicker {
    max-width: 100%;
    display: block;
    margin-right: 0px;
  }
  .timePicker {
    max-width: 100%;
    display: block;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  #root .container {
    max-width: 992px;
  }
  /* COMMUNITY SCREEN */
  .newPostUpload p {
    font-size: 12px;
  }

  .newPostUpload svg {
    font-size: 21px;
  }

  .newPostUploadVideo svg {
    font-size: 27px;
  }

  p.invitationUserName {
    font-size: 11px;
  }

  .invitationUserInfo p {
    font-size: 9px;
  }

  .invitationUserInfoWrapper .userImg img {
    width: 50px;
    height: 50px;
  }

  .invitationConnect button {
    display: flex;
    align-items: center;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  /* .flexDefault {
        display: block;
      } */
  /* COMMUNITY SCREEN */
  .newPostUpload p {
    font-size: 12px;
  }

  .newPostUpload svg {
    font-size: 21px;
  }

  .newPostUploadVideo svg {
    font-size: 27px;
  }
  /* ======== PROFILE SCREEN ============ */
  .uploadTypeIcons {
    flex-basis: auto;
  }
  .myProfilePhoto {
    margin-left: 0;
    bottom: 0;
    margin-bottom: 15px;
  }

  /* .flexDefault {
        display: block;
      } */

  .myProifleUserInfo {
    text-align: center;
    position: relative;
    bottom: 90px;
    height: 330px;
  }

  .borderRightDivider {
    border: none;
    height: auto;
  }

  .myProfileEdit {
    display: block;
    height: auto;
  }

  .myProfileDescription {
    margin: 15px 0;
  }

  .myKnowledgePoint {
    margin: 15px 0;
  }

  .myProfileEdit button {
    position: inherit;
  }

  .myIntrestCards img {
    height: 350px;
  }

  .myIntrestCard p {
    margin: 15px 0;
  }

  .addNewIntrest {
    height: 350px;
  }

  .addNewMyIntrestCard p {
    text-align: center;
    margin-top: 15px;
  }

  .myCompetencie p {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .myCompetencie svg {
    font-size: 18px;
  }

  .reviewerInfo {
    display: flex;
  }

  .allBadges {
    display: flex;
  }

  .newTodoForm {
    display: flex;
  }

  .myReminder {
    display: flex;
  }
  .reviewCard {
    margin-bottom: 15px;
  }
  /* STUDY TOUR */
  .createStudyGroupBtn {
    position: relative;
    top: 0;
    text-align: center;
  }
}
@media (min-width: 0) and (max-width: 768px) {
  /* login page */
  h3.loginQuotes {
    font-size: 14px;
    margin-top: 15px;
  }

  .signupOptions {
    display: block !important;
    width: 100%;
  }

  .signupLogo {
    margin-bottom: 15px;
    margin-right: 0;
  }
  .signupTxt p {
    margin-bottom: 5px;
  }

  /* welcome screen */
  #header .userDetailsMenu {
    display: none !important;
  }

  .welcomeCard img {
    width: 100%;
  }

  .welcomeCard {
    margin: 0;
  }

  /* ======== PROFILE SCREEN ============ */
  .ProfileTabNav .nav-item {
    width: 100%;
    margin-bottom: 5px;
  }

  .showTabArrow:after,
  .showTabArrow:before {
    content: inherit;
  }

  a#about-you {
    padding-left: 15px;
  }

  a#learning {
    padding-left: 15px;
  }
  .learningSection .profileBadge {
    display: inline-block !important;
    margin-bottom: 0;
  }

  .profileImg img {
    width: 100%;
  }

  .profileImg {
    margin-bottom: 15px;
  }
  .profileFormTextarea textarea {
    width: 100%;
  }

  .aboutInputAddButton i {
    right: 5%;
    top: 44%;
    left: inherit;
  }

  input {
    width: 100% !important;
  }
  .dragDropCardContainer {
    width: auto;
  }

  .dragDropCard {
    width: auto;
  }
  .showMobileNav {
    display: flex !important;
  }
  /* ======= MY NETWORK  ========= */
  .searchFriendSelectBoxFlex {
    display: block;
    width: 100%;
  }
  .searchFriendInput.input-group,
  .findaMatchSearchInput.input-group {
    width: 100%;
  }
  .findaMatchSearchInput {
    width: 80% !important;
  }

  .findaMatchSearchInput i {
    top: 25px;
    left: -20px;
  }

  .filterDownArrow {
    position: absolute;
    right: -55px;
  }

  .filterDownArrow i {
    top: 0;
  }

  .findMatachHeading h3 {
    font-size: 16px;
  }
  .chat-btn,
  .profile-details-wrap {
    margin-top: 15px;
  }
  .BooktimeMainContainer .col-md-6 {
    padding: 0 15px !important;
  }
  .filterTxt {
    display: none;
  }
  .filterDownArrow i {
    top: 20px;
  }

  .searchFriend.findaMatchCard .searchFriendSelectBoxFlex {
    width: 100%;
  }
  .navbar-toggler-icon {
    margin: 0;
  }
  .classroomCard {
    height: auto;
  }

  .shareClass {
    justify-content: flex-start;
    margin-top: 10px;
  }
  /* study group */
  .linkCard img {
    width: 100%;
    border-radius: 4px 4px 0px 0px;
    height: 250px;
  }
  .linkCard {
    display: block;
  }

  .createStudyGroupBtn {
    position: inherit;
    top: 0;
    margin-bottom: 15px;
    text-align: center;
  }
  .findaMatchSearchInput span {
    right: -30px;
  }
  /* STUDY GROUP DIALOGUE */
  .createStudyGroupModel .form-group {
    width: 100%;
    margin-left: 0;
  }

  .formBasicCheckbox {
    margin-top: 15px;
  }
  .newStudyTourinviteParticipate input {
    width: 1% !important;
  }

  .newStudyTourinviteParticipate .individualBadge {
    display: inline-flex;
    position: inherit;
    margin-top: 5px;
  }

  .formBasicCheckbox input {
    width: auto !important;
  }

  .formBasicMaxStudent select {
    width: 100%;
  }

  .createNewStudyTourModelfooter {
    justify-content: flex-start;
  }

  .createNewStudyTourModelfooter .btn-secondary {
    margin-right: 10px;
  }

  .createNewStudyTourModelfooter .btn {
    font-size: 11px;
  }

  .createStudyGroupModel {
    padding-left: 15px !important;
  }
  /* Community screen */
  .newPostUpload p {
    font-size: 12px;
  }

  .newPostUpload svg {
    font-size: 21px;
  }

  .newPostUploadVideo svg {
    font-size: 27px;
  }

  .communityRootContianer {
    padding: 30px 0;
  }

  /* .newPostUploadFileTypes p {
        display: none;
      } */

  .newPostUpload {
    margin-right: 5px;
  }

  .newPostUploadFileTypes {
    justify-content: center;
  }

  .newPostSearchField input {
    padding: 5px 15px;
    font-size: 12px;
  }

  .postLike {
    margin-right: 12px;
  }

  .likeComment p {
    font-size: 12px;
  }

  .likeComment svg {
    font-size: 18px;
    margin-right: 5px;
  }

  .postLike img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    position: relative;
    top: -3px;
  }

  .postCardFooter {
    padding: 15px;
  }

  .postCardHeader {
    padding: 15px 15px 20px;
  }

  .writeCommentWrapper {
    padding: 10px 15px;
  }
  /* MY PROFILE SCREEN */
  .myProfilePhoto {
    margin-left: 0;
    bottom: 0;
    margin-bottom: 15px;
  }

  .uploadTypeIcons {
    flex-basis: auto;
  }
  .myProifleUserInfo {
    text-align: center;
    position: relative;
    bottom: 90px;
    height: 330px;
  }

  .borderRightDivider {
    border: none;
    height: auto;
  }

  .myProfileEdit {
    display: block;
    height: auto;
  }

  .myProfileDescription {
    margin: 15px 0;
  }

  .myKnowledgePoint {
    margin: 15px 0;
  }

  .myProfileEdit button {
    position: inherit;
  }

  .myIntrestCards img {
    height: 250px;
  }

  .myIntrestCard p {
    margin: 15px 0;
  }

  .addNewIntrest {
    height: 250px;
  }

  .addNewMyIntrestCard p {
    text-align: center;
    margin-top: 15px;
  }

  .myCompetencie p {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .myCompetencie svg {
    font-size: 18px;
  }

  .reviewerInfo {
    display: flex;
  }

  .allBadges {
    display: flex;
  }

  .newTodoForm {
    display: flex;
  }

  .myReminder {
    display: flex;
  }
  .reviewCard {
    margin-bottom: 15px;
  }
}

@media (min-width: 0) and (max-width: 378px) {
  /* COMMUNITY SCREEN */
  .likesInfo p {
    font-size: 11px;
  }

  p.postEventName {
    font-size: 14px;
  }

  p.postEventTime {
    font-size: 11px;
  }

  .eventOrginzerDetails p {
    font-size: 12px;
  }
}
/* .barBtn img {
        width: 10px;
        height: 10px;
      }
      
      .barBtn img {
        margin-right: 5px;
      }
      
      .barBtn i {
        font-size: 14px !important;
      }
      
      
      .barBtn p {
        font-size: 12px;
        margin-right: 10px;
      } */

.action-bar {
  margin-left: 10px;
}

.action-item {
  margin: 5px !important;
  text-align: center;
}

.action-item > span {
  margin: 0px !important;
  text-align: center;
}

.colorLikeImg {
  width: 24px !important;
  height: 24px !important;
  margin-top: -5px;
  margin-right: 8px;
}

.commentInput {
  flex: 1 1;
  margin-left: 6px;
  margin-left: 25px;
}

.commentList {
  padding: 10px 25px;
}

/* .profileMultiInput input {
   
    } */

.profileMultiInput input {
  border: 0px solid #dededf !important;
}

.aboutMultiAddButton {
  position: relative;
}

.aboutMultiAddButton i {
  position: absolute;
  left: 46%;

  color: #747576;
  cursor: pointer;
}

.aboutMultiAddButton i {
  right: 5%;

  left: inherit;
}

.friend-avatar-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (min-width: 768px) {
  .friend-avatar-xxl {
    width: 8rem !important;
    height: 8rem !important;
  }
}

@media (min-width: 0) and (max-width: 399px) {
  .friend-avatar-xxl {
    height: 5.125rem !important;
    width: 5.125rem !important;
  }
}

.friend-avatar {
  height: 3rem;
  width: 3rem;
  position: relative;
  display: inline-block;
  flex-shrink: 0 !important;
}

.friend-cover-img {
  /* background-image: url("../../images/cover_page.png"); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
}

.h-200px {
  height: 200px !important;
  object-fit: cover;
}
.rounded-top {
  border-top-left-radius: 0.4rem !important;
  border-top-right-radius: 0.4rem !important;
}

.ms-sm-4 {
  margin-left: 1.5rem !important;
}
@media (min-width: 576px) {
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
}

.list-inline {
  padding-left: 0 !important;
  list-style: none !important;
}

@media (min-width: 576px) {
  .text-sm-start {
    text-align: left !important;
  }
}

.friend-text-correction {
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5rem;
  color: #676a79;
  text-align: var(--bs-body-text-align);
  -webkit-text-size-adjust: 100%;
}

.nav-link {
  /* display: block !important; */
  display: flex;
  align-items: center;
  padding-top: 0.1rem !important;
  padding-bottom: 0.1rem !important;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
  color: #000000;
}

.greenLabelConnection {
  color: #21ba45 !important;
  /* border-radius:30px !important; */
  /* padding-top:  4px !important;
    padding-bottom: 4px !important; */
  margin-left: 0.5rem;
  font-size: 9px !important;
  background: #c4eece !important;
  padding: 6px 10px !important;
  float: right !important;
  border-radius: 4px !important;
}

.about-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.09);
  border-radius: 0.4rem;
}

.about-card-title {
  margin-bottom: 0.5rem;
  font-weight: 700;
  line-height: 1.25;
  color: #14191e;
  color: #212529;
  text-align: left;
  font-size: 1.171875rem;
}

.about-card-header:first-child {
  border-radius: calc(0.4rem - 1px) calc(0.4rem - 1px) 0 0;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.border-0 {
  border: 0 !important;
}
.about-card-header {
  padding: 1.25rem 1.25rem;
  padding-bottom: 1.25rem;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}

.about-body-p {
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #676a79;
}

.about-mb-2 {
  margin-bottom: 0.5rem !important;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #676a79;
}

.exp-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.09);
  border-radius: 0.4rem;
}

.exp-small {
  font-weight: 400;
  font-size: 0.875em;
  line-height: 1.5;
  color: #676a79;
  margin-top: 0;
  margin-bottom: 1rem !important;
}

.exp-card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.exp-card-title {
  font-weight: 700;
  line-height: 1.25;
  color: #14191e;
  color: #212529;
  text-align: left;
  font-size: 1.171875rem;
}

.exp-card-header {
  padding-top: 1.25rem !important;
  padding-bottom: 0rem !important;
  padding-left: 1.25rem !important;
  padding-right: 2rem !important;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}

.connection-side-row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  /* margin-top: 1rem;
    margin-right: 1rem;
    margin-left: 1rem; */
}

.main-card-footer {
  padding: 1.25rem 1.25rem;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  background-color: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.09);
}

.friendlist-w-100 {
  width: 100% !important;
  overflow: hidden;
}

.friendlist-avatar {
  height: 3rem;
  width: 3rem;
  position: relative;
  display: inline-block;
  flex-shrink: 0 !important;
}

.friendlist-me-3 {
  margin-right: 1rem !important;
}

@media (min-width: 768px) {
  .friendlist-mb-md-0 {
    margin-bottom: 0 !important;
  }
}

.friendlist-mb-3 {
  margin-bottom: 1rem !important;
}

/* .friendlist-mb-4 {
    margin-bottom: 1.5rem !important;
  } */
.friendlist-align-items-center {
  align-items: center !important;
}

.friendlist-card-body {
  flex: 1 1 auto;
  padding-top: 1.25rem;
  padding-bottom: 0rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.nav.nav-bottom-line .nav-link.active {
  background: transparent;
  color: #a00030;
  border-bottom: 3px solid #a00030;
  display: block !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.nav-link-body {
  margin-bottom: 0.5rem !important;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #676a79 !important;
  text-align: center !important;
}

.nav-link-body:hover {
  margin-bottom: 0.5rem !important;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #676a79 !important;
  text-align: center !important;
}

.nav-link-body-active {
  margin-bottom: 0.5rem !important;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #0f6fec !important;
  text-align: center !important ;
}

.nav-link-body-active:hover {
  margin-bottom: 0.5rem !important;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #0f6fec !important;
  text-align: center !important ;
}

.friendlist-col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.friend-connection-pt-0 {
  padding-top: 0 !important;
}
.friend-connection-position-relative {
  position: relative !important;
}
.friend-connection-card-body {
  flex: 1 1 auto;
  padding: 1.25rem 1.25rem;
  padding-top: 1.25rem;
}

.fcc-pb-0 {
  padding-bottom: 0 !important;
}
.fcc-p-2 {
  padding: 0.5rem !important;
  padding-bottom: 0.5rem;
}
.fcc-card-body {
  /* -ms-flex: 1 1 auto; */
  flex: 1 1 auto;
  /* padding: 1.25rem 1.25rem; */
}

.fcc-h-100 {
  height: 100% !important;
}

.ffc-shadow-none {
  box-shadow: none !important;
}
.fcc-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.09);
  border-radius: 0.4rem;
  overflow: hidden;
}

.fcc-avatar-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.fcc-avatar-xl {
  height: 5.125rem;
  width: 5.125rem;
}
.fcc-avatar {
  position: relative;
  display: inline-block;
}

.post-avatar-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.post-avatar-story {
  position: relative;
  padding: 4px;
  /* z-index: 1; */
}
.post-avatar {
  height: 3rem;
  width: 3rem;
  display: inline-block;
  flex-shrink: 0 !important;
}
.post-me-2 {
  margin-right: 0.5rem !important;
}
.postCardImg-me-2 {
  margin-right: 0.5rem !important;
}
.post-avatar-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.post-nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.post-nav.post-nav-divider .post-nav-item {
  display: flex;
  align-items: center;
}
.post-mb-0 {
  margin-bottom: 0 !important;
}

.post-h6 {
  font-size: 0.9375rem;
}

.post-small {
  font-weight: 400;
  font-size: 0.775em;
  color: #676a79 !important;
}

.post-card-body {
  flex: 1 1 auto;
  padding: 1.25rem 1.25rem;
}

textarea.post-form-control {
  min-height: calc(2.5em + 1rem + 2px);
}
.post-bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}
.post-pe-4 {
  padding-right: 1.5rem !important;
}

.post-w-100 {
  width: 100% !important;
}
.post-position-relative {
  position: relative !important;
}

.post-form-control {
  display: block;
  width: 101%;
  padding: 0.5rem 1rem;
  padding-right: 1rem;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #29292e;
  background-clip: padding-box;
  border: 1px solid #e1e4e6;
  appearance: none;
  border-radius: 0.4rem;
}

.description-h5 {
  font-weight: 700 !important;
  line-height: 1.25 !important;
  color: #14191e !important;
  font-size: 1.171875rem !important;
}

.post-nav.post-nav-divider .post-nav-item + .post-nav-item::before {
  content: " ";
  color: inherit;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  opacity: 0.8;
}

.post-card-img,
.post-card-img-bottom {
  border-bottom-right-radius: calc(0.4rem - 1px);
  border-bottom-left-radius: calc(0.4rem - 1px);
}
.post-card-img,
.post-card-img-top {
  border-top-left-radius: calc(0.4rem - 1px);
  border-top-right-radius: calc(0.4rem - 1px);
}
.post-card-img,
.post-card-img-top,
.post-card-img-bottom {
  width: 100%;
}

.comment-small {
  font-weight: 400;

  font-size: 0.875em;
}
.comment-nav-stack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  min-height: 2rem;
}
.comment-py-3 {
  /* padding-top: 1rem !important; */
  /* padding-bottom: 1rem !important; */
}
.comment-nav {
  flex-wrap: wrap;
  margin-bottom: 0;
  list-style: none;
}

.comment-nav-link.active {
  /* margin-bottom: 0.5rem !important; */
  font-size: 0.875em;
  font-weight: 400;
  line-height: 1.5;
  color: #a00030 !important;
}

.comment-nav-link {
  /* margin-bottom: 0.5rem !important; */
  font-size: 0.875em;
  font-weight: 400;
  line-height: 1.5;
  color: #676a79 !important;
}

.comment-nav-link:hover {
  /* margin-bottom: 0.5rem !important; */
  font-size: 0.875em;
  font-weight: 400;
  line-height: 1.5;
  color: #676a79 !important;
}
.comment-nav-link.active:hover {
  /* margin-bottom: 0.5rem !important; */
  font-size: 0.875em;
  font-weight: 400;
  line-height: 1.5;
  color: #a00030 !important;
}

.comment-name {
  color: #14191e;
  font-size: 0.9375rem;
  font-weight: 700;
  line-height: 1.25;
}

.load-more-comment {
  font-size: 0.8125rem !important;
  border-radius: 0.2rem !important;
  color: #676a79 !important;
  font-weight: 500 !important;
  text-align: center !important;
}

.connectionredLabel {
  color: #ba2121 !important;
  /* border-radius:30px !important; */
  /* padding-top:  4px !important;
          padding-bottom: 4px !important; */
  font-size: 12px !important;
  background: #eec4c4 !important;
  padding: 6px 10px !important;
  float: right !important;
  margin-left: 12px !important;
  border-radius: 4px !important;
  width: 100px !important;
}

.connectiongreenLabel {
  color: #21ba45 !important;
  font-size: 12px !important;
  background: #c4eece !important;
  padding: 6px 10px !important;
  margin-left: 12px !important;
  float: right !important;
  width: 100px !important;
  border-radius: 4px !important;
}
.m-l-0 {
  margin-left: 0 !important;
}
.connectiongrayLabel {
  color: #252525 !important;
  font-size: 12px !important;
  background: #eaebea !important;
  padding: 6px 10px !important;
  margin-left: 12px !important;
  float: right !important;
  width: 100px !important;
  border-radius: 4px !important;
}
.connectionblueLabel {
  color: #0f6fec !important;
  /* border-radius:30px !important; */
  /* padding-top:  4px !important;
          padding-bottom: 4px !important; */
  font-size: 12px !important;
  background: #cddbee !important;
  padding: 6px 10px !important;
  margin-left: 12px !important;
  float: right !important;
  width: 100px !important;
  border-radius: 4px !important;
}
.report-btn {
  /* color: #0f6fec !important; */
  /* border-radius:30px !important; */
  /* padding-top:  4px !important;
          padding-bottom: 4px !important; */
  font-size: 12px !important;
  background: #ece75f !important;
  padding: 6px 10px !important;
  /* margin-left: 12px !important; */
  float: right !important;
  width: 100px !important;
  border-radius: 4px !important;
}
.connectionListredLabel {
  font-size: 12px !important;
  background: #ba2121 !important;
  padding: 6px 10px !important;
  margin-left: 12px !important;
  border-radius: 4px !important;

  border: #ba2121 !important;
}

.connectionListgreenLabel {
  font-size: 12px !important;
  background: #21ba45 !important;
  padding: 6px 10px !important;
  margin-left: 12px !important;
  border-radius: 4px !important;

  border: #21ba45 !important;
}

.connectionListgrayLabel {
  font-size: 12px !important;
  background: #252525 !important;
  padding: 6px 10px !important;
  margin-left: 12px !important;
  border-radius: 4px !important;

  border: #252525 !important;
}

.connectionListblueLabel {
  font-size: 12px !important;
  background: #0f6fec !important;
  padding: 6px 10px !important;
  margin-left: 12px !important;
  border-radius: 4px !important;
  border: #0f6fec !important;
}

.profileInfoOverview {
  font-size: 0.9375rem !important;
  margin-top: 0 !important;
  margin-bottom: 0.5rem !important;
  font-weight: 700 !important;
  line-height: 1.25 !important;
  color: #14191e !important;
}
.nav-item-post-card:hover {
  background: #80808033;
}
/* .nav-item-post-card > a {
  transition: all 0.3s ease-in-out;
} */
.nav-item-post-card {
  cursor: pointer;
  border-radius: 5px;
  padding: 0.5rem;
}

.meeting {
  /* padding-top: 90px; */
  margin-left: 3rem;
  display: flex;
  height: 90vh;
  background: white;
}

.meetingSideBar {
  flex-basis: 30%;
  overflow-y: scroll;
  border-right: 1px solid grey;
}
.meetingSideBar--Heading {
  display: flex;
  font-size: 18px;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid lightgray;
  padding-left: 1rem;
  padding-block: 2rem;
}

.meeting-link:link,
meeting-link:visited {
  color: white;
}
.meetingSidebarCard {
  /* height: 2rem; */
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  border-bottom: 1px solid lightgray;
  padding-block: 1rem;
  cursor: pointer;
}
.meetingSidebarCard h3 {
  font-size: 18px;
  width: 85%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.meetingSidebarCard p {
  font-size: 14px;
  color: gray;
  white-space: nowrap;
  width: 85%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.meetingMainBar {
  flex-basis: 70%;
  display: flex;
  flex-direction: column;
  /* background-color: gray; */
}

.meetingMainBar--heading {
  display: flex;
  /* justify-content: center;
    align-items: center; */
  height: 20%;
  /* background-color: lightgray; */
  /* border-bottom: 1px solid lightgray; */
  flex-direction: column;
  font-size: 18px;
  word-wrap: break-word;
}

.meetingMainBar--body {
  display: flex;
  padding-left: 1rem;
  /* justify-content: flex-start; */
  /* align-items: center; */
  flex-direction: column;
  height: 100%;
  /* align-items: center; */
  word-wrap: break-word;
}

.meetingMainBar-description {
  margin-top: 1rem;
  flex-basis: 50%;
}
.meeting-btn {
  background: white;
  color: black !important;
  font-weight: 700;
  border: 1px solid red;
}

.newanchor:link {
  color: white !important;
  text-decoration: none;
}

.newanchor:hover {
  color: white !important;
}

.newanchor {
  color: white !important;
}

.wrapper-forum {
  max-width: 100%;
  padding: 0 1.5em;
  background-color: #f8f9fa !important;
  border: 1px solid lightgray;
  border-radius: 0.5em;
  overflow: hidden;
}
.wrapper-forum:hover,
.wrapper-forum.focus {
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
}

.search {
  display: flex;
  height: 2.1em;
  align-items: center;
}
.search input {
  flex: 1 1;
  width: 100%;
  border: 0;
  background-color: #f8f9fa !important;
}
.search i {
  color: tomato;
}

:focus {
  outline: none;
}
.Dropdown-menu p {
  text-transform: capitalize;
  line-height: 1.7;
  cursor: pointer;
  text-align: center;
}
.Dropdown-menu p:hover {
  background: whitesmoke;
}

.Dropdown-menu {
  top: 100%;
  left: 18px;
  z-index: 1;
  /* min-width: 21rem; */
  padding: 8px 0;
  margin: 7px 0;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 2px 6px rgb(0 0 0 / 35%);
  list-style: none;
  /* text-align: left; */
  color: #111;
  position: absolute;
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: 7rem;
  min-width: 90%;
}

.result {
  display: flex;
  align-items: center;
}
.result b {
  margin-right: 0.5em;
}
.result span {
  margin: 0 0.5em;
  width: 1em;
  height: 1em;
  border-radius: 0.5em;
}

@keyframes enlarge {
  to {
    max-height: 10em;
  }
}
button[type="search"] {
  text-indent: -999px;
  overflow: hidden;
  width: 40px;
  padding: 0;
  margin: 0;
  border: 1px solid transparent;
  border-radius: inherit;
  background: transparent
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
    no-repeat center;
  cursor: pointer;
  opacity: 0.7;
}

button[type="search"]:hover {
  opacity: 1;
}
.discussionButton {
  color: #fff;
  background: linear-gradient(90deg, #000940 0%, #a00030 100%);
  font-weight: bold;
  /* padding-left: 20px;
  padding-right: 20px; */
  border-color: #a00030;
  font-size: 11px !important;
}
.cardButton-forum {
  border: 1px rgb(245, 245, 245);
  border-radius: 4px !important;
  font-size: 11px !important;
  background: #dcdcdc;
  color: #000;
  cursor: pointer;
}
.cardButton-forum-dropdownCardPost {
  background: #ffffff 0% 0% no-repeat padding-box;
  /* box-shadow: 5px 5px 25px #00000040; */
  border: 1px solid #dededebf;
  border-radius: 4px;
  min-width: 134px;
  height: auto;
  /* padding: 0px 3px 1px 13px; */
  position: absolute;
  /* right: 63%; */
  top: 2.6em;
  cursor: pointer;
  z-index: 10;
  float: left;
  padding: 5px;
}
.cardButton-forum-dropdownCardPost div {
  font-size: 14px;
  padding: 4px;
  text-align: center;
}
.cardButton-forum-dropdownCardPost:hover div {
  /* font-size: 14px;
    padding: 4px;
    text-align: center; */
  color: #000;
}
.refresh-forum {
  background: lightgray;
  padding: 3px 5px;
  border-radius: 3px;
  cursor: pointer;
}
.displayName-forum {
  font-size: 12px;
  color: gray;
  font-weight: bold;
}

.forum-card:hover,
.forum-card.focus {
  background: #eeeeee;
  cursor: pointer;
  border-radius: 10px !important;
  /* margin-bottom: 8px !important; */
}
.forum-card {
  margin-top: 1.1rem;
  padding: 7px 0px 5px 4px;
}
.forum-card-ans {
  margin-top: 1.1rem;
  padding: 11px 15px 5px 13px;
  margin-bottom: 2rem;
}
.forum-tags span {
  font-size: 0.79rem;
  margin-left: 8px;
  font-weight: normal;
  font-weight: initial;
}
.forum-tags:hover {
  color: gray;
}
.forum-menu li {
  cursor: pointer;
}
.segmentsList-forum.selected-forum {
  background: #eeeeee;
  color: black;
  /* border-radius: 10px !important; */
  /* margin-bottom: 8px !important; */
  text-align: center;
}
.forum-ans {
  word-break: break-all;
  color: gray;
  font-size: 11px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.badge-primary {
  color: #fff !important;
  background-color: #007bff;
}
.forum-tags-badges:hover,
a {
  color: white;
}
.forum-tags-badges {
  display: flex;
  overflow: hidden;
}
.forum-tags-badges:hover {
  display: flex;
  flex-direction: row-reverse;
  overflow: visible;
  overflow: initial;
}
.badge-primary:hover,
a {
  color: #fff;
}
.tag-List {
  padding: 6px;
  color: gray;
  font-size: 11px;
  height: 33.1rem;
  overflow: scroll;
  overflow-x: hidden;
  /* text-align: center; */
}
.tag-List p {
  padding: 5px 5px;
}
.tag-List p:hover {
  background: #eeeeee;
  cursor: pointer;
  border-radius: 5px;
}
.tag-List-select {
  color: #007bff;
  padding: 5px !important;
  border-radius: 2px;
}
.tag-List-Unselect {
  background: white;
  padding: 5px !important;
}
.forum-module {
  background: white;
  border-radius: 6px;
  padding: 7px;
  /* height: 39.5rem; */
  overflow: hidden;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}
.forum-description-wrapper {
  /* background-color:rgb(85, 85, 85); */
  /* height: 8rem; */
  border-radius: 12px;
  overflow: hidden;
  background-color: rgb(85 85 85 / 7%);
}
.forum-description-question-wrapper {
  display: flex;
  margin-left: 2rem;
  /* justify-content: center; */
  /* text-transform: capitalize; */
  padding-top: 10px;
  padding-left: 10px;
  /* margin-right: 11px; */
  /* color: white; */
  font-size: 1.1rem;
  /* font-weight: 700; */
}
.desIcons {
  /* height: 34px !important;  */
  width: 16px !important;
  margin-left: 10px !important;
  color: #e83e8c;
  cursor: pointer;
}
.forum-ans-button p:hover {
  color: #a00030;
}
.forum-reply-status {
  color: #a00030 !important;
}
.answer-reply {
  display: flex;
  align-items: baseline;
  padding: 10px;
  /* margin-left: 28px; */
  font-size: 14px;
  color: gray;
}
.forum-ans-button p {
  float: right;
  font-size: 12px;
  color: gray;
  padding-left: 2rem;
  cursor: pointer;
}
.forum-ans-clear {
  float: right;
  font-size: 14px;
  color: gray;
  padding-left: 2rem;
  cursor: pointer;
}
.forum-ans-button span {
  font-size: 12px;
  color: gray;
  cursor: pointer;
  padding-right: 7px;
}

.forum-ans-button span:hover {
  color: #a00030 !important;
}

.forum_questionMedia {
  width: 193px !important;
  height: 145px !important;
  margin-top: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
}
.q-tag {
  display: flex;
  flex-direction: row;
  margin-left: 2rem;
  /* align-items: center; */
  /* justify-content: center; */
}
.forum-text-Editor {
  position: fixed;
  bottom: 50px;
  border: 2px solid #f7f7f7;
  background: rgba(255, 255, 255, 0.95);
  padding: 2px 13px 8px 13px;
  z-index: 1020;
}
.cardBox {
  max-width: 22rem;
  border-radius: 5px !important;
}
.cardBox:hover {
  box-shadow: 5px 10px 15px 1px rgba(0, 0, 0, 253) !important;
}
.bg-forum-dark {
  color: #fff !important;
  background: #444 !important;
  font-weight: bold !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.forum-big-dis {
  font-size: 12px;
  padding: 2px 9px 10px 9px;
  /* color: white; */
  text-indent: 34px;
  /* /* text-align: center; */
}
.badge-secondary {
  color: #fff !important;
  background-color: #6c757d;
  margin-left: 5px;
}
.forum-q-image {
  display: flex;
  justify-content: center;
  align-items: center;
}
.submit-btn {
  flex-grow: 1;
  border-color: gray;
  border-style: dashed;
  padding: 10px;
  border-radius: 13px;
}
.submit-btn:hover {
  border-color: #a00030;
}
.forum-wrapper-search {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 9px;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  flex-wrap: wrap-reverse;
}
.join-Button {
  color: #fff;
  background: linear-gradient(90deg, #000940 0%, #a00030 100%);
  font-weight: bold;
  border-color: #a00030;
  padding: 0.5rem;
  font-size: 11px !important;
  border-radius: 7px !important;
}
.private-forum-wrapper {
  max-width: 100%;
  padding: 0 1.5em;
  background-color: #f8f9fa !important;
  border: 1px solid lightgray;
  border-radius: 0.5em;
  overflow: hidden;
  margin-top: 4rem;
  margin-bottom: 6.7rem;
}

.main__chatcontent {
  flex-grow: 1;
  padding: 9px 18px;
  max-width: 100%;
  border: 1px solid #a00030;
  border-width: 1px;
  border-radius: 19px;
}
.content__header {
  padding-bottom: 15px;
  border-bottom: 1px solid gray;
}
.current-chatting-user {
  display: flex;
  align-items: center;
}
.current-chatting-user p {
  margin: 0;
  font-weight: 600;
}
.content__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.settings .btn-nobg {
  color: #000;
}
.content__body {
  max-height: calc(100vh - calc(100vh / 2));
  overflow: auto;
}
.chat__item {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 15px;
  transition: all 0.3s ease;
  transform: scale(0);
  transform-origin: right;
  animation-name: showIn;
  animation-duration: 0.2s; /* or: Xms */
  animation-iteration-count: 1;
  animation-direction: normal; /* or: normal */
  animation-timing-function: cubic-bezier(
    0.88,
    0.19,
    0.37,
    1.11
  ); /* or: ease, ease-in, ease-in-out, linear, cubic-bezier(x1, y1, x2, y2) */
  animation-fill-mode: both; /* or: backwards, both, none */
  animation-delay: 0.2s; /* or: Xms */
}
@keyframes showIn {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
.chat__item .avatar {
  margin-right: 0px;
  margin-left: 20px;
  background: #fff;
  padding: 1px;
}
.chat__item__content {
  background-color: #ffcccc;
  color: black;
  padding: 15px;
  border-radius: 10px 10px 0 10px;
  max-width: 50%;
  min-width: 215px;
  word-break: break-all;
}
.chat__item__content .chat__meta {
  justify-content: space-between;
  display: flex;
  margin-top: 10px;
}
.chat__item__content .chat__meta span {
  font-size: 10px;
  color: #a00030;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.chat__msg {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.chat__item.other {
  flex-direction: row-reverse;
  transform-origin: left;
}
.chat__item.other .chat__item__content {
  background-color: #e4e4e4;
  color: #000;
  border-radius: 10px 10px 10px 0;
  max-width: 50%;
}
.chat__item.other .avatar {
  margin-right: 20px;
  margin-left: 0px;
}
.chat__item.other .chat__item__content .chat__meta span {
  color: #a00030;
}
.content__footer {
  padding-top: 10px;
}
.sendNewMessage {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #a00030;
  margin-top: 1rem;
}
.sendNewMessage button {
  width: 36px;
  height: 36px;
  background-color: #ecefff;
  border: none;
  box-shadow: none;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  color: #4665ff;
  padding: 0;
  border-radius: 5px;
  line-height: 36px;
  transition: all 0.3s cubic-bezier(0.88, 0.19, 0.37, 1.11);
}
.sendNewMessage button:hover {
  transform: scale(1.2);
}
.sendNewMessage button i {
  display: block;
}
.sendNewMessage input {
  flex-grow: 1;
  padding: 0 15px;
  background-color: transparent;
  border: none;
  outline: none;
}
#sendMsgBtn {
  background-color: #3b5bfe;
  color: #fff;
}

.main__chatlist {
  border-right: 1px solid #ebe7fb;
  padding: 0 40px 0 0px;
}
.chatlist__heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btn-nobg {
  background-color: transparent;
  border: none;
  box-shadow: none;
  font-size: 18px;
  cursor: pointer;
  padding: 10px;
  color: #dad9dd;
  outline: none;
}
.search_wrap {
  background-color: #e6e5ea;
  border-radius: 5px;
}
.search_wrap input {
  background-color: transparent;
  border: none;
  padding: 15px 15px;
  outline: none;
  width: 80%;
  padding-right: 0;
}
.search-btn {
  height: 46px;
  border: none;
  background-color: transparent;
  outline: none;
  width: 20%;
  cursor: pointer;
  font-size: 20px;
}
.chatlist__items {
  margin-top: 30px;
  overflow: auto;
  max-height: calc(100vh - calc(100vh / 2));
}

.chatlist__item {
  display: flex;
  border-bottom: 1px solid #ebe7fb;
  margin-top: 10px;
  cursor: pointer;
  padding: 10px 10px 10px 20px;
  transition: all 0.3s cubic-bezier(0.88, 0.19, 0.37, 1.11);
  transform: scale(0);
  animation-name: showIn;
  animation-duration: 0.2s; /* or: Xms */
  animation-iteration-count: 1;
  animation-direction: normal; /* or: normal */
  animation-timing-function: cubic-bezier(
    0.88,
    0.19,
    0.37,
    1.11
  ); /* or: ease, ease-in, ease-in-out, linear, cubic-bezier(x1, y1, x2, y2) */
  animation-fill-mode: both; /* or: backwards, both, none */
  animation-delay: 0.1s; /* or: Xms */
}
@keyframes showIn {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
.chatlist__item:first-child {
  margin-top: 0;
}
.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;

  margin-right: 20px;
  position: relative;
}
.avatar img {
  max-width: 100%;
  object-fit: cover;
}

.chatlist__item .userMeta p {
  margin: 0;
  padding: 0;
  color: #000;
  font-weight: 600;
  font-size: 14px;
}
.chatlist__item .userMeta span {
  margin: 0;
  padding: 0;
  color: #ceccd3;
  font-weight: 400;
  font-size: 12px;
  display: block;
}
.chatlist__item:hover,
.chatlist__item.active {
  background: #fff;

  border-radius: 10px;
}
.isOnline {
  width: 10px;
  height: 10px;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #ddd;
  border-radius: 50%;
  border: 2px solid #fff;
}
.isOnline.active {
  background-color: tomato;
}
.avatar-img {
  overflow: hidden;
  border-radius: 50%;
  width: 100%;
  height: 100%;
}
.down-icon {
  float: right;
  margin-top: -12px;
  width: 23px;
  height: 25px;
  color: #4665ff;
  cursor: pointer;
}

.invalid-feedback {
  margin-top: -15px;
}

button.btnBlack {
  min-width: 106px;
  width: auto !important;
}
.letter {
  margin: 5px;

  text-align: center;
}
.likeopen {
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: center;
}
.likecount {
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: center;
}
.likeclose {
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: center;
}

.madeInLogo {
  height: 60px;
}
.langBox,
.madeBox {
  margin: 0px;
  font-size: 12px;
  padding-top: 20px;
}
.langDescTxt,
.madeInTxt {
  margin: 0px;
}
.langTxt {
  margin: 0px;
  margin: 0px 10px;
  cursor: pointer !important;
}

.langImg {
  width: 32px;
  height: 24px;
}

.loginInput {
  width: 260px !important;
}

.formInputss input {
  width: 260px !important;
}

@media only screen and (max-width: 768px) {
  .formInputss input {
    width: 260px !important;
  }
}

.formInputsDescription {
  width: 260px !important;
}

.logo img {
  /* height: 60px;
  width:200px;
  margin-right: 10px; */
}

/* Error User */
.error-user-body {
  /* background-color: #2f3242; */
  height: 100vh;
  overflow: hidden;
}
.error-user-body svg {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -250px;
  margin-left: -400px;
}
.message-box {
  height: 200px;
  width: 380px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -100px;
  margin-left: 50px;
  color: white;
  font-family: Roboto;
  font-weight: 300;
}
.message-box h1 {
  font-size: 60px;
  line-height: 46px;
  margin-bottom: 40px;
}
.buttons-con .action-link-wrap {
  margin-top: 40px;
}
.buttons-con .action-link-wrap a {
  background: #68c950;
  padding: 8px 25px;
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  transition: all 0.3s linear;
  cursor: pointer;
  text-decoration: none;
  margin-right: 10px;
}
.buttons-con .action-link-wrap a:hover {
  background: #5a5c6c;
  color: #fff;
}

#Polygon-1,
#Polygon-2,
#Polygon-3,
#Polygon-4,
#Polygon-4,
#Polygon-5 {
  animation: float 1s infinite ease-in-out alternate;
}
#Polygon-2 {
  animation-delay: 0.2s;
}
#Polygon-3 {
  animation-delay: 0.4s;
}
#Polygon-4 {
  animation-delay: 0.6s;
}
#Polygon-5 {
  animation-delay: 0.8s;
}

@keyframes float {
  100% {
    transform: translateY(20px);
  }
}
@media (max-width: 450px) {
  .error-user-body svg {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -250px;
    margin-left: -190px;
  }
  .message-box {
    top: 50%;
    left: 50%;
    margin-top: -100px;
    margin-left: -190px;
    text-align: center;
  }
}
.login-container {
  display: flex;
  align-items: center;
  height: 100vh;
  background: #a00030 0 0 no-repeat padding-box;
  background-image: linear-gradient(9deg, #000940 0%, #a00030 100%);
  justify-content: center;
  overflow: auto;
}
.login-option-wrap > img {
  width: 224px;
  height: auto;
}
.login-options-opt {
  color: white;
  list-style: none;
  display: flex;
  flex-direction: column;
  margin: 0;
  text-align: center;
  padding: 4rem 2rem;
  grid-gap: 2rem;
  grid-gap: 2rem;
  gap: 2rem;
  background: rgba(74, 144, 226, 0.1);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(0px);
  -webkit-backdrop-filter: blur(0px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  margin-top: 1rem;
}
.login-options-btn {
  border: 1px solid white;
  padding: 0.5rem 1.5rem;
  border-radius: 25px 25px !important;
  display: flex;
  align-items: center;
  font-weight: 600 !important;
  transition: all 0.5s;
  text-transform: uppercase;
}
.login-options-img {
  height: 24px;
  width: 24px;
  margin-right: 14px;
}
.reg-btn {
  text-decoration: underline;
  color: wheat !important;
  display: block;
  cursor: pointer;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 13px;
}
.login-options-btn:hover {
  background: white;
  cursor: pointer;
  color: black;
}
.login-other-option {
  font-size: 14px;
}
.m1-option {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 1rem;
  gap: 1rem;
  padding: 0;
}
.m1-option-img {
  height: 24px;
  width: 24px;
  margin: 10px;
  cursor: pointer;
}
.arrow-icon {
  color: white;
  position: absolute;
  left: 20px;
  top: 20px;
  height: 28px;
  width: auto;
  cursor: pointer;
}

.login-option-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
}
.wrap-menu-1 {
  animation: moving-menu-1 0.5s ease-in-out;
}

.wrap-menu-2 {
  animation: moving-menu-2 0.5s ease-in-out;
}

@keyframes moving-menu-1 {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes moving-menu-2 {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
.input-text {
  position: relative;
}
.input-text-input {
  height: 45px;
  width: 100%;
  border-radius: 6px;
  border: 1px solid #e0e3e9;
  padding: 0px 10px;
  padding-right: 40px;
  font-size: 13px;
  background: transparent;
  caret-color: white;
  color: white;
}
.input-text svg {
  position: absolute;
  right: 10px;
  /* cursor: pointer; */
  top: 15px;
  color: white;
  height: 19px;
  width: auto;
}
.custom-select-login {
  background: transparent;
}
.login-details-wrapper {
  background: rgba(74, 144, 226, 0.1);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(0px);
  -webkit-backdrop-filter: blur(0px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.login-details-content {
  font-size: 14px;
  font-weight: 300;
}
.btn-hover:hover {
  background: black !important;
  color: white !important;
}
.otpInput {
  background: transparent;
  color: white;
  padding: 1rem;
  width: 3rem;
  border: 1px solid white;
  border-radius: 11px;
  font-weight: bold;
  font-size: 17px;
  text-align: center;
}
/* Loader */
.loader {
  animation: rotate 1s infinite;
  height: 50px;
  width: 50px;
}

.loader:before,
.loader:after {
  border-radius: 50%;
  content: "";
  display: block;
  height: 20px;
  width: 20px;
}
.loader:before {
  animation: ball1 1s infinite;
  background-color: #fff;
  box-shadow: 30px 0 0 #ff3d00;
  margin-bottom: 10px;
}
.loader:after {
  animation: ball2 1s infinite;
  background-color: #ff3d00;
  box-shadow: 30px 0 0 #fff;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
  }
}

@keyframes ball1 {
  0% {
    box-shadow: 30px 0 0 #ff3d00;
  }
  50% {
    box-shadow: 0 0 0 #ff3d00;
    margin-bottom: 0;
    transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 #ff3d00;
    margin-bottom: 10px;
  }
}

@keyframes ball2 {
  0% {
    box-shadow: 30px 0 0 #fff;
  }
  50% {
    box-shadow: 0 0 0 #fff;
    margin-top: -20px;
    transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 #fff;
    margin-top: 0;
  }
}
.blur-loader-bg {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
  z-index: 5;
}
.login-details-wrapper > label {
  font-weight: 500;
  color: white;
}
.email-verify {
  color: black;
  background: white;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 11px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.email-verify > img {
  width: 24rem;
  height: auto;
  object-fit: cover;
  margin-top: 3rem;
}
.react-tel-input .country-list {
  color: black;
  background: white;
  font-weight: 600;
}
.react-tel-input .form-control {
  height: 45px !important;
  width: 100% !important;
  border-radius: 6px !important;
  border: 1px solid #e0e3e9 !important;
  padding-right: 40px !important;
  font-size: 13px !important;
  background: transparent !important;
  caret-color: white !important;
  color: white !important;
}
.react-tel-input .flag-dropdown {
  background: transparent !important;
}
.resend-otp {
  font-weight: 600;
  margin-top: 1rem !important;
  text-decoration: underline;
  color: blue;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
}
.c-info {
  max-width: 32rem;
  font-size: 9px;
  margin-top: 0.5rem !important;
}
.email-warn {
  background: #ffff004a;
  padding: 1rem;
  border-radius: 5px;
  font-size: 12px;
}

.custom-phone-input .form-control {
  color: black !important; /* Set font color to black */
  caret-color: black !important;
}
.z-1000 {
  z-index: 1000;
}
.input-dark .form-control {
  color: black !important;
}

.market-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: -3rem;
  /* height: 100vh;
  padding-top: 3rem; */
  /* background: rgb(34, 193, 195); */
  /* background: linear-gradient(
      0deg,
      rgba(34, 193, 195, 1) 0%,
      rgba(45, 113, 253, 1) 100%
    ); */
}
/* Error component */
.error-container {
  text-align: center;
  font-size: 180px;
  font-family: "Catamaran", sans-serif;
  font-weight: 800;
  margin: 20px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.error-container > span {
  display: inline-block;
  line-height: 0.7;
  position: relative;
  color: #ffb485;
}
.error-container > span {
  display: inline-block;
  position: relative;
  vertical-align: middle;
}
.error-container > span:nth-of-type(1) {
  color: #d1f2a5;
  animation: colordancing 4s infinite;
}
.error-container > span:nth-of-type(3) {
  color: #f56991;
  animation: colordancing2 4s infinite;
}
.error-container > span:nth-of-type(2) {
  width: 120px;
  height: 120px;
  border-radius: 999px;
}
.error-container > span:nth-of-type(2):before,
.error-container > span:nth-of-type(2):after {
  border-radius: 0%;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: 999px;
  box-shadow: inset 30px 0 0 rgba(209, 242, 165, 0.4),
    inset 0 30px 0 rgba(239, 250, 180, 0.4),
    inset -30px 0 0 rgba(255, 196, 140, 0.4),
    inset 0 -30px 0 rgba(245, 105, 145, 0.4);
  animation: shadowsdancing 4s infinite;
}
.error-container > span:nth-of-type(2):before {
  transform: rotate(45deg);
}

.screen-reader-text {
  position: absolute;
  top: -9999em;
  left: -9999em;
}
@keyframes shadowsdancing {
  0% {
    box-shadow: inset 30px 0 0 rgba(209, 242, 165, 0.4),
      inset 0 30px 0 rgba(239, 250, 180, 0.4),
      inset -30px 0 0 rgba(255, 196, 140, 0.4),
      inset 0 -30px 0 rgba(245, 105, 145, 0.4);
  }
  25% {
    box-shadow: inset 30px 0 0 rgba(245, 105, 145, 0.4),
      inset 0 30px 0 rgba(209, 242, 165, 0.4),
      inset -30px 0 0 rgba(239, 250, 180, 0.4),
      inset 0 -30px 0 rgba(255, 196, 140, 0.4);
  }
  50% {
    box-shadow: inset 30px 0 0 rgba(255, 196, 140, 0.4),
      inset 0 30px 0 rgba(245, 105, 145, 0.4),
      inset -30px 0 0 rgba(209, 242, 165, 0.4),
      inset 0 -30px 0 rgba(239, 250, 180, 0.4);
  }
  75% {
    box-shadow: inset 30px 0 0 rgba(239, 250, 180, 0.4),
      inset 0 30px 0 rgba(255, 196, 140, 0.4),
      inset -30px 0 0 rgba(245, 105, 145, 0.4),
      inset 0 -30px 0 rgba(209, 242, 165, 0.4);
  }
  100% {
    box-shadow: inset 30px 0 0 rgba(209, 242, 165, 0.4),
      inset 0 30px 0 rgba(239, 250, 180, 0.4),
      inset -30px 0 0 rgba(255, 196, 140, 0.4),
      inset 0 -30px 0 rgba(245, 105, 145, 0.4);
  }
}
@keyframes colordancing {
  0% {
    color: #d1f2a5;
  }
  25% {
    color: #f56991;
  }
  50% {
    color: #ffc48c;
  }
  75% {
    color: #effab4;
  }
  100% {
    color: #d1f2a5;
  }
}
@keyframes colordancing2 {
  0% {
    color: #ffc48c;
  }
  25% {
    color: #effab4;
  }
  50% {
    color: #d1f2a5;
  }
  75% {
    color: #f56991;
  }
  100% {
    color: #ffc48c;
  }
}

/* demo stuff */
* {
  box-sizing: border-box;
}
.error-wrapper {
  background-color: #416475;
  margin-bottom: 50px;
  height: 100vh;
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  top: -22px;
  bottom: 0;
}

.error-wrapper h1 {
  text-align: center;
  margin: 30px 15px;
  color: white;
}
.zoom-area {
  max-width: 490px;
  margin: 30px auto 30px;
  font-size: 19px;
  text-align: center;
}
.link-container {
  text-align: center;
}
.error-wrapper a.more-link {
  text-transform: uppercase;
  font-size: 13px;
}

/* Error componet end */
.form-wrapper {
  background: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  width: 95%;
  border-radius: 1rem;
}
.form-wrapper > div:first-child {
  width: 100%;
  font-weight: bold;
  padding: 1rem;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  border-bottom: 1px solid brown;
}
.form-wrapper h4 {
  text-align: center;
  font-weight: bold;
  font-size: 22px;
  color: #a00030;
}
.form-wrapper p {
  font-size: 12px;
  font-weight: 500;
  color: gray;
}
.custom-form label {
  font-weight: bold;
  font-size: 17px;
}
.market-text-area {
  border: 1px solid gray;
  border-radius: 5px;
  width: 100%;
  padding: 1rem;
}
.bg-color {
  color: brown;
}
.kb-data-box {
  width: 100%;
  flex: 1 1;
}

.kb-modal-data-title {
  margin-bottom: 10px;
}
.kb-data-title h6 {
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 600;
}
.kb-file-upload {
  margin-bottom: 20px;
}
.file-upload-box {
  border: 1px dashed #b6bed1;
  background-color: #f0f2f7;
  border-radius: 4px;
  min-height: 150px;
  position: relative;
  overflow: hidden;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #8194aa;
  font-weight: 400;
  font-size: 15px;
}
.file-upload-box .file-upload-input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}
.file-link {
  color: #475f7b;
  text-decoration: underline;
  margin-left: 3px;
}
.file-upload-box .file-link:hover {
  text-decoration: none;
}
.file-atc-box {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.file-image {
  /* width: 130px;
    min-width: 130px;
    height: 85px;
    min-height: 85px; */
  width: 130px;
  height: 85px;
  background-size: cover;
  border-radius: 5px;
  margin-right: 15px;
  background-color: #eaecf1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  color: #475f7b;
  padding: 3px;
}
.file-image img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 4px;
}
.file-detail {
  flex: 1 1;
  width: calc(100% - 210px);
}
.file-detail h6 {
  word-break: break-all;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 8px;
}
.file-detail p {
  font-size: 12px;
  color: #8194aa;
  line-height: normal;
  line-height: initial;
  font-weight: 400;
  margin-bottom: 8px;
}
.file-actions {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.file-action-btn {
  font-size: 12px;
  color: #8194aa;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  text-decoration: underline;
  margin-right: 15px;
  cursor: pointer;
}
.file-action-btn:hover {
  color: #3d546f;
  text-decoration: underline;
}
.file-atc-box:last-child {
  margin-bottom: 0;
}
.f-image {
  height: 100%;
  width: 100%;
  display: block;
  object-fit: cover;
  border-radius: 9px;
  margin-bottom: 1rem;
}
.form-bg {
  background: linear-gradient(
    0deg,
    rgba(34, 193, 195, 1) 0%,
    rgba(45, 113, 253, 1) 100%
  );
}
.refer-phone-btn {
  background: white;
  color: black;
  border-radius: 5px;
  font-size: 13px;
  padding: 10px;
  font-weight: bold;
  cursor: pointer;
}
.text-input-v2 {
  width: 100%;
  border: 1px solid gainsboro;
  border-radius: 5px;
  height: 44px;
  margin-top: 3px;
  padding: 0 12px;
}
.service-otp > input {
  border: 1px solid black;
  color: black;
  text-align: center;
  height: 49px;
}
.service-landing-wrapper {
  background: #f9f9fc;
  min-height: 100vh;
}

.service-sec-1 {
  display: flex;
  grid-gap: 2rem;
  gap: 2rem;
  align-items: center;
  justify-content: center;
}
.service-sec-1-img {
  /* width: 36rem; */
  flex-shrink: 0;
  border-radius: 7px;
  overflow: hidden;
  border-radius: 9rem 2rem;
}
.service-sec-1 h2 {
  font-weight: bold;
  margin-bottom: 2rem;
  font-size: 2rem;
}
.service-btn {
  margin-top: 2rem;
  border-radius: 1rem !important;
  padding: 12px !important;
}
.service-wrap {
  width: 80%;
  margin: 0 auto;
}
.service-sec-2 {
  padding-top: 4rem;
  width: 50%;
  margin: auto;
  text-align: center;
  min-height: 100vh;
  place-content: center;
  display: grid;
  grid-gap: 1rem;
  gap: 1rem;
}

.service-sec-2 h6 {
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: 3rem;
}
.service-types {
  list-style: none;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  grid-gap: 2rem;
  gap: 2rem;
}
.service-types svg {
  color: white;
  width: 6rem;
  height: auto;
  background: #a00030;
  border-radius: 50%;
  padding: 1rem;
}
.font-bold {
  font-weight: bold;
}
@media (max-width: 960px) {
  .service-sec-1 {
    flex-direction: row-reverse;
    flex-wrap: wrap;
    height: 100%;
  }
  .service-sec-1 > div:nth-child(2) {
    width: 100%;
  }
  .service-wrap {
    width: 95%;
    margin: 0 auto;
  }
  .service-sec-2 {
    height: 100%;
    width: 90%;
  }
}

.font-14 {
  font-size: 14px;
}

.service-card {
  min-height: 21rem;
  width: 18rem;
  background: white;
  text-align: center;
  position: relative;
  /* overflow: hidden; */
  border-radius: 5px;
  transition: all 0.5s ease-in;
  cursor: pointer;
}
.service-card > div {
  background: linear-gradient(90deg, #000940 0%, #a00030 100%);
  width: 100%;
  height: 3rem;
  position: absolute;
  text-align: center;
  bottom: 0;
  border-radius: 5rem 5rem 0rem 0rem;
  transition: all 0.5s ease-in;
}
.service-card:hover > div {
  height: 100%;
  width: 100%;
  border-radius: 0%;
}
.service-card:hover {
  color: white;
}
.service-info-wrap {
  display: flex;
  min-height: 100vh;
}
.service-info-wrap {
  display: flex;
  padding-top: 5rem;
  grid-gap: 2rem;
  gap: 2rem;
}
.service-landing-wrapper h6 {
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 1rem;
  /* margin-top: 2rem; */
}
.service-info-wrap li {
  font-size: 16px;
  color: gray;
}
.service-card p {
  color: gray;
}
.service-card > section:nth-child(1) {
  z-index: 10;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid brown;
  overflow: hidden;
  border-radius: 5px;
}
.service-card > section:nth-child(1) ul {
  padding: 14px;
  text-align: start;
}

.service-card:hover p {
  color: white;
}
.gap-1 {
  grid-gap: 1rem;
  gap: 1rem;
}
.service-wis {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding-top: 2rem; */
  grid-gap: 2rem;
  gap: 2rem;
  flex-wrap: wrap;
  margin-bottom: 3rem;
}
.service-wis svg {
  /* background: linear-gradient(90deg, #000940 0%, #a00030 100%); */
  width: 4rem;
  height: auto;
  padding: 14px;
}
.service-wis div {
  border-radius: 50%;
  color: white;
}
.service-head-under {
  /* font-size: 1.5rem; */
  border-bottom: 2px solid brown;
  width: -moz-fit-content;
  width: fit-content;
  padding: 12px 0px;
  margin: auto;
  font-weight: bold;
  color: #000940;
}
.service-tag {
  border-radius: 24px;
  padding: 7px 17px;
  /* font-weight: bold; */
  color: black;
  transition: all 0.5s ease-in-out;
  border: 1px solid brown;
  font-size: 12px;
  background: white;
}
.service-tag:hover {
  /* background: brown; */
}
.service-bg-gradient {
  /* background: linear-gradient(3deg, #000940 0%, #a00030 100%); */
  color: black !important;
}
.service-form {
  max-width: 33rem;
  text-align: start;
  padding: 2rem 2rem;
  /* border: 1px solid brown; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.text-crass {
  text-decoration: line-through;
  color: gray;
  margin-right: 5px;
  font-size: 18px;
}
.service-box {
  max-width: 21rem;
  border: 2px solid brown;
  border-radius: 35px;
  padding: 1.5rem;
  background: white;
  min-height: 19rem;
}
.service-box p {
  margin-top: 2rem !important;
  font-size: 15px;
  color: gray;
}
.service-box h5 {
  border: 1px solid gray;
  width: -moz-fit-content;
  width: fit-content;
  padding: 13px;
  border-radius: 5px;
  font-weight: bold;
  color: brown;
}
.service-card li {
  font-weight: bold;
  font-size: 14px;
}
.s-heading {
  font-size: 3rem;
  font-weight: 700;
  color: #a00030;
}
.service-img-grid {
  grid-gap: 3rem;
  gap: 3rem;
  align-items: center;
  display: flex;
}
.service-img-grid-head h2 {
  font-size: 2rem;
  max-width: 30rem;
}
.slider-arrow {
  position: absolute;
  top: 50%;
  background: #00000063;
  width: 3rem;
  height: 3rem;
  padding: 5px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.slider-arrow svg {
  width: 25px;
  height: auto;
  margin-left: 6px;
}

.slider-arrow.a-left {
  left: -3rem;
}
.slider-arrow.a-right {
  right: -3rem;
}
.carouselv2-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  margin: auto;
  position: relative;
}

.carouselv2 {
  position: relative;
  overflow: hidden;
  /* width: 900px; */
  /* min-height: 34rem; */
  border-radius: 20px;
}

.carouselv2-inner {
  display: flex;
  transition: transform 0.5s ease-in-out;
  /* gap: 1rem; */
  /* overflow: hidden; */
}

.carouselv2-item {
  /* min-width: 100%; */
  /* min-width: 50%; */
  overflow: hidden;
  padding: 1rem;
  background: white;
  margin: 1rem;
  border-radius: 10px;
  height: 100%;
}
.s-min-50 {
  min-width: 50%;
  overflow: hidden;
  padding: 1rem;
}

.carouselv2-item img {
  width: 100%;
  height: 500px;
  object-fit: cover;
}

.carouselv2-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  color: white;
  font-size: 24px;
}

.prevv2 {
  left: -5rem;
}

.nextv2 {
  right: -5rem;
}

.carouselv2-indicators {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  grid-gap: 5px;
  gap: 5px;
}

.carouselv2-indicators span {
  width: 8px;
  height: 8px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  cursor: pointer;
  transition: width 0.3s ease-in-out;
}

.carouselv2-indicators .active {
  width: 16px;
  background: white;
}
.w-fit-content {
  width: -moz-fit-content;
  width: fit-content;
}
.sub-dis-image {
  position: absolute;
  width: 7rem;
  height: 7rem;
  top: -3rem;
  left: -3rem;
  z-index: 1000;
  rotate: 345deg;
}
.service-form-heading {
  max-width: 37rem;
  text-align: start;
  font-size: 15px;
  color: black;
}

.service-button {
  font-size: 2rem;
  background: none;
  border: none;
  position: relative;
  /* to do, calc arrow-stripes so they repeat nicely on animation loop   */
  --padding-block: 1rem;
  --padding-inline: 2rem;
  --arrow: 5rem;
  --arrow-stripes: 0.8rem;

  padding: var(--padding-block) var(--padding-inline);
  padding-right: calc(var(--padding-inline) + var(--arrow));
  filter: drop-shadow(4px 4px 4px hsl(0 0% 0% / 0.5));
  color: white;
  border-radius: 999vmax 0 0 999vmax;
  cursor: pointer;
  transition: all 125ms;
}
.service-button:active {
  scale: 0.975;
}
.service-button:hover::after {
  animation-play-state: paused;
}
.service-button::after {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: inherit;
  z-index: -1;
  background-color: green;
  background-image: linear-gradient(transparent 50%, hsl(0 0% 0% / 0.5) 0);
  animation: mask-position 5s linear infinite;

  --conic-stops: black 0 90deg, #0000 0;
  --linear-stops: #0000 0px, black 1px var(--arrow-stripes),
    #0000 calc(var(--arrow-stripes) + 1px) calc(var(--arrow-stripes) * 2);
  --mask-image: conic-gradient(from 225deg at right, var(--conic-stops)),
    conic-gradient(from 225deg at right, var(--conic-stops)),
    repeating-linear-gradient(-135deg, var(--linear-stops)),
    repeating-linear-gradient(-45deg, var(--linear-stops));
  --mask-position: 0 0, 0 0, 0 0, 0 100%;
  --mask-position-to: 0, 0, -100% 0, -100% 100%;
  --mask-size: calc(100% - var(--arrow)) 100%, 100%, 200% 50%, 200% 50%;
  --mask-repeat: no-repeat, repeat, repeat-x, repeat-x;

  -webkit-mask-image: var(--mask-image);
  -webkit-mask-position: var(--mask-position);
  -webkit-mask-size: var(--mask-size);
  -webkit-mask-repeat: var(--mask-repeat);
  -webkit-mask-composite: source-over, source-out, source-over, source-over;

  mask-image: var(--mask-image);
  mask-position: var(--mask-position);
  mask-size: var(--mask-size);
  mask-repeat: var(--mask-repeat);
  -webkit-mask-composite: source-over, subtract, source-over, source-over;
          mask-composite: add, subtract, add, add;
}
.carouselv3-card {
  background: white;
  border-radius: 14px;
  overflow: hidden;
}
.carouselv3-card > div:nth-child(1) {
  height: 19rem;
  width: 100%;
}
@media (max-width: 739px) {
  .service-padding {
    width: 85%;
    padding: 0;
    margin: 0 auto;
  }
}
.service-image {
  align-items: center;
  justify-content: center;
  display: flex;
}

/* Base styles for the button */

/* Base styles for the button */
.getStartedWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.getStartedButton {
  background-color: linear-gradient(90deg, #000940 0%, #a00030 100%);
  border: 1px solid #ffb485;
  color: #fff; /* White text */
  padding: 9px 15px;
  text-align: center;
  text-decoration: none;
  font-size: 1rem; /* Base font size */
  cursor: pointer;
  border-radius: 50px; /* Rounded shape on both sides */
  margin-top: 30px;
}

.fee {
  font-size: 1rem; /* Base font size */
  margin: 0 5px; /* Spacing between elements */
}

.fee strong {
  font-weight: bold;
}

.text-crass {
  font-size: 1.2rem; /* Slightly larger font size for $4999 */
  font-weight: bold;
}

.div-calendar {
  /* width: 550px; */
  max-width: 100%;
  background: #ffffff;
  /* border: 1px solid #a0a096; */
  line-height: 1.125em;
  color: #000000;
  /* border: 1px solid #000000; */
  box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.05);
  border: 1px solid #eaeaea;
  border-radius: 10px;
  position: relative;
  padding: 8px !important;
}

.rbc-row-segment {
  padding: 1px 1px 1px 1px !important;
  border-radius: 4px !important;
  display: inline-flex;
}
.rbc-event,
.rbc-day-slot .rbc-background-event {
  border: none;
  box-sizing: border-box;
  box-shadow: none;
  margin: 8px;
  padding: 2px 5px;
  background-color: #3174ad;
  border-radius: 14px !important;
  font-size: 1px !important;
  color: #fff;
  cursor: pointer;
  width: 13px !important;
  height: 13px !important;
  text-align: left;
  display: inline-flex;
}

.rbc-toolbar {
  font-weight: bold !important;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;
  color: #f97516;
}

.rbc-btn-group {
  display: inline-block;
  white-space: nowrap;
  color: #ffffff !important;
}

.rbc-toolbar button:hover {
  color: #f97516 !important;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.rbc-toolbar button {
  color: #f97516 !important;
  display: inline-block;
  margin: 0;
  text-align: center;
  vertical-align: middle;
  background: none;
  background-image: none;
  border: 1px solid #ccc;
  padding: 0.375rem 1rem;
  border-radius: 4px;
  line-height: normal;
  white-space: nowrap;
}

.rbc-calendar *,
.rbc-calendar *:before,
.rbc-calendar *:after {
  box-sizing: inherit;
}
.calender-vcard {
  min-height: 100vh;
  display: flex;
  background-image: linear-gradient(205deg, #000940 0%, #a00030 100%);
  /* background-image: linear-gradient(
      206deg,
      rgba(72, 45, 45, 0.46) 0%,
      rgba(72, 45, 45, 0.46) 14.286%,
      rgba(102, 42, 45, 0.46) 14.286%,
      rgba(102, 42, 45, 0.46) 28.572%,
      rgba(131, 39, 44, 0.46) 28.572%,
      rgba(131, 39, 44, 0.46) 42.858%,
      rgba(161, 36, 44, 0.46) 42.858%,
      rgba(161, 36, 44, 0.46) 57.144%,
      rgba(191, 33, 43, 0.46) 57.144%,
      rgba(191, 33, 43, 0.46) 71.43%,
      rgba(220, 30, 43, 0.46) 71.43%,
      rgba(220, 30, 43, 0.46) 85.716%,
      rgba(250, 27, 42, 0.46) 85.716%,
      rgba(250, 27, 42, 0.46) 100.002%
    ),
    linear-gradient(
      127deg,
      rgb(254, 36, 223) 0%,
      rgb(254, 36, 223) 14.286%,
      rgb(227, 44, 210) 14.286%,
      rgb(227, 44, 210) 28.572%,
      rgb(201, 52, 198) 28.572%,
      rgb(201, 52, 198) 42.858%,
      rgb(174, 60, 185) 42.858%,
      rgb(174, 60, 185) 57.144%,
      rgb(147, 67, 172) 57.144%,
      rgb(147, 67, 172) 71.43%,
      rgb(121, 75, 160) 71.43%,
      rgb(121, 75, 160) 85.716%,
      rgb(94, 83, 147) 85.716%,
      rgb(94, 83, 147) 100.002%
    ); */
  align-items: stretch;
}
.calender-vcard > section:nth-child(1) {
  flex: 1 1;
  display: flex;
  margin-bottom: auto;
  flex-direction: column;
  margin-top: 2rem;
  padding: 1rem;
}
.calender-vcard > section:nth-child(2) {
  flex: 2 1;
  background: #efece3;
  /* height: 100vh; */
}
.cal-small-box {
  border: 1px solid white;
  border-radius: 5px;
  padding: 0.5rem;
  text-align: center;
  background: white;
  margin-top: 1rem;
  width: -moz-fit-content;
  width: fit-content;
}
.cal-small-box > p:nth-child(1) {
  background: gainsboro;
  width: 100%;
  padding: 12px 14px;
  border-radius: 5px;
}
.cal-small-box > p:nth-child(2) {
  font-size: 13px;
  font-weight: bold;
  margin-top: 0.5rem !important;
}
.box-rd {
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 23px;
  padding: 2rem;
  text-align: start;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.gray-box {
  border: 1px solid gray;
  background: #dcdcdc47;
  border-radius: 14px;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.gray-box:hover {
  background-color: #f3f3f3;
  background-image: linear-gradient(
    90deg,
    #f3f3f3 25%,
    #e6e6e6 37%,
    #f3f3f3 63%
  );
  background-size: 200% 100%;
  animation: skeleton-loading 1.5s ease-in-out infinite;
}

@keyframes skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: -200% 50%;
  }
}
.booking-container {
  /* transition: all 0.5s ease-in; */
  position: absolute;
  top: 0;
  animation: booking-slot 0.1s ease-in;
}

@keyframes booking-slot {
  from {
    top: 60%;
  }
  to {
    top: 0%;
  }
}
.f-1 {
  flex: 1 1;
}
.cal-scroll {
  list-style: none;
  display: flex;
  grid-gap: 1rem;
  grid-gap: 1rem;
  gap: 1rem;
  overflow: scroll;
  padding: 0;
  scroll-behavior: smooth;
  scrollbar-width: none;
  margin: 0;
}
.cal-scroll::-webkit-scrollbar {
  width: 6px;
  height: 3px;
}
.gray-box-1 {
  border: 1px solid gray;
  border-radius: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}
.calender-shadow-box {
  right: 41px;
  display: flex;
  align-items: center;
  background: pink;
  position: absolute;
  padding: 4px 9px;
  border-radius: 12px;
  grid-gap: 7px;
  gap: 7px;
  display: flex;
}
.calendar-wrapper-an {
  animation: cal-wrap 0.4s ease-in-out;
  margin-top: 1rem;
}
@keyframes cal-wrap {
  from {
    margin-top: 50%;
  }
  to {
    margin-top: 0%;
  }
}
.cal-plans {
  border-radius: 21px;
  border-top: 23px solid brown;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
.cal-label label {
  font-size: 13px;
  color: gray;
}
.glass-shadow {
  background-image: repeating-linear-gradient(
    45deg,
    rgb(241, 241, 241) 0px,
    rgb(241, 241, 241) 144px,
    rgb(207, 207, 207) 144px,
    rgb(207, 207, 207) 288px,
    rgb(224, 224, 224) 288px,
    rgb(224, 224, 224) 432px
  );
}
.box-shadow-v1 {
  border-radius: 30px !important;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
.week-wrap {
  max-height: 4rem;
  transition: max-height 0.5s ease-in;
  overflow: hidden;
}
.week-wrap.active {
  max-height: 26rem;
  overflow: scroll;
}
.w-28 {
  width: 28px !important;
}
.fit-content {
  width: -moz-fit-content;
  width: fit-content;
}
.cal-war {
  cursor: pointer;
  position: absolute;
  right: -56px;
  top: -11px;
  color: white;
  padding: 11px 9px;
  width: 7rem;
  text-align: center;
  border-radius: 50%;
  font-size: 14px;
  font-weight: bold;
  scale: 1;
  transition: all 0.2s ease-in;
  background-image: radial-gradient(
      circle at 85% 1%,
      hsla(190, 0%, 93%, 0.05) 0%,
      hsla(190, 0%, 93%, 0.05) 96%,
      transparent 96%,
      transparent 100%
    ),
    radial-gradient(
      circle at 14% 15%,
      hsla(190, 0%, 93%, 0.05) 0%,
      hsla(190, 0%, 93%, 0.05) 1%,
      transparent 1%,
      transparent 100%
    ),
    radial-gradient(
      circle at 60% 90%,
      hsla(190, 0%, 93%, 0.05) 0%,
      hsla(190, 0%, 93%, 0.05) 20%,
      transparent 20%,
      transparent 100%
    ),
    radial-gradient(
      circle at 79% 7%,
      hsla(190, 0%, 93%, 0.05) 0%,
      hsla(190, 0%, 93%, 0.05) 78%,
      transparent 78%,
      transparent 100%
    ),
    radial-gradient(
      circle at 55% 65%,
      hsla(190, 0%, 93%, 0.05) 0%,
      hsla(190, 0%, 93%, 0.05) 52%,
      transparent 52%,
      transparent 100%
    ),
    linear-gradient(135deg, rgb(37, 56, 222), rgb(96, 189, 244));
  /* background-image: linear-gradient(205deg, #000940 0%, #a00030 100%); */
}
.cal-war:hover {
  scale: 1.1;
}
.info-box {
  border-radius: 5px;
  padding: 5px;
  font-size: 10px;
  font-weight: 600;
  border: 1px solid #8080807d;
}
.info-box > svg {
  width: 14px;
  height: auto;
  margin-right: 7px;
}
.nomini {
  position: absolute;
  right: 0;
  z-index: 100;
  animation: cal-war 1s infinite ease-in;
  cursor: pointer;
}

@keyframes cal-war {
  25% {
    scale: 1;
  }
  50% {
    scale: 1.1;
  }
  75% {
    scale: 1;
  }
}

.nomini > img {
  width: 98px;
  height: auto;
  position: relative;
}

/* Togle button */

.toggle-label {
  position: relative;
  display: block;
  width: 60px;
  height: 30px;
}

.toggle-label > input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-label > span {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.3s;
  cursor: pointer;
  background: #a00030;
  border-radius: 30px;
}
.toggle-label > span:before {
  position: absolute;
  content: "";
  height: 25px;
  width: 25px;
  left: 3px;
  bottom: 2.6px;
  background-color: #fff;
  border-radius: 50%;
  transition: 0.3s;
}
.toggle-active:after {
  position: absolute;
  content: "ON";
  font-size: 12px;
  bottom: 5px;
  left: 6px;
  color: white;
  font-weight: bold;
}
.toggle-un-active:after {
  position: absolute;
  content: "OFF";
  font-size: 12px;
  bottom: 5px;
  left: 30px;
  right: 0;
  color: white;
  font-weight: bold;
}
.toggle-v2-active:after {
  position: absolute;
  content: "YES";
  font-size: 12px;
  bottom: 5px;
  left: 6px;
  color: white;
  font-weight: bold;
}
.toggle-v2-un-active:after {
  position: absolute;
  content: "NO";
  font-size: 12px;
  bottom: 5px;
  left: 30px;
  right: 0;
  color: white;
  font-weight: bold;
}
.toggle-label > input:checked + span {
  background-color: #17a2b8;
}

.toggle-label > input:checked + span:before {
  transform: translateX(29px);
}

.cal-res-1 {
  padding-left: 3rem;
}
.cal-text-area {
  width: 21rem;
  margin-left: auto;
  margin-right: 21px;
  border: 1px solid gray;
  margin-top: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  height: 43px;
}
.invalid-url {
  border-radius: 6px;
  border: 2px solid red;
}
.valid-url {
  border-radius: 6px;
  border: 2px solid green;
}

.checkbox-wrapper-26 * {
  -webkit-tap-highlight-color: transparent;
  outline: none;
}

.checkbox-wrapper-26 input[type="checkbox"] {
  display: none;
}

.checkbox-wrapper-26 label {
  --size: 20px;
  --shadow: calc(var(--size) * 0.07) calc(var(--size) * 0.1);

  position: relative;
  display: block;
  width: var(--size);
  height: var(--size);
  margin: 0 auto;
  background-color: #f72414;
  border-radius: 50%;
  box-shadow: 0 var(--shadow) #ffbeb8;
  cursor: pointer;
  transition: 0.2s ease transform, 0.2s ease background-color,
    0.2s ease box-shadow;
  overflow: hidden;
  /* z-index: 1; */
}

.checkbox-wrapper-26 label:before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  width: calc(var(--size) * 0.7);
  height: calc(var(--size) * 0.7);
  margin: 0 auto;
  background-color: #fff;
  transform: translateY(-50%);
  border-radius: 50%;
  box-shadow: inset 0 var(--shadow) #ffbeb8;
  transition: 0.2s ease width, 0.2s ease height;
}

.checkbox-wrapper-26 label:hover:before {
  width: calc(var(--size) * 0.55);
  height: calc(var(--size) * 0.55);
  box-shadow: inset 0 var(--shadow) #ff9d96;
}

.checkbox-wrapper-26 label:active {
  transform: scale(0.9);
}

.checkbox-wrapper-26 .tick_mark {
  position: absolute;
  top: -1px;
  right: 0;
  left: calc(var(--size) * -0.05);
  width: calc(var(--size) * 0.6);
  height: calc(var(--size) * 0.6);
  margin: 0 auto;
  margin-left: calc(var(--size) * 0.14);
  transform: rotateZ(-40deg);
}

.checkbox-wrapper-26 .tick_mark:before,
.checkbox-wrapper-26 .tick_mark:after {
  content: "";
  position: absolute;
  background-color: #fff;
  border-radius: 2px;
  opacity: 0;
  transition: 0.2s ease transform, 0.2s ease opacity;
}

.checkbox-wrapper-26 .tick_mark:before {
  left: 0;
  bottom: 0;
  width: calc(var(--size) * 0.1);
  height: calc(var(--size) * 0.3);
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.23);
  transform: translateY(calc(var(--size) * -0.68));
}

.checkbox-wrapper-26 .tick_mark:after {
  left: 0;
  bottom: 0;
  width: 100%;
  height: calc(var(--size) * 0.1);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.23);
  transform: translateX(calc(var(--size) * 0.78));
}

.checkbox-wrapper-26 input[type="checkbox"]:checked + label {
  background-color: #07d410;
  box-shadow: 0 var(--shadow) #92ff97;
}

.checkbox-wrapper-26 input[type="checkbox"]:checked + label:before {
  width: 0;
  height: 0;
}

.checkbox-wrapper-26 input[type="checkbox"]:checked + label .tick_mark:before,
.checkbox-wrapper-26 input[type="checkbox"]:checked + label .tick_mark:after {
  transform: translate(0);
  opacity: 1;
}
.small-box {
  border: 0.5px solid #b5b5b5;
  padding: 8px;
  border-radius: 7px;
  cursor: pointer;
}

.cal-v2-wrap {
  background: white;
  margin-top: 2rem;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  overflow: hidden;
}
.cal-p {
  padding: 3rem 2.5rem;
}
.cal-drawer {
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 0;
  background: #b0b0b091;
}
.cal-drawer > li {
  list-style: none;
  display: flex;
  flex-direction: column;
  border-top: 1px solid gray;
  padding: 0.2rem 2.1rem;
  background: #b0b0b091;
}
.cal-drawer span {
  border: 1px solid gray;
  border-radius: 50%;
  text-align: center;
}
.cal-drawer svg {
  width: 27px;
  height: auto;
}
.cal-dot-border {
  height: auto;
  border: 3px solid gray;
  padding: 12px;
  border-radius: 21px;
  border-style: dotted;
}
.cal-box {
  height: 4rem;
  transition: all 0.5s ease-in-out;
  overflow: hidden;
}
.cal-box.open {
  height: 8rem;
}
.arrowIcon-cal {
  rotate: 0deg;
  transition: all 0.5s ease-in-out;
}
.arrowIcon-cal.active {
  rotate: 180deg;
}
.cal-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr)); */
  grid-gap: 1rem;
  gap: 1rem;
  margin: 1rem;
}
.cal-grid > :last-child {
  grid-column: 1/-1;
}
.cal-full {
  grid-column: 1/-1;
}
.cal-grid > section {
  width: 100%;
}
.cal-border-c {
  padding: 6px;
  font-weight: bold;
  font-size: 19px;
  width: 13rem;
  text-align: center;
  border-right: 1px solid gray;
  border-top-right-radius: 26%;
  overflow: hidden;
  background: #607d8b6e;
  display: flex;
  align-items: center;
}
.booking-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
  grid-gap: 2rem;
  gap: 2rem;
}
.css-b62m3t-container {
  z-index: 100;
}
.z-1000 {
  z-index: 1000;
}
.z-1001 {
  z-index: 1001;
}
.z-1002 {
  z-index: 1002;
}
.radio-btn {
  width: 1rem !important;
  height: auto;
}

/* src/PremiumSubscriptionActivation.css */

.premium-subscription-activation {
    /* max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9; */
    text-align: center;
}

.premium-subscription-activation h2 {
    margin-bottom: 20px;
    color: #333;
}

.premium-subscription-activation p {
    margin-bottom: 20px;
}

.premium-subscription-activation ul {
    text-align: left;
    margin-bottom: 20px;
}

.subscription-plans {
    display: flex;
    justify-content: space-around;
    margin-bottom: 50px;
}

.plan-card {
    border: 1px solid #ccc;
    border-radius: 3rem 3rem 42px 3px;;
    
    width: 25%;
    cursor: pointer;
    transition: all 0.3s ease;
    color: white;
}

.plan-card:hover {
    border-color: #a00030
}

.plan-card.selected {
    border-color: #a00030;
    background-color: #e7f3ff;
}

.premium-subscription-activation form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.premium-subscription-activation button {
    padding: 10px 20px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.premium-subscription-activation button:hover {
    background-color: #218838;
}

.premium-subscription-activation p {
    color: black;
    margin-top: 20px;
}


.hight-badgeName-amount{
    background: linear-gradient(90deg, #000940 0%, #a00030 100%);
    border-radius: 60px;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  /* // height: 100vh; */
  background-color: #f3f2ef;
}

.App {
  /* position: relative; */
}

@media (min-width: 1025px) {
  .chat-card {
    width: 35vw;
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.08),
      0px 4px 4px rgba(0, 0, 0, 0.3);
  }
  .card-header {
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  }
  .card-body {
    /* border-top: 1px solid #788fa5; */
    padding: 0.6rem 1rem;
  }

  .chatItemBox-container {
    right: 36vw;
    min-height: 70vh;
    max-height: 75vh;
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.08),
      0px 4px 4px rgba(0, 0, 0, 0.3);
    width: 25rem;
  }
  .conversation-user-avatar {
    width: 3.6rem;
    height: 3.6rem;
  }
  .conversation-user-name {
    font-size: 16px !important;
  }
  .conversation-user-name-container span {
    font-size: 12px !important;
  }
  .conversation-list-item {
    grid-gap: 0.6rem;
    gap: 0.6rem;
  }
}

@media (min-width: 739px) and (max-width: 1024px) {
  .chat-card {
    width: 40vw;
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.08),
      0px 4px 4px rgba(0, 0, 0, 0.3);
  }
  .card-header {
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  }
  .card-body {
    border-top: 1px solid #788fa5;
    padding: 0.6rem 0.2rem;
  }
  .chatItemBox-container {
    right: 41vw;
    min-height: 70vh;
    max-height: 75vh;
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.08),
      0px 4px 4px rgba(0, 0, 0, 0.3);
    width: 25rem;
  }

  .conversation-user-avatar {
    width: 2.6rem;
    height: 2.6rem;
  }

  .conversation-user-name {
    font-size: 14px !important;
  }

  .conversation-user-name-container span {
    font-size: 10px !important;
  }

  .conversation-last-message {
    font-size: 12px !important;
  }

  .search-filter {
    margin-inline: 0.2rem;
  }

  .conversation-list-item {
    grid-gap: 0.4rem;
    gap: 0.4rem;
    margin-left: 0.2rem;
  }
}

@media (min-width: 0px) and (max-width: 738px) {
  .Desktop-App {
    display: none;
  }
  .Mobile-App {
    display: block;
  }
  .icons-container {
    display: none;
    visibility: hidden;
  }
  .chat-card {
    width: 100vw;
    top: 95px;
    box-shadow: none;
  }
  .card-header {
    background: white;
    box-shadow: none;
  }
  .card-body {
    border-top: none;
  }
  .conversation-user-avatar {
    height: 2.6rem;
    width: 2.6rem;
  }
  .conversation-list-item {
    grid-gap: 0.6rem;
    gap: 0.6rem;
  }
  .conversation-user-name {
    font-size: 14px;
  }
  .chatItemBox-container {
    top: 95px;
    width: 100vw;
    height: 100%;
  }
  .chatItemBox--header {
  }
  .chatItemBox--body {
  }
  .chatItemBox--footer {
  }
}


.home-container {
  /* position: relative; */
  /* height: 100vh; */
}
.home-container .user-profile {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.4rem;
  font-weight: bold;
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.home-container .chat-card {
  position: fixed;
  bottom: 0;
  right: 0rem;
  /* width: 30vw; */
  background-color: #fff;
  z-index: 5;
}
.home-container .card-header {
  padding: 1rem 0.6rem;
  display: flex;
  justify-content: space-between;
}
.home-container .card-header .img-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}
.home-container .card-header .img-container .avatar-img {
  border-radius: 50%;
  width: 2.4rem;
  height: 2.4rem;
}
.home-container .card-header .icons-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  /* background-color: green; */
}
.home-container .chatApp-icon {
  cursor: pointer;
}
.home-container .open {
  display: block;
}
.home-container .close {
  display: none;
}
.home-container .card-body {
  /* border-top: 1px solid #788fa5; */
  /* padding: 0.6rem 1rem; */
  /* height:40vh; */
}
.home-container .card-body .search-filter {
  /* padding: 1rem 0; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 3rem;
  gap: 3rem;
  padding: 0.6rem;
  background-color: #eef3f8;
  border-radius: 2px;
}
.home-container .card-body .search-filter div {
  display: flex;
  align-items: center;
  grid-gap: 0.4rem;
  gap: 0.4rem;
}
.home-container .card-body .search-filter div input {
  background: none;
  border: none;
  outline: none;
  flex: 1 1;
}
.home-container .card-body .conversation-list {
  /* max-height: 70vh; */
  margin-top: 1rem;
  min-height: 50vh;
  max-height: 60vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
.home-container .card-body .conversation-list .conversation-list-item {
  padding: 10px 0rem;
  border-bottom: 1px solid #eef3f8;
  cursor: pointer;
  display: flex;
  align-items: center;
  /* gap: 0.6rem; */
  flex: 1 1;
}
.home-container
  .card-body
  .conversation-list
  .conversation-list-item
  .conversation-user-name-details {
  display: flex;
  flex-direction: column;
  /* gap: 0.8rem; */
  flex: 1 1;
  position: relative;
}
.home-container
  .card-body
  .conversation-list
  .conversation-list-item
  .conversation-user-name-container {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

/* .conversation-user-name {
  font-size: 16px !important;
} */

.home-container
  .card-body
  .conversation-list
  .conversation-list-item
  .conversation-user-name-container
  span {
  font-size: 10px;
  color: gray;
}
.home-container
  .card-body
  .conversation-list
  .conversation-list-item
  .conversation-user-avatar {
  /* width: 3.6rem;
  height: 3.6rem; */
  border-radius: 50%;
  object-fit: contain;
}
.home-container
  .card-body
  .conversation-list
  .conversation-list-item
  .conversation-last-message {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  flex: 1 1;
  grid-gap: 1rem;
  gap: 1rem;
}

.conversation-last-message {
  color: "#898989" !important;
}
.home-container
  .card-body
  .conversation-list
  .conversation-list-item
  .conversation-last-message
  span:nth-child(2) {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 10rem;
  flex: 1 1;
}
.home-container
  .card-body
  .conversation-list
  .conversation-list-item
  .conversation-last-message
  span:last-child {
  /* margin-left: auto; */
}
.unseen-count {
  margin-left: auto;
  background: brown;
  color: #fff !important;
  border-radius: 50%;
  padding: 2px 3px;
  font-weight: 700;
  position: absolute;
  bottom: 0;
  right: 9px;
  text-align: center;
  min-width: 20px;
  z-index: 100;
}

.message-check {
  display: inline-block;
  transform: rotate(45deg);
  height: 18px;
  width: 9px;
}

.message-seen {
  border-bottom: 2px solid #a00030;
  border-right: 3px solid #a00030;
}
.message-deliver {
  border-bottom: 2px solid gray;
  border-right: 3px solid gray;
}
.message-status {
  position: absolute;
  right: 19px;
}

.notification-puls {
  animation: puls 2s ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes puls {
  40% {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.3, 1.3, 1.3);
  }

  55% {
    transform: scale3d(1, 1, 1);
  }

  60% {
    transform: scale3d(1.3, 1.3, 1.3);
  }

  65% {
    transform: scale3d(1, 1, 1);
  }
}

.rounded-notification {
  margin-bottom: 21px;
  background: brown;
  border-radius: 50%;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  min-width: 19px;
  height: auto;
  text-align: center;
  padding: 1px 4px;
}

.chatItemBox-container {
  position: fixed;
  bottom: 0;
  /* right: 31vw; */
  /* background-color: white; */
  background-color: #fff;
 
  padding: 1rem 0.6rem 0 0.6rem;
  z-index: 999;
  border-radius: 5px;
  /* max-height: 60vh; */
  display: flex;
  flex-direction: column;

}
.chatItemBox-container .img {
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 50%;
}
.chatItemBox-container .chatItemBox--header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1rem;
  border-bottom: 1px solid lightgrey;
  max-height: 20%;
}
.chatItemBox-container .chatItemBox--header .chatItemBox-img-container {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}
.chatItemBox-container .chatItemBox--header .chatItemBox-icons-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 2rem;
  gap: 2rem;
}
.chatItemBox-container .chatItemBox--header .chatItemBox-icon {
  cursor: pointer;
}
.chatItemBox-container .chatItemBox--body {
  padding: 0.4rem 0 1rem 0rem;
  max-height: 50%;
  overflow-y: scroll;
  flex-grow: 1;
  overflow-x: hidden;
  word-break: break-word;
}
.chatItemBox-container .chatItemBox--body .chatItemBox--body-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  padding: 0.5rem 0;
  border-bottom: 1px solid gray;
}

.chatItemBox--body-header-name {
  font-size: medium !important;
}

.chatItemBox-container .chatItemBox--body .chatItemBox-message-list {
  max-height: 60%;
}

.chatItemBox-container .chatItemBox--body .chatItemBox-message-item {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  grid-gap: 1rem;
  gap: 1rem;
  padding: 1rem 0;
}

.chatItemBox-message-item-user{
    display: flex;
    flex: 1 1;
    flex-direction: column;
}

.chatItemBox-container
  .chatItemBox--body
  .chatItemBox-message-item
  .chatItemBox--body-sender-name {
  /* padding-bottom: 0.6rem; */
  font-size: medium !important;
  display: flex;
  justify-content: space-between;
  align-items:baseline;
}
.chatItemBox-container
  .chatItemBox--body
  .chatItemBox-message-item
  .chatItemBox--body-sender-message {
  word-wrap: break-word;
}
.chatItemBox-container
  .chatItemBox--body
  .chatItemBox-message-item
  .chatItemBox--body-sender-name
  span {
  margin-left: 2rem;
  font-size: 13px;
  color: gray;

}
.chatItemBox-container .chatItemBox--footer {
  border-top: 1px solid lightgray;
  max-height: 30%;
}
.chatItemBox-container .chatItemBox--footer .chatItemBox--footer-text-box {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  /* height: 3rem; */
  padding-top: 0.8rem;
  width: 100%;
}
.chatItemBox-container
  .chatItemBox--footer
  .chatItemBox--footer-text-box
  textarea {
  border: none;
  outline: none;
  flex-grow: 1;
  resize: none;
}
.chatItemBox-container .chatItemBox--footer .chatItemBox--footer-send-box {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid lightgray;
  padding: 1rem 0;
}
.chatItemBox-container
  .chatItemBox--footer
  .chatItemBox--footer-emoji-container {
  display: flex;
  justify-content: flex-start;
  grid-gap: 1rem;
  gap: 1rem;
}
.chatItemBox-container .chatItemBox--footer .chatItemBox--footer-send-button {
  display: flex;
  justify-content: flex-start;
  grid-gap: 2rem;
  gap: 2rem;
  align-items: center;
}
.chatItemBox-container
  .chatItemBox--footer
  .chatItemBox--footer-send-button
  button {
  padding: 0.4rem 0.8rem;
  border-radius: 10rem;
  background-color: blue;
  color: white;
  border: none;
  outline: none;
}
.chatItemBox-container
  .chatItemBox--footer
  .chatItemBox--footer-send-button
  button:disabled {
  background-color: lightgray;
  color: #5e5e5e;
  cursor: not-allowed;
}
.chatItemBox-container .emojiContainer {
  position: absolute;
  right: 0vw;
  bottom: 30%;
}
.chatItemBox-container .empty-conversation {
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-content: center;
  font-size: 24px;
  font-weight: bold;
}

.chatItemBox--body-sender-name {
  font-size: medium !important;
}

