.navigationsMenu-mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
}
.navigationsMenu-navbar {
  /* position: relative;  
    display: flex; */
  -webkit-box-align: center;
  align-items: center;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}

.navigationsMenu-nav {
  /* display: block; */
}

@media (min-width: 992px) {
  .navbar-expand-lg .navigationsMenu-offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    -webkit-box-flex: 1;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
}

.navigationsMenu-offcanvas-start {
  top: 0;
  left: 0;
  width: 300px;
}
.navigationsMenu-offcanvas {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  max-width: 100%;
  /* background-color: #fff; */
  background-clip: padding-box;
  outline: 0;
}

@media (min-width: 992px) {
  .navbar-expand-lg .navigationsMenu-offcanvas-header {
    display: none;
  }
}

.navigationsMenu-offcanvas-header {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 1rem 1.5rem;
}

@media (min-width: 992px) {
  .navbar-expand-lg .navigationsMenu-offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    -webkit-transition: none;
    transition: none;
    -webkit-transform: none;
    transform: none;
  }
}

/* .navigationsMenu-offcanvas {
    position: fixed;
    bottom: 0;
    z-index: 1045;

    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    max-width: 100%;
    visibility: hidden;
    background-color: #fff;
    background-clip: padding-box;
    outline: 0;
} */
.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}
.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.navigationsMenu-offcanvas-header .btn-close {
  padding: 0.5rem 0.75rem;
  margin-top: -0.5rem;
  margin-right: -0.75rem;
  margin-bottom: -0.5rem;
}
button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}
.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}
.ms-auto {
  margin-left: auto !important;
}
.btn-close {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  border: 0;
  border-radius: 0.4rem;
  opacity: 0.5;
}
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button,
select {
  text-transform: none;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

@media (min-width: 992px) {
  .navbar-expand-lg .navigationsMenu-offcanvas-body {
    -webkit-box-flex: 0;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navigationsMenu-px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}
.navigationsMenu-px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}
.navigationsMenu-d-block {
  display: block !important;
}

div {
  display: block;
}
@media (min-width: 992px) {
  .navbar-expand-lg .navigationsMenu-offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    -webkit-box-flex: 1;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
}
/* .navigationsMenu-offcanvas {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    max-width: 100%;
    background-color: #fff;
    background-clip: padding-box;
    outline: 0;
  } */

.navigationsMenu-card.navigationsMenu-overflow-hidden {
  will-change: transform;
}
.overflow-hidden {
  overflow: hidden !important;
}
.navigationsMenu-card {
  position: relative;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.09);
  border-radius: 0.4rem;
}

.h-50px {
  height: 100px !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.navigationsMenu-card-body {
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  /* padding: 1.25rem 1.25rem; */
}

.text-center {
  text-align: center !important;
}

.avatar-lg {
  height: 4rem;
  width: 4rem;
}
.avatar {
  position: relative;
  display: inline-block;
  flex-shrink: 0 !important;
}
.mt-n5 {
  margin-top: -3rem !important;
}
.mb-3 {
  margin-bottom: 1rem !important;
}

a {
  transition: all 0.3s ease-in-out;
}

a {
  color: #0f6fec;
  text-decoration: none;
}

.avatar-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.rounded {
  border-radius: 0.4rem !important;
}
.border-3 {
  border-width: 3px !important;
}
.border-white {
  border-color: #fff !important;
}
.border {
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
}
img {
  max-width: 100%;
}
img,
svg {
  vertical-align: middle;
}

.mb-0 {
  margin-bottom: 0 !important;
}

h5,
.h5 {
  font-size: 1.171875rem;
}
.navigationsMenu-h6 {
  margin-top: 0;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  line-height: 1.25;
  color: #14191e;
}

small,
.small {
  font-weight: 400;
}

small,
.small {
  font-size: 0.875em;
}

.mt-3 {
  margin-top: 1rem !important;
}

.gap-xl-3 {
  gap: 1rem !important;
}
.navigationsMenu-justify-content-center {
  -webkit-box-pack: center !important;
  justify-content: center !important;
}
.gap-2 {
  /* gap: 0.5rem !important; */
}
.hstack {
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  align-self: stretch;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.navigationsMenu-h6,
.h6 {
  font-size: 0.9375rem;
}
.navigationsMenu-h6 {
  margin-top: 0;
  font-weight: 700;
  line-height: 1.25;
  color: #14191e;
}

.navigationsMenu-h6 {
  display: block;
  font-size: 0.67em;
  margin-block-start: 2.33em;
  margin-block-end: 2.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentColor;
  opacity: 0.25;
}

.fw-bold {
  font-weight: 700 !important;
}
.gap-2 {
  gap: 0.5rem !important;
}
.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  flex-direction: column !important;
}
.navigationsMenu-nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
ol,
ul,
dl {
  margin-top: 0;
}
ol,
ul {
  padding-left: 2rem;
}

ul {
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

.nav.flex-column:not(.nav-pills) .nav-link {
  padding-left: 0;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}
.nav-link-secondary .nav-item .nav-link {
  color: #14191e;
}
.nav-link {
  /* display: block; */
  display: flex;
  padding: 0.25rem 0.75rem;
  color: #676a79;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.navigationsMenu-card-footer:last-child {
  border-radius: 0 0 calc(0.4rem - 1px) calc(0.4rem - 1px);
}
.text-center {
  text-align: center !important;
}
.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.navigationsMenu-card-footer {
  padding: 1.25rem 1.25rem;
  background-color: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.09);
}

.btn {
  outline: 0;
}
.btn-link {
  padding: 0;
}
.btn-sm,
.btn-group-sm > .btn {
  padding: 0.4rem 0.8rem;
  font-size: 0.8125rem;
  border-radius: 0.2rem;
}
.btn-link {
  font-weight: 500;
  color: #0f6fec;
  text-decoration: none;
}

.lh-1 {
  line-height: 1 !important;
}
.mt-4 {
  margin-top: 1.5rem !important;
}
.navigationsMenu-justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}
.navigationsMenu-nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  /* display: block !important; */
  display: flex;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
  color: #000000;
}

.nav-link-body-sideMenu {
  margin-bottom: 0.5rem !important;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #676a79 !important;
}

.nav-link-body-sideMenu:hover {
  /* margin-bottom: 0.5rem !important; */
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #676a79 !important;
}

.nav-link-body-sideMenu-active {
  margin-bottom: 0.5rem !important;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #a00030 !important;
}

.nav-link-body-sideMenu-active:hover {
  /* margin-bottom: 0.5rem !important; */
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #a00030 !important;
}

.refer-wrapper {
  display: flex;
  justify-content: center;
}

.cta {
  display: flex;
  padding: 6px 9px;
  background: thistle;
  transition: 1s;
  box-shadow: 6px 6px 0 black;
  transform: skewX(-15deg);
  color: black !important;
  cursor: pointer;
  font-size: 11px;
  font-weight: 600;
}

.cta:focus {
  outline: none;
}

.cta:hover {
  transition: 0.5s;
  box-shadow: 8px 8px 0 #fbc638;
  color: black !important;
}

.cta span:nth-child(2) {
  transition: 0.5s;
  margin-right: 0px;
}

.cta:hover span:nth-child(2) {
  transition: 0.5s;
  margin-right: 15px;
}

.cta span {
  transform: skewX(15deg);
}

.cta > span:nth-child(2) {
  width: 20px;
  margin-left: 11px;
  position: relative;
  /* top: 12%; */
}

/**************SVG****************/

path.one {
  transition: 0.4s;
  transform: translateX(-60%);
}

path.two {
  transition: 0.5s;
  transform: translateX(-30%);
}

.cta:hover path.three {
  animation: color_anim 1s infinite 0.2s;
}

.cta:hover path.one {
  transform: translateX(0%);
  animation: color_anim 1s infinite 0.6s;
}

.cta:hover path.two {
  transform: translateX(0%);
  animation: color_anim 1s infinite 0.4s;
}

/* SVG animations */

@keyframes color_anim {
  0% {
    fill: white;
  }
  50% {
    fill: #000;
  }
  100% {
    fill: white;
  }
}

.ask-help-wrap {
  display: flex;
  background: white;
  border-radius: 5px;
  flex-wrap: wrap;
  margin-top: 1rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.ask-help-wrap img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.ask-help-wrap > section:nth-child(1) {
  height: 27rem;
  width: 26rem;
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.ask-help-wrap > section:nth-child(2) {
  flex: 1;
  background: #a52a2a63;
  border-bottom-left-radius: 29%;
  border-top-right-radius: 5px;
  position: relative;
  border-bottom-right-radius: 5px;
  padding: 2rem;
}
.ask-help-wrap h5 {
  font-weight: bold;
  margin-top: 2rem;
  text-transform: capitalize;
  font-size: 22px;
  margin-bottom: 0;
}
.ask-help-d1 {
  background: black;
  width: 1.5rem;
  height: 13rem;
  position: absolute;
  top: 0;
  right: 0;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 2.5rem;
}
.ask-help-d2 {
  background: black;
  width: 1.5rem;
  height: 13rem;
  position: absolute;
  top: 30%;
  left: 0;
  border-top-right-radius: 3rem;
  border-bottom-right-radius: 2.5rem;
}
.ask-help-wrap span {
  color: blue;
  font-size: 12px;
  padding-bottom: 1rem;
  display: block;
  margin-top: 6px;
}
.nav-new-menus > li:hover {
  background: #a52a2a0f;
  border-radius: 5px;
}
.nav-new-menus > li span {
  font-weight: 600;
}
.calendar-op-menu {
  list-style: none;
  background: gainsboro;
  width: fit-content;
  padding: 1rem;
  margin-left: 1rem;
  border-radius: 6px;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  gap: 1rem;
  height: fit-content;
}
.date-picker {
  border-radius: 5px;
  width: 6.5rem;
  padding: 12px;
  text-align: center;
  border: 1px solid gray;
}

.g-2 {
  gap: 2rem;
}
.g-1 {
  gap: 1rem;
}
.g-3 {
  gap: 3rem;
}
.calendar-multi {
  z-index: 0 !important;
}
.l-none {
  list-style: none;
  padding: 0;
}

.slot-card {
  font-size: 13px;
  border: 1px solid gainsboro;
  padding: 1rem;
  border-radius: 5px;
  flex: 1;
  overflow: hidden;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
    rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
}
.share-btn {
  flex-basis: 100%;
  background: linear-gradient(90deg, #000940 0%, #a00030 100%);
  color: white;
  overflow: hidden;
  padding: 12px;
  font-weight: bold;
  cursor: pointer;
}
.f-12 {
  font-size: 12px;
}
.f-14 {
  font-size: 14px;
}
.card-shadow {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.t-box {
  border: 1px solid gainsboro;
  border-radius: 6px;
  padding: 5px;
  font-size: 12px;
  overflow: hidden;
  height: fit-content;
  text-align: center;
}
.calender-share {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  border-radius: 6px;
  background: #778899f7;
  width: 100%;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  animation: share-eventwrap 0.5s;
}
@keyframes share-eventwrap {
  from {
    transform: scale(0.5);
  }
  to {
    transform: scale(1);
  }
}
.c-pointer {
  cursor: pointer;
}
/* App.css */
.cal-grid {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(17rem, 1fr));
  place-items: center;
}
.s-box {
  border: 1px solid gray;
  padding: 7px;
  border-radius: 9px;
  display: flex;
  height: 2rem;
  place-content: center;
}
.event-hide-wrap {
  background: lightslategray;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  animation: eventwrap 0.5s;
}

@keyframes eventwrap {
  from {
    transform: scale(2);
  }
  to {
    transform: scale(1);
  }
}
.user-cal-btn {
  background: #0000ff6e !important;
  border: none !important;
  color: black;
}
.box-dis {
  display: flex;
  width: 7rem;
  text-align: center;
  background: gainsboro;
  flex-shrink: 0;
  height: 3rem;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.calendar-multi {
  --rmdp-font-size: 1.2em; /* Increase the font size */
  --rmdp-width: auto; /* Adjust width as needed */
}

.calendar-multi .rmdp-wrapper {
  width: var(--rmdp-width);
  height: auto;
}

.calendar-multi .rmdp-container {
  font-size: var(--rmdp-font-size);
  padding: 10px; /* Adjust padding if needed */
}

.calendar-multi .rmdp-calendar {
  max-width: var(--rmdp-width);
}

.calendar-multi .rmdp-day-picker {
  padding: 10px; /* Adjust padding if needed */
  display: flex;
}

.calendar-multi .rmdp-day {
  width: calc(100% / 7); /* Ensure each day cell adjusts to the new width */
}

.calendar-multi .rmdp-day span {
  font-size: var(--rmdp-font-size);
  font-weight: 600;
  margin: -3px -1px;
}

.calendar-multi .rmdp-week {
  gap: 2rem;
}

.calendar-multi .rmdp-week-day {
  font-weight: bold;
  margin-left: 8px;
}
.calendar-multi .rmdp-week {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.calendar-multi .rmdp-header-values {
  font-weight: bold;
  font-size: 1.5em;
}
@media (min-width: 0px) and (max-width: 760px) {
  .calendar-multi .rmdp-day-picker {
    padding: 0;
  }
  .calendar-multi .rmdp-week {
    gap: 0;
  }
}
.d-slot-index {
  z-index: 0 !important;
}
