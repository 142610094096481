span.closeBtn {
  right: 0 !important;
  position: absolute !important;
  margin-right: 30px !important;
}

.userImgPost img {
  float: left !important;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  box-shadow: 0px 0px 5px #00000029;
  border: 3px solid #ffffff;
}
