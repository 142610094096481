/* ============= 992px to 1200px ============= */

@media (min-width: 992px) and (max-width: 1200px) {
  .recentSearchToolbar {
    width: 96.5%;
  }
}

/* =============  992 to 1240 ============= */

@media (min-width: 992px) and (max-width: 1240px) {
  /* =========== CALL SCREEN ========== */
  .UCCHeader {
    padding: 0 15px;
  }
  .UCCScheduleTiming {
    padding: 0 15px;
  }
  p.UCCUserName {
    padding: 0 15px 15px;
    font-size: 12px;
    min-height: 40px;
  }
  .FACBtnWrapper button {
    font-size: 10px !important;
  }
  p.UCCSessionName {
    font-size: 16px;
  }
}

/* ============= 0 to 992 ============= */

@media (min-width: 0px) and (max-width: 992px) {
  /* =========== TAB MAIN ========= */
  .meeting-box {
    flex-wrap: wrap;
  }
  .meeting-copy {
    flex-wrap: wrap;
    justify-content: center;
  }
  .mentorCard {
    background: #fff;
    width: 18em !important;
    border-radius: 0.6em;
    margin: 2.2em;
    overflow: hidden;
    cursor: pointer;
    box-shadow: 0 13px 27px -5px hsl(240deg 30% 28% / 25%),
      0 8px 16px -8px hsl(0deg 0% 0% / 30%),
      0 -6px 16px -6px hsl(0deg 0% 0% / 3%);
    transition: all ease 200ms;
  }

  .postCardImage {
    /* height: 18rem; */
  }

  .postCardImageSingle {
    height: auto;
  }

  .upcomingCardWrapper {
    border: 1px solid #f7f7f7;
    background: #ffffff;
    border-radius: 4px;
    padding: 16px 16px 16px;
    cursor: pointer;
    width: auto;
    margin-right: 20px;
    box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.05);
  }
  .listCardWrapperLeader {
    margin-bottom: 20px;
    width: 290px;
    height: 28rem;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .listCardWrapperLeaderHeader {
    margin-bottom: 20px;
    width: 290px;
    height: 35rem;
  }
  .tabWrapper .MuiTab-textColorPrimary {
    margin-right: 30px;
  }
  .askQuestions {
    padding: 15px 25px 18px 0px !important;
  }
  .tabWrapper .MuiTabs-root {
    padding: 15px 0px 18px 25px;
  }
  /* ============ ANSWER SCREEN ========== */
  .QCQuestionInfoBanner {
    /* display: block; */
    padding: 7px 18px;
  }
  .QCTagsPostedOn {
    display: block;
  }
  .QCQuestion {
    font-size: 18px;
  }
  .answerCountUserImgWrapper {
    display: block;
  }
  .answeredUserImg div:nth-child(1) {
    margin: auto;
  }
  .ansCountWrapper {
    margin-bottom: 10px;
  }
  .answersWrapper .answeCountArrowGrid {
    text-align: center !important;
  }
  /* =========== CALL SCREEN ========== */
  .UCCHeader {
    padding: 0 15px;
  }
  .UCCScheduleTiming {
    padding: 0 15px;
  }
  p.UCCUserName {
    padding: 0 15px 15px;
  }
  .FACBtnWrapper button {
    font-size: 10px !important;
  }
}

@media (min-width: 600px) and (max-width: 800px) {
  .navMenuInnerGap {
    margin-left: 60px !important;
  }
}

/* ============= 0 to 768 ============= */

@media (min-width: 0) and (max-width: 768px) {
  /* ========= DEFAULT ======== */
  .carouselv2-container {
    width: 100%;
  }
  .prevv2 {
    left: 0rem !important;
  }
  .nextv2 {
    right: 0rem !important;
  }
  .s-min-50 {
    min-width: 100% !important;
  }
  .cpt-0 {
    padding-top: 0 !important;
  }
  .flex-col-reverse {
    flex-direction: column-reverse;
  }
  .enquiry-wrap {
    flex-wrap: wrap;
  }
  .service-img-grid {
    gap: 0 !important;
  }
  .service-img-grid {
    flex-wrap: wrap;
  }
  .s-heading {
    font-size: 2rem !important;
  }
  .service-otp > input {
    border: 1px solid black;
    color: black;
    width: 2rem !important;
    padding: 0;
    height: 2rem;
  }
  .service-wis {
    flex-direction: column;
    padding: 0;
  }
  .service-info-wrap {
    flex-wrap: wrap;
  }
  .booking-grid {
    grid-template-columns: repeat(auto-fill, minmax(22rem, 1fr)) !important;
  }
  .cal-grid {
    grid-column: 1fr;
  }
  .calendar-image {
    margin-left: 0rem !important;
  }
  .cal-res-1 {
    padding-left: 0rem;
  }
  .login-option-wrap {
    padding: 11px;
  }
  .login-options-opt {
    width: 100%;
    padding: 39px 12px !important;
    margin-top: 1rem !important;
  }

  .login-options-btn {
    padding: 8px 14px !important;
    align-items: center !important;
    text-align: center;
    justify-content: center;
    font-size: 13px;
  }

  .headerDynamicLogo {
    height: 57px;
    width: 198px;
  }
  .nav-menu-new > *:nth-child(2) {
    margin-top: 0rem;
    padding: 1rem;
  }
  .nav-menu-new {
    flex-direction: column;
  }
  .selfAssessment-form {
    background: #f8f9fa;
    padding: 5px;
    border-radius: 5px;
  }
  .kyc-background {
    width: auto;
  }
  .article-wrap {
    margin-top: 4rem;
    margin-left: 0;
    overflow-wrap: break-word;
  }
  .grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1rem;
  }

  .workspace-button {
    position: absolute;
    right: 27px;
    top: 115px;
    z-index: 100;
  }
  .dropdownshareCard {
    left: inherit;
  }
  .req-un-active-menu {
    width: 7.3rem;
    border-radius: 0px !important;
    background: white;
    color: black;
    border-color: gray;
  }
  .req-active-menu {
    width: 7.3rem;
    border-radius: 0px !important;
    background: #d3d3d378;
    color: #a00030;
    border-color: gray;
  }

  .videopostcontainer .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0px;
    right: 0;
    height: 100%;
    width: 100%;
    /* opacity: 0; */
    transition: 0.3s ease;
    background-color: transparent;
    opacity: 1;
  }
  .headerDynamicLogo-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: 50px;
    width: auto;
    padding: 14px;
  }
  .unAccess-page {
    margin-top: 90px;
    margin-left: 0;
  }
  .access-page {
    margin-top: 90px;
    margin-left: 0;
  }
  .option-profile {
    border: 0.1rem solid gray;
    padding: 8px;
    border-radius: 9px;
    margin-left: 1rem;
  }
  .option-profile1 {
    border: 1px solid gray;
    padding: 7px 7px;
    border-radius: 7px;
    margin-left: 1rem;
  }
  .workspace-card {
    margin-right: 0rem;
    margin-top: 6rem;
    display: flex;
    flex-wrap: wrap;
    padding: 25px;
    background: white;
    border-radius: 10px;
    box-shadow: rgb(50 50 93 / 25%) 0px 13px 27px -5px,
      rgb(0 0 0 / 30%) 0px 8px 16px -8px;
  }
  .adb-card {
    display: block;
    flex-wrap: wrap;
  }
  .img-grid-chat {
    width: 100%;
    height: 400px;
    background-size: 100% 100%;
    opacity: 0.9;
    box-shadow: 0px -5px 18px 0px rgb(0 0 0 / 38%);
    margin-bottom: 10px;
  }
  .main__chatcontent {
    flex-grow: 1;
    padding: 10px;
    max-width: 100%;
    border: 1px solid #ebe7fb;
    border-width: 1px;
    border-radius: 19px;
  }
  .containerSlide {
    /* padding: 37px; */
    padding: 4px 34px 6px 34px;
    height: 19rem;
    overflow: scroll;
    overflow-x: hidden;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;
    border: 1px solid #dededf;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 4px;
  }
  .tag-List {
    padding: 6px;
    color: gray;
    font-size: 14px !important;
    height: 6.7rem !important;
    overflow: scroll;
    overflow-x: hidden;
    text-align: center;
  }
  .forum-tags-badges {
    display: contents !important;
    overflow: hidden !important;
  }
  .forum-tags-badges:hover {
    display: flex;
    flex-direction: unset !important;
    overflow: initial;
  }
  p.yourAnswerClear {
    font-size: 18px;
    color: blue;
    font-weight: 500;
    margin: 37px 0 5px !important;
    cursor: pointer;
  }
  .QCFooter {
    padding: 5px 0px 0px 12px !important;
  }
  .QCQuestionInfoBanner p {
    margin-right: 7px !important;
  }
  .mentorCard {
    background: #fff;
    width: 20em !important;
    border-radius: 0.6em;
    margin: 2.2em;
    overflow: hidden;
    cursor: pointer;
    box-shadow: 0 13px 27px -5px hsl(240deg 30% 28% / 25%),
      0 8px 16px -8px hsl(0deg 0% 0% / 30%),
      0 -6px 16px -6px hsl(0deg 0% 0% / 3%);
    transition: all ease 200ms;
  }
  h3.listHomeTitle {
    font-size: 21px;
    letter-spacing: 0px;
    color: #333333;
    font-weight: bold;
    padding: 10px 5px 5px 0px;
  }
  .postCardImg-me-2 {
    margin-right: 0.5rem !important;
    /* margin-bottom: 3rem !important; */
    /* margin-bottom: 1rem; */
  }
  .groupCard-nav {
    display: inline-flex !important;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
  .groupCard {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.09);
    border-radius: 0.4rem;
    padding: 2px;
  }
  .groupPost-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.09);
    border-radius: 0.4rem;
    /* width: 21rem  !important; */
    padding: 2px;
  }
  .JRWrapper {
    padding: 10px 12px 10px;
    /* width: 21rem  !important; */
    padding: 2px;
    background: white !important;
  }
  .about-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.09);
    border-radius: 0.4rem;
    /* width: 21rem; */
  }
  .kycContainerInside {
    position: absolute;
    margin-top: 52%;
    margin-left: 14%;
    justify-content: center !important;
    align-items: center;
    background: #ffffff;
    box-shadow: 5px 5px 6px #0000000d;
    border: 1px solid #dededfbf;
    padding: 44px 35px 35px;
    border-radius: 10px;
  }
  .kycContainerInsideLoading {
    position: absolute;
    margin-top: 52%;
    margin-left: 35%;
    justify-content: center !important;
    align-items: center;
    background: #ffffff;
    box-shadow: 5px 5px 6px #0000000d;
    border: 1px solid #dededfbf;
    padding: 44px 35px 35px;
    border-radius: 10px;
    width: 50%;
  }
  .naviGationPadding {
    padding: 15px 0px 7px 3px;
    position: "relative";
    background: "#f5f5f5";
  }
  .navMenuInnerGap {
    margin-left: 1px;
  }
  .navMenuInnerGapNotHome {
    margin-left: 1px;
  }
  .notificationWrapper {
    position: absolute;
    top: 95px !important;
    z-index: 2000 !important;
    width: 292px !important;
    max-height: 520px;
    right: 12px !important;
    background: #fff;
    box-shadow: 0px 1px 1px #00000029;
    border-radius: 4px;
  }
  .profileselectCard {
    background: #ffffff;
    border-radius: 10px;
    margin-top: 10px;
    height: auto;
    width: auto;
    align-items: center;
    padding: 20px 15px;
    margin-bottom: 6px;
    box-shadow: 0px 1px 3px #00000029;
    border: 1px solid #f7f7f7;
    /* border-radius: 3px; */
  }
  .BACDuration {
    border: 1px solid #dededf;
    border-radius: 12px;
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 9px;
    font-weight: 500;
    color: black;
    cursor: pointer;
    margin-right: 10px;
    padding: 6px;
  }
  .profileselectCardMobile {
    background: #ffffff;
    /* border-radius: 10px; */
    margin-top: 10px;
    height: 600px;
    width: auto;
    align-items: center;
    padding: 20px 15px;
    margin-bottom: 6px;
    box-shadow: 0px 1px 3px #00000029;
    border: 1px solid #f7f7f7;
    border-radius: 3px;
    overflow-y: scroll;
  }
  .profileselectCardEducation {
    background: #ffffff;
    /* border-radius: 10px; */
    margin-top: 10px;
    height: 600px;
    width: auto;
    align-items: center;
    padding: 20px 15px;
    margin-bottom: 6px;
    box-shadow: 0px 1px 3px #00000029;
    border: 1px solid #f7f7f7;
    border-radius: 3px;
  }
  .profileselectCardExpMobile {
    background: #ffffff;
    /* border-radius: 10px; */
    margin-top: 10px;
    height: 700px;
    width: auto;
    align-items: center;
    padding: 20px 15px;
    margin-bottom: 6px;
    box-shadow: 0px 1px 3px #00000029;
    border: 1px solid #f7f7f7;
    border-radius: 3px;
    overflow-y: scroll;
  }
  .valueselectCard {
    background: #ffffff;
    /* border-radius: 10px; */
    margin-top: 10px;
    height: 800px;
    width: auto;
    align-items: center;
    padding: 20px 15px;
    margin-bottom: 6px;
    box-shadow: 0px 1px 3px #00000029;
    border: 1px solid #f7f7f7;
    border-radius: 3px;
  }
  .profileselectCardFirstTime {
    background: #ffffff;
    /* border-radius: 10px; */
    margin-top: 10px;
    height: auto;
    width: auto;
    align-items: center;
    padding: 20px 15px;
    margin-bottom: 6px;
    box-shadow: 0px 1px 3px #00000029;
    border: 1px solid #f7f7f7;
    border-radius: 3px;
  }

  .professionCard {
    background: #ffffff 0% 0% no-repeat padding-box;
    padding: 20px 15px;
    margin: 6px;
    box-shadow: 0px 1px 3px #00000029;
    border: 1px solid #f7f7f7;
    border-radius: 2px;
    height: auto;
    /* padding: 22px; */
  }
  .commentViewAll {
    /* float:"right" !important; */
    cursor: pointer !important;
    font-size: 12px;
    margin-right: -12px !important;
  }
  .commentInput input {
    width: 142% !important;
    margin: 0;
    margin-left: -18px !important;
    background: #f4f2f2;
    border-radius: 4px !important;
    border: none;
    height: 56px;
    padding: 0px 0px 1px 7px !important;
    font-size: 12px !important;
  }

  .educationContainerEdit {
    background: #ffffff 0% 0% no-repeat padding-box;
    /* padding: 22px; */

    padding: 20px 15px;
    margin: 6px;
    box-shadow: 0px 1px 3px #00000029;
    border: 1px solid #f7f7f7;
    border-radius: 2px;
    height: auto;
  }
  .player {
    /*width: auto !important;*/
    width: auto !important;
    height: 250px !important;
    max-height: 100% !important;
  }
  .dBlockMblTab {
    display: block;
  }
  .navigationWrapper {
    padding: 0;
  }
  .MuiDialog-root {
    width: 100% !important;
  }
  /* ================ HOME > PROFILE  ============== */
  .profilePictureEditProfile {
    padding: 0 25px;
    display: block;
  }
  .profilePictureFriend {
    padding: 0 25px;
    display: block;
  }
  .profieBannerWrapper {
    height: 380px;
  }
  .profieBannerWrapper > img {
    height: 380px;
  }
  .friendprofieBannerWrapper {
    height: 380px;
  }
  .friendprofieBannerWrapper > img {
    height: 380px;
  }
  .profilePictureEditProfile > div:nth-child(1) {
    margin: auto;
    top: 15px;
    position: relative;
  }
  .profilePictureFriend > div:nth-child(1) {
    margin: auto;
    top: 15px;
    position: relative;
  }
  .editProfileButton button {
    margin: auto;
    margin-top: 40px;
  }
  .userDetailsWrapper {
    padding: 15px 25px;
  }
  /* ================= ANSWER SCREEN =========== */
  .answersWrapper {
    padding: 20px 25px;
  }
  .QCFooter {
    display: block;
  }
  .shareFollowBtn {
    margin-bottom: 15px;
  }
  .ACFooter {
    display: block;
  }
  /* ============ COMMUNITY > ASK QUESTIONS ===========*/
  .askQuestionWrapper {
    /*padding: 25px;*/
    padding: 5px;
  }
  .askQuestions {
    font-size: 12px;
  }
  /* ============= PROFILE =========== */
  .profileInfoWrapper {
    padding: 10px;
  }
  .settingsCheckBox {
    margin-top: 5px !important;
    width: 25px !important;
  }
  .settingsText {
    padding-left: 5px;
  }
  .pageWrapper {
    padding: 20px 15px;
  }

  .pageWrapperProfileSetup {
    padding: 20px 15px;
    background: white !important;
  }
  .contactpageWrapper {
    padding: 20px 15px;
  }

  /* ============= CHAT ============= */
  .messageHeaderBackBtn {
    margin-right: 10px;
    cursor: pointer;
  }
  .messageHeaderBackBtn svg {
    font-size: 21px;
  }
  .messageTyping {
    max-width: 50%;
  }
  .videoArticleWrapper {
    padding: 0px;
  }
  .feedbackBox {
    width: 100%;
  }
}

/* ============ 0 to 595 ============ */

@media (min-width: 0) and (max-width: 595px) {
  .headerUserName {
    display: none;
    margin-left: 5px;
    margin-right: 5px;
    font-size: 12px;
  }
  /* ========= DEFAULT ======== */
  .dBlockMbl {
    display: block;
  }
  /* =========== HEADER =========== */
  .headerUserProfile {
    margin-left: 0px;
  }
  .notifictionMainIconWrapper {
    margin-left: 5px !important;
  }
  .newMessageIconWrapper {
    /* display: block; */
    width: 32px;
  }
  .newMessageIconWrapper .headerIcon p {
    background: #1ab4de;
  }
  .logoDark img {
    width: 100px;
    height: 50px;
    object-fit: contain;
  }
  /* ================ NAVGATION ============= */
  .peopleMainWrapper {
    padding: 25px;
  }
  .navigationWrapper {
    display: contents;
  }
  .navigationWrapper a {
    margin-bottom: 10px;
  }
  .navCalls a {
    float: inherit !important;
    /* margin-right: 170px !important; */
  }
  /*  ============= SEARCH AUTOCOMPLELTE ============= */
  .searchWrapper {
    padding: 0;
  }
  /* =================== HOME > AFTER SERACH =========== */
  .SCardWrapper div:nth-child(1) {
    margin: auto;
  }
  .SCardWrapper {
    text-align: center;
    padding: 0 0 20px;
  }
  p.SCmutualFriend {
    margin-bottom: 15px;
  }
  .SAWrapper {
    margin-top: 10px;
  }
  /* =================== HOME > SEARCH =========== */
  .peopleCarouselWrapper {
    padding: 0;
  }
  .PIWrapper {
    padding: 0;
  }
  /* ============== HOME > WALL ============== */
  h2.CCTitle {
    display: block;
    font-size: 14px;
  }
  h2.CCTitle .likedIcon {
    display: inline-flex;
  }
  .upvotesTitle {
    display: block;
    margin-right: 0;
  }
  .upvotesCount {
    margin-right: 0;
    width: 72px;
    margin-bottom: 15px;
  }
  .likedInfo {
    margin-top: 5px;
    margin-left: 0;
  }
  .askedTagInfoWrapper .askedonTagWrapper {
    margin-right: auto;
    margin-bottom: 24px;
    margin-left: 0;
  }
  .communityCardQuestion {
    display: block;
    background-color: #f5f5f5 0 0 no-repeat padding-box !important;
  }
  .communityCardUserImg {
    margin-right: 0;
  }
  .communityCardUserImg div:nth-child(1) {
    margin-bottom: 15px;
  }
  .upvotesQuestion p {
    font-size: 14px;
  }
  .wallMainWrapper {
    padding: 0px;
  }
  .likeCommentWrapper {
    padding: 16px 15px;
    display: block;
  }
  /* ============== TAB ========= */
  .askQuestionMain {
    display: flex;
  }
  .subMenu {
    display: block;
  }
  .askQuestions {
    padding: 15px 25px 15px 10px;
    text-align: left;
  }
  .tabWrapper .MuiTab-textColorPrimary {
    margin-right: 15px;
  }
  .tabWrapper .MuiTabs-root {
    padding: 15px 0px 18px 10px !important;
  }
  /* ============== SUBJECTS ========== */
  .toggleButtonWrapper {
    text-align: left;
  }
  .subjectsWrapper {
    /* padding: 30px 20px 60px; */
  }
  /*  In question view card -- by madhan start*/
  .questionCardWrapper {
    display: flex;
    padding-bottom: 10px !important;
    margin-right: 2px;
    border-bottom: 1px solid rgba(179, 179, 179, 0.25);
    justify-content: center;
    align-content: stretch;
    flex-direction: column;
    margin-left: 15px;
  }
  .questionCardWrapper > div:last-child {
    margin-left: 0px;
    width: 100%;
  }
  .questionCardWrapperBlue {
    display: flex;
    padding-bottom: 10px !important;
    margin-right: 2px;
    background: #f5fcff !important;
    justify-content: center;
    align-content: stretch;
    flex-direction: column;
    margin-left: 15px;
  }
  .questionCardWrapperBlue > div:last-child {
    margin-left: 0px;
    width: 100%;
    background: #f5fcff !important;
  }
  .questionCardWrapperGrey {
    display: flex;
    padding-bottom: 10px !important;
    margin-right: 2px;
    justify-content: center;
    align-content: stretch;
    flex-direction: column;
    margin-left: 15px;
    background: #ffffdd !important;
  }
  .questionCardWrapperGrey:hover .questionCardQuestion > p {
    color: #829397;
    background: #ffffdd !important;
  }

  .questionCardWrapperGrey > div:last-child {
    margin-left: 0px;
    width: 100%;
    background: #ffffdd !important;
  }

  .questionCardWrapperRed {
    display: flex;
    padding-bottom: 10px !important;
    margin-right: 2px;
    justify-content: center;
    align-content: stretch;
    flex-direction: column;
    margin-left: 15px;
    /* background: #ECEBEB  !important; */
  }
  .questionCardWrapperRed:hover .questionCardQuestion > p {
    color: #829397;
    /* background: #ECEBEB  !important; */
  }

  .questionCardWrapperRed > div:last-child {
    margin-left: 0px;
    width: 100%;
    /* background: #ECEBEB  !important; */
  }
  .postcardGeneral {
    float: left;
    background: #f4f2f2;
    border-radius: 22px;
    border: none;
    margin: 0;
    height: 24px;
    width: 75px;
    padding: 4px 10px 1px 16px;
    font-size: 10px;
  }
  .dropdownfirstTimeCardPost {
    background: #ffffff 0% 0% no-repeat padding-box;
    /* box-shadow: 5px 5px 25px #00000040; */
    border: 1px solid #dededebf;
    border-radius: 4px;
    width: 170px;
    height: auto;
    padding: 15px 14px 16px 17px;
    position: absolute;
    right: 1%;
    /* top: 37.1em; */
    cursor: pointer;
    z-index: 10;
    float: right;
  }
  .newQuestionCardVoteBcMobile {
    color: #1ab4de !important;
    font-size: 8px;
    margin-top: 25px;
    display: flex !important;
    font-weight: bold;
    margin-left: 6px;
  }
  .postcardGroup {
    background: #f4f2f2;
    border-radius: 22px;
    border: none;
    margin: 0;
    height: 25px;
    width: 68px;
    padding: 4px 9px 1px 9px;
    font-size: 10px;
    float: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    /* margin-top: -58px; */
  }
  .postcardGroupOverFlow {
    background: #f4f2f2;
    border-radius: 22px;
    border: none;
    margin: 0;
    height: 24px;
    padding: 4px 10px 1px 16px;
    font-size: 10px;
    float: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 68px;
  }
  .postcardPost {
    float: left;
    background: #f4f2f2;
    border-radius: 22px;
    border: none;
    margin: 0;
    height: 25px;
    width: 50px;
    padding: 4px 10px 1px 16px;
    font-size: 10px;
    /* margin-top: -58px; */
  }
  .friendsConnectiontextOverflow {
    width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .myRequestCardDetails {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 110px;
  }
  .questionCardDetailsWrapperRight {
    margin: 0 0px;
  }
  .qabox {
    padding: 0px;
    margin-bottom: 20px;
  }
  .friendsCardView {
    padding: 8px;
    box-sizing: content-box;
    width: 100%;
  }
  .callGrpImage {
    height: 60px;
    width: 60px;
  }
  /* .navigationWrapper .nav-link {
  margin-right: 17px !important;
    display: inline-block;
    vertical-align: middle;
    font-size: 15px;
} */
  .navigationWrapper .nav-link {
    margin-right: 20px !important;
    display: inline-block;
    vertical-align: middle;
    font-size: 15px !important;
  }
  .navigationIcon {
    display: block;
    text-align: center;
  }
  .badgesCardFooter {
    max-height: 600px !important;
    padding: 40px 20px;
    font-size: 14px;
    /* letter-spacing: 0px; */
    color: #001737;
    /* border-bottom: 1px solid #ddd; */
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
  }
  .widthincress {
    width: 310px !important;
  }
  .updatesCarousel {
    margin-bottom: 0px;
  }
  /* .screenTitle {
   display: none;
  } */
  .badgeslistTitle {
    padding: 15px 30px 20px;
    border-bottom: 1px solid #ddd;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .friendssearch {
    display: flex;
    flex-wrap: nowrap;
  }
  .moreIcon {
    display: none;
  }
  .LikeAllicons {
    display: none !important;
  }
  .titleLarge {
    display: none !important;
  }
  .courseTitle {
    display: none !important;
  }
  .askQuestions_incalls p {
    display: flex;
    cursor: pointer;
    flex-direction: column;
    margin-top: -10px !important;
  }
  .arrowBeforeGreen {
    /* width:0px;
  border-left: 16px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid green;   */
    cursor: pointer;
  }
  .arrowAfterRed {
    /* width:0px;
  border-left: 16px solid transparent;
  border-right: 15px solid transparent;
  border-top: 15px solid red;
  cursor: pointer; */
  }
  .navClassroom {
    float: inherit !important;
    margin-right: 10px !important;
  }
  .navCommunity {
    float: inherit !important;
    margin-right: 10px !important;
  }
  .navHome {
    float: inherit !important;
    margin-right: 17px;
    margin-left: 17px !important;
  }
  .likesMainWrapper {
    margin-bottom: 16px;
  }
  .showbottom {
    display: none;
  }
  .messageIconWrapper {
    min-width: 107px;
  }
  .viewsIconWrapper {
    min-width: 84px;
  }
  .votesview {
    display: flex;
    align-items: flex-end;
    margin-left: -18px;
  }
  .questionVotes {
    margin: 0px 20px;
    display: flex;
  }
  .askedonTagWrapper {
    width: auto;
  }
  .showcount {
    display: block;
  }
  .likesInfo {
    display: none;
  }
  .mobile-likesInfo {
    display: none !important;
  }
  .likesCount {
    display: none;
  }
  .tabs {
    background-color: #dfe2e6;
    margin-bottom: 18px;
  }

  .HidequestionViewLeftbar {
    display: none !important;
  }
  .QCQuestionInfoBanner p {
    margin-right: 7px !important;
  }
  .questionEditDelete {
    display: flex;
  }
  .QCHeader {
    padding: 8px 17px;
  }
  .QCFooter {
    padding: 0 11px 3px;
  }
  .QCQuestionDescribed {
    padding: 1px 15px 23px;
    font-size: 16px;
    color: #333333;
  }
  .answerCardWrapper {
    box-shadow: 5px 5px 6px #0000000d;
    border: 1px solid #dededfbf;
    border-radius: 4px;
    background: #fff;
    /* margin-bottom: 70px; */
  }
  .answerviewimg {
    display: block;
  }
  .answerviewimginview {
    display: none;
  }
  .QuestionFilteronMobile {
    display: block;
  }
  /* .subjectsWrapper {
  padding: 30px 4px 60px;
} */
  /*  In question view card -- by madhan end*/

  /* ============== NOTIFICATION ============== */
  /* .notificationWrapper {
    right: 55px;
  } */
  /* ================ ANSWER SCREEN ========== */
  .QCSeeMoreQuestionTag {
    display: block;
    margin-bottom: 10px;
  }
  p.QCSeeMoreText {
    margin-bottom: 5px !important;
  }
  .ACUserName {
    display: block;
  }
  .answersWrapper .QCAnswerQountGrid {
    max-width: 10.333333%;
    flex-basis: 10.333333%;
  }
  .answersWrapper .AnswerQuestionCardGrid {
    /* max-width: 89.666667%;
    flex-basis: 89.666667%; */
  }
  .arrowAfter {
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 15px solid #33333380;
    width: 25px;
  }
  .arrowBefore {
    width: 25px;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid #33333380;
  }
  .answersWrapper .answeCardGrid {
    padding-left: 8px !important;
  }
  .answeredUserImg div:nth-child(1) {
    width: 30px !important;
    height: 30px !important;
    position: relative;
  }
  .answersWrapper {
    padding: 20px 10px;
  }
  .answerVideo video {
    width: 100%;
  }
  /* ============== COMMUNITY > ASK QUESTION ==========*/
  .askAQuestionCardWrapper {
    padding: 18px 15px 35px;
    background: #ffffff;
  }
  .askQuestions {
    justify-content: flex-end;
  }
  /* =============== CALL SCREEN ================ */
  .callMainWrapper {
    padding: 25px;
  }
  .YFTitleWrapper,
  .YFCardWrapper {
    padding: 15px;
  }
  p.YFTitle {
    font-size: 12px;
  }
  p.YFBookGroupCall {
    font-size: 12px;
  }
  /* SEARCH COURSE CARD */
  .searchCourseCarouselWrapper {
    padding: 0 0px 50px;
  }
  .peopleCarouselWrapper {
    margin-bottom: 50px;
  }
  .upcomingCallCarouselWrapper {
    padding: 0px !important;
  }
  .SCRemoveFriendOutlined,
  .SCaddFriendFilled {
    margin-top: 15px;
  }
  .SCuserInfo {
    margin-top: 10px;
  }
  .SCmutualFriend {
    padding-top: 5px;
  }
  /* ============= CLASSROOM SCREEN > COURSES ============ */
  .courseToolbarWrapper {
    /*padding: 25px;*/
    padding: 0px;
    padding-bottom: 25px;
  }
  .coursesMainWrapper > h2 {
    padding: 25px;
  }
  .allCourses {
    padding: 25px;
  }
  .courseTitle {
    font-size: 21px;
  }
  /* =============== SQUARE SCREEN ================= */
  .postCardHeader {
    padding: 16px 15px 10px;
  }
  .postDescription {
    padding: 0 15px 20px;
  }
  .postCardFooter {
    padding: 16px 20px;
  }
  .writeCommentWrapper {
    padding: 16px 20px;
  }
}

/* =========== 0 to 399 ============ */

@media (min-width: 0) and (max-width: 399px) {
  .screenInnerGap {
    /* padding: 0px 0px 113px 2px !important; */
    background: #f5f5f5 0 0 no-repeat padding-box;
  }

  .screenInnerGapMyGroup {
    /* padding: 122px 0px 113px 200px; */
    /* padding: 0px 0px 113px 2px; */
    background: #f5f5f5 0 0 no-repeat padding-box;
  }

  /* ============= HOME > SEARCH ========== */
  .logoDark img {
    width: 100px;
    height: 50px;
    object-fit: contain;
  }
  .peopleInvitationCardWrapper {
    display: block;
    text-align: center;
  }
  .peopleInvitationCardWrapper > div:nth-child(1) {
    margin: auto;
  }
  .PICardAction button {
    margin: auto;
  }
  button.PIAcceptFilled {
    margin-bottom: 5px;
  }
  .PICardAction {
    margin-top: 15px;
  }
  .peopleCarouselWrapper {
    padding: 0 0 30px;
  }
  .PIWrapper {
    padding: 0;
  }
  /* ============ DROPDOWN ========= */
  .dropdonwCard {
    top: 85px;
  }
  /* ============ NOTIFICATION =========== */
  /* .notificationWrapper {
    right: 0;
    width: 100%;
    left: 0;
    top: 88px;
  } */
  .notifictionMainIconWrapper {
    position: inherit !important;
  }
  /* .notificationWrapper:after {
    margin-left: -50px;
  } */
  /* ============= ANSWER SCREEN =========== */
  .shareFollowBtn {
    display: block;
  }
  .shareFollowBtn > button:nth-child(1) {
    margin-bottom: 5px;
  }
  .answerVerfiedIcon {
    position: relative;
    left: -5px;
  }
  .answeredUserImg div:nth-child(1) {
    left: -6px;
  }
  .ansCountWrapper {
    position: relative;
    left: -5px;
  }
  p.ansCount {
    position: relative;
    left: 8px;
  }
  /* ============ CALL SCREEN ======== */
  .BACDuration {
    margin-bottom: 10px;
    font-size: 10px;
    margin-right: 2px;
  }
  /* =========== SQUARE > HOME =========== */
  .writeComment {
    display: block;
  }
  .commentInput {
    margin: 10px 0;
  }
}

/* ====================== LOGIN =============== */

@media (max-width: 768px) {
  .signUpLogo {
    width: 100%;
    margin-top: 20px;
  }
  .loginQuotes {
    font-size: 12px;
  }
  .questionCardDetailsWrapper {
    display: block;
  }
  .questionCardTagsWrapper .subjectTag {
    margin-bottom: 10px;
  }
  .questionFilterInputWrapper {
    margin-bottom: 10px;
  }
  .paginationWrapperRight {
    justify-content: end;
  }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
  /* For portrait layouts only */
  .signUpLogo {
    width: auto;
    margin: 0 10px 0 0;
  }
  /* For portrait layouts only */
  .questionFilterInputWrapper input {
    max-width: 100%;
  }
  .toggleButtonWrapper {
    text-align: left;
  }
}

.mobileMenu {
  display: none;
}

@media (max-width: 738px) {
  .card-body {
    padding: 0 !important;
  }
  /* For Mobile Menu */
  .desktopMenu {
    display: none;
  }
  /* .askQuestions {
    display: none !important;
  } */
  .askQuestions_incalls {
    display: block !important;
    height: 57px;
  }
  .menuIconWrapper {
    padding: 4px 0px 4px 0px;
    /* text-align: right; */
    display: inline-flex;
    /* flex-direction: row; */
    /* margin-left: 8px; */
  }
  .mobileMenu {
    display: block;
  }

  .searchCourseCardWrapper__content__hash {
    margin-top: 10px;
    height: auto;
    /* overflow: scroll; */
  }

  .searchCourseCardWrapper__content__hash span {
    color: #1ab4de;
    font-size: 13px;
    margin-right: 8px;
  }

  .questionViewLeftbar {
    display: block;
    left: -5 !important;
    padding-top: 20px !important;
    font-size: 10px;
  }
}
/* New mobile wrapper */
@media (min-width: 0px) and (max-width: 992px) {
  .new-desktop-wrapper {
    display: none;
  }
  .new-mobileMenu {
    display: flex;
  }
  .nav-menu-new {
    position: relative;
    display: flex;
    height: 100vh;
    padding: 0rem 0.1rem;
    gap: 0.5rem;
  }
  .header-menu-new {
    display: flex;
    align-items: center;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media (min-width: 768px) and (max-width: 1240px) {
  .service-sec-1 > div:nth-child(2) {
    width: 24rem !important;
  }
  .service-sec-1 h2 {
    font-size: 2rem !important;
  }
}
