button:focus,
:focus,
div:focus {
  outline: none !important;
}
/* img {
  max-width: 100%;
  height: auto;
} */

/* ========= model ======== */
.uploadEmptyCard {
  border: 1px solid #707070;
  background: #e1e1e1;
  text-align: center;
}

.uploadEmptyCard img {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  border: 5px dashed #ffffff;
}

.uploadButton button {
  display: block;
  width: 175px;
  border: none;
  background: #dfdfdf 0% 0% no-repeat padding-box;
  border-radius: 4px;
  color: #333333;
  font-size: 14px;
  font-weight: bold;
  font-family: "Lato", sans-serif;
  padding: 7.5px;
  margin-bottom: 15px;
}
g .uploadButton {
  margin-left: 35px;
}
.createPostModel button {
  margin-left: 0 !important;
}
h5#uploadModelLabel {
  font-size: 16px;
  font-weight: bold;
  color: #000000de;
}

div#uploadModel .modal-header {
  border-bottom: 1px solid #707070;
}

#uploadModel .modal-footer {
  border: navajowhite;
}

#uploadModel .btn-primary {
  background: #60c2e7 0% 0% no-repeat padding-box;
  border-radius: 4px;
  border: none;
  color: #ffffff;
  font-size: 16px;
  width: 120px;
  padding: 11.5px;
  font-weight: bold;
}

#uploadModel .btn-secondary {
  background: no-repeat;
  border: none;
  color: #333333;
  font-size: 14px;
  font-weight: bold;
}

.blurSection {
  filter: blur(8px);
}

/* ========== about section ======== */
.profileFormTextarea textarea {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dededf;
  border-radius: 4px;
  margin-bottom: 15px;
}

.aboutInputAddButton {
  position: relative;
}

.aboutInputAddButton i {
  position: absolute;
  left: 46%;
  top: 45%;
  color: #747576;
  cursor: pointer;
}

.individualBadge {
  display: flex;
  border: 1px solid #333333;
  border-radius: 100px;
  padding: 2px 10px;
  margin-right: 15px;
  align-items: center;
}

.individualBadge p {
  font-size: 13px;
  font-family: "Lato", sans-serif;
  margin-right: 10px;
}

.profileBadge {
  margin-bottom: 15px;
}

.individualBadge span {
  font-size: 14px;
  cursor: pointer;
}

.aboutSubmitBtn button {
  width: 120px !important;
}

/* ================ LEARNING SECTION ========= */
.learningSection .individualBadge p {
  margin: 0;
}
.learningSection .individualBadge {
  display: inline-block;
  margin-bottom: 5px;
}
p.dropTitle {
  font-size: 13px;
  color: #333333de;
  font-family: "Lato", sans-serif;
  font-weight: bold;
  text-align: center;
}

.dragDropCardContainer {
  margin-top: 25px;
  width: 220px;
}

.dragDropCard {
  width: 220px;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #b2b2b2;
  border-radius: 4px;
  background: #b2b2b205;
  margin: 5px 0;
}

.dragDropCard p.dragDropTxt {
  color: #000000;
  opacity: 0.25;
  font-size: 14px;
  font-weight: bold;
  width: 100px;
  text-align: center;
  font-family: "Lato", sans-serif;
}

.dragDropBottomIcons {
  text-align: center;
}

.dragDropBottomIcons img {
  width: 15%;
}
.learningBtn button {
  width: 106px;
  margin-top: 35px;
}

.dropBadgeArea {
  min-height: 150px;
}
.activeDragArea {
  display: inline-block;
  padding: 5px;
  text-align: center;
  overflow: hidden auto;
}

.profileNumber .fa {
  color: #21ba45;
  font-size: 30px;
}

.letsGoBtn img {
  width: 20px !important;
  margin-left: 5px;
}

/* ============= MY CLASSROOM ================= */
.classroomCard {
  box-shadow: 5px 5px 10px #0000000d;
  border-radius: 4px;
  background: #fff;
  padding: 10px;
  height: 570px;
  margin-bottom: 15px;
}

.classDetails h3 {
  font-size: 16px;
  margin-bottom: 2px;
}

.classDetails h2 {
  font-size: 18px;
}

.classDetails {
  position: relative;
  padding: 15px 10px 30px;
}

.classDetails i {
  position: absolute;
  left: 2%;
  top: 14%;
}
.classDetails h3,
.classDetails h2 {
  /* font-family: "nunitoRegular"; */
  font-family: "nunitoRegular", sans-serif;
  font-weight: bold;
  margin-left: 8%;
  color: #000000de;
}

.classDetails p {
  letter-spacing: 0px;
  color: #545454;
  font-size: 12px;
}

.classImg img {
  width: 100px;
  height: 50px;
  object-fit: fill;
}

.classList h2 {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0px;
  font-family: "nunitoRegular", sans-serif;
}

.classList {
  margin-bottom: 30px;
  position: relative;
  width: 100%;
  cursor: pointer;
}

.classList p {
  font-family: "Lato", sans-serif;
  font-size: 12px;
}

.classList strong {
  font-size: 24px;
  color: #333333;
  font-family: "Bebas Neue", cursive;
  position: absolute;
  right: 2%;
  top: 21%;
}

.classListOpactiy {
  opacity: 0.5;
}

.activeClass {
  background: #e9e9e9;
  border-radius: 4px;
}

.classFeatureimg img {
  width: 100%;
  height: auto;
}

.classDetailed h1 {
  font-size: 18px;
  font-weight: bold;
  font-family: "nunitoRegular", sans-serif;
  margin-bottom: 0;
}

.classDetailed {
  padding: 25px;
}

.classDetailed p {
  font-size: 12px;
  color: #b3b3b3;
  font-weight: bold;
  font-family: "nunitoRegular", sans-serif;
  margin-bottom: 30px;
}

.takeQuizBtn {
  text-align: right;
  margin-top: 20px;
}

.takeQuizBtn button {
  background: #60c2e7 0% 0% no-repeat padding-box;
  border-radius: 4px;
  border: none;
  color: #fff;
  font-size: 14px;
  width: 130px;
  padding: 7.5px 0;
}

.classDetailed hr {
  margin: 20px 0;
  border: 2px solid #707070;
}

.flexReview {
  display: flex;
}

.flexReview p {
  margin-bottom: 0;
  margin-right: 12px;
}

.flexReview img {
  width: 20px;
  margin-right: 5px;
}

.shareClass p {
  margin-bottom: 0;
  margin-right: 25px;
  color: #333333;
  font-weight: bold;
  font-family: "Lato", sans-serif;
}

.shareClass {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.ClassRoomMainContainer {
  padding-bottom: 35px;
}
.flexClassList {
  display: flex;
  padding: 5px;
}

.classInfo {
  margin-left: 15px;
  margin-top: 5px;
}
#myclassRoom {
  padding-top: 3%;
}
/* ============= MY CLASSROOM DIALOGUE ============ */
.myClassroomModelContent {
  text-align: center;
  padding: 30px 0;
}

.myClassroomModelContent img {
  width: 60px;
}

.myClassroomModelContent p {
  color: #707070;
  font-size: 18px;
  font-family: "Lato", sans-serif;
  font-weight: bold;
  margin-bottom: 25px;
}

.myClassroomModelContent button {
  width: 170px;
  background: #60c2e7 !important;
  border: none;
  padding: 10px 0;
}

/* =============== MY NETWORK SCREEN ================ */
.findFriendContainer {
  padding: 3% 0;
}

.searchFriend {
  margin-bottom: 25px;
}

.searchFriend h3 {
  color: #707070;
  font-size: 24px;
  font-weight: bold;
  font-family: "Lato", sans-serif;
  margin-bottom: 25px;
  text-align: center;
}

.searchFriendInput.input-group,
.findaMatchSearchInput.input-group {
  width: 250px;
  margin: auto;
  position: relative;
}
.searchFriend.findaMatchCard .searchFriendSelectBoxFlex {
  width: 85%;
}
.searchFriend .input-group input {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dededf;
  border-radius: 100px !important;
  font-size: 12px;
  padding: 7px 15px;
  height: auto !important;
}

.searchFriendInput i {
  position: absolute;
  right: 15px;
  top: 9px;
  color: #9a9a9a;
  font-size: 14px;
  cursor: pointer;
}

.searchFriendSelectBoxFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 75%;
  margin: auto;
  margin-bottom: 30px;
}

.searchFriendSelectBoxFlex label {
  width: 100%;
  color: #333333de;
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 5px;
  line-height: 1;
}

.searchFriendFormField {
  margin-right: 10px;
  width: 160px;
}

.searchFriendSelectBoxFlex select {
  border: 1px solid #dededf;
  border-radius: 4px !important;
  font-size: 12px;
}

.profilePhoto img {
  width: 100px;
  height: 100px;
  box-shadow: 0px 0px 5px #00000029;
  border: 3px solid #ffffff;
  border-radius: 50%;
  object-fit: cover;
}

.networkCard {
  text-align: center;
  border: 1px solid #dededf;
  border-radius: 4px;
  background: #fff;
  position: relative;
}

.coverPhoto {
  height: 100px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  border-radius: 4px 4px 0px 0px;
}

.profilePhoto {
  margin-top: -45px;
}

.networkDetails {
  padding: 20px 15px 0;
}

.networkDetails h3 {
  font-size: 18px;
  color: #333333;
  font-weight: bold;
  font-family: "Lato", sans-serif;
}

.networkDetails p {
  color: #b3b3b3;
  font-size: 12px;
  margin-bottom: 15px;
}

.connectBtn {
  padding: 20px 5px 15px;
}

.connectBtn button {
  border-radius: 4px;
  border: none;
  background: #60c2e7;
  color: #fff;
  font-size: 12px;
  padding: 5px 8px;
  font-weight: bold;
  font-family: "nunitoRegular", sans-serif;
  border: 1px solid #60c2e7;
}
.networkCardContainer .col-md-3 {
  margin-bottom: 30px;
}
.networkCardContainer {
  min-height: 500px;
}
.networkDetailsBtn button {
  display: block;
  margin: auto;
  font-size: 14px;
  border: none;
  padding: 7.5px 0;
  font-weight: bold;
  font-family: "Lato", sans-serif;
  cursor: pointer;
}

button.networkSkip {
  background: #dfdfdf;
  border-radius: 4px;
  color: #333333;
  width: 125px;
}

/* =============== FIND A MATCH SCREEN ============ */
.findaMatchCard {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 5px 5px 10px #0000000d;
  border-radius: 4px;
  padding: 15px 10px;
  margin-bottom: 15px;
  min-height: 230px;
}
span.filterTxt {
  margin: 0 !important;
}
.filterDownArrow {
  cursor: pointer;
}
.findaMatchSearchInput span i {
  margin-right: 5px;
}
.findaMatchSearchInput {
  width: 350px !important;
}

.findaMatchSearchInput i {
  position: inherit;
  top: 10px;
  right: 10px;
  color: #9a9a9a;
  font-size: 14px;
  cursor: pointer;
}

.findaMatchSearchInput span {
  font-size: 14px;
  color: #989898;
  text-decoration: underline;
  margin-top: 6px;
  margin-left: 10px;
}

.findaMatchSearchInput span i {
  font-size: 14px;
}

.findaMatchSearchInput input {
  width: 275px;
}

.findMatachHeading {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.findMatachHeading:before,
.findMatachHeading:after {
  background: #707070;
  height: 1px;
  content: "";
  flex: 1;
}

.findMatchNetworkCard {
  padding: 20px 0;
}

.findMatachHeading h3 {
  color: #333333de;
  font-size: 24px;
  font-weight: bold;
  font-family: "nunitoRegular", sans-serif;
  padding: 0 10px;
}
.yuukkeHeading h3 {
  color: #b3b3b3;
}
.yuukkeHeading:before,
.yuukkeHeading:after {
  background: #b3b3b3;
}
.detailedCard {
  width: 90%;
  margin: auto;
}

.detailedCard .col-md-3 {
  margin-bottom: 30px;
}

.findMatchCardBtn i {
  margin-right: 5px;
}
.findMatchCardBtn button {
  width: auto;
  padding: 5px 10px;
}

button.btnOutlined {
  background: none;
  color: #60c2e7;
  border: 1px solid #60c2e7;
  margin-left: 5px;
}

.findMatchNetworkCard .row {
  margin-bottom: 25px;
}

.detailedCard .networkDetails > p:nth-child(3) {
  margin-bottom: 0;
}

/*=============== Book Time ============*/
.book-time {
  padding: 50px 0px;
}
.book-time h4 {
  color: #707070;
  font-weight: bold;
  margin-bottom: 50px;
}
.bpc-img-wrapper {
  text-align: center;
}
.bpc-img-wrapper img {
  border-radius: 50%;
  box-shadow: 0px 0px 5px #00000029;
  border: 3px solid #ffffff;
}
.hash-tags {
  font-size: 13px;
}
.chat-btn {
  border: 1px solid #60c2e7;
  border-radius: 4px;
  padding: 10px 30px;
  color: #60c2e7;
  font-weight: bold;
  font-size: 14px;
  background: transparent;
}
.update-btn {
  background: #60c2e7 0% 0% no-repeat padding-box;
  border-radius: 4px;
  color: #fff;
  padding: 10px 30px;
  border: none;
  font-weight: bold;
}
.booking-profile-card {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d4d4d5;
  border-radius: 4px 4px 0px 0px;
  padding: 30px;
}
.right-col-wrap {
  padding: 30px;
}
.align-flex-end {
  align-items: flex-end;
}

/* ================ CALANDER =========== */
.appoinmentCalander {
  position: relative;
}
.appoinmentCalander .fc-header-toolbar {
  height: 0;
  margin: 0 !important;
}
.appoinmentCalander h2.fc-toolbar-title {
  display: none;
}
.appoinmentConfirmend {
  padding: 25px;
  text-align: center;
}

.appoinmentConfirmedContent img {
  width: 40px;
  margin-bottom: 10px;
}

p.appoinmentBoldTxt {
  font-size: 21px !important;
  font-weight: bold;
  color: #707070;
  margin-bottom: 25px !important;
}

.appoinmentConfirmedContent p {
  font-size: 18px;
  margin-bottom: 0;
}

.appoinmentBtnFlex i {
  margin-right: 5px;
}
a.fc-col-header-cell-cushion {
  font-size: 12px;
}

.fc-scroller {
  /* overflow: hidden !important; */
}

.fc-button-group .fc-next-button {
  right: -10%;
}

.fc-button-primary {
  /* background: none !important; */
  /* color: #333 !important; */
  border: none !important; /* width: 50px; */
}

.fc-button-primary .fc-icon {
  width: auto !important;
}

.fc-button-group .fc-prev-button {
  left: -10%;
}

.fc-button-group {
  position: inherit !important;
  z-index: 1;
}

.fc-view-harness {
  padding-bottom: 50% !important;
  z-index: 0;
  border-bottom: 1px solid #d4d4d5;
}

.fc .fc-timegrid-col.fc-day-today {
  background: none !important;
}

.fc-theme-standard td,
.fc-theme-standard th {
  border: 0px;
}

table.fc-scrollgrid.fc-scrollgrid-liquid {
  /* border: 0px; */
}

.fc-timeGridWeek-view thead .fc-scrollgrid-section td {
  border-top: 1px solid #d4d4d5 !important;
  border-bottom: 1px solid #d4d4d5 !important;
  padding: 5px 0;
}

.fc-button-group .fc-button {
  position: absolute !important;
  top: 2%;
  color: #5fc0e4 !important;
}
.avaliableText {
  color: #707070;
  text-align: center;
  font-size: 21px;
  font-family: "Lato", sans-serif;
}
.bookingCalanderContainer > .col-md-6 {
  padding-left: 15;
  padding-right: 0;
}
.row.bookingCalanderContainer {
  margin-top: 15px;
}
.seeMoreAvalibile {
  color: #5fc0e4;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  font-family: "Lato", sans-serif;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 0;
}
.hideOverflow {
  overflow: hidden auto !important;
}
.fc-button-group .fc-button:focus {
  box-shadow: none !important;
}
.fc .fc-scroller-harness-liquid {
  /* margin-top: 10px; */
}
.fc-timegrid-slots tr td:nth-child(odd) {
  /* display: none; */
}

.fc-scrollgrid-sync-inner {
  width: 50px;
  margin: auto;
}
.fc-event-main {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
}
.fc-v-event {
  border: none !important;
}
.available,
.unavailable {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2ecc71;
  padding: 0;
  border-radius: 3px;
  color: #fff;
}
.available {
  cursor: pointer;
}
.unavailable {
  background-color: #5fc0e4 !important;
  color: #333333 !important;
}
.fc-media-screen .fc-timegrid-event-harness {
  height: 26px;
}
/* ======== Book Time ============ */
#booktime {
  padding: 50px 0px;
}
#booktime h4 {
  color: #707070;
  font-weight: bold;
  margin-bottom: 50px;
}
.bpc-img-wrapper {
  text-align: center;
}
.bpc-img-wrapper img {
  border-radius: 50%;
  box-shadow: 0px 0px 5px #00000029;
  border: 3px solid #ffffff;
}
.hash-tags {
  font-size: 13px;
}
.chat-btn {
  border: 1px solid #60c2e7;
  border-radius: 4px;
  padding: 10px 30px;
  color: #60c2e7;
  font-weight: bold;
  font-size: 14px;
  background: transparent;
}
.update-btn {
  background: #60c2e7 0% 0% no-repeat padding-box;
  border-radius: 4px;
  color: #fff;
  padding: 10px 30px;
  border: none;
  font-weight: bold;
}
.booking-profile-card {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d4d4d5;
  border-radius: 4px 4px 0px 0px;
  padding: 20px;
}
.right-col-wrap {
  padding: 20px;
}
.align-flex-end {
  align-items: flex-end;
}
.profile-details-wrap p {
  margin: 0;
}
/* ======== Book Time ============ */
#sendRequestModal .modal-body {
  padding: 0;
}
.sr-title h3 {
  font-size: 18px;
  color: #333;
  text-transform: uppercase;
  border-bottom: 1px solid #707070;
  padding: 1rem;
  font-weight: bold;
}
.srModalContent {
  padding: 20px 40px 40px 40px;
}
.subjectDropdown {
  max-width: 180px;
}
.descriptionTextArea {
  max-width: 500px;
}
.datePicker {
  max-width: 170px;
  display: inline-block;
  margin-right: 20px;
}
.timePicker {
  max-width: 100px;
  display: inline-block;
}
.srCancel {
  background: #dfdfdf;
  color: #333;
  border: none;
  font-size: 14px;
  padding: 7.5px 20px;
  border-radius: 4px;
  font-weight: bold;
}
.srSend {
  background: #60c2e7;
  border: none;
  padding: 7.5px 20px;
  font-size: 14px;
  border-radius: 4px;
  font-weight: bold;
  color: #fff;
}
#sendRequestModal label {
  font-size: 13px;
  color: #333333de;
}

/* =========== STUDY GROUP ============ */
.studyGroupCard {
  margin: 0;
  margin-bottom: 15px !important;
}

.studyGroupCard img {
  width: 185px;
  height: 185px;
}

.studyGroupCard p.postEventName {
  font-size: 14px;
}

.studyGroupCard p.postEventTime {
  font-size: 14px;
}

.studyGroupCard .postLinkDescription p {
  font-size: 12px !important;
  padding: 10px;
}

.studyGroupCard .eventOrginzerDetails p {
  font-size: 10px;
}

.studyGroupCard .eventOrginzerDetails svg {
  font-size: 16px;
}

.studyGroupCard .eventOrginzerDetails p:nth-child(2) {
  margin-right: 0;
}

.studyGroupCard .linkInfo {
  padding: 10px 10px 0;
}

.studyGroupCard .eventOrginzerDetails {
  padding: 0 10px 10px;
}

.studyGroupCard p.postEventTime svg {
  font-size: 14px;
}
.studyGroupCard .joinNowBtn button {
  width: 85px;
  height: 30px;
  font-size: 14px;
}
.studyGroupCard .postLinkDescription {
  max-height: 92px;
  overflow: hidden;
}
.studyGroupSeeMoreBtn {
  padding: 0;
  text-align: center;
  margin: 30px 0;
}

.studyGroupSeeMoreBtn button {
  width: 105px;
  height: 36px;
}
.createStudyGroupBtn button {
  width: 238px;
  height: 48px;
  font-size: 16px;
  font-weight: 300;
}

.createStudyGroupBtn button i {
  font-size: 13px;
}

.createStudyGroupBtn {
  padding: 0;
  position: absolute;
  top: 4.5em;
  right: 0;
}

.studyTourWrapper .searchFriend {
  position: relative;
  padding-top: 30px;
  min-height: 200px;
}
.createStudyGroupModelTitle {
  letter-spacing: 0px;
  color: #333333;
  text-transform: uppercase;
  font-size: 18px !important;
  font-family: "Lato", sans-serif;
  padding-left: 15px !important;
}

.formBasicName {
  width: 291px;
}

.createStudyGroupModel .form-group label {
  font-size: 13px;
  color: #333333de;
  font-weight: bold;
  margin-bottom: 5px;
}

.createStudyGroupModel input,
.createStudyGroupModel textarea,
.createStudyGroupModel select {
  border: 1px solid #dededf;
  border-radius: 4px;
}

.formBasicSubject {
  width: 180px;
}

.createStudyGroupModel input,
.createStudyGroupModel select {
  height: 38px;
  font-size: 14px;
}

.formBasicDescription {
  width: 500px;
}

.createStudyGroupModel .form-group {
  margin-bottom: 15px;
}

.formBasicDate {
  width: 150px;
}

.formBasicHour {
  width: 70px;
  margin: 0 20px 15px;
}

.formBasicDuration {
  width: 86px;
}

.newStudyTourinviteParticipate .input-group-prepend .input-group-text {
  background: none;
  border-right: 0;
  color: #747576;
  padding-right: 0;
}
.newStudyTourinviteParticipate .input-group-append .input-group-text {
  background: none;
  border-left: 0;
  color: #747576;
}
.newStudyTourinviteParticipate input {
  border-left: 0;
  border-right: 0;
}

.newStudyTourinviteParticipate .individualBadge p {
  margin: 0 !important;
  margin-right: 10px !important;
}

.newStudyTourinviteParticipate .individualBadge {
  height: 30px;
  position: relative;
  top: -5px;
  left: 30px;
}

.newStudyTourinviteParticipate {
  align-items: center;
  height: 100%;
}

.formBasicCheckbox input {
  height: auto;
}

.formBasicMaxStudent select {
  width: 75px;
}

.createNewStudyTourModelfooter {
  border-top: 0 !important;
}

.createNewStudyTourModelfooter .btn-secondary {
  background: #dfdfdf 0% 0% no-repeat padding-box;
  width: 75px;
  margin-right: 65px;
}

.createNewStudyTourModelfooter .btn {
  border-radius: 4px;
  border: none;
  letter-spacing: 0px;
  color: #333333;
  font-weight: bold;
  font-size: 14px;
  height: 36px;
}

.createNewStudyTourModelfooter button.btn.btn-primary {
  color: #fff;
  background: #60c2e7;
}
.createStudyGroupModel {
  padding-left: 30px !important;
}
.formBasicinviteParticipate {
  margin-bottom: 0 !important;
}
.createStudyGroupModelWrapper .modal-header {
  border-bottom: 1px solid #707070;
}

/* ============== SELF REVOVERY SCREEN ========= */
.selfDiscoveryBannerContainer {
  height: 230px;
  margin-top: 20px;
  position: relative;
  border-radius: 4px;
  background-image: url(https://thewallpaper.co//wp-content/uploads/2016/10/amazing-forest-road-photos-desktop-images-background-photos-download-hd-windows-wallpaper-samsung-iphone-mac-2560x1600.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  margin-bottom: 30px;
}

.blackOverlay {
  background: #000;
  width: 100%;
  position: absolute;
  height: 100%;
  opacity: 0.7;
}

.selfDiscoveryBannerInfo {
  z-index: 1;
}

.selfDiscoveryBannerInfo h1 {
  font-family: "nunitoRegular", sans-serif;
  font-weight: 900;
  text-transform: uppercase;
  font-size: 38px;
  color: #fff;
}

.selfDiscoveryBannerTab {
  justify-content: space-around;
}

.selfDiscoveryBannerTab p {
  margin-bottom: 0;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  font-family: "nunitoRegular", sans-serif;
  padding: 0 5px;
}

.selfDiscoveryBannerContainer .blackOverlay {
  border-radius: 4px;
}

.selfDiscoveryPersonal.selfDiscoveryActiveTab {
  border-bottom: 6px solid #2ecc71;
}

.selfDiscoveryAcademic.selfDiscoveryActiveTab {
  border-bottom: 6px solid #9b59b6;
}

.selfDiscoveryCareer.selfDiscoveryActiveTab {
  border-bottom: 6px solid #3498db;
}
.selfDiscoveryCard {
  width: 90%;
  max-height: 250px;
  padding: 25px;
  margin: -10px auto;
}
.selfDiscoveryQuestionTypeSelect {
  margin-right: 15px;
}
h5.selfDiscoveryQuestion {
  color: #333333;
  font-size: 13px;
  font-weight: bold;
  font-family: "nunitoRegular", sans-serif;
  margin-bottom: 30px;
}

.selfDiscoveryQuestionAnsInput input {
  border: 1px solid #dededf;
  border-radius: 4px;
  border-right: 0;
  font-size: 14px;
}

.selfDiscoveryContent {
  display: flex;
}

.selfDiscoveryContinueBtn {
  flex-basis: 20%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.selfDiscoveryContent > div:nth-child(1) {
  flex: 1;
  max-height: 150px;
  overflow: hidden auto;
}

.selfDiscoveryQuestionAnsInput .input-group-text {
  background: transparent;
  border-left: 0;
}

.selfDiscoveryQuestionAnsInput .input-group {
  margin-bottom: 15px !important;
  width: 250px;
}

.selfDiscoveryQuestionAnsInput .input-group:last-child {
  margin-bottom: 0 !important;
}

.selfDiscoveryContinueBtn button {
  width: 85px;
  height: 36px;
  padding: 0 !important;
  font-weight: bold;
  font-family: "Lato", sans-serif;
}

.selfDiscoveryQuestionTypeInput .input-group {
  width: 250px;
  margin-bottom: 0 !important;
}

.selfDiscoveryQuestionTypeInput input {
  border: 1px solid #dededf;
  border-radius: 4px;
}

.selfDiscoveryBadge p {
  margin-bottom: 0;
  color: #fff;
  font-weight: bold;
  font-family: "Lato", sans-serif;
  font-size: 14px;
}

.selfDiscoveryBadge {
  padding: 9px 15px;
  display: inline-block;
  background: #b3b3b3 0% 0% no-repeat padding-box;
  border-radius: 4px;
  margin-bottom: 15px;
  margin-right: 15px;
  cursor: pointer;
}
.roadImg .borderJoin {
  border-right: 6px solid;
  margin: 0;
  position: absolute;
  z-index: -1;
  height: 100%;
  top: 20%;
  left: 10.5%;
}
.roadImg img {
  width: 100%;
}
.roadImg .borderJoin.personal {
  border-color: #2ecc71;
}
.roadImg .borderJoin.academic {
  border-color: #9b59b6;
}
.roadImg .borderJoin.career {
  border-color: #3498db;
}
.roadImg.personal:after {
  background: #2ecc71;
}
.roadImg.academic:after {
  background: #9b59b6;
}
.roadImg.career:after {
  background: #3498db;
}
.roadImg {
  position: relative;
  padding-bottom: 50px;
}

.roadImg:after {
  content: "";
  width: 20px;
  height: 20px;
  background: #000;
  position: absolute;
  left: 9.2%;
  bottom: 0;
  border-radius: 50%;
}

/* ================ CALL SCREEN =============== */
.upcomingCallWrapper h2:nth-child(1) {
  font-weight: bold;
  font-size: 21.0939px;
  line-height: 26px;
  margin-top: 40px;
  margin-bottom: 50px;
  color: #5a5a5a;
}

.upcomingCallCardWrapper {
  background: #ffffff;
  box-shadow: 0px 0px 9.04026px rgba(0, 0, 0, 0.25);
  border-radius: 3.01342px;
  padding: 25px 20px;
  margin-bottom: 10px;
  position: relative;
}

.upcomingCallCardHeader {
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.upcomingCallType p {
  font-weight: bold;
  font-size: 24px;
  line-height: 26px;
  color: #5a5a5a;
  margin-bottom: 0;
}

.upcomingCallMoreIcon svg {
  font-size: 30px;
  color: #5a5a5a;
}

.upcomingCallCardDateInfo svg {
  font-size: 16px;
  color: #b3b3b3;
}

.upcomingCallDate p {
  font-weight: bold;
}

.upcomingCallCardDateInfo p {
  margin-bottom: 0;
  font-size: 12px;
  line-height: 29px;
  color: #5a5a5a;
  margin-left: 5px;
}

.upcomingCallCardDateInfo div {
  align-items: center;
}

.upcomingCallDate {
  margin-right: 15px;
}
.upcomingFullwidthBtn button {
  width: 100% !important;
}
.callUserInfo img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #3dc2ec;
}

.callUserInfo p {
  font-size: 10px;
  line-height: 26px;
  text-align: center;
  margin-bottom: 0;
}

.callUserInfo {
  width: 40px;
}

.groupCallUserInfoWrapper {
  display: flex;
  align-items: flex-start;
}

.groupCallUserInfoWrapper .callUserInfo {
  margin-right: 10px;
}

.viewMoreUsers {
  width: 40px;
  height: 40px;
  background: #3dc2ec;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}

.viewMoreUsers svg {
  color: #fff;
  font-size: 18px !important;
  line-height: 29px;
}

.upcomingCallCardFooter {
  padding: 0;
  justify-content: space-between;
  align-items: center;
}

.upcomingCallCardBody {
  padding: 25px 0 30px;
}
.upcomingCallCardFooter button {
  width: 72px;
  height: 27px;
  font-weight: bold;
  font-size: 16px !important;
  line-height: 8px;
  background: rgba(96, 194, 231, 0.5);
  border: 1px solid rgba(96, 194, 231, 0.5) !important;
}
.yourFriedsMainWrapper {
  background: #ffffff;
  box-shadow: 0px 3.01342px 6.02684px rgba(0, 0, 0, 0.1);
  border-radius: 3.01342px;
  margin-top: 60px;
  margin-bottom: 60px;
  padding-bottom: 35px;
}

.yourFriendsSimpleToolbar {
  border-bottom: 0.753355px solid #c4c4c4;
  align-items: center;
  justify-content: space-between;
  padding: 25px 50px;
  margin-bottom: 50px;
}

.yourFriendsSimpleToolbar h2 {
  font-weight: bold;
  font-size: 13.5604px;
  line-height: 26px;
  text-transform: uppercase;
  color: #a3a3a3;
  margin-bottom: 0;
}

.yourFriendsSimpleToolbar button {
  width: 120px;
  font-weight: 500;
  font-size: 10.547px !important;
  line-height: 24px;
  height: 27px;
  display: grid;
  place-content: center;
}

.yourFriendsSimpleToolbar .connectBtn {
  padding: 0;
}

.yourFriendSeemoreBtn {
  padding: 0;
  text-align: center;
}

.yourFriendSeemoreBtn button {
  width: 80px;
  height: 27px;
  font-weight: 500;
  font-size: 10.547px !important;
  line-height: 24px;
  display: grid;
  place-content: center;
  margin: auto;
}
.yourFriendInfoWraper {
  padding: 0 50px 30px;
}

.bookpeerSessionCardWrapper {
  box-shadow: 0px 0px 9.04026px rgba(0, 0, 0, 0.25);
  border-radius: 3.01342px;
}

.bookpeerSessionCardWrapper .profilePhoto img {
  box-shadow: 0px 3.01342px 3.01342px rgba(0, 0, 0, 0.25);
}

.bookpeerSessionCardWrapper .networkDetails h3 {
  font-weight: bold;
  font-size: 15.0671px;
  line-height: 21px;
  color: #000000;
}

.bookpeerSessionCardWrapper .connectBtn.findMatchCardBtn button:nth-child(1) {
  margin-right: 5px;
}

.bookpeerSessionCardWrapper .connectBtn.findMatchCardBtn button {
  font-size: 12px !important;
  font-weight: normal;
}
/* .callCardWrapper {
  height: 305px;
}
.callCardHeader {
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  border-bottom: 1px solid #dededf;
}

.callCardWrapper p {
  margin-bottom: 0;
}

.upcomingCallDate p {
  font-size: 18px;
  font-weight: bold;
}

.upcomingCallCardUserInfo strong {
  margin-right: 5px;
}

.upcomingCallTime p {
  font-size: 18px;
  text-transform: uppercase;
}

.upcomingCallCardBody {
  padding: 10px 15px;
}

.upcomingCallCardType {
  align-items: center;
  justify-content: space-between;
}

.upcomingCallType {
  font-size: 26px;
  font-weight: 600;
  flex: 1;
}

.upcomingCallEditBtn a.btn-peer {
  padding: 5px 15px;
  font-size: 18px;
}

.upcomingCallCardTypeIcon svg {
  font-size: 45px;
  margin-left: 10px;
}

.upcomingCallCardUserInfo h2 {font-size: 16px;font-weight: 500;}

.upcomingCallCardUserInfo {
  height: 120px;
  padding-top: 15px;
}

.upcomingCallCardFooter button {width: 100%;padding: 7px 10px;}

.upcomingCallCardFooter div {
  flex-basis: 49%;
}

.upcomingCallWrapper > h2:nth-child(1) {
  font-size: 26px;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 15px;
}

.upcomingCallWrapper {
  padding-top: 50px;
} */
.bookpeerSessionCardWrapper {
  margin-bottom: 15px;
}

.callTabMainWrapper {
  padding-top: 50px;
}

.callTabMainWrapper .tab-content {
  margin-top: 25px;
}

.callTabMainWrapper nav a {
  font-size: 24px;
  color: #000;
  font-weight: bold;
}
.groupSessionCard {
  padding: 20px;
}

.groupSessionCard .scheduleBtn {
  padding-bottom: 0;
}

.groupSessionCard h2 {
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 20px;
}

.groupSessionCard p {
  font-size: 16px;
  height: 120px;
  margin-bottom: 0;
}

.groupSessionCard .scheduleBtn button {
  width: 100%;
  font-size: 18px !important;
  text-transform: uppercase;
}
.bookGroupSessionTitleWrappper h2 {
  font-size: 28px;
  font-weight: bold;
}

.bookGroupSessionWrapper > .row:nth-child(1) {
  align-items: center;
  margin-bottom: 30px;
}

.bookGroupSessionBtnWrapper {
  text-align: right;
  padding: 0;
}

.bookGroupSessionBtnWrapper button {
  font-size: 18px !important;
  text-transform: uppercase;
  padding: 10px 15px;
}
.bookaCallMainWrapper {
}

.bookaCallMainWrapper .modal-title {
  margin: auto;
}
.bookaCallMainWrapper .modal-header .close {
  margin: 0;
  padding: 0;
  font-size: 26px !important;
}

.bookaCallMainWrapper .modal-header .close:nth-child(1) {
  font-size: 7px;
}

.bookaCallMainWrapper .modal-header {
  border-bottom: 0;
}

.bookaCallMainWrapper label.form-label {
  font-weight: bold;
  font-size: 21.0939px;
  line-height: 26px;
  color: #5a5a5a;
  margin-bottom: 5px;
}

.bookaCallMainWrapper label[for="meetingDuration"] {
  display: block;
}

.formButtonGroup button {
  border-radius: 0 !important;
  color: #a3a3a3;
  font-weight: bold;
  font-size: 21.0939px !important;
  line-height: 26px;
  background: #f3f3f3;
  border: 0;
  border-bottom: 2px solid #3dc2ec;
  margin-right: 2px;
  width: 38px;
  height: 47px;
  padding: 0;
}

.bookaCallMainWrapper textarea {
  resize: none;
  height: 150px !important;
}
.bookaCallMainWrapper .form-group {
  margin-bottom: 0;
}
.requestMeetingBtn button {
  width: 100%;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 18px !important;
  line-height: 22px;
  text-align: center;
  height: 40px;
  margin-bottom: 0 !important;
}
.bookaCallMainWrapper .modal-dialog {
  max-width: 670px;
}

.bookaCallMainWrapper .modal-body {
  padding: 25px 45px;
}

.bookaCallMainWrapper [class*="col-"] {
  margin-bottom: 50px;
}
.bookaCallTitle h2 {
  font-weight: bold;
  font-size: 21.0939px;
  line-height: 26px;
  color: #5a5a5a;
  text-align: center;
}

.bookaCallTitle h2 span {
  color: #3dc2ec;
  margin-left: 5px;
}

.bookaCallTitle {
  padding-top: 70px;
  padding-bottom: 25px;
}

.bookaCallFormWrapper {
  background: #ffffff;
  box-shadow: 0px 3.01342px 6.02684px rgba(0, 0, 0, 0.1);
  border-radius: 3.01342px;
  padding: 40px 50px;
  max-width: 750px;
  margin: auto;
}
.bookaCallMainWrapper textarea,
.bookaCallMainWrapper input,
.bookaCallMainWrapper select {
  background: #f3f3f3;
  border: 0;
  border-bottom: 2px solid #3dc2ec;
}
.activeBtnSelect {
  background-color: #3dc2ec !important;
  color: #fff !important;
}
.bookaCallFormRadioBtn {
  display: flex;
}

.bookaCallFormRadioBtn .form-check {
  margin-right: 15px;
  padding-left: 0;
}

.bookaCallFormRadioBtn .form-check label {
  font-weight: bold;
  font-size: 21.0939px;
  line-height: 26px;
  color: #a3a3a3;
}
.bookaCallFormRadioBtn .form-check [type="radio"]:checked,
.bookaCallFormRadioBtn .form-check [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.bookaCallFormRadioBtn .form-check [type="radio"]:checked + label,
.bookaCallFormRadioBtn .form-check [type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
}
.bookaCallFormRadioBtn .form-check [type="radio"]:checked + label:before,
.bookaCallFormRadioBtn .form-check [type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 19px;
  height: 19px;
  border: 2px solid #000000;
  border-radius: 50%;
  background: #fff;
}
.bookaCallFormRadioBtn .form-check [type="radio"]:checked + label:after,
.bookaCallFormRadioBtn .form-check [type="radio"]:not(:checked) + label:after {
  content: "";
  width: 7px;
  height: 7px;
  background: #000;
  position: absolute;
  top: 6px;
  left: 6px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.bookaCallFormRadioBtn .form-check [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.bookaCallFormRadioBtn .form-check [type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.bookaCallMainWrapper input {
  height: 47px;
}
.requestMeetingBtn {
  padding: 0 !important;
}
.bookaCallMainWrapper {
  padding-bottom: 50px;
}
.formButtonGroup button:hover {
  background: #3dc2ec;
  border-bottom: 2px solid #3dc2ec;
}
.requesSuccessWrapper h2 {
  font-weight: bold;
  font-size: 21.0939px;
  line-height: 26px;
  color: #00d26d;
  text-align: center;
  margin-bottom: 20px;
}

.requestSuccessMycallBtn {
  text-align: center;
  margin-top: 30px;
}

.requesSuccessWrapper {
  padding-top: 80px;
}

.requestSuccessMycallBtn button {
  width: 120px;
}
.yourFriendInfoWraper .nav {
  margin-bottom: 30px;
}
.yourFriendInfoWraper .nav a {
  color: #495057;
}
.yourFriendInfoWraper .nav a.active {
  color: #60c2e7;
}
.bookaCallFormWrapper .form-check input {
  height: auto;
}
.optionsToggle ul {
  list-style: none;
  background: #fff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  padding: 10px;
  margin-bottom: 0;
}
.optionsToggle {
  position: absolute;
  top: 60px;
  right: 30px;
  width: 100px;
  z-index: 10;
}
.optionsToggle ul li {
  color: #5a5a5a;
}
.optionsToggle:before {
  /* width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 0px solid transparent;
  border-bottom: 10px solid red;
  position: absolute;
  content: "";
  top: 52px;
  right: 48px; */
}
.options-edit svg {
  color: #60c2e7;
  font-size: 16px;
  margin-right: 10px;
}
.optionsToggle ul li {
  color: #5a5a5a;
}
.options-cancel svg {
  color: #ff0000;
  font-size: 16px;
  margin-right: 10px;
}
.upcomingCallMoreIcon svg,
.upcomingCallMoreIcon li {
  cursor: pointer;
}
.upcomingCallMoreIcon {
  position: relative;
}
.upcomingCallMoreIcon svg,
.upcomingCallMoreIcon li {
  cursor: pointer;
}
/* .requestMeetingBtn {
  padding: 0 !important;
}
.requestSuccess {
  width: 75%;
  margin: auto;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 30px;
}

.requestSuccess svg {
  font-size: 75px;
  color: green;
}

h3.requestSuccessTitle {
  font-size: 24px;
  font-weight: bold;
  color: green;
  margin: 25px 0;
}

p.requestGroupInfo {
  font-size: 16px !important;
  margin-bottom: 40px;
}

p.requestSuccessDate, .requestSuccessTime {
  font-size: 26px;
  font-weight: bold;
}

p.requestSuccessDate {
  margin-bottom: 10px;
}

.requestSuccessEditBtn {
  padding: 0;
  text-align: right;
}

.requestSuccessMycallBtn button {
  width: 60%;
  font-size: 18px !important;
}

.requestSuccessMycallBtn {
  text-align: center;
  padding: 30px 0!important;
} */
/* .swiper-container {
  width: 100%;
} */

/* ==================== MY SOCIAL SCREEN ============ */
.mySocialTabWrapper {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  padding: 25px 25px 50px;
  margin-top: 30px;
}

.mySocialTabWrapper nav a {
  color: #33333380;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
}

.mySocialTabWrapper nav .nav-link.active {
  color: #333;
  border-bottom-width: 3px;
  border-color: #fff #fff #64c2e5;
}

.mySocialTabWrapper nav .nav-link:hover,
.mySocialTabWrapper nav .nav-link:focus {
  border-color: #fff #fff #64c2e5;
  border-bottom-width: 3px;
}

.mySocialTabWrapper nav.nav-tabs {
  border-bottom: 1px solid #dededfbf;
  margin-bottom: 20px;
}

.sidebarMenu .nav-item a {
  font-size: 16px;
  color: #8d8d8d;
  min-height: 47px;
  display: flex;
  align-items: center;
}

.sidebarMenu .nav-link.active {
  background: #f2f2f2 !important;
  color: #000 !important;
  font-weight: bold;
  border-bottom: 0;
  border-left: 3px solid #60c2e7;
  border-radius: 0;
}

.sidebarWrapper select {
  border: 1px solid #dededf;
  border-radius: 4px;
}

.boldLabel {
  font-size: 16px;
  font-weight: 600;
}

.noinvitationLabel {
  font-size: 14px;

  margin-left: 12px;
}

.sidebarWrapper .nav-pills {
  margin-bottom: 30px;
}

.popularTopicWrapper .slick-slide > div {
  margin: 0 5px;
}
.socialHeading {
  font-size: 20px;
  font-weight: bold;
  color: #333333;
  text-transform: capitalize;
  margin-bottom: 15px;
}
.newQuestionWrapper {
  box-shadow: 5px 5px 6px #0000000d;
  border: 1px solid #dededfbf;
  border-radius: 4px;
  margin-top: 21px;
}

.postQuestionField img {
  width: 52px;
  height: 52px;
  box-shadow: 0px 0px 5px #00000029;
  border: 3px solid #ffffff;
  border-radius: 100%;
  margin-right: 30px;
}

.postQuestionField textarea {
  border: none;
  font-size: 14px;
  resize: none;
}
.postQuestionField textarea::placeholder {
  font-size: 17px;
  color: #999999;
  font-weight: bold;
  position: relative;
  top: 5px;
}
.postQuestionField {
  padding: 20px 20px 10px;
  border-bottom: 1px solid #dededfbf;
  align-items: center;
}

.postQuestionField textarea:focus {
  border-color: inherit;
  box-shadow: none;
}

/* .formPostMoreOption {
  align-items: center;
  justify-content: space-between;
  padding: 25px 20px;
  color: #d9d9d9;
}

.formPostMoreOption .flexDefault:nth-child(1) svg {
  margin-right: 20px;
  cursor: pointer;
}

.formPostMoreOption .flexDefault:nth-child(2) > svg {
  margin-right: 10px;
  cursor: pointer;
}

.formPostMoreOption .flexDefault:nth-child(2) > div {
  width: 40px;
  height: 40px;
  background: #60c2e7;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 100%;
  cursor: pointer;
}

.formPostMoreOption .flexDefault:nth-child(2) {
  align-items: center;
} */
.topQuestionsMainWrapper {
  margin-top: 40px;
}

.topQuestionsMainWrapper > .row {
  padding-bottom: 50px;
}

#test > div.row {
  margin-bottom: 50px;
}

.filterTabMenu {
  border: 1px solid #dededf;
  border-radius: 0px 3px 4px 0px;
  width: max-content;
  margin-left: auto;
}

.filterTabMenu .nav-item {
  border-right: 1px solid #dededf;
}

.filterTabMenu .nav-link.active {
  background: #60c2e7 !important;
  border-radius: 0;
}
.filterTabMenu .nav-link {
  color: #60c2e7;
}
.postedQuestionUserInfo img {
  box-shadow: 0px 0px 5px #00000029;
  border: 3px solid #ffffff;
  border-radius: 100%;
  width: 90px;
  height: 90px;
}

.postedQuestion {
  flex: 1;
}

.postedQuestionUserInfo {
  margin-right: 21px;
  display: flex;
  align-items: center;
}

.postedQuestion > div > p {
  color: #333333;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  word-break: break-all;
}

.answersLikeViews p {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0;
  margin-right: 30px;
}

.answersLikeViews svg {
  font-size: 24px;
  margin-right: 8px;
}

.answersLikeViews {
  margin-bottom: 15px;
}

p.answersWrapper {
  color: #db2828;
}

p.votesWrapper {
  color: #21ba45;
}

p.viewsWrapper {
  color: #60c2e7;
}

.questiontag {
  border-radius: 16px;
  padding: 7px 15px;
  background: #f3f5f9;
  margin-right: 10px;
  font-size: 11px;
  color: #242134;
  max-width: max-content;
}

.questiontag p {
  margin-bottom: 0;
}

.postedQuestionAskedTime {
  color: #d9d9d9;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: flex-end;
}

.individualQuestion {
  padding-bottom: 20px;
  border-bottom: 1px solid #dededfbf;
  margin-bottom: 25px;
}
.topQuestionsMainWrapper h3.socialHeading {
  margin-bottom: 0;
  margin-top: 10px;
}
.individualQuestion:last-child {
  border-bottom: 0;
}

.filterTabMenu .nav-item:last-child {
  border-right: 0;
}
/* -============== TAGS TAB IN MY SOCIAL SCREEN =============== */
.tagsBannerWrapper p {
  color: #8d8d8d;
  font-size: 15px;
  font-weight: 300;
  margin-bottom: 0;
}

.tagsBannerWrapper {
  margin-bottom: 40px;
}

.tagsBannerWrapper h3.socialHeading {
  margin-bottom: 5px;
}

.filterRow .input-group-prepend span {
  border: none;
  border-right: 0px;
  padding: 0.375rem 10px;
  border-radius: 20px;
  background: #f2f2f2;
}

.filterRow input.form-control {
  border-left: 0;
  border-radius: 20px;
  background: #f2f2f2;
  border: none;
}

.filterRow .input-group {
  width: 254px;
}
.filterRow {
  margin-bottom: 50px;
}

.tagCardWrapper {
  border: 1px solid #ececec;
  border-radius: 8px;
  padding: 10px 15px;
  margin-bottom: 15px;
}

.subjectTagsDecription {
  font-size: 12px;
  color: #242134;
  margin-top: 15px;
  margin-bottom: 30px;
}

.QuestionsAskedCount {
  color: #dbdbdb;
  font-weight: bold;
  font-size: 14px;
  align-items: center;
  justify-content: space-between;
}

/* =============== MY SOCIAL SCREEN USERS TAB ============== */
.forumUserImg img {
  box-shadow: 0px 0px 5px #00000029;
  border: 3px solid #ffffff;
  width: 58px;
  height: 58px;
  border-radius: 100%;
}

.userTag {
  border: 1px solid #f7f7f7;
  border-radius: 4px;
  padding: 15px 10px;
  align-items: center;
  margin-bottom: 15px;
}

.forumUserImg {
  flex-basis: 65px;
  margin-right: 15px;
}

p.forumUserName {
  color: #333333;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0;
}

p.forumUserLocation {
  color: #b3b3b3;
  font-size: 15px;
  margin-bottom: 0;
}

p.forumUserQuestionsCount {
  color: #b3b3b3;
  font-size: 15px;
  margin-bottom: 0;
}

p.forumUserQuestionsTags {
  color: #60c2e7;
  font-size: 15px;
  margin-bottom: 0;
}
.yuukkePagination {
  margin-bottom: 0;
  float: right;
  margin-top: 50px;
}

.yuukkePagination a.page-link {
  border-radius: 6px;
  background: #f8f9fa;
  padding: 0;
  width: 33px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #353344;
  font-size: 14px;
  border: none;
  margin-right: 5px;
}

.yuukkePagination li.active .page-link {
  background: #64c2e5;
  border: none;
  width: 33px;
  height: 33px;
  border-radius: 6px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.yuukkePagination a.page-link:focus {
  box-shadow: none;
}

/* ============ MY QUESTIONS IN MY SOCIAL SCREEN ========= */
.questionAnswerWrapper .question {
  color: #333333;
  font-size: 18px;
  font-weight: bold;
  word-break: break-all;
}
.questionAnswerWrapper ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.questionAnswerWrapper ul li {
  display: inline-block;
  margin-right: 10px;
}
.questionAnswerWrapper {
  margin-bottom: 30px;
}
.questionUserDetailsWrapper .chatImageWrapper img {
  max-width: 60px;
}
.questionUserDetailsWrapper .chatImageWrapper {
  margin: 0 15px 0 0;
}
.questionUserDetailsWrapper {
  margin-top: 10px;
}
.replyCounts {
  color: #5b5b5b;
  font-size: 14px;
  border-bottom: 1px solid #ececec;
  padding: 20px 0px;
  margin-bottom: 20px;
}
.ansObjWrapper .chatImageWrapper img {
  max-width: 60px;
}
.ansObjWrapper .chatImageWrapper {
  margin: 0 15px 0 0;
}
.ansObjWrapperOuter {
  background: #f5f5f5;
  margin-left: 20px;
  padding: 20px 20px 0px;
}
.ansObjWrapperOuter small {
  color: #b3b3b3 !important;
}
.ansObjWrapper {
  padding-bottom: 20px;
}
.answerUser {
  color: #333333;
  margin: 20px 0px;
  word-break: break-all;
}
.lfvWrapper {
  color: #5b5b5b;
  font-size: 16px;
}
.lfvWrapper svg {
  font-size: 16px;
  margin-right: 5px;
}
.lfvLike .active {
  color: #fd2928;
}
.lfvWrapper > div {
  margin-right: 10px;
  cursor: pointer;
}
.questionUserDetails small {
  color: #b3b3b3 !important;
}

/* ============ STICKY CHAT IN MAIN SCREEN ========= */
.stickyChatContainer {
  position: fixed;
  right: 0;
  color: #333;
  z-index: 1029;
  /* height: calc(100vh - 96px); */
  height: 100vh;
  background: #fff;
  width: 60px;
  top: 0px;
  box-shadow: 0px 0 10px 0 rgba(0, 0, 0, 0.1);
}
.chatImageWrapperouter {
  position: relative;
  top: 96px;
}
.chatImageWrapper {
  border-radius: 50% !important;
  position: relative !important;
  padding: 3px !important;
  background: #fff !important;
  box-shadow: 0px 0 3px 0 rgba(0, 0, 0, 0.1) !important;
  margin: 20px 5px 0 !important;
  cursor: pointer !important;
}
.chatImageWrapper .online:before {
  position: absolute;
  content: "";
  background: #6ad148;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  top: 0;
  right: 5px;
}
.chatImageWrapper .offline:before {
  position: absolute;
  content: "";
  background: #c63928;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  top: 0;
  right: 5px;
}
.chatImageWrapper .away:before {
  position: absolute;
  content: "";
  background: #efc742;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  top: 0;
  right: 5px;
}
.chatImageWrapper img {
  border-radius: 50%;
  overflow: hidden;
}
/* ==================== FOR MOBILE ============= */
/* @media (min-width: 1300px) {
  #root .container {
    max-width: 1410px;
  }
} */
/* @media screen and (min-width: 640px) {
  .swiper-container {
    width: 640px;
  }
}

@media screen and (min-width: 768px) {
  .swiper-container {
    width: 768px;
  }
}
@media screen and (min-width: 992px) {
  .swiper-container {
    width: 992px;
  }
} */
@media (min-width: 1440px) {
  /* ============= SELFDISCOVERY SCREEN =============== */
  .roadImg:after {
    left: 9.5%;
  }
}
@media (max-width: 768px) {
  .subjectDropdown {
    max-width: 100%;
  }
  .descriptionTextArea {
    max-width: 100%;
  }
  .descriptionTextArea {
    max-width: 100%;
  }
  .datePicker {
    max-width: 100%;
    display: block;
    margin-right: 0px;
  }
  .timePicker {
    max-width: 100%;
    display: block;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  #root .container {
    max-width: 992px;
  }
  /* COMMUNITY SCREEN */
  .newPostUpload p {
    font-size: 12px;
  }

  .newPostUpload svg {
    font-size: 21px;
  }

  .newPostUploadVideo svg {
    font-size: 27px;
  }

  p.invitationUserName {
    font-size: 11px;
  }

  .invitationUserInfo p {
    font-size: 9px;
  }

  .invitationUserInfoWrapper .userImg img {
    width: 50px;
    height: 50px;
  }

  .invitationConnect button {
    display: flex;
    align-items: center;
  }
  /* STUDY GROUP SCREEN */
  .studyGroupCard p.postEventName {
    font-size: 12px;
  }

  .studyGroupCard p.postEventTime {
    font-size: 11px;
  }

  .studyGroupCard .postLinkDescription p {
    font-size: 11px !important;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  /* .flexDefault {
    display: block;
  } */
  /* COMMUNITY SCREEN */
  .newPostUpload p {
    font-size: 12px;
  }

  .newPostUpload svg {
    font-size: 21px;
  }

  .newPostUploadVideo svg {
    font-size: 27px;
  }

  /* ============ CALL SCREEN ========= */
  .callTabMainWrapper nav a {
    width: 100%;
  }
  .bookGroupSessionBtnWrapper button {
    font-size: 14px !important;
  }
  .requestSuccess {
    width: 100%;
  }
  .requestSuccessEditBtn {
    text-align: left;
  }
  /* ==================== MY SOCIAL SCREEN =========== */
  .filterTabMenu {
    margin-left: 0;
    margin-top: 15px;
  }

  .postedQuestion .questiontag {
    margin-bottom: 5px;
  }
  .sidebarWrapper {
    margin-top: 30px;
  }
  .borderLeftCol {
    border-left: none;
  }
  .yuukkePagination {
    float: left;
  }
  .filterRow .input-group {
    flex-wrap: nowrap;
  }
  /* ============= CHAT ============ */
  .stickyChatContainer {
    display: none;
  }
}

@media (min-width: 0) and (max-width: 378px) {
  /* COMMUNITY SCREEN */
  .likesInfo p {
    font-size: 11px;
  }

  p.postEventName {
    font-size: 14px;
  }

  p.postEventTime {
    font-size: 11px;
  }

  .eventOrginzerDetails p {
    font-size: 12px;
  }
  .yuukkePagination {
    float: left;
  }
  /* =============== MY SOCIAL SCREEN ============== */
  .mySocialTabWrapper nav .nav-link {
    display: block;
    width: 100%;
  }
}

/* ============= YUUKKE TUTORING =============== */
.peerTutoringRootContainer {
  padding: 50px 0px;
}

.peerTutoringRootContainer .slick-slide > div {
  margin: 0 5px;
}
.peerTutoringSliderWrapper
  > div
  > div
  > div.slick-list
  > div
  > div.slick-slide.slick-active.slick-current
  > div {
  margin-left: 0;
}
.peerTutoringRootContainer h2 {
  text-transform: uppercase;
  color: #333;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 30px;
}
.peerTutoringSliderWrapper {
  margin-bottom: 50px;
}
/* ============= UPCOMING CALLS CARD =============== */
.upcomingCalls {
  background: #fff;
  color: #333;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  margin: 10px;
}
.isUpcomingCallActive {
  background: #60c2e7;
  color: #fff;
}
.upcominingCalls_imgWrapper .chatImageWrapper {
  margin: 0;
  max-width: 60px;
  width: 52px;
  height: 52px;
}
.isUpcomingActiveText {
  color: #fff !important;
}
.upcomingCalls__nameWrapper {
  margin-right: 15px;
}
.upcomingCalls__nameWrapper p {
  min-height: 48px;
}
.upcomingCalls__nameWrapper h3 {
  font-size: 20px;
  font-weight: bold;
}
.upcomingCalls__nameWrapper p {
  margin: 0;
}
.upcomingCalls small {
  opacity: 0.6;
}
.upcomingCalls__Duration ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.upcomingCalls__Duration ul li {
  display: inline-block;
}
.upcomingCalls__Duration ul {
  margin: 10px 0px;
  padding: 0;
  list-style: none;
}
.upcomingCalls__Duration ul li {
  display: inline-block;
}
.upcomingCalls__Duration ul li svg {
  margin-right: 10px;
  width: 12px;
  height: 12px;
}
.upcomingCalls__Cta button {
  width: 48%;
  text-transform: uppercase;
  font-size: 12px !important;
  font-weight: bold;
  margin-top: 20px;
}
.upcomingCalls__Start {
  background: #fff;
  color: #60c2e7;
  text-transform: uppercase;
  font-size: 12px !important;
  font-weight: bold;
}
.upcomingCalls__Cta {
  display: flex;
  justify-content: space-between;
}
.upcomingCalls__Cancel {
  box-shadow: 0 0 0 1px #fff;
}
.peerTutoringTabsWrapper {
  background: #fff;
  padding: 20px;
}
.upcomingCalls__nameWrapper svg {
  width: 12px;
  height: 12px;
  cursor: pointer;
}
/* .MuiAvatarGroup-avatar {
  box-shadow: 0px 0px 5px #00000029;
  border: 2px solid #ffffff;
  width: 34px;
  height: 34px;
  margin-left: -15px !important;
}
.MuiAvatarGroup-root div:nth-child(1) {
  z-index: 4 !important;
}

.MuiAvatarGroup-root div:nth-child(2) {
  z-index: 5 !important;
}

.MuiAvatarGroup-root div:nth-child(3) {
  z-index: 6 !important;
  font-size: 13px;
  font-weight: bold;
  background: #60c2e7;
} */
/* FRIENDS CARD */
.friendsCardWrapper {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 30px;
}
.friendsUserDetails {
  padding: 20px 15px;
}
.profileImgWrapper img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px solid #fff;
  object-fit: cover;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}
.coverImgWrapper {
  position: relative;
}
.profileImgWrapper {
  position: absolute;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
}
.friendsUserDetails p {
  margin: 0;
  padding-top: 50px;
  font-size: 20px;
  font-weight: bold;
}
.friendsUserDetails p {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden !important;
}
.friendsUserDetails small {
  opacity: 0.5;
}
.friendsUserDetails button {
  font-size: 11px !important;
  font-weight: bold;
  margin-top: 10px;
  display: flex;
  align-items: center;
  height: 26px;
  width: auto;
  justify-content: center;
}
button.fcwCallBooked {
  padding: 0 5px;
  margin-right: 10px;
}
.friendsUserDetails button svg {
  margin-right: 5px;
  width: 15px;
  height: 15px;
}
.friendsUserDetails .fcwChat {
  background: transparent;
  color: #60c2e7;
}
.friendsTabWrapper {
  padding: 20px 0 0;
}
.peerTutoringTabsWrapper nav a {
  border: none !important;
}
.peerTutoringTabsWrapper nav a {
  text-transform: uppercase;
  color: #333;
  opacity: 0.5;
  font-weight: bold;
}
.peerTutoringTabsWrapper nav .active {
  border-bottom: 2px solid #60c2e7 !important;
  opacity: 1;
}
.ftwGroupCallCta {
  margin-bottom: 20px;
  text-align: right;
  text-transform: uppercase;
  color: #60c2e7;
  font-weight: bold;
  cursor: pointer;
}
.ftwGroupCallCta svg {
  margin-right: 10px;
}
.friendsListName {
  display: inline-flex !important;
}

.myCallWrapper {
  padding: 25px;
}

.mySearchCallWrapper {
  padding: 35px;
}
