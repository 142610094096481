.div-calendar {
  /* width: 550px; */
  max-width: 100%;
  background: #ffffff;
  /* border: 1px solid #a0a096; */
  line-height: 1.125em;
  color: #000000;
  /* border: 1px solid #000000; */
  box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.05);
  border: 1px solid #eaeaea;
  border-radius: 10px;
  position: relative;
  padding: 8px !important;
}

.rbc-row-segment {
  padding: 1px 1px 1px 1px !important;
  border-radius: 4px !important;
  display: inline-flex;
}
.rbc-event,
.rbc-day-slot .rbc-background-event {
  border: none;
  box-sizing: border-box;
  box-shadow: none;
  margin: 8px;
  padding: 2px 5px;
  background-color: #3174ad;
  border-radius: 14px !important;
  font-size: 1px !important;
  color: #fff;
  cursor: pointer;
  width: 13px !important;
  height: 13px !important;
  text-align: left;
  display: inline-flex;
}

.rbc-toolbar {
  font-weight: bold !important;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;
  color: #f97516;
}

.rbc-btn-group {
  display: inline-block;
  white-space: nowrap;
  color: #ffffff !important;
}

.rbc-toolbar button:hover {
  color: #f97516 !important;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.rbc-toolbar button {
  color: #f97516 !important;
  display: inline-block;
  margin: 0;
  text-align: center;
  vertical-align: middle;
  background: none;
  background-image: none;
  border: 1px solid #ccc;
  padding: 0.375rem 1rem;
  border-radius: 4px;
  line-height: normal;
  white-space: nowrap;
}

.rbc-calendar *,
.rbc-calendar *:before,
.rbc-calendar *:after {
  box-sizing: inherit;
}
.calender-vcard {
  min-height: 100vh;
  display: flex;
  background-image: linear-gradient(205deg, #000940 0%, #a00030 100%);
  /* background-image: linear-gradient(
      206deg,
      rgba(72, 45, 45, 0.46) 0%,
      rgba(72, 45, 45, 0.46) 14.286%,
      rgba(102, 42, 45, 0.46) 14.286%,
      rgba(102, 42, 45, 0.46) 28.572%,
      rgba(131, 39, 44, 0.46) 28.572%,
      rgba(131, 39, 44, 0.46) 42.858%,
      rgba(161, 36, 44, 0.46) 42.858%,
      rgba(161, 36, 44, 0.46) 57.144%,
      rgba(191, 33, 43, 0.46) 57.144%,
      rgba(191, 33, 43, 0.46) 71.43%,
      rgba(220, 30, 43, 0.46) 71.43%,
      rgba(220, 30, 43, 0.46) 85.716%,
      rgba(250, 27, 42, 0.46) 85.716%,
      rgba(250, 27, 42, 0.46) 100.002%
    ),
    linear-gradient(
      127deg,
      rgb(254, 36, 223) 0%,
      rgb(254, 36, 223) 14.286%,
      rgb(227, 44, 210) 14.286%,
      rgb(227, 44, 210) 28.572%,
      rgb(201, 52, 198) 28.572%,
      rgb(201, 52, 198) 42.858%,
      rgb(174, 60, 185) 42.858%,
      rgb(174, 60, 185) 57.144%,
      rgb(147, 67, 172) 57.144%,
      rgb(147, 67, 172) 71.43%,
      rgb(121, 75, 160) 71.43%,
      rgb(121, 75, 160) 85.716%,
      rgb(94, 83, 147) 85.716%,
      rgb(94, 83, 147) 100.002%
    ); */
  align-items: stretch;
}
.calender-vcard > section:nth-child(1) {
  flex: 1;
  display: flex;
  margin-bottom: auto;
  flex-direction: column;
  margin-top: 2rem;
  padding: 1rem;
}
.calender-vcard > section:nth-child(2) {
  flex: 2;
  background: #efece3;
  /* height: 100vh; */
}
.cal-small-box {
  border: 1px solid white;
  border-radius: 5px;
  padding: 0.5rem;
  text-align: center;
  background: white;
  margin-top: 1rem;
  width: fit-content;
}
.cal-small-box > p:nth-child(1) {
  background: gainsboro;
  width: 100%;
  padding: 12px 14px;
  border-radius: 5px;
}
.cal-small-box > p:nth-child(2) {
  font-size: 13px;
  font-weight: bold;
  margin-top: 0.5rem !important;
}
.box-rd {
  height: fit-content;
  border-radius: 23px;
  padding: 2rem;
  text-align: start;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.gray-box {
  border: 1px solid gray;
  background: #dcdcdc47;
  border-radius: 14px;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.gray-box:hover {
  background-color: #f3f3f3;
  background-image: linear-gradient(
    90deg,
    #f3f3f3 25%,
    #e6e6e6 37%,
    #f3f3f3 63%
  );
  background-size: 200% 100%;
  animation: skeleton-loading 1.5s ease-in-out infinite;
}

@keyframes skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: -200% 50%;
  }
}
.booking-container {
  /* transition: all 0.5s ease-in; */
  position: absolute;
  top: 0;
  animation: booking-slot 0.1s ease-in;
}

@keyframes booking-slot {
  from {
    top: 60%;
  }
  to {
    top: 0%;
  }
}
.f-1 {
  flex: 1;
}
.cal-scroll {
  list-style: none;
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  overflow: scroll;
  padding: 0;
  scroll-behavior: smooth;
  scrollbar-width: none;
  margin: 0;
}
.cal-scroll::-webkit-scrollbar {
  width: 6px;
  height: 3px;
}
.gray-box-1 {
  border: 1px solid gray;
  border-radius: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}
.calender-shadow-box {
  right: 41px;
  display: flex;
  align-items: center;
  background: pink;
  position: absolute;
  padding: 4px 9px;
  border-radius: 12px;
  gap: 7px;
  display: flex;
}
.calendar-wrapper-an {
  animation: cal-wrap 0.4s ease-in-out;
  margin-top: 1rem;
}
@keyframes cal-wrap {
  from {
    margin-top: 50%;
  }
  to {
    margin-top: 0%;
  }
}
.cal-plans {
  border-radius: 21px;
  border-top: 23px solid brown;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
.cal-label label {
  font-size: 13px;
  color: gray;
}
.glass-shadow {
  background-image: repeating-linear-gradient(
    45deg,
    rgb(241, 241, 241) 0px,
    rgb(241, 241, 241) 144px,
    rgb(207, 207, 207) 144px,
    rgb(207, 207, 207) 288px,
    rgb(224, 224, 224) 288px,
    rgb(224, 224, 224) 432px
  );
}
.box-shadow-v1 {
  border-radius: 30px !important;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
.week-wrap {
  max-height: 4rem;
  transition: max-height 0.5s ease-in;
  overflow: hidden;
}
.week-wrap.active {
  max-height: 26rem;
  overflow: scroll;
}
.w-28 {
  width: 28px !important;
}
.fit-content {
  width: fit-content;
}
.cal-war {
  cursor: pointer;
  position: absolute;
  right: -56px;
  top: -11px;
  color: white;
  padding: 11px 9px;
  width: 7rem;
  text-align: center;
  border-radius: 50%;
  font-size: 14px;
  font-weight: bold;
  scale: 1;
  transition: all 0.2s ease-in;
  background-image: radial-gradient(
      circle at 85% 1%,
      hsla(190, 0%, 93%, 0.05) 0%,
      hsla(190, 0%, 93%, 0.05) 96%,
      transparent 96%,
      transparent 100%
    ),
    radial-gradient(
      circle at 14% 15%,
      hsla(190, 0%, 93%, 0.05) 0%,
      hsla(190, 0%, 93%, 0.05) 1%,
      transparent 1%,
      transparent 100%
    ),
    radial-gradient(
      circle at 60% 90%,
      hsla(190, 0%, 93%, 0.05) 0%,
      hsla(190, 0%, 93%, 0.05) 20%,
      transparent 20%,
      transparent 100%
    ),
    radial-gradient(
      circle at 79% 7%,
      hsla(190, 0%, 93%, 0.05) 0%,
      hsla(190, 0%, 93%, 0.05) 78%,
      transparent 78%,
      transparent 100%
    ),
    radial-gradient(
      circle at 55% 65%,
      hsla(190, 0%, 93%, 0.05) 0%,
      hsla(190, 0%, 93%, 0.05) 52%,
      transparent 52%,
      transparent 100%
    ),
    linear-gradient(135deg, rgb(37, 56, 222), rgb(96, 189, 244));
  /* background-image: linear-gradient(205deg, #000940 0%, #a00030 100%); */
}
.cal-war:hover {
  scale: 1.1;
}
.info-box {
  border-radius: 5px;
  padding: 5px;
  font-size: 10px;
  font-weight: 600;
  border: 1px solid #8080807d;
}
.info-box > svg {
  width: 14px;
  height: auto;
  margin-right: 7px;
}
.nomini {
  position: absolute;
  right: 0;
  z-index: 100;
  animation: cal-war 1s infinite ease-in;
  cursor: pointer;
}

@keyframes cal-war {
  25% {
    scale: 1;
  }
  50% {
    scale: 1.1;
  }
  75% {
    scale: 1;
  }
}

.nomini > img {
  width: 98px;
  height: auto;
  position: relative;
}

/* Togle button */

.toggle-label {
  position: relative;
  display: block;
  width: 60px;
  height: 30px;
}

.toggle-label > input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-label > span {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.3s;
  cursor: pointer;
  background: #a00030;
  border-radius: 30px;
}
.toggle-label > span:before {
  position: absolute;
  content: "";
  height: 25px;
  width: 25px;
  left: 3px;
  bottom: 2.6px;
  background-color: #fff;
  border-radius: 50%;
  transition: 0.3s;
}
.toggle-active:after {
  position: absolute;
  content: "ON";
  font-size: 12px;
  bottom: 5px;
  left: 6px;
  color: white;
  font-weight: bold;
}
.toggle-un-active:after {
  position: absolute;
  content: "OFF";
  font-size: 12px;
  bottom: 5px;
  left: 30px;
  right: 0;
  color: white;
  font-weight: bold;
}
.toggle-v2-active:after {
  position: absolute;
  content: "YES";
  font-size: 12px;
  bottom: 5px;
  left: 6px;
  color: white;
  font-weight: bold;
}
.toggle-v2-un-active:after {
  position: absolute;
  content: "NO";
  font-size: 12px;
  bottom: 5px;
  left: 30px;
  right: 0;
  color: white;
  font-weight: bold;
}
.toggle-label > input:checked + span {
  background-color: #17a2b8;
}

.toggle-label > input:checked + span:before {
  transform: translateX(29px);
}

.cal-res-1 {
  padding-left: 3rem;
}
.cal-text-area {
  width: 21rem;
  margin-left: auto;
  margin-right: 21px;
  border: 1px solid gray;
  margin-top: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  height: 43px;
}
.invalid-url {
  border-radius: 6px;
  border: 2px solid red;
}
.valid-url {
  border-radius: 6px;
  border: 2px solid green;
}

.checkbox-wrapper-26 * {
  -webkit-tap-highlight-color: transparent;
  outline: none;
}

.checkbox-wrapper-26 input[type="checkbox"] {
  display: none;
}

.checkbox-wrapper-26 label {
  --size: 20px;
  --shadow: calc(var(--size) * 0.07) calc(var(--size) * 0.1);

  position: relative;
  display: block;
  width: var(--size);
  height: var(--size);
  margin: 0 auto;
  background-color: #f72414;
  border-radius: 50%;
  box-shadow: 0 var(--shadow) #ffbeb8;
  cursor: pointer;
  transition: 0.2s ease transform, 0.2s ease background-color,
    0.2s ease box-shadow;
  overflow: hidden;
  /* z-index: 1; */
}

.checkbox-wrapper-26 label:before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  width: calc(var(--size) * 0.7);
  height: calc(var(--size) * 0.7);
  margin: 0 auto;
  background-color: #fff;
  transform: translateY(-50%);
  border-radius: 50%;
  box-shadow: inset 0 var(--shadow) #ffbeb8;
  transition: 0.2s ease width, 0.2s ease height;
}

.checkbox-wrapper-26 label:hover:before {
  width: calc(var(--size) * 0.55);
  height: calc(var(--size) * 0.55);
  box-shadow: inset 0 var(--shadow) #ff9d96;
}

.checkbox-wrapper-26 label:active {
  transform: scale(0.9);
}

.checkbox-wrapper-26 .tick_mark {
  position: absolute;
  top: -1px;
  right: 0;
  left: calc(var(--size) * -0.05);
  width: calc(var(--size) * 0.6);
  height: calc(var(--size) * 0.6);
  margin: 0 auto;
  margin-left: calc(var(--size) * 0.14);
  transform: rotateZ(-40deg);
}

.checkbox-wrapper-26 .tick_mark:before,
.checkbox-wrapper-26 .tick_mark:after {
  content: "";
  position: absolute;
  background-color: #fff;
  border-radius: 2px;
  opacity: 0;
  transition: 0.2s ease transform, 0.2s ease opacity;
}

.checkbox-wrapper-26 .tick_mark:before {
  left: 0;
  bottom: 0;
  width: calc(var(--size) * 0.1);
  height: calc(var(--size) * 0.3);
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.23);
  transform: translateY(calc(var(--size) * -0.68));
}

.checkbox-wrapper-26 .tick_mark:after {
  left: 0;
  bottom: 0;
  width: 100%;
  height: calc(var(--size) * 0.1);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.23);
  transform: translateX(calc(var(--size) * 0.78));
}

.checkbox-wrapper-26 input[type="checkbox"]:checked + label {
  background-color: #07d410;
  box-shadow: 0 var(--shadow) #92ff97;
}

.checkbox-wrapper-26 input[type="checkbox"]:checked + label:before {
  width: 0;
  height: 0;
}

.checkbox-wrapper-26 input[type="checkbox"]:checked + label .tick_mark:before,
.checkbox-wrapper-26 input[type="checkbox"]:checked + label .tick_mark:after {
  transform: translate(0);
  opacity: 1;
}
.small-box {
  border: 0.5px solid #b5b5b5;
  padding: 8px;
  border-radius: 7px;
  cursor: pointer;
}

.cal-v2-wrap {
  background: white;
  margin-top: 2rem;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  overflow: hidden;
}
.cal-p {
  padding: 3rem 2.5rem;
}
.cal-drawer {
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 0;
  background: #b0b0b091;
}
.cal-drawer > li {
  list-style: none;
  display: flex;
  flex-direction: column;
  border-top: 1px solid gray;
  padding: 0.2rem 2.1rem;
  background: #b0b0b091;
}
.cal-drawer span {
  border: 1px solid gray;
  border-radius: 50%;
  text-align: center;
}
.cal-drawer svg {
  width: 27px;
  height: auto;
}
.cal-dot-border {
  height: auto;
  border: 3px solid gray;
  padding: 12px;
  border-radius: 21px;
  border-style: dotted;
}
.cal-box {
  height: 4rem;
  transition: all 0.5s ease-in-out;
  overflow: hidden;
}
.cal-box.open {
  height: 8rem;
}
.arrowIcon-cal {
  rotate: 0deg;
  transition: all 0.5s ease-in-out;
}
.arrowIcon-cal.active {
  rotate: 180deg;
}
.cal-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr)); */
  gap: 1rem;
  margin: 1rem;
}
.cal-grid > :last-child {
  grid-column: 1/-1;
}
.cal-full {
  grid-column: 1/-1;
}
.cal-grid > section {
  width: 100%;
}
.cal-border-c {
  padding: 6px;
  font-weight: bold;
  font-size: 19px;
  width: 13rem;
  text-align: center;
  border-right: 1px solid gray;
  border-top-right-radius: 26%;
  overflow: hidden;
  background: #607d8b6e;
  display: flex;
  align-items: center;
}
.booking-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
  gap: 2rem;
}
.css-b62m3t-container {
  z-index: 100;
}
.z-1000 {
  z-index: 1000;
}
.z-1001 {
  z-index: 1001;
}
.z-1002 {
  z-index: 1002;
}
.radio-btn {
  width: 1rem !important;
  height: auto;
}
