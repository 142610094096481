.groupPost-card-body {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.25rem 1.25rem;
}
.groupPost-card {
    position: relative;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.09);
    border-radius: 0.4rem;
}

.groupPost-mb-3 {
    margin-bottom: 1rem !important;
}
.groupPost-d-flex {
   
    display: flex !important;
}

.groupPost-avatar-xs {
    height: 2.1875rem;
    width: 2.1875rem;
}
.groupPost-avatar {
    position: relative;
    display: inline-block;
    flex-shrink: 0 !important;
}
.groupPost-me-2 {
    margin-right: 0.5rem !important;
}


.groupPost-avatar-img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}
.groupPost-rounded-circle {
    border-radius: 50% !important;
}
.groupPost-img {
    max-width: 100%;
}

.groupPost-w-100 {
    width: 100% !important;
}

.groupPost-pe-4 {
    padding-right: 1.5rem !important;
}

.groupPost-border-0 {
    border: 0 !important;
}
.groupPost-form-control {
    display: block;
    width: 100%;
    padding: 0.5rem 1rem;
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 1.5;
    color: #29292e;
    background-color: #fff;
    background-clip: padding-box;
   
    appearance: none;
    border-radius: 0.4rem;
  }

  .groupPost-nav-stack {

    display: flex !important;
    flex-direction: row;
    align-items: center;
    align-self: stretch;
    gap: 0.7rem;
}

.groupPost-fw-normal {
    font-weight: 500 !important;
}
.groupPost-nav {

    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}
small, .groupPost-small {
    font-size: 0.875em;
}

.groupPost-nav-stack .groupPost-nav-link {
    padding-left: 0;
    padding-right: 0;
}
.groupPost-nav-pills .groupPost-nav-link {
    cursor: pointer;
    margin-bottom: 15px;
    padding: 0.5rem 1rem;
}
.groupPost-nav-pills .groupPost-nav-link {
    background: none;
    border: 0;
    border-radius: 0.4rem;
}
.groupPost-bg-light {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}
.groupPost-py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
}
.groupPost-px-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
}



.groupPost-mb-0 {
    margin-bottom: 0 !important;
}
.groupPost-nav-link {
    display: block;
    padding: 0.25rem 0.75rem;
    color: #676a79 !important;
}

.connectionSumbitLabel {
    color: #ffffff !important;
    font-size: 12px !important;
    background: linear-gradient(to right, #EF5A3E 5%, #A00030 100%);
    padding: 6px 10px !important;
    margin-left: 12px !important;
    float: right !important;
    width: 100% !important;
    border-radius: 4px !important;
  }
  

  .groupPost-nav-link:hover{
    color: #007bff !important;
}