@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@1,100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;1,100;1,300&display=swap");
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif !important;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
p,
span,
button,
input,
div,
li {
  font-family: "Poppins", sans-serif !important;
}

.screenInnerGapchat {
  /* padding: 122px 0px 113px 200px; */
  /* padding: 30px 0px 113px 207px; */
  background: #f5f5f5 0 0 no-repeat padding-box;
}
.container {
  text-align: left;
}
.toggle-switch {
  position: relative;
  width: 60px;
  height: 29px;
  display: inline-block;
  text-align: left;
  top: 16px;
  font-size: 10px;
  margin-left: 40px;
}
.rdw-image-modal-upload-option-label {
  word-break: break-all !important;
}
/* .max-width-100 */
.checkbox {
  display: none;
}
.label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid #bbb;
  border-radius: 20px;
  margin-top: 2px;
  width: 45px;
  height: 24px;
}
.inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}
.inner:before,
.inner:after {
  float: left;
  width: 50%;
  height: 36px;
  padding: 0;
  line-height: 36px;
  color: #fff;
  font-weight: bold;
  box-sizing: border-box;
}
.inner:before {
  content: "YES";

  padding-left: 10px;
  margin-top: -5px;
  background-color: #060;
  color: #fff;
}
.inner:after {
  content: "NO";
  padding-right: 10px;
  margin-top: -5px;
  background-color: #bbb;
  color: #fff;
  text-align: right;
}
.switch {
  display: block;
  width: 24px;
  margin: 5px;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 40px;
  border: 0 solid #bbb;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}
.checkbox:checked + .label .inner {
  margin-left: 0;
}
.checkbox:checked + .label .switch {
  right: 0px;
}

p {
  margin: 0px !important;
}

.flexDefault {
  display: flex;
}

.flexAlignCenter {
  align-items: center;
}

.flexJustifySpacebetween {
  justify-content: space-between;
}

body {
  background: white !important;
  margin: 0;
}
body.react-joyride-is-anchored {
  overflow: hidden;
}
img {
  max-width: 100%;
  height: auto;
}

a:focus,
div:focus,
button:focus,
input:focus,
fieldset:focus {
  outline: none;
  box-shadow: none;
}

a:hover,
a {
  color: #a00030 !important;
}

.uploadFileModelFooter button {
  width: 100%;
  font-weight: 600;
  background: #a00030 0 0 no-repeat padding-box;
  background-image: linear-gradient(90deg, #000940 0%, #a00030 100%);
  border: 1px solid #a00030;
}

/* button:hover {
  color: #065064 !important;
} */

.sendbtn {
  border-radius: 80px;
  border: none;
  border: 1px solid #a00030;
  background: #a00030 0 0 no-repeat padding-box;
  background-image: linear-gradient(90deg, #000940 0%, #a00030 100%);
  color: #fff;
  font-size: 12px;
  padding: 5px 8px;
  font-weight: bold;
  /* font-family: "nunitoRegular"; */
  font-family: "nunitoRegular", sans-serif;
  margin-left: 2%;
  width: 10%;
}

button.networkSeeMore {
  border-radius: 4px;
  width: 105px;
  background: linear-gradient(90deg, #000940 0%, #a00030 100%);
}

button,
.sendbtn {
  border: 1px solid #f97516;
  /* border-radius: 80px !important; */
  color: #fff;
  font-size: 14px !important;
  padding: 5px 15px;
  background: linear-gradient(90deg, #000940 0%, #a00030 100%);
  border-radius: 4px !important;
  /* background: #f97516 0 0 no-repeat padding-box;
  background-image: linear-gradient(to right, #f97516 5%, #f97516 100%); */
}

.cardWrapper {
  background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;
  border: 1px solid #dededf;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
}

.MuiBackdrop-root {
  backdrop-filter: blur(3px);
}

/* .fontFamilyIBMPlex {
  font-family: "IBM Plex Sans", sans-serif;
} */

.bold {
  font-weight: bold;
}

::-webkit-scrollbar {
  width: 10px;
  height: 20px;
}

::-webkit-scrollbar-track {
  border-radius: 15px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #dedede;
}

/* ============== TAG MENU =========== */

.tagSelectedMenuWrapper .MuiChip-root {
  border-radius: 4px;
  background: #f3f5f9;
  color: linear-gradient(90deg, #000940 0%, #a00030 100%);
  font-size: 11px;
  font-weight: normal;
  height: 26px;
}

.tagSelectedMenuWrapper .MuiChip-root svg {
  border: 0.5px solid #707070;
  border-radius: 100%;
  color: linear-gradient(90deg, #000940 0%, #a00030 100%);
  font-size: 20px;
}

.tagSelectedMenuWrapper .MuiChip-root .MuiChip-deleteIcon {
  color: #db2828;
  width: 20px;
  height: 20px;
}

.tagMenuFilter {
  min-width: 200px;
  border: 1px solid #c7c7c780;
  border-radius: 4px;
  padding: 5px;
}

.tagMenuFilter .tagSelectedMenus {
  max-width: 100%;
  overflow: auto hidden;
}

.tagMenuFilter .tagSelectedMenus::-webkit-scrollbar {
  display: none;
}

.TSMMenu {
  margin-right: 10px;
}

/* ====================== HEADER =============== */

header.headerWrapper {
  background: #fff;
  padding: 16px 0;
}

.logoDark img {
  height: 100px;
}

.headerIcon {
  position: relative;
  cursor: pointer;
}

.headerIcon svg {
  color: #333333;
  font-size: 26px;
  position: relative;
  top: 3px;
  /* left: -7px; */
}

.headerIcon img {
  font-size: 26px;
  position: relative;
  top: 9px;
  left: -17px;
}

/* .newMessageIconWrapper img {
  width: 22px;
  
} */

.headerIcon p {
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  background: linear-gradient(90deg, #000940 0%, #a00030 100%);
  text-align: center;
  position: absolute;
  min-width: 25px;
  min-height: 14px;
  bottom: 15px;
  left: 15px;
  padding: 2px;
  z-index: 0;
  border-radius: 50%;
}

/* .newMessageIconWrapper .headerIcon p {
  background: #6d064e;
} */

.headerUserProfile {
  /* margin-left: 50px; */
  margin-top: 7px;
  cursor: pointer;
}

.headerUserName {
  margin-left: 10px;
  margin-right: 5px;
  font-size: 18px;
  color: #333333;
}

.dropdonwCard {
  position: absolute;
  right: 0;
  top: 120px;
  box-shadow: 5px 5px 25px #00000040;
  border: 1px solid #dededebf;
  border-radius: 4px;
  background: #fff;
  z-index: 9999;
}

.dropdonwCard ul {
  padding-left: 0;
  list-style: none;
  width: 224px;
  height: 200px;
  margin: 0;
}

.dropdonwCard li a {
  color: #333333;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-size: 15px;
  /* font-weight: 600; */
  padding-left: 12px;
  padding-top: 5px;
}

.dropdonwCard li a div {
  width: 38px;
  height: 38px;
  /* background-color: #d8dadf; */
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 7px;
}

.navigationIcon {
  display: flex;
  align-items: center;
}

.dropdownIcon svg {
  font-size: 30px;
}

/* ====================== HEADER > NAVIGATION =========== */

.navMainWrapper {
  /* background: #fff;
  border-top: 1px solid #ddd; */
}

.navigationWrapper a.active {
  color: linear-gradient(90deg, #000940 0%, #a00030 100%);
  border-bottom: 3px solid;
}

.navigationWrapper a:hover {
  color: linear-gradient(90deg, #000940 0%, #a00030 100%);
}

.navigationWrapper a.active svg,
.navigationWrapper a:hover svg {
  fill: #a00030 !important;
}

.navigationWrapper {
  background: #fff;
  height: 75px;
  justify-content: flex-start;
  /*grid-gap: 90px !important;
  gap: 90px !important;*/
}

.navigationWrapper a {
  color: #263238;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 600;
  padding-left: 0;
}

.navigationWrapper a svg {
  margin-right: 6px;
}

/* =================== NOTIFICATION ================= */

.notificationWrapper {
  position: absolute;
  top: 5rem;
  right: -10rem;
  /* top: 67; */
  /* top: 74px; */
  z-index: 2000;
  width: 352px;
  max-height: 520px;
  /* left: -74px; */
  /* right: -164px; */
  background: #fff;
  box-shadow: 0px 1px 1px #00000029;
  border-radius: 4px;
}

.notificationCard > div.likedInfo {
  margin-left: 0;
}

.norfityReminderCardWrapper {
  border: 0.5px solid #33333380;
  border-top: 0;
  border-radius: 0 0 4px 4px;
}

.notifyHeader {
  background-color: #a00030;
  height: 56px;
  color: #fff;
  padding: 0 32px;
  border-radius: 4px 4px 0 0;
}

p.notifyTitle {
  font-size: 14px;
  font-weight: bold;
}

p.notifyCount {
  font-size: 14px;
}

.notifyReminder {
  background: #db28281a;
  height: 80px;
  padding: 0 32px;
  border-bottom: 1px solid #db2828ad;
}

.notifyReminderIcon svg {
  font-size: 32px;
  color: #306174;
}

.notifyReminderIcon {
  margin-right: 24px;
}

p.notifyReminderText {
  font-size: 14px;
  font-weight: 500;
  color: #242134;
  margin-bottom: 5px !important;
}

.notifyReminderGroup .MuiAvatarGroup-avatar {
  width: 32px;
  height: 32px;
  font-size: 11px;
}

.notificationCard {
  /* padding: 16px 32px; */
  padding: 10px 6px 10px 20px;
  border-bottom: 1px solid #3333331a;
  align-items: flex-start;
}

.notificationCardUserInfo {
  flex: 1;
}

p.NCUserName {
  font-size: 14px;
  color: #242134;
  font-weight: 600;
  margin-bottom: 3px !important;
  line-height: 15px;
}

p.NCType {
  font-size: 11px;
  color: #333333;
  font-weight: 500;
}

p.NCDescriptionBlue {
  color: #f97516;
  font-size: 11px;
  font-weight: 500;
}

.NCTime {
  font-size: 11px;
  color: #f97516;
  font-weight: 600;
  align-items: baseline !important;
  float: right !important;
}

.NCTimeGray {
  font-size: 11px;
  color: #9b9b9b;
  font-weight: 600;
  align-items: baseline !important;
  padding-top: 6px;
}

p.NCDescription {
  font-size: 12px;
  font-weight: 600;
  color: #333333;
}

.notificationCard > div:nth-child(1) {
  width: 48px;
  height: 48px;
  padding: 0;
  justify-content: center;
  margin-right: 16px;
}

.notificationWrapper:after,
.notificationWrapper:before {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.notificationWrapper:after {
  border-color: rgba(136, 183, 213, 0);
  border-bottom-color: #a00030;
  border-width: 20px;
  margin-left: -20px;
}

.notificationWrapper:before {
  border-color: rgba(194, 225, 245, 0);
  border-width: 38px;
  margin-left: -38px;
}

/* ======================= INNER TAB ============== */

.tabWrapper {
  background: white;
}

.MuiTab-textColorPrimary,
.OpenButton-textButton-14,
.NavigationButton-button-13,
.TodayButtonBase-button-11,
.MuiToggleButtonGroup-groupedHorizontal {
  background-image: none !important;
}

.tabs {
  background-image: linear-gradient(90deg, #000940 0%, #a00030 100%);
  margin-bottom: 10px;
}

.tabWrapper .MuiTabs-root {
  padding: 15px 50px 18px 20px !important;
  /* padding: 15px 50px 18px 0px; */
  min-height: 25px;
}

.tabWrapper .MuiTab-textColorPrimary {
  color: black !important;
  font-size: 16px;
}

.tabWrapper .MuiTab-textColorPrimary {
  min-width: 57px;
  min-height: 23px;
  padding: 0;
}

.tabWrapper .MuiTabs-indicator {
  bottom: 0;
  color: #db2828;
  /* background-color: #f97516; */
}

.tabWrapper .MuiTab-textColorPrimary {
  margin-right: 87px;
}

.MuiTab-wrapper {
  flex-direction: row !important;
  gap: 5px;
}

.MuiTab-wrapper svg {
  color: white !important;
}

/* ================ HOME > SEARCH TAB ============== */

.peopleMainWrapper {
  padding: 25px 0;
}

.boldLabel {
  font-size: 16px;
  font-weight: 600;
}

.searchHeading {
  margin-bottom: 7px;
}

/* .peopleCardWrapper img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  object-position: top center;
  border-radius: 4px 4px 0px 0px;
} */

.peopleCardInfor h2 {
  color: #333333;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0px;
}

.groupCardInfor h2 {
  color: #333333;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0px;
}
.groupCardInfor h2:hover {
  color: #a00030;
}
.groupCardInfor {
  text-align: center;
  margin-top: 15px;
  margin-bottom: 25px;
  height: 100px;
}

.peopleCardInfor {
  text-align: center;
  margin-top: 25px;
  margin-bottom: 60px;
  height: 70px;
}

.peopleCardInfor p {
  /* font-size: 12px;
  color: #b3b3b3;
  margin-bottom: 0; */
  font-size: 12px;
  color: #b3b3b3;
  margin-bottom: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 4px;
}

.groupCardInfor p {
  font-size: 12px;
  color: #b3b3b3;
  margin-bottom: 0;
}

.peopleCardAdd {
  flex-basis: 50%;
  /* background: linear-gradient(to right, #f97516 1%, #f97516 100%); */
  background: linear-gradient(90deg, #000940 0%, #a00030 100%);
  border-radius: 4px !important;
  border-radius: 0px 0px 0px 3px;
  color: #fff;
  border: 1px solid #f97516;
}

.peopleCardAddVG {
  flex-basis: 50%;
  background: #ffffff;
  border-radius: 0px 0px 0px 3px;
  color: #eeeeee;
  border: 1px solid #eeeeee;
  margin: 8px;
  text-align: center;
  padding: 12px;
}
.peopleCardRemove {
  flex-basis: 50%;
  border-top: 1px solid #dededf;
}

.peopleCardAddRemove {
  min-height: 42px;
  align-items: center;
  text-align: center;
  border-radius: 0px 0px 10px 10px;
}

.peopleCardAddRemove p {
  margin-bottom: 0;
}

.peopleCardAddReq p {
  margin-bottom: 0;
}

.peopleCardAddRemove div {
  font-size: 10px;
  font-weight: 600;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-transform: uppercase;
  border-radius: 0px 0px 10px 10px;
}

.peopleCardAddReq div {
  font-size: 10px;
  font-weight: 600;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  border-radius: 0px 0px 10px 10px;
}

.peopleCarouselWrapper .slick-slide > div {
  margin: 0 5px;
}

div.peopleCarouselWrapper
  > div
  > div.slick-list
  > div
  > div.slick-slide.slick-active.slick-current
  > div {
  margin-left: 0;
  width: 100px;
}

.slick-slide > div {
  margin: 0 0px;
  padding: 8px;
}

/* .slick-list {
  margin: 0 -10px;
} */

.peopleCarouselWrapper {
  padding: 0px 10px;
  /*padding: 0 50px 30px;*/
}

.PITitle {
  margin-bottom: 6px;
}

.peopleInvitationCardWrapper {
  background: #ffffff 0% 0% no-repeat padding-box;
  align-items: center;
  padding: 20px 15px;
  margin-bottom: 6px;
  box-shadow: rgb(17 17 26 / 10%) 0px 4px 16px, rgb(17 17 26 / 5%) 0px 8px 32px;
  border: 1px solid #f7f7f7;
  border-radius: 10px;
  cursor: pointer;
}
.peopleInvitationCardWrapper1 {
  background: #ffffff 0% 0% no-repeat padding-box;
  align-items: center;
  padding: 10px 15px 1px;
  margin-bottom: 6px;
  box-shadow: 0px 1px 3px #00000029;
  border: 1px solid #f7f7f7;
  border-radius: 2px;
}

.peopleInvitationCardWrapperPopup {
  background: #ffffff 0% 0% no-repeat padding-box;
  align-items: center;
  padding: 20px 15px;
  margin: 6px;
  box-shadow: 0px 1px 3px #00000029;
  border: 1px solid #f7f7f7;
  border-radius: 2px;
  text-align: left;
  height: 150px;
  /* padding: 22px; */
  overflow-y: scroll;
}

h2.PIuserName {
  font-size: 15px;
  font-weight: bold;
  /* color: #333333; */
  color: linear-gradient(to right, #ef5a3e 5%, #a00030 100%) !important ;
  margin-bottom: 3px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 120px;
}
p.PIFriend {
  font-size: 10px;
}

p.PImutualFriend {
  font-size: 13px;
  color: #b3b3b3;
  margin-bottom: 5px;
  line-height: 14px;
}

p.PIminutesAgo {
  font-size: 10px;
  color: #b3b3b3;
  margin-bottom: 0;
}

button.PIAcceptOutlined {
  border: 1px solid #dededf;
  background: #fff;
  color: #383838;
  margin-top: 10px;
}

.peopleInvitationUserInfo {
  flex: 1 1;
  margin: 0 10px;
}

.PICardAction button {
  /* display: block; */
  margin-right: 0;
  width: 105px;
  height: 33px;
  font-size: 12px !important;
  padding: 0 !important;
  display: flex;
  float: right !important;
  align-items: center;
  justify-content: center;
}

.PIWrapper {
  padding: 0 50px;
}

/* ============ SEARCH AUTOCOMPLETE  ==========*/

.SOptionImg img {
  width: 34px;
  height: 34px;
  border-radius: 100%;
}

.SOptionWrapper {
  align-items: center;
}

.SOptionLabel p {
  margin-bottom: 0;
  font-size: 14px;
  color: #253137;
  margin-left: 11px;
}

.SAWrapper {
  width: 100%;
  position: relative;
}

.searchWrapper {
  margin-bottom: 35px;
  padding: 0 50px;
}

label.searchLabel {
  font-size: 18px;
  color: #333333;
  margin-right: 10px;
}

.MuiOutlinedInput-notchedOutline {
  box-shadow: 1px 1px 1px #00000029;
  border: 0.5px solid #b5b5b5;
  border-radius: 4px;
}

.SOptionImg {
  margin-right: 10px;
  width: 34px;
  height: 34px;
}

.recentSearchToolbar p {
  margin-bottom: 0;
}

.recentSearchToolbar {
  background: #fff;
  padding: 17px 24px 11px;
  /* position: absolute; */
  width: 100%;
  z-index: 100;
  /* top: 40px;
  right: 0px;
  border-top: 0.5px solid #b5b5b5;
  border-radius: 4px 4px 0px 0px;
  border-right: 0.5px solid #b5b5b5;
  border-left: 0.5px solid #b5b5b5; */
}

.MuiAutocomplete-popper {
  /* top: 44px !important; */
  border-radius: 0px 0px 4px 4px;
  border-right: 0.5px solid #b5b5b5;
  border-left: 0.5px solid #b5b5b5;
  border-bottom: 0.5px solid #b5b5b5;
}

.MuiAutocomplete-paper {
  box-shadow: 0px 8px 16px #2f53971a !important;
  border-radius: 0 !important;
}

.RSClearAll p {
  color: #56accc;
  font-weight: normal;
}

/* .searchHeading {
  margin-bottom: 30px;
} */

.RSsearchSuffixWrapper {
  /* position: absolute;
  top: 290px; */
  z-index: 1301;
  background: #fff;
  width: 100%;
  padding: 5px 24px 20px;
  align-items: center;
  /* right: 0px;
  border-bottom: 0.5px solid #b5b5b5;
  border-radius: 0px 0px 4px 4px;
  border-right: 0.5px solid #b5b5b5;
  border-left: 0.5px solid #b5b5b5; */
}

.RSearchForText p {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: normal;
  color: #f97516;
}

.RSsearchSuffix {
  width: 34px;
  height: 34px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f97516;
  color: #fff;
  margin-right: 11px;
}

.RSsearchSuffix svg {
  width: 16px;
  height: 16px;
}

/* .MuiAutocomplete-noOptions {
  height: 210px;
  background: #fff;
}
.MuiAutocomplete-paper {
  height: 210px;
  background: #fff;
} */

.MuiAutocomplete-option {
  padding: 5px 16px !important;
}

.MuiAutocomplete-paper {
  margin: 0px !important;
}

/* ============== HOME > SEARCH CARD ============= */

.SCardWrapper {
  border-bottom: 1px solid #dededfbf;
  align-items: center;
  /* padding-bottom: 20px; */
  margin-bottom: 20px;
  padding: 0px 50px 20px;
}

.SCardWrapper .userImg img {
  width: 90px;
  height: 90px;
}

h2.SCuserName {
  margin-bottom: 2px;
  font-size: 18px;
  font-weight: bold;
}

p.SCmutualFriend {
  color: #b3b3b3;
  font-size: 13px;
  font-weight: normal;
  margin-bottom: 0;
}

.SCuserInfo {
  margin-left: 20px;
  flex: 1;
}

button.SCaddFriendFilled {
  font-size: 10px !important;
  text-transform: uppercase;
  padding: 0;
  width: 100px;
  height: 33px;
}

.SOptionImg img {
  width: 34px;
  height: 34px;
  border-radius: 100%;
}

.SOptionWrapper {
  align-items: center;
  margin-bottom: 10px;
}

.SOptionLabel p {
  margin-bottom: 0;
  font-size: 14px;
  color: #253137;
  margin-left: 11px;
}

button.SCRemoveFriendOutlined {
  color: #ff7272;
  font-size: 10px !important;
  font-weight: 600;
  background: #fff;
  border-color: #ff7272;
  width: 136px;
  height: 33px;
  padding: 0;
}

/* =================== HOME > PROFILE =========== */

.profieBannerWrapper {
  position: relative;
  height: 383px;
}

.profieBannerWrapper > img {
  position: absolute;
  width: 100%;
  height: 283px;
  object-fit: cover;
  /* z-index: 2; */
  top: 0;
}
.profileFlipWrapper {
  position: absolute;
  width: 100%;
  height: 283px;
  object-fit: contain;
  z-index: 2;
  top: 0;
}
.withoutProfileFlipWrapper {
  position: absolute;
  width: 100%;
  height: 283px;
  object-fit: cover;
  z-index: 2;
  top: 0;
}
.friendprofieBannerWrapper {
  position: relative;
  height: 283px;
}

.friendprofieBannerWrapper > img {
  position: absolute;
  width: 100%;
  height: 283px;
  object-fit: cover;
  /* z-index: 0; */
  border-radius: 10px 10px 0px 0px;
  top: 0;
}

.profilePictureEditProfile {
  /* z-index: 1; */
  position: relative;
  height: 100%;
  padding: 0 16px 0 80px;
  align-items: flex-end;
}

/* .profilePictureEditProfile > div:nth-child(1) {
  margin-bottom: 45px;
} */

.editProfileButton button {
  display: flex;
  align-items: center;
  font-size: 16px !important;
  font-weight: 600;
  /* background: #f97516; */
  border-radius: 9px !important;
  height: 53px;
  /* width: 192px; */
  /* padding: 0; */
  padding: 6px;
  justify-content: center;
  margin-bottom: 20px;
}

.editProfileButton button svg {
  margin-right: 10px;
}

.userDetailsWrapper {
  padding: 35px 56px 15px 40px;
}

.userInfoWrapper h2 {
  font-size: 28px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 10px;
  color: #242134;
}

p.userDesignation {
  font-size: 16px;
  color: #242134;
  margin-bottom: 5px;
}

p.useradditionalInfo {
  color: #707070;
  font-size: 14px;
  margin-bottom: 10px;
}

.pointsInfo {
  display: flex;
  width: 184px;
  height: 48px;
  align-items: center;
  border-radius: 4px 0px 0px 4px;
}

.pointsCount {
  width: 80px;
  height: 100%;
  background: #f97516;
  border: 1px solid #f97516;
  border-radius: 4px 0px 0px 4px;
  color: #fff;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pointsInfo div:nth-child(2) {
  background: #ffffff;
  border: 0.5px solid #707070;
  border-radius: 0px 4px 4px 0px;
  font-size: 12px;
  color: #f97516;
  height: 100%;
  display: flex;
  align-items: center;
  flex: 1;
}

.pointsInfo div:nth-child(2) p {
  position: relative;
  left: 15px;
  width: 70px;
}

.FriendsCardWrapper {
  box-shadow: 0px 1px 2px #00000029;
  border: 1px solid #f7f7f7;
  border-radius: 5px;
  background: #fff;
  padding: 16px 70px 16px 16px;
  align-items: center;
  margin-bottom: 25px;
}

.friendsinfo h2 {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  color: #333333;
}

.friendsinfo p {
  font-size: 13px;
  color: #b3b3b3;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.friendsinfo {
  margin-left: 18px;
  margin-top: 2px;
}

/* PROFILE 16 TO 20 */

.userInfoCardWrapper {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
  background: #fff;
  padding: 32px 45px;
  margin: 30px 25px;
}

p.UIUserName {
  font-size: 28px;
  font-weight: 600;
  color: #242134;
  line-height: 29px;
  margin-bottom: 5px !important;
}

p.UIDesignation {
  font-size: 17px;
  color: #242134;
  margin-bottom: 2px !important;
}

p.UILocation {
  font-size: 14px;
  color: #707070;
  margin-bottom: 20px !important;
}

.UILocation svg {
  font-size: 20px;
  margin-right: 8px;
}

.UiReputationCount {
  width: 80px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #999999;
  border-radius: 4px 0px 0px 4px;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
}

.UIReputationLabel {
  width: 70px;
  height: 48px;
  color: #f97516;
  font-size: 12px;
  display: flex;
  align-items: center;
  margin-left: 16px;
}

.UIDescription {
  font-size: 14px;
  color: #707070;
}

.UIDescription p {
  margin-bottom: 25px !important;
}

.UIDescription button {
  box-shadow: 0px 3px 6px #00000029;
  font-size: 13px !important;
}

.UIDescription button svg {
  font-size: 16px;
  margin-left: 3px;
}

/* ============================ COMMUNITY > TAB > ASK A QUESTIONS =============== */

.askQuestions {
  flex: 1;

  /* padding: 15px 0px 18px 50px; */
  padding: 15px 20px 18px 50px !important;
  min-height: 25px;
  text-align: right;
  color: #f97516;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.askQuestions a {
  color: #f97516;
  padding: 0;
}

.askQuestions p {
  display: inline-block;
  cursor: pointer;
}

.askQuestions_incalls {
  flex: 1;
  background: #dfe2e6;
  /* padding: 15px 0px 18px 50px; */
  padding: 15px 20px 18px 50px !important;
  min-height: 25px;
  text-align: right;
  color: #f97516;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.askQuestions_incalls a {
  color: #f97516;
  padding: 0;
}

.askQuestions_incalls p {
  display: inline-block;
  cursor: pointer;
}

.askQuestionWrapper {
  padding: 20px 56px 56px;
}

p.askAQuestionTitle {
  font-size: 32px;
  color: #263238;
  font-weight: 500;
  margin-bottom: 20px !important;
}

.askAQuestionCardWrapper {
  /* background: #f3f5f9; */
  background: #ffffff;
  box-shadow: 5px 5px 6px #0000000d;
  border: 1px solid #dededfbf;
  padding: 18px 35px 35px;
  border-radius: 10px;
}

label.formLabel {
  display: block;
  font-size: 16px;
  color: #263238;
  font-weight: 500;
  margin-bottom: 8px;
}

.askAQuestionInputGroup.inputLarge {
  margin-bottom: 25px;
  margin-top: 0;
}

.askAQuestionInputGroup input {
  border: 0.5px solid #b5b5b5;
  border-radius: 4px;
  width: 100%;
  color: #333333;
  padding: 0 22px;
  height: 40px;
}

.askAQuestionInputGroup.inputLarge input {
  height: 58px;
  font-size: 24px;
}

/* .askAQuestionInputGroup {
  margin: 24px 0;
} */

.askAQuestionBtn button {
  width: 100%;
  height: 40px;
}

p.bookACallLabel {
  margin-right: 15px !important;
}

.askAQuestionInputGroup .tagMenuFilter {
  background: #fff;
}

.rdw-link-modal-btn,
.rdw-embedded-modal-btn,
.rdw-image-modal-btn {
  background: linear-gradient(to right, #ef5a3e 5%, #a00030 100%) !important;
  border: 1px solid #f97516 !important;
  border-radius: 4px !important;
  color: #fff;
  font-size: 11px !important;
  padding: 5px 15px;
}

.rdw-link-modal-btn:disabled,
.rdw-embedded-modal-btn:disabled,
.rdw-image-modal-btn:disabled {
  background: #f97516;
  opacity: 0.5;
}

.rdw-link-modal-buttonsection .rdw-link-modal-btn:nth-child(2),
.rdw-embedded-modal-btn-section .rdw-embedded-modal-btn:nth-child(2),
.rdw-image-modal-btn-section .rdw-image-modal-btn:nth-child(2) {
  background: #fff !important;
  color: #f97516 !important;
}

.rdw-link-modal,
.rdw-embedded-modal,
.rdw-image-modal {
  height: max-content !important;
}

.rdw-link-modal-buttonsection,
.rdw-embedded-modal-btn-section,
.rdw-image-modal-btn-section {
  margin: 0 !important;
  justify-content: flex-start !important;
}

.rdw-link-modal-btn:nth-child(1),
.rdw-embedded-modal-btn:nth-child(1),
.rdw-image-modal-btn:nth-child(1) {
  margin-left: 0 !important;
}

/* =============== COMMUNITY > SUBJECTS ============ */

.subjectsWrapper {
  /* padding: 30px 30px 60px;
  background: white !important; */
}

.chatWrapper {
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  /* flex-wrap: nowrap;
  overflow-x: hidden;
  overflow-y: auto; */
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  height: 200px;
}

.chatWrapperMyEvent {
  /* flex-wrap: nowrap;
  overflow-x: hidden;
  overflow-y: auto; */
  height: 100px;
  margin-top: 8px;
  margin-left: 0px;
}

.chatWrapperChallenge {
  /* flex-wrap: nowrap;
  overflow-x: hidden;
  overflow-y: auto; */
  height: 100px;
  margin-top: 8px;
  margin-left: 20px;
}

p.titleLarge {
  font-size: 32px;
  color: #263238;
  font-weight: 500;
  margin-bottom: 10px !important;
}

.subjectInput .MuiOutlinedInput-input {
  padding: 10px 15px 10px 0px;
}

.subjectInput fieldset {
  border: 1px solid #c7c7c780;
  border-radius: 4px;
  box-shadow: none;
}

.toggleButtonWrapper {
  text-align: right;
}

.toggleButtonWrapper .Mui-selected {
  background: #a00030 !important;
}

.subjectInput .MuiInputAdornment-positionStart svg {
  color: #c9c9c9;
}

.creategroupButton {
  /* style={{ paddingLeft: "90%", paddingTop: "30" } */
  padding-left: 590px;
  padding-bottom: 50px;
}

.toggleButtonWrapper .Mui-selected {
  background: #a00030 0% 0% no-repeat padding-box;
  color: #fff !important;
}

.toggleButtonWrapper button {
  font-size: 15px !important;
  color: #a00030 !important;
  border-radius: 0 !important;
  padding: 10px 0px 10px 0px !important;
  text-transform: capitalize;
}

.subjectCardWrapper {
  border: 1px solid #ececec;
  border-radius: 4px;
  padding: 16px 24px 25px;
  cursor: pointer;
}

.upcomingCardWrapper {
  border: 1px solid #f7f7f7;
  background: #ffffff;
  border-radius: 4px;
  padding: 16px 16px 16px;
  cursor: pointer;
  margin-right: 20px;
  box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.05);
}

.insideupcomingCardWrapper {
  border: 1px solid #e4e4e4;
  padding-bottom: 40px;
}

.insideupcomingCardWrapperPS {
  border: 1px solid #e4e4e4;
}

p.engageLabel {
  font-size: 11px;
  font-weight: 300;
  color: #333333;
  margin-bottom: 10px !important;
}

.subjectTitle {
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

p.subjectName {
  font-size: 20px;
  font-weight: 600;
  color: #f97516;
}

.subjectTitleUE {
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.subjectTitleGroups {
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 10px;
}

p.subjectNameUE {
  font-size: 20px !important;
  font-weight: 600 !important;
  color: #000000 !important;
  padding: 12px !important;
}

.followCount {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.followCountBg {
  width: 24px;
  height: 24px;
  background: #999999;
  border-radius: 4px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

p.followFriends {
  font-size: 11px;
  font-weight: 500;
  color: #333333;
  line-height: 12px;
}

p.questionWeeks {
  font-size: 11px;
  color: #333333;
  font-weight: 500;
}

/* ================ COMMUNITY CARD ================ */

.communityCardWrapper {
  margin-bottom: 24px;
}

.upvotesCount {
  flex-basis: 72px;
  height: 54px;
  background: #21ba45;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 24px;
}

.upvotesQuestion {
  flex: 1;
}

p.upvotesCountLabel {
  font-size: 20px;
  line-height: 21px;
  margin-bottom: 2px !important;
  font-weight: 500;
}

p.upvotesLabel {
  font-size: 12px;
  line-height: 13px;
}

.upvotesQuestion p {
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
}

.upvotesTitle {
  margin-bottom: 14px;
  align-items: center;
  padding: 24px 24px 0;
}

.askedonTagWrapper > p:nth-child(1) {
  font-size: 14px;
  font-weight: 500;
  color: #999999;
}

.askedonTagWrapper > p:nth-child(1) > span {
  color: #242134;
  margin-left: 12px;
}

.askedonCard {
  background: #9999994d;
  border-radius: 4px;
  align-items: center;
  padding: 7px;
}

.askedImg img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
}

p.askedName {
  font-size: 14px;
  font-weight: 500;
  color: #242134;
}

p.askedCount {
  font-size: 14px;
  color: #24213480;
  font-weight: 500;
}

.askedInfo {
  margin-left: 8px;
}

.askedImg {
  width: 40px;
  height: 40px;
}

.askedonTagWrapper {
  width: 168px;
}

.askedTagInfoWrapper .askedonTagWrapper {
  margin-left: auto;
  margin-bottom: 24px;
}

.askedTagInfoWrapper {
  padding: 0 24px;
}

.likeCommentWrapper {
  border-top: 1px solid #f7f7f7;
  border-bottom: 1px solid #f7f7f7;
  padding: 11px 25px;
}

.borderLineUE {
  border-top: 1px solid #e4e4e4;
}
.askedTagLikeCommentWrapper {
  margin-bottom: 16px;
}

.likeCommentIconLabel p {
  font-size: 14px;
  font-weight: 500;
}

.likeCommentIconLabel svg {
  font-size: 24px;
  margin-right: 5px;
}

.likeCommentIconLabel {
  align-items: center;
  cursor: pointer;
}

.likedInfo {
  align-items: center;
  margin-left: 20px;
}

.likedIconGroups .MuiAvatar-root {
  width: 17px;
  height: 17px;
}

.likedIconGroups .MuiAvatar-root svg {
  font-size: 9px;
}

.likedUserNames {
  font-size: 13px;
  font-weight: 500;
}

.wallButtonWrapper button {
  width: 100%;
  height: 40px;
  font-size: 13px !important;
  text-transform: uppercase;
}

.wallButtonWrapper {
  padding: 0 24px 24px;
}

.communityCardQuestion {
  padding: 24px;
  align-items: flex-start;
  background-color: #f5f5f5 0 0 no-repeat padding-box !important;
}

p.AYCMinsAgo {
  font-size: 13px;
  color: #b3b3b3;
  margin-bottom: 3px;
}

h2.CCTitle {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}

.CCTitle span {
  color: #999999;
}

.communityCardUserImg {
  margin-right: 16px;
}

.likedIcon {
  width: 17px;
  height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}

.like {
  background: #21ba45 !important;
}

.favorite {
  background: #db2828 !important;
}

.likedIcon svg {
  font-size: 9px;
  color: #fff;
}

.CCnoUserFound {
  width: 64px;
  height: 64px;
}

.CCnoUserFound svg {
  font-size: 64px;
  color: #db2828;
}

p.communityCardDescription {
  font-size: 13px;
  color: #33333380;
  font-weight: 300;
  margin-top: 18px !important;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.badgeWrapper {
  display: inline-block;
  margin-right: 8px;
  background: #f3f5f9;
  border-radius: 4px;
  font-size: 14px;
  color: #242134;
  padding: 5px 10px;
}

.CCtitleGrey {
  color: #82b0c5;
}

.badgeMainWrapper {
  margin-top: 12px;
}

.wallMainWrapper {
  padding: 25px 0px;
}

p.loadmoreText {
  font-size: 12px;
  color: #333333;
  text-align: center;
  font-weight: 500;
  cursor: pointer;
}

.suggestedCard {
  padding: 20px 24px 17px;
  border-bottom: 0.5px solid #dededf;
}

p.suggCardTitle {
  font-size: 11px;
  font-weight: 300;
  margin-bottom: 10px !important;
}

.suggSubjectName p {
  font-size: 20px;
  color: #242134;
  font-weight: 600;
}

.suggSubjectName {
  margin-bottom: 10px;
}

.followIcon {
  background: #f97516;
  color: #fff;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.followIcon svg {
  font-size: 14px;
}

.suggFriendsCount {
  width: 24px;
  height: 24px;
  background: #999999;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
  margin-right: 5px;
}

p.followText {
  font-size: 11px;
  color: #333333;
  font-weight: 500;
}

.suggFriendsWrapper {
  align-items: center;
  margin-bottom: 24px;
}

p.SFQuestionInWeek {
  font-size: 11px;
  font-weight: 500;
  color: #333333;
}

.followIconButton {
  align-items: center;
  justify-content: center;
  padding: 13px 0 11px;
}

.followIconButton p {
  font-size: 14px;
  color: #242134;
  font-weight: 600;
}

.followIconButton svg {
  font-size: 16px;
  color: #000000;
  margin-right: 5px;
}

.suggestedCardWrapper {
  margin-bottom: 24px;
}

.eventUserName {
  font-size: 13px;
  color: #333333;
  font-weight: 500;
  margin-left: 12px;
}

.eventUserName span {
  color: #999999;
}

.sessionCardWrapper {
  background: #f97516;
  box-shadow: 0px 3px 20px #00000036;
  border-radius: 4px;
  padding: 16px 24px 25px;
  margin-bottom: 24px;
}

.todayText {
  color: rgba(255, 255, 255, 0.5);
  font-size: 13px;
  font-weight: bold;
}

.moreItemsIcon svg {
  color: #fff;
}

.sessionCardToolbar {
  margin-bottom: 17px;
}

p.sessionName {
  font-size: 21px;
  font-weight: bold;
  color: #ffffff;
}

p.sessionUserName {
  font-size: 15px;
  font-weight: 500;
  color: #fff;
}

.sessionCardUserInfo {
  margin-bottom: 20px;
}

.sessionCardEventDate,
.sessionCardEventTime {
  font-size: 15px;
  color: #fff;
}

.sessionCardEventDateIon svg,
.sessionCardEventTimeIon svg {
  font-size: 16px;
  color: #fff;
}

.sessionCardEventDateIon,
.sessionCardEventTimeIon {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.joinSessionBtn {
  margin-top: 34px;
}

.joinSessionBtn button {
  width: 100%;
  background: #ffffff;
  color: #f97516;
  font-size: 13px;
  font-weight: 600;
  height: 36px;
}

.suggestedCard {
  padding: 20px 24px 17px;
  border-bottom: 0.5px solid #dededf;
}

p.suggCardTitle {
  font-size: 11px;
  font-weight: 300;
  margin-bottom: 10px !important;
}

.suggSubjectName p {
  font-size: 20px;
  color: #242134;
  font-weight: 600;
}

.suggSubjectName {
  margin-bottom: 10px;
}

.followIcon {
  background: #f97516;
  color: #fff;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.followIcon svg {
  font-size: 14px;
}

.suggFriendsCount {
  width: 24px;
  height: 24px;
  background: #999999;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(255, 255, 255);
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
  margin-right: 5px;
}

p.followText {
  font-size: 11px;
  color: #333333;
  font-weight: 500;
}

.suggFriendsWrapper {
  align-items: center;
  margin-bottom: 24px;
}

p.SFQuestionInWeek {
  font-size: 11px;
  font-weight: 500;
  color: #333333;
}

.followIconButton {
  align-items: center;
  justify-content: center;
  padding: 13px 0 11px;
}

.followIconButton p {
  font-size: 14px;
  color: #242134;
  font-weight: 600;
}

.followIconButton svg {
  font-size: 16px;
  color: #000000;
  margin-right: 5px;
}

.suggestedCardWrapper {
  margin-bottom: 24px;
}

.bdayUpdates {
  padding: 16px 12px 0px;
  margin-bottom: 24px;
}

.eventUserName {
  font-size: 13px;
  color: #333333;
  font-weight: 500;
  margin-left: 12px;
}

.eventUserName span {
  color: #999999;
}

.sessionCardWrapper {
  background: #f97516;
  box-shadow: 0px 3px 20px #00000036;
  border-radius: 4px;
  padding: 16px 24px 25px;
  margin-bottom: 24px;
}

.todayText {
  color: rgba(255, 255, 255, 0.5);
  font-size: 13px;
  font-weight: bold;
}

.moreItemsIcon svg {
  color: #fff;
}

.sessionCardToolbar {
  margin-bottom: 17px;
}

p.sessionName {
  font-size: 21px;
  font-weight: bold;
  color: #ffffff;
}

p.sessionUserName {
  font-size: 15px;
  font-weight: 500;
  color: #fff;
}

.sessionCardUserInfo {
  margin-bottom: 20px;
}

.sessionCardEventDate,
.sessionCardEventTime {
  font-size: 15px;
  color: #fff;
}

.sessionCardEventDateIon svg,
.sessionCardEventTimeIon svg {
  font-size: 16px;
  color: #fff;
}

.sessionCardEventDateIon,
.sessionCardEventTimeIon {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.joinSessionBtn {
  margin-top: 34px;
}

.joinSessionBtn button {
  width: 100%;
  background: #ffffff;
  color: #f97516;
  font-size: 13px;
  font-weight: 600;
  height: 36px;
}

.becameFriendsCardWrapper {
  background: #ffffff;
  border: 1px solid #ececec;
  border-radius: 4px;
}

.becameFriendsHeading {
  border-bottom: 0.5px solid #dededf;
  text-align: center;
}

.becameFriendsHeading h3 {
  margin: 0;
  font-size: 18px;
  font-weight: 900;
  color: #333333;
  padding: 16px 0 18px;
}

.becameFriendsLists {
  padding: 16px 24px 0px;
}

.becameFriendList {
  margin-bottom: 16px;
}

.becameFriendUserName p {
  color: #999999;
  font-size: 15px;
  font-weight: bold;
}

.becameFriendUserName {
  margin-left: 16px;
}

.bookGroupCallBtn button {
  width: 100%;
  font-size: 13px !important;
  text-transform: uppercase;
  font-weight: 600;
  height: 36px;
}

.bookGroupCallBtn {
  padding: 0 24px 24px;
}

.likeOptions {
  display: flex;
  position: absolute;
  top: -35px;
  background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;
  border: 1px solid #dededf;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
  width: 70px;
  align-items: center;
  justify-content: space-evenly;
}

.likeOption svg {
  font-size: 20px;
  margin-top: 4px;
}

.likeOption {
  height: 30px;
  cursor: pointer;
}

.likeOption svg[title="Favorite"] {
  color: #db2828;
}

.likeOption svg[title="Like"] {
  color: #21ba45;
}

.likeOption svg[title="Favorite"]:hover {
  color: #db2828 !important;
}

.likeOption svg[title="Like"]:hover {
  color: #21ba45 !important;
}

.dashboardSidebar {
  border: 1px solid #ececec;
  border-radius: 4px;
  background: #fff;
}

.myQuestionsWrapper {
  margin-bottom: 32px;
}

.sidebarTitle h2 {
  font-size: 18px;
  color: #333333;
  font-weight: 900;
}

.sidebarTitle {
  padding: 16px;
  text-align: center;
  border-bottom: 1px solid #ececec;
}

.myQuestionWrapper {
  padding: 25px;
}

.myQuestion,
.RVQQuestion div {
  font-size: 16px;
  font-weight: 500;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.questionActions {
  margin-bottom: 10px;
}

.questionActions {
  font-size: 16px;
  font-weight: 500;
}

.questionActions div {
  margin-right: 15px;
}

.myQuesLikes {
  color: #21ba45;
}

.myQuesLikes svg {
  font-size: 25px;
}

.myQuesLikes .arrowBefore {
  width: 24px;
  height: 12px;
  border-left: 13px solid transparent;
  border-right: 13px solid transparent;
  border-bottom: 13px solid #21ba45;
  margin: 0;
  margin-top: 5px;
  margin-right: 3px;
}

.myQuesComments {
  color: #db2828;
}

.myQuesComments svg {
  margin-right: 3px;
}

.myQuesViews {
  color: #999999;
}

.myQuesViews svg {
  margin-right: 3px;
}

.myQuestionsPagination {
  padding: 10px 10px 10px 10px;
  margin-top: 0px !important;
  overflow-y: scroll;
}

.myQuestionsPagination nav.MuiPagination-root {
  margin-top: 0;
}

.RVQQuestion {
  padding: 25px;
}

.RVQQuestion div {
  -webkit-line-clamp: 2;
}

.paginationWrapper .MuiPagination-ul {
  justify-content: center;
}

/* ================ LOGIN SCREEN ============== */

/* ====================== LOGIN =============== */

/* .loginContainer {
  background-image: url("./images/cover_page.png");
  background-size: inherit;
  background-position: 65% 45%;
  background-repeat: no-repeat;
  height: 100vh;
  padding: 30px 0;
} */

.loginContainer {
  background-image: url("./images/cover_page.png");
  /* background-size: contain; */
  background-position: right;
  background-repeat: no-repeat;
  height: 100vh;
  padding: 30px 0;
  background-size: 100%;
}

.rightlogin {
  position: absolute;
  right: 10%;
  top: 10%;
}

.loginQuotes {
  font-size: 18px;
  font-family: inherit;
}

.loginHeaderWrapper {
  margin: 5% 0;
}

.formInput label {
  display: block;
  color: #333333de;
  font-size: 13px;
  margin-bottom: 5px;
}

.formInput input {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dededf;
  border-radius: 4px;
  width: 260px;
  height: 10px;
  padding: 15px;
  font-size: 13px;
  margin-bottom: 20px;
}

.passwordInput input {
  margin-bottom: 5px;
}

.forgotPassword {
  width: 288px;
  text-align: right;
}

.forgotPassword a {
  text-decoration: underline;
  letter-spacing: 0px;
  color: #333333de;
  font-size: 13px;
}

.formButton {
  margin-bottom: 15%;
}

.formButton .btnBlack {
  background: #f97516 0% 0%;
  border-radius: 4px;
  width: 106px;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  padding: 6px 15px;
  background-image: linear-gradient(to right, #f97516 5%, #f97516 100%);
}

.signUpLogo {
  border: 2px solid #f97516;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 15px 7px 8px;
  margin-right: 10px;
  cursor: pointer;
  font-size: 12px;
}

.googleLogo {
  border: 2px solid #e84133;
  color: #e84133;
}

.iamLogo {
  border: 2px solid #5a5b5d;
  color: #5a5b5d;
}

.emailLogo {
  border: 2px solid #77b3d4;
  color: #77b3d4;
}

.signUpLogo p {
  margin-left: 5px;
}

.signupTxt p {
  font-size: 11px;
  margin-right: 10px;
  font-weight: bold;
}

.loginContainer .MuiContainer-root {
  max-width: 1140px;
}

/* ====================== COMMUNITY QUESTIONS ================== */

.questionVotes p {
  color: #263238;
  font-size: 24px;
  text-align: center;
}

.questionVotes small {
  color: #999999;
  font-size: 12px;
  display: block;
  text-align: center;
  margin-bottom: 10px;
}

.questionVotes svg {
  color: #21ba45;
  display: block;
  margin: 0 auto;
}

.questionVotes {
  margin: 0px 20px;
}
.questionCardWrapper {
  display: flex;
  /*align-items: center;*/
  align-items: flex-start;
  padding-bottom: 10px !important;
  /*padding-bottom: 40px;*/
  border-bottom: 1px solid rgba(179, 179, 179, 0.25);
  /*margin-bottom: 15px;*/
  /* cursor: pointer; */
}

.questionCardWrapper:hover .questionCardQuestion > p {
  color: #f97516;
}

.questionCardWrapper > div:last-child {
  /* margin-left: 35px; */
  width: 100%;
}

.questionCardQuestion p {
  color: #333333;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px !important;
}

.subjectTag {
  font-size: 11px;
  color: #242134;
  padding: 8px 14px;
  background: #f3f5f9;
  border-radius: 4px;
  display: inline-block;
  cursor: pointer;
}

.questionCardMessageWrapper {
  position: relative;
  color: #db2828;
  font-size: 14px;
}

.questionCardMessageWrapper svg {
  position: absolute;
  width: 20px;
  height: 20px;
}

.questionCardMessageWrapper span {
  position: absolute;
  top: 0;
  left: 27px;
}

.messageIconWrapper {
  display: inline-block;
  color: #db2828;
  position: relative;
}

.viewsIconWrapper {
  position: relative;
  display: inline-block;
  color: #999999;
}

.messageIconWrapper span,
.viewsIconWrapper span {
  font-size: 14px;
  position: relative;
  left: 28px;
  top: -2px;
}

.messageIconWrapper svg,
.viewsIconWrapper svg {
  width: 23px;
  height: 23px;
  position: absolute;
}

.questionCardDetailsWrapperLeft > div:first-child {
  margin-right: 50px;
}

/* .questionCardTagsWrapper .subjectTag {
  margin-right: 10px;
} */

.questionCardTagsWrapper .subjectTag {
  /* border-radius: 30px; */
  margin-right: 10px;
  /* border-style: outset; */
  border-color: gray;
  border-width: 1px;
  background-color: white;
}

.questionCardTagsWrapper {
  margin-top: 10px;
}

.questionCardDetailsWrapper {
  display: flex;
  justify-content: space-between;
}

.questionCardDetailsWrapperRight {
  margin: 0 20px;
}

.questionCardDetailsWrapperRight .askedName {
  font-size: 14px;
  margin: 0 !important;
}

.questionCardDetailsWrapperRight .askedCount {
  font-size: 14px;
  color: #24213480;
}

.questionCardDetailsWrapperRight .askedonTagWrapper p {
  margin: 0 !important;
  font-size: 13px;
}

.questionFilterInputWrapper label,
.CTInputWrapper label {
  display: block;
  font-size: 16px;
  color: #333333;
  margin-bottom: 3px;
}

.questionFilterInputWrapper {
  display: inline-block;
  margin-right: 16px;
  max-width: 200px;
}

.questionFilterInputWrapper input,
.CTInputWrapper input,
.CTInputWrapper select {
  max-width: 200px;
  border: 1px solid #c7c7c780;
  border-radius: 4px;
  padding: 5px 10px;
}

.questionsFilterWrapper {
  background: #f4f4f46f;
  padding: 20px 55px;
}

.questionWrapperTitle p {
  margin: 0 !important;
}

.questionWrapperTitle {
  /* padding: 20px 55px; */
  /* padding: 20px 0px; */
  padding: 0px 0px 20px 0px;
}

.paginationWrapper input {
  max-width: 50px;
  border: 1px solid #dededf;
  border-radius: 4px;
  padding: 5px 10px;
}

.paginationWrapper {
  display: flex;
  align-items: center;
  margin-top: 30px;
  color: #8d8d8d;
  justify-content: center;
}

.paginationWrapperRight {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.paginationWrapper nav {
  margin: 0;
}

.paginationWrapper ul li button {
  background: #e8ecef;
  border: none;
  color: #8d8d8d;
}

.paginationWrapper .MuiPaginationItem-ellipsis {
  color: #8d8d8d;
}

.paginationWrapper .MuiPaginationItem-page.Mui-selected {
  background: #f97516;
  color: #fff;
}

/* =================== ANSWER SCREEN ============ */

.answersWrapper {
  /* padding: 20px 56px; */
  padding: 0px 0px;
}

.ansBackQuestion {
  margin-bottom: 15px;
  color: #a00030 !important;
  cursor: pointer;
  align-items: center;
}

.backEventorGroup {
  margin-bottom: 8px;
  margin-top: 8px;
  color: #a00030;
  cursor: pointer;
  align-items: center;
}

.ansBackQuestion p {
  font-size: 16px;
  font-weight: 500;
  margin-left: 5px !important;
}

.ansBackQuestion svg {
  font-size: 20px;
}

p.ansCount {
  position: relative;
  font-size: 24px;
  color: #263238;
  font-weight: 500;
  text-align: center;
  padding: 10px 0;
  line-height: 25px;
}

.arrowBefore {
  width: 42px;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid #33333380;
  margin: auto;
  cursor: pointer;
}

.arrowAfter {
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #33333380;
  width: 42px;
  margin: auto;
  cursor: pointer;
}

.answerCountUserImgWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.answersWrapper .answeCountArrowGrid {
  max-width: 10.333333%;
  flex-basis: 10.333333%;
}

.answersWrapper .answeCardGrid {
  max-width: 89.666667%;
  padding-left: 0 !important;
  flex-basis: 89.666667%;
}

.ACUserNameBadge {
  border-radius: 12px;
  background-color: #c7c7c7;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  padding: 1px 7px 1px 10px;
  margin-right: 10px;
  min-height: 26px;
  max-width: max-content;
}

.ACUserName {
  align-items: center;
  margin-bottom: 15px;
}

p.ACAnsweredOn {
  font-size: 14px;
  font-weight: 600;
  color: #999;
}

.answerVideo video {
  border-radius: 8px;
}

.answerVideo {
  margin-top: 15px;
}

.ACAnsweredOn span {
  margin-left: 8px;
  color: #242134;
}

.questionAnswerCardWrapper {
  box-shadow: 5px 5px 6px #0000000d;
  border: 1px solid #dededfbf;
  border-radius: 4px;
  background: #fff;
  margin-bottom: 70px;
}

.QCHeader {
  padding: 23px 33px;
}

.QCQuestion {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px !important;
}

p.QCSeeMoreText {
  color: #999999;
  font-size: 14px;
  font-weight: 500;
  margin-right: 15px !important;
}

.QCTag {
  border-radius: 2px;
  background: #f97516;
  color: #fff;
  padding: 2px 5px;
  margin-right: 10px;
  font-size: 11px;
  font-weight: 500;
}

.QCPostedOn p {
  font-size: 14px;
  color: #999999;
  font-weight: 500;
}

.QCPostedOn span {
  color: #000;
  margin-left: 15px;
}

.QCQuestionInfoBanner {
  padding: 6px 33px;
  background: #f3f5f9;
  font-size: 14px;
  color: #999999;
  font-weight: 500;
}

.QCQuestionInfoBanner strong {
  font-weight: 500;
  color: #242134;
  margin-left: 8px;
}

.QCQuestionInfoBanner p {
  margin-right: 25px !important;
}

.QCQuestionDescribed {
  padding: 18px 33px 40px;
  font-size: 16px;
  color: #333333;
}

.QCQuestionDescribed p {
  font-size: 16px;
  font-weight: 300;
}

.QCFooter {
  padding: 0 33px 26px;
}

.shareFollowBtn button {
  font-size: 16px !important;
  font-weight: 600;
  text-transform: uppercase;
  margin-right: 15px;
  display: flex;
}

.shareFollowBtn button svg {
  font-size: 21px;
  margin-right: 2px;
}

p.ansCountInfo {
  font-size: 24px;
  color: #263238;
  margin-bottom: 14px !important;
}

.answerVerfiedIcon {
  fill: #f97516;
  margin-top: 30px;
}

.answerCardWrapper {
  border: 1px solid #dededf;
  border-radius: 4px;
  padding: 15px 19px 21px;
}

.answersGrid {
  margin-bottom: 15px !important;
}

.ACFooter {
  align-items: flex-end;
  margin-top: 25px;
}

.ACTitle {
  font-size: 16px;
  font-weight: bold;
}

p.ACAnswer {
  font-size: 16px;
  font-weight: 300;
  /* word-break: break-all; */
  word-break: normal !important;
}

.ACAskedTag > p:nth-child(1) {
  font-size: 16px;
  color: #999999;
  font-weight: 500;
}

.ACAskedTag > p:nth-child(1) span {
  color: #000;
  margin-left: 10px;
}

.ACAskedTag .askedonTagWrapper {
  margin-left: auto;
}

/* ============================ TECT EDITOR  =================== */

.DraftEditor-editorContainer {
  border: 1px solid #dededf !important;
  /* min-height: 205px; */
  background: #f2f2f233 !important;
  padding: 5px;
}

.public-DraftStyleDefault-block {
  margin: 0 !important;
}

.rdw-block-dropdown span {
  color: #000;
}

.rdw-fontsize-wrapper span {
  color: #000;
}

.rdw-fontfamily-wrapper span {
  color: #000;
}

.rdw-editor-toolbar {
  background: #99999917 !important;
  margin-bottom: 0 !important;
}

p.yourAnswer {
  font-size: 24px;
  color: #263238;
  font-weight: 500;
  margin: 33px 0 5px !important;
}

.yourAnswerBtn button {
  height: 44px;
  width: 100%;
  margin-top: 10px;
  font-size: 15px;
  font-weight: 600;
}

/* ============= CALL SCREEN ============ */

.callMainWrapper {
  background: #fff;
  padding: 46px 56px;
}

.upcomingCallCarouselWrapper p.boldLabel {
  font-size: 18px;
  color: #263238;
  text-transform: uppercase;
  padding-bottom: 32px;
}

.UCCWrapper {
  background: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  padding: 25px 16px;
  position: relative;
}

.UCCActive {
  background: #f97516;
  color: #fff;
  border: 1px solid #f97516;
}

.UCCCurrentCourse {
  background: #5dba73;
  color: #fff;
  border: 1px solid #5dba73;
}

.UCCResumeBtn {
  background: #fff;
  color: #5dba73;
}

.UCCHeader {
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 25px 0px 32px;
}

.UCCActive .UCCSessionDay {
  color: #edfffc;
}

.UCCSessionDay {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px !important;
}

.UCCSessionName {
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 10px;
}

.UCCUserName div {
  width: 18px;
  height: 18px;
  background: #f0f0f0;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999999;
  min-width: 18px;
  cursor: pointer;
  margin-left: 5px;
}

.UCCUserName svg {
  font-size: 10px;
}

.UCCUserName {
  display: flex;
  justify-content: flex-start;
  font-size: 16px;
  line-height: 17px;
  font-weight: 400;
  min-height: 56px;
  padding: 0 25px 22px 32px;
  width: 90%;
}

.UCCActive .UCCUserName div {
  background: #fff;
}

.UCCSchduleDay {
  font-size: 12px;
  padding-bottom: 10px;
}

.UCCSchduleDay svg {
  font-size: 16px;
  margin-right: 7px;
}

.UCCButtonWrapper button {
  width: 100%;
  height: 33px;
  text-transform: uppercase;
  font-size: 12px !important;
  font-weight: 600;
}

.UCCButtonWrapper {
  display: flex;
}

.UCCButtonWrapper button:nth-child(1) {
  margin-right: 16px;
}

.UCCStartBtn {
  background: #fff;
  color: #f97516;
}

.outlinedBtn {
  background: none;
  border: 1px solid #f97516;
  color: #f97516;
}

.UCCActive .outlinedBtn {
  color: #fff;
  border: 1px solid #fff;
}

.UCCUserImg .MuiAvatar-root {
  width: 42px;
  height: 42px;
  box-shadow: 0px 0px 5px #00000029;
  border: 3px solid #ffffff;
  background: #f97516;
  font-size: 14px;
  margin-left: -22px;
}

div.UCCUserImg > div > div:nth-child(1) {
  z-index: 0 !important;
}

div.UCCUserImg > div > div:nth-child(2) {
  z-index: 1 !important;
}

div.UCCUserImg > div > div:nth-child(3) {
  z-index: 2 !important;
}

.UCCScheduleTiming {
  padding: 0 25px 22px 32px;
}

.upcomingCallCarouselWrapper .slick-slide > div {
  margin: 0 5px;
}

.yourFriendsWrapper {
  background: #f3f5f9;
  margin-top: 39px;
}

.YFTitleWrapper.flexDefault.flexAlignCenter.flexJustifySpacebetween {
  border-bottom: 1px solid #dededf;
  padding: 20px 30px;
  margin-bottom: 30px;
}

p.YFTitle {
  font-size: 16px;
  font-weight: 600;
  color: #242134;
  line-height: 17px;
}

p.YFBookGroupCall {
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  color: #f97516;
  cursor: pointer;
}

.YFCardWrapper {
  padding: 35px 30px;
}

.YFSeeMoreBtnWrapper {
  padding: 0 30px 40px;
  text-align: center;
}

img.FACCoverImg {
  width: 100%;
  height: 90px;
  border-radius: 4px 4px 0px 0px;
}

.homePostPopup {
  border-radius: 10px 10px 0px 0px;
}

.FACHeader > div {
  margin: auto;
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
}

.FACWrapper {
  position: relative;
  border: 1px solid #ececec;
  border-radius: 4px;
  background: #fff;
}

.FACUserInfo {
  padding-top: 40px;
  text-align: center;
}

.FACUserName {
  font-size: 20px;
  color: #242134;
  font-weight: 600;
  padding-bottom: 3px;
  line-height: 21px;
}

.FACGender {
  font-size: 16px;
  font-weight: 600;
  color: #999999;
}

.FACBtnWrapper {
  padding: 20px 15px;
  display: flex;
}

.FACBtnWrapper button {
  width: 100%;
  padding: 0;
  display: flex;
  height: 33px;
  align-items: center;
  justify-content: center;
  font-size: 11px !important;
}

.FACBtnWrapper button svg {
  font-size: 20px;
  margin-right: 5px;
}

.FACChatBtn {
  margin-left: 10px;
}

.YFSeeMoreBtnWrapper button {
  width: 136px;
  height: 33px;
  font-size: 14px !important;
}

.yourFriendsWrapper .tabWrapper {
  background: white;
}

.yourFriendsWrapper .tabWrapper > div:nth-child(1) {
  border-bottom: 1px solid #dededf;
}

.bookACallTitleWrapper h2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.BACTitle {
  font-size: 20px;
  font-weight: 600;
  color: #000000;
}

.bookACallTitleWrapper button {
  background: #d9d9d9;
  padding: 0;
  width: 28px;
  height: 28px;
  border-radius: 100% !important;
}

.bookACallTitleWrapper button svg {
  font-size: 20px;
}

.bookACallTitleWrapper {
  padding: 20px 35px 35px !important;
}

.formGroup label {
  display: block;
  font-size: 16px;
  font-weight: 600;
  color: #999999;
  line-height: 17px;
  margin-bottom: 8px;
}

.BACContentWrapper {
  padding: 0 35px 16px !important;
}

.BACDuration {
  border: 1px solid #dededf;
  border-radius: 12px;
  width: 130px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
  color: black;
  cursor: pointer;
  margin-right: 10px;
  padding: 10px;
}

.BACDurationWrapper {
  padding: 32px 0 20px;
}

.BACDuration.BACActive {
  background: #a00030;
  color: #fff;
  border: 1px solid #a00030;
}

.BACNotes .MuiOutlinedInput-multiline {
  width: 100%;
  padding: 0;
}

.BACNotes textarea {
  width: 100%;
  padding: 15px;
}

.BACScheduleCallBtn button {
  width: 100%;
  height: 32px;
  font-size: 12px;
  text-transform: uppercase;
}

.BACScheduleCallBtn {
  padding: 16px 35px 32px !important;
}

.formGroup .toggleButtonWrapper {
  text-align: left;
}

.formGroup .toggleButtonWrapper button {
  padding: 0;
  height: 32px;
  width: 91px;
  font-size: 14px !important;
  font-weight: 300 !important;
}

.formGroup .MuiToggleButtonGroup-root {
  width: 100%;
}

.SCContentWrapper {
  text-align: center;
  padding: 0 30px 40px;
}

.SCContentWrapper div:nth-child(1) {
  margin: auto;
  margin-bottom: 40px;
}

p.SCTitle {
  font-size: 20px;
  color: #000;
  font-weight: 600;
  padding-bottom: 15px;
}

p.SCSUbTitle {
  font-size: 16px;
  color: #999999;
  font-weight: 600;
}

.SCCallBtn button {
  width: 100%;
  height: 32px;
  font-size: 12px !important;
  text-transform: uppercase;
}

.SCCallBtn {
  padding: 45px 30px 25px !important;
}

#scheduledCall {
  padding: 16px 14px !important;
}

.particiapntsTag {
  width: 100%;
  height: 88px;
  border: 1px solid #dededf;
  border-radius: 2px;
  margin-bottom: 32px;
  padding: 8px;
  overflow: hidden auto;
}

.BGCGroupName fieldset,
.BACDateTimeField fieldset,
.BACNotes fieldset,
.BGCTopicDiscussion fieldset {
  border: 1px solid #dededf;
  border-radius: 2px;
  box-shadow: none;
}

.BGCGroupName .MuiFormControl-root,
.BACDateTimeField .MuiFormControl-root,
.BGCTopicDiscussion .MuiInputBase-root {
  height: 31px;
}

.BGCGroupName .MuiInputBase-root,
.BACDateTimeField .MuiInputBase-root,
.BGCTopicDiscussion .MuiInputBase-root {
  height: 31px;
  font-size: 14px;
  color: #999999;
}

p.famousFiveLabel {
  font-size: 14px;
  font-weight: 500;
  color: #999999;
  padding: 7px 15px 5px;
}

.BGCTopicDiscussion {
  margin-bottom: 41px;
}

.SGCContentWrapper .MuiAvatar-root {
  width: 64px;
  height: 64px;
  margin-left: -25px;
  background: #f97516 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 5px #00000029;
  border: 3px solid #ffffff;
  margin-bottom: 40px;
  font-size: 24px;
  font-weight: 600;
}

.SGCContentWrapper {
  text-align: center;
}

.SGCContentWrapper .MuiAvatarGroup-root {
  margin: auto;
  width: max-content;
}

.SGCContentWrapper > div > div.MuiAvatar-root:nth-child(1) {
  z-index: 0 !important;
}

.SGCContentWrapper > div > div.MuiAvatar-root:nth-child(2) {
  z-index: 1 !important;
}

.SGCContentWrapper > div > div.MuiAvatar-root:nth-child(3) {
  z-index: 2 !important;
}

.SGCContentWrapper > div > div.MuiAvatar-root:nth-child(4) {
  z-index: 3 !important;
}

.SGCContentWrapper > div > div.MuiAvatar-root:nth-child(5) {
  z-index: 4 !important;
}

.BGCParticipants {
  margin-bottom: 32px;
}

/* Course Card */

.searchCourseCardWrapper__img img {
  width: 100%;
  height: 152px;
  object-fit: cover;
  border-radius: 4px 4px 0px 0px;
}

.searchCourseCardWrapper__content {
  padding: 15px;
  border: 0px solid #ddd;
  border-radius: 0px 0px 4px 4px;
}

.searchCourseCardWrapper__img .d-flex {
  position: relative;
  margin-top: -30px;
  justify-content: space-between;
  padding: 0px 15px;
  color: #fff;
}

.searchCourseCardWrapper {
  box-shadow: 0px 3px 6px #00000029;
  position: relative;
}

.searchCourseCardWrapper__content__title {
  color: #818181;
  font-size: 18px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden !important;
}

.searchCourseCardWrapper__content small {
  display: block;
  color: #a3a3a3;
  font-size: 13px;
}

.searchCourseCardWrapper__content__hash {
  margin-top: 10px;
  height: 120px;
  /* overflow: scroll; */
}

.searchCourseCardWrapper__content__hash span {
  color: #f97516;
  font-size: 13px;
  margin-right: 8px;
}

.searchCourseCardWrapper__title {
  font-size: 14px;
  font-weight: 600;
  /* white-space: nowrap; */
  text-overflow: ellipsis;
  overflow: hidden !important;
  white-space: break-spaces !important;
}

.searchCourseCardWrapper__duration {
  font-size: 12px;
  background: #fff;
  color: #999999;
  padding: 2px 6px;
  border-radius: 20px;
}

.searchCourseCarouselWrapper .slick-slide > div {
  margin: 0 5px;
}

.searchCourseCarouselWrapper {
  padding: 0px 50px 50px;
}

/* ================ UPCOMING CALLS NO RECORD CARD ======== */

.NUCCardWrapper {
  width: 328px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background: #f97516;
  border-radius: 4px;
  color: #fff;
  padding: 0 20px;
}

p.NUCTitle {
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 10px;
}

.NUCContent {
  font-size: 16px;
  font-weight: 500;
}

/* ==================== CLASSROOM SCREEN > COURSES ============= */

.courseTitle {
  font-size: 32px;
  font-weight: 600;
  color: #263238;
  margin-bottom: 0;
  line-height: 34px;
}

.alignLeft {
  text-align: left;
}

.alignCenter {
  text-align: center;
}

.coursesMainWrapper > h2 {
  /* padding: 22px 55px; */
  padding: 22px 0px;
}

.courseToolbarWrapper {
  background: #f4f4f46f;
  /* padding: 25px 55px; */
  /* padding: 25px 0px; */
  padding: 0px 0px 20px 0px;
}

.allCourses {
  padding: 30px 30px;
  background: white;
}

.tagsLabel {
  padding-top: 15px;
}

.CTInputWrapper input,
.CTInputWrapper select {
  width: 100%;
  max-width: 100%;
  font-size: 14px;
  min-height: 38px;
}
.CTInputwrapper label {
  padding-bottom: 10px;
  display: block;
  font-size: 16px;
  color: #333333;
  margin-bottom: 3px;
}

.CTSearchBtn {
  margin-top: 25px !important;
}

.QPSearchBtn {
  margin-top: 25px !important;
}

.allCourses .courseTitle {
  margin-bottom: 18px;
}

.peerProgramBtn button {
  width: 136px;
  height: 34px;
  font-size: 12px !important;
  text-transform: uppercase;
  font-weight: 600;
  margin-top: 32px;
}

.yuukkeProgramWrapper {
  margin-bottom: 40px;
}

.accreditedProgramsWrapper {
  margin-bottom: 40px;
}

.more_video {
  position: relative;
  display: inline-block;
  margin-left: 50px;
}
.courseCardPlayBt {
  /* width: 40px;
  height: 40px; */
  display: flex;
  /* align-items: center;
  justify-content: center; */
  background: gray;
  border-radius: 100%;
  position: absolute;
  /* left: 2px; */
  margin-bottom: 200px;
  /* top: 50px; */
  cursor: pointer;
}
.courseCardPlayBtn {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 100%;
  position: absolute;
  right: 17px;
  top: 50px;
  cursor: pointer;
}
.CompletecourseCardPlayBtn {
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 18%;
  position: absolute;
  left: 17px;
  top: 170px;
  color: #f97516;
}

.courseCardPlayBtn svg {
  color: #f97516;
  font-size: 40px;
}

.CTSearchBtn button {
  width: 96px;
  height: 32px;
  font-size: 15px !important;
  font-weight: 600;
  text-transform: uppercase;
}

.QPSearchBtn button {
  width: 96px;
  height: 32px;
  font-size: 13px !important;
  font-weight: 600;
  text-transform: uppercase;
}

/* ================== PROFILE ============= */

.disabledField .tagMenuFilter {
  pointer-events: none;
  /* background-color: #f2f2f2; */
}

.profileInfoWrapper {
  max-width: 662px;
  margin: auto;
  padding: 75px 0px 65px;
}

.profileTitle {
  font-size: 24px;
  font-weight: 500;
  color: #263238;
  padding-bottom: 10px;
}

.profileDescription {
  color: #999999;
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 55px;
}

.formFieldGroup label {
  display: block;
  font-size: 18px;
  font-weight: 500;
  color: #999999;
  margin-bottom: 5px;
  padding-right: 20px;
}

.formFieldGroup fieldset {
  box-shadow: 1px 1px 1px #00000029;
  border: 0.5px solid #b5b5b5;
  border-radius: 4px;
}

.formFieldGroup input,
.formFieldGroup select {
  padding: 5px 10px;
  font-size: 14px;
}

.MuiOutlinedInput-root {
  height: 40px;
}

.formFieldGroup button.MuiIconButton-root {
  width: 24px;
  height: 24px;
  background: #e8e8e8;
  border-radius: 100% !important;
  color: #242134;
}

.profileFormBtn {
  padding-top: 25px;
}

.profileFormBtn button {
  width: 100%;
  height: 45px;
  max-width: 294px;
  text-transform: uppercase;
  font-weight: 600;
}

.formFieldGroup.disabledField fieldset {
  /* background: #f2f2f2; */
}

.formFieldGroup .tagMenuFilter {
  box-shadow: 1px 1px 1px #00000029;
  border: 0.5px solid #b5b5b5;
  border-radius: 4px;
}

/* ============ UPDATES CARD ============ */

.updatesCardWrapper {
  max-width: 100%;
  padding: 20px;
  gap: 15px;
  align-items: center;
}

.updatesCardWrapper > div:nth-child(1) {
  width: 56px;
  border-radius: 100%;
  height: 56px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.updatesBg {
  width: 56px;
  height: 56px;
  position: absolute;
  top: 0;
  z-index: 0;
  border-radius: 100%;
  opacity: 0.16;
}

.updatesCardWrapper div:nth-child(1) svg {
  z-index: 1;
  font-size: 25px;
}

.updatesCardWrapper img {
  width: 25px;
  height: 25px;
}

.updatesCardWrapper p {
  flex: 1;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* number of lines to show */
  -webkit-box-orient: vertical;
  line-height: 18px;
}

.updatesCarouselCardWrapper {
  position: relative;
  margin-left: 12px;
}

.updatesCarouselCardWrapper .updatesCardClose {
  position: absolute;
  right: 5px;
  font-size: 16px;
  color: #999;
  top: 5px;
  cursor: pointer;
}

.slick-list {
  padding: 0 20% 0 0;
}

/* ================ SQUARE SCREEN ============== */

.updatesCarousel
  > div
  > div
  > div
  > div.slick-slide.slick-active.slick-current
  > div {
  margin-left: 0;
}

.newQuestionWrapper {
  box-shadow: 5px 5px 6px #0000000d;
  border: 1px solid #dededfbf;
  border-radius: 4px;
  background-color: #fff;
}

.postQuestionField img {
  min-width: 52px;
  height: 52px;
  box-shadow: 0px 0px 5px #00000029;
  border: 3px solid #ffffff;
  border-radius: 100%;
  margin-right: 30px;
}

.postQuestionField textarea {
  border: none;
  font-size: 14px;
  resize: none;
}

.postQuestionField textarea::placeholder {
  font-size: 17px;
  color: #999999;
  font-weight: bold;
  position: relative;
  top: 0px;
}

.postQuestionField {
  padding: 20px 20px 10px;
  border-bottom: 1px solid #dededfbf;
  align-items: center;
}

.postQuestionField textarea:focus {
  border-color: inherit;
  box-shadow: none;
}

/* .formPostMoreOption {
  align-items: center;
  justify-content: space-between;
  padding: 25px 20px;
  color: #d9d9d9;
} */

/* .formPostMoreOption .flexDefault:nth-child(1) svg {
  margin-right: 20px;
  cursor: pointer;
} */

/* .formPostMoreOption .flexDefault:nth-child(2)>svg {
  margin-right: 10px;
  cursor: pointer;
} */

.formPostMoreOption .flexDefault:nth-child(2) > div {
  width: 40px;
  height: 40px;
  /* background: #6d064e; */
  /* display: flex;
  align-items: center;
  justify-content: center; */
  color: #fff;
  border-radius: 100%;
  cursor: pointer;
}

/* .formPostMoreOption .flexDefault:nth-child(2) {
  align-items: center;
} */

.topQuestionsMainWrapper {
  margin-top: 40px;
}

.topQuestionsMainWrapper > .row {
  padding-bottom: 50px;
}

p.postUserName,
p.commentUserName {
  font-size: 17px;
  letter-spacing: 0px;
  color: #000000;
  font-weight: bold;
}

p.postUploadTime,
p.commentedTime {
  font-size: 13px;
  letter-spacing: 0px;
  color: #898989;
}

.postCardHeaderInfo {
  gap: 15px;
}

.postDescription {
  letter-spacing: 0px;
  color: #001737;
  font-size: 14px;
  padding: 0 30px 20px;
}

.postCardHeader {
  padding: 16px 30px 10px;
}

.postCardBody img {
  max-height: 465px;
  object-fit: cover;
  width: 100%;
}

.postCardFooter {
  padding: 16px 16px;
  font-size: 10px;
  letter-spacing: 0px;
  color: #001737;
  /*border-bottom: 1px solid #ddd;*/
}

.likeComment svg {
  font-size: 19px;
  margin-right: 10px;
  cursor: pointer;
}

p.commentInfo {
  padding-right: 25px;
}

.writeCommentWrapper {
  padding: 16px 42px;
}

.commentWrapper {
  padding-bottom: 20px;
}

.comment {
  gap: 15px;
}

p.comment {
  letter-spacing: 0px;
  color: #001737;
  font-size: 14px;
  padding-bottom: 6px;
}

.commentWrapper svg {
  font-size: 14px;
}

p.showMoreComments {
  letter-spacing: 0px;
  color: #f97516;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 30px;
  cursor: pointer;
}

.commentInput {
  flex: 1;
  margin: 0 16px;
  position: relative;
}

.commentInput input {
  width: 100%;
  background: #f4f2f2;
  border-radius: 28px;
  border: none;
  margin: 0;
  height: 56px;
  padding: 0 15px;
  font-size: 14px;
}

.commentActionIcons {
  position: absolute;
  top: 18px;
  right: 30px;
  color: #b2b2b2;
  gap: 20px;
}

.commentActionIcons svg {
  cursor: pointer;
  font-size: 18px;
}

.postCommentBtn button {
  background: #f97516 0% 0% no-repeat padding-box;
  width: 42px;
  height: 42px;
  border-radius: 100% !important;
  color: #fff;
}

.newFeedsWrapper {
  padding: 18px 0 30px;
}

.postCard {
  margin-bottom: 18px;
}

.updatesCarousel {
  margin-bottom: 48px;
}

.updatesCarousel .slick-track {
  margin-left: 0;
}

h3.listTitle {
  font-size: 20px;
  letter-spacing: 0px;
  color: #333333;
  font-weight: bold;
  padding: 15px 30px 20px;
  border-bottom: 1px solid #ddd;
}

.listContent {
  margin: 0px 22px 25px;
  overflow: hidden auto;
}

.friendOnline {
  letter-spacing: 0px;
  color: #001737;
  font-size: 14px;
  align-items: center;
  gap: 20px;
  /* cursor: pointer; */
  padding: 13px 0px;
}

.screenTitle {
  font-size: 30px;
  letter-spacing: 0px;
  color: #3c3c3c;
  font-weight: 600;
  margin-bottom: 12px;
}

.screenTitleEvents {
  font-size: 20px;
  letter-spacing: 0px;
  color: #3c3c3c;
  font-weight: 600;
  margin-bottom: 12px;
}

.screenTitleevents {
  font-size: 20px;
  letter-spacing: 0px;
  color: #3c3c3c;
  font-weight: 600;
  margin-bottom: 12px;
  padding-bottom: 23px;
}
.announcementImg {
  margin-left: auto;
}

.announcementWrapperOuter .listContent {
  margin: 0;
  padding: 0px 22px 25px;
}

.announcementImg img {
  width: auto;
  height: 150px;
  object-fit: cover;
}

.announcementWrapper {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
  background-color: #fff;
  align-items: center;
  margin-bottom: 11px;
}

.announcementContent {
  max-width: 270px;
  padding-left: 16px;
}

.announcementWrapperOuter {
  position: relative;
}

.announcementWrapperOuter .announcementClose {
  position: absolute;
  top: 5px;
  right: 20px;
  font-size: 16px;
  color: #999;
  cursor: pointer;
}

.announcementContent h3 {
  font-size: 15px;
  letter-spacing: 0px;
  color: #f97516;
  text-transform: uppercase;
  font-weight: bold;
}

.announcementContent p {
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
  font-size: 16px;
  font-weight: 500;
}

.badgeCardWrapper {
  background: #ffffff;
  /* box-shadow: 0px 3px 6px #00000029; */
  position: relative;
  min-height: 250px;
  /* min-height: 268px; */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 12px;
}

.badgeMainWrapper .listContent {
  margin: 0;
  padding: 20px 22px 25px;
}

.earnedOuter {
  width: 60px;
  height: 60px;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  right: -20px;
  top: -30px;
}

.earnedInner {
  width: 38px;
  height: 38px;
  background-color: #3bb54a;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 50%;
}

.badgeImgWrapper {
  width: 122px;
  height: 122px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-bottom: 30px;
}

.badgeImgInner {
  width: 103px;
  height: 103px;
  border-radius: 50%;
  display: grid;
  place-content: center;
}

.badgeImgInner img {
  max-width: 70px;
}

.badgeCardTitle {
  font-size: 12px;
  font-weight: bold;
  padding-bottom: 7px;
  color: #f97516;
}

.earnedLabel {
  font-size: 12px;
  color: #3bb54a;
}

a.badgeCardQuestion {
  text-decoration: underline;
  color: #f97516;
}

/* 
.updatesCarousel>div>div>div>div.slick-slide.slick-active.slick-current>div {
  margin-left: 0;
}

.newQuestionWrapper {
  box-shadow: 5px 5px 6px #0000000d;
  border: 1px solid #dededfbf;
  border-radius: 4px;
  background-color: #fff;
}

.postQuestionField img {
  width: 52px;
  height: 52px;
  box-shadow: 0px 0px 5px #00000029;
  border: 3px solid #ffffff;
  border-radius: 100%;
  margin-right: 30px;
}

.postQuestionField textarea {
  border: none;
  font-size: 14px;
  resize: none;
}

.postQuestionField textarea::placeholder {
  font-size: 17px;
  color: #999999;
  font-weight: bold;
  position: relative;
  top: 0px;
}

.postQuestionField {
  padding: 20px 20px 10px;
  border-bottom: 1px solid #dededfbf;
  align-items: center;
}

.postQuestionField textarea:focus {
  border-color: inherit;
  box-shadow: none;
}

.formPostMoreOption {
  align-items: center;
  justify-content: space-between;
  padding: 25px 20px;
  color: #d9d9d9;
}

.formPostMoreOption .flexDefault:nth-child(1) svg {
  margin-right: 20px;
  cursor: pointer;
}

.formPostMoreOption .flexDefault:nth-child(2)>svg {
  margin-right: 10px;
  cursor: pointer;
}

.formPostMoreOption .flexDefault:nth-child(2)>div {
  width: 40px;
  height: 40px;
  background: #6d064e;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 100%;
  cursor: pointer;
}

.formPostMoreOption .flexDefault:nth-child(2) {
  align-items: center;
}

.topQuestionsMainWrapper {
  margin-top: 40px;
}

.topQuestionsMainWrapper >.row {
  padding-bottom: 50px;
}

p.postUserName, p.commentUserName {
  font-size: 17px;
  letter-spacing: 0px;
  color: #000000;
  font-weight: bold;
}

p.postUploadTime, p.commentedTime {
  font-size: 13px;
  letter-spacing: 0px;
  color: #898989;
}

.postCardHeaderInfo {
  gap: 15px;
}

.postDescription {
  letter-spacing: 0px;
  color: #001737;
  font-size: 14px;
  padding: 0 30px 20px;
}

.postCardHeader {
  padding: 16px 30px 10px;
}

.postCardBody img {
  max-height: 465px;
  object-fit: cover;
  width: 100%;
}

.postCardFooter {
  padding: 16px 42px;
  font-size: 14px;
  letter-spacing: 0px;
  color: #001737;
  border-bottom: 0px !important;
} */

/* 
.likeComment svg {
  font-size: 19px;
  margin-right: 10px;
  cursor: pointer;
}

p.commentInfo {
  padding-right: 25px;
}

.writeCommentWrapper {
  padding: 16px 42px;
}

.commentWrapper {
  padding-bottom: 20px;
}

.comment {
  gap: 15px;
}

p.comment {
  letter-spacing: 0px;
  color: #001737;
  font-size: 14px;
  padding-bottom: 6px;
}

.commentWrapper svg {
  font-size: 14px;
}

p.showMoreComments {
  letter-spacing: 0px;
  color: #6d064e;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 30px;
  cursor: pointer;
}

.commentInput {
  flex: 1;
  margin: 0 16px;
  position: relative;
}

.commentInput input {
  width: 100%;
  background: #f4f2f2;
  border-radius: 28px;
  border: none;
  margin: 0;
  height: 56px;
  padding: 0 15px;
  font-size: 14px;
}

.commentActionIcons {
  position: absolute;
  top: 18px;
  right: 30px;
  color: #b2b2b2;
  gap: 20px;
}

.commentActionIcons svg {
  cursor: pointer;
  font-size: 18px;
}

.postCommentBtn button {
  background: #6d064e 0% 0% no-repeat padding-box;
  width: 42px;
  height: 42px;
  border-radius: 100% !important;
  color: #fff;
}

.newFeedsWrapper {
  padding: 18px 0 30px;
}

.postCard {
  margin-bottom: 18px;
}


.updatesCarousel {
  margin-bottom: 48px;
}

.updatesCarousel .slick-track {
  margin-left: 0;
}

h3.listTitle {
  font-size: 20px;
  letter-spacing: 0px;
  color: #333333;
  font-weight: bold;
  padding: 15px 30px 20px;
  border-bottom: 1px solid #ddd;
}

.listContent {
  margin: 0px 22px 25px;
  overflow: auto;
}

.friendOnline {
  letter-spacing: 0px;
  color: #001737;
  font-size: 14px;
  align-items: center;
  gap: 20px;
  padding: 13px 0px;
}

.screenTitle {
  font-size: 30px;
  letter-spacing: 0px;
  color: #3c3c3c;
  font-weight: 600;
  margin-bottom: 12px;
}

.announcementImg {
  min-width: 84px;
  height: 77px;
}

.announcementImg img {
  width: 84px;
  height: 77px;
}

.announcementWrapper {
  gap: 15px;
  padding: 30px 0px 25px;
}

.announcementWrapperOuter {
  position: relative;
}

.announcementWrapperOuter .announcementClose {
  position: absolute;
  top: 5px;
  right: 20px;
  font-size: 16px;
  color: #999;
  cursor: pointer;
}

.announcementContent h3 {
  font-size: 14px;
  letter-spacing: 0px;
  color: #6d064e;
  text-transform: uppercase;
  font-weight: bold;
}

.announcementContent p {
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
  font-size: 13px;
  font-weight: 500;
} */

/* ================== CHAT SCREEN =========== */

.chatContainer {
  box-shadow: 0px 10px 99px #0000000d;
  border-radius: 11px;
  background: #fff;
  padding: 16px 25px 16px 30px;
}

.chatListsWrapper {
  max-height: 450px;
  overflow: auto;
  padding-right: 15px;
}

.chatMainContainer {
  padding-top: 28px;
}

.chatMyUserImg {
  box-shadow: 0px 0px 5px #00000029;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  position: relative;
}

.chatMyUserImg img {
  border-radius: 50%;
  width: 55px;
  height: 55px;
  border: 2px solid #ffffff;
}

.chatActiveStatus {
  width: 13px;
  height: 13px;
  border: 3px solid #ffffff;
  opacity: 1;
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  right: 2px;
}

.chatUserDetails {
  flex: 1;
  align-items: center;
  gap: 16px;
}

.chatUserName {
  font-size: 17px;
  color: #4b5155;
  font-weight: bold;
  margin-bottom: 1.5px;
  position: relative;
  top: 5px;
}

.MuiInput-underline:before {
  border-bottom: 0 !important;
}

.MuiInput-underline:hover:not(.Mui-disabled):before {
  border: none;
}

.chatUserNameStatus .MuiInputBase-root {
  font-size: 10px;
}

.chatUserNameStatus .MuiSelect-selectMenu {
  letter-spacing: 0px;
  color: #f97516;
  font-size: 9px;
  font-weight: bold;
  padding: 0;
}

.MuiSelect-nativeInput {
  padding: 0;
}

.settingIcon {
  color: #afbbc6;
  cursor: pointer;
}

.chatUserInfoWrapper {
  padding-right: 15px;
}

.activeChats {
  align-items: center;
  justify-content: space-between;
  padding: 26px 15px 18px 0;
}

.activeChatTitle {
  margin-bottom: 0;
  font-size: 16px;
  color: #4b5155;
  font-weight: bold;
}

.activeChatPlusBtn {
  background: rgba(26, 180, 222, 0.2);
  border-radius: 5px;
  color: #f97516;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.activeChatPlusBtn svg {
  font-size: 16px;
}

.searchField .MuiFormControl-root {
  width: 100%;
  padding-right: 15px;
}

.searchField {
  padding-bottom: 25px;
}

.chatListImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: relative;
}

.chatListImg img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.chatListDetails {
  flex: 1;
}

.chatListWrapper {
  gap: 15px;
  margin-bottom: 15px;
  cursor: pointer;
}

.chatListImg .chatActiveStatus {
  right: 0px;
}

.chatListName {
  font-size: 16px;
  color: #7b8793;
  font-weight: bold;
  margin-bottom: 4px;
}

.chatListTagLine {
  font-size: 12px;
  color: #afbbc6;
}

.chatDateWrapper {
  color: #7b8793;
  font-size: 12px;
  font-weight: bold;
}

.messageMainWrapper {
  border: 1px solid #dbe5ed;
  border-radius: 12px;
  background-color: #f9fafc;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.messageHeaderWrapper {
  border-bottom: 1px solid #dbe5ed;
  border-radius: 11px;
  min-height: 67px;
  padding: 0 25px;
  background-color: #f4f5f8;
}

.messageUserNameWrapoer {
  flex: 1;
}

.callIconWrapper {
  background-color: #f97516;
  border-radius: 20px;
  color: #fff;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.callIconWrapper svg {
  font-size: 16px;
}

.messagesBodyWrapper {
  flex: 1;
  /* min-height: 450px; */
  padding-top: 20px !important;
  overflow: auto;
  /* max-height: 450px; */
}

.messageActionWrapper input {
  flex: 1;
  height: 50px;
  font-size: 14px;
  border: none;
  border-radius: 30px;
  padding-left: 15px;
}

.messageActionWrapper {
  background: #ffffff;
  border: 1px solid #dbe5ed;
  border-radius: 30px;
  align-items: center;
  padding: 5px 5px 5px 0px;
  gap: 15px;
  margin: 15px 17px;
}

.messageActionWrapper {
  font-size: 16px;
  color: #afbbc6;
}

.sendIconBtn {
  width: 38px;
  height: 38px;
  background-color: #f97516;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
}

.sendIconBtn svg {
  font-size: 16px;
}

.justifyEnd {
  justify-content: flex-end;
}

.messagesBodyWrapper {
  padding: 0 17px;
}

.MRimgWrapper {
  box-shadow: 0px 0px 5px #00000029;
  border: 2px solid #ffffff;
  width: 43px;
  height: 43px;
  border-radius: 50%;
}

.MRimgWrapper img {
  border-radius: 50%;
}

.MessageReplyWrapper {
  align-items: center;
}

.colorWhite {
  color: #fff;
  cursor: pointer;
}

.reply {
  color: #7b8793;
}

.justifyEnd .message {
  background: #afbbc6;
}

.message,
.reply {
  padding: 15px;
  background: #fff;
  border-radius: 10px 10px 0px 10px;
  font-size: 14px;
  font-weight: 500;
}

.messageWrapper {
  gap: 15px;
  margin-bottom: 15px;
  align-items: center;
}

.justifyEnd .MRTime {
  text-align: right;
}

.MRTime {
  font-size: 11px;
  color: #afbbc6;
  font-weight: 500;
  padding-top: 5px;
}

.messageTyping {
  background: #dbe5ed;
  border-radius: 10px 10px 10px 0px;
  padding: 10px 15px;
  font-size: 11px;
  color: #7b8793;
  margin: 10px 17px 0;
  max-width: 20%;
  overflow: hidden;
}

.searchField fieldset {
  border: 1px solid #dbe5ed;
  border-radius: 8px;
  box-shadow: none;
}

.searchField .MuiInputBase-root {
  background: #f9fafc;
  border-radius: 8px !important;
}

.searchField svg {
  color: #afbbc6;
}

input#searchBar {
  letter-spacing: 0px;
  color: #afbbc6;
  font-size: 14px;
  font-weight: bold;
}

.activeChat .chatListDetails h3 {
  color: #4b5155;
}

.activeChat .chatListDetails p {
  color: #7b8793;
}

.textAvatar {
  font-size: 13px !important;
  color: #f97516 !important;
  font-weight: bold;
  background-color: rgba(26, 180, 222, 0.2) !important;
}

/*--- own ---*/

.slick-track {
  float: left !important;
}

.tabWrapper .MuiTab-textColorPrimary {
  margin-right: 45px !important;
}

.backlink {
  font-size: 12px;
  color: blue;
  cursor: pointer;
  text-decoration: underline;
}

.peopleCardWrapper {
  background: #fff 0 0 no-repeat padding-box;
  border: 1px solid #dededf;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  border-radius: 10px !important;
  margin-bottom: 20px;
  width: 290px;
}

.peopleCardWrapperGroup {
  background: #fff 0 0 no-repeat padding-box;
  border: 1px solid #dededf;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  border-radius: 10px !important;
  width: auto;
}

.no-data {
  background: #fff 0 0 no-repeat padding-box;
  /* border: 1px solid #dededf;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  border-radius: 4px; */
  width: 100%;
  padding: 20px 10px;
  margin: 7px 7px;
}

.headerZeroIcon {
  position: relative;
  cursor: pointer;
}

.headerZeroIcon svg {
  color: #808080;
  font-size: 26px;
  position: relative;
  top: 9px;
  left: -17px;
}

.headerZeroIcon p {
  font-size: 12px;
  font-weight: bold;
  color: rgb(99, 97, 97);
  background: #fff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  text-align: center;
  position: absolute;
  width: 23px;
  height: 15px;
  top: 0;
}

.settingsText {
  text-align: left !important;
  color: gray;
  padding-left: 60px;
}

.settingsRow {
  margin-bottom: 20px !important;
}

.settingsLanguage {
  max-width: 50% !important;
  margin-right: 10px;
}

.arrowUpvotes {
  width: 6px;
  height: 6px;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid rgb(24, 240, 24);
  margin: auto;
  cursor: pointer;
}

.paginationDashboardmyquest {
  padding: 0 24px 24px;
  margin: 8px !important;
}

.libraryBody img,
.libraryBody video,
.libraryBody audio,
.libraryBody a {
  max-width: 100% !important;
  height: 80px !important;
  object-fit: cover;
}

.profileDialog {
  max-height: 500px !important;
  overflow: scroll;
}

.divider {
  margin: 0 15px 0 15px;
  border-right: 1px;
}

.coursesCardWrapper img {
  width: 200%;
  height: 150px;
  object-fit: cover;
  object-position: top center;
  border-radius: 4px 4px 0px 0px;
}

.coursesCardWrapper {
  background: #fff 0 0 no-repeat padding-box;
  border: 1px solid #dededf;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  border-radius: 4px;
  margin-bottom: 20px !important;
}

.courseCardInfor h2 {
  color: #333333;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.courseCardInfor {
  text-align: left;
  margin-top: 25px;
  margin-bottom: 25px;
  margin-left: 25px;
}

.courseCardInfor p {
  font-size: 12px;
  color: #b3b3b3;
  margin-bottom: 0;
}

.coursesWrapper {
  padding: 30px 55px 0px;
}

.actionIcon {
  width: 18px !important;
  height: 18px !important;
  cursor: pointer;
}

.answerInfoBanner {
  padding: 5px 5px;
  margin: -0px -25px 0px -23px;
  background: #f3f5f9;
  font-size: 14px;
  color: #999999;
  font-weight: 500;
  width: 106%;
  display: inline-flex !important;
}

.NUCCSessionName {
  font-size: 18px !important;
  font-weight: 400;
  padding-bottom: 10px;
  padding-top: 40px;
}

.groupMemberName {
  margin: 8px;
  text-align: center !important;
}

.GCCUserName div {
  width: 18px;
  height: 18px;
  background: #f0f0f0;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999999;
  min-width: 18px;
  cursor: pointer;
  margin-left: 5px;
}

.GCCUserName svg {
  font-size: 10px;
}

/* .GCCUserName {
  display: flex;
  justify-content: flex-start;
  font-size: 16px;
  line-height: 17px;
  font-weight: 400;
  padding: 0 25px 22px 32px;
  width: 90%;
} */

.GCCUserName {
  display: flex;
  justify-content: center !important;
  font-size: 16px;
  line-height: 17px;
  font-weight: 400;
  align-items: center !important;
  padding: 0px;
  width: 90%;
}

.GCCUserName p {
  display: flex;
  text-align: center !important;
  padding: 0 20px 2px 40px;
  color: #00f;
  text-decoration: underline;
  font-size: 14px;
}

.GCCActive .GCCUserName div {
  background: #fff;
}

/* .GCCUserName p {
  display: flex;
  text-align: center !important;
  padding: 0 0px 2px 40px;
  color: blue;
  text-decoration: underline;
  font-size: 14px;
} */

.UGCCUserImg .MuiAvatar-root {
  width: 42px;
  height: 42px;
  box-shadow: 0px 0px 5px #00000029;
  border: 3px solid #ffffff;
  background: #f97516;
  font-size: 14px;
  margin-left: -22px;
  margin-top: 25px;
}

div.UGCCUserImg > div > div:nth-child(1) {
  z-index: 0 !important;
}

div.UGCCUserImg > div > div:nth-child(2) {
  z-index: 1 !important;
}

div.UGCCUserImg > div > div:nth-child(3) {
  z-index: 2 !important;
}

/** -- changed --**/

.UCCSessionName {
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden !important;
  width: 200px;
}

.FACUserName {
  font-size: 20px;
  color: #242134;
  font-weight: 600;
  /* padding-bottom: 3px; */
  line-height: 21px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden !important;
  padding: 10px 20px 3px;
}

.askedonCard {
  background: #9999994d;
  border-radius: 4px;
  /*align-items: center;*/
  align-items: flex-start;
  padding: 7px;
}

.norfityReminderCardWrapper {
  border: 0.5px solid #33333380;
  border-top: 0;
  border-radius: 0 0 4px 4px;
  max-height: 300px;
  overflow: scroll;
}

.answerVerfiedIconGray {
  fill: #999999;
  margin-top: 30px;
}

/**** MODIFIED CSS ***/

.askedImg img {
  max-width: 40px;
}

/* .NCTime {
  font-size: 11px;
  color: #6d064e;
  font-weight: 600;
  align-items: baseline !important;
  float: right !important;
} */

.notify-action-icon {
  float: right !important;
  width: 20px !important;
  height: 20px !important;
  color: red !important;
  align-items: baseline !important;
  cursor: pointer !important;
}

.groupquestionCardDetailsWrapperRight {
  max-height: 300px;
  overflow: scroll;
}

.groupimgHolder {
  padding-bottom: 5px;
}

.formPostMoreOption {
  /* padding: 20px 10px 20px 10px !important; */
  color: #000000 !important;
}

/* .previewWrapper {
  margin: 20px 10px 0px 10px !important;
} */

.questionMoreOptions {
  border: #ccc 1px solid;
  /* margin-bottom: 20px; */
  border-radius: 5px;
}

.likeCommentWrapper {
  padding: 0px !important;
}

.postCardFooter {
  padding: 15px 15px !important;
}

.topLine {
  margin-top: 40px;
  border-top: 1px solid lightgray;
  width: 100%;
}

.bottomLine {
  margin-bottom: 40px;
  border-bottom: 1px solid lightgray;
  width: 100%;
}

.noMoreArticles {
  margin: 12px !important;
}

.YFGroupMoreBtnWrapper {
  padding: 0 30px 40px;
  text-align: center !important;
}

.footerWrapper {
  padding: 0 30px 40px;
  text-align: center !important;
  cursor: pointer;
  margin: 20px !important;
}

.confirmBoxHeading {
  font-size: 50px;
}

.confirmBoxHeadingCL {
  font-size: 25px;
}

.confirmBoxIcon {
  font-size: 80px !important;
  color: rgb(3, 197, 3) !important;
}

.cscheckboxUi {
  text-align: left !important;
  align-items: left !important;
}

.csbookboxform {
  /*margin: 0 250px !important;*/
  margin: 0 0% !important;
}

.cscardUi {
  border-color: #01a4ef !important;
  /*margin: 0 90px !important;*/
  margin: 0 10% !important;
  background-color: #f5f5f5 !important;
}

.contactFilterWrapper {
  background: #ffffff;
  padding: 20px 55px;
  margin-left: 50px !important;
  margin-top: 20px !important;
  margin-right: 50px !important;
  margin-bottom: 60px !important;
}

.support {
  margin: 5px 15%;
}

.peopleCardInfor h2 {
  color: #333333;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden !important;
  padding: 10px 10px;
}
#firstText {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 180px;
  display: inline-block;
}
.groupCardInfor h2 {
  color: #333333;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden !important;
  padding: 10px 20px;
}

.dropdonwCard ul {
  padding-left: 0;
  list-style: none;
  width: 224px;
  /*height: 200px;*/
  height: auto;
  /* padding-bottom: 10px; */
  margin: 0;
}

.arrowBeforeGreen {
  width: 42px;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid green;
  margin: auto;
  cursor: pointer;
}

.arrowAfterRed {
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid red;
  width: 42px;
  margin: auto;
  cursor: pointer;
}

.answerCardWrappers:hover .questionCardAnswerBlur > p {
  color: #6d064e;
}

/* p.subjectName :hover{
  color: #081b22 ;
} */

.questionCardAnswerBlur p {
  color: #acabab !important;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px !important;
  max-width: 100% !important;
}

.questionCardTagsWrapper .subjectTagClassroom {
  margin-right: 10px;
}

.chatMedia {
  width: 100%;
}

.subjectTagClassroom {
  font-size: 11px;
  color: #f3f5f9;
  padding: 8px 12px;
  background: #f97516;
  border-radius: 4px;
  display: inline-block;
}

.libraryCardType {
  color: #acabab !important;
  font-size: 14px !important;
  font-weight: 600;
  width: 104px;
}

.questionNameDisplay {
  max-width: 100% !important;
}

.typeLibraryText {
  max-width: 100% !important;
}

.ansBackUpvotes {
  border-radius: 6px;
  margin-bottom: 15px;
  color: #21ba45;
  cursor: pointer;
  align-items: center;
  background-color: #c4eece;
  position: absolute;
  right: 0px;
  padding-left: 5px;
  padding-right: 5px;
}

.ansBackUpvotes p {
  font-size: 12px;
  font-weight: 500;
  margin-left: 5px !important;
  float: right !important;
}

.ansBackUpvotes svg {
  font-size: 20px;
}

.questionCardMedia img,
video,
audio {
  margin-bottom: 12px !important;
}

/* .CTMultiWrapper label {
width: 100%;
max-width: 100%;
font-size: 14px;
min-height: 38px;
} */

.CTMultiWrapper label {
  display: block;
  font-size: 16px;
  color: #333333;
  margin-bottom: 3px;
}

.MuiGrid-item {
  align-self: flex-start !important;
}

.loading-bullet {
  display: inline-block;
  opacity: 0;
  font-size: 2em;
  color: #a00030 !important;
}

.block-ui-message {
  color: #a00030 !important;
  background: none;
  z-index: 1011;
}

.mulListData {
  color: #db2828;
}

.bookmarkIcon {
  float: left;
  padding-top: 10px;
  cursor: pointer !important;
}

.bookmarkIconyellow {
  color: #f5ed04d1 !important;
  float: left;
  padding-top: 10px;
  cursor: pointer !important;
}

.bookmarkIconDash {
  float: right;
  margin: 20px !important;
  cursor: pointer !important;
}

.bookmarkIconyellowDash {
  color: #f5ed04d1 !important;
  float: right;
  margin: 20px !important;
  cursor: pointer !important;
}

.subMenu > .MuiTabs-root {
  padding: 15px 50px 18px 0px !important;
}

.MuiToggleButton-root {
  width: 120px;
  height: 40px;
}

.QCTags.flexDefault.flexAlignCenter {
  overflow: scroll;
}

/* .hide { 
  display: none; 
}  */

/* .rdw-option-wrapper { 
  padding: 10px 0px 10px 0px !important; 
  background: transparent !important; 
  color: black !important; 
} */

p.smallText {
  font-size: 75%;
  font-weight: 400;
}

.hide {
  display: none !important;
}

.rdw-editor-toolbar {
  border-top: 6px solid black;
  padding: 0px !important;
  background: #d6d6d6 !important;
  padding-top: 5px !important;
}

.rdw-editor-toolbar {
  padding: 0px !important;
  background: #d6d6d6 !important;
  padding-top: 5px !important;
  border: 1px solid #b5b5b5 !important;
  border-bottom: 0px !important;
  border-radius: 4px 4px 0px 0px !important;
}

.rdw-option-wrapper {
  padding: 10px 0px 10px 0px !important;
  background: transparent !important;
  color: black !important;
  border: 0px solid #f1f1f1 !important;
}

.DraftEditor-editorContainer {
  /* border: 1px solid #b5b5b5 !important; */
  border-top: 0px !important;
  border-bottom: 0px !important;
  border-radius: 0px 0px 0px 0px;
  overflow: scroll;
  /* height: 4rem !important; */
  overflow-x: hidden;
  height: 250px !important;
}

.boxText {
  font-size: 75%;
  font-weight: 400;
  border: 1px solid #b5b5b5 !important;
  border-top: 0px !important;
  background: #f1f1f1 !important;
  border-radius: 0px 0px 4px 4px;
  padding: 5px 0px 5px 10px;
}

.annoucmentBox {
  /* box-shadow: 0px 0px 6px #00000029; */
  opacity: 1;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  background: white;
  border-radius: 2px;
}

.annoucmentTitle {
  text-align: left;
  /* font: normal normal medium 15px/21px Roboto; */
  font: normal normal medium 15px/21px Roboto, sans-serif;
  letter-spacing: 0px;
  text-transform: uppercase;
  opacity: 1;
  color: white;
}

.annoucmentSubTitle {
  text-align: left;
  font: normal normal medium 15px/21px Roboto, sans-serif;
  padding: 15px 0px;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
}

.annoucmentContent {
  text-align: left;
  font: normal normal medium 15px/21px Roboto, sans-serif;
  /* line-height: 2; */
  letter-spacing: 0;
  color: white;
  opacity: 1;
  display: flex;
  flex-wrap: wrap;
}

.announcementClose {
  float: right;
}

.simpleMargin {
  margin: "50px" !important;
}

.CTSearchBtn {
  margin-top: 15px;
}

/* .QPSearchBtn {
  margin-top: 15px;
} */

.CTSearchBtn button {
  width: 100%;
  height: 37px;
}

.QPSearchBtn button {
  /* width: 100%; */
  height: 37px;
}

.ChipBox {
  overflow-x: auto;
  scrollbar-width: thin;
}

.askAQuestionInputGroup {
  margin: 0px;
}

.previewWrapper {
  margin: 10px 10px 10px 10px !important;
}

.overAlluploadicons {
  font-size: 14px;
}

.formPostMoreOption {
  padding: 10px 10px 10px 0px !important;
}

.previewWrapper {
  height: auto !important;
  max-width: 180px !important;
}

.questionMoreOptions {
  border: 0px !important;
}

.fileChip {
  margin-top: 10px !important;
}

/* .pageWrapper {
  padding: 30px 55px 60px;
  background: white !important;
} */

.profileWrapper {
  padding: 0px;
  background: white !important;
}

.pageHeading {
  padding: 0px 0px 30px 0px;
  font-size: 32px;
  font-weight: 600;
  color: #263238;
  margin-bottom: 0;
  line-height: 34px;
}

.formInputsDescriptions {
  width: 100%;
  border: 1px solid gray;
  border-radius: 3px;
}

.profilePictureEditProfile > div:nth-child(1) {
  margin-bottom: 0px;
}

.upVoteWallCard > .cardWrapper {
  background: #f5fcff !important;
  border: 1px solid #f7f7f7 !important;
}

.questionWallCard > .cardWrapper {
  background: #effbf2 !important;
  border: 1px solid #e0f8e6 !important;
}

.navigationWrapper .nav-link {
  margin-right: 90px !important;
}

.version {
  padding: 10px;
  color: gray;
  font-size: 6px;
}

.commentViewAll {
  /* float:"right" !important; */
  cursor: pointer !important;
  /* position: absolute !important;  */
}

.PIWrapperNew {
  padding: 20px 0 20px 0;
}

button.PIAcceptOutlinedNew {
  border: 1px solid #dededf;
  background: #fff;
  color: #383838;
  margin-top: 0px;
}

.player {
  height: auto !important;
  width: auto !important;
}

.videoArticleWrapper {
  background: #f4f4f46f;
  padding: 20px 55px;
}

.searchCourseCardWrapper__content__title {
  white-space: break-spaces !important;
}

.feedbackBox {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border: 20px solid #f97516;
  opacity: 1;
  padding: 15px;
  width: 50%;
}

.feedbackBoxTitle {
  font: normal normal medium 15px/21px Roboto, sans-serif;
  color: #f97516;
}

.feedbackBoxIcon {
  font-size: 20px;
  color: #f97516;
  padding: 10px 0px 20px 0px;
}

.feedbackBoxText {
  font-size: 10px;
}

.feedbackIcons {
  padding: 25px 0px 25px 0px;
}

.feedbackResult {
  padding: 0px 0px 25px 0px;
  font-weight: bold;
  color: darkgreen;
}

.react-thumbnail-generator {
  border-bottom: 1px solid #f2f2f2;
  height: 220px;
}

.searchCourseCardWrapper__content__title {
  height: 60px;
}

.snapshot-generator,
.videoRender {
  display: none !important;
}

.searchCourseCard_desc {
  height: 25px;
}

.react-thumbnail-generator {
  display: none;
  margin-left: auto;
  margin-right: auto;
  width: auto;
}

.thumbnail-view > img {
  height: auto !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: auto !important;
}

.CTInputWrapper select {
  font-size: 14px !important;
  min-height: 38px !important;
  border: 1px solid #c7c7c780 !important;
  border-radius: 4px !important;
  padding: 5px 10px !important;
}

.toggleButtonWrapper button {
  padding: 10px 10px !important;
}

.smart-option {
  font-size: 12px;
  margin: 3px 10px 0px 30px;
}

.smart-option input {
  font-size: 12px !important;
  height: 12px !important;
  float: left;
  width: 15px !important;
  margin: 3px 5px 0px 0px;
}

.page-item.active .page-link {
  color: #ffffff !important;
  /* background-color: #f97516 !important; */
  background: linear-gradient(90deg, #000940 0%, #a00030 100%);
  border-color: linear-gradient(to right, #ef5a3e 5%, #a00030 100%) !important;
}

.infoButton {
  padding: 0 !important;
}

/*.page-link {
  color: #6d064e !important;
  background-color: #fff;
  border: 1px solid #6d064e;
}
a {
  color: #6d064e !important;
} */

.surveyBox {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border: 20px solid #f97516;
  opacity: 1;
  padding: 5px;
}

.roleRadio {
  font: normal normal medium 15px/18px Poppins, sans-serif;
  letter-spacing: 0px;
  padding: 2px !important;
  margin-bottom: 2px !important;
  color: #6a6a6a;

  /* text-transform: capitalize; */
  /* border: #ebe4e4;
  border: 1px solid #ebe4e4; */
}

.roleRadio label {
  padding: 5px !important;
}

.roleRadio .form-check-input {
  width: 16px;
  height: 16px;
  margin-top: 0.4rem;
}

.roleRadio .form-check {
  margin-bottom: 15px;
  cursor: pointer;
}

.surveyTitle {
  text-align: left;
  font: normal normal medium 15px/21px Roboto, sans-serif;
  letter-spacing: 0px;
  color: #0c0c0c;
  /* text-transform: uppercase; */
  opacity: 1;
  margin-bottom: 20px !important;
}

.greenLabel {
  color: #21ba45 !important;
  /* border-radius:30px !important; */
  /* padding-top:  4px !important;
  padding-bottom: 4px !important; */
  font-size: 9px !important;
  background: #c4eece !important;
  padding: 6px 10px !important;
  float: right !important;
  border-radius: 15px !important;
}

.peopleCardChat {
  flex-basis: 50%;
  border-top: 1px solid #f2f2f2;
  background: #f2f2f2;
}

.FACBtnWrapper button:hover,
.wallButtonWrapper button:hover,
.CTSearchBtn button:hover,
.QPSearchBtn button:hover,
.profileFormBtn button:hover {
  background: linear-gradient(to right, #ef5a3e 5%, #a00030 100%) !important;
}

.subjectName {
  /* color: linear-gradient(to right, #ef5a3e 5%, #a00030 100%) !important; */
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden !important;
  font-size: 20px;
  font-weight: 600;
  color: #f97516;
}

/* .peopleCardAddRemove div:hover {
  background: #6d064e !important;
} */

div.peopleCardAdd:hover {
  background: linear-gradient(to right, #ef5a3e 5%, #a00030 100%) !important;
}

div.myQuestion:hover {
  color: linear-gradient(to right, #ef5a3e 5%, #a00030 100%) !important;
}

div.peopleCardChat:hover {
  border-top: 1px solid lightgray !important;
  background: lightgray !important;
}

div.subjectName:hover {
  color: linear-gradient(to right, #ef5a3e 5%, #a00030 100%) !important;
}

div.subjectCardWrapper:hover {
  background: #f5f4f4 !important;
}

p.bookACallLabel {
  margin-right: 0px !important;
}

div.RVQQuestion:hover {
  color: #667277 !important;
}

.questionCardWrapperBlue {
  display: flex;
  /*align-items: center;*/
  align-items: flex-start;
  padding-bottom: 10px !important;
  /*padding-bottom: 40px;*/
  border-bottom: 1px solid rgba(179, 179, 179, 0.25);
  /*margin-bottom: 15px;*/

  background: #f5fcff !important;
}

.questionCardWrapperBlue:hover .questionCardQuestion > p {
  color: #f97516;
  background: #f5fcff !important;
}

.questionCardWrapperBlue > div:last-child {
  margin-left: 35px;
  width: 100%;
  background: #f5fcff !important;
}

.questionCardWrapperGrey {
  display: flex;
  /*align-items: center;*/
  align-items: flex-start;
  padding-bottom: 10px !important;
  /*padding-bottom: 40px;*/
  border-bottom: 1px solid rgba(179, 179, 179, 0.25);
  /*margin-bottom: 15px;*/

  background: #ffffdd !important;
}
.questionCardWrapperGrey:hover .questionCardQuestion > p {
  color: #829397;
  background: #ffffdd !important;
}

.questionCardWrapperGrey > div:last-child {
  margin-left: 35px;
  width: 100%;
  background: #ffffdd !important;
}

.questionCardWrapperRed {
  display: flex;
  /*align-items: center;*/
  align-items: flex-start;
  padding-bottom: 10px !important;
  /*padding-bottom: 40px;*/
  border-bottom: 1px solid rgba(179, 179, 179, 0.25);
  /*margin-bottom: 15px;*/

  /* background: #ecebeb !important; */
}
.questionCardWrapperRed:hover .questionCardQuestion > p {
  color: #829397;
  /* background: #ecebeb !important; */
}

.questionCardWrapperRed > div:last-child {
  /* margin-left: 10px; */
  width: 100%;
  /* background: #ecebeb !important; */
}

.messageIconWrapper,
.viewsIconWrapper {
  min-width: 150px;
}

.qabox {
  padding: 2px 3px 3px 0px;
  margin-bottom: 10px;
}

.qaIconView {
  display: block;
}

.qaIcon {
  min-width: 150px !important;
  position: relative;
  padding-bottom: 10px;
}

p.notifyCount {
  margin-right: 13px !important;
}

.hlink {
  color: #f97516 !important;
}

.hlink:hover {
  color: #f97516 !important;
}

.player {
  /*width: auto !important;*/
  width: auto !important;
  height: 500px !important;
  max-height: 100% !important;
}

.bannerImg {
  height: 100px;
  width: 100%;
  object-fit: cover;
  object-position: top center;
  border-radius: 10px 10px 0px 0px;
}

.bannerImgPOpup {
  width: 100%;
  object-fit: cover;
  object-position: top center;
  border-radius: 10px 10px 0px 0px;
  height: 170px;
}

.bannerGroupImg {
  height: 130px;
  width: 100%;
  object-fit: cover;
  /* object-position: top center; */
  border-radius: 10px 10px 0px 0px;
}

.bannerEventImg {
  height: 200px;
  width: 100%;
  object-fit: cover;
  object-position: top center;
  border-radius: 0px 0px 0px 0px;
}

.bannerClassroomImg {
  height: 180px;
  width: 320px;
  /* object-fit: cover; */
  object-position: top center;
  border-radius: 0px 0px 0px 0px;
}

.userImg {
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
  object-fit: cover !important;
  object-position: top center !important;
  width: 100px !important;
  height: 100px !important;
  border-radius: 50px !important;
  box-shadow: rgb(0 0 0 / 16%) 0px 0px 5px !important;
  border: 3px solid rgb(255, 255, 255) !important;
}

.postuserImg {
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
  object-fit: cover !important;
  object-position: top center !important;
  width: 65px !important;
  height: 65px !important;
  border-radius: 50px !important;
  box-shadow: rgb(0 0 0 / 16%) 0px 0px 5px !important;
  border: 3px solid rgb(255, 255, 255) !important;
}

.chatImg {
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
  object-fit: cover !important;
  object-position: top center !important;
  width: 60px !important;
  height: 60px !important;
  border-radius: 30px !important;
  box-shadow: rgb(0 0 0 / 16%) 0px 0px 5px !important;
  border: 3px solid rgb(255, 255, 255) !important;
}

.w3-col.w3-container.w3-green {
  display: flex;
  align-items: center;
  margin: auto;
}

.w3-container {
  width: 100px;
  height: 100px;
}

.w3-row {
  border: 0px solid #aaa;
  width: 100%;
  background-color: #fff;
  margin-top: -50px;
}
.bookmarkIconCourse {
  float: right;
  padding-top: 10px;
  cursor: pointer !important;
}

.bookmarkIconyellowCourse {
  color: #f5ed04d1 !important;
  float: right;
  padding-top: 10px;
  cursor: pointer !important;
}

.listCardTitle {
  border-bottom: 1px solid #ddd;
}

.badgesCarouselWrapper .slick-slide > div {
  margin: 0 5px;
}

div.badgesCarouselWrapper
  > div
  > div.slick-list
  > div
  > div.slick-slide.slick-active.slick-current
  > div {
  margin-left: 0;
}
.badgesCarouselWrapper {
  padding: 0px 10px;
  margin: 50px !important;
  /*padding: 0 50px 30px;*/
}
.blueLabel {
  color: #ffffff !important;
  font-size: 12px !important;
  background: #f97516 !important;
  /* padding: 6px 10px !important; */
  float: right !important;
  border-radius: 25px !important;
}

.blueLabelLeft {
  color: #ffffff !important;
  font-size: 12px !important;
  background: #f97516 !important;
  padding: 6px 10px !important;
  margin-top: 8px !important;
  /* float: right !important; */
  border-radius: 25px !important;
}

.likesDashboard img {
  max-width: 100% !important;
  height: 80px !important;
  object-fit: cover;
}

.questionMedia {
  width: 320px !important;
  height: 220px !important;
}
.profilepageWrapper {
  padding: 30px 0px 0px !important;
  background: white !important;
}
.tabsProfile {
  background-image: linear-gradient(to right, #f97516 5%, #f97516 100%);
  /* padding-left: 60px; */
}

.badgeMainWrapper .listContentAnnouncement {
  margin: 0;
  padding: 20px 22px 25px;
}

.announcementWrapperOuter .listContentAnnouncement {
  margin: 0;
  padding: 0px 22px 25px;
}

.listContentAnnouncement {
  margin: 0px 22px 25px;
  /* overflow: hidden auto; */
}

.surveyBoxCard {
  padding: 5px !important;
}

.surveyButton {
  background: #f97516 !important ;
  margin-bottom: 30px !important;
  padding: 12px !important;
  cursor: "pointer" !important;
  width: 100% !important;
}

/* .surveyBoxCard-root:last-child {
  padding-bottom: 2px !important;
  
} */

.badgesCardColored {
  background: #f97516 !important;
  overflow-y: scroll !important;
  max-height: 800px !important;
  padding: 2px !important;
}

.MuiCardContent-root {
  padding: 10px !important;
}

.badgesCard {
  overflow-y: scroll !important;
  max-height: 800px !important;
}

.badgesTextWhite {
  color: #ffff !important;
  font-size: 13px !important;
  width: 200px !important;
}

.badgesTextYellowPoints {
  color: #fcee0e !important;
  font-size: 13px !important;
  width: 200px !important;
}

.badgesHomeWhiteLearn {
  color: #ffff !important;
  font-size: 15px !important;
  letter-spacing: 0px;
  font-weight: bold;
  float: right !important;
}

.badgesHomeWhite {
  color: #ffff !important;
  font-size: 15px !important;
  float: right !important;
  margin-top: 6px !important;
  margin-right: 8px !important;
  margin-left: 8px !important;
}

.badgesTextYellow {
  color: #fcee0e !important;
  font-size: 15px !important;
  font-weight: bold !important;
  text-align: center !important;
  margin-right: 8px !important;
}

.badgesImage {
  width: 39px !important;
  height: 35px !important;
}

.mybadgesImage {
  width: 35px !important;
  height: 35px !important;
}

.badgesTextWhiteName {
  color: #ffff !important;
  font-size: 18px !important;
  font-weight: bold !important;
  width: 200px !important;
}

.badgesTextWhiteCondition {
  color: #ffff !important;
  font-size: 13px !important;
}

.groupuserImg {
  margin-left: auto !important;
  margin-right: auto !important;
}

.badgeslistHeading {
  font-size: 20px;
  letter-spacing: 0px;
  color: #333333;
  font-weight: bold;
}

.badgeslistTitle {
  padding: 15px 30px 20px;
  border-bottom: 1px solid #ddd;
}

.badgeslistSubHeading {
  font-size: 15px;
  letter-spacing: 0px;
  font-weight: bold;
  float: right !important;
  color: #f97516 !important;
  cursor: pointer !important;
}

.badgeRowHeading {
  font-size: 13px !important;
  padding: 18px !important;
}

.badgeRowHeadingName {
  font-size: 13px !important;
  font-weight: bold !important;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 1px solid #edeff0 !important;
}

.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #edeff0 !important;
}

.bluetable thead th {
  vertical-align: bottom;
  border-bottom: 1px solid #f0efef29 !important;
}

.bluetable td,
.bluetable th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #f0efef29 !important;
}

.bluetable {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

.verticalLine {
  border: 1px solid #e9e9e9 !important;
  height: 100px !important;
  width: 0px !important;
}

.badgesBottomWrapper {
  max-height: 500px !important;
  background: #f97516 !important;
}

.badgesCardFooter {
  max-height: 600px !important;
  padding: 40px 40px;
  font-size: 14px;
  /* letter-spacing: 0px; */
  color: #001737;
  /*border-bottom: 1px solid #ddd;*/
}

.badgesflexSpaceBetween {
  display: inline-flex;
  /* align-items: center; */
}

.badgeslistContent {
  overflow: hidden auto;
}

.deleteGroup {
  text-decoration: underline !important;
  color: blue !important;
  cursor: pointer !important;
  text-align: center !important;
  margin-bottom: 8px !important;
}
.likesCountAvataricon {
  background: #ffffff 0 0 no-repeat padding-box !important;
  border: 1px solid #8e8f8f !important;
  border-radius: 30px !important;
  padding: 0px !important;
}

.announcementContent h6 {
  font-size: 14px;
  letter-spacing: 0px;
  color: #f97516;
  font-weight: bold;
  margin-top: 12px !important;
}

.headerIconCalls img {
  font-size: 26px;
  position: relative;
  left: -30px;
}
.headerIconCalls1 img {
  font-size: 26px;
  position: relative;
  left: -45px;
}
.widthincress {
  width: 630px !important;
}
.friendssearch {
  display: flex;
  flex-wrap: nowrap;
}
.moreIcon {
  display: inline-flex;
}
.navsincontact {
  display: flex;
  flex-wrap: nowrap;
  justify-content: left;
  flex-direction: column;
}
.showcount {
  display: none;
}
.likesInfo {
  display: block;
}
.mobile-likesInfo {
  display: block;
}
/* .slick-slide {
  width: 250px;
} */
.badgeCardInner1 {
  width: 250px;
  height: 300px;
  background: #f97516 !important;
}
.badgeCardTitle1 {
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 7px;
  color: #f4f8fa;
  margin-top: 20px !important;
  letter-spacing: 1px !important;
}

.badgeCardTitle2 {
  font-size: 11px;
  font-weight: normal;
  color: #f4f8fa !important;
  margin-bottom: 12px !important;
  letter-spacing: 1px !important;
  padding-bottom: 12px !important;
}

.questionViewLeftbar {
  display: none;
}
.questionEditDelete {
  display: none;
}
.answerviewimg {
  display: none;
}
.answerviewimginview {
  display: block;
}
.QuestionFilteronMobile {
  display: none;
}

.mycorses {
  padding-top: 50px !important;
}

.classroomBottomWrapper {
  color: #000000 !important;
  font-size: 20px;
  font-weight: bold !important;
  padding: 14px !important;
  margin-top: 100px;
  margin-bottom: 0px !important;
}

.mobileMenu {
  display: flex;
}

.mobileMenu .menuIconWrapper {
  width: 100%;
  display: flex;
}

.mobileMenu .navHome,
.mobileMenu .navCalls,
.mobileMenu .navClassroom,
.mobileMenu .navCommunity,
.mobileMenu .navBanner {
  display: flex;
  flex: 1;
  justify-content: center;
  /* margin-right: 0px; */
  margin: 0px;
}

.mobileMenu .navigationWrapper .nav-link {
  margin-right: 0px !important;
}

.mobileMenu .navigationWrapper a {
  padding-left: 0px;
}

.mobileMenu .nav-link {
  padding-right: 0px !important;
}

.contactpageWrapper {
  padding: 30px 0px 60px;
}

.removeButton {
  float: right !important;
}

.topbar-wrapper {
  /* padding: 0 24px; */
  /* padding-right: 24px; */
  /* background-color: #1f262e; */
  box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%);
  /* min-height: 99.69px; */
  position: fixed;
  /* left: 208px; */
  left: 0;
  top: 0;
  right: 0;
  z-index: 1000;
  background-color: white;
  min-height: 64.69px;
}
.topbar-inner {
  background: #f5f5f5;
  display: flex;
  /* min-height: 99px; */
  min-height: 70px;
  align-items: center;
  color: #fff;
  justify-content: space-between;
}

.screenInnerGapEUI {
  /* padding: 122px 0px 113px 200px; */
  /* padding: 30px 0px 113px 150px; */
  background: #f5f5f5 0 0 no-repeat padding-box;
}

.screenInnerGapAA {
  /* padding: 122px 0px 113px 200px; */
  /* padding: 110px 0px 213px 217px; */
  background: #f5f5f5 0 0 no-repeat padding-box;
}

.screenInnerGapMyGroup {
  /* padding: 122px 0px 113px 200px; */
  /* padding: 80px 0px 113px 0px; */
  background: #f5f5f5 0 0 no-repeat padding-box;
}

.logo {
  float: left;
  margin-left: -10px;
}

/* .leftside-menu {
  width: 208px;
  height: auto;
  z-index: 10;
  background: linear-gradient(to right, #9c1c28 5%, #6d064e 90%); 
  border-radius: 4px !important;
  color: #fff;
  bottom: 0;
  position: fixed;
  top: 0;
  padding-top: 115px;
} */

/* .leftside-menu::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}
.leftside-menu::-webkit-scrollbar {
  width: 1px;
  background-color: #f5f5f5;
}
.leftside-menu::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #1f262e;
} */

.leftside-menu {
  width: 240px;
  flex-wrap: nowrap;
  overflow-x: hidden;
  overflow-y: hidden;
  /* height: 100vh; */
  /* z-index: 10; */
  /* background: #f5f5f5 0 0 no-repeat padding-box; */
  background: block;
  border-radius: 4px !important;
  color: #fff;
  bottom: 0;
  position: fixed;
  top: 0;
  /* padding-top: 115px; */
}

/* .leftside-menu:hover {
  overflow-y: auto;
} */
.leftside-menu::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}
.leftside-menu::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}
.leftside-menu::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #1f262e;
}

.border-none {
  border: none;
}

.border-none .modal-content {
  border: none;
}

.customLoading .modal-dialog {
  position: relative;

  top: 35%;
}

.adminPanelWrapper {
  /* width: 300px; */
  height: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 0px #00000029;
  padding: 20px 10px 0px 10px;
}

.adminPanelWrapper {
  /* width: 370px; */
  height: 100%;
  overflow-y: scroll;
}
.myEventsIcon {
  width: 20px;
  height: 20px !important;
  margin-right: 10px !important;
}

.navHome {
  padding-top: 9px;
}

.navBanner {
  padding-top: 9px;
}

div.navHome:focus {
  background-color: #c0c0c0;
  border-radius: 4px !important;
  padding: 12px;
  height: 50px;
  width: 200px;
}

div.navBanner:focus {
  background-color: #c0c0c0;
  border-radius: 4px !important;
  padding: 12px;
  height: 120px;
  width: 200px;
}

.navHome {
  border-radius: 4px !important;
  padding: 12px;
  height: 50px;
  width: 200px;
  cursor: pointer;
}

.navBanner {
  border-radius: 4px !important;
  padding: 12px;
  height: 120px;
  width: 200px;
  cursor: pointer;
}

.navHome:hover {
  background-color: #eeeeee !important;
}

.navBanner:hover {
  background-color: #eeeeee !important;
}

.navHomeProfession {
  padding: 12px;
  height: 50px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid gray;
  width: auto;
  cursor: pointer;
}

.navHomeProfile {
  /* padding: 3px;
  height: 50px;
  margin-top: 5px;
  margin-bottom: 5px;*/
  border-bottom: 1px solid gray;
  width: auto;
  cursor: pointer;
  display: flex;
  height: 4rem;
  /* padding: 1rem 2px; */
}
.navHomeProfile p {
  font-size: 15px;
  display: flex;
  align-items: center;
}
.navHomeSkills {
  padding: 12px;
  height: 50px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid gray;
  width: auto;
  cursor: pointer;
}

.navHomeEducation {
  padding: 12px;
  height: 50px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid gray;
  width: auto;
  cursor: pointer;
}

.navMember {
  border-radius: 4px !important;
  padding: 12px;
  padding-top: 9px;
  height: 50px;
  width: 200px;
  cursor: pointer;
}

div.navMember:focus {
  background-color: #c0c0c0;
  border-radius: 4px !important;
  padding: 12px;
  height: 50px;
  width: 200px;
}

.navMember:hover {
  background-color: #eeeeee !important;
}

.navGroup {
  border-radius: 4px !important;
  padding: 12px;
  padding-top: 9px;
  height: 50px;
  width: 200px;
  cursor: pointer;
}

div.navGroup:focus {
  background-color: #c0c0c0;
  border-radius: 4px !important;
  padding: 12px;
  height: 50px;
  width: 200px;
}

.navGroup:hover {
  background-color: #eeeeee !important;
}

.navEvent {
  border-radius: 4px !important;
  padding: 12px;
  height: 50px;
  width: 200px;
  cursor: pointer;
  padding-top: 9px;
}

div.navEvent:focus {
  background-color: #c0c0c0;
  border-radius: 4px !important;
  padding: 12px;
  height: 50px;
  width: 200px;
}

.navEvent:hover {
  background-color: #eeeeee !important;
}

.navCEvent {
  border-radius: 4px !important;
  padding: 12px;
  height: 50px;
  width: 200px;
  cursor: pointer;
  padding-top: 9px;
}

div.navCEvent:focus {
  background-color: #c0c0c0;
  border-radius: 4px !important;
  padding: 12px;
  height: 50px;
  width: 200px;
}

.navCEvent:hover {
  background-color: #eeeeee !important;
}

.navCGroup {
  border-radius: 4px !important;
  padding: 12px;
  height: 50px;
  width: 200px;
  cursor: pointer;
  padding-top: 9px;
}

div.navCGroup:focus {
  background-color: #c0c0c0;
  border-radius: 4px !important;
  padding: 12px;
  height: 50px;
  width: 200px;
}

.navCGroup:hover {
  background-color: #eeeeee !important;
}

.pageWrapper {
  padding: 60px 255px 150px;
  background: white !important;
}

.pageWrapperClassroom {
  padding: 20px 20px 20px;
}

.pageWrapperHome {
  padding: 60px 235px 90px;
  padding-right: 30px;
}

.messageBoxWrapper {
  height: 400px;
}

.listContentMessage {
  margin: 0px 22px 25px;
  overflow: scroll;
  height: 400px;
}
.listContentMessage1 {
  margin: 0px 22px 25px;
  /* overflow: scroll; */
  height: 400px;
}
.material-icons {
  font-family: "Material Icons" !important;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

.grouporeventwrapper {
  padding: 120px 25px 90px 235px !important;
}

.backtoGroup {
  float: right !important;
}

.bgUED {
  background: #f5fcff !important;
  padding: 20px 20px 0px 20px !important;
}

.gbgUED {
  padding: 20px 20px 0px 20px !important;
}

.uploader {
  /* color: #f97516; */
  color: #a00030 !important;
}

.dateinevents {
  position: absolute;
  right: 0;
}

.boxcolor {
  /* background-color: #d5d9dd; */
  padding: 4px 4px 4px 4px;
  border-radius: 6px;
  font-size: 12px;
  display: inline-flex !important;
  /* padding-left: 5px; */
}

.boxcolorRed {
  background-image: linear-gradient(to right, #f97516 5%, #f97516 100%);
  padding: 8px 8px 8px 8px;
  border-radius: 0px;
  font-size: 15px;
  color: #ffffff;
}

.postUserName {
  color: brown;
  font-weight: bold;
  font-size: 16px;
}

.postUploadTime {
  padding: 2px;
  color: black;
  font-size: 13px;
  padding-left: 10px;
  border-radius: 2px;
}

.pageWrapperHomePage {
  background: #f5f5f5 0 0 no-repeat padding-box;
  padding: 60px 235px 90px;
  padding-right: 30px;
}

.membersList {
  font-weight: bold;
  color: #000000;
  font-size: 25px;
  padding-top: 2%;
}

.hostedWrapper {
  border-top-style: solid;
  border-top-color: #f97516;
  border-top-width: 10px;
  border-radius: 7px;
  padding: 20px;
  cursor: pointer;
  background: #ffffff;
}

.aboutGroupWrapper {
  border-radius: 10px;
  padding: 20px;
  background: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.aboutMyRequestWrapper {
  border-radius: 10px;
  padding: 20px;
  background: #fff;
  box-shadow: rgb(17 17 26 / 10%) 0px 4px 16px, rgb(17 17 26 / 5%) 0px 8px 32px;
}

.aboutGroupTitle {
  align-items: center;
  font-weight: bold;
  justify-content: space-between;
  margin-bottom: 10px;
}

/* .nav-link:focus{
  background-image: #ffffff;
 
} */

/* .nav-link:focus{
  background-color: #c0c0c0;
  border-radius: 4px !important;
  padding: 12px;
  height: 50px;
  width: 200px;
}


.nav-link{
  border-radius: 4px !important;
  padding: 12px;
  height: 50px;
  width: 200px;
  background-color: #000000 !important;
}

.nav-link:hover {
  background-color: #eeeeee !important;
} */

.JRWrapper {
  padding: 10px 12px 10px;
  background: white !important;
}

.MuiIconButton-root,
.MuiIconButton-colorInherit,
.button,
.sendbtn {
  border: 1px solid #ffffff;
  border-radius: 4px !important;
  color: #a00030;
  font-size: 14px !important;
  padding: 5px 15px;
  background: #ffffff 0 0 no-repeat padding-box;
  background-image: linear-gradient(to right, #ffffff 5%, #ffffff 100%);
}

@media (min-width: 600px) and (max-width: 1200px) {
  /* =========== TAB MAIN ========= */

  .screenInnerGap {
    /* padding: 30px 0px 113px 255px !important; */
    background: #f5f5f5 0 0 no-repeat padding-box;
  }

  .screenInnerGapMyGroup {
    /* padding: 122px 0px 113px 200px; */
    /* padding: 0px 0px 113px 2px; */
    background: #f5f5f5 0 0 no-repeat padding-box;
  }
}

@media (max-width: 400px) {
  /* =========== TAB MAIN ========= */

  .screenInnerGap {
    /* padding: 30px 0px 113px 250px; */
    background: #f5f5f5 0 0 no-repeat padding-box;
  }

  .screenInnerGapMyGroup {
    /* padding: 122px 0px 113px 200px; */
    /* padding: 0px 0px 113px 2px; */
    background: #f5f5f5 0 0 no-repeat padding-box;
  }
}

.videoThumbnailcontainer {
  position: relative;
  width: 100%;
  max-width: 400px;
}

.videoThumbnailcontainer img {
  width: 100%;
  height: auto;
}

.videoThumbnailcontainer .btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

h3.listHomeTitle {
  font-size: 22px;
  letter-spacing: 0px;
  color: #333333;
  font-weight: bold;
  padding: 10px 5px 5px 0px;
}

.GroupTSearchBtn button {
  width: 90px;
  height: 32px;
  font-size: 16px;
  color: #333333;
  padding: 10px 10px;
}

.greenLabelHome {
  color: #006400 !important;
  font-size: 12px !important;
  background: #c4eece !important;
  padding: 6px 10px !important;
  border-radius: 15px !important;
  font-weight: bold;
  width: 100px !important;
}

.greenTextHome {
  color: #21ba45 !important;
  font-size: 12px !important;
  padding: 6px 10px !important;
}

.peopleCardInforhome {
  text-align: center;
  margin-top: 25px;
  margin-bottom: 25px;
  height: auto;
  margin-left: 12px;
  margin-right: 12px;
}

.peopleCardInforhome p {
  font-size: 12px;
  color: #b3b3b3;
  margin-bottom: 0;
}
.peopleCardInforhome h2 {
  color: #333333;
  font-size: 18px;
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden !important;
  padding: 10px 20px;
}

.setupCard {
  background: #e8e8e8;
  border-radius: 10px;
  margin: 12px;
  height: 250px;
  width: 200px;
  padding: 12px;
  justify-content: center;
  text-align: center;
}

.setupMainCard {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}

.setupCardInfor h6 {
  color: #333333;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0px;
}

.setupCardInfor {
  text-align: center;
  margin-top: 15px;
  margin-bottom: 25px;
  height: 70px;
}
.setupCardInfor h2 {
  color: #333333;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden !important;
  padding: 10px 20px;
}

.setupCardInfor p {
  font-size: 14px;
  color: #b3b3b3;
}

.pendingButton {
  border: 1px solid #eeeeee;
  border-radius: 4px !important;
  color: darkgrey;
  font-size: 14px !important;
  padding: 5px 15px;
  background: #ffffff 0 0 no-repeat padding-box;
  background-image: linear-gradient(to right, #ffffff 5%, #ffffff 100%);
  margin: 12px;
}

.menuFocus {
  padding: 5px;
  height: 50px;
  width: 200px;
}

.menuFocus:hover {
  background-color: #eeeeee;
  height: 50px;
  width: 250px;
  border-radius: 4px !important;
}

.bannerImgHOme {
  height: 100px;
  width: 100%;
  object-fit: cover;
  object-position: top center;
  border-radius: 10px 10px 0px 0px;
}

.spaceFiletCard {
  box-shadow: 5px 5px 6px #0000000d;
  border: 1px solid #dededfbf;
  border-radius: 10px;
  background: #fff;
  margin-bottom: 16px;
  padding: 12px;
}

@media (max-width: 1200px) {
}

button.mobileTabBackground {
  padding: 15px 15px 15px 15px !important;
  justify-content: center;
  background: #ffffff;
  color: #f97516;
  width: 100% !important;
  font-size: 14px !important;
  border-color: #eeeeee;
  border-radius: 0px !important;
}

button.mobileTabBackground:hover {
  border-bottom: #f97516;
  border-bottom-style: solid;
  border-bottom-color: #f97516;
  border-bottom-width: 2px;
  border-radius: 0px !important;
}

@media (min-width: 992px) {
  .screenInnerGap {
    /* padding:30px 0px 0px 250px; */
    background: #f5f5f5 0 0 no-repeat padding-box;
  }

  .leftside-menu {
    width: 200px;
    flex-wrap: nowrap;
    overflow-x: hidden;
    /* height: 97vh; */
    overflow-y: hidden;
    /* z-index: 10; */
    /* background: #f5f5f5 0 0 no-repeat padding-box; */
    background: block;
    border-radius: 4px !important;
    color: #fff;
    bottom: 0;
    position: fixed;
    top: 0;
    /* padding-top: 15px; */
  }
  .leftside-menuStatic {
    width: 280px;
    flex-wrap: nowrap;
    overflow-x: hidden;
    overflow-y: hidden;
    /* z-index: 10;   */
    background: block;
    border-radius: 4px !important;
    color: #fff;
    position: fixed;
    bottom: 12.8rem;
  }
  /* .leftside-menu:hover {
    overflow-y: auto;
  } */
  .leftside-menu::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }
  .leftside-menu::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f5f5;
  }
  .leftside-menu::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #1f262e;
  }
}

@media (min-width: 600px) {
  .screenInnerGap {
    /* padding-top: 90px;
      padding-left: 260px; */
    /* padding:30px 0px 0px 260px; */
    background: #f5f5f5 0 0 no-repeat padding-box;
  }

  /* .leftside-menu {
    width: 200px;
    flex-wrap: nowrap;
    overflow-x: hidden;
    overflow-y: hidden;
    height: 100vh;
    z-index: 10;
    background: #f5f5f5 0 0 no-repeat padding-box;
    background: block;
    border-radius: 4px !important;
    color: #fff;
    bottom: 0;
    position: fixed;
    top: 0;
    padding-top: 115px;
  } */

  .leftside-menu {
    width: 280px;
    flex-wrap: nowrap;
    overflow-x: hidden;
    overflow-y: hidden;
    /* height: 100vh; */
    /* z-index: 10; */
    /* background: #f5f5f5 0 0 no-repeat padding-box; */
    background: block;
    border-radius: 4px !important;
    color: #fff;
    bottom: 0;
    position: static;
    top: 0;
    /* padding-top: 87px; */
  }

  /* .leftside-menu:hover {
    overflow-y: auto;
  } */

  .leftside-menu::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }
  .leftside-menu::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f5f5;
  }
  .leftside-menu::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #1f262e;
  }
}
.listCardWrapperLeaderHeader {
  margin-bottom: 20px;
  width: 290px;
  height: 46rem;
  position: fixed;
  overflow: hidden;
}
.listCardWrapperLeader {
  margin-bottom: 20px;
  width: 290px;
  height: 39rem;
  overflow-y: scroll;
  overflow-x: hidden;

  /* scrollbar-width: thin; */
}

@media (min-width: 1280px) {
  .MuiContainer-maxWidthLg {
    max-width: 2000px !important;
  }
}

@media (min-width: 600px) {
  .MuiContainer-maxWidthLg {
    max-width: 1300px !important;
  }
}

@media (min-width: 1280px) {
  .MuiContainer-maxWidthLg {
    max-width: 1500px !important;
    height: auto !important;
  }
}

@media (min-width: 600px) {
  /* .MuiContainer-root {
    padding-left: 0px !important;
    padding-right: 0px !important;
  } */
}
.MuiContainer-root {
  width: 100%;
  display: block;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
}

.max-width-100 {
  min-width: 100%;
}
.pd-10 {
  margin: 30px;
  position: relative;
  right: 16px;
  top: 12px;
}

.relative-top-38 {
  position: relative;
  top: 5px;
}

.input.has-error {
  border-color: tomato;
}

.error {
  margin: 0;
  font-size: 90%;
  color: tomato;
}

.tag-item {
  background-color: #d4d5d6;
  font-size: 14px;
  border-radius: 30px;
  height: 30px;
  padding: 0 4px 0 1rem;
  display: inline-flex;
  align-items: center;
  margin: 0 0.3rem 0.3rem 0;
}

.tag-item > .button {
  background-color: white;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  font: inherit;
  margin-left: 10px;
  font-weight: bold;
  padding: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px !important;
  outline: 0;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}
.w2-row {
  border: 0px solid #aaa;
  float: right;
  background-color: #fff;
  margin-top: -101px;
}

.profilePopupContainer {
  position: relative;
  text-align: center;
  color: white;
  width: 700px;
  margin-top: -100px;
}

.peopleCardInner {
  background: #fff 0 0 no-repeat padding-box;
  border: 1px solid #dededf;
  box-shadow: 35px 35px 35px 35px #00000029;
  border-radius: 4px !important;
  width: 550px;
  height: 450px;
}
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.circlediv {
  width: 100px;
  height: 100px;
  background: #eeeeee;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  padding: 24px;
}

.first-time-setup {
  margin-top: -20px;
  margin-left: -20%;
}

.pageWrapperProfileSetup {
  padding: 60px 35px 30px;
  background: white !important;
}

.vl {
  border-left: 1px solid gray;
  height: auto !important;
}

.selectCard {
  background: #ffffff;
  border-radius: 10px;
  margin: 12px;
  height: 250px;
  width: auto;
  padding: 12px;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}
.selectdotCard {
  background: #fffafa;
  border-radius: 10px;
  border-style: dotted;
  margin: 12px;
  height: 250px;
  width: 200px;
  padding: 12px;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}

.selectCardArrow {
  border-radius: 10px;
  margin: 12px;
  height: 250px;
  width: auto;
  padding-top: 50%;
  padding-bottom: 50%;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}

.setupPhotoCardWrapperContainer {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30%;
  margin-bottom: 25%;
}

.profilepageWrapperProfileSetup {
  /* padding: 60px 35px 30px; */
  background: #f5f5f5 0 0 no-repeat padding-box;
}

.profileInvitationCardWrapper {
  /* background: #ffffff 0% 0% no-repeat padding-box; */
  align-items: center;
  padding: 20px 15px;
  margin-bottom: 6px;
  box-shadow: 0px 1px 3px #00000029;
  border: 1px solid #f7f7f7;
  border-radius: 2px;
}

.profileselectCard {
  background: #ffffff;
  margin-top: 10px;
  height: 800px;
  width: auto;
  align-items: center;
  padding: 20px 15px;
  margin-bottom: 6px;
  box-shadow: 0px 1px 3px #00000029;
  border: 1px solid #f7f7f7;
  border-radius: 3px;
  overflow-y: scroll;
}

.poweredby-div {
  position: relative;
}

.poweredby-div span {
  position: absolute;
  bottom: 0;
  right: 0;
}

.homeCardWrapper {
  background: #ffffff 0% 0% no-repeat padding-box;
  align-items: center;
  margin-bottom: 6px;
  box-shadow: 0px 1px 3px #00000029;
  padding-bottom: 10px;
  border-bottom: 0px solid gray;
  cursor: pointer;
}

.postcardUploadTime {
  font-size: 13px;
  letter-spacing: 0px;
  color: #898989 !important;
}

.newPostUploadUserImgCol {
  padding-right: 0 !important;
  padding-left: 0 !important;
  left: 11px;
}

.postscommunityCard {
  box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(222, 222, 223, 0.75);
  border-radius: 10px;
  background: #fff;
  margin-bottom: 16px;
}

.postInput {
  flex: 1;
  margin: 0 16px;
  position: relative;
}

.postInput input {
  width: 100%;
  background: #f4f2f2;
  border-radius: 16px;
  border: none;
  margin: 0;
  height: 56px;
  padding: 0 15px;
  font-size: 14px;
}

.valid-form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  resize: none !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.valid-form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}

.URLInvalid {
  border-color: #dc3545 !important;
  box-shadow: none !important;
  border-radius: 6px;
  padding: 8px;
  width: auto;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") !important;
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.URLvalid {
  border-color: #18e43a0d !important;
  box-shadow: none !important;
  border-radius: 6px;
  padding: 8px;
  width: auto;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.urlBox {
  box-shadow: none !important;
  border-radius: 6px;
  padding: 8px;
  width: auto;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.kycContainerInside {
  position: absolute;
  margin-top: 10%;
  margin-left: 25%;
  justify-content: center !important;
  align-items: center;
  background: #ffffff;
  box-shadow: 5px 5px 6px #0000000d;
  border: 1px solid #dededfbf;
  padding: 18px 35px 35px;
  border-radius: 10px;
}

.educationContainerEdit {
  background: #ffffff 0% 0% no-repeat padding-box;
  margin: 6px;
  box-shadow: 0px 1px 3px #00000029;
  border: 1px solid #f7f7f7;
  border-radius: 2px;
  height: 110px;
  padding: 22px;
  padding: 20px 15px;
}
.professionCard {
  background: #ffffff 0% 0% no-repeat padding-box;
  padding: 20px 15px;
  margin: 6px;
  box-shadow: 0px 1px 3px #00000029;
  border: 1px solid #f7f7f7;
  border-radius: 2px;
  height: 190px;
  /* padding: 22px; */
}

.invitationuserImg {
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
  object-fit: cover !important;
  object-position: top center !important;
  width: 48px !important;
  height: 48px !important;
  border-radius: 50px !important;
  box-shadow: rgb(0 0 0 / 16%) 0px 0px 5px !important;
  border: 3px solid rgb(255, 255, 255) !important;
}

.videopostcontainer {
  position: relative;
  width: auto;
  max-width: auto;
  /* margin-bottom: 12px; */
  /* display: inline-flex !important; */
  cursor: pointer;
  /* margin-right: 8px; */
}
.videoOverAllCard {
  background: #000000 !important;
}

.image {
  display: block;
  width: 100%;
  height: auto;
}

/* .videopostcontainer:hover .overlay {
  opacity: 1;
} */

/* .overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .3s ease;
  background-color: transparent;
} */

.videopostcontainer .overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50px;
  right: 0;
  height: 100%;
  width: 100%;
  transition: 0.3s ease;
  background-color: transparent;
  opacity: 1;
}

.icon {
  color: white;
  font-size: 100px;
  position: absolute;
  top: 40%;
  left: 40%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.fa-user:hover {
  color: #eee;
}

.videoCardPlayBtn {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 100%;
  position: absolute;
  /* right: 17px;
  top: 50px; */
  cursor: pointer;
}

.profileselectCardExpMobile {
  background: #ffffff;
  margin-top: 10px;
  height: 800px;
  width: auto;
  align-items: center;
  padding: 20px 15px;
  margin-bottom: 6px;
  box-shadow: 0px 1px 3px #00000029;
  border: 1px solid #f7f7f7;
  border-radius: 3px;
  overflow-y: scroll;
}
.businessProfile {
  background: #ffffff;
  margin-top: 10px;
  height: auto;
  width: auto;
  align-items: center;
  padding: 31px 38px;
  margin-bottom: 6px;
  box-shadow: 0px 1px 3px #00000029;
  border: 1px solid #f7f7f7;
  border-radius: 3px;
}
.profileselectCardEducation {
  background: #ffffff;
  margin-top: 10px;
  height: 800px;
  width: auto;
  align-items: center;
  padding: 20px 15px;
  margin-bottom: 6px;
  box-shadow: 0px 1px 3px #00000029;
  border: 1px solid #f7f7f7;
  border-radius: 3px;
  overflow-y: scroll;
}
.profileselectCardMobile {
  background: #ffffff;
  margin-top: 10px;
  height: 800px;
  width: auto;
  align-items: center;
  padding: 20px 15px;
  margin-bottom: 6px;
  box-shadow: 0px 1px 3px #00000029;
  border: 1px solid #f7f7f7;
  border-radius: 3px;
  overflow-y: scroll;
}
.valueselectCard {
  background: #ffffff;

  margin-top: 10px;
  height: 800px;
  width: auto;
  align-items: center;
  padding: 20px 15px;
  margin-bottom: 6px;
  box-shadow: 0px 1px 3px #00000029;
  border: 1px solid #f7f7f7;
  border-radius: 3px;
}
.profileselectCardValue {
  background: #ffffff;
  border-radius: 10px;
  margin-top: 10px;
  height: auto;
  width: auto;
  align-items: center;
  padding: 20px 15px;
  margin-bottom: 6px;
  box-shadow: 0px 1px 3px #00000029;
  border: 1px solid #f7f7f7;
}
#video-img-wrapper {
  display: flex;
  flex-wrap: wrap;
}

#video-img-wrapper > div {
  flex: 0 1 33%;
}

.profileselectCardFirstTime {
  background: #ffffff;
  margin-top: 10px;
  height: 380px;
  width: auto;
  align-items: center;
  padding: 20px 15px;
  margin-bottom: 6px;
  box-shadow: 0px 1px 3px #00000029;
  border: 1px solid #f7f7f7;
  border-radius: 3px;
}

.PrivateTabIndicator-colorPrimary-6 {
  background-color: #f97516 !important;
}

/* .MuiTab-textColorPrimary.Mui-selected {
  color: #ffffff !important;
} */

.PrivateTabIndicator-colorPrimary-2 {
  background-color: #ffffff !important;
}
.PrivateTabIndicator-colorSecondary-7 {
  /* background-color: #f97516 !important; */
  background-color: #a00030 !important;
}

.groupheaderIcon {
  font-size: 10px;
  font-weight: bold;
  color: #fff;
  background: linear-gradient(90deg, #000940 0%, #a00030 100%);
  border-radius: 10px;
  text-align: center;
  position: absolute;
  width: 23px;
  height: 15px;
  top: 0;
  left: 95%;
  right: 0;
}

.menuBarHeaderIcon {
  font-size: 10px;
  font-weight: bold;
  color: #fff;
  background: linear-gradient(90deg, #000940 0%, #a00030 100%);
  border-radius: 5px;
  text-align: center;
  position: absolute;
  width: 26px;
  height: 15px;
  top: 5%;
  left: 85%;
  right: 0;
}

.postcardGeneral {
  float: left;
  background: #f4f2f2;
  border-radius: 22px;
  border: none;
  margin: 0;
  /* height: 24px;
  width: 75px; */
  /* padding: 4px 10px 1px 16px; */
  padding: 4px 10px;
  font-size: 10px;
}
.postcardPost {
  float: left;
  background: #f4f2f2;
  border-radius: 22px;
  border: none;
  margin: 0;
  height: 25px;
  width: 58px;
  padding: 4px 10px 1px 16px;
  font-size: 10px;
}

.MuiTab-textColorPrimary.Mui-selected {
  color: black !important;
}
.peopleCardWrapperGroups {
  background: #fff 0 0 no-repeat padding-box;
  border: 1px solid #dededf;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  margin-bottom: 20px;
  width: 100%;
  height: 60px;
  border-radius: 10px !important;
}

.menuCardBanner {
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  border-radius: 10px !important;
  margin-bottom: 20px;
  width: 100%;
  height: 60px;
}

.homePostInputWrapper select {
  width: 150px;
  border: none;
  border-radius: 6px;
  padding: 5px 5px;
  font-size: 12px;
  color: #0c0c0c;
}

.homepostTitleWrapper {
  padding: 20px 20px 20px !important;
}

.homepostTitleWrapper h2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.homepostTitleWrapper button {
  background: #d9d9d9;
  padding: 0;
  width: 28px;
  height: 28px;
  border-radius: 100% !important;
}

.homepostTitleWrapper button svg {
  font-size: 20px;
}

.homePostMediaButtons {
  border: 1px solid #d6d6d6;
  height: 40px;
  margin-right: 5px;
  width: 50px;
  padding: 8px;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
}
.navMenuTextoverFlowOrange {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 120px;
  color: #ffffff !important;
  background: linear-gradient(to right, #ef5a3e 5%, #a00030 100%) !important;
  border: linear-gradient(to right, #ef5a3e 5%, #a00030 100%) !important;
}
.navMenuTextoverFlow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 150px;
}

.navMenuTextoverFlowGray {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 120px;
  color: #000000 !important;
  background: #e0e0e0 !important;
  border: #e0e0e0 !important;
}

.homepostgroupcommunityCard {
  box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(222, 222, 223, 0.75);
  border-radius: 2px;
  background: #fff;
  margin-bottom: 16px;
  position: absolute;
  width: 310px;
  margin-top: 6px;
  height: 100px;
  overflow-y: scroll;
}
.homepostgroupcommunityCard p:hover {
  color: red;
  cursor: pointer;
}
.homeMyRequestCard {
  margin-bottom: 16px;
  margin-top: 6px;
  height: 200px;
  overflow: scroll;
  box-sizing: border-box;
}

.cardButton {
  border: 1px rgb(245, 245, 245);
  border-radius: 4px !important;
  font-size: 14px !important;
  padding: 4px 0px 0px 0px;
  background: rgb(245, 245, 245) none repeat scroll 0% 0%;
  color: #000 !important;
  float: right;
  cursor: pointer;
}

.chooseCardList {
  font-size: 14px;
  padding: 3px;
}

.postCardCloud {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  /* margin-top: 3px; */
  /* border-radius: 10px !important;
  margin-bottom: 20px;
  width: auto;
  height: auto;
  padding: 7px;
  border-radius: 10px !important;
  padding: 5px;
  */
}

.dropdownCardPost {
  background: #ffffff 0% 0% no-repeat padding-box;
  /* box-shadow: 5px 5px 25px #00000040; */
  border: 1px solid #dededebf;
  border-radius: 4px;
  width: 170px;
  height: auto;
  padding: 15px 14px 16px 17px;
  position: absolute;
  right: 1%;
  /* top: 9.6em; */
  cursor: pointer;
  z-index: 10;
  float: right;
}

.dropdownfirstTimeCardPost {
  background: #ffffff 0% 0% no-repeat padding-box;
  /* box-shadow: 5px 5px 25px #00000040; */
  border: 1px solid #dededebf;
  border-radius: 4px;
  /* width: 170px; */
  height: auto;
  padding: 0.7rem;
  position: absolute;
  right: 1%;
  cursor: pointer;
  z-index: 10;
  float: right;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.dropdownfirstTimeCardPost p:hover {
  color: #a00030;
}
.postcardGroup {
  background: #f4f2f2;
  border-radius: 22px;
  border: none;
  margin: 0;
  height: 24px;
  width: auto;
  padding: 4px 10px 1px 16px;
  font-size: 10px;
  float: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* width: 150px; */
}

.postcardGroupOverFlow {
  background: #f4f2f2;
  border-radius: 22px;
  border: none;
  margin: 0;
  height: 24px;
  padding: 4px 10px 1px 16px;
  font-size: 10px;
  float: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 250px;
}
.privacyCardWrapper {
  background: #ffffff 0% 0% no-repeat padding-box;
  align-items: center;
  padding: 20px;
  margin-bottom: 6px;
  box-shadow: 0px 1px 3px #00000029;
}
.privacyCardInnerWrapper {
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid #eeeeee;
}

.profilePictureFriend {
  z-index: 1;
  position: relative;
  height: 100%;
  padding: 50px 16px 0 80px;
  align-items: flex-end;
}

.profilePictureFriend > div:nth-child(1) {
  margin-top: 100px;
  margin-bottom: 0px;
}

.friendTabCardWrapper {
  background: #fff 0 0 no-repeat padding-box;
  /* border: 1px solid #dededf; */
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  border-radius: 10px !important;
  width: 100%;
  height: 60px;
}

.connectionfriendTabCardWrapper {
  background: #eeeeee 0 0 no-repeat padding-box;
  /* border: 1px solid #dededf; */
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  border-radius: 10px !important;
  width: 100%;
  padding: 8px !important;
  padding-top: 20px !important;
}

.aboutFriendTitle {
  float: left;
  font-weight: bold;
  justify-content: space-between;
  margin-bottom: 10px;
}

.aboutFriendWrapper {
  border-radius: 10px;
  padding: 20px;
  cursor: pointer;
  background: #ffffff;
}

.friendInvitationCardWrapper {
  padding: 10px 1px;
  margin-bottom: 6px;
  border-radius: 1px;
  border-bottom: gray;
}

.redLabel {
  color: #ba2121 !important;
  /* border-radius:30px !important; */
  /* padding-top:  4px !important;
  padding-bottom: 4px !important; */
  font-size: 9px !important;
  background: #eec4c4 !important;
  padding: 6px 10px !important;
  float: right !important;
  margin-left: 12px !important;
  border-radius: 4px !important;
}

.friendCardInfor h2 {
  color: #333333;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden !important;
  padding: 10px 20px;
}
p.subhead {
  font-size: 13px;
  width: 100%;
  /* max-width: 45em; */
  /* margin: 0 auto; */
}

.subhead:first-letter {
  color: black;
  float: left;
  font-size: 6em;
  position: absolute;
  /* margin: 8px 2px 2px 0px; */

  /* margin: 0 .1em .1em .1em; */
  /* margin-top: 5px; */
}
.reqestDescription {
  /* background: #f3f5f9; */
  background: #f5f5f5;
  /* box-shadow: 5px 5px 6px #0000000d; */
  border: 1px solid #dededfbf;
  padding: 18px 35px 35px;
  border-radius: 3px;
}
h3.requestHomeTitle {
  font-size: 22px;
  letter-spacing: 0px;
  color: #333333;
  font-weight: bold;
  padding: 8px 1px 0px 0px;
}
.requestHomeSubTitle {
  font-size: 17px;
  letter-spacing: 0px;
  color: #333333;
  font-weight: bold;
  padding: 22px 0px 5px;
}
.myRequestCardWrapper {
  background: #ffffff;
  border: 1px solid #dededfbf;
  padding: 18px 35px 35px;
  border-radius: 10px;
  box-shadow: rgb(17 17 26 / 10%) 0px 4px 16px, rgb(17 17 26 / 5%) 0px 8px 32px;
}

.myRequestCardWrapperUser {
  background: #ffffff;
  box-shadow: 5px 5px 6px #0000000d;
  border: 1px solid #dededfbf;
  border-radius: 10px;
  padding-bottom: 8px;
}

.myRequestCardDetails {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 150px;
}
.myrequestTextfield {
  height: 41px;
  width: 100%;
  background-color: white;
  border: 1px solid #999999;
  border-radius: 5px;
}

.myRequestTagCard {
  margin-bottom: 16px;
  margin-top: 6px;
  /* height: 200px; */
}
.friendsConnectiontextOverflow {
  width: 127px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.otherRequestPage {
  margin-top: 70px !important;
  margin-bottom: 100px !important;
}
.segmentsList-forum:hover {
  cursor: pointer;
  background: gray;
  border-radius: 0 !important;
  margin-bottom: 0.4rem;
}
.segmentsList:hover {
  color: blue;
  border-radius: 10px !important;
  margin-bottom: 8px !important;
  cursor: pointer;
}
.segmentsList.selected {
  /* background: #eeeeee; */
  color: #a00030;
}
.dropdownshareCard {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 8px 7px #00000040;
  border: 1px solid #dededebf;
  border-radius: 4px;
  min-width: 258px;
  /* height: 17rem; */
  padding: 8px 15px 18px 17px;
  position: absolute;
  /* right: -76%; */
  /* cursor: pointer; */
  z-index: 1000;
  /* margin-top: 0; */
  /* width: 100%; */
  left: 0;
  bottom: 40px;
  right: 0;
}
.dropdownshareList {
  font-size: 16px;
  padding: 1px;
  cursor: pointer;
}
.homepostgroupCard {
  box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(222, 222, 223, 0.75);
  border-radius: 10px;
  background: #fff;
  margin-bottom: 16px;
  position: absolute;
  width: 200px;
  margin-top: 6px;
  height: 140px;
  overflow-y: scroll;
  z-index: 1;
}
.wallLoadingPage:block-ui-overlay {
  width: 0% !important;
  height: 81%;
  opacity: 0.5;
}

.block-ui-overlay {
  width: 0% !important;
  height: 100%;
  opacity: 0.5;
  filter: alpha(opacity=50);
}

.dropdownCardGroup {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dededebf;
  border-radius: 4px;
  width: 123px;
  height: auto;
  padding: 18px 14px 16px 17px;
  position: absolute;
  right: 2%;
  cursor: pointer;
  z-index: 10;
  float: left;
}

.thumbnail-view-challenge-card > img {
  height: auto !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: auto !important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.postGroupCardChallenge {
  padding: 12px;
  display: inline-flex;
}

.challengeCardDescription {
  line-height: 18px !important;
  /* max-height: 34px;  */
  overflow: hidden !important;
  /* text-overflow:ellipsis !important;
  white-space:nowrap !important;  */
}

.challengeCardUserName {
  line-height: 18px !important;
  max-height: 18px !important; /* line-height * 3 */
  overflow: hidden !important;
  font-weight: bold !important;
}

.challengeCardOthers {
  line-height: 18px !important;
  max-height: 18px !important; /* line-height * 3 */
  overflow: hidden !important;
}

.scrollTrendingGroups {
  box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(222, 222, 223, 0.75);
  border-radius: 10px;
  background: #fff;
  margin-bottom: 16px;
  position: absolute;
  width: auto;
  margin-top: 6px;
  height: 300px;
  overflow-y: scroll;
}

.challengeParticipantImg {
  height: 200px !important;
  width: 100% !important;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.challengeCardDesp {
  color: #333333;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden !important;
}

p.Grouppage {
  font-size: 16px;
  font-weight: 300;
  word-break: normal !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis !important;
  width: 150px;
}
.MuiFormLabel-root.Mui-focused {
  color: red !important;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #a00030 !important;
}

.MuiMenuItem-root {
  color: #db2828 !important;
  min-height: auto;
}

.postCardImage {
  /* height: 35rem; */
  cursor: pointer;
  margin: 2px;
}

.postCardImageSingle {
  /* height: 25rem; */
  height: auto;
  cursor: pointer;
}

.mentorCardWrapper {
  background: #fff 0 0 no-repeat padding-box;
  border: 1px solid #dededf;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  border-radius: 10px !important;
  width: auto;
}

.mentorCardInfor h2 {
  color: #333333;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0px;
}

.mentorCardInfor {
  text-align: center;
  margin-top: 15px;
  margin-bottom: 25px;
  height: 100px;
}
.mentorCardInfor p {
  font-size: 12px;
  color: #b3b3b3;
  margin-bottom: 0;
}
.mentorCardInfor h2:hover {
  color: #a00030;
}
.mentorCardInfor p:hover {
  color: #a00030;
}
.mentorupdateCardWrapper {
  background: #ffffff;
  box-shadow: 5px 5px 6px #0000000d;
  border: 1px solid #dededfbf;
  padding: 18px 35px 35px;
  border-radius: 10px;
  /* overflow-y: scroll; */
  /* height: 33rem; */
}
.noMoreCardWrapper {
  background: #ffffff;
  box-shadow: 5px 5px 6px #0000000d;
  border: 1px solid #dededfbf;
  padding: 8px 25px 16px;
  width: 13rem;
  margin-top: 3rem;
}
.navMenuInnerGap {
  margin-left: 40px;
}
.navMenuInnerGapNotHome {
  margin-left: 40px;
  padding: 5px;
}
.containerSlide {
  /* padding: 37px; */
  padding: 0px 34px 25px 34px;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;
  border: 1px solid #dededf;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
}

@media (min-width: 992px) {
  /* .screenInnerGapConnection{
    padding: 1px 10px 0px 3px;
   }
  .screenInnerGapGroup{
    padding:6px 0px 0px 0px; 
    background: #f5f5f5 0 0 no-repeat padding-box; 
    margin-left: 20px;
  } */
  .screenInnerGap {
    padding: 6px 0px 0px 7px;
    background: #f5f5f5 0 0 no-repeat padding-box;
  }
  /* .containerSlide {
    
    padding: 4px 34px 6px 34px;
    background: white;
    height: 30rem;
    overflow: scroll;
    overflow-x: hidden;
    
  } */
  /* .screenInnerGapFriendsCard { 
    padding:6px 0px 0px 0px; 
    background: #f5f5f5 0 0 no-repeat padding-box; 
  } */
}

@media (min-width: 992px) and (max-width: 1400px) {
  /* =========== SMALL SCREEN ========== */
  /* .screenInnerGapConnection{
      padding: 6px 10px 0px 26px !important;
      margin-left: 20px;
     }
     .screenInnerGapFriendsCard { 
      padding:7px 0px 0px 25px; 
      background: #f5f5f5 0 0 no-repeat padding-box; 
    } */
  /* .listCardWrapperLeaderHeader {
      margin-bottom: 20px;
      width: 194px;
      height: 46rem;
      position: fixed;
  } */
  .navHomeProfile p {
    font-size: 13px;
  }
  .screenInnerGap {
    padding: 6px 0px 0px 25px;
    background: #f5f5f5 0 0 no-repeat padding-box;
  }
  /* .screenInnerGapGroup{
        padding:6px 0px 0px 25px !important;
        background: #f5f5f5 0 0 no-repeat padding-box; 
        margin-left: 20px;
      } */
}
.rw-widget-input {
  color: #db2828 !important;
  background-color: #fff;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  background-clip: padding-box;
}

.form-control:focus {
  /* color: #db2828 !important; */
  background-color: #fff;
  border-color: #db2828 !important;
  outline: 0;
  box-shadow: #db2828 !important;
}

.resultsCardChallenge {
  /* background: #f3f5f9; */
  background: linear-gradient(
    to top,
    #ff7b6e 0,
    #ff7b6e 13%,
    #ff9b6b 43%,
    #ffa86b 66%
  );

  /* background: linear-gradient(to top, #A00030 10%, #EF5A3E 100%); */
  box-shadow: 5px 5px 6px #0000000d;
  border: 1px solid #dededfbf;
  padding: 18px 35px 35px;
  border-radius: 10px;
}

.resultData {
  width: 100%;
  background: #eeeeee;
  border-radius: 28px;
  border: none;
  margin: 0;
  height: 56px;
  padding: 0 0px;
  font-size: 14px;
  display: inline-flex;
  margin-top: 18px;
}

.resultHeaderIcon {
  font-size: 10px;
  font-weight: bold;
  color: #fff;

  border-radius: 5px;
  text-align: center;
  position: absolute;
  width: 26px;
  height: 15px;
  top: 5%;
  left: 94%;
  right: 0;
}

.confirm_selection {
  animation: glow 0.5s infinite alternate;
}

@keyframes glow {
  to {
    text-shadow: 0 0 10px red;
  }
}

.confirm_selection {
  font-size: 36px;
  font-weight: bold;
  color: gold !important;
}

.badgesTextPink {
  color: #a00030 !important;
  font-size: 14px !important;
  font-weight: bold !important;
  text-align: center !important;
  margin-left: 9px;
  margin-right: 22px;
}

.badgesTextNormal {
  padding-top: 20px !important;
}

.rightModal {
  min-width: 240px;
  z-index: 10;
  background: block;
  border-radius: 4px !important;
  color: #fff;
  bottom: 0;
  position: absolute;
  top: 0;
  padding-top: 45px;
  overflow-y: scroll;
}
.naviGationPadding {
  padding: 30px;
  position: "relative";
  background: "#f5f5f5";
}

.kycContainerInsideLoading {
  position: absolute;
  margin-top: 15%;
  margin-left: 25%;
  justify-content: center !important;
  align-items: center;
  background: #ffffff;
  box-shadow: 5px 5px 6px #0000000d;
  border: 1px solid #dededfbf;
  padding: 44px 35px 35px;
  border-radius: 10px;
  width: 50%;
}

#sub {
  vertical-align: sub !important;
  font-size: 12px;
}

.groupParticipantsImage {
  font-size: 14px;
  font-weight: bold;
  color: #a00030 !important;
  text-align: center;
  height: 10px;
  top: 15px;
  bottom: 10px;
}
.meetingModal {
  padding: 15px 15px 15px 15px !important;
  justify-content: center;
  background: #ffffff;
  width: 100% !important;
  font-size: 14px !important;
  border-color: #eeeeee;
  border-radius: 0px !important;
  height: 600px;
  align-items: center;
  margin-bottom: 6px;
  box-shadow: 0px 1px 3px #00000029;
  border: 12px solid #a00030;
  overflow-y: scroll;
}

.meetingListCard {
  background: #ffffff 0% 0% no-repeat padding-box;
  align-items: center;
  margin-bottom: 6px;
  box-shadow: 2px 2px 3px #00000029;
  padding: 12px;
  border-bottom: 0px solid gray;
  cursor: pointer;
  border: 1px solid #eeeeee;
}

.meetingSelectCard {
  background: #eeeeee;
  margin-top: 10px;
  height: 500px;
  width: auto;
  align-items: center;
  padding: 20px 15px;
  margin-bottom: 6px;
  box-shadow: 0px 1px 3px #00000029;
  border: 1px solid #f7f7f7;
  border-radius: 3px;
}

.meetingSelectRightCard {
  background: #fff;
  margin-top: 10px;
  height: 500px;
  width: auto;
  align-items: center;
  padding: 20px 15px;
  margin-bottom: 6px;
  box-shadow: 0px 1px 3px #fff;
  border: 1px solid #f7f7f7;
  border-radius: 3px;
}
/* @media only screen and (min-width: 790px) and (max-width: 810px)  { 
  .navMenuInnerGap{
    margin-left: 0px;
    margin-right: 0rem;
      
  }
} */
@media only screen and (min-width: 810px) and (max-width: 900px) {
  .navMenuInnerGap {
    margin-left: 1rem;
    margin-right: 0rem;
    padding: 0px 10px;
  }
}
@media only screen and (min-width: 900px) and (max-width: 980px) {
  .navMenuInnerGapNotHome {
    margin-left: 3.1rem;
    padding: 5px;
  }
  .navMenuInnerGap {
    /* margin-left: 1rem; */
    /* margin-right: 8rem; */
    padding: 0px 10px;
  }
  .screenInnerGap {
    margin-left: 30px;
    background: #f5f5f5 0 0 no-repeat padding-box;
  }
}
@media (min-width: 980px) and (max-width: 1100px) {
  .navMenuInnerGap {
    margin-left: 1px;
    /* margin-right: 128px; */
    padding: 0px 10px;
  }
  .listCardWrapperLeaderHeader {
    margin-bottom: 20px;
    width: 12rem;
    height: 46rem;
    position: fixed;
  }
  .homeCardWrapper {
    background: #ffffff 0% 0% no-repeat padding-box;
    align-items: center;
    margin-bottom: 6px;
    box-shadow: 0px 1px 3px #00000029;
    padding-bottom: 10px;
    border-bottom: 0px solid gray;
    cursor: pointer;
    width: 9rem;
  }
  .screenInnerGap {
    margin-left: 30px;
    background: #f5f5f5 0 0 no-repeat padding-box;
  }
}
@media (min-width: 1100px) and (max-width: 1160px) {
  .workspace-button {
    position: absolute;
    right: 12px !important;
    top: 74px;
  }
  .navMenuInnerGap {
    margin-left: 0px;
    /* margin-right: 112px; */
    padding: 0px 10px;
  }
  .screenInnerGap {
    padding: 6px 0px 0px 0px;
    background: #f5f5f5 0 0 no-repeat padding-box;
  }
  .listCardWrapperLeaderHeader {
    margin-bottom: 20px;
    width: 207px;
    height: 46rem;
    position: fixed;
  }
  .homeCardWrapper {
    background: #ffffff 0% 0% no-repeat padding-box;
    align-items: center;
    margin-bottom: 6px;
    box-shadow: 0px 1px 3px #00000029;
    padding-bottom: 10px;
    border-bottom: 0px solid gray;
    cursor: pointer;
    width: 9rem;
  }
}
@media (min-width: 1160px) and (max-width: 1200px) {
  .workspace-button {
    right: 11px !important;
  }
  .navMenuInnerGap {
    margin-left: 0px;
    /* margin-right: 112px; */
    padding: 0px 1px;
  }
  .navHomeProfile p {
    font-size: 11px;
  }
  .screenInnerGap {
    padding: 6px 0px 0px 0px;
    background: #f5f5f5 0 0 no-repeat padding-box;
  }
  .listCardWrapperLeaderHeader {
    margin-bottom: 20px;
    width: 215px;
    height: 46rem;
    position: fixed;
  }
  .homeCardWrapper {
    background: #ffffff 0% 0% no-repeat padding-box;
    align-items: center;
    margin-bottom: 6px;
    box-shadow: 0px 1px 3px #00000029;
    padding-bottom: 9px;
    border-bottom: 0px solid gray;
    cursor: pointer;
    width: 12rem;
  }
}
@media (min-width: 1200px) and (max-width: 1260px) {
  .navMenuInnerGapNotHome {
    margin-left: 0px;
    padding: 5px;
  }
  .navHomeProfile p {
    font-size: 11px;
  }
  .navMenuInnerGap {
    margin-left: 0px;
    margin-right: 72px;
    padding: 0px 0px;
  }
  .screenInnerGap {
    padding: 5px 6px 2px 0px;
    background: #f5f5f5 0 0 no-repeat padding-box;
  }

  .listCardWrapperLeaderHeader {
    margin-bottom: 20px;
    width: 280px;
    height: 46rem;
    position: fixed;
  }
}

@media (min-width: 1260px) and (max-width: 1280px) {
  .navMenuInnerGapNotHome {
    margin-left: 0px;
    padding: 5px;
  }
  .navHomeProfile p {
    font-size: 11px;
  }
  .navMenuInnerGap {
    margin-left: 0px;
    margin-right: 75px;
    padding: 0px 0px;
  }
  .screenInnerGap {
    padding: 6px 0px 0px 0px;
    background: #f5f5f5 0 0 no-repeat padding-box;
  }
}
@media only screen and (min-width: 1280px) and (max-width: 1400px) {
  .navMenuInnerGap {
    margin-left: 30px;
    margin-right: 43px;
    padding: 0px 10px;
  }
  .navMenuInnerGapNotHome {
    margin-left: 5rem;
    /* margin-right: 43px; */
    padding: 5px;
  }
  .workspace-button {
    position: absolute;
    right: 11px !important;
    top: 74px;
  }

  .screenInnerGapConnection {
    padding: 1px 10px 0px 3px;
  }
  .listCardWrapperLeaderHeader {
    margin-bottom: 20px;
    width: 279px;
    height: 46rem;
    position: fixed;
  }
  .screenInnerGap {
    padding: 6px 0px 0px 35px;
    background: #f5f5f5 0 0 no-repeat padding-box;
  }
}

@media (min-width: 980px) and (max-width: 1100px) {
  .navHomeProfile p {
    font-size: 11px;
  }
  .mentorCard {
    background: #fff;
    width: 18em !important;
    border-radius: 0.6em;
    margin: 0.2em;
    overflow: hidden;
    cursor: pointer;
    box-shadow: 0 13px 27px -5px hsl(240deg 30% 28% / 25%),
      0 8px 16px -8px hsl(0deg 0% 0% / 30%),
      0 -6px 16px -6px hsl(0deg 0% 0% / 3%);
    transition: all ease 200ms;
  }
}

.friendsCardAreaofinterest p {
  color: #333333;
  font-size: 12px !important;
  font-weight: bold;
  margin-bottom: 0px;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}

.newQuestionCardVoteBc {
  box-shadow: 2px 2px 2px #0000000f;
  border-radius: 10px;
  color: #ffffff !important;
  font-size: 14px;
  /* top: 437px; */
  /* left: 65px; */
  /* width: 58px; */
  /* height: 55px; */
  background: #edf6fc;
  padding: 2px;
  opacity: 1;

  /* box-shadow: 2px 2px 2px #0000000F;
  border-radius: 10px;
  color: #ffffff !important;
  font-size: 18px;
  top: 437px;
  left: 65px;
  width: 58px;
  height: 55px;
  padding: 10px !important;
  background: #edf6fc;
  opacity: 1; */
}

.newQuestionCardVoteBcMobile {
  color: #1ab4de !important;
  font-size: 10px;
  margin-top: 25px;
  display: flex !important;
  font-weight: bold;
  margin-left: 6px;
}
.newaskedImg img {
  width: 25px;
  height: 25px;
  border-radius: 100%;
}
p.newaskedName {
  font-size: 8px;
  font-weight: 300;
  display: flex !important;
}
.askedInfo {
  margin-left: 8px;
}
.newquestionVotes p {
  color: #838e98;
  text-align: center;
  font-size: 16px;
}

.newquestionVotes small {
  color: #838e98;
  font-size: 10px;
  display: block;
  text-align: center;
  margin-bottom: 10px;
}

.newquestionVotes svg {
  color: #21ba45;
  display: block;
  margin: 0 auto;
}

.newquestionVotes {
  /* margin: 10px 10px; */
}
.prefferenceCard {
  background: #ffffff;
  /* margin-top: 10px; */
  width: auto;
  align-items: center;
  padding: 20px 15px;
  margin-bottom: 6px;
  box-shadow: 0px 1px 3px #00000029;
  border: 1px solid #f7f7f7;
  border-radius: 3px;
  overflow-y: scroll;
}

.dropdownshareCard p:hover {
  color: #a00030;
}
.reportMenuColor {
  color: black !important;
}

.slick-slide img {
  margin: auto;
}
.slick-next:before {
  font-family: "slick", sans-serif;
  font-size: 27px !important;
  line-height: 1;
  opacity: 0.75;
  color: #a00030 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick", sans-serif;
  font-size: 27px !important;
  line-height: 1;
  opacity: 0.75;
  color: white !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.post-replay-control {
  display: block;
  width: 100%;
  padding: 0.5rem 1rem;
  padding-right: 1rem;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.8;
  color: #29292e;
  background-clip: padding-box;
  border: 1px solid #e1e4e6;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.6rem;
  background: #fbfbfb;
}
.replayButton {
  border-radius: 17px !important;
  font-size: 11px !important;
  padding: 5px 10px 6px 10px;
  float: right;
  background: gray;
  /* border: 1px rgb(245, 245, 245); */
  border: 1px solid gray;
  color: white;
}
.replayButtonPost {
  border-radius: 17px !important;
  font-size: 11px !important;
  padding: 5px 10px 6px 10px;
  float: right;
  /* border: 1px rgb(245, 245, 245); */
  border: 1px solid gray;
  color: white;
}
.list-replay {
  padding-left: 59px;
  list-style: none;
}
/* .nav-item-post-card a:hover {
  color: #a00030 !important;
} */
.replayComment {
  color: #a00030;
  text-decoration: underline;
  cursor: pointer;
  font-size: 12px;
}
.p-reply {
  padding: 5px 3rem 20px 15px !important;
}
.container-box {
  position: relative;
}
.container-box textarea {
  display: block;
  width: 100% !important;
  resize: vertical;
  background: #fbfbfb;
  border: 1px solid #e1e4e6;
  border-radius: 9px;
  padding: 9px 13px 0px 13px;
}

.container-box .reply-btn {
  padding: 5px 11px;
  font-size: 20px;
  position: absolute;
  right: 30px;
  bottom: 17px;
  border-radius: 30px !important;
}
.containerSlide p {
  font-size: 14px;
  color: gray;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 0.2rem !important;
}

.languageIcon {
  display: flex;
  cursor: pointer;
  padding-top: 5px;
}
.languageIcon p {
  padding: 2px;
  font-size: 19px;
  font-weight: 500;
  color: lightslategrey;
}
.languageIcon p:hover {
  color: #a00030;
}
.languageInputWrapper select {
  border-color: gainsboro;
  /* border-radius: 5px; */
  background: white;
  font-size: 15px;
  border: none;
  cursor: pointer;
}
.service-small option {
  font-size: 14px;
  padding: 5px;
  /* background: #5c5c5c; */
}
.service-small option:hover {
  background: #5c5c5c !important;
}
.w3-list-row {
  border: 0px solid #aaa;
  width: 100%;
  background-color: #fff;
  margin-top: 28px;
}
.bannerImg-playList {
  height: 10rem;
  width: 100%;
  object-fit: cover;
  object-position: top center;
  border-radius: 10px 10px 0px 0px;
}
.peopleCardInfor-play {
  text-align: center;
  margin-bottom: 23px;
}
.peopleCardInfor-play h2 {
  color: #333333;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden !important;
  padding: 10px 10px;
  text-transform: capitalize;
}
.toggle-switch-event {
  position: relative;
  width: 60px;
  height: 29px;
  display: inline-block;
  text-align: left;
  top: 16px;
  font-size: 10px;
}
.tabWrapperPoints {
  background: white;
  border-radius: 5px;
}
.userDetailsWrapper-friend {
  padding: 35px 0px 15px 0px;
}
#cb1 {
  accent-color: #a00030;
}
.forumCardWrapper {
  background: #ffffff 0% 0% no-repeat padding-box;
  align-items: center;
  padding: 11px 12px;
  margin-bottom: 6px;
  box-shadow: 0px 1px 3px #00000029;
  border: 1px solid #f7f7f7;
  border-radius: 2px;
}
.forum-applyButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1rem;
}
.memberCount {
  font-size: 11px;
  color: #a00030;
  padding-top: 3px;
  padding-left: 10px;
}
.workspace {
  justify-content: center;
  display: flex;
  cursor: pointer;
  align-items: center;
}
.dropdown-work-space {
  background: #ffffff 0% 0% no-repeat padding-box;
  /* box-shadow: 5px 5px 25px #00000040; */
  border: 1px solid #dededebf;
  border-radius: 4px;
  padding: 15px 14px 16px 17px;
  position: absolute;
  cursor: pointer;
  z-index: 10;
  top: 4em;
}
.dropdown-work-space p:hover {
  color: gray;
}
.chooseCardListSelected {
  font-size: 14px;
  padding: 3px;
  color: #a00030;
}
.page-item.active .page-link {
  z-index: 0 !important;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.userList {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  /* display: flex;
  gap: 1rem;
  margin-bottom: 10px;
  flex-wrap: wrap;
  justify-content: space-between; */
}
.userListCard {
  background: white;
  padding: 15px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
}
.userList-search {
  max-width: 100%;
  padding: 0 1.5em;
  background-color: #f8f9fa !important;
  border: 1px solid lightgray;
  border-radius: 0.5em;
  overflow: hidden;
  max-height: 2.3rem;
}
.userTable div:hover {
  color: red;
}
.selectedMenu {
  color: red !important;
}
.unSelectedMenu {
  color: #000 !important;
}

.text-area-workSpace {
  width: 100%;
  min-height: 40px !important;
  border-radius: 3px;
  border-color: lightgray;
  overflow: hidden;
}
.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
  background-color: rgba(0, 0, 0, 0.08);
  color: blue !important;
}

.adb-card-wrap {
  flex: 5 1 303px;
  margin-top: 5px;
}
.adb-card {
  display: flex;
  flex-wrap: wrap;
}
.un-active-menu {
  flex: 1;
  border-radius: 0px !important;
  background: white;
  color: black;
  border-color: gray;
}
.active-menu {
  flex: 1;
  border-radius: 0px !important;
  background: #d3d3d378;
  color: #a00030;
  border-color: gray;
}
.img-grid-chat {
  width: auto;
  min-height: 300px;
  background-size: 100% 100%;
  box-shadow: 0px -5px 18px 0px rgb(0 0 0 / 24%);
}
.popover {
  /* z-index: 999 !important; */
}
/* .img-grid-chat:hover{
  opacity: 1;
  transition: .9s;
} */
.user-post-description-textarea {
  padding: 10px 1px 0px 9px;
  border-color: darkgray;
  border-radius: 5px;
  height: 57px;
  flex: 1;
}
.user-post-des {
  font-size: 12px;
  color: gray;
  text-indent: 40px;
}
.home-post-card-wrap1 {
  flex: 2 1 303px;
  margin-top: 5px;
}
.home-post-card-wrap2 {
  flex: 1 1 303px;
  margin-top: 5px;
}
.home-post-comment-wrap {
  border: 1px solid lavender;
  background: #f5f5f5;
  border-radius: 7px;
  padding: 10px;
}
.sendMsgBtnHome {
  background: lightgray !important;
  border: 1px solid gray !important;
  color: black !important;
  margin-left: 5px;
}
.adb-card p {
  font-size: 13px;
  color: gray;
}
.home-post-overflow {
  margin-top: 2rem;
  height: 46rem;
  overflow: scroll;
}
.MuiPaper-elevation8 {
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
    0px 8px 10px 1px rgb(0 0 0 / 0%), 0px 3px 14px 2px rgb(0 0 0 / 0%) !important;
}
.img-black-theme {
  align-items: center;
  display: flex;
  justify-content: center;
  background: black;
}
.post-date-small {
  font-weight: 400;
  font-size: 0.7rem;
  color: #676a79 !important;
}
.post-badge-secondary {
  color: #676a79;
  background-color: #6c757d29;
  font-size: 10px !important;
  font-weight: 420 !important;
  text-transform: capitalize;
  padding: 5px !important;
}
.home-img-icon {
  width: 39px;
  height: auto;
  cursor: pointer;
}
.home-img-icon-like {
  width: 34px;
  height: 42px;
  cursor: pointer;
  object-fit: cover;
}
.home-img-icon-like:hover {
  opacity: 0.6;
}
.comment-overflow {
  /* max-height: 35rem;
  overflow-y: scroll;
  overflow-x: hidden; */
}
.like-count {
  margin-bottom: 0.5rem !important;
  font-size: 0.65em;
  font-weight: 400;
  line-height: 1.5;
  color: #000000bd;
  margin-top: 5px !important;
  display: flex;
  align-content: center;
  align-items: center;
  width: fit-content;
}
.home-like-img-icon {
  width: 39px;
  height: 51px;
  margin-right: 6px;
}

.bg-color {
  color: #a00030 !important;
}
.bck-color {
  background: #a00030 !important;
}
.workspace-card {
  display: flex;
  flex-wrap: wrap;
  padding: 25px;
  background: white;
  border-radius: 10px;
  box-shadow: rgb(50 50 93 / 25%) 0px 13px 27px -5px,
    rgb(0 0 0 / 30%) 0px 8px 16px -8px;
}
.workspace-card-wrap1 {
  flex: 3 1 303px;
  margin-top: 5px;
}
.workspace-card-wrap2 {
  flex: 1 2 303px;
  margin-top: 5px;
}
.workspace-card p {
  display: block;
  font-size: 18px;
  font-weight: 500;
  color: #999999;
  margin-bottom: 7px !important;
  padding-right: 20px;
}
.workspace-btn {
  background: #0000ff73;
  border-color: #60c2e7;
  color: black;
  float: right;
  margin-right: 2rem;
}

.header-logo-inner {
  display: block;
  margin: 0 auto;
  height: 50px;
}
.headerDynamicLogo {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 72px;
  width: 251px;
  /* height: 74px;
  width: auto; */
  padding: 5px;
}
.headerDynamicLogo > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.peopleInvitationCardWrapper :is(p, h6):hover {
  color: #a00030;
}
.hr-tag {
  border-top: 2px solid #a00030;
  margin-top: 10px;
  margin-bottom: 1px;
}
input[type="checkbox"] {
  accent-color: #a00030;
}
.MuiAvatar-colorDefault {
  color: #fafafa;
  background-color: #a00030 !important;
}
.MuiChip-root .MuiChip-avatar {
  /* color: #616161; */
  width: 24px;
  height: 24px;
  font-size: 0.75rem;
  margin-left: 5px;
  margin-right: -6px;
  color: white !important;
}
.group-chip {
  overflow: hidden;
  font-size: 12px;
  text-overflow: ellipsis;
  text-transform: capitalize;
  max-width: 138px;
}
.PrivateTabIndicator-colorSecondary-3 {
  background-color: #a00030 !important;
}
.card-header-profile {
  display: flex;
  padding: 12px 2px 43px 4px;
  justify-content: center;
  cursor: pointer;
}
.card-body-profile {
  flex: 3 1 0%;
  background: white;
  border-top-left-radius: 17px;
  border-top-right-radius: 17px;
  cursor: pointer;
}
.card-profile {
  display: flex;
  flex-direction: column;
  /* background: linear-gradient(90deg, #000940 0%, #a00030 100%); */
  border-radius: 21px;
  background: #e4ccd4;
}
.avatar-profile {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 11px;
  margin-top: -37px;
}
.avatar-profile img {
  max-width: 100%;
  object-fit: cover;
}
.dis-color {
  color: gray;
  font-size: 0.9375rem;
}
.social-icons {
  color: rgb(160, 0, 48);
  width: 21px;
  height: 33px;
  cursor: pointer;
  margin-right: 16px;
}
.option-profile {
  border: 1px solid gray;
  padding: 4px 11px;
  border-radius: 7px;
  margin-left: 3rem;
}
.small-font {
  font-size: 12px;
  color: gray;
}
.bg.grad {
  background: linear-gradient(90deg, #000940 0%, #a00030 100%);
}
.social-icons:hover {
  color: blue;
}
.profileCopy-card p {
  cursor: pointer;
  font-size: 12px;
  text-decoration: underline;
  color: blue;
}
.profileCopy-card p:hover {
  color: rgb(160, 0, 48);
}
.unAccess-page {
  margin-top: 90px;
  margin-left: -24%;
  display: flex;
  justify-content: center;
}
.access-page {
  margin-top: 90px;
  margin-left: 5rem;
}
.copy-icon {
  margin-bottom: 1px;
  width: 18px;
  height: 14px;
  cursor: pointer;
}
.arrowIcon-home {
  width: 18px !important;
  height: auto !important;
  transition: all 0.4s ease;
}
.arrow-rotate {
  transform: rotateZ(90deg);
}
.add-slow {
  transition: all 0.4s ease;
}
.home-item-card {
  display: flex;
  flex-wrap: wrap;
  margin-top: -9px;
  grid-gap: 6px;
  gap: 6px;
}
.home-item-card p {
  font-size: 0.8rem;
  font-weight: 400;
  color: #333333;
  padding-left: 4.5rem;
  cursor: pointer;
  flex-basis: 100%;
}
.home-item-card p:hover {
  color: blue;
}
.animi-icon {
  width: 30px;
  height: auto;
  cursor: pointer;
}
.option-profile1 {
  border: 1px solid gray;
  padding: 7px 9px;
  border-radius: 7px;
  margin-left: 3rem;
  cursor: pointer;
}
.my-element {
  cursor: pointer;
}
.card-body-profile p:hover {
  color: rgb(160, 0, 48);
}
.postuserImg-fourm {
  display: block !important;
  /* margin-left: auto !important; */
  /* margin-right: auto !important; */
  object-fit: cover !important;
  object-position: top center !important;
  width: 50px !important;
  height: 50px !important;
  border-radius: 50px !important;
  box-shadow: rgb(0 0 0 / 16%) 0px 0px 5px !important;
  border: 3px solid rgb(255, 255, 255) !important;
}
.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 9rem;
}
.postuserImg-vcard {
  margin-top: -1.9rem;
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
  object-fit: cover !important;
  object-position: top center !important;
  width: 75px !important;
  height: 75px !important;
  border-radius: 50px !important;
  box-shadow: rgb(0 0 0 / 16%) 0px 0px 5px !important;
  border: 3px solid rgb(255, 255, 255) !important;
  margin-bottom: 0.5rem;
}
.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
  background-color: #a00030 !important;
  color: white !important;
}
.select.decorated option:hover {
  background-color: #a00030 !important;
}
.qr-active {
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  /* transform: rotateY(0deg);
  transition: transform 0.1s ease-in-out 0s;
  cursor: pointer; */
}
.qr-unactive {
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  /* transform: rotateY(0deg);
  transition: transform 1.3s ease-in-out 0s;
  cursor: pointer; */
  width: 300px;
}
.select {
  padding: 10px;
}
.qr-present {
  width: auto;
  display: block;
  margin: 0 auto;
}
.qr-card {
  padding: 2.1rem;
  display: flex;
  flex: 3 1;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
}
.lang-card {
  border-radius: 4px;
  background: white;
  position: absolute;
  top: 5.2rem;
  box-shadow: rgb(0 0 0 / 31%) 0px 2px 5px;
  /* overflow: scroll;
  overflow-x: hidden;
  height: 16rem; */
}
.lang-card-overflow > div:hover {
  background-color: rgb(160, 0, 48);
  cursor: pointer;
  border-radius: 4px;
  color: white !important;
}

.lang-card p {
  padding: 0.7rem;
}
.lang-card::before {
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 2px solid transparent;
  border-bottom: 9px solid #fff;
  content: "";
  top: -9px;
  position: absolute;
  right: 72px;
  z-index: 2;
}
.selected-lang {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 0.7rem;
}
.selected-lang-option > p {
  padding-right: 15px;
  /* padding-top: 8px; */
  font-weight: 600;
  cursor: pointer;
}
.selected-lang-option > p:hover {
  color: #a00030;
}
/* .full-bg-color {
  background-color: #a00030 !important;
  color: white;
  border-radius: 4px;
} */
.up-arrow {
  position: fixed;
  bottom: 90px;
  right: 1rem;
  padding: 3px;
  opacity: 1;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #a00030ba;
  z-index: 10;
  border-radius: 50%;
  cursor: pointer;
}
.up-arrow:hover {
  background-color: gray;
}
.lang-card-overflow {
  /* height: 12rem; */
  overflow: scroll;
  overflow-x: hidden;
}

.lang-card-overflow::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}
.lang-card-overflow::-webkit-scrollbar {
  width: 2px;
  background-color: #f5f5f5;
}
.lang-card-overflow::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #a00030;
}
.home-img-icon-select {
  width: 34px;
  height: 39px;
  cursor: pointer;
  object-fit: cover;
}
.filter-btn {
  color: black !important;
  background: rgb(245, 245, 245);
}
.home-img-icon-friend {
  width: 42px;
  height: 53px;
  cursor: pointer;
}
.calender-edit {
  width: 23px;
  height: 19px;
  color: gray;
  margin-bottom: 2px;
  margin-left: 5px;
  cursor: pointer;
}
.stepper p {
  font-size: 13px;
  color: gray;
  max-width: 25rem;
}
.stepper {
  margin-top: 1.1rem;
}
.stepper-box {
  /* margin-top: 13px; */
  /* background: white; */
  padding: 2px;
  border-radius: 12px;
  /* box-shadow: 5px 5px 6px #0000000d; */
}
.stepper-box > div:nth-child(2) {
  color: #a00030;
  cursor: pointer;
}
.stepper-left {
  background: white;
  /* padding: 1rem; */
  border-radius: 12px;
  /* box-shadow: 5px 5px 6px #0000000d; */
}
.stepper-left > div:nth-child(1) {
  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
  gap: 2rem;
  padding: 23px 0px 64px 28px;
}
.radio-box input[type="radio"] {
  cursor: pointer;
  width: 26px;
  height: 18px;
}
.file-uploader {
  align-items: center;
  justify-content: center;
  border-style: dotted;
  display: flex;
  text-align: center;
  border-radius: 12px;
  cursor: pointer;
  flex-flow: column;
}
.details-card {
  margin-top: 1rem;
  margin-bottom: 1rem;
  /* width: 19rem; */
  background: white;
  padding: 14px;
  border-radius: 12px;
  box-shadow: 5px 5px 6px #0000000d;
}
.market-checkbox {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.wizard-icon {
  width: 24px;
  height: 24px;
  color: gray;
}
.cancel-btn {
  font-size: 12px !important;
  padding: 2px 6px 3px 6px !important;
}
.my-button {
  position: fixed;
  right: 0;
  top: 16rem;
  font-size: 15px !important;
  font-weight: bold;
  transform: translate(36%, 50%) rotate(270deg);
  border: 1px solid gray;
  transition: transform 0.2s ease-in-out;
}
.my-button:hover {
  opacity: 0.5;
}
.peopleInvitationCardWrapper1 {
  background: #ffffff 0% 0% no-repeat padding-box;
  align-items: center;
  padding: 13px 10px;
  margin-bottom: 6px;
  box-shadow: rgb(17 17 26 / 10%) 0px 4px 16px, rgb(17 17 26 / 5%) 0px 8px 32px;
  border: 1px solid #f7f7f7;
  border-radius: 10px;
  cursor: pointer;
}
.gird-myreq {
  display: grid;
  /* grid-template-columns: repeat(auto-fit, minmax(17rem, 1fr)); */
  align-items: center;
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.8rem;
}
.gird-myreq > p {
  color: gray;
}
.gird-myreq li {
  font-size: 13px;
}
.gird-myreq div {
  font-size: 13px;
}
.askAQuestionCardWrapperNew {
  background: #ffffff;
  box-shadow: rgb(17 17 26 / 10%) 0px 4px 16px, rgb(17 17 26 / 5%) 0px 8px 32px;
  border: 1px solid #dededfbf;
  padding: 18px 35px 35px;
  border-radius: 7px;
}
.homeMyRequestCard::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
.homeMyRequestCard::-webkit-scrollbar-thumb {
  height: 120px;
}
.community-tag-wrapper::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
.community-tag-wrapper::-webkit-scrollbar-thumb {
  height: 120px;
}
.community-tag-wrapper {
  height: 11rem;
  overflow: scroll;
  text-transform: capitalize;
}
.otherRequestPage-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.otherRequestPage-wrapper:hover {
  color: blue;
}
.setupMainCard::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
.announcement-image {
  width: 40%;
  aspect-ratio: 3/2;
  object-fit: cover;
  height: 50%;
  mix-blend-mode: color-burn;
  margin-top: 5px;
}
.need-help-Modal {
  width: 100vh;
  z-index: 10;
  background: block;
  border-radius: 4px !important;
  color: #0000007a;
  bottom: 0;
  /* position: absolute; */
  top: 0;
  /* padding-top: 45px; */
  overflow-y: scroll;
  position: static;
}

.ask-badge {
  position: fixed;
  top: 14.5rem;
  right: 0;
  z-index: 1;
}
.need-help-Modal-close {
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-left: 12.6rem;
}
.need-help-wrapper {
  padding: 12px;
  color: black;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.need-help-card {
  position: relative;
  background: #2b544c1f;
  padding: 9px;
  max-width: 19.3rem;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.ask-help-imgCard {
  display: block !important;
  object-fit: cover !important;
  object-position: top center !important;
  width: 65px !important;
  height: 65px !important;
  border-radius: 50px !important;
  box-shadow: rgb(0 0 0 / 16%) 0px 0px 5px !important;
  border: 3px solid rgb(255, 255, 255) !important;
}
.need-help-card p {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 6rem;
  white-space: nowrap;
  color: gray;
  font-size: 10px;
}
.need-help-card a {
  color: gray !important;
}
.need-help-card :hover {
  cursor: pointer;
}
.arrow-request {
  position: absolute;
  top: 0;
  right: 0;
  width: 37px;
  height: 31px;
  background: #a0000042;
  border-radius: 0px 8px 0px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.arrow-request-svg {
  width: 20px;
  height: 18px;
  color: #0000009c;
  margin-bottom: 4px;
}
.need-help-card:hover {
  transition: all 0.2s ease-out;
  box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
  top: -4px;
  border: 1px solid #cccccc;
  background-color: #a0000042;
}
.req-un-active-menu {
  width: 10.3rem;
  background: #80808036;
  color: black;
  border-color: white;
  border-radius: 15px !important;
}
.req-un-active-menu:hover {
  background: #808080ab;
}
.req-active-menu {
  width: 10.3rem;
  color: white;
  background: black;
  border-color: white;
  border-radius: 15px !important;
}
.annoucmentBox img {
  width: 75vw;
  height: auto;
}

.annoucmentBox h3 {
  font-size: 31px;
  line-height: 42px;
  letter-spacing: -1.4px;
  font-weight: normal;
  color: #22222278;
  margin-top: 1rem;
  margin-left: 1rem;
}
.image-container {
  position: relative;
}
.announcement-overlay {
  background-color: rgb(0 0 0 / 53%);
  color: white;
  padding: 10px;
  width: 100%;
}

.svg-icons {
  width: 24px;
  height: 24px;
  cursor: pointer;
  color: black;
}
.slick-next:before {
  content: "→";
  margin-left: -3rem !important;
  color: black !important;
}
.home-announcement > p {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.home-announcement-wrapper {
  position: absolute;
  bottom: 13px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px;
}
.home-announcement-wrapper > h3 {
  margin: 0;
}
.waring-box {
  display: flex;
  flex: 1;
  justify-content: end;
}
.waring-box > p {
  margin-right: 1rem !important;
  font-size: 12px;
  color: gray;
  margin-top: 4px !important;
}
.search-container {
  position: relative;
  /* display: inline-block; */
  margin-right: 10px;
  width: 100%;
  display: flex;
}

.search-container input[type="text"] {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  width: 100%;
}

.search-container ul {
  position: absolute;
  top: 57px;
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0;
  margin: 0;
  z-index: 1;
}

.search-container li {
  list-style: none;
  padding: 10px;
  cursor: pointer;
}

.search-container li:hover {
  background-color: #f2f2f2;
}
.rdw-suggestion-dropdown {
  position: absolute;
  display: flex;
  flex-direction: column;
  border: 2px solid rgba(0, 0, 0, 0.15) !important;
  min-width: 100px;
  max-height: 150px;
  overflow: auto;
  background: white !important;
  cursor: pointer !important;
  z-index: 100;
}
.rdw-suggestion-dropdown > span:hover {
  background: #a00030 !important;
  color: white;
}
.textarea-comment {
  border: 1px solid #ccc;
  border-radius: 7px;
  padding: 5px;
  background: white;
}
.editor-wrapper {
  overflow: hidden;
}
.form-control-comment {
  overflow-y: hidden !important;
  resize: none;
  /* max-height: 5rem !important; */
  /* font-size: 16px;
  line-height: 1.5;
  height: 200px; */
}
/* .rdw-suggestion-wrapper {
  position: fixed;
  top: 100px; 
  left: 100px;  
  z-index: 1; 
} */
.editor-textFiled {
  background-color: #000;
}
.DraftEditor-editorContainer::-webkit-scrollbar {
  width: 4px;
  height: 20px;
}
.box-shadow {
  border: 1px solid rgb(0 0 0 / 10%) !important;
  border-radius: 5px !important;
}
.skeleton {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background-color: #f0f0f0;
}

.skeleton-img {
  width: 100px;
  height: 100px;
  background-color: #ccc;
}

.skeleton-text {
  flex: 1;
  height: 1.5rem;
  background-color: #ccc;
}
.underline-text {
  cursor: pointer;
  font-size: 12px;
  text-decoration: underline !important;
  color: blue !important;
}
.csv-button {
  border: 3px solid gray;
  position: relative;
  display: inline-block;
  overflow: hidden;
  cursor: pointer;
  text-align: center;
  border-style: dashed;
  width: 94%;
  border-radius: 13px;
}

.csv-button:before {
  content: "Drag and drop a file here or click to select a file";
  position: absolute;
  top: 21px;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  text-align: center;
  line-height: 1;
  pointer-events: none;
  z-index: 1000;
  background: white;
}
.user-count {
  position: absolute;
  left: 28px;
  bottom: 22px;
}

.csv-button input[type="file"] {
  padding: 27px 35px;
  cursor: pointer;
  width: 100%;
}
.csv-button:hover {
  cursor: pointer;
  border: 3px solid #db2828;
  border-style: dashed;
}
.react-share__ShareButton {
  background: white !important;
}
.workspace-button {
  position: absolute;
  right: 27px;
  top: 74px;
}
.add-btn {
  font-size: 12px !important;
  background: #0000ff29 !important;
  padding: 6px 10px !important;
  margin-left: 12px !important;
  float: right !important;
  border-radius: 4px !important;
}
.add-btn-green {
  background: #c4eece !important;
}
.hashtag-style {
  gap: 0.6rem;
  font-size: 1rem;
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  margin-bottom: 1rem;
  align-items: center;
}
.hashtag-style-group {
  gap: 0.6rem;
  font-size: 1rem;
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.2rem;
  margin-left: 0.8rem;
  align-items: center;
}
.directory-card {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
}
.directory-card-wrapper {
  /* width: 24rem; */
  height: 14rem;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  color: #f0f0f0;
  box-shadow: 0 10px 30px 5px rgba(0, 0, 0, 0.2);
}
.directory-card-wrapper img {
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.9;
  transition: opacity 0.2s ease-out;
  filter: brightness(0.5);
}
.directory-card-wrapper h2 {
  position: absolute;
  inset: auto auto 30px 30px;
  margin: 0;
  transition: inset 0.3s 0.3s ease-out;
  font-family: "Roboto Condensed", sans-serif;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: bold;
}
.directory-card-wrapper p {
  position: absolute;
  opacity: 0;
  max-width: 80%;
  transition: opacity 0.3s ease-out;
  font-size: 12px;
  inset: auto auto 80px 30px;
}

.directory-card-wrapper:hover h2 {
  inset: auto auto 115px 30px;
  transition: inset 0.3s ease-out;
}
.directory-card-wrapper:hover p {
  opacity: 1;
  transition: opacity 0.5s 0.1s ease-in;
}
.directory-card-wrapper:hover img {
  transition: opacity 0.3s ease-in;
  opacity: 1;
}

.material-symbols-outlined {
  vertical-align: middle;
}
.directory-card-wrapper:hover hr {
  background: white;
  position: absolute;
  width: 100%;
  bottom: 93px;
  left: 32px;
  transition: 0.5s 0.1s ease-in;
}
.directory-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 3rem;
}

.image-gallery {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.selected-image {
  width: 100%;
  max-height: 400px;
  object-fit: cover;
  border-radius: 5px;
  cursor: pointer;
}

.image-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.image-item-dir {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
  border-radius: 8px;
  opacity: 0.5;
}

.image-item-dir.active {
  border: 2px solid #a00030;
  opacity: 1;
}
.image-item-dir:hover {
  opacity: 1;
}
.skeleton {
  width: 100%;
  height: 100%;
  background-color: #f3f3f3;
  background-image: linear-gradient(
    90deg,
    #f3f3f3 25%,
    #e6e6e6 37%,
    #f3f3f3 63%
  );
  background-size: 200% 100%;
  animation: skeleton-loading 1.5s ease-in-out infinite;
}

@keyframes skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: -200% 50%;
  }
}
.selected-image-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: opacity 0.3s;
}

.arrow:hover {
  opacity: 0.7;
}

.left-arrow {
  left: 10px;
}

.right-arrow {
  right: 10px;
}
.svg-icons-dir {
  width: 24px;
  height: auto;
  cursor: pointer;
}
.dir-card {
  border-radius: 5px;
  padding: 20px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.dir-content::before {
  content: " . ";
  color: #a00030;
  margin-left: 0.3rem;
}
/* .dir-card > h6 {
  font-size: 18px;
  font-weight: bold;
} */
.dir-card p {
  font-size: 14px;
  color: darkgray;
}
.full-image-container {
  width: 100%;
  height: 300px;
  position: relative;
}
.full-image-container > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: gray;
  filter: brightness(0.8);
  cursor: pointer;
  border-radius: 5px;
}
.full-image-container > div {
  position: absolute;
  bottom: 46px;
  left: 36px;
  color: white;
}
.dir-card-icons {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  word-break: break-word;
  align-items: center;
}
.dir-card > span {
  background: #0080003d;
  padding: 1px 8px;
  font-size: 9px;
  border-radius: 2px;
}
.div-card-headr-icons {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.div-card-headr-icons > svg {
  width: 19px;
  height: 19px;
  margin-right: 5px;
}
.hash-bg-color {
  color: #a00030 !important;
}
.dir-similar {
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  border: 1px solid #80808059;
  border-radius: 9px;
  padding: 5px;
  margin-bottom: 1rem;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.dir-similar > img {
  width: 100%;
  height: 10rem;
  object-fit: cover;
  border-radius: 6px;
  opacity: 1;
}
.dir-sub-card {
  border-radius: 5px;
  padding: 10px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  max-height: 71rem;
  overflow: scroll;
  overflow-x: hidden;
  margin-bottom: 3rem;
}
.dir-sub-card::-webkit-scrollbar {
  width: 6px;
}
.dir-similar:hover {
  background: #dcdcdc3b;
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 2rem;
}
.grid-wrap-dir {
  grid-column: span 2;
}
@media (max-width: 1200px) {
  .grid-wrap-dir {
    grid-column: auto;
  }
}
.rmdp-container {
  width: 100%;
}
.announcement-edit {
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
  margin-right: 1rem;
  margin-bottom: 1rem;
  align-items: center;
  gap: 1rem;
  color: #a00030;
  font-weight: 700;
  cursor: pointer;
}
.listHomeTitle > p {
  font-size: 14px;
  color: gray;
  margin-top: 0.2rem !important;
}
.listHomeTitle > p::before {
  content: " - ";
  margin-left: 5px;
}
.search-icon {
  background: white;
  right: 6px;
}
.resource-img-icon {
  object-fit: cover;
  width: 64px;
  height: 64px;
}

.cardButton-com {
  border: 1px solid #8080805e !important;
  border-radius: 4px !important;
  font-size: 17px !important;
  background: white !important;
}
.kyc-background {
  background: #1e8fd5;
  padding: 8px;
  border-radius: 4px;
  color: white;
  max-width: 33rem;
  font-size: 14px;
}
.kyc-background > p::before {
  content: "Disclaimer : ";
  font-weight: 700;
}
.sub-font {
  font-size: 12px;
  color: gray;
}
.message-icon {
  width: 20px;
  height: 20px;
  margin-right: 6px;
}
.adb-card-new {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 10px;
  gap: 2em;
}
.adb-card-new > div {
  width: 100%;
}
.share-popup {
  border: 1px solid gray;
  border-radius: 4px;
  width: 100%;
  min-height: 20px;
  padding: 5px;
}
.article-wrap {
  margin-top: 4rem;
  margin-left: -16%;
  overflow-wrap: break-word;
}
.selfAssessment-form {
  background: #f8f9fa;
  padding: 2rem;
  border-radius: 5px;
}
.friendsCardWrapper-new {
  box-shadow: 0px 1px 2px #00000029;
  border: 1px solid #f7f7f7;
  border-radius: 5px;
  background: #fff;
  align-items: center;
  justify-content: center;
  padding: 11px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background: #dcdcdc1f;
}
.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-top: 0px;
  /* min-height: 8rem; */
}
.editor-container {
  position: relative;
}

.mention-dropdown {
  position: absolute;
  /* bottom: -100%;   */
  left: 0;
  width: 100%;
  padding: 8px;
  background-color: #fff;
  border: 1px solid #ccc;
  list-style-type: none;
  margin: 0;
  z-index: 1;
  /* top: 0; */
}

.mention-dropdown li {
  cursor: pointer;
}

.mention-dropdown li:hover {
  background-color: #f2f2f2;
}
.resource-file-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(8rem, 1fr));
  grid-gap: 0.3rem;
  gap: 0.3rem;
  margin-top: 1rem;
}
.resource-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 8px;
  border: 1px solid #a00030;
  border-radius: 9px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  height: 8rem;
  overflow: hidden;
}
.resource-content:hover {
  background-color: #dcdcdc91;
}

.resource-content > img {
  border-radius: 5px;
  object-fit: cover;
  margin-top: 1rem;
  height: 100%;
  margin-bottom: 1rem;
  width: auto;
}
.video-play-icon {
  background: white;
  border-radius: 50%;
  color: #a00030;
  padding: 3px;
}
.svg-icon-doc {
  width: 28px;
  height: 44px;
  cursor: pointer;
}
.mentor-time-slot {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(6rem, 1fr));
  justify-items: center;
  gap: 1rem;
}
.mentor-time-slot > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border: 1px solid #a00030;
  border-radius: 5px;
  padding: 7px;
  cursor: pointer;
  font-weight: 500;
  color: #a00030;
}

.mentor-time-slot > div:hover {
  border: none !important;
  background-color: #fcf7f8;
  color: black !important;
}
.mentor-selected {
  background-color: blue;
  color: white !important;
  border: none !important;
}
.mentor-slot {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(11rem, 1fr));
  padding: 22px;
  border: 1px solid #a00030;
  border-radius: 5px;
  gap: 2rem;
  font-weight: 700;
  /* color: #a00030; */
}
.mentor-slot > span {
  padding: 5px;
  text-align: center;
  border-radius: 5px;
  border: 1px solid #a00030;
  cursor: pointer;
}
.mentor-slot-select {
  /* background-color: #f3e0e5; */
  color: black !important;
  border: none !important;
}
.mentor-slot-chip {
  padding: 10px;
  border: 1px solid #a00030;
  border-radius: 5px;
  grid-gap: 2rem;
  display: flex;
  gap: 1rem;
  font-weight: 700;
  flex-wrap: wrap;
}
.time-slots {
  border: 1px solid #a00030;
  padding: 10px;
  border-radius: 5px;
  background: #fcf7f8;
  /* cursor: pointer; */
}

.right-model {
  overflow: scroll;
  height: 90vh;
}
.time-slot-model {
  border: 1px solid #a00030;
  padding: 5px;
  border-radius: 5px;
  background: #fcf7f8;
  font-size: 8px;
  width: 100%;
  cursor: pointer;
  text-align: center;
  font-weight: 700;
}
.time-slot-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(7rem, 1fr));
  justify-items: center;
  gap: 1rem;
}
.time-slot-model:hover {
  background-color: white;
}
.mentor-select-model {
  color: white;
  border: none !important;
  background: blue !important;
}
.slide-in {
  transition: transform 0.5s ease !important;
  transform: translateX(100%) !important;
}

.slide-in.show {
  transform: translateX(0%) !important;
}
.mentor-selected-slot {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(20rem, 100%), 1fr));
  padding: 22px;
  grid-gap: 2rem;
  gap: 2rem;
}
.slot-box {
  padding: 5px;
  border: 1px solid #a00030;
  border-radius: 5px;
  text-align: center;
}
.connectionredLabel-slot {
  color: #ba2121 !important;
  font-size: 12px !important;
  background: #eec4c4 !important;
  padding: 6px 10px !important;
  float: right !important;
  border-radius: 4px !important;
  width: 100px !important;
}

/* .top-dialog-paper {
  bottom: 10vh;
  margin-top: 0 !important;
} */
.pop-up-images {
  list-style: none;
  align-items: center;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(5rem, 1fr));
  text-align: center;
  padding: 0;
  margin-bottom: -15px;
  margin-top: 0.5rem;
  gap: 1rem;
}
.popup-active {
  /* opacity: 0.5; */
}
.popup-active:after {
  content: "";
  display: block;
  height: 3px;
  width: 100%;
  background: #a00030;
  position: absolute;
  top: 65px;
}
.pop-up-images > li {
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.resource-like {
  flex-wrap: wrap;
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 6px;
  padding: 0;
  gap: 1rem;
}
.resource-item {
  cursor: pointer;
  width: fit-content;
  color: gray;
  font-size: 12px;
}
.resource-overflow {
  max-height: 44rem;
  overflow: scroll;
  overflow-x: hidden;
}
.participant-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(17rem, 1fr));
  gap: 1rem;
}
.remove-user-button {
  background: #a00030;
  border-radius: 50%;
  font-size: 36px;
  padding: 5px;
  position: absolute;
  top: -15px;
  right: -9px;
  color: white;
  cursor: pointer;
}
.remove-user-button:hover {
  background: red;
}
.learning-filter {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(14rem, 1fr));
  gap: 2rem;
}
.home-post-button {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-left: 1.5rem;
  gap: 1rem;
}

.poll-btn {
  background: rgb(221, 76, 94) !important;
  border: none !important;
}
.survey-btn {
  background: rgb(253, 132, 144) !important;
  border: none !important;
}
.article-btn {
  background: rgb(253, 104, 118) !important;
  border: none !important;
}
.group-help-card {
  position: relative;
  background: #2b544c1f;
  padding: 9px;
  /* max-width: 19.3rem; */
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  flex-wrap: wrap;
}
.bg-light-new {
  background-color: #e9ecef !important;
}
.bell-svg {
  position: absolute;
  left: 2rem;
  bottom: 1rem;
}
.profile-img {
  cursor: pointer;
  margin-right: 21px;
  width: auto;
  height: 24px;
  margin-left: 0.3rem;
}
.valuematch-img {
  cursor: pointer;
  margin-right: 7px;
  width: auto;
  height: 24px;
}
.education-img {
  cursor: pointer;
  margin-right: 8px;
  width: auto;
  height: 19px;
}
.skill-img {
  cursor: pointer;
  margin-right: 13px;
  width: auto;
  height: 32px;
}
.profession-img {
  cursor: pointer;
  margin-right: 14px;
  width: 29px;
  height: 29px;
}
.group-count {
  display: flex;
  align-items: center;
  gap: 10px;
}
.formInputsDescriptions-new {
  width: 91%;
  border: 1px solid #808080a3;
  border-radius: 6px;
  padding: 5px;
}
.time-slot-wrap {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(11rem, 1fr));
}
.calender-btn {
  background: blue !important;
  border: none !important;
}
.event-box input[type="checkbox"] {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.text-outer-box {
  border: 1px solid #ddd;
  border-radius: 3px;
}
.time-wrapper {
  background: #8080803b;
  padding: 2rem;
  margin-bottom: 1rem !important;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
}
.seller-container {
  position: relative;
}
.seller-container img {
  height: 500px;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
  filter: brightness(0.7);
}
.seller-container > h4 {
  bottom: 4rem;
  position: absolute;
  left: 2rem;
  font-weight: bold;
  color: white;
}

.indicator {
  position: relative;
  width: 25px;
  height: 25px;
  transform: rotate(45deg);
}

.indicator span {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: none;
  border-bottom: 3px solid #fff;
  border-right: 3px solid #fff;
  animation: animate 1s linear infinite;
}

.indicator span:nth-child(1) {
  top: -30px;
  left: -30px;
  animation-delay: 0s;
}

.indicator span:nth-child(2) {
  top: -15px;
  left: -15px;
  animation-delay: 0.2s;
}

.indicator span:nth-child(3) {
  top: 0;
  left: 0;
  animation-delay: 0.4s;
}

.indicator span:nth-child(4) {
  top: 15px;
  left: 15px;
  animation-delay: 0.6s;
}

@keyframes animate {
  0% {
    border-color: blue;
    transform: translate(0, 0);
  }
  20% {
    border-color: blue;
    transform: translate(15px, 15px);
  }
  20.1%,
  100% {
    border-color: blue;
  }
}
.down-arrow {
  transform: rotate(270deg);
  -webkit-transform: rotate(270deg);
}
.notification-card {
  background: aliceblue;
  border: 1px solid #80808082;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}
::selection {
  background: #a00030;
  color: white;
}
.community-description {
  display: flex;
  background: ghostwhite;
  align-items: center;
  justify-content: center;
}
.group-card {
  width: 21rem;
  max-height: 25rem;
  margin-top: 1rem;
  background: white;
  padding: 1rem 1rem;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 1rem;
  border-radius: 15px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.group-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.group-card p {
  font-size: 12px;
}
.bg-warning-post {
  background-color: #ffc10799 !important;
}
.popover-body {
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.mentor-home-card {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(17rem, 1fr));
  text-transform: capitalize;
}
.mentor-home-card > div {
  display: flex;
  height: 9rem;
  gap: 1rem;
  overflow: hidden;
  background: #fff;
  box-shadow: rgb(17 17 26 / 10%) 0px 4px 16px, rgb(17 17 26 / 5%) 0px 8px 32px;
  border-radius: 5px;
  cursor: pointer;
}
.mentor-home-card > div:hover {
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
}
.mentor-home-card img {
  height: auto;
  width: 124px;
  object-fit: cover;
}
.mentor-home-card h4 {
  font-weight: bold;
  font-size: 18px;
  color: gray;
}
.mentor-home-card p {
  font-size: 12px;
}
.drawer-body {
  background: #8080801c;
  padding: 1rem;
  border-radius: 12px;
  margin-top: 2rem !important;
  margin-bottom: 1rem !important;
}
.drawer-bar {
  border: 1px solid gray;
  padding: 1rem;
  border-radius: 5px;
  overflow: hidden;
  height: 4rem;
}
.drawer-bar-active {
  min-height: 4rem;
  border: 0.5px solid #b5b5b5;
  padding: 1rem;
  border-radius: 5px;
  overflow: hidden;
}

.mentor-menu {
  position: absolute;
  top: 21px;
  right: 12px;
  height: 24px;
  width: auto;
  transform: translate(0%, 0%) rotate(0deg);
  cursor: pointer;
}
.mentor-menu.active {
  transform: translate(0%, 0%) rotate(90deg);
}
.youtube-container {
  gap: 1rem;
  padding: 1.3rem;
  border-radius: 5px;
  /* background: black; */
  border: 1px solid gray;
}
.connection-card {
  background: gainsboro;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  border-radius: 5px;
}
.admin-span {
  background: gold;
  border-radius: 15px;
  font-size: 12px;
  padding: 2px 8px;
  position: absolute;
  right: 5px;
  top: 107px;
  color: blue;
}

.admin-span > svg {
  width: 23px;
  height: auto;
  margin-top: 0px;
  color: darkviolet;
}

.group-filter {
  gap: 1rem;
  background: gainsboro;
  padding: 1rem;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.community-img {
  border: 1px solid gray;
  border-radius: 5px;
  padding: 13px;
  margin-bottom: 27px;
}

.referral-code {
  cursor: pointer;
  background: #dcdcdc8f;
  width: fit-content;
  border-radius: 2px;
  font-size: 14px;
  padding: 1px 9px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}
.referral-code p {
  font-size: 12px;
  color: brown;
}

.social-icons-share {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
}
.social-icons-share ul {
  padding: 0;
  flex-direction: row;
  gap: 2rem;
  margin-top: 2rem;
}

.social-icons-share li {
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  color: gray;
  gap: 1rem;
}
.copy-button {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  gap: 7px;
}

.copy-button li {
  padding: 4px;
}
.copy-button li:nth-child(1) {
  background: aliceblue;
  padding: 13px;
  border: 1px solid gainsboro;
  cursor: pointer;
}
.copy-button li:nth-child(2) {
  background: antiquewhite;
  padding: 13px;
  cursor: pointer;
}
.waviy {
  position: relative;
}
.waviy span {
  position: relative;
  display: inline-block;
  font-size: 14px;
  color: #a00030;
  animation: flip 2s infinite;
  animation-delay: calc(0.2s * var(--i));
  font-weight: 700;
}
@keyframes flip {
  0%,
  80% {
    transform: rotateY(360deg);
    color: black;
  }
}

.moving-arrow {
  animation: arrow 1s ease-in infinite alternate;
  margin-left: 1rem;
}
@keyframes arrow {
  0% {
    margin-right: 1rem;
  }
  25% {
    margin-right: 1.5rem;
  }
  50% {
    margin-right: 2rem;
  }
  75% {
    margin-right: 1.5rem;
  }
  100% {
    margin-right: 1rem;
  }
}

.market-place-animation > span {
  margin: 0 15px;
  font-size: 12px;
  font-weight: 200;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.45);
  animation: span 4s ease-in infinite alternate;
}

.design-letter {
  display: inline-flex;
  height: 30px;
  width: 27px;
  border: 2.35px solid black;
  border-radius: 14px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.75), inset 0 0 2px rgba(0, 0, 0, 0.45);
  animation: letter 3s ease-in-out infinite alternate;
}

@keyframes span {
  0%,
  30% {
    margin: 0 1px;
  }
  70%,
  100% {
    margin: 0 3px;
    font-weight: 500;
  }
}
@keyframes letter {
  0%,
  30% {
    width: 27px;
  }
  70%,
  100% {
    width: 30vw;
  }
}
.green-btn {
  background: forestgreen;
  padding: 12px;
  border-radius: 22px;
  color: white;
  cursor: pointer;
  font-size: 13px;
}
.directory-wrap {
  background: gainsboro;
  margin-top: 1rem;
  gap: 1rem;
  padding: 1rem;
  border-radius: 5px;
}

.profile-form {
  /* background: aliceblue; */
  margin: 1rem;
  border-radius: 14px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
/* .form-label {
  color: #999999;
  font-size: 18px;
} */
.card-dName {
  color: #333333;
  font-size: 18px;
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden !important;
  padding: 1px 1px 0px 10px;
  margin: 0;
}
.w-content {
  width: max-content;
}
.selected-img {
  height: 50px;
  width: auto;
}

#popover-basic {
  margin-left: 0px;
  animation: popover 0.5s ease;
}
@keyframes popover {
  0% {
    margin-left: 25vw;
  }

  100% {
    margin-left: 0vw;
  }
}
.nav-new-menus {
  color: black;
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  font-size: 0.9375rem;
  gap: 4px;
}
.nav-new-menus > li {
  margin-left: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 1rem;
  padding: 0.5rem;
}
.nav-active {
  border: 0.01rem solid #a00030;
  border-radius: 5px;
  color: brown;
  /* border: 1px solid #a00030;
  border-radius: 5px;
  background: #a000300d;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px; */
  /* color: black; */
}
.nav-new-menus svg {
  width: 24px;
  height: auto;
}
.nav-new-menus li > span:nth-child(1) {
  margin-right: 0.5rem;
}
.sub-nav-menu {
  margin-left: 2rem !important;
  font-size: 12px;
  color: black;
}
.text-filed-border {
  background: white;
  border-radius: 5px;
}
.connection-grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(7rem, 1fr));
  gap: 1rem;
}
/* market-card  */

.market-card-container {
  width: 100%;
  background-color: white;
  border-radius: 15px;
  margin-bottom: 1rem;
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

/* Details */
.hero-image-container {
  height: 330px;
  display: flex;
  width: auto;
  border-radius: 14px;
  cursor: pointer;
  background: black;
}
.hero-image-container img {
  width: 100%;
  height: 100%;
  object-fit: fill;
  border-radius: 1rem;
  transition: all 0.5s ease-in-out;
}
/* .hero-image-container::after {
  content: "";
  background-image: url("https://i.postimg.cc/9MtT4GZY/view.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 5rem;
  background-color: hsla(178, 100%, 50%, 0.3);
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  z-index: 2;
  opacity: 0;
  transition: opacity 0.3s ease-out;
} */

.hero-image-container > img:hover {
  border-radius: 45%;
}

.small-image {
  width: 1.2em;
  margin-right: 0.5em;
}

.small-avatar {
  width: 2em;
  border-radius: 200px;
  outline: 2px solid white;
  margin-right: 1.4em;
}
.pay-button {
  background: #00800075;
  color: black;
  border: none;
  padding: 5px 18px;
  font-weight: 600;
  transition: all 0.5s ease-in-out;
}

.market-grid {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
  grid-gap: 1rem;
  gap: 1rem;
}
.friend-about-card {
  color: gray;
  width: auto;
  height: 19px;
  margin-right: 5px;
}

/* Nav Menu  */
.nav-menu-new {
  position: relative;
  display: flex;
  height: 100vh;
  padding: 0rem 4rem;
  gap: 0.5rem;
}
.nav-menu-new > main {
  flex: 1;
  overflow-x: clip;
  width: 100%;
}
@-moz-document url-prefix() {
  .announcement-res-wrap {
    width: 50vw;
  }
}
.nav-menu-new > *:nth-child(1),
.nav-menu-new > *:nth-child(2) {
  margin-top: 7rem;
}
.new-mobileMenu {
  display: none;
}

.home-sub {
  color: gray;
  font-size: 14px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-weight: 600;
}
.t-community-logo {
  width: 33px;
  height: auto;
  margin-right: 7px;
  color: brown;
  margin-bottom: 5px;
}
.cardWrapper > span {
  padding: 0.8rem;
  display: flex;
  align-items: center;
  text-align: center;
}
.trending-card:hover {
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.header-menu-new {
  display: flex;
  align-items: center;
  padding-left: 4rem;
  padding-right: 4rem;
}
.like-alert {
  position: absolute;
  left: 38%;
  transform: translate(-50%, -50%);
  padding: 20px;
  z-index: 10000;
  top: 93%;
}
.menu-filter-list {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 1rem;
}
.menu-filter-list > li {
  display: flex;
  list-style: none;
  padding: 1rem;
  gap: 1rem;
}
.menu-filter-list svg {
  height: 24px;
  color: #a00030;
  width: auto;
}
.menu-filter-list h4 {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
}
.menu-filter-list > li:hover {
  cursor: pointer;
  background: gainsboro;
  border-radius: 5px;
}
.menu-filter-list p {
  font-size: 13px;
  margin-top: 5px !important;
}
.profile-card-v1 {
  background: white;
  margin-top: 10px;
  box-shadow: 0px 1px 3px #00000029;
  border: 1px solid #f7f7f7;
  border-radius: 3px;
  list-style: none;
  padding: 0;
  height: 800px;
}
.profile-card-v1 li {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  margin-left: 1rem;
  border-bottom: 1px solid gray;
  padding-bottom: 1rem;
  padding-top: 1rem;
  cursor: pointer;
  color: gray;
}
.profile-card-v1 li:first-child {
  margin-top: 1rem;
}
.profile-card-v1 svg {
  width: 24px;
  height: auto;
}
.profile-card-v1 h4 {
  margin: 0;
  font-size: 15px;
}
.pay-svg,
.pay-button svg {
  width: 18px;
  height: auto;
  margin-right: 0.5rem;
  margin-bottom: 3px;
}
.m-button {
  background: blue;
  color: white;
  border-radius: 5px;
  padding: 8px;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
}
/* market place animation */
.product-container {
  display: flex;
  justify-content: center;
  padding: 1rem;
  background: #a9a9a942;
  grid-gap: 1rem;
  gap: 1rem;
  flex-wrap: wrap;
  border-radius: 5px;
}

.product-container h6 {
  font-size: 13px;
  font-weight: bold;
}
.product-container > div:nth-child(1) {
  max-width: 17rem;
  height: 17rem;
}
.product-container > div:nth-child(2) {
  display: flex;
  flex: 1;
  flex-direction: column;
}
/* .product-container span {
  color: white;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
   font-weight: bold;
  font-size: 14px;
  width: 100%;
  cursor: pointer;
  margin-top: auto;
  border-radius: 5px;
  animation: product 1s infinite;
}
@keyframes product {
  from {
    background: linear-gradient(
      12deg,
      rgba(34, 193, 195, 1) 0%,
      rgb(236 45 253) 100%
    );
  }
  to {
    background: linear-gradient(
      317deg,
      rgba(34, 193, 195, 1) 0%,
      #c435dca8 100%
    );
  }
} */
.product-container img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
  /* cursor: pointer; */
}
.sub-product-container h4 {
  font-size: 17px;
  font-weight: bold;
}
.sub-product-container > p:nth-child(2) {
  text-transform: capitalize;
  word-break: break-all;
  color: gray;
  font-size: 11px;
  display: -webkit-box;
  -webkit-line-clamp: 15;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.sub-product-container > p:nth-child(4) {
  font-size: 13px;
  font-weight: 400;
  margin-top: 8px !important;
}
.r-model {
  text-align: center;
  line-height: 2rem;
  justify-content: center;
  flex-wrap: wrap;
  margin: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.r-model p {
  font-weight: bold;
  margin-left: 8px !important;
  margin-right: 8px !important;
}
.form-label {
  display: block !important;
  font-size: 15px !important;
  font-weight: bold !important;
  color: black !important;
  padding-right: 20px !important;
}
.m-stepper {
  list-style: none;
  display: flex;
  align-items: center;
  margin-top: 2rem;
}

.m-stepper > ul {
  padding: 0;
  margin: 0;
  display: flex;
}

.m-stepper div {
  height: 1rem;
  width: 4rem;
  z-index: 100;
  color: red;
  border-top: 3px solid brown;
  margin-top: 1rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.m-stepper span {
  padding: 0.5rem 0.9rem;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid gray;
}
.m-stepper span:hover {
  background: blue;
  color: white;
}
.m-stepper p {
  color: gray;
  font-size: 15px;
  margin-top: 1rem !important;
  margin-bottom: 0 !important;
}
/* .m-container {
  list-style: none;
  margin: 0;
  background: #8080801f;
  border-radius: 5px;
  padding: 1rem !important;
}
.m-container p {
  font-size: 16px;
  margin-bottom: 0.5rem !important;
  margin-top:0.5rem !important;
} */
.img-chip {
  display: flex;
  border: 1px solid gainsboro;
  width: fit-content;
  padding: 5px;
  border-radius: 5px;
  color: gray;
  height: fit-content;
  flex-direction: column;
}
.post-container-btn {
  padding: 5px 12px !important;
  font-size: 12px;
  background: #e9ebeff7 !important;
  display: flex !important;
  align-items: center !important;
  grid-gap: 4px !important;
  gap: 4px !important;
  text-overflow: ellipsis;
  word-break: break-all;
  overflow: hidden;
  border-radius: 5px;
  color: gray !important;
}
.button-svg {
  width: 20px;
  height: 20px;
}
.post-container-btns {
  margin-left: 19px;
  list-style: none;
  padding: 0;
  /* display: grid;
  grid-template-columns: repeat(auto-fit, minmax(6rem, 1fr)); */
  gap: 1rem;
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
}
.post-container-btns a:hover {
  color: blue !important;
}
.post-container-btns p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* .post-container-btns > :last-child {
  grid-column: span 2;
} */

@media (max-width: 1400px) {
  .post-container-btns {
    align-items: center;
    justify-content: center;
  }
}

.pay-button:hover {
  padding: 9px 25px;
}

.lineUp {
  animation: 2s anim-lineUp ease-out infinite;
  font-weight: 600;
}
@keyframes anim-lineUp {
  0% {
    opacity: 0;
    transform: translateY(80%);
  }
  20% {
    opacity: 0;
    color: red;
  }
  50% {
    opacity: 1;
    transform: translateY(0%);
    color: rebeccapurple;
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
    color: brown;
  }
}
.directory-card-new {
  background: #dededf;
  display: flex;
  flex-direction: column;
  align-items: start;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.directory-card-new > section:nth-child(1) {
  width: 100%;
  height: 284px;
  overflow: hidden;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.directory-card-new img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  transition: all 0.5s;
}
.directory-card-new > section:nth-child(2) div {
  width: 100%;
  background: #dededf;
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
  margin-top: -2rem;
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.directory-card-new button {
  width: 100%;
  border-top-left-radius: 2rem !important;
  border-top-right-radius: 2rem !important;
  margin-top: 1rem;
  padding: 0.7rem;
  border: none;
  /* background: black; */
  font-weight: bold;
}
.directory-card-new img:hover {
  transform: scale(1.4) rotate(12deg);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.directory-mark {
  position: absolute;
  width: 40px;
  height: auto;
  right: -1px;
  color: #80808099;
  cursor: pointer;
  top: 4px;
  /* filter: drop-shadow(2px 4px 6px black); */
}
.directory-mark:hover {
  filter: opacity(0.7);
}
.text-area {
  border: 1px solid gray;
  border-radius: 5px;
  width: 100%;
  padding: 1rem;
}

.cardWrapper-2 {
  background: white;
  padding: 2rem;
  border-radius: 10px;
  box-sizing: border-box;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.elpsis-line {
  color: gray;
  font-size: 12px;
  text-transform: capitalize;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  word-break: break-all;
  height: 3rem;
}
.b-17 {
  border-radius: 17px !important;
}
.bd-container {
  width: 13rem;
  border-radius: 5px;
  border: 1px solid gainsboro;
  overflow: hidden;
  height: 13rem;
}
.bd-container > img {
  height: 100%;
  border-radius: 5px;
  object-fit: contain;
  width: 100%;
  background: #80808012;
}
.ring:hover {
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
}

.single-line {
  font-size: 16px;
  font-weight: bold;
  text-transform: capitalize;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  word-break: break-all;
}
.p-container {
  list-style: none;
  padding: 0;
  align-items: center;
}
.p-container > li:nth-child(1),
.p-container > li:nth-child(2) {
  min-width: 11rem;
}
.p-sub {
  color: gray;
  font-size: 13px;
  font-weight: 500;
  border-bottom: 1px solid gainsboro;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.event-ref-page {
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: white;
}
.event-ref-page > header {
  width: auto;
  padding: 1rem;
}
.event-ref-page img {
  height: 4rem;
  width: auto;
}
.event-ref-page section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background: url(https://corporate.yuukke.com/bcav2/auth/image/4723eb0e-4948-4096-911d-464d3e75778d)
      center / cover no-repeat fixed,
    #28254c5e;
  color: black;
  text-align: center;
  width: -webkit-fill-available;
  /* min-height: 23rem; */
  width: 100%;
}
.meeting-box {
  border-top: 3px solid brown;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 1rem;
  gap: 1rem;
  margin: 2rem 1rem;
}
.meeting-box-btn {
  border: 1px solid brown;
  border-radius: 5px;
  padding: 0.4rem;
  font-size: 10px;
  font-weight: bold;
  display: block;
  color: brown;
  cursor: pointer;
}
.btn-svg {
  color: brown;
  margin-left: 10px;
  width: 15px;
  height: auto;
}
.meeting-copy {
  display: flex;
  gap: 1rem;
  border: 1px solid gray;
  border-radius: 5px;
  padding: 0.4rem;
  color: blue;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
}
.meeting-copy > p:nth-child(1) {
  overflow: hidden;
  text-wrap: nowrap;
  max-width: 18rem;
  text-overflow: ellipsis;
  margin-left: 12px !important;
}
.f-5 {
  font-weight: 500 !important;
}
/* .meeting-btn {
  margin-right: 15px;
  position: absolute;
  right: 0;
  top: 38%;
  background: white;
  color: black !important;
  font-weight: 700;
  border: 1px solid red;
} */
.btn2 {
  padding: 8px 18px;
  border: 1px solid #a00030;
  border-radius: 19px;
  background: #a0000014;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  width: -moz-fit-content;
  width: fit-content;
}
.btn2:hover {
  outline: 2px solid brown;
}
.community-v2-card {
  height: 6.5rem;
  border: 1px solid gainsboro;
  border-radius: 5px;
  overflow: hidden;
  line-height: 21px;
}
.text-overflow-v2 {
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.community-v2-card {
  height: 6.5rem;
  border: 1px solid gainsboro;
  border-radius: 5px;
  overflow: hidden;
  line-height: 21px;
}
.text-overflow-v2 {
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.load-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-weight: 700;
  text-transform: capitalize;
  font-size: 14px !important;
}

.activity-badge-container {
  margin-top: 5px;
}

.activity-badge-parent {
  display: flex;
  background-color: aliceblue;
  border-radius: 15px;
  width: 11rem;
  padding: 5px;
}

.label-v2 {
  font-size: 15px !important;
  color: black !important;
  font-weight: 500 !important;
  margin-bottom: 5px !important;
}
.heading-h4-v2 {
  font-size: 1.5rem;
  font-weight: 700;
}
.l-select > div{
margin-top:0 !important;
}
.mw-15{
  min-width:15rem;
}
.w-fit{
  width: fit-content;
}
 